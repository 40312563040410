(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ListeReferenceDeleteController',ListeReferenceDeleteController);
ListeReferenceDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'ListeReference'];
function ListeReferenceDeleteController($scope, $uibModalInstance, entity, ListeReference){
	$scope.listeReference = entity;
    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.confirmDelete = function (id) {
        ListeReference.delete({id: id},
            function () {
                $uibModalInstance.close(true);
            });
    };
}

})();
