(function() {
	'use strict';
	angular.module('infochamApp').controller('UserManagementAddController',
			UserManagementAddController);

	UserManagementAddController.$inject = [ 'Agenda', 'Certificat',
			'ReferentielWs', 'Principal', '$window', '$stateParams', '$http',
			'$rootScope', 'DTColumnBuilder', '$scope', 'DTOptionsBuilder',
			'$translate', '$state', 'Inscription2', 'ParseLinks',
			'AlertService', 'FileUploader', 'CustomServices', '$timeout',
			'fileUpload' ];

	function UserManagementAddController(Agenda, Certificat, ReferentielWs,
			Principal, $window, $stateParams, $http, $rootScope,
			DTColumnBuilder, $scope, DTOptionsBuilder, $translate, $state,
			Inscription2, ParseLinks, AlertService, FileUploader,
			CustomServices, $timeout, fileUpload) {
		
	    var vm = this;
	    $scope.emailDTO = {};
	    $scope.inscription ={};
	    $scope.aaa ={};
	    $scope.adresseSiege ={};
	    $scope.adresseUsine ={};
	    $scope.oeExpediteur ={};
	    $scope.user ={};
	    $scope.responsable ={};
	    $scope.date = new Date();
	    $scope.mailExist = false;
	    $scope.loginExist = false;
	    $scope.user.email = null;
	    $scope.fileList = [];
	    vm.password = null;
	    $scope.disabled = true;
	    var i = 0;
	    $scope.isAuthenticated = Principal.isAuthenticated;
	       $scope.annuler = function() {
	    			$state.go('home', null, { reload: true } );
	        	}
	       $scope.checkLogin = function(login) {
	    		 Inscription2.loginExist(login).then(function (result) {
			   $scope.loginExist = result.data;
		        	 });
		        	}
	       $scope.checkMail = function(email) {
	    	   $scope.emailDTO.email = email;
	    	   Inscription2.emailExist($scope.emailDTO).then(function (result) {
				   $scope.mailExist = result.data;
			        	 });
			        	}
	    	  $scope.add = function(user, oeExpediteur,adresseUsine,adresseSiege,password,confirmPassword) {
	    	    	if($scope.loginExist){
		  				swal("Login existe déja ..!"," ", "error")

	    	    	}
	    	    	else if ($scope.mailExist){
		  				swal("Email existe déja ..!"," ", "error")

	    	    	}
	    	    	else if ($scope.mailExist){
		  				swal("Email existe déja ..!"," ", "error")

	    	    	}
	    	    	else if(password !=null && confirmPassword !=null && !angular.equals(password ,confirmPassword)){
	    	    		swal("Le mot de passe et sa confirmation ne sont pas égaux !"," ","error")
		         	  }
	    	    	else{
	    		  $scope.inscription.adresseSiege = adresseSiege;
	    	  		$scope.inscription.adresseUsine = adresseUsine;
	    	  		$scope.inscription.oeExpediteur = oeExpediteur ;
	    	  		$scope.inscription.user = user;
	    	  		$scope.inscription.responsable =  $scope.responsable;
	    	  		
	    	  		 if($scope.typeCci == undefined) {
						 $scope.inscription.typeC = "Siege" ;
					 }
					 if($scope.typeCci == "Siege") {
						 $scope.inscription.typeC = "Siege" ;
					 }
					 if($scope.typeCci == "Usine") {
						 $scope.inscription.typeC = "Usine" ;
					 }

	    	  		
	    	  $scope.newUser =	Inscription2.addInscriptionByAdmin($scope.inscription,password).then(function (result) {
	    		  if(result.status==200 ){
	    			  $scope.idAgenda = result.data.id;
	    	  			 swal({
	           			  position: 'center',
	           			  type: 'success',
	           			  title: 'Demande est ajoutée avec succès',
	           			  showConfirmButton: false,
	           			  timer: 2000
	           			})
	    		  }
	    		  else{
	    			  swal("veuillez réessayer","", "error")

	    		  }
	       			$state.go('new_operateur', null, { reload: true } );
	    	  			         });
	    	  }	
	    	  }
	    	 
	    	   $(".toggle-password").click(function() {
		    	   $(this).toggleClass("fa-eye fa-eye-slash");
		    	   var input = $($(this).attr("toggle"));
		    	   if (input.attr("type") == "password") {
		    	     input.attr("type", "text");
		    	   } else {
		    	     input.attr("type", "password");
		    	   }
		    	 });
	    	  $scope.lovModel2 = 'api/referentiels/secteur';// this will be
	  		// the LOV
	  		// datasource
	  		$scope.lovTitle2 = 'Liste des secteurs';
	  		$scope.lovColumnList2 = [  'Libellé' ];
	  		$scope.lovFieldList2 = [  'libelle' ];
	  		// function to be called when you click "choose" button
	  		$scope.lovCallBack2 = function(result) {
	  			$scope.oeExpediteur.secteurActivite = result;
	  			$scope.$emit('eventSousSecteur',result)
	  		}
	  		
	  		
	  		
	  		$scope.lovModel20 = 'api/referentiels/categories';// this will
	  		// be
	  	// the LOV
	  	// datasource
	  	$scope.lovTitle20= 'Liste des categories Juridique';
	  	$scope.lovColumnList20 = [ 'Libellé' ];
	  	$scope.lovFieldList20 = [ 'libelle' ];
	  	// function to be called when you click "choose" button
	  	$scope.lovCallBack20 = function(result) {
	  	$scope.oeExpediteur.categorieJuridique = result;
	  	$scope.$emit('eventCategorieJuridique', result)
	  	}
	  	  $scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
	  		// the LOV
	  		// datasource
	  		$scope.lovTitle3 = 'Liste des régions';
	  		$scope.lovColumnList3 = [  'Libellé' ];
	  		$scope.lovFieldList3 = [  'libelle' ];
	  		// function to be called when you click "choose" button
	  		$scope.lovCallBack3 = function(result) {
	  			$scope.adresseSiege.gouvernorat = result;
	  			$scope.$emit('eventLocaliteAdrSiege',result)
	  			
	  			Agenda.getCCIByGouvernorat($scope.adresseSiege.gouvernorat.valeur1).then(function (result){
					$scope.libelleCci = result.data.libelle ; 
	  				$scope.telephoneCci = result.data.telephone;
					$scope.adresseCci = result.data.adresse;
					$scope.faxCci = result.data.fax;
					$scope.courrielCci = result.data.courriel;
					$scope.siteWebCci = result.data.siteWeb;
//					$scope.records = $scope.data.length;
				});
	  		}
	  		
		  	  $scope.lovModel5 = 'api/referentiels/gouvernorat';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle5 = 'Liste des régions';
		  		$scope.lovColumnList5 = [ 'Libellé' ];
		  		$scope.lovFieldList5 = [  'libelle' ];
		  		// function to be called when you click "choose" button
				$scope.lovCallBack5 = function(result) {
		  			$scope.adresseUsine.gouvernorat = result;
		  			$scope.$emit('eventLocaliteAdrUsine',result)
		  			Agenda.getCCIByGouvernorat($scope.adresseUsine.gouvernorat.valeur1).then(function (result){
		  				$scope.libelleCciUsine = result.data.libelle ; 
		  				$scope.telephoneCciUsine = result.data.telephone;
						$scope.adresseCciUsine = result.data.adresse;
						$scope.faxCciUsine = result.data.fax;
						$scope.courrielCciUsine = result.data.courriel;
						$scope.siteWebCciUsine = result.data.siteWeb;
						
//						$scope.records = $scope.data.length;
					});
		  		}
		  		$scope.siegeToUsine= function(siegeUsine) {
		  			if(siegeUsine == "O"){
		  				$scope.adresseUsine = $scope.adresseSiege;
		  			}
		  			else $scope.adresseUsine = {} ;
		  					  		}
	  		
	  		$scope.getSousSecteur= function(secteur) {
	  		}
	  		
	  		 $scope.lovModel1 = 'api/referentiels/sousSecteur/all';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle1 = 'Liste des sous Secteur';
		  		$scope.lovColumnList1 = [  'Libellé' ];
		  		$scope.lovFieldList1 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack1 = function(result) {
		  			$scope.oeExpediteur.sousSecteur = result;
		  		}
	  		
	  		$rootScope.$on('eventSousSecteur', function(event,result) {
	  				  		 $scope.lovModel1 = 'api/referentiels/sousSecteur/'+result.codeRubrique;// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle1 = 'Liste des sous Secteur';
		  		$scope.lovColumnList1 = [  'Libellé' ];
		  		$scope.lovFieldList1 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack1 = function(result) {
		  			$scope.oeExpediteur.sousSecteur = result;
		  		}
	  	       	});
	  		
	  		 $scope.lovModel6 = 'api/referentiels/localite/all';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle6 = 'Liste des villes';
		  		$scope.lovColumnList6 = [  'Libellé' ];
		  		$scope.lovFieldList6 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack6 = function(result) {
		  			$scope.adresseUsine.localite = result;
		  		}

	  		$rootScope.$on('eventLocaliteAdrUsine', function(event,result) {
	  				  		 $scope.lovModel6 = 'api/referentiels/localite/'+result.codeRubrique;// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle6 = 'Liste des villes';
		  		$scope.lovColumnList6 = [  'Libellé' ];
		  		$scope.lovFieldList6 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack6 = function(result) {
		  			$scope.adresseUsine.localite = result;
		  		}
	  	       	});
	  		
	  		
	  		 $scope.lovModel4 = 'api/referentiels/localite/all';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle4 = 'Liste des villes';
		  		$scope.lovColumnList4 = [  'Libellé' ];
		  		$scope.lovFieldList4 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack4 = function(result) {
		  			$scope.adresseSiege.localite = result;
		  		}
		  		
		  		
		  		 $scope.lovModel8 = 'api/referentiels/allNationalites';// this will be
			  		// the LOV
			  		// datasource
			  		$scope.lovTitle8 = 'Liste des Nationalités';
			  		$scope.lovColumnList8 = [  'Libellé' ];
			  		$scope.lovFieldList8 = [  'libelle' ];
			  		// function to be called when you click "choose" button
			  		$scope.lovCallBack8 = function(result) {
			  			$scope.responsable.nationalite = result;
			  		}
			  		
			  		
			  		 $scope.lovModel9 = 'api/referentiels/allPays';// this will be
				  		// the LOV
				  		// datasource
				  		$scope.lovTitle9 = 'Liste des pays';
				  		$scope.lovColumnList9 = [  'Libellé' ];
				  		$scope.lovFieldList9 = [  'libelle' ];
				  		// function to be called when you click "choose" button
				  		$scope.lovCallBack9 = function(result) {
				  			$scope.oeExpediteur.pays = result;
				  		}
		  		
	  		$rootScope.$on('eventLocaliteAdrSiege', function(event,result) {
	  				  		 $scope.lovModel4 = 'api/referentiels/localite/'+result.codeRubrique;// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle4 = 'Liste des villes';
		  		$scope.lovColumnList4 = [  'Libellé' ];
		  		$scope.lovFieldList4 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack4 = function(result) {
		  			$scope.adresseSiege.localite = result;
		  		}
	  	       	});

	    		$scope.changeNature=function(nature){
	    			$scope.nature=nature;
	    			if($scope.nature.aSigner == 0){
	    				$scope.isSigner = false;
	    			}
	    			else{
	    				$scope.isSigner = true;
	    			}
	    		}
	    
		$scope.lovModel7 = 'api/referentiels/getTypeOperateur';// this will be
		// the LOV
		// datasource
		$scope.lovTitle7 = 'Liste des Types Opérateur';
		$scope.lovColumnList7 = [  'Libellé' ];
		$scope.lovFieldList7 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack7 = function(result) {
			$scope.oeExpediteur.typeOperateur = result;
		}
	    		
}
})();
