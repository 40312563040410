(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('NaturePieceJointeDeleteController', NaturePieceJointeDeleteController);

	NaturePieceJointeDeleteController.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'entity', 'NaturePieceJointe'];

	function NaturePieceJointeDeleteController ($scope, $rootScope, $uibModalInstance, entity, NaturePieceJointe) {

        $scope.naturePieceJointe = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            NaturePieceJointe.delete({id: id},
                function () {
            		$rootScope.$broadcast('savedNatPJ',{update:true});
                    $uibModalInstance.close(true);
                });
        };

    }
})();
