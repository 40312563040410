(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ResponsableDeleteController',ResponsableDeleteController);
ResponsableDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'Responsable'];
function ResponsableDeleteController($scope, $uibModalInstance, entity, Responsable){
	$scope.responsable = entity;
    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.confirmDelete = function (id) {
        Responsable.delete({id: id},
            function () {
                $uibModalInstance.close(true);
            });
    };
}

})();
