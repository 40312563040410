(function() {
	'use strict';
	angular.module('infochamApp').factory('CustomTypeAdhesion', CustomTypeAdhesion);
	CustomTypeAdhesion.$inject = ['$http', '$rootScope'];
	function CustomTypeAdhesion ($http, $rootScope) {
		return {
			'getTypeAdhesionByCCI':function(idCCI){
				return $http.get('api/getTypeAdhesionByCCI/'+idCCI)	;
			},

		}
	}
})();

