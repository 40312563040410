(function() {
	'use strict';

	angular.module('infochamApp').controller('DemandeAdhesionDetailController',
			DemandeAdhesionDetailController);

	DemandeAdhesionDetailController.$inject = [ '$scope', '$rootScope',
			'$stateParams', 'entity', 'DemandeAdhesion', 'TypeAdhesion', 'Cci',
			'Facture', 'ModeDepot', 'User' ];

	function DemandeAdhesionDetailController($scope, $rootScope, $stateParams,
			entity, DemandeAdhesion, TypeAdhesion, Cci, Facture, ModeDepot,
			User) {
		$scope.demandeAdhesion = entity;
		$scope.load = function(id) {
			DemandeAdhesion.get({
				id : id
			}, function(result) {
				$scope.demandeAdhesion = result;
			});
		};
		var unsubscribe = $rootScope.$on('infochamApp:demandeAdhesionUpdate',
				function(event, result) {
					$scope.demandeAdhesion = result;
				});
		$scope.$on('$destroy', unsubscribe);

	}
})();
