(function() {
	'use strict';
	angular.module('infochamApp').factory('DocumentPrix', DocumentPrix);

	DocumentPrix.$inject = [ '$resource', 'DateUtils' ];
	function DocumentPrix($resource, DateUtils) {
        return $resource('api/documentsPrix/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();