(function() {
	'use strict';
	angular.module('infochamApp').controller('DetailUserByRoleController',
			DetailUserByRoleController);

	DetailUserByRoleController.$inject = [ '$compile', '$filter', '$translate',
			'$state', '$scope', 'entity', '$uibModalInstance', '$http',
			'$stateParams', 'DTOptionsBuilder', 'DTColumnBuilder' ];

	function DetailUserByRoleController($compile, $filter, $translate, $state,
			$scope, entity, $uibModalInstance, $http, $stateParams,
			DTOptionsBuilder, DTColumnBuilder) {

		$scope.role = $stateParams.name;
		$scope.treeObj = undefined;
		$scope.authority = entity.data;

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.standardOptions = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					// Either you specify the AjaxDataProp here
					// dataSrc: 'data',
					url : 'api/getUserByRole/datatable/' + $stateParams.name,
					type : 'GET'
				})
				// or here
				.withDataProp('data')
				.withDisplayLength(10)
				.withOption('processing', true)
				.withOption('serverSide', true)
				.withPaginationType('full_numbers')
				.withOption('createdRow', createdRow)
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},

				});
		$scope.standardColumns = [
				//	                       
				DTColumnBuilder.newColumn('login').withTitle(
						$translate.instant('login')).withOption('width', '22%'),
				DTColumnBuilder.newColumn('nom').withTitle(
						$translate.instant('Prénom'))
						.withOption('width', '22%'),
				DTColumnBuilder.newColumn('prenom').withTitle(
						$translate.instant('Nom')).withOption('width', '22%'),

		];
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}

	}
})();
