(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('helpdetaillovlibre', helpdetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('helpDetailLibreCtrl', helpDetailLibreCtrl);

	helpdetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	helpDetailLibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','id','CustomProcessBonita' ,'DTColumnDefBuilder'];

	function helpdetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				
			},
			
			template: '  <a style="font-size:16px" ng-click="openPopUp()">Besoin de l\'aide cliquez ici?&nbsp;&nbsp;<span class="glyphicon glyphicon-question-sign" style="font-size: x-large;"></span> <spanclass="hidden-xs forme-button">  </span></a>',


			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function() {

					
					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/helpDetailTemplate.html',
							controller : 'helpDetailLibreCtrl',
							resolve: {
								
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function helpDetailLibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,id,CustomProcessBonita ,DTColumnDefBuilder) {
		$http.get('api/referentiels/getByCode/DECISIONREC').then(function(result) {
			$scope.listDecision = result.data;
		});
		
		
		$scope.traitement = function(reclamation) {
			
			$scope.reclamation = reclamation
			$scope.reclamation.idUser = iduser ;
			$scope.reclamation.id=idreclamation ;
			$scope.reclamation.note = reclamation.note ;
			$scope.reclamation.decision = reclamation.decision.id
			Agenda.updateReclamation($scope.reclamation).then(function (result) {
				$state.go('consult-all-reclamation', null, { reload: true } );
				$uibModalInstance.dismiss(true);
			});
			
		}
		$scope.chooseItem = function (result) {
			$uibModalInstance.close(result);
		}

				// when you clic "X" button
				$scope.closeLov = function() {
					$uibModalInstance.dismiss('cancel');
				};
			}
})();
