(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('SpecimenSignatureDetailController',SpecimenSignatureDetailController);
SpecimenSignatureDetailController.$inject=['$scope', '$rootScope', '$stateParams', 'entity','SpecimenSignature','PieceJointe','DemandeAdhesion'];
function SpecimenSignatureDetailController($scope, $rootScope, $stateParams, entity, SpecimenSignature, PieceJointe, DemandeAdhesion){
	$scope.specimenSignature = entity;
    $scope.load = function (id) {
        SpecimenSignature.get({id: id}, function(result) {
            $scope.specimenSignature = result;
        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:specimenSignatureUpdate', function(event, result) {
        $scope.specimenSignature = result;
    });
    $scope.$on('$destroy', unsubscribe);
}

})();

