(function() {
	'use strict';
	angular.module('app.dashboard', [ 'ui.router', 'ngResource' ]).config(
			stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('dashboard', {
			parent : 'site',
			url : '/dashboard',
			views : {
				"content@" : {
					controller : 'DashboardCtrl',
					templateUrl : 'scripts/app/dashboard/dashboard.html'
				}
			},
			data : {
				title : 'Dashboard'
			}
		}).state('app.dashboard-social', {
			url : '/dashboard-social',
			views : {
				"content@app" : {
					templateUrl : 'app/dashboard/social-wall.html'
				}
			},
			data : {
				title : 'Dashboard Social'
			}
		});
	}
})();
