(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('NumeroAttestationDetailController',NumeroAttestationDetailController);
NumeroAttestationDetailController.$inject=['$scope','$rootScope', '$stateParams', 'entity', 'NumeroAttestation'];
function NumeroAttestationDetailController($scope, $rootScope, $stateParams, entity, NumeroAttestation){
	$scope.numeroAttestation = entity;
    $scope.load = function (id) {
        NumeroAttestation.get({id: id}, function(result) {
            $scope.numeroAttestation = result;
        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:numeroAttestationUpdate', function(event, result) {
        $scope.numeroAttestation = result;
    });
    $scope.$on('$destroy', unsubscribe);
}

})();
