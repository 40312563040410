(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('produitdetaillovlibre', produitdetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('produitDetailLibreCtrl', produitDetailLibreCtrl);

	produitdetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	produitDetailLibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','CustomProcessBonita' ,'DTColumnDefBuilder','idproduit'];

	function produitdetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				idproduit:'@'
			},
			template: '<button style="background-color: transparent;width:20px;height:20px;border: none;" ng-click="openPopUp(idproduit)">' +

			'</button>',
			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(idproduit) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/produitDetailTemplate.html',
							controller : 'produitDetailLibreCtrl',
							resolve: {
								idproduit: function () {
									return idproduit;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function produitDetailLibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,CustomProcessBonita ,DTColumnDefBuilder,idproduit) {


		$http.get('api/produits/'+idproduit).then(function(result) {
			$scope.produit = result.data;
		});

		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
