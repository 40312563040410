(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeProcessController', TypeProcessController);

	TypeProcessController.$inject = ['$state','$filter','$resource','$scope', '$compile', '$translate','DTOptionsBuilder' , 'DTColumnBuilder', 'ReferentielWs', '$rootScope','$uibModalInstance'];

	function TypeProcessController ($state,$filter,$resource,$scope, $compile, $translate,DTOptionsBuilder , DTColumnBuilder, ReferentielWs, $rootScope,$uibModalInstance) {
    	$scope.dtInstance={};
    	$scope.records =0;
    	$scope.listCriterionDto = [] ;
    	$scope.dtOptions = DTOptionsBuilder
			.newOptions()
			.withOption('ajax',function(data, fnCallback, settings) {
								ReferentielWs.findReferentielByTypeProcess().then(function(result) {
									$scope.total = result.data.recordsTotal;
									fnCallback(result.data);
								});

					})
					.withOption('createdRow', createdRow)
					.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
					.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter(
						{'0' : {type : 'text'}
				});
    	$scope.dtColumns = [
    		DTColumnBuilder.newColumn('libelle').withTitle($translate.instant('Type des processus')).withOption('width', '30%')
		];

        $scope.close = function() {
            $uibModalInstance.dismiss('cancel');
        };
        function createdRow(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

    		$('td', nRow).bind('dblclick', function(){
    			$scope.chooseItem(aData);
    		});

    		$('td', nRow).bind('click', function() {
    			$scope.$apply(function() {
    				if($(nRow).hasClass('selected')){
    					$(nRow).removeClass('selected');
    					$scope.selected=null;
    					$scope.data=null;
    				}else{
    					if($scope.selected!=null)
    					{
    						$scope.selected.removeClass('selected');
    					}
    					$(nRow).addClass('selected');
    					$scope.data=aData;
    					$scope.selected=$(nRow);
    				}
    			});
    		});
    		return nRow;
    	}
        $scope.chooseItem = function (result){
        	$scope.$emit("itemChoosen", result);
        	$uibModalInstance.dismiss('cancel');
        }
}
})();
