(function() {
	'use strict';
	angular
	.module("lovwithId-directive", [])
	.directive('lovwithid', lovwithid)
	.directive('ngConfirmWithIdClick', ngConfirmWithIdClick)
	.controller('LovwithidCtrl', LovwithidCtrl)
	.filter('StartOnPage', StartOnPage);

	lovwithid.$inject = ['$uibModal'];
	ngConfirmWithIdClick.$inject = [];
	LovwithidCtrl.$inject = ['DTColumnBuilder', '$translate', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', 'model', 'Title', 'ColumnList', 'FieldList'];
	StartOnPage.$inject = [];


	function lovwithid ($uibModal) {
		return {
			restrict: 'A',
			scope: {
				model: '=',
				title: '=',
				callback: '&',
				columnlist: '=',
				fieldlist: '='
			},
			template: '<button type="button" class="lovStyle btn-grey btn  " ' +
			'ng-click="openPopUp(model, title, callback, columnlist, fieldlist)">' +
			' <span class="glyphicon glyphiconn glyphicon-search" ></span>' +
			'</button>',
			controller: ['$scope', '$element', function ($scope, $element) {

				$scope.openPopUp = function (model, title, callback, columnlist, fieldlist) {
					$scope.localPopUp = {
							backdrop: 'static',
							size: 'md',
							backdropClick: false,
							dialogFade: false,
							keyboard: false,
							templateUrl: 'app/directives/lovTemplate.html',
							controller: 'LovwithidCtrl',
							windowClass: '',
							resolve: {
								model: function () {
									return model;
								},
								Title: function () {
									return title;
								},
								ColumnList: function () {
									return columnlist;
								},
								FieldList: function () {
									return fieldlist;
								}
							}
					};

					var modalInstance = $uibModal.open($scope.localPopUp);

					modalInstance.result.then(function (selectedItem) {
						callback({ e: selectedItem });
					}, function () {
						//dismiss: nothing to do
					});
				}
			}]
		}
}

	function ngConfirmWithIdClick () {
        return {
            link: function (scope, element, attr) {
                var msg = attr.ngConfirmClick || "Are you sure?";
                var clickAction = attr.confirmedClick;
                element.bind('click',function (event) {
                    if ( window.confirm(msg) ) {
                        scope.$eval(clickAction)
                    }
                });
            }
        };
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		}
}
	
	
	function LovwithidCtrl (DTColumnBuilder,$translate, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, model, Title, ColumnList, FieldList) {

		$scope.ColumnList = ColumnList; //list of columns to show

		$scope.FieldList = FieldList; //list of fields to show
		$scope.model = model; //data we want to show 
		$scope.Title = Title; //title for the modal
		$scope.dtOptions = DTOptionsBuilder
		.fromFnPromise(function() {
			return $resource($scope.model).query().$promise; })
			.withOption('createdRow', createdRow)
			.withOption('order', [0, 'asc'])
			.withPaginationType('full_numbers')
			.withOption('order', [0, 'asc'])
			.withDOM("t" +
			"<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
			$scope.data=null;
		function createdRow(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
			// Unbind first in order to avoid any duplicate handler (see https://github.com/l-lin/angular-datatables/issues/87)
			//$('td', nRow).unbind('click');

			$('td', nRow).bind('dblclick', function(){
				$scope.chooseItem(aData);
			});

			$('td', nRow).bind('click', function() {
				$scope.$apply(function() {
					if($(nRow).hasClass('selected')){
						$(nRow).removeClass('selected');
						$scope.selected=null;
						$scope.data=null;
						//	$scope.dtInstance.rerender(); 
					}else{
						if($scope.selected!=null)
						{
							$scope.selected.removeClass('selected');
						}
						$(nRow).addClass('selected');
						$scope.data=aData;
						$scope.selected=$(nRow);
					}
				});
			});
			return nRow;
		}
		$scope.dtColumns=[];
		for (var iter = 0; iter < $scope.ColumnList.length; iter++) {
			if(iter==0){
			$scope.dtColumns.push(DTColumnBuilder.newColumn($scope.FieldList[iter]).withTitle($scope.ColumnList[iter]).notVisible());
			}else{
				$scope.dtColumns.push(DTColumnBuilder.newColumn($scope.FieldList[iter]).withTitle($scope.ColumnList[iter]));

			}
		}



		//when you click "choose" button
		$scope.chooseItem = function (result) {
			$uibModalInstance.close(result);
		}

		//when you clic "X" button
		$scope.closeLov = function () {
			$uibModalInstance.dismiss('cancel');
		};
}
})();












