(function() {
    'use strict';
    angular
        .module('infochamApp')
        .controller('RaisonController', RaisonController);
    RaisonController.$inject = ['$filter','$resource', '$scope', '$state', 'OeExpediteur', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder'];

    function RaisonController ($filter,$resource, $scope, $state,OeExpediteur , ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder) {
		$scope.agenceTypes = {};
		
		$scope.dtOptions = DTOptionsBuilder
		.newOptions()
		.withOption('ajax', {
			url: 'api/getAllExpDTO',
			type: 'GET',
		})
	.withOption('createdRow', createdRow)

	.withPaginationType('full_numbers')
	.withOption('order', [0, 'desc'])
	.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
	.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

		'0' : {
			type : 'text'
		},
		'1' : {
			type : 'text'
		},
		'2' : {
			type : 'text'
		},
		'3' : {
			type : 'text'
		},
		'4' : {
			type : 'text'
		},
		'5' : {
			type : 'text'
		},
		'6' : {
			type : 'text'
		},
		'7' : {
			type : 'text'
		},
		'8' : {
			type : 'text'
		},
		
		
	})

	$scope.dtColumns = [
		DTColumnBuilder.newColumn('id').withTitle('ID').notVisible(),   
	    DTColumnBuilder.newColumn('raisonSociale').withTitle('Raison Sociale').withOption('width', '20%'),
	    DTColumnBuilder.newColumn('numIde').withTitle('Identifiant Unique').withOption('width', '10%'),
		DTColumnBuilder.newColumn('adresseSiegeDTO.email').withTitle('Email').withOption('width', '10%'),	
		DTColumnBuilder.newColumn('responsableDTO.nom').withTitle('Nom Responsable').withOption('width', '10%'),	
		DTColumnBuilder.newColumn('responsableDTO.prenom').withTitle('Prénom Responsable').withOption('width', '10%'),	
		DTColumnBuilder.newColumn('secteurActiviteDTO.libelle').withTitle('Secteur activité').withOption('width', '10%'),
		DTColumnBuilder.newColumn('adresseSiegeDTO.tel').withTitle('Téléphone').withOption('width', '10%'),
		DTColumnBuilder.newColumn('adresseSiegeDTO.gouvernorat.libelle').withTitle('Gouvernorat').withOption('width', '10%'),
		DTColumnBuilder.newColumn('dateInscription').withTitle('Date Inscription').withOption('width', '20%').renderWith(function(data, type) {
			return $filter('date')(data, 'dd/MM/yyyy');
		}),	
		DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withClass('datatable-action-width')
		.renderWith(actionsHtml),
		];
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
			 if ( data.valide)
	      {
	          $('td', row).css('background-color', '#E2FAE2');
	      } else {
	    	  $('td', row).css('background-color', '#ffb5b5');
	      }
		}

		function actionsHtml(data, type, full, meta) {
			// $scope.agenceTypes[data.id] = data;
			return '<div class="action-center" style="margin-top:0px">' + ' <div class="col-md-1"><a ui-sref="raison.edit({id:' + data.id + '})">' +

			'   <i class="fa fa-pencil pencil-style"></i> ' + '</a></div>' + '<div class="col-md-1"><a ui-sref="raison.view({id:' + data.id + '})" )"="">' + '   <i class="glyphicon glyphicon-eye-open"></i> ' + '</a></div>'
		}
		$scope.dtInstance = {};

		$scope.clear = function() {

		};
	}
})();





