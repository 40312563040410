(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeExpediteurDialogController', OeExpediteurDialogController);

	OeExpediteurDialogController.$inject = ['Raison','$state','$rootScope','$scope', '$stateParams',  'entity', 'OeExpediteur', 'Referentiel', 'Adresse', 'Responsable'];

	function OeExpediteurDialogController (Raison,$state, $rootScope,$scope, $stateParams, entity, OeExpediteur, Referentiel, Adresse, Responsable) {
		
        $scope.oeExpediteur = entity;
        $scope.referentiels = Referentiel.query();
        $scope.adresses = Adresse.query();
        $scope.responsables = Responsable.query();
        $scope.load = function(id) {
            OeExpediteur.get({id : id}, function(result) {
                $scope.oeExpediteur = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:oeExpediteurUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

//        $scope.save = function () {
//            $scope.isSaving = true;
//            if ($scope.oeExpediteur.id != null) {
//                OeExpediteur.update($scope.oeExpediteur, onSaveSuccess, onSaveError);
//            } else {
//                OeExpediteur.save($scope.oeExpediteur, onSaveSuccess, onSaveError);
//            }
//        };
//        
        $scope.precedent=function() {

			$state.go('raison', null, {
				reload : true
			});

		}
        $scope.save = function () {
            if ($scope.oeExpediteur.id != null) {
                OeExpediteur.update($scope.oeExpediteur);
                
                
                Raison.updateOeExpediteur($scope.oeExpediteur,$stateParams.id).then(function (result){
                	   $scope.OeExpediteur = result.data;
                	  
                	   
                });
                
      	    

      		  
                $state.go('raison', null, {
   					reload : true
   				}); 
              
            } else {
                OeExpediteur.save($scope.oeExpediteur);
                $state.go('raison', null, {
					reload : true
				});
            }
        };
  	  $scope.lovModel2 = 'api/referentiels/secteur';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des secteurs d`activités';
		$scope.lovColumnList2 = [  'Libellé' ];
		$scope.lovFieldList2 = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.oeExpediteur.secteurActiviteDTO = result;
			$scope.$emit('eventSousSecteur',result)
		}
		
		 $scope.lovModel20 = 'api/referentiels/categories';// this will
			// be
		// the LOV
		// datasource
		$scope.lovTitle20= 'Liste des categories Juridique';
		$scope.lovColumnList20 = [ 'Libellé' ];
		$scope.lovFieldList20 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack20 = function(result) {
		$scope.oeExpediteur.categorieJuridiqueDTO = result;
		$scope.$emit('eventCategorieJuridique', result)
		}
		  
		  
			
		  $scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
			// the LOV
			// datasource
			$scope.lovTitle3 = 'Liste des régions';
			$scope.lovColumnList3 = [  'Libellé' ];
			$scope.lovFieldList3 = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBack3 = function(result) {
				$scope.oeExpediteur.adresseSiegeDTO.gouvernorat = result;
				$scope.$emit('eventLocaliteAdrSiege',result)
			}
			
//	  	  
	  	  $scope.lovModel7 = 'api/referentiels/getTypeOperateur';// this will be
//			// the LOV
//			// datasource
			$scope.lovTitle7 = 'Liste des Types Opérateurs économiques';
			$scope.lovColumnList7 = [  'Libellé' ];
		$scope.lovFieldList7 = [ 'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBack7 = function(result) {
				$scope.oeExpediteur.typeOperateurDTO = result;
		}
	  		
	 
	  		
			$rootScope.$on('eventSousSecteur', function(event,result) {
			  		 $scope.lovModel1 = 'api/referentiels/sousSecteur/'+result.codeRubrique;// this will be
//		// the LOV
//		// datasource
		$scope.lovTitle1 = 'Liste des sous Secteur';
		$scope.lovColumnList1 = [ 'Libellé' ];
		$scope.lovFieldList1 = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack1 = function(result) {
			$scope.oeExpediteur.sousSecteurDTO = result;
		}
    	});
	  	      

	  		
	  		
//	  		$rootScope.$on('eventLocaliteAdrSiege', function(event,result) {
//	  				  		 $scope.lovModel4 = 'api/referentiels/localite/'+result.codeRubrique;// this will be
////		  		// the LOV
////		  		// datasource
//		  		$scope.lovTitle4 = 'Liste des villes';
//		  		$scope.lovColumnList4 = [  'Libellé' ];
//		  		$scope.lovFieldList4 = [  'libelle' ];
//		  		// function to be called when you click "choose" button
//		  		$scope.lovCallBack4 = function(result) {
//		  			$scope.oeExpediteur.adresseSiegeDTO.localite = result;
//		  		}
//	  	       	});
	  	

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForDateRccm = {};

        $scope.datePickerForDateRccm.status = {
            opened: false
        };

        $scope.datePickerForDateRccmOpen = function($event) {
            $scope.datePickerForDateRccm.status.opened = true;
        };
        $scope.datePickerForDateAdhesion = {};

        $scope.datePickerForDateAdhesion.status = {
            opened: false
        };

        $scope.datePickerForDateAdhesionOpen = function($event) {
            $scope.datePickerForDateAdhesion.status.opened = true;
        };
}
})();
