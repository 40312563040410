(function() {
'use strict';
angular
	.module('infochamApp')
	.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('modelePj', {
        parent: 'entity',
        url: '/modeles',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.document.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/modelePj/modelePj.html',
                controller: 'ModelePJController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('document');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    .state('modelePj.detail', {
        parent: 'entity',
        url: '/modelePj/{id}',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.document.detail.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/modelePj/modelePj-detail.html',
                controller: 'ModelePJDialogController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('document');
                return $translate.refresh();
            }],
            entity: ['$stateParams', 'modelePj', function($stateParams, Personnel) {
                return Personnel.get({id : $stateParams.id});
            }]
        }
    })
    .state('modelePj.new', {
        parent: 'modelePj',
        url: '/new',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/modelePj/modelePj-add.html',
                controller: 'ModelePJDialogController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            nom: null,
                            prenom: null,
                            poste: null,
                            codeRubrique: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(result) {
                $state.go('modelePj', null, { reload: true });
            }, function() {
                $state.go('modelePj');
            })
        }]
    })
    .state('modelePj.edit', {
        parent: 'modelePj',
        url: '/{id}/edit',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/modelePj/modelePj-update.html',
                controller: 'ModelePJDialogController',
                size: 'lg',
                resolve: {
                    entity: ['ModelePj', function(ModelePj) {
                        return ModelePj.get({id : $stateParams.id});
                     
                    }]
                }
            }).result.then(function(result) {
                $state.go('modelePj', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    .state('modelePj.delete', {
        parent: 'modelePj',
        url: '/{id}/delete',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/modelePj/modelePj-delete-dialog.html',
                controller: 'ModelePjDeleteController',
                size: 'md',
                resolve: {
                    entity: ['ModelePj', function(ModelePj) {
                        return ModelePj.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('modelePj', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    });
}

})();
