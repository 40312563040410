(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ModelePJDialogController',ModelePJDialogController);
ModelePJDialogController.$inject=['$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ModelePj','Agenda','fileUpload'];
function ModelePJDialogController($translate, $scope, $stateParams, $uibModalInstance, entity,ModelePj,Agenda,fileUpload){
	$scope.modelePj = entity;
	$scope.imageUser=null;
	$scope.load = function(id) {
		ModelePj.get({
			id : id
		}, function(result) {
			$scope.modelePj = result;
		});
	};
	 $scope.$on('eventEmitedModele', function(event, data) {
        	 $scope.piece = data.message;
        	$scope.imageUser = data.image;
        });
	var onSaveSuccess = function(result) {
		$scope.$emit('infochamApp:ModelePjUpdate', result);
		var uploadUrl = "api/uploadModele/"+result.id;
		 var file =  $scope.piece ;
			if(typeof file !='undefined'){
				var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
						function(result) {
						});

		}
		$uibModalInstance.close(result);
		$scope.isSaving = false;
	};
	$scope.typeCod = {}
	var onSaveError = function(result) {
		$scope.isSaving = false;
		if (result.status == 500) {
			swal("Opération interrompue!", "Déja existant", "error");
		} else {
			swal("Opération interrompue!", "", "error");
		}
	};
	$scope.save = function() {
		$scope.isSaving = true;
		if ($scope.modelePj.id != null) {
			ModelePj.update($scope.modelePj, onSaveSuccess, onSaveError);
		} else {
			ModelePj.save($scope.modelePj, onSaveSuccess, onSaveError)
			
		}
	};
	

	$scope.downloadPiecee = function(docId) {
	    if(docId != undefined){
		var downloadLink = document.createElement("a");

		document.body.appendChild(downloadLink);
		downloadLink.style = "display: none";
		Inscription2
		.downloadDocument(docId)
		.then(
		function(result) {
		   	if(angular.equals( { status: result.status }, { status: 500 } )){
		swal(message,"Un problème s'est produit!", "error")
		}
		var fName = result
		.headers('Content-Disposition');
		var file = new Blob(
		[ result.data ],
		{
		type : 'application/pdf'
		});
		var fileURL = (window.URL || window.webkitURL)
		.createObjectURL(file);
		downloadLink.href = fileURL;
		downloadLink.download = fName;
		downloadLink.click();
		});
	    }
		} 
	$scope.clear = function() {
		$uibModalInstance.dismiss('cancel');
	};
}

})();
