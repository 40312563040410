(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ParamPaiementDeleteController',ParamPaiementDeleteController);
ParamPaiementDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'ParamPaiement'];
function ParamPaiementDeleteController($scope, $uibModalInstance, entity, ParamPaiement){
	$scope.ParamPaiement = entity;
    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.confirmDelete = function (id) {
    	ParamPaiement.delete({id: id},
            function () {
                $uibModalInstance.close(true);
            });
    };
}

})();
