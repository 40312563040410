(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ModelePJController',ModelePJController);
ModelePJController.$inject=['Inscription2','$filter', '$resource', '$scope', '$state','ParseLinks', '$rootScope', '$compile', '$translate','DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService'];
function ModelePJController(Inscription2,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService){
	$scope.agenceTypes = {};
	
	function actionsHtml(data, type, full, meta) {
		return '<div class="action-center" style="margin-top:0px">' + ' <div class="col-md-1"><a ui-sref="modelePj.edit({id:' + data.id + '})">' +
		'   <i class="fa fa-pencil pencil-style"></i> ' + '</a></div>' + '<div class="col-md-1"><a ui-sref="modelePj.delete({id:' + data.id + '})" )"="">' + '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> ' + '</a></div>'
	}
	function actionsHtml1(data, type, full, meta) {
//		return '<img style="height: 90px;width: 145px;" ng-src="data:image/JPEG;base64,'+data.signature+'" alt="Pas de photo" />'
		return '<strong> <a classe="glyphicon glyphicon-file" ng-click="downloadPiecee('+data.piece.id+')">	'+data.piece.nom+'</a></strong>'
	}
	$scope.dtOptions = DTOptionsBuilder
	.newOptions()
	.withOption('ajax', {
		url: 'api/findAllModelePj',
		type: 'GET',
	})
.withOption('createdRow', createdRow)

.withPaginationType('full_numbers')
.withOption('order', [0, 'asc'])
.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

	'0' : {
		type : 'text'
	},
	'1' : {
		type : 'text'
	},
	'2' : {
		type : 'text'
	},
	'3' : {
		type : 'text'
	},

})

$scope.dtColumns = [
                    
    DTColumnBuilder.newColumn('type').withTitle('Type').withOption('width', '20%'),	
    DTColumnBuilder.newColumn('code').withTitle('Code').withOption('width', '20%'),
    DTColumnBuilder.newColumn('libelle').withTitle('Libelle').withOption('width', '20%'),
    DTColumnBuilder.newColumn('libelleArabe').withTitle('Libelle Arabe').withOption('width', '20%'),
    DTColumnBuilder.newColumn(null).withTitle('Pièce Jointe').notSortable().withClass('datatable-action-width')
	.renderWith(actionsHtml1),
	DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withClass('datatable-action-width')
	.renderWith(actionsHtml),
	];
$scope.search=0;
function createdRow(row, data, dataIndex) {
$compile(angular.element(row).contents())($scope);
}
	$scope.dtInstance = {};

	$scope.clear = function() {

	};
	
	$scope.downloadPiecee = function(docId) {
	    if(docId != undefined){
		var downloadLink = document.createElement("a");

		document.body.appendChild(downloadLink);
		downloadLink.style = "display: none";
		Inscription2
		.downloadDocument(docId)
		.then(
		function(result) {
		   	if(angular.equals( { status: result.status }, { status: 500 } )){
		swal(message,"Un problème s'est produit!", "error")
		}
		var fName = result
		.headers('Content-Disposition');
		var file = new Blob(
		[ result.data ],
		{
		type : 'application/pdf'
		});
		var fileURL = (window.URL || window.webkitURL)
		.createObjectURL(file);
		downloadLink.href = fileURL;
		downloadLink.download = fName;
		downloadLink.click();
		});
	    }
		} 
}



})();

