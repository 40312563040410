(function() {
    'use strict';

    angular
        .module('lov-directive')
        .directive('notification', notification)
        .controller('NotifCtrl', NotifCtrl);

    notification.$inject = ['$uibModal'];
    NotifCtrl.$inject = ['$scope','$uibModalInstance','fileUpload','codeprocess','$http'];

    function notification ($uibModal) {
    	return {
    		restrict: 'A',
    		scope: {
    			callback : '&',
    			codeprocess : '@'

    		},
    		template: '<span class="box-container-direct" ng-click="openPopUp(callback,codeprocess)"></span>',
    		controller: ['$scope', '$element', function ($scope, $element) {

    			$scope.openPopUp = function (callback,codeprocess) {
    				$scope.localPopUp = {
    						size: 'lm',
    						templateUrl: 'app/directives/notification/notification.html',
    						controller: 'NotifCtrl',
    						resolve: {
    							codeprocess: function () {
    								return codeprocess;
    							},
    						}
    				};
    				var modalInstance = $uibModal.open($scope.localPopUp);
    				modalInstance.result.then(function(selectedItem) {
    					callback({
    						result : selectedItem
    					});
    				}, function() {
    					// dismiss: nothing to do
    				});
    			}
    		}]
    	}
}
    
    function NotifCtrl ($scope,$uibModalInstance,fileUpload,codeprocess,$http) {

    	
    	$scope.motif = {};
    	$scope.motif.motifRaison = {};

    	if (codeprocess == "VALID_VAL1" || codeprocess == "VALID_VAL2") {
    		$http.get('api/referentiels/getByCode/MOTIFACCEPT').then(function(result) {
    			$scope.listMotif = result.data;
    		});
    	} else if (codeprocess == "REF_VAL1" || codeprocess == "REF_VAL2") {
    		$http.get('api/referentiels/getByCode/MOTIFREFUS').then(function(result) {
    			$scope.listMotif = result.data;
    		});
    	}
    	
    	
    	$scope.traitement = function() {
    		$scope.motif.codeProcess = codeprocess;
    		$scope.chooseItem($scope.motif);
    		
    		
    	}
    	
    	//when you click "choose" button
    	$scope.chooseItem = function (result) {
    		$uibModalInstance.close(result);
    	}

    	
    	
    	
    	
    	$scope.Notif = function (){
    		$uibModalInstance.close($scope.idagenda);
    	}
    	$scope.closeLov = function () {
    		$uibModalInstance.dismiss('close');
    	};
    	
    	

}
})();

