(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeDestinataireDetailController', OeDestinataireDetailController);

	OeDestinataireDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OeDestinataire'];

	function OeDestinataireDetailController ($scope, $rootScope, $stateParams, entity, OeDestinataire) {
        $scope.oeDestinataire = entity;
        $scope.load = function (id) {
            OeDestinataire.get({id: id}, function(result) {
                $scope.oeDestinataire = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:oeDestinataireUpdate', function(event, result) {
            $scope.oeDestinataire = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
