(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('RapportActiviteDetailController',RapportActiviteDetailController);
RapportActiviteDetailController.$inject=['Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'calendarConfig', 'moment', 'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];
function RapportActiviteDetailController(Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile, calendarConfig, moment, AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder){
	$scope.inscription={}
	$scope.dtInstance={};
	$scope.dtInstance1={};
	$scope.dtInstance2={};
	$scope.dtInstance3={};
	$scope.dtInstance4={};
	var vm = this;
	
	 Agenda.getNbreDossTraitByUser(0,0).then(function (result) {
		 $scope.nbreDossTraitByUser = result.data;
 });
	 Agenda.getAllInscriptionByDate().then(function (result) {
		 $scope.inscriptionByDateList = result.data;
 });
	 Agenda.getAllDDUByDate().then(function (result) {
		 $scope.DDUByDateList = result.data;
 });
	 
	 Agenda.getAllCOByDate().then(function (result) {
		 $scope.COByDateList = result.data;
 });
	 
	 Agenda.getAllAttestationByDate().then(function (result) {
		 $scope.attestationByDateList = result.data;
 });
	 
	 Agenda.getAllAttestationAdhesionByDate().then(function (result) {
		 $scope.attestationAdhesionByDateList = result.data;
 });	 
	 
	 
	Principal.identity().then(function(account) {
		$scope.account = account;
		if ($scope.account == null) {
			$state.go('login');
		}
		Inscription2.getUserByLogin(account.login).then(function(result) {
			$scope.user = result.data;
		});
		
	});
	
	 $scope.valider= function(du,au){
		 var dStart="";
		 var dFin="";
		 if(du == undefined || du ==  null){
			 dStart=-1;
		 }
		 if(au == undefined || au == null){
			 dFin=-1;
		 }
		
		 $scope.inscriptionByDateList=[];
		 if(dStart != -1){
		 dStart = moment(du).format('YYYY-MM-DD');
		 }
		 if(dFin != -1){
			 dFin = moment(au).format('YYYY-MM-DD');
		 }
		 Agenda.getAllInscriptionByPeriod(dStart,dFin).then(function (result) {
			 $scope.inscriptionByDateList = result.data;
			
			 
	 });
		 Agenda.getAllAttestationByPeriod(dStart,dFin).then(function (result) {
			 $scope.attestationByDateList = result.data;
			
			 
	 });
		 
		 Agenda.getAllAttestationAdhesionByPeriod(dStart,dFin).then(function (result) {
			 $scope.attestationAdhesionByDateList = result.data;
			
			 
	 });
		 
		 Agenda.getAllDDUByPeriod(dStart,dFin).then(function (result) {
			 $scope.DDUByDateList = result.data;
			
			 
	 });
		 Agenda.getAllCOByPeriod(dStart,dFin).then(function (result) {
			 $scope.COByDateList = result.data;
			
			 
	 });
		 
		 
		 
		 
		 
		 
	 }


	 $scope.dtOptionsConsolide = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		
//.withOption('order',[ 0, 'desc' ] )
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withButtons([

{
	extend: "excel",
	text:'Excel',
	className:'table-margin buttons-html5 btn red btn-outline',
	title:"Inscription et Adhésion",

	exportOptions: {
        columns: ':visible',
	
	},
	//CharSet: "utf8",
	exportData: { decodeEntities: true }
},
{
	extend: "pdf",
	text:'PDF',
	orientation: 'landscape',
	className:'table-margin buttons-html5 btn yellow btn-outline ',
	title:"Inscription et Adhésion",
	exportOptions: {
		columns: ':visible'
	}
},
{
	extend: 'print',
	text:'Imprimer',
	className:'table-margin buttons-html5 btn green btn-outline',
	title:"Inscription et Adhésion",
	//text: 'Print current page',
	autoPrint: true,
	exportOptions: {
		columns: ':visible'
	}
},

])
			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
			$scope.dtColumnsConsolide = [
				DTColumnDefBuilder.newColumnDef(0).withTitle("Raison sociale").withOption('width', '15%'),
				DTColumnDefBuilder.newColumnDef(1).withTitle("Nom Premier Responsable").withOption('width', '15%'),
				DTColumnDefBuilder.newColumnDef(2).withTitle("Type Opérateur Economique").withOption('width', '15%'),
				DTColumnDefBuilder.newColumnDef(3).withTitle("Secteur d'activité").withOption('width', '25%'),
				DTColumnDefBuilder.newColumnDef(4).withTitle("Activité").withOption('width', '10%'),
				DTColumnDefBuilder.newColumnDef(5).withTitle("Statut").withOption('width', '20%'),

				];

	 
	 
	 $scope.dtOptionsConsolideDDU = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		
//.withOption('order',[ 0, 'desc' ] )
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withButtons([
{
	extend: "excel",
	text:'Excel',
	className:'table-margin buttons-html5 btn red btn-outline',
	title:"DDU",

	exportOptions: {
        columns: ':visible',
	},
	//CharSet: "utf8",
	exportData: { decodeEntities: true }
},
{
	extend: "pdf",
	text:'PDF',
	orientation: 'landscape',
	className:'table-margin buttons-html5 btn yellow btn-outline ',
	title:"DDU",
	exportOptions: {
		columns: ':visible'
	}
},
{
	extend: 'print',
	text:'Imprimer',
	className:'table-margin buttons-html5 btn green btn-outline',
	title:"DDU",
	//text: 'Print current page',
	autoPrint: true,
	exportOptions: {
		columns: ':visible'
	}
},
])
	.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
	 				.withOption('fnFooterCallback',
    						function(nRow, aasData, iStart, iEnd, aiDisplay) {

    					var columnas = [3,4]; //the columns you wish to add
    					var secondRow = $(nRow).next()[0];
    					$scope.totalSomme = [];
    					for (var j in columnas) {
    					
    						var columnaActual = columnas[j];
    						var totalFiltre = 0;
    						var devise = " ";
    						var total = 0;
    						for (var i = 0; i < aiDisplay.length; i++) {

    							totalFiltre = totalFiltre + parseFloat(aasData[aiDisplay[i]][columnaActual]);
    						}
    						for (var i = 0; i < aasData.length; i++) {
    							total = total + parseFloat(aasData[i][columnaActual]);
    						}
//    						$($(nRow).children().get(columnaActual)).html(total.toString());
    						$($(nRow).children().get(columnaActual)).html(total.toString());
    						$scope.totalSomme.push(total)
    					} // end


    				})
		$scope.dtColumnsConsolideDDU = [
		DTColumnDefBuilder.newColumnDef(0).withTitle("Raison sociale").withOption('width', '15%'),
		DTColumnDefBuilder.newColumnDef(1).withTitle("Secteur d'activité").withOption('width', '15%'),
		DTColumnDefBuilder.newColumnDef(2).withTitle("Activité").withOption('width', '15%'),
		DTColumnDefBuilder.newColumnDef(3).withTitle("Nombre de copies").withOption('width', '10%').withOption('text-right'),
		DTColumnDefBuilder.newColumnDef(4).withTitle("Montant payé").withOption('width', '10%').withOption( 'text-right'),
		DTColumnDefBuilder.newColumnDef(5).withTitle("Statut").withOption('width', '20%'),
		];
	
	
	 
	 $scope.dtOptionsConsolideCO = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		
//.withOption('order',[ 0, 'desc' ] )
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withButtons([
			              
{
	extend: "excel",
	text:'Excel',
	className:'table-margin buttons-html5 btn red btn-outline',
	title:"Certificat d'Origine",

	exportOptions: {
        columns: ':visible',
//        columns: [ 0, 1,2,3,4,5,6,7,8,9,10,11],format: {
//        	body : function(data, row, column, node) {
//        		if ( column===0){
//        			data = data.substring(data.indexOf(">")+1,data.indexOf("</div>"));
//        		}
//				// Les colonnes de type montants
//				var j = [ 1,2,3,4,5,6,7,8,9,10,11] 
//				//-1 : there is no column that contains a directive
//				return j.indexOf(column) >= 0 ? ReportingService.traitMontantExcel(data,column,0) : data;
//			}
//        }
    
	
	},
	//CharSet: "utf8",
	exportData: { decodeEntities: true }
},
{
	extend: "pdf",
	text:'PDF',
	orientation: 'landscape',
	className:'table-margin buttons-html5 btn yellow btn-outline ',
	title:"Certificat d'Origine",
	exportOptions: {
		columns: ':visible'
	}
},
{
	extend: 'print',
	text:'Imprimer',
	className:'table-margin buttons-html5 btn green btn-outline',
	title:"Certificat d'Origine",
	//text: 'Print current page',
	autoPrint: true,
	exportOptions: {
		columns: ':visible'
	}
},
])
	.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
	 
		$scope.dtColumnsConsolideCO = [
		DTColumnDefBuilder.newColumnDef(0).withTitle("Raison sociale").withOption('width', '15%'),
		DTColumnDefBuilder.newColumnDef(1).withTitle("Secteur d'activité").withOption('width', '20%'),
		DTColumnDefBuilder.newColumnDef(2).withTitle("Activité").withOption('width', '25%'),
		DTColumnDefBuilder.newColumnDef(3).withTitle("Type Certificat d'Origine").withOption('width', '20%'),
		DTColumnDefBuilder.newColumnDef(4).withTitle("Statut").withOption('width', '20%'),
		];
	 
	 
	 $scope.dtOptionsConsolideAttestation = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		
//.withOption('order',[ 0, 'desc' ] )
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withButtons([
			              
{
	extend: "excel",
	text:'Excel',
	className:'table-margin buttons-html5 btn red btn-outline',
	title:"Certificat d'Origine",

	exportOptions: {
        columns: ':visible',
	},
	//CharSet: "utf8",
	exportData: { decodeEntities: true }
},
{
	extend: "pdf",
	text:'PDF',
	orientation: 'landscape',
	className:'table-margin buttons-html5 btn yellow btn-outline ',
	title:"Attestation d'Origine",
	exportOptions: {
		columns: ':visible'
	}
},
{
	extend: 'print',
	text:'Imprimer',
	className:'table-margin buttons-html5 btn green btn-outline',
	title:"Attestation d'Origine",
	//text: 'Print current page',
	autoPrint: true,
	exportOptions: {
		columns: ':visible'
	}
},
])
	.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
	 
		$scope.dtColumnsConsolideAttestation = [
		DTColumnDefBuilder.newColumnDef(0).withTitle("Raison sociale").withOption('width', '15%'),
		DTColumnDefBuilder.newColumnDef(1).withTitle("Secteur d'activité").withOption('width', '20%'),
		DTColumnDefBuilder.newColumnDef(2).withTitle("Activité").withOption('width', '25%'),
		DTColumnDefBuilder.newColumnDef(3).withTitle("Destinataire").withOption('width', '20%'),
		DTColumnDefBuilder.newColumnDef(4).withTitle("Statut").withOption('width', '20%'),
		];
	 
	 
	 
	 
	 $scope.dtOptionsConsolideAttestationAdhesion = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withButtons([
			              
{
	extend: "excel",
	text:'Excel',
	className:'table-margin buttons-html5 btn red btn-outline',
	title:"Certificat d'Origine",

	exportOptions: {
     columns: ':visible',
	},
	//CharSet: "utf8",
	exportData: { decodeEntities: true }
},
{
	extend: "pdf",
	text:'PDF',
	orientation: 'landscape',
	className:'table-margin buttons-html5 btn yellow btn-outline ',
	title:"Attestation d'adhesion",
	exportOptions: {
		columns: ':visible'
	}
},
{
	extend: 'print',
	text:'Imprimer',
	className:'table-margin buttons-html5 btn green btn-outline',
	title:"Attestation d'adhesion",
	//text: 'Print current page',
	autoPrint: true,
	exportOptions: {
		columns: ':visible'
	}
},
])
	.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
	 
		$scope.dtColumnsConsolideAttestationAdhesion = [
		DTColumnDefBuilder.newColumnDef(0).withTitle("Raison sociale").withOption('width', '15%'),
		DTColumnDefBuilder.newColumnDef(1).withTitle("Nom premier responsable").withOption('width', '20%'),
		DTColumnDefBuilder.newColumnDef(2).withTitle("RCCM").withOption('width', '25%'),
		DTColumnDefBuilder.newColumnDef(3).withTitle("Numéro carte ressortissant").withOption('width', '20%'),
		DTColumnDefBuilder.newColumnDef(4).withTitle("Statut").withOption('width', '20%'),
		];
	 
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}
		$scope.reset = function() {
			$state.go('rapportActivite-detail', null, {
				reload : true
			});

		}

	$scope.dtInstance = {};
	$rootScope.$on('reeload', function(event) {
		$scope.dtInstance._renderer.rerender();
		$scope.$emit('reeloadD');
	});
	$scope.myAccordionStatut = true;
	$scope.opened = function() {
		if ($scope.myAccordionStatut) {
			return "#collapse1";
		}
	}

	$(function() {
		var active = true;

		$('#collapse-init').click(
				function() {
					if (active) {
						active = false;
						$('.panel-collapse').collapse('show');
						$('.panel-title').attr('data-toggle',
								'');
						$(this).text(
								'Enable accordion behavior');
					} else {
						active = true;
						$('.panel-collapse').collapse('show');
						$('.panel-title').attr('data-toggle',
								'collapse');
						$(this).text(
								'Disable accordion behavior');
					}
				});

		$('#myAccordion').on('show.bs.collapse', function() {
			if (active) {
				$('#myAccordion1 .in').collapse('show');
			}
		});
		$('#myAccordion').on('show.bs.collapse', function() {
		});

		$('#myAccordion1').on('show.bs.collapse', function() {
			$scope.myAccordionStatut = false;
			if (active) {
				$('#myAccordion .in').collapse('show');
			}
		});
		$('#myAccordion1').on('hide.bs.collapse', function() {
			$scope.myAccordionStatut = true;
		});

	});
	$('#myAccordion .panel-collapse').on(
			'shown.bs.collapse',
			function() {
				$(this).prev().find(".a-callapseStyle ")
						.removeClass("fa fa-plus").addClass(
								"fa fa-minus");
			});

	// The reverse of the above on hidden event:

	$('#myAccordion .panel-collapse').on(
			'hidden.bs.collapse',
			function() {
				$(this).prev().find(".a-callapseStyle ")
						.removeClass("fa fa-minus").addClass(
								"fa fa-plus");
				// $scope.statut=false;
			});
	$('#myAccordion1 .panel-collapse').on(
			'shown.bs.collapse',
			function() {
				$(this).prev().find(".a-callapseStyle ")
						.removeClass("fa fa-plus").addClass(
								"fa fa-minus");
			});

	// The reverse of the above on hidden event:

	$('#myAccordion1 .panel-collapse').on(
			'hidden.bs.collapse',
			function() {
				$(this).prev().find(".a-callapseStyle ")
						.removeClass("fa fa-minus").addClass(
								"fa fa-plus");
				// $scope.statut=false;
			});

  		
		$scope.tab=0;
		$scope.selectTab = function (res){
			if(res==0){
			}else if(res==1){
			}
			$scope.tab=res;
		}
		$scope.isSelected = function(setTab){
			return setTab==$scope.tab;
		}
	  
		
		
		
		
		
		//******************************************************************************************************//

	        		 $scope.droit=     function droit(idUser) {
	        			 if(idUser != undefined){
	        			$state.go('rapportActivite-detail.droit', {id:idUser}, { reload: false } );
	        			 }
	        		       }
	        		
	        		function actionsDroit(data, type, full, meta) {
	            		return '<a ui-sref="rapportActivite-detail.droit({id:'+data.user.id+'})" class="badge badge-danger bg-dangerStyle" >'+data.user.authorities.length+'</a>'
	            	}
	        		

	        		
	        		 $scope.validerSuivi= function(du,au){
	        			 var dStart="";
	        			 var dFin="";
	        			 if(du == undefined || du ==  null){
	        				 dStart=-1;
	        			 }
	        			 if(au == undefined || au == null){
	        				 dFin=-1;
	        			 }
	        			
	        			 if(dStart != -1){
	        			 dStart = moment(du).format('YYYY-MM-DD');
	        			 }
	        			 if(dFin != -1){
	        				 dFin = moment(au).format('YYYY-MM-DD');
	        			 }
	        			 
	        				 Agenda.getNbreDossTraitByUser(dStart,dFin).then(function (result) {
	    	        			 $scope.nbreDossTraitByUser = result.data;
	        			 });
	        		 }

	        		 $scope.dtOptionsConsolideNbreDossByUser = DTOptionsBuilder
	        			.newOptions()
	        			// or here
	        			.withOption('createdRow', createdRow)
	        			.withPaginationType('full_numbers')
	        			
	        	//.withOption('order',[ 0, 'desc' ] )
	        			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
	        				.withButtons([
	        	])
	        		.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
	        		 
	        			$scope.dtColumnsConsolideNbreDossByUse = [
	        			DTColumnDefBuilder.newColumnDef(0).withTitle("Nom du responsable").withOption('width', '15%'),
	        			DTColumnDefBuilder.newColumnDef(1).withTitle("Prénom du responsable").withOption('width', '15%'),
	        			DTColumnDefBuilder.newColumnDef(2).withTitle("Rôle").withOption('width', '5%'),
	        			DTColumnDefBuilder.newColumnDef(3).withTitle("Inscription et Adhésion").withOption('width', '15%'),
	        			DTColumnDefBuilder.newColumnDef(4).withTitle("DDU").withOption('width', '10%'),
	        			DTColumnDefBuilder.newColumnDef(5).withTitle("Certificat d'origine").withOption('width', '15%'),
	        			DTColumnDefBuilder.newColumnDef(6).withTitle("Attestation d'origine").withOption('width', '15%'),
	        			DTColumnDefBuilder.newColumnDef(7).withTitle("Attestation d'adhésion").withOption('width', '15%'),

	        			];
}

})();
