(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ParamPaiementDialogController',ParamPaiementDialogController);
ParamPaiementDialogController.$inject=['$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ParamPaiement'];
function ParamPaiementDialogController($translate, $scope, $stateParams, $uibModalInstance, entity,ParamPaiement){
	$scope.ParamPaiement = entity;
	$scope.load = function(id) {
		ParamPaiement.get({
			id : id
		}, function(result) {
			$scope.paramPaiement = result;
		});
	};
	var onSaveSuccess = function(result) {
		$scope.$emit('infochamApp:ParamPaiementUpdate', result);
		$uibModalInstance.close(result);
		$scope.isSaving = false;
	};
	$scope.typeCod = {}
	var onSaveError = function(result) {
		$scope.isSaving = false;
		if (result.status == 500) {
			swal("Opération interrompue!", "Déja existant", "error");
		} else {
			swal("Opération interrompue!", "", "error");
		}
	};
	$scope.save = function() {
		$scope.isSaving = true;

		if ($scope.ParamPaiement.id != null) {
			ParamPaiement.update($scope.ParamPaiement, onSaveSuccess, onSaveError);
		} else {
			ParamPaiement.save($scope.ParamPaiement, onSaveSuccess, onSaveError);
		}
	};
	$scope.clear = function() {
		$uibModalInstance.dismiss('cancel');
	};	
}

})();