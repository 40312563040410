(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ParamMobileDeleteController',ParamMobileDeleteController);
ParamMobileDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'ParamMobile'];
function ParamMobileDeleteController($scope, $uibModalInstance, entity, ParamMobile){
	$scope.ParamMobile = entity;
    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.confirmDelete = function (id) {
    	ParamMobile.delete({id: id},
            function () {
                $uibModalInstance.close(true);
            });
    };	
}

})();
