(function() {
	'use strict';
	angular.module('infochamApp').factory('fileUpload', fileUpload);

	fileUpload.$inject = [ '$http', '$rootScope' ];

	function fileUpload($http, $rootScope) {
		return {

			'uploadFileToUrl' : function(file, uploadUrl) {

				var fd = new FormData();
				fd.append('file', file);

				return $http.post(uploadUrl, fd, {
					transformRequest : angular.identity,
					headers : {
						'Content-Type' : undefined
					}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});

			},

			'uploadFileToUrl1' : function(file1, uploadUrl) {

				var fd = new FormData();
				fd.append('file1', file1);

				return $http.post(uploadUrl, fd, {
					transformRequest : angular.identity,
					headers : {
						'Content-Type' : undefined
					}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});

			},
		}

	}
})();
