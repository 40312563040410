(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ReferentielDeleteController', ReferentielDeleteController);

	ReferentielDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Referentiel'];

	function ReferentielDeleteController ($scope, $uibModalInstance, entity, Referentiel) {

        $scope.referentiel = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Referentiel.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
