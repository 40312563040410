(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('password', {
			parent : 'account',
			url : '/password',
			data : {
				pageTitle : 'global.menu.account.password'
			},
			views : {
				'content@' : {
					templateUrl : 'app/account/password/password.html',
					controller : 'PasswordController'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate',
						'$translatePartialLoader',
						function($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('password');
							$translatePartialLoader.addPart('Account');

							return $translate.refresh();
						} ]
			}
		});
	}
})();
