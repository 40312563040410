(function() {
    'use strict';

    angular
        .module('lov-directive')
        .directive('reaffectation', reaffectation)
        .controller('ReaffectationCtrl', ReaffectationCtrl);

    reaffectation.$inject = ['$uibModal'];
    ReaffectationCtrl.$inject = ['$scope','$uibModalInstance','fileUpload','codeprocess','$http'];

    function reaffectation ($uibModal) {
    	return {
    		restrict: 'A',
    		scope: {
    			callback : '&',
    			codeprocess : '@'

    		},
    		template: '<span class="box-container-direct" ng-click="openPopUp(callback,codeprocess)"></span>',
    		controller: ['$scope', '$element', function ($scope, $element) {

    			$scope.openPopUp = function (callback,codeprocess) {
    				$scope.localPopUp = {
    						size: 'lm',
    						templateUrl: 'app/directives/reaffectation/reaffectation.html',
    						controller: 'ReaffectationCtrl',
    						resolve: {
    							codeprocess: function () {
    								return codeprocess;
    							},
    						}
    				};
    				var modalInstance = $uibModal.open($scope.localPopUp);
    				modalInstance.result.then(function(selectedItem) {
    					callback({
    						result : selectedItem
    					});
    				}, function() {
    					// dismiss: nothing to do
    				});
    			}
    		}]
    	}
}
    
    function ReaffectationCtrl ($scope,$uibModalInstance,fileUpload,codeprocess,$http) {

    	
    	$scope.demandeAutorisation = {};
    	$scope.demandeAutorisation.ministere = {};


    	$scope.lovModel5 = 'api/referentiels/getByCode/MINISTERE';// this will be
    	// the LOV
    	// datasource
    	$scope.lovTitle5 = 'Liste des ministères';
    	$scope.lovColumnList5 = [  'Libellé' ];
    	$scope.lovFieldList5 = [  'libelleFr' ];
    	// function to be called when you click "choose" button
    	$scope.lovCallBack5 = function(result) {
    		$scope.demandeAutorisation.ministere = result;

    	}
    	
    	
    	$scope.traitement = function() {
    		$scope.demandeAutorisation.codeProcess = codeprocess;
    		$scope.chooseItem($scope.demandeAutorisation);
    		
    		
    	}
    	
    	//when you click "choose" button
    	$scope.chooseItem = function (result) {
    		$uibModalInstance.close(result);
    	}

    	
    	
    	
    	
    	$scope.Notif = function (){
    		$uibModalInstance.close($scope.idagenda);
    	}
    	$scope.closeLov = function () {
    		$uibModalInstance.dismiss('close');
    	};
    	
    	

}
})();


