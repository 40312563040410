(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'banque',
						{
							parent : 'entity',
							url : '/banques',
							data : {
								authorities : [ 'ROLE_USER',
										'ROLE_ADVANCEDUSER', 'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.referentiel.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/banque/banque.html',
									controller : 'BanqueController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('referentiel');
											$translatePartialLoader
													.addPart('banque');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'banque.detail',
						{
							parent : 'entity',
							url : '/banque/{id}',
							data : {
								authorities : [ 'ROLE_ADVANCEDUSER',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.referentiel.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/banque/banque-detail.html',
									controller : 'BanqueDetailController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('referentiel');
											$translatePartialLoader
													.addPart('banque');
											return $translate.refresh();
										} ],
								entity : [ '$stateParams', 'Banque',
										function($stateParams, Banque) {
											return Banque.get({
												id : $stateParams.id
											});
										} ]
							}
						})
				.state(
						'banque.new',
						{
							parent : 'banque',
							url : '/new',
							data : {
								authorities : [ 'ROLE_ADVANCEDUSER',
										'ROLE_ADMIN' ],

							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/banque/banque-add.html',
													controller : 'BanqueDialogController',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																codeType : null,
																codeRubrique : null,
																libelle : null,
																valeur1 : null,
																valeur2 : null,
																status : null,
																codeListeMere : null,
																id : null
															};
														}
													}
												}).result.then(
												function(result) {
													$state.go('banque', null, {
														reload : true
													});
												}, function() {
													$state.go('banque');
												})
									} ]
						})
				.state(
						'banque.edit',
						{
							parent : 'banque',
							url : '/{id}/edit',
							data : {
								authorities : [ 'ROLE_ADVANCEDUSER',
										'ROLE_ADMIN' ],

							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/banque/banque-update.html',
													controller : 'BanqueDialogController',
													size : 'lg',
													resolve : {
														entity : [
																'Banque',
																function(Banque) {
																	return Banque
																			.get({
																				id : $stateParams.id
																			});

																} ]
													}
												}).result.then(
												function(result) {
													$state.go('banque', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						})
				.state(
						'banque.delete',
						{
							parent : 'banque',
							url : '/{id}/delete',
							data : {
								authorities : [ 'ROLE_ADVANCEDUSER',
										'ROLE_ADMIN' ],

							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/banque/banque-delete-dialog.html',
													controller : 'BanqueDeleteController',
													size : 'md',
													resolve : {
														entity : [
																'Banque',
																function(Banque) {
																	return Banque
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('banque', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						});
	}
})();
