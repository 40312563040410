(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
		.state(
				'paiementEnLigne',
				{
					parent : 'entity',
					url : '/paiementEnLigneConfig',
					data : {
						authorities : [ 'ROLE_USER',
							'ROLE_ADVANCEDUSER', 'ROLE_ADMIN' ],
							pageTitle : 'infochamApp.referentiel.home.title'
					},
					views : {
						'content@' : {
							templateUrl : 'app/entities/paiementEnLigne/paiementEnLigne.html',
							controller : 'PaiementEnLigne'
						}
					},
					resolve : {
						translatePartialLoader : [
							'$translate',
							'$translatePartialLoader',
							function($translate,
									$translatePartialLoader) {
								$translatePartialLoader
								.addPart('referentiel');
								$translatePartialLoader
								.addPart('banque');
								$translatePartialLoader
								.addPart('global');
								return $translate.refresh();
							} ]
					}
				})
				.state(
						'paiementEnLigneConfigAdd',
						{
							parent : 'paiementEnLigne',
							url : '/newx',
							data : {
								authorities : [ 'ROLE_ADVANCEDUSER',
									'ROLE_ADMIN' ],

							},
							onEnter : [
								'$stateParams',
								'$state',
								'$uibModal',
								function($stateParams, $state, $uibModal) {
									$uibModal
									.open({
										templateUrl : 'app/entities/paiementEnLigne/paiementEnLigneConfig-add.html',
										controller : 'PaiementEnLigneDialogController',
										size : 'lg',
										resolve : {
											entity : function() {
												return {
													codeType : null,
													codeRubrique : null,
													libelle : null,
													valeur1 : null,
													valeur2 : null,
													status : null,
													codeListeMere : null,
													id : null
												};
											}
										}
									}).result.then(
											function(result) {
												$state.go('paiementEnLigne', null, {
													reload : true
												});
											}, function() {
												$state.go('paiementEnLigne');
											})
								} ]
						})

						.state(
								'paiementEnLigne.edit',
								{
									parent : 'paiementEnLigne',
									url : '/{id}/editPaiement',
									data : {
										authorities : [ 'ROLE_ADVANCEDUSER',
											'ROLE_ADMIN' ],

									},
									onEnter : [
										'$stateParams',
										'$state',
										'$uibModal',
										function($stateParams, $state, $uibModal) {
											$uibModal
											.open({
												templateUrl : 'app/entities/paiementEnLigne/paiementEnLigne-update.html',
												controller : 'PaiementEnLigneDialogController',
												size : 'lg',
												resolve : {
													entity : [
														'PaiementEnLigneConfig',
														function(PaiementEnLigneConfig) {
															return PaiementEnLigneConfig
															.get({
																id : $stateParams.id
															});

														} ]
												}
											}).result.then(
													function(result) {
														$state.go('paiementEnLigne', null, {
															reload : true
														});
													}, function() {
														$state.go('^');
													})
										} ]
								})
								.state(
										'paiementEnLigne.delete',
										{
											parent : 'paiementEnLigne',
											url : '/{id}/deletePaiement',
											data : {
												authorities : [ 'ROLE_ADVANCEDUSER',
													'ROLE_ADMIN' ],

											},
											onEnter : [
												'$stateParams',
												'$state',
												'$uibModal',
												function($stateParams, $state, $uibModal) {
													$uibModal
													.open({
														templateUrl : 'app/entities/paiementEnLigne/paiementEnLigne-update-delete-dialog.html',
														controller : 'PaiementConfigDeleteController',
														size : 'md',
														resolve : {
															entity : [
																'PaiementEnLigneConfig',
																function(PaiementEnLigneConfig) {
																	return PaiementEnLigneConfig
																	.get({
																		id : $stateParams.id
																	});
																} ]
														}
													}).result.then(
															function(result) {
																$state.go('paiementEnLigne', null, {
																	reload : true
																});
															}, function() {
																$state.go('^');
															})
												} ]
										});

	}
})();
