(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'synthese',
						{
							parent : 'entity',
							url : '/synthese',
							data : {
								
								pageTitle : 'statistiques_co.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/synthese/synthese.html',
									controller : 'SyntheseController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('Admin');
											return $translate.refresh();
										} ]
							}
						})
						
						.state(
						'reclamation-detail',
						{
							parent : 'entity',
							url : '/reclamation-detail',
							data : {
								pageTitle : 'statistiques_co.home.title'
							},
							params : {
				    			id : null,
				    		},
							views : {
								'content@' : {
									templateUrl : 'app/admin/synthese/reclamation-dialog.html',
									controller : 'ReclamationDialogController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('Admin');
											return $translate.refresh();
										} ]
							}
						})
						
						.state('reclamation', {
                parent: 'synthese',
                url: '/reclamation/{id}',
                data: {
                             },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/synthese/reclamation.html',
                        controller: 'ReclamationController',
                        size: 'lg',
                    }).result.then(
							function(result) {
								$state.go(
										'synthese',
										null, {
											reload : true
										});
							}, function() {
								$state.go('^');
							})
                }]
            })
        	
			.state('reclamation-info', {
    parent: 'reclamation-detail',
    url: '/reclamation-info/{id}',
    data: {
    
			},
			params : {
    			reclamation : null,
    			key : null,
    			value : null,
    		},
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/admin/synthese/reclamation-info.html',
            controller: 'ReclamationInfoController',
            size: 'lg',
            resolve: {
                entity: function () {
                    return {
                    };
                }
            }
        })
    }]
})


	}
})();
