(function() {
	'use strict';
	angular
	.module('infochamApp')
	.factory('ClickToPay', ClickToPay);

	ClickToPay.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

	function ClickToPay ($http,$resource, DateUtils,$rootScope) {

		return {
			'addClickToPay': function (paiementClickToPay,idAgenda,montant) {

				return $http({
					method : "PUT",
					url : "api/paiementClickToPay/"+idAgenda+"/"+montant,
					dataType: "json",
					traditional: true,
					data : paiementClickToPay,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
		};


	}
})();