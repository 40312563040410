(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeReferentielController', TypeReferentielController);

	TypeReferentielController.$inject = ['$resource' ,'$scope', '$state', 'TypeReferentiel' ,'ParseLinks','$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder','ReportingService'];

	function TypeReferentielController ($resource ,$scope, $state, TypeReferentiel, ParseLinks,  $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder,ReportingService) {

	     $scope.reverse = true;
	     $scope.page = 1;
	     
	     
	     $scope.dtOptions = DTOptionsBuilder
	 	.fromFnPromise(function() {
	         return $resource('api/findAllTypeReferentiel').query().$promise; })
	 	.withOption('createdRow', createdRow)
	 	.withPaginationType('full_numbers')
	 	.withOption('order', [0, 'asc'])

	 	.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
	     

				.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			}

		})
		$scope.dtColumns = [
			DTColumnBuilder.newColumn('codeType').withTitle($translate.instant('infochamApp.typeReferentiel.codeType')).withOption('width','15%'),

			DTColumnBuilder.newColumn('description').withTitle($translate.instant('infochamApp.typeReferentiel.description')),
			DTColumnBuilder.newColumn('nature').withTitle($translate.instant('infochamApp.typeReferentiel.nature')),
			DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('width','7%')
			.renderWith(actionsHtml)
			//.notVisible()
			];

	     $rootScope.$on('$translateChangeSuccess', function () {
	 		$scope.dtOptions.withLanguageSource($translate.instant('global.datatable'));
	 		$scope.dtColumns = [
	 			DTColumnBuilder.newColumn('codeType').withTitle($translate.instant('infochamApp.typeReferentiel.codeType')).withOption('width','15%'),
	 	 		DTColumnBuilder.newColumn('description').withTitle($translate.instant('infochamApp.typeReferentiel.description')),
	 			DTColumnBuilder.newColumn('nature').withTitle($translate.instant('infochamApp.typeReferentiel.nature')),
	 			DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('width','7%')
	 			.renderWith(actionsHtml)
	 			//.notVisible()
	 			];
	 		
	     });
	 		function createdRow(row, data, dataIndex) {
	 			// Recompiling so we can bind Angular directive to the DT
	 			$compile(angular.element(row).contents())($scope);
	 		}
	 		
	 		
	 		
	 		function actionsHtml(data, type, full, meta) {
	 		//	$scope.agenceTypes[data.id] = data;
	 			return '<div class="action-center" style="margin-top:0px">' +
	 			' <div class="col-md-1"><a ui-sref="typeReferentiel.edit({id:' + data.id + '})">' +
	 			'   <i class="fa fa-pencil pencil-style"></i> ' +
	 			'</a></div>' +
	 			'<div class="col-md-1"><a ui-sref="typeReferentiel.delete({id:' + data.id + '})" )"="">' +
	 			'   <i class="glyphicon glyphicon-trash icon-trash-style"></i> ' +
	 			'</a></div>'

	 		}
	 		 $scope.dtInstance={}; 
	 		
	    
	 		



	     $scope.clear = function () {
	         $scope.agenceType = {
	             libelle: null,
	             id: null
	         };
	     };
	 }
})();
