(function() {
    'use strict';

    angular
        .module('infochamApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll', 'ngSanitize',
        	'SmartAdmin.Layout',
        	'SmartAdmin.UI',
        	'app.smartAdmin',
        	'SmartAdmin','nk.touchspin','ngMask', 'chart.js','lov-directive','lovwithId-directive',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'app.tables',
            'datatables.scroller','datatables.buttons', 
            'blockUI',
            'fileupload-directive','angularFileUpload','widgets','vcRecaptcha','ui.multiselect','ui.multiselectgouv'
            
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
