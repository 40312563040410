


(function() {
	'use strict';


angular.module("infochamApp")

	.directive('note', ['$uibModal', function ($uibModal) {
		return {
		restrict: 'E',
		scope: {
		id : '=',
		
		},
		template: '<span class="box-container-direct" ng-click="openPopUp(id)"></span>',
		controller: ['$scope', '$element', function ($scope, $element) {
		$scope.openPopUp = function (id) {
		$scope.localPopUp = {
		size: 'lg',
		templateUrl: 'app/directives/note.html',
		controller: 'NoteCtrl',
		resolve: {
		id: function () {
		return id;
		}
		}
		};
		var modalInstance = $uibModal.open($scope.localPopUp);
		modalInstance.result.then(function(selectedItem) {
		callback({
		result : selectedItem
		});
		}, function() {
		// dismiss: nothing to do
		});
		}
		}]
		}
		}])
		.controller('NoteCtrl', function (id,$scope,DepenseTechService,ReportService,$uibModalInstance) {

		$scope.id = id;

		$scope.Notif = function (){
		$uibModalInstance.close($scope.datanotif);
		}
		$scope.closeLov = function () {
		$uibModalInstance.dismiss('close');
		};

		});


})();

