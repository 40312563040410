(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ReferentielDialogController', ReferentielDialogController);

	ReferentielDialogController.$inject = ['$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Referentiel', 'TypeReferentiel'];

	function ReferentielDialogController ($translate, $scope, $stateParams, $uibModalInstance, entity, Referentiel, TypeReferentiel) {

		$scope.referentiel = entity;
		$scope.load = function(id) {
			Referentiel.get({
				id : id
			}, function(result) {
				$scope.referentiel = result;

			});
		};
				$scope.lovModel = 'api/findAllTypeReferentiel';// this will be the LOV
													// datasource

		$scope.lovTitle = $translate.instant('infochamApp.referentiel.codificationList');
		$scope.lovColumnList = [ $translate.instant('infochamApp.referentiel.codeType'), $translate.instant('infochamApp.referentiel.description'), $translate.instant('infochamApp.referentiel.nature') ];
		$scope.lovFieldList = [ "codeType", "description", "nature" ];
		// function to be called when you click "choose" button
		$scope.lovCallBack = function(result) {
			// e parameter contains the whole object for the row selected
			$scope.referentiel.codeType = result;
			$scope.codeType = result.codeType;

		}

		var onSaveSuccess = function(result) {
			$scope.$emit('infochamApp:referentielUpdate', result);
			$uibModalInstance.close(result);
			$scope.isSaving = false;

		};
		$scope.typeCod = {}

		var onSaveError = function(result) {
			$scope.isSaving = false;
			if (result.status == 500) {
				swal("Opération interrompue!", "Déja existant", "error");
			} else {

				swal("Opération interrompue!", "", "error");

			}
		};

		$scope.save = function() {
			$scope.isSaving = true;
			if ($scope.referentiel.id != null) {
				Referentiel.update($scope.referentiel, onSaveSuccess, onSaveError);
			} else {
				Referentiel.save($scope.referentiel, onSaveSuccess, onSaveError);
			}
		};

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$scope.lovModel2 = 'api/referentiels/status';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des Status';
		$scope.lovColumnList2 = [ 'Code rubrique', 'Libelle' ];
		$scope.lovFieldList2 = [ 'codeRubrique', 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.referentiel.status = result.codeRubrique;
		}

	}
})();
