(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeAdhesionDetailController', TypeAdhesionDetailController);

	TypeAdhesionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TypeAdhesion', 'Cci'];

	function TypeAdhesionDetailController ($scope, $rootScope, $stateParams, entity, TypeAdhesion, Cci) {
        $scope.typeAdhesion = entity;
        $scope.load = function (id) {
            TypeAdhesion.get({id: id}, function(result) {
                $scope.typeAdhesion = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:typeAdhesionUpdate', function(event, result) {
            $scope.typeAdhesion = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
