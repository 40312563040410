
(function() {
	'use strict';
	angular
	.module('infochamApp')
	.factory('PaiementEnLigneConfig', PaiementEnLigneConfig);

	PaiementEnLigneConfig.$inject = ['$resource', 'DateUtils'];

	function PaiementEnLigneConfig ($resource, DateUtils) {
		return $resource('api/paiementConfiguration/:id', {}, {
			'query': { method: 'GET', isArray: true},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'update': { method:'PUT' }

		});
	}
})();