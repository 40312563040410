(function() {
	'use strict';
	angular
		.module('infochamApp')
		.controller('DepotDetailController',DepotDetailController);
	DepotDetailController.$inject=['$scope', '$rootScope','$stateParams', 'entity', 'Depot','Produit', 'User'];
	function DepotDetailController($scope, $rootScope, $stateParams, entity, Depot,Produit, User){
		$scope.depot = entity;
        $scope.load = function (id) {
            Depot.get({id: id}, function(result) {
                $scope.depot = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:depotUpdate', function(event, result) {
            $scope.depot = result;
        });
        $scope.$on('$destroy', unsubscribe);
	}

	})();
