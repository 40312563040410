(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('managementRapportDroitController',managementRapportDroitController);
managementRapportDroitController.$inject=['$translate','$stateParams', '$http', '$scope', '$uibModalInstance', 'User','DTOptionsBuilder','DTColumnBuilder','$timeout'];
function managementRapportDroitController($translate,$stateParams, $http, $scope, $uibModalInstance, User,DTOptionsBuilder,DTColumnBuilder,$timeout){
	$scope.id=$stateParams.id;
	$timeout( function(){

		$http.get('api/usersById/'+$scope.id).then(function(result){
			$scope.roleList=[];
			$scope.user =result.data;
			$scope.userRoles=$scope.user.authorities;
			 for (var i = 0; i < $scope.userRoles.length; i++) 
    {
        if($scope.userRoles[i]=="ROLE_ADHESION"){
        	$scope.roleList.push("Responsable Adhésion")
    }
			else if($scope.userRoles[i]=="ROLE_CHEF_SERVICE"){
	        	$scope.roleList.push("Responsable Chef service")
	    }
			else if($scope.userRoles[i]=="ROLE_ADMIN"){
	        	$scope.roleList.push("Administrateur")
	    }
			else if($scope.userRoles[i]=="ROLE_RESPONSABLE_ATTESTATION"){
	        	$scope.roleList.push("Responsable Attestation")
	    }
			else if($scope.userRoles[i]=="ROLE_RESPONSABLE_PAIEMENT"){
	        	$scope.roleList.push("Responsable Paiement")
	    }
		else if($scope.userRoles[i]=="ROLE_USER"){
        	$scope.roleList.push("Utilisateur")
    } 
		else if($scope.userRoles[i]=="ROLE_OPERATEUR_ECONOMIQUE"){
        	$scope.roleList.push("Opérateur économique")
    } 
		else if($scope.userRoles[i]=="PRESIDENT"){
        	$scope.roleList.push("Président")
    } 
		else if($scope.userRoles[i]=="DIRECTRICE_GENERALE"){
        	$scope.roleList.push("Directrice générale")
    } 
	}
		})
		}, 100 );
	
	$scope.clear = function() {
		$uibModalInstance.dismiss('cancel');
	};

	$scope.dtOptions = DTOptionsBuilder
			.newOptions()

			.withPaginationType('full_numbers')
			.withOption('order', [ 0, 'asc' ])

			.withDOM(
					"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap()
			.withLanguageSource(
					$translate
							.instant('global.datatable'))
			.withLightColumnFilter({

				'0' : {
					type : 'text'
				}
			});
	$scope.dtColumns = [

			DTColumnBuilder.newColumn(0).withTitle('Rôles').withOption('width', '20%'),
	];
}

})();
