

(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('typeReferentiel', {
            parent: 'entity',
            url: '/typeReferentiels',
            data: {
                authorities: [],
                pageTitle: 'infochamApp.typeReferentiel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/typeReferentiel/typeReferentiels.html',
                    controller: 'TypeReferentielController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('typeReferentiel');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('typeReferentiel.detail', {
            parent: 'entity',
            url: '/typeReferentiel/{id}',
            data: {
                authorities: [],
                pageTitle: 'infochamApp.typeReferentiel.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/typeReferentiel/typeReferentiel-detail.html',
                    controller: 'TypeReferentielDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('typeReferentiel');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TypeReferentiel', function($stateParams, TypeReferentiel) {
                    return TypeReferentiel.get({id : $stateParams.id});
                }]
            }
        })
        .state('typeReferentiel.new', {
            parent: 'typeReferentiel',
            url: '/new',
            data: {
                authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/typeReferentiel/typeReferentiel-dialog.html',
                    controller: 'TypeReferentielDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                codeType: null,
                                description: null,
                                nature: null,
                                listeMere: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('typeReferentiel', null, { reload: true });
                }, function() {
                    $state.go('typeReferentiel');
                })
            }]
        })
        .state('typeReferentiel.edit', {
            parent: 'typeReferentiel',
            url: '/{id}/edit',
            data: {
                authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/typeReferentiel/typeReferentiel-dialog.html',
                    controller: 'TypeReferentielDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['TypeReferentiel', function(TypeReferentiel) {
                            return TypeReferentiel.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('typeReferentiel', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('typeReferentiel.delete', {
            parent: 'typeReferentiel',
            url: '/{id}/delete',
            data: {
                authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/typeReferentiel/typeReferentiel-delete-dialog.html',
                    controller: 'TypeReferentielDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['TypeReferentiel', function(TypeReferentiel) {
                            return TypeReferentiel.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('typeReferentiel', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        });
}
})();

