(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ReferentielDetailController', ReferentielDetailController);

	ReferentielDetailController.$inject = ['$scope','$rootScope', '$uibModalInstance', 'entity', 'Referentiel'];

	function ReferentielDetailController ($scope, $rootScope,$uibModalInstance, entity, Referentiel) {
        $scope.referentiel = entity;
        $scope.load = function (id) {
            Referentiel.get({id: id}, function(result) {
                $scope.referentiel = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:referentielUpdate', function(event, result) {
            $scope.referentiel = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
