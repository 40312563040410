(function() {
	'use strict';
	angular.module('infochamApp').controller('RoleManagementController',
			RoleManagementController);

	RoleManagementController.$inject = [ '$scope', '$timeout', 'authorities',
			'DTOptionsBuilder', 'DTColumnDefBuilder', 'CreateUser' ];

	function RoleManagementController($scope, $timeout, authorities,
			DTOptionsBuilder, DTColumnDefBuilder, CreateUser) {
		$scope.authorities = authorities.data;
		$scope.selected = 0;
		$scope.selectTab = function(setSelected) {

			$scope.selected = setSelected;

		};
		$scope.isSelected = function(checkSelected) {
			return $scope.selected === checkSelected;
		}
		$scope.dtOptions = DTOptionsBuilder.newOptions().withDOM('tr')
				.withOption('order', []).withOption('scrollX', true)
				.withOption('scrollY', 200).withDisplayLength(-1)
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					}
				})

		$scope.dtColumnDefs = [
				DTColumnDefBuilder.newColumnDef(0).withTitle("Nom")
						.notSortable().withOption('width', '70%'),
				DTColumnDefBuilder.newColumnDef(1).withTitle(
						"Nombre utilisateurs").notSortable().withOption(
						'width', '15%'),
		];

		$scope.download = function() {
			var fileName = 'Liste des utilisateurs par role';
			var a = document.createElement("a");
			document.body.appendChild(a);
			CreateUser.GroupUserParRole().then(function(result) {
				var file = new Blob([ result.data ], {
					type : 'application/pdf'
				});
				var fileURL = window.URL.createObjectURL(file);

				if ($scope.navigateurIE) {
					// seulement pour le navigateur IE
					window.navigator.msSaveOrOpenBlob(file, titre);
				} else {
					// avec d'autres navigateurs
					a.href = fileURL;
					a.download = fileName;
					a.click();
				}

			});

		}
	}
})();
