

(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
    	$stateProvider
    	.state('initiationDemandeAdhesionCOOnline', {
    		parent: 'entity',
    		url: '/initiationDemandeAdhesionCOOnline',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			codeProcess : null,
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/ProcessDemandeAdhesionCO/Online/initiationDemandeAdhesionCOOnline.html',
    				controller: 'InitiationDemandeAdhesionCOOnlineController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})      

    	.state('traitDemandeAdhesionCO', {
    		parent: 'entity',
    		url: '/traitDemandeAdhesionCO',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/ProcessDemandeAdhesionCO/Online/traitDemandeAdhesionCO.html',
    				controller: 'TraitDemandeAdhesionCOController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
}
})();

