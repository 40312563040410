(function() {
	'use strict';
	angular.module('infochamApp').controller('usermanagementDroitController',
			usermanagementDroitController);

	usermanagementDroitController.$inject = [ '$translate', '$stateParams',
			'$http', '$scope', '$uibModalInstance', 'User', 'DTOptionsBuilder',
			'DTColumnBuilder', '$timeout' ];

	function usermanagementDroitController($translate, $stateParams, $http,
			$scope, $uibModalInstance, User, DTOptionsBuilder, DTColumnBuilder,
			$timeout) {
		$scope.id = $stateParams.log;
		$timeout(function() {

			$http.get('api/usersById/' + $scope.id).then(function(result) {
				$scope.user = result.data;
				$scope.userRoles = $scope.user.authorities;
			})
		}, 100);

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.dtOptions = DTOptionsBuilder
				.newOptions()

				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])

				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					}
				});
		$scope.dtColumns = [

				DTColumnBuilder.newColumn(0).withTitle('Rôles').withOption(
						'width', '20%'), ];

	}
})();
