(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('MatierePremiereDialogController',MatierePremiereDialogController);
MatierePremiereDialogController.$inject=['$scope', '$stateParams', '$uibModalInstance', 'entity', 'MatierePremiere', 'Depot', 'Produit', 'User', 'Facture', 'PieceJointe'];
function MatierePremiereDialogController($scope, $stateParams, $uibModalInstance, entity, MatierePremiere, Depot, Produit, User, Facture, PieceJointe){
	$scope.matierePremiere = entity;
    $scope.depots = Depot.query();
    $scope.produits = Produit.query();
    $scope.users = User.query();
    $scope.factures = Facture.query();
    $scope.piecejointes = PieceJointe.query();
    $scope.load = function(id) {
        MatierePremiere.get({id : id}, function(result) {
            $scope.matierePremiere = result;
        });
    };

    var onSaveSuccess = function (result) {
        $scope.$emit('infochamApp:matierePremiereUpdate', result);
        $uibModalInstance.close(result);
        $scope.isSaving = false;
    };

    var onSaveError = function (result) {
        $scope.isSaving = false;
    };

    $scope.save = function () {
        $scope.isSaving = true;
        if ($scope.matierePremiere.id != null) {
            MatierePremiere.update($scope.matierePremiere, onSaveSuccess, onSaveError);
        } else {
            MatierePremiere.save($scope.matierePremiere, onSaveSuccess, onSaveError);
        }
    };

    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.datePickerForDateCre = {};

    $scope.datePickerForDateCre.status = {
        opened: false
    };

    $scope.datePickerForDateCreOpen = function($event) {
        $scope.datePickerForDateCre.status.opened = true;
    };		
}

})();

