(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('managementDeleteController', managementDeleteController);

	managementDeleteController.$inject = ['$stateParams', '$http','CreateUser' , '$scope', '$uibModalInstance', 'User'];

	function managementDeleteController ($stateParams, $http,CreateUser , $scope, $uibModalInstance, User) {
		$scope.idUser=$stateParams.login;

		   CreateUser.getUserById($scope.idUser).then(function (result) {
	       	

	           $scope.user = result.data;
		   });
	        $scope.clear = function() {
	            $uibModalInstance.dismiss('cancel');
	        };
	        $scope.confirmDelete = function (login) {
	            User.delete({login: login},
	                function () {
	                    $uibModalInstance.close(true);
	                });
	        };

}
})();
