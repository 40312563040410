(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ParamPaiementDetailController',ParamPaiementDetailController);
ParamPaiementDetailController.$inject=['$scope', '$rootScope','$stateParams', 'entity', 'ParamPaiement'];
function ParamPaiementDetailController($scope, $rootScope, $stateParams, entity, ParamPaiement){
	$scope.ParamPaiement = entity;
    $scope.load = function (id) {
    	ParamPaiement.get({id: id}, function(result) {
            $scope.ParamPaiement = result;

        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:ParamPaiementUpdate', function(event, result) {
        $scope.ParamPaiement = result;
    });
    $scope.$on('$destroy', unsubscribe);
}

})();
