(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('demandeDepotDossierTechniqueOnlineController', demandeDepotDossierTechniqueOnlineController);

	demandeDepotDossierTechniqueOnlineController.$inject = ['fileUpload','$http','$stateParams','Agenda' ,'Principal','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita'];

	function demandeDepotDossierTechniqueOnlineController (fileUpload,$http,$stateParams,Agenda ,Principal,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita) {

		$( document ).ready(function() {
			$('.uploadDirective .panel-title').text("DOCUMENTS A RATTACHER");
		});
		Principal.identity().then(function(account) {
			$scope.user = account;
			
			$http.get("api/findAllDossJuridiqueByExpediteurByYearAndType/"+$scope.user.oeExpediteur.id).then(function(result) {
				if (result.data.length == 0) {
					swal("Opération interrompue!", "Vous n'avez pas déposé le dossier juridique", "error");
					$state.go("home");
				}

			});
			
			Agenda.findAdhesionByExpediteur($scope.user.oeExpediteur.id).then(function(result){
				if(result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='SOUS' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='MAHD' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='KAIR' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique!='MONA'){
					swal("Opération interrompue!", "Ce service n'est pas disponible", "error");
					$state.go("home");
				}
				
			});
		});
			
			$scope.status = $stateParams.status;
			if($scope.status == null){
				$state.go('home', null, { reload: false } );
			}
			var vm = this;
			$scope.codePiece= 'DEPDOSSTECH';
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
		    $scope.matierePremiereEtrangereList=[];
			$scope.matierePremiereLocaleList=[];
			$scope.depot={};
			$scope.produit={};
			$scope.depotDoss={};
			$scope.depotDoss.depot={};
			$scope.depotDoss.produit={};
			$scope.depotDoss.depot.totalMo=0;
			$scope.depotDoss.depot.totalProd=0;
			$scope.depotDoss.depot.totalAutreFrais=0;
			$scope.depotDoss.depot.totalMP=0;
			$scope.depot.totalMainDoeuvre=0;
			$scope.depot.totalAutresFrais=0;
			$scope.depotDoss.produit.tauxIntegration=0;
			$scope.totalMPL=0;
			$scope.totalMPE=0;
			$scope.totalMP=0;
			$scope.fraisAchats=0;
			$scope.depot.coutTotalProd=0;
			$scope.docFacture = [];
			$scope.produitTest={};
			$scope.depotTest={};
			$scope.adhesion ={};
			$scope.expediteur = {};
			Principal.identity().then(function(account) {
				$scope.user = account;
				if ($scope.user == null) {
					$state.go('login');
				}
				if($scope.status == "ONLINE"){
					$scope.expediteur = $scope.user.oeExpediteur;

	Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
						
						$scope.adhesion = result.data
					});
					
				}
			});
			
	

			$scope.lovModel2 = 'api/getAllExpediteur';// this will be
		 	// the LOV
		 	// datasource
		 	$scope.lovTitle2 = 'Liste des raisons sociales';
		 	$scope.lovColumnList2 = [' Raison Sociale '];
		 	$scope.lovFieldList2 = ['raisonSociale'];
		 	// function to be called when you click "choose" button
		 	$scope.lovCallBack2 = function(result) {
		 	$scope.expediteur = result;
		 	Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
				
				$scope.adhesion = result.data
			});
		 	}
				$scope.lovModelSecteur = 'api/referentiels/unites';// this will be
			// the LOV
			// datasource
			$scope.lovTitleSecteur = 'Liste des unités';
			$scope.lovColumnListSecteur = [  'Libellé' ];
			$scope.lovFieldListSecteur = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackSecteur = function(result) {
				$scope.produit.uniteDTO = result;
				$scope.$emit('eventSousSecteur',result)
			}

			vm.validationStep = function(item, actions) {
				$scope.errors =[];
				 if(item.index==0){
					  if($scope.status=="OFFLINE" && ($scope.expediteur ==null || $scope.expediteur == undefined || $scope.expediteur == "") ){
		         		  $scope.errors.push("Le champ Raison sociale est requis.")
		         	  }
					  if ($scope.pieceValide == undefined ||
								 $scope.pieceValide==false) {
								 $scope.errors.push("Il faut attacher toutes les piéces justificatives!")
								
						}   
		    		
				 }
				 if(item.index==1){
					 
					  
		    		   if($scope.produit.designation ==null || $scope.produit.designation == undefined || $scope.produit.designation == "" ){
			         		  $scope.errors.push("Le champ Désignation du produit à exporter est requis.")
			         	  }
		    		   if($scope.produit.uniteDTO ==null || $scope.produit.uniteDTO == undefined || $scope.produit.uniteDTO == "" ){
			         		  $scope.errors.push("Le champ Unité est requis.")
		    		   }
		    		   if($scope.produit.ngpNdp ==null || $scope.produit.ngpNdp == undefined || $scope.produit.ngpNdp == "" || $scope.produit.ngpNdp.toString().length < 11 ){
			         		  $scope.errors.push("Le champ NGP doit être composé de 11 chiffres.")
			         	 }
		    		   if($scope.produit.uniteDTO.codeRubrique =='AUTR'  ){
		    			   $("ngpndpAutre").attr("required", "true");
		    			   $("ngpndp").attr("required", "false");
		    			   if($scope.produit.nbreUniteAutre ==null || $scope.produit.nbreUniteAutre == undefined || $scope.produit.nbreUniteAutre == "" ){
				         		  $scope.errors.push("Le champ nature est requis.")
				         	 }
		    		   }
		    		   
				 }
if(item.index==3){
	   if($scope.depot.fraisPersDirProd ==null || $scope.depot.fraisPersDirProd == undefined || $scope.depot.fraisPersDirProd == "" ){
  		  $scope.errors.push("Le champ Frais du personnel direct à la production est requis.")
  	  }
	   if($scope.depot.fraisPersIndirProd ==null || $scope.depot.fraisPersIndirProd == undefined || $scope.depot.fraisPersIndirProd == "" ){
  		  $scope.errors.push("Côte part des frais du personnel indirect à la production est requis.")
  	  }
}
if(item.index==4){
	   if($scope.depot.fraisTransp ==null || $scope.depot.fraisTransp == undefined || $scope.depot.fraisTransp == "" ){
  		  $scope.errors.push("Le champ Frais du transport et d'assurance est requis.")
  	  }
	   if($scope.depot.fretTransit ==null || $scope.depot.fretTransit == undefined || $scope.depot.fretTransit == "" ){
	  		  $scope.errors.push("Le champ Frêt et transit est requis.")
	  	  }
	   if($scope.depot.commAchat ==null || $scope.depot.commAchat == undefined || $scope.depot.commAchat == "" ){
	  		  $scope.errors.push("Le champ Commission sur achat est requis.")
	  	  }
	   if($scope.depot.fraisStock ==null || $scope.depot.fraisStock == undefined || $scope.depot.fraisStock == "" ){
	  		  $scope.errors.push("Le champ Frais de stockage est requis.")
	  	  }
	   if($scope.depot.sousTrait ==null || $scope.depot.sousTrait == undefined || $scope.depot.sousTrait== "" ){
  		  $scope.errors.push("Le champ Sous-traitance générale est requis.")  
  	  }
	   else{
		   if($scope.depot.sousTrait>0){
			   
			   if( document.getElementById("testFileInputMessage").value == '' ){
				   $scope.errors.push("Il faut attacher une facture");
				}
	  		  }
		   
		  
		   }
	   
	   if($scope.depot.entrRepar ==null || $scope.depot.entrRepar == undefined || $scope.depot.entrRepar == "" ){
  		  $scope.errors.push("Le champ Entretien et réparation est requis.")
  	  }
	   if($scope.depot.matNonStock ==null || $scope.depot.matNonStock == undefined || $scope.depot.matNonStock == "" ){
  		  $scope.errors.push("Le champ Matières non stockées (Petit Outillage) est requis.")
  	  }
	   if($scope.depot.matConsEnreg ==null || $scope.depot.matConsEnreg == undefined || $scope.depot.matConsEnreg == "" ){
  		  $scope.errors.push("Le champ Matieres consommables energétiques (Eau, Gaz, Electricité,...) est requis.")
  	  }
	   if($scope.depot.serExt ==null || $scope.depot.serExt == undefined || $scope.depot.serExt == "" ){
  		  $scope.errors.push("Le champ Etude, recherche et divers services extérieurs est requis.")
  	  }
	   if($scope.depot.chargFinanc ==null || $scope.depot.chargFinanc == undefined || $scope.depot.chargFinanc == "" ){
  		  $scope.errors.push("Le champ Charges financières  est requis.")
  	  }
	   if($scope.depot.amortiss ==null || $scope.depot.amortiss == undefined || $scope.depot.amortiss == "" ){
  		  $scope.errors.push("Le champ Dotations aux amortissements du materiel et des equipements servant à la fabrication du produit en question est requis.")
  	  }
}
				
				if($scope.errors.length> 0){
					actions.error($scope.errors);
					$("html, body").animate({scrollTop: 200},"slow");
				}
				else {
					actions.success();
				}
			}
			 $scope.gettotalMainDoeuvre = function(fraisPersDir,fraisPersIndir) {
				 var fraisPersDir1 = 0;
				 var fraisPersIndir1=0;
				 if(fraisPersDir != undefined){
					 fraisPersDir1=fraisPersDir;
				 }
				 if(fraisPersIndir != undefined){
					 fraisPersIndir1=fraisPersIndir;
				 }
				 $scope.depot.totalMainDoeuvre=parseFloat(fraisPersDir1)+parseFloat(fraisPersIndir1);
				 $scope.depot.coutTotalProd = parseFloat($scope.totalMP)+parseFloat($scope.depot.totalMainDoeuvre) +parseFloat($scope.depot.totalAutresFrais);

			 }
			 $scope.gettotalAutresFrais = function(fraisTrans,fretTransit,commissionAchat,fraisStockage,sousTraitGen,entretienReparation,matiereNonStock,matiereConsommableEnerg,etudeRechDiversServExt,chargesFinancieres,dotAmortissementMateriel) {
				 
				 var fraisTrans1 = 0;
				 var fretTransit1 = 0;
				 var commissionAchat1 = 0;
				 var fraisStockage1 = 0;
				 var sousTraitGen1 = 0;
				 var entretienReparation1=0;
				 var matiereNonStock1=0;
				 var matiereConsommableEnerg1=0;
				 var etudeRechDiversServExt1=0;
				 var chargesFinancieres1=0;
				 var dotAmortissementMateriel1=0;
				 if(fraisTrans != undefined){
					 fraisTrans1=fraisTrans;
				 }
				 if(fretTransit != undefined){
					 fretTransit1=fretTransit;
				 }
				 if(commissionAchat != undefined){
					 commissionAchat1=commissionAchat;
				 }
				 if(fraisStockage != undefined){
					 fraisStockage1=fraisStockage;
				 }
				 if(sousTraitGen != undefined){
					 sousTraitGen1=sousTraitGen;
				 }
				 if(entretienReparation != undefined){
					 entretienReparation1=entretienReparation;
				 }
				 if(matiereNonStock != undefined){
					 matiereNonStock1=matiereNonStock;
				 }
				 if(matiereConsommableEnerg != undefined){
					 matiereConsommableEnerg1=matiereConsommableEnerg;
				 }
				 if(etudeRechDiversServExt != undefined){
					 etudeRechDiversServExt1=etudeRechDiversServExt;
				 }
				 if(chargesFinancieres != undefined){
					 chargesFinancieres1=chargesFinancieres;
				 }
				 if(dotAmortissementMateriel != undefined){
					 dotAmortissementMateriel1=dotAmortissementMateriel;
				 }
				 $scope.depot.totalAutresFrais=parseFloat(fraisTrans1)+parseFloat(fretTransit1)+parseFloat(commissionAchat1)+parseFloat(fraisStockage1)+parseFloat(sousTraitGen1)+parseFloat(entretienReparation1)+parseFloat(matiereNonStock1)+parseFloat(matiereConsommableEnerg1)+parseFloat(etudeRechDiversServExt1)+parseFloat(chargesFinancieres1)+parseFloat(dotAmortissementMateriel1);
				 $scope.depot.coutTotalProd = parseFloat($scope.totalMP)+parseFloat($scope.depot.totalMainDoeuvre) +parseFloat($scope.depot.totalAutresFrais);
				 $scope.fraisAchats = parseFloat(fraisTrans1)+parseFloat(fretTransit1)+parseFloat(commissionAchat1)+parseFloat(fraisStockage1);

			 }
			 
			 $scope.styleSwalConfirmation = {
						title: "Confirmation",
						text: "Voulez vous vraiment continuer ?",
						type: "warning",
						showCancelButton: true,
						cancelButtonText: "NON",
						confirmButtonColor: "#337ab7",
						confirmButtonText: "Oui",
						closeOnConfirm: true
				}

			  $scope.$on('eventEmitedFacture', function(event, data) {
				  $scope.docFacture = data.message;
			  });
			 $scope.$on('eventEmitedVisiteTechnique', function(event, data) {
				  $scope.docVisiteTechnique = data.message;
			  });
			 $scope.$on('eventEmitedFactureExport', function(event, data) {
				  $scope.docFactureExport = data.message;
			  });
			 $scope.$on('eventEmitedAutreDT', function(event, data) {
				  $scope.docAutreDT = data.message;
			  });
			 
			 
			 
			vm.completeWizard = function(scope, actions) {
				if($scope.status=='ONLINE'){
				$scope.depotDoss.depot = $scope.depot;
				$scope.depotDoss.produit=$scope.produit;
				$scope.depotDoss.depot.totalMo=$scope.depot.totalMainDoeuvre;
				$scope.depotDoss.depot.totalProd=$scope.depot.coutTotalProd;
				$scope.depotDoss.depot.totalAutreFrais=$scope.depot.totalAutresFrais;
				$scope.depotDoss.depot.totalMP=$scope.totalMP;
				$scope.depotDoss.produit.tauxIntegration = (($scope.depotDoss.depot.totalProd -$scope.totalMPE )/$scope.depotDoss.depot.totalProd)*100
				$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
				$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
				$scope.depotDoss.docFacture = $scope.docFacture;
				swal($scope.styleSwalConfirmation,function(){
					Agenda.addDemandeDepotDossTechnique($scope.depotDoss).then(function(result){
						 if(result.status==200 ){
						$scope.agenda=result.data;
						var file = $scope.docFacture;
						var fileName= file != undefined ? file.name : "";
							var uploadUrl = "api/uploadPieceDossTechFacture/"+$scope.agenda.id;
							if(typeof file !='undefined'){
								var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
										function(result) {
											var file1 = $scope.docFactureExport;
											var fileName= file1 != undefined ? file1.name : "";
												var uploadUrl = "api/uploadPieceDossTechFactureExport/"+$scope.agenda.id;
												if(typeof file1 !='undefined'){
													var result=fileUpload.uploadFileToUrl(file1, uploadUrl).then(
															function(result) {
																var file2 = $scope.docVisiteTechnique;
																var fileName= file2 != undefined ? file.name2 : "";
																	var uploadUrl = "api/uploadPieceDossTechVisiteTechnique/"+$scope.agenda.id;
																	if(typeof file2 !='undefined'){
																		var result=fileUpload.uploadFileToUrl(file2, uploadUrl).then(
																				function(result) {
																					var file3 = $scope.docAutreDT;
																					var fileName= file3 != undefined ? file3.name : "";
																						var uploadUrl = "api/uploadPieceDossTechAutrePj/"+$scope.agenda.id;
																						
																							var result=fileUpload.uploadFileToUrl(file3, uploadUrl).then(
																									function(result) {
																										Agenda.savePieces($scope.pieces,$scope.agenda.id).then(function (result) {
																											swal({
																									    		position: 'center',
																									    	    type: 'success',
																									    		title: 'Votre demande a été créée avec succès',
																									    		showConfirmButton: false,
																									    		timer: 2000
																									    		})
																									    				$state.go('home', null, { reload: true } );
																											 });
																									});

																					
																				});

																}
															});

											}
										});

						}
						
								
								
						
							Agenda.savePieces($scope.pieces,$scope.agenda.id).then(function (result) {
								swal({
						    		position: 'center',
						    	    type: 'success',
						    		title: 'Votre demande a été créée avec succès',
						    		showConfirmButton: false,
						    		timer: 2000
						    		})
						    				$state.go('home', null, { reload: true } );
							});
						 }
						 else{
			    			  swal("veuillez réessayer","", "error")

			    		  }
					})
					setTimeout(function() {
						$state.go('home', null, { reload: true } );
					}, 3000);
				});
				
				} else {
					$scope.depotDoss.depot = $scope.depot;
					$scope.depotDoss.produit=$scope.produit;
					$scope.depotDoss.depot.totalMo=$scope.depot.totalMainDoeuvre;
					$scope.depotDoss.depot.totalProd=$scope.depot.coutTotalProd;
					$scope.depotDoss.depot.totalAutreFrais=$scope.depot.totalAutresFrais;
					$scope.depotDoss.depot.totalMP=$scope.totalMP;
					$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
					$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
					$scope.depotDoss.produit.tauxIntegration = (($scope.depotDoss.depot.totalProd -$scope.totalMPE )/$scope.depotDoss.depot.totalProd)*100
					swal($scope.styleSwalConfirmation,function(){
						Agenda.addDemandeDepotDossTechniqueOffline($scope.depotDoss,$scope.expediteur.id).then(function(result){
							 if(result.status==200 ){
							$scope.agenda=result.data;
							swal({
				    		position: 'center',
				    	    type: 'success',
				    		title: 'Votre demande a été créée avec succès',
				    		showConfirmButton: true,
				    		timer: 10000
				    		})
				    				$state.go('home', null, { reload: true } );
							 }
							 else{
				    			  swal("veuillez réessayer","", "error")

				    		  }
						})
					});
				}
			}
			function formErrors(form){
				var errors = [];
				for(var key in form.$error){
					errors.push(key + "=" + form.$error);
				}
				if(errors.length > 0){

					for(var err in form.$error.required){
					}
				}
			}
			$scope.test = function(obj) {
				formErrors(obj);
			}
			$scope.initiation = function() {
				CustomProcessBonita.createProcessAdhesionOnline({}).then(function(result) {
				});
			}
			$scope.next = function() {
				$state.go("initiationDemandeAdhesionOnline.syhnthese", { demandeDadhesion:  $scope.demandeDadhesion  });
			}
			$scope.dtOptionsMPE = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
	.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
			.withOption('fnFooterCallback',
					function(nRow, aasData, iStart, iEnd, aiDisplay) {
				var columnas = [0]; //the columns you wish to add
				var secondRow = $(nRow).next()[0];
				for (var j in columnas) {
					var columnaActual = columnas[j];
					var total = 0;
					var devise = " ";
					for (var i = 0; i < aasData.length; i++) {
						total = total + parseFloat($scope.matierePremiereEtrangereList[i].pht);
						$scope.totalMPE = total;
						$scope.totalMP = $scope.totalMPE + $scope.totalMPL;
						$scope.depot.coutTotalProd = parseFloat($scope.totalMP)+parseFloat($scope.depot.totalMainDoeuvre) +parseFloat($scope.depot.totalAutresFrais);

					}
					$($(nRow).children().get(columnaActual)).html("Total TA1 ="+total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")+" TND");
					} // end
			})
			$scope.dtColumnsMPE = [
DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '30%'),
DTColumnDefBuilder.newColumnDef(1).withTitle("NGP").withOption('width', '15%'),
DTColumnDefBuilder.newColumnDef(2).withTitle("P.H.T").withOption('width', '15%').notSortable(),
DTColumnDefBuilder.newColumnDef(3).withTitle("Fournisseur").withOption('width', '10%'),
DTColumnDefBuilder.newColumnDef(4).withTitle("Facture").withOption('width', '10%'),
DTColumnDefBuilder.newColumnDef(5).withTitle("Actions").withOption('width', '10%')
				];
			$scope.dtOptionsMPL = DTOptionsBuilder
			.newOptions()
			// or here
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
				.withOption('fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {
					var columnas = [0]; //the columns you wish to add
					var secondRow = $(nRow).next()[0];
					for (var j in columnas) {
						var columnaActual = columnas[j];
						var total = 0;
						var devise = " ";
						for (var i = 0; i < aasData.length; i++) {
							total = total + parseFloat($scope.matierePremiereLocaleList[i].pht);
							$scope.totalMPL = total;
							$scope.totalMP = $scope.totalMPE + $scope.totalMPL;
							$scope.depot.coutTotalProd = parseFloat($scope.totalMP)+parseFloat($scope.depot.totalMainDoeuvre) +parseFloat($scope.depot.totalAutresFrais);

						}
						$($(nRow).children().get(columnaActual)).html("Total TA2 ="+total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")+" TND");
						} // end
				})
				$scope.dtColumnsMPL = [
	DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '30%'),
	DTColumnDefBuilder.newColumnDef(1).withTitle("NGP").withOption('width', '15%'),
	DTColumnDefBuilder.newColumnDef(2).withTitle("P.H.T").withOption('width', '15%').notSortable(),
	DTColumnDefBuilder.newColumnDef(3).withTitle("Fournisseur").withOption('width', '10%'),
	DTColumnDefBuilder.newColumnDef(4).withTitle("Facture").withOption('width', '10%'),
	DTColumnDefBuilder.newColumnDef(5).withTitle("Actions").withOption('width', '10%')
					];
			  $rootScope.$on('eventEmitedMatierePremiereEtrangere', function(event, data) {
				  $scope.matierePremiereEtrangere = data.message;
				  $scope.matierePremiereEtrangereList.push($scope.matierePremiereEtrangere);
			  });
			  $rootScope.$on('eventEmitedMatierePremiereLocale', function(event, data) {
				  $scope.matierePremiereLocale = data.message;
				  $scope.matierePremiereLocaleList.push($scope.matierePremiereLocale);
			  });
			  var unsubscribe1 = $scope.$on('eventEmitedPiece', function(event, result) {
					$scope.pieces = result.message;
					$scope.pieceValide = result.pieceValide;
				});
			  $scope.click = 0;
				$scope.downloadPiecee = function(docId) {
                    if(docId != undefined){
					 $scope.click = $scope.click+1;
						if($scope.click%2 ==0){
					var downloadLink = document.createElement("a");
					document.body.appendChild(downloadLink);
					downloadLink.style = "display: none";
					Inscription2
					.downloadDocument(docId)
					.then(
					function(result) {
					   	if(angular.equals( { status: result.status }, { status: 500 } )){
					swal(message,"Un problème s'est produit!", "error")
					}
					var fName = result
					.headers('Content-Disposition');
					var file = new Blob(
					[ result.data ],
					{
					type : 'application/pdf'
					});
					var fileURL = (window.URL || window.webkitURL)
					.createObjectURL(file);
					downloadLink.href = fileURL;
					downloadLink.download = fName;
					downloadLink.click();
					});
					}  
                    }
				}
				$scope.aa = function(docId) {
					}  
				$scope.nombreAvecVirgule = function(aa)
				{
					var a =parseFloat(aa)
					return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
				}
			  function createdRow(row, data, dataIndex) {
					// Recompiling so we can bind Angular directive to the DT
					$compile(angular.element(row).contents())($scope);
				}  
			  $scope.generateFicheProduit = function(depot,produit,totalMainDoeuvre,totalAutresFrais,coutTotalProd){
				  $scope.depotDoss.depot = depot;
				$scope.depotDoss.expediteur = $scope.expediteur;
					$scope.depotDoss.produit=produit;
					$scope.depotDoss.depot.totalMo=totalMainDoeuvre;
					$scope.depotDoss.depot.totalProd=coutTotalProd;
					$scope.depotDoss.depot.totalAutreFrais=totalAutresFrais
					$scope.depotDoss.depot.totalMP=$scope.totalMP;
					$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
					$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
				  ReportingService.generateFicheProduit($scope.depotDoss).then(function (result) {
					  var fileName="Fiche Technique"
					  ReportingService.downloadPdfFile(result, fileName)
			 });
				}
				 $scope.removeMatierePremEtrangere = function(detail) {
						if(detail != undefined){
						$rootScope.$broadcast('eventEmitedDeletMatierePremiereEtrangereList',{message:detail});
						}
				};
				$scope.$on('eventEmitedDeletMatierePremiereEtrangereList', function(event, data) {
					  $scope.matierePremiereEtrangereList.splice(data.message, 1);

				  });
				
				 $scope.removeMatierePremLocale = function(detail) {
						if(detail != undefined){
						$rootScope.$broadcast('eventEmitedDeletMatierePremiereLocaleList',{message:detail});
						}
				};
				$scope.$on('eventEmitedDeletMatierePremiereLocaleList', function(event, data) {
					  $scope.matierePremiereLocaleList.splice(data.message, 1);

				  });
				
				$scope.$on('eventEmitedUpdateMatierePremiereLocaleList', function(event, data) {
					$scope.matierePremiereLocaleUpdate = data.message;
					$scope.index = data.index;
					$scope.matierePremiereLocaleList[$scope.index] = $scope.matierePremiereLocaleUpdate;
							  });
							$scope.updateMatierePremLocale = function(index) {
								  if (index != undefined) {
									  $scope.matierePremiereLocaleUpdate = $scope.matierePremiereLocaleList[index];
									  $state.go( 'matierePremiereLocaleUpdate', {matierePremiere :$scope.matierePremiereLocaleUpdate, index : index });
								  }
								}

							$scope.$on('eventEmitedUpdateMatierePremiereEtrangereList', function(event, data) {
								$scope.matierePremiereLocaleUpdate = data.message;
								$scope.index = data.index;
								$scope.matierePremiereEtrangereList[$scope.index] = $scope.matierePremiereLocaleUpdate;
										  });
										$scope.updateMatierePremEtrangere = function(index) {
											  if (index != undefined) {
												  $scope.matierePremiereEtrangereUpdate = $scope.matierePremiereEtrangereList[index];
												  
												  $state.go( 'matierePremiereEtrangereUpdate', {matierePremiere :$scope.matierePremiereEtrangereUpdate, index : index });
											  }
											}
										

		}
})();
