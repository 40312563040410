(function() {
	'use strict';
	angular.module('infochamApp')

	.directive('dateInputDirective', function() {
		return {
			require : 'ngModel',
			link : function(scope, elem, attr, modelCtrl) {
				// test pour le format de la date.
				if (attr['type'] === 'date') {
					modelCtrl.$formatters.push(function(modelValue) {
						if (modelValue) {
							return new Date(modelValue);
						} else {
							return null;
						}
					});
				}

			}
		};
	})
})();