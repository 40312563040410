(function() {
'use strict';
angular
	.module('infochamApp')
	.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('rapportActivite-detail', {
        parent: 'home',
        url: 'rapportActivite/',
        data: {
        	  authorities: ['PRESIDENT','ROLE_ADMIN'],		
            pageTitle: 'infochamApp.agenda.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/rapportActivite/rapportActivite-detail.html',
                controller: 'RapportActiviteDetailController',
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('agenda');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    
   .state('rapportActivite-detail.droit', {
    parent: 'rapportActivite-detail',
    url: '/{id}/droit',
    data: {
        authorities: [],
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/entities/rapportActivite/user-management-rapport-droit.html',
            controller: 'user-managementRapportDroitController',
            size: 'lg',

        }).result.then(function(result) {
            $state.go('rapportActivite-detail', null, { reload: true });
        }, function() {
            $state.go('^');
        })
    }]
})
}

})();