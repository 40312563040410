(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ConsultAllDTOEController', ConsultAllDTOEController);

	ConsultAllDTOEController.$inject = ['ReferentielWs' ,'ReportingService' ,'Inscription2','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$http','$state', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function ConsultAllDTOEController (ReferentielWs ,ReportingService ,Inscription2,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal,  $http,$state, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		
		$scope.dj = {}
		$scope.typeProcess = "CO";
		$scope.dtInstance1 = {};
		
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});

	
		// or here
		$scope.dtOptions = DTOptionsBuilder.newOptions().withOption('ajax', {
			url: 'api/getEtatRechercheDossierTechByOE',
		    type: 'GET'
		})
			
		.withDataProp('data')
		.withDisplayLength(10)
		.withOption('processing', true)
		.withOption('serverSide', true)
		.withOption('autoWidth', false)
		.withOption('createdRow', createdRow)
		.withOption('order',[[2, 'desc']])
		.withPaginationType('full_numbers')
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
		.withLightColumnFilter({
			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			},
			'4' : {
				type : 'text'
			},
			'5' : {
				type : 'text'
			},
			'6' : {
			type : 'text'
			},
		
		})
	$scope.dtColumns = [
		DTColumnBuilder.newColumn('NUM_IDE').withTitle($translate.instant('Identifiant Unique')).withOption('width', '10%').renderWith(oeExp),
		DTColumnBuilder.newColumn(null).withTitle($translate.instant('Dossier technique ')).withOption('width', '10%').renderWith(function(data, type) {
			return '<label class="directive-lien"> '+data.IDDEPOT+'<span depotdetaillovlibre iddepot="'+data.IDDEPOT+'" idagenda="'+data.ID+'" ></span></label>'
			}),
			DTColumnBuilder.newColumn('DATE_CRE').withTitle($translate.instant('Date création ')).withOption('width', '10%'),

		DTColumnBuilder.newColumn('RAISON_SOCIALE').withTitle($translate.instant('Raison sociale')).withOption('width', '20%'),
		DTColumnBuilder.newColumn(null).withTitle($translate.instant('Désignation ')).withOption('width', '10%').renderWith(function(data, type) {
			return '<label class="directive-lien"> '+data.DESIGNATION+'<span produitdetaillovlibre idproduit="'+data.IDPRODUIT+'" ></span></label>'
			}),
		DTColumnBuilder.newColumn('NGP_NDP').withTitle($translate.instant('Code NGP')).withOption('width', '10%'),
		DTColumnBuilder.newColumn('LIBELLE_REF').withTitle($translate.instant('Statut')).withOption('width', '20%'),


		];
	 
	 function oeExp (data, type, full, meta){
			return '<label class="directive-lien"> '+data+'<span oeexpediteurdetaillovlibre idoe="'+data+'" ></span></label>'
		}
	
		 

		function createdRow(row, data, dataIndex) {
		$compile(angular.element(row).contents())($scope);
		}

		  		
}
})();
