(function() {
'use strict';
angular
	.module('infochamApp')
		.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('listeReference', {
        parent: 'entity',
        url: '/listeReferences',
        data: {
        	  authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],		
            pageTitle: 'infochamApp.listeReference.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/listeReference/listeReferences.html',
                controller: 'ListeReferenceController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('listeReference');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    .state('listeReference.detail', {
        parent: 'entity',
        url: '/listeReference/{id}',
        data: {
      	  authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],		
            pageTitle: 'infochamApp.listeReference.detail.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/listeReference/listeReference-detail.html',
                controller: 'ListeReferenceDetailController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('listeReference');
                return $translate.refresh();
            }],
            entity: ['$stateParams', 'ListeReference', function($stateParams, ListeReference) {
                return ListeReference.get({id : $stateParams.id});
            }]
        }
    })
    .state('listeReference.new', {
        parent: 'listeReference',
        url: '/new',
        data: {
      	  authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],		
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/listeReference/listeReference-dialog.html',
                controller: 'ListeReferenceDialogController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            codeListe: null,
                            designationListe: null,
                            status: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(result) {
                $state.go('listeReference', null, { reload: true });
            }, function() {
                $state.go('listeReference');
            })
        }]
    })
    .state('listeReference.edit', {
        parent: 'listeReference',
        url: '/{id}/edit',
        data: {
      	  authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],		
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/listeReference/listeReference-dialog.html',
                controller: 'ListeReferenceDialogController',
                size: 'lg',
                resolve: {
                    entity: ['ListeReference', function(ListeReference) {
                        return ListeReference.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('listeReference', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    .state('listeReference.delete', {
        parent: 'listeReference',
        url: '/{id}/delete',
        data: {
      	  authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],		
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/listeReference/listeReference-delete-dialog.html',
                controller: 'ListeReferenceDeleteController',
                size: 'md',
                resolve: {
                    entity: ['ListeReference', function(ListeReference) {
                        return ListeReference.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('listeReference', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    });		
}

})();

