(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeReferentielDeleteController', TypeReferentielDeleteController);

	TypeReferentielDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'TypeReferentiel'];

	function TypeReferentielDeleteController ($scope, $uibModalInstance, entity, TypeReferentiel) {

        $scope.typeReferentiel = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            TypeReferentiel.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
