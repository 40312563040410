(function() {
	'use strict';
	angular.module('infochamApp').controller('AgendaPaiementEditController',
			AgendaPaiementEditController);
	AgendaPaiementEditController.$inject = [ 'ReportingService',
			'Inscription2', 'Certificat', 'DTColumnDefBuilder', '$rootScope',
			'fileUpload', 'Agenda', 'Referentiel', '$stateParams',
			'$translate', '$scope', 'Principal', '$state',
			'$http', '$compile', 'calendarConfig', 'moment', 'AuditsService',
			'$filter', 'DTOptionsBuilder', 'DTColumnBuilder' ];
	function AgendaPaiementEditController(ReportingService, Inscription2,
			Certificat, DTColumnDefBuilder, $rootScope, fileUpload, Agenda,
			Referentiel, $stateParams, $translate, $scope,
			Principal, $state, $http, $compile, calendarConfig, moment,
			AuditsService, $filter, DTOptionsBuilder, DTColumnBuilder) {
		$scope.dtInstance = {};
		$scope.paiementVirement = {};
		$scope.paiementCheque = {};
		$scope.paiementEspece = {};
		$scope.numTmoney = 0;
		$scope.numFlouss = 0;
		$scope.RIB = 0;

		$scope.verifPaiementMobile = false;
		$scope.verifPaiementEspece = false;
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});

		Certificat.getNumTmoney().then(function(result) {
			$scope.numTmoney = result.data.valeur;
		});

		Certificat.getNumFlouss().then(function(result) {
			$scope.numFlouss = result.data.valeur;
		});

		Certificat.getRib().then(function(result) {
			$scope.RIB = result.data.valeur;
		});

		$scope.verifMobile = function(numPaiement) {

			Inscription2.verifPaiementMobile(numPaiement).then(
					function(result1) {
						if (result1.data != null && result1.data != "") {
							$scope.verifPaiementMobile = true;
						} else {
							$scope.verifPaiementMobile = false;

						}
					})

		};
		$scope.verifEspece = function(numPaiementEspece) {

			Inscription2.verifPaiementEspece(numPaiementEspece).then(
					function(result1) {
						$scope.verifPaiementEspece = result1.data;
					})
		};

		$scope.idAgenda = $stateParams.id;
		Certificat
				.getDemandeByProcess($scope.idAgenda)
				.then(
						function(result) {
							$scope.agenda = result.data.agenda;

							$scope.facture = result.data.facture;
							$scope.autresServices = result.data.autresServices
							$scope.detailFacture = result.data.detailFactureList;
							$scope.modeDepot = result.data.modeDepot;
							$http
									.get(
											"api/listBouton/"
													+ $scope.facture.process.idCase)
									.then(
											function(result) {
												// récupérer key(id du bouton =
												// nextStep) and value(libelle
												// du bouton)
												$scope.listButton = result.data;
												// parcour :
												// ng-repeat="(key,value) in
												// listButton" pour distinguer
												// entre les ids des boutons
												// lors du clic
												var html = ' <div ng-show="facture.modePaiement==\'Chèque\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid || form4.$invalid || isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>'
														+ ' <div ng-show="facture.modePaiement==\'Virement\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid || form6.$invalid || isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>'
														+ ' <div ng-show="facture.modePaiement==\'Mobile\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid || form5.$invalid || isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>'
														+ ' <div ng-show="facture.modePaiement==\'Espèce\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid ||form7.$invalid ||  isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>';
												// ajouter le ou les boutons
												// dans la balise boutonWK
												// l'id #boutonWK est ajouté au
												// niveau html
												angular
														.element("#boutonWK")
														.append(
																$compile(html)(
																		$scope));
											})
							Certificat.getMontantAdhesion().then(
									function(result) {
										$scope.montant = result.data;
									});
							$scope.dtOptions = DTOptionsBuilder
									.newOptions()
									.withOption(
											'ajax',
											{
												url : 'api/getCommentairePaiementInscriptionByProcess/'
														+ $scope.idAgenda,
												type : 'GET',
											})
									.withOption('createdRow', createdRow)
									.withPaginationType('full_numbers')
									.withOption('order', [ 0, 'asc' ])
									.withDOM(
											"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
									.withBootstrap()
									.withLanguageSource(
											$translate
													.instant('global.datatable'))
									.withLightColumnFilter({

										'0' : {
											type : 'text'
										},
										'1' : {
											type : 'text'
										},
										'2' : {
											type : 'text'
										}
									})
							$scope.dtColumns = [
									DTColumnBuilder.newColumn('note')
											.withTitle('Description')
											.withOption('width', '30%'),
									DTColumnBuilder.newColumn('dateCreation')
											.withTitle('Date').withOption(
													'width', '30%').renderWith(
													function(data, type) {
														return $filter('date')(
																data,
																'dd/MM/yyyy');
													}), ];
							if ($scope.facture.modePaiement == "Chèque") {

								Certificat
										.getPaiementChequeByFacture(
												$scope.facture.id)
										.then(
												function(result) {
													$scope.paiementCheque = result.data
													$scope.paiementCheque.dateCheque = new Date(
															$scope.paiementCheque.dateCheque)
												});
							} else if ($scope.facture.modePaiement == "Virement") {
								Certificat
										.getPaiementVirementByFacture(
												$scope.facture.id)
										.then(
												function(result) {
													$scope.paiementVirement = result.data
													$scope.paiementVirement.dateVirement = new Date(
															$scope.paiementVirement.dateVirement)
												});
							} else if ($scope.facture.modePaiement == "Mobile") {
								Certificat
										.getPaiementMobileByFacture(
												$scope.facture.id)
										.then(
												function(result) {
													$scope.paiementMobile = result.data
													$scope.numPaiMobileOld = result.data.numPaiement;
												});
							} else if ($scope.facture.modePaiement == "Espèce") {
								Certificat
										.getPaiementEspeceByFacture(
												$scope.facture.id)
										.then(
												function(result) {
													$scope.paiementEspece = result.data
													$scope.numRecuOld = result.data.numRecu;
													if ($scope.paiementEspece != null
															&& $scope.paiementEspece != "") {
														$scope.paiementEspece.numRecu = parseInt($scope.paiementEspece.numRecu);
													}
												});
							}
						});

		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}

		/* get file dec tresor from alfreco */
		$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2.downloadDocument(docId)
					.then(
							function(result) {
								if (angular.equals({
									status : result.status
								}, {
									status : 500
								})) {
									swal(message, "Un problème s'est produit!",
											"error")
								}
								var fName = result
										.headers('Content-Disposition');
								var file = new Blob([ result.data ], {
									type : 'application/pdf'
								});
								var fileURL = (window.URL || window.webkitURL)
										.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
		}
		$scope.traitement = function(keyBouton, value, modePaiement,
				paiementVirement, paiementCheque, paiementMobile,
				paiementEspece) {
			$scope.errors = null;

			$scope.authenticationError = false;
			if (modePaiement == null || modePaiement == undefined
					|| modePaiement == "") {
				$scope.errors = "Le mode de paiement est requis";
			}
			if ((modePaiement != null && modePaiement != undefined && modePaiement != "")) {
				if (modePaiement == "Mobile") {
					if (paiementMobile == null || paiementMobile == undefined
							|| paiementMobile == "") {
						$scope.errors = "ID de transaction est requis";
					}

					if ($scope.verifPaiementMobile == true
							&& paiementMobile.numPaiement != $scope.numPaiMobileOld) {
						$scope.errors = "Id de transaction déjà utilisé.";
					}

				} else if (modePaiement == "Virement") {
					if (paiementVirement == null
							|| paiementVirement == undefined
							|| paiementVirement == "") {
						$scope.errors = "La banque est requis";
					} else if (paiementVirement != null
							&& paiementVirement != undefined
							&& paiementVirement != "") {
						if (paiementVirement.dateVirement == null
								|| paiementVirement.dateVirement == undefined
								|| paiementVirement.dateVirement == "") {
							$scope.errors = "La date du virement est requis";
						} else if (paiementVirement.banque == null
								|| paiementVirement.banque == undefined
								|| paiementVirement.banque == "") {
							$scope.errors = "La banque est requis";
						} else if (paiementVirement.numVirement == null
								|| paiementVirement.numVirement == undefined
								|| paiementVirement.numVirement == "") {
							$scope.errors = "Le numéro du virement est requis";
						} else if (paiementVirement.agence == null
								|| paiementVirement.agence == undefined
								|| paiementVirement.agence == "") {
							$scope.errors = "L'agence du virement est requis";
						}

					}
				}

				else if (modePaiement == "Chèque") {
					if (paiementCheque == null || paiementCheque == undefined
							|| paiementCheque == "") {
						$scope.errors = "La banque est requis";
					} else if (paiementCheque != null
							&& paiementCheque != undefined
							&& paiementCheque != "") {
						if (paiementCheque.dateCheque == null
								|| paiementCheque.dateCheque == undefined
								|| paiementCheque.dateCheque == "") {
							$scope.errors = "La date du chèque est requis";
						} else if (paiementCheque.banque == null
								|| paiementCheque.banque == undefined
								|| paiementCheque.banque == "") {
							$scope.errors = "La banque est requis";
						} else if (paiementCheque.numCheque == null
								|| paiementCheque.numCheque == undefined
								|| paiementCheque.numCheque == "") {
							$scope.errors = "Le numéro du chèque est requis";
						} else if (paiementCheque.agence == null
								|| paiementCheque.agence == undefined
								|| paiementCheque.agence == "") {
							$scope.errors = "L'agence du chèque est requis";
						}

					}
				} else if (modePaiement == "Espèce") {
					if (paiementEspece.numRecu == null
							|| paiementEspece.numRecu == undefined
							|| paiementEspece.numRecu == "") {
						$scope.errors = "ID de transaction est requis";
					}
					if ($scope.verifPaiementEspece == true
							&& paiementEspece.numRecu != $scope.numRecuOld) {
						$scope.errors = "Numéro de reçu  déjà utilisé.";
					}

				}
			}

			if ($scope.errors != null) {
				$scope.authenticationError = true;
				$("html, body").animate({
					scrollTop : 300
				}, "slow");
			}

			if ($scope.authenticationError == false) {
				if ($scope.facture.modePaiement == "Virement") {
					Certificat.paiementVirement(paiementVirement,
							$scope.idAgenda, $scope.facture.modePaiement,
							$scope.montant, $scope.RIB).then(
							function(result) {
								var uploadUrl = "api/uploadFileVirement/"
										+ result.data.id;
								var file = $scope.docPaiement;
								if (typeof file != 'undefined') {
									var result = fileUpload.uploadFileToUrl(
											file, uploadUrl).then(
											function(result) {
												$scope.piece = result.data;
											});
								}
							});
				}
				if ($scope.facture.modePaiement == "Chèque") {
					Certificat.paiementCheque(paiementCheque, $scope.idAgenda,
							$scope.facture.modePaiement, $scope.montant).then(
							function(result) {
								var uploadUrl = "api/uploadFileCheque/"
										+ result.data.id;
								var file = $scope.docPaiement;
								if (typeof file != 'undefined') {
									var result = fileUpload.uploadFileToUrl(
											file, uploadUrl).then(
											function(result) {
												$scope.piece = result.data;
											});
								}
							});
				}
				if ($scope.facture.modePaiement == "Mobile") {
					Certificat.paiementMobile(paiementMobile, $scope.idAgenda,
							$scope.facture.modePaiement, $scope.montant).then(
							function(result) {
							});

					if ($scope.verifPaiementMobile == true) {
						$scope.errors = "Id de transaction déjà utilisé.";
					}

				}
				if ($scope.facture.modePaiement == "Espèce") {
					Certificat.paiementEspece(paiementEspece, $scope.idAgenda,
							modePaiement, $scope.montant).then(
							function(result) {
								var uploadUrl = "api/uploadFileEspece/"
										+ result.data.id;
								var file = $scope.docPaiement;
								if (typeof file != 'undefined') {
									var result = fileUpload.uploadFileToUrl(
											file, uploadUrl).then(
											function(result) {
												$scope.piece = result.data;
											});
								}
							});
				}
				Inscription2.updateProcInscription($scope.idAgenda, keyBouton,
						value).then(function(result) {
					$state.go('home', null, {
						reload : true
					});
				});
			}
		}
		$scope.lovModel1 = 'api/findAllBanque';// this will be
		// the LOV
		// datasource
		$scope.lovTitle1 = 'Liste des banques';
		$scope.lovColumnList1 = [ 'denomination' ];
		$scope.lovFieldList1 = [ 'denomination' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack1 = function(result) {
			$scope.paiementVirement.banque = result;
		}

		$scope.lovModel2 = 'api/findAllBanque';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des banques';
		$scope.lovColumnList2 = [ 'denomination' ];
		$scope.lovFieldList2 = [ 'denomination' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.paiementCheque.banque = result;
		}

		$scope.$on('eventEmitedPaiement', function(event, data) {
			$scope.docPaiement = data.message;
		});
		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption(
						'ajax',
						{
							url : 'api/getCommentairePaiementInscriptionByProcess/'
									+ $scope.idAgenda,
							type : 'GET',

						})
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					}
				})
		$scope.dtColumns = [
				DTColumnBuilder.newColumn('note').withTitle('Description')
						.withOption('width', '30%'),
				DTColumnBuilder.newColumn('dateCreation').withTitle('Date')
						.withOption('width', '30%').renderWith(
								function(data, type) {
									return $filter('date')(data, 'dd/MM/yyyy');
								}), ];

		$scope.noteHonoraire = function() {
			ReportingService.downloadNoteHonoraire($scope.idAgenda).then(
					function(result) {
						var fileName = "Note honoraire"
						ReportingService.downloadPdfFile(result, fileName)
					});
		}
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}

		$(function() {
			var active = true;

			$('#collapse-init').click(function() {
				if (active) {
					active = false;
					$('.panel-collapse').collapse('show');
					$('.panel-title').attr('data-toggle', '');
					$(this).text('Enable accordion behavior');
				} else {
					active = true;
					$('.panel-collapse').collapse('hide');
					$('.panel-title').attr('data-toggle', 'collapse');
					$(this).text('Disable accordion behavior');
				}
			});

			$('#myAccordion2').on('show.bs.collapse', function() {
				if (active) {
					$('#myAccordion2 .in').collapse('hide');
				}
			});
			$('#myAccordion2').on('hide.bs.collapse', function() {
			});
		});
		$('#myAccordion2 .panel-collapse').on(
				'hidden.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-minus").addClass("fa fa-plus");
					// $scope.statut=false;
				});
		$('#myAccordion2 .panel-collapse').on(
				'shown.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-plus").addClass("fa fa-minus");
				});
		function renderer(api, rowIdx, columns) {
			var data = $.map(
					columns,
					function(col, i) {
						return col.hidden ? '<li data-dtr-index="'
								+ col.columnIndex + '" data-dt-row="'
								+ col.rowIndex + '" data-dt-column="'
								+ col.columnIndex + '">'
								+ '<span class="dtr-title">' + col.title
								+ '</span> ' + '<span class="dtr-data">'
								+ col.data + '</span>' + '</li>' : '';
					}).join('');
			return data ? $compile(
					angular.element($('<ul data-dtr-index="' + rowIdx + '"/>')
							.append(data)))($scope) : false;
		}
	}

})();