(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('AffectationRoleDeleteController', AffectationRoleDeleteController);

    AffectationRoleDeleteController.$inject = ['$stateParams', '$http', '$scope', '$uibModalInstance'];

    function AffectationRoleDeleteController ($stateParams, $http, $scope, $uibModalInstance) {
    	$scope.clear = function() {
    		$uibModalInstance.dismiss('cancel');
    	};
    	var id = $stateParams.id;
    	$scope.confirmDelete = function (idAffectation) {
    		$http.delete("api/actorMember/"+id).then(function(result){
    		})
    		$uibModalInstance.close(true);
    	}
}
})();
