(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'certificatOrigine',
						{
							parent : 'entity',
							url : '/certificatOrigines',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.certificatOrigine.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/certificatOrigines.html',
									controller : 'CertificatOrigineController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('certificatOrigine');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'certificatOrigine.detail',
						{
							parent : 'entity',
							url : '/certificatOrigine/{id}',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.certificatOrigine.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/certificatOrigine-detail.html',
									controller : 'CertificatOrigineDetailController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('certificatOrigine');
											return $translate.refresh();
										} ],
								entity : [
										'$stateParams',
										'CertificatOrigine',
										function($stateParams,
												CertificatOrigine) {
											return CertificatOrigine.get({
												id : $stateParams.id
											});
										} ]
							}
						})
				.state(
						'certificatOrigine.new',
						{
							parent : 'certificatOrigine',
							url : '/new',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/certificatOrigine-dialog.html',
													controller : 'CertificatOrigineDialogController',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																numeroCo : null,
																infTransport : null,
																remarque : null,
																commentaire : null,
																id : null
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'certificatOrigine',
																			null,
																			{
																				reload : true
																			});
														},
														function() {
															$state
																	.go('certificatOrigine');
														})
									} ]
						})
				.state(
						'certificatOrigine.edit',
						{
							parent : 'certificatOrigine',
							url : '/{id}/edit',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/certificatOrigine-dialog.html',
													controller : 'CertificatOrigineDialogController',
													size : 'lg',
													resolve : {
														entity : [
																'CertificatOrigine',
																function(
																		CertificatOrigine) {
																	return CertificatOrigine
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'certificatOrigine',
																			null,
																			{
																				reload : true
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})
				.state(
						'certificatOrigine.delete',
						{
							parent : 'certificatOrigine',
							url : '/{id}/delete',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/certificatOrigine-delete-dialog.html',
													controller : 'CertificatOrigineDeleteController',
													size : 'md',
													resolve : {
														entity : [
																'CertificatOrigine',
																function(
																		CertificatOrigine) {
																	return CertificatOrigine
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'certificatOrigine',
																			null,
																			{
																				reload : true
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})

				.state(
						'consultAllCO',
						{
							parent : 'home',
							url : 'consult-all-CO',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO','ROLE_DEPOT',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/rechercheCO/consult-all-CO.html',
									controller : 'ConsultAllCOController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
						.state(
						'consultAllCOOE',
						{
							parent : 'home',
							url : 'consult-all-CO-OE',
							data : {
//								authorities : [ 'ROLE_RESPONSABLE_CO','ROLE_DEPOT',
//										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/rechercheCO/consult-all-CO-OE.html',
									controller : 'ConsultAllCOOEController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

	}
})();
