(function() {
	'use strict';
	angular
	.module('infochamApp')
	.directive('effectifdirective', effectifdirective)

	effectifdirective.$inject = ['$rootScope'];

	function effectifdirective ($rootScope) {
		return {
			restrict: "E",
			scope: {
				
				effectifTotal:'=',
			},
			scope: false,
			templateUrl: 'app/directives/effectif-directive.html',
			controller:['Inscription2','blockUI', '$timeout','fileReader' ,'fileUpload' ,'FileUploader' ,
				'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope', '$state', 'ParseLinks', 'Agenda' ,
				'$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'CustomNaturePieceJointe',  'DTColumnBuilder','$http', 'Principal', '$window', '$sce', function (Inscription2,blockUI, $timeout,fileReader ,fileUpload ,FileUploader ,
					DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, $state, ParseLinks, Agenda ,
					$rootScope, $compile, $translate, DTOptionsBuilder, CustomNaturePieceJointe,  DTColumnBuilder,$http, Principal, $window, $sce){
				$scope.listWithFile = false;
//				$scope.pourcentage = false ;
				$scope.typeAttach = 'N'
//				$scope.listeffectif = [];
				$scope.addeffectif = function() {
					$scope.listeffectif.push({});
				}
				
				
//				$http.get('api/referentiels/getByCode/FONCTION').then(function(result) {
//					$scope.listFonction = result.data;
//				});
				
				
				$scope.changeType = function(rep) {
					if (rep == "O") {
						$scope.listeffectif = [];
					} else {
						
					}
				}
				
				$scope.hasFile = function(rep) {
					if (rep == "O") {
						$scope.listWithFile = true;
					} else {
						$scope.listWithFile = false;
					}
					$scope.$emit('eventEmitedlistWithFile',{message:$scope.listWithFile});
				}
				
				
				$scope.changeNb = function() {
//					if(((100 * $scope.effectifmobilise) / $scope.effectifTotal) > 30) {
//						$scope.pourcentage = true ;
//					}
//					else {
//						$scope.pourcentage = false ;
//					}
					$scope.$emit('eventEmitedNbEffectif',{message:$scope.effectifmobilise});
				}
//			
				
				$scope.deleteEffectif = function(index) {

						$scope.listeffectif.splice(index, 1);
				}
				
				$scope.code="MODELE" ;
				CustomNaturePieceJointe.getPieceJointeByCode($scope.code).then(function (result){
					$scope.piece = result.data ;
					$scope.idPiece  = result.data.id ;
					
					
					
				});
				$scope.help = function() {
		swal({
			position: 'center',
			title: 'Votre demande a été ajoutée avec succès',
			showConfirmButton: true,
		    text :'aaa',
		})
	}
				
				
				$scope.downloadDecharge = function() {
					
					CustomNaturePieceJointe.getPieceJointeByCode($scope.code).then(function (result){
						$scope.piece = result.data[0] ;
						$scope.idPiece  = result.data[0].id ;
					var downloadLink = document.createElement("a");
					document.body.appendChild(downloadLink);
					downloadLink.style = "display: none";
					Agenda
					.downloadDocument($scope.idPiece)
					.then(
							function(result) {
								if(angular.equals( { status: result.status }, { status: 500 } )){
									swal(message,"Un problème s'est produit!", "error")
								}
								var fName = result
								.headers('Content-Disposition');
								var file = new Blob(
										[ result.data ],
										{
											type : 'application/pdf'
										});
								var fileURL = (window.URL || window.webkitURL)
								.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
					
					});
				}
				
				


			}]
		};

}
	

})();
