(function() {
    'use strict';

    angular
        .module('infochamApp')
        .directive('uploadnoteDirective', uploadnoteDirective)

    uploadnoteDirective.$inject = ['$rootScope'];
    function uploadnoteDirective($rootScope) {
        return {
			restrict: 'E',
			//passer deux paramétres pour la directive
			scope: {
				//passer le nom de l'entité
				valide:'=',
				mode:'=',
				data:'=',
				idagenda:'=',
				codeprocess: '=',
				codestatut: '=',
				//passer l'id de l'entité
			},
			templateUrl: 'app/directives/motifNoteProcess-directive.html',
			controller:['fileUpload' ,'FileUploader' ,'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope', '$state', 'ParseLinks',  '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder','$http', 'Principal', function (fileUpload ,FileUploader ,DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, $state, ParseLinks,  $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder,$http, Principal){
				 $scope.dtInstance={};
				 if($scope.idagenda==undefined || $scope.idagenda==null){
				 	$scope.idagenda=-1;
				 }
				 				$scope.dtOptions = DTOptionsBuilder
				 				.newOptions()
				 				.withOption('ajax', {
				 					url: 'api/findNoteByProcess/'+$scope.idagenda+'/'+$scope.codeprocess,
				 					type: 'GET',
				 				})
				 			.withOption('createdRow', createdRow)
				 			.withPaginationType('full_numbers')
				 			.withOption('order', [0, 'asc'])
				 			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				 			.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({
				 			
				 				'0' : {
				 					type : 'text'
				 				},
				 				'1' : {
				 					type : 'text'
				 				},
				 				'2' : {
				 					type : 'text'
				 				}
				 			})
				 			$scope.dtColumns = [
				 DTColumnBuilder.newColumn('idUserCre.login').withTitle('Créateur').withOption('width', '20%'),	
				 DTColumnBuilder.newColumn('description').withTitle('Description').withOption('width', '60%'),
				 DTColumnBuilder.newColumn('dateCre').withTitle('Date').withOption('width', '20%').renderWith(function(data, type) {
				 return $filter('date')(data, 'dd/MM/yyyy HH:mm');
				 }),
				 				];
				 				
				 			  function createdRow(row, data, dataIndex) {
				 				  $compile(angular.element(row).contents())($scope);
				 				  }
				 			}]
		};
    }
})();


