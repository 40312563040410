(function() {
	'use strict';
	angular.module('infochamApp').controller('AgendaPaiementConsultController',
			AgendaPaiementConsultController);
	AgendaPaiementConsultController.$inject = [ 'blockUI', 'ReportingService',
			'Inscription2', 'Certificat', 'DTColumnDefBuilder', '$rootScope',
			'fileUpload', 'Agenda', 'Referentiel', '$stateParams',
			'$translate', '$scope', 'Principal', '$state',
			'$http', '$compile', 'calendarConfig', 'moment', 'AuditsService',
			'$filter', 'DTOptionsBuilder', 'DTColumnBuilder' ];
	function AgendaPaiementConsultController(blockUI, ReportingService,
			Inscription2, Certificat, DTColumnDefBuilder, $rootScope,
			fileUpload, Agenda, Referentiel, $stateParams, $translate,
			 $scope, Principal, $state, $http, $compile,
			calendarConfig, moment, AuditsService, $filter, DTOptionsBuilder,
			DTColumnBuilder) {
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});
		blockUI.stop();
		$scope.dtInstance = {};
		$scope.idAgenda = $stateParams.id;
		Certificat
				.getDemandeByProcess($scope.idAgenda)
				.then(
						function(result) {
							$scope.agenda = result.data.agenda;
							$scope.facture = result.data.facture;
							$scope.modeDepot = result.data.modeDepot;
							$scope.imageUser = $scope.agenda.createur.image;
							$http
									.get(
											"api/listBouton/"
													+ $scope.facture.process.idCase)
									.then(
											function(result) {
												$scope.listButton1 = {};
												// récupérer key(id du bouton =
												// nextStep) and value(libelle
												// du bouton)
												$scope.listButton = result.data;
												$scope.listButton1["COMPLEMENTPAYINSCRIPTION"] = "Demander des informations complémentaires";
												$scope.listButton1["PAYVALIDINSCRIPTION"] = "Valider";
												// parcour :
												// ng-repeat="(key,value) in
												// listButton" pour distinguer
												// entre les ids des boutons
												// lors du clic
												var html = ' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
												// ajouter le ou les boutons
												// dans la balise boutonWK
												// l'id #boutonWK est ajouté au
												// niveau html
												angular
														.element("#boutonWK")
														.append(
																$compile(html)(
																		$scope));
											})
							Certificat.getMontantAdhesion().then(
									function(result) {
										$scope.montant = result.data;
									});
							if ($scope.facture.modePaiement == "Chèque") {
								Certificat.getPaiementChequeByFacture(
										$scope.facture.id).then(
										function(result) {
											$scope.paiement = result.data
										});
							} else if ($scope.facture.modePaiement == "Mobile") {
								Certificat.getPaiementMobileByFacture(
										$scope.facture.id).then(
										function(result) {
											$scope.paiement = result.data
										});
							} else if ($scope.facture.modePaiement == "Virement") {
								Certificat.getPaiementVirementByFacture(
										$scope.facture.id).then(
										function(result) {
											$scope.paiement = result.data
										});
							} else if ($scope.facture.modePaiement == "Espèce") {
								Certificat.getPaiementEspeceByFacture(
										$scope.facture.id).then(
										function(result) {
											$scope.paiement = result.data
										});
							}
							$scope.dtOptions = DTOptionsBuilder
									.newOptions()
									.withOption(
											'ajax',
											{
												url : 'api/getCommentairePaiementInscriptionByProcess/'
														+ $scope.idAgenda,
												type : 'GET',
											})
									.withOption('createdRow', createdRow)
									.withPaginationType('full_numbers')
									.withOption('order', [ 0, 'asc' ])
									.withDOM(
											"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
									.withBootstrap()
									.withLanguageSource(
											$translate
													.instant('global.datatable'))
									.withLightColumnFilter({

										'0' : {
											type : 'text'
										},
										'1' : {
											type : 'text'
										},
										'2' : {
											type : 'text'
										}
									})
							$scope.dtColumns = [
									DTColumnBuilder.newColumn('note')
											.withTitle('Description')
											.withOption('width', '30%'),
									DTColumnBuilder.newColumn('dateCreation')
											.withTitle('Date').withOption(
													'width', '30%').renderWith(
													function(data, type) {

														return $filter('date')(
																data,
																'dd/MM/yyyy');
													}), ];
						});

		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}
		/* get file dec tresor from alfreco */
		$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2.downloadDocument(docId)
					.then(
							function(result) {
								if (angular.equals({
									status : result.status
								}, {
									status : 500
								})) {
									swal(message, "Un problème s'est produit!",
											"error")
								}
								var fName = result
										.headers('Content-Disposition');
								var file = new Blob([ result.data ], {
									type : 'application/pdf'
								});
								var fileURL = (window.URL || window.webkitURL)
										.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
		}
		$scope.traitement = function(keyBouton, value) {
			if (keyBouton == "COMPLEMENTPAYINSCRIPTION") {
				$state
						.go(
								'commentaire-detail-demandeInscription-infoComplementaire',
								{
									id : $scope.idAgenda,
									idProcess : $scope.agenda.idCase,
									key : keyBouton,
									value : value
								}, {
									reload : true
								});
			} else {
				Inscription2.updateProcInscription($scope.idAgenda, keyBouton,
						value).then(function(result) {
					$state.go('home', null, {
						reload : true
					});
				});
			}
		}

		$scope.lovModel1 = 'api/findAllBanque';// this will be
		// the LOV
		// datasource
		$scope.lovTitle1 = 'Liste des banques';
		$scope.lovColumnList1 = [ 'denomination' ];
		$scope.lovFieldList1 = [ 'denomination' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack1 = function(result) {
			$scope.paiementVirement.banque = result;
		}

		$scope.lovModel2 = 'api/findAllBanque';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des banques';
		$scope.lovColumnList2 = [ 'denomination' ];
		$scope.lovFieldList2 = [ 'denomination' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.paiementCheque.banque = result;
		}
		$scope.$on('eventEmitedPaiement', function(event, data) {
			$scope.docPaiement = data.message;
		});
		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption(
						'ajax',
						{
							url : 'api/getCommentairePaiementByProcess/'
									+ $scope.idAgenda,
							type : 'GET',

						})
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					}
				})

		$scope.dtColumns = [
				DTColumnBuilder.newColumn('note').withTitle('Description')
						.withOption('width', '30%'),
				DTColumnBuilder.newColumn('dateCreation').withTitle('Date')
						.withOption('width', '30%').renderWith(
								function(data, type) {
									return $filter('date')(data, 'dd/MM/yyyy');
								}), ];
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}
		$(function() {
			var active = true;

			$('#collapse-init').click(function() {
				if (active) {
					active = false;
					$('.panel-collapse').collapse('show');
					$('.panel-title').attr('data-toggle', '');
					$(this).text('Enable accordion behavior');
				} else {
					active = true;
					$('.panel-collapse').collapse('hide');
					$('.panel-title').attr('data-toggle', 'collapse');
					$(this).text('Disable accordion behavior');
				}
			});

			$('#myAccordion2').on('show.bs.collapse', function() {
				if (active) {
					$('#myAccordion2 .in').collapse('hide');
				}
			});
			$('#myAccordion2').on('hide.bs.collapse', function() {
			});
		});
		$('#myAccordion2 .panel-collapse').on(
				'hidden.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-minus").addClass("fa fa-plus");
					// $scope.statut=false;
				});
		$('#myAccordion2 .panel-collapse').on(
				'shown.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-plus").addClass("fa fa-minus");
				});
		function renderer(api, rowIdx, columns) {
			var data = $.map(
					columns,
					function(col, i) {
						return col.hidden ? '<li data-dtr-index="'
								+ col.columnIndex + '" data-dt-row="'
								+ col.rowIndex + '" data-dt-column="'
								+ col.columnIndex + '">'
								+ '<span class="dtr-title">' + col.title
								+ '</span> ' + '<span class="dtr-data">'
								+ col.data + '</span>' + '</li>' : '';
					}).join('');
			return data ? $compile(
					angular.element($('<ul data-dtr-index="' + rowIdx + '"/>')
							.append(data)))($scope) : false;
		}
	}

})();
