(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ParamMobileDetailController',ParamMobileDetailController);
ParamMobileDetailController.$inject=['$scope', '$rootScope','$stateParams', 'entity', 'ParamMobile'];
function ParamMobileDetailController($scope, $rootScope, $stateParams, entity, ParamMobile){
	$scope.ParamMobile = entity;
    $scope.load = function (id) {
    	ParamMobile.get({id: id}, function(result) {
            $scope.ParamMobile = result;

        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:ParamMobileUpdate', function(event, result) {
        $scope.ParamMobile = result;
    });
    $scope.$on('$destroy', unsubscribe);
}

})();
