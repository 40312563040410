(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ListeReferenceController',ListeReferenceController);
ListeReferenceController.$inject=['$scope', '$state', 'ListeReference', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', '$resource', 'ReportingService'];
function ListeReferenceController($scope, $state, ListeReference, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, $resource, ReportingService){
	function createdRow(row, data, dataIndex) {
		// Recompiling so we can bind Angular directive to the DT
		$compile(angular.element(row).contents())($scope);
	}
	$scope.myAccordionStatut = true;
	$scope.opened = function() {
		if ($scope.myAccordionStatut)
			return "#collapse2";
	}
	$scope.selected = null;
	$scope.reset = function() {
		$state.go('listeReference', null, {
			reload : true
		});
	}
	function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

		// Unbind first in order to avoid any duplicate handler
		$('td', nRow).unbind('click');
		$('td', nRow).bind('click', function() {
			$scope.$apply(function() {
				if ($scope.selected != null) {
					$scope.selected.removeClass('selected');
				}
				$(nRow).addClass('selected');
				$scope.search = aData.id;
				$scope.selected = $(nRow);
				// faire le traitement nécessaire après le click

			});
		});
		$compile(angular.element(nRow).contents())($scope);
	}
	$scope.searchImportateurs = function() {
		var idPcp;
		var idRef;
		if ($scope.pcp == null || undefined) {
			idPcp = -1;
		} else {
			idPcp = $scope.pcp.id;
		}
		if ($scope.listeRef == null || undefined) {
			idRef = -1;
		} else {
			idRef = $scope.listeRef.id;
		}
		$scope.criteres = "'" + idPcp + "'";
		var lien = 'api/getListRef/datatable/' + idPcp + '/'+idRef;

		$scope.dtOptions = DTOptionsBuilder.fromSource(lien)

		// or here
		.withDataProp('data').withDisplayLength(10).withOption('processing', true).withOption('serverSide', true).withOption('createdRow', createdRow).withPaginationType('full_numbers').withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")

		.withButtons([ {
			extend : 'colvis',
			className : 'btn dark btn-outline',
			text : 'Columns' + '<i class="fa fa-angle-down"></i>',
		}, {
			extend : "excel",
			text : 'Excel',
			className : 'buttons-html5 btn red btn-outline',
			title : "Listes des références",
			exportOptions : {
				columns : [ 0, 1, 2, 3 ]
			},
			// CharSet: "utf8",
			exportData : {
				decodeEntities : true
			}
		}, {
			extend : "pdf",
			text : 'PDF',
			className : 'buttons-html5 btn yellow btn-outline',
			title : "Listes des références",
			exportOptions : {
				columns : [ 0, 1, 2, 3 ]
			},
			action : function() {

				var fileName = 'Liste des listes des références';

				ReportingService.downloadReportListRef($scope.criteres).then(function(result) {
					ReportingService.downloadPdfFile(result, fileName)
				});
			}
		}, {
			extend : 'print',
			text : 'Print',
			className : 'buttons-html5 btn green btn-outline',
			title : "Listes des références",
			// text: 'Print current page',
			autoPrint : true,
			exportOptions : {
				columns : [ 0, 1, 2, 3 ]
			}
		}, {
			extend : 'copy',
			text : 'Copy',
			className : 'table-margin buttons-html5 btn blue btn-outline',
			title : "Listes des références",
			// text: 'Print current page',

			exportOptions : {
				columns : [ 0, 1, 2, 3 ]
			}
		}

		])

		.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			}
			,
			'4' : {
				type : 'text'
			}
		})
	}
	$scope.dtOptions = DTOptionsBuilder.fromSource('').withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>").withBootstrap().withLanguageSource($translate.instant('global.datatable'));

	$scope.dtColumns = [
		DTColumnBuilder.newColumn('pcp.numCas').withTitle($translate.instant('infochamApp.listeReference.idPcp')).withOption('width', '15%'),
		DTColumnBuilder.newColumn('pcp.designationPCP').withTitle($translate.instant('infochamApp.listeReference.designationPCP')).withOption('width', '15%'),

		DTColumnBuilder.newColumn('listeRef.codeRubrique').withTitle($translate.instant('infochamApp.listeReference.codeListe')).withOption('width', '15%'),
			DTColumnBuilder.newColumn('designationListe').withTitle($translate.instant('infochamApp.listeReference.designationListe')), DTColumnBuilder.newColumn('status.libelle').withTitle($translate.instant('infochamApp.listeReference.status')).withOption('defaultContent',''),

			DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('width', '7%').renderWith(actionsHtml)
	// .notVisible()
	];

	

	function actionsHtml(data, type, full, meta) {
		var show=data.status.codeRubrique != "SUPP";
		return '<div class="action-center" style="margin-top:0px">' + ' <div class="col-md-1"><a ui-sref="listeReference.edit({id:' + data.id + '})">' + '   <i class="fa fa-pencil pencil-style"></i> ' + '</a></div>' + '<div ng-show="' + show + '" class="col-md-1"><a ui-sref="listeReference.delete({id:' + data.id
				+ '})" )"="">' + '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> ' + '</a></div>'

	}
	

	$scope.dtInstance = {};
	$rootScope.$on('reload', function(event) {
		$scope.dtInstance._renderer.rerender();
	});
	$(function() {
		var active = true;

		$('#collapse-init').click(function() {
			if (active) {
				active = false;
				$('.panel-collapse').collapse('show');
				$('.panel-title').attr('data-toggle', '');
				$(this).text('Enable accordion behavior');
			} else {
				active = true;
				$('.panel-collapse').collapse('hide');
				$('.panel-title').attr('data-toggle', 'collapse');
				$(this).text('Disable accordion behavior');
			}
		});

		$('#myAccordion').on('show.bs.collapse', function() {
			if (active)
				$('#myAccordion2 .in').collapse('hide');
		});
		$('#myAccordion').on('hide.bs.collapse', function() {
		});

		$('#myAccordion2').on('show.bs.collapse', function() {
			$scope.myAccordionStatut = false;
			if (active)
				$('#myAccordion .in').collapse('hide');
		});
		$('#myAccordion2').on('hide.bs.collapse', function() {
			$scope.myAccordionStatut = true;
		});

	});
	$('#myAccordion .panel-collapse').on('shown.bs.collapse', function() {
		$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-plus").addClass("fa fa-minus");
	});

	// The reverse of the above on hidden event:

	$('#myAccordion .panel-collapse').on('hidden.bs.collapse', function() {
		$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-minus").addClass("fa fa-plus");
		// $scope.statut=false;
	});
	$('#myAccordion2 .panel-collapse').on('shown.bs.collapse', function() {
		$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-plus").addClass("fa fa-minus");
	});

	// The reverse of the above on hidden event:

	$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse', function() {
		$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-minus").addClass("fa fa-plus");
		// $scope.statut=false;
	});
	$scope.getPcp = function(result) {

		$scope.pcp = result;

	}

	$scope.clear = function() {

	};
	

	$scope.lovModel = 'api/getListCodeRubriqueListReference';// this will be
																// the LOV
																// datasource

	$scope.lovTitle = $translate.instant('infochamApp.listeReference.codeListe');
	$scope.lovColumnList = [ $translate.instant('infochamApp.nature.codeRubrique'), $translate.instant('infochamApp.nature.libelle') ];
	$scope.lovFieldList = [ "codeRubrique", "libelle" ];
	// function to be called when you click "choose" button
	$scope.lovCallBack = function(result) {
		// e parameter contains the whole object for the row selected
		$scope.listeRef = result;
		


	}	
}

})();
