(function() {
'use strict';
angular
	.module('infochamApp')
	.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('personnel', {
        parent: 'entity',
        url: '/personnels',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.document.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/personnel/personnel.html',
                controller: 'PersonnelController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('document');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    .state('personnel.detail', {
        parent: 'entity',
        url: '/personnel/{id}',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.document.detail.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/personnel/personnel-detail.html',
                controller: 'PersonnelDetailController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('document');
                return $translate.refresh();
            }],
            entity: ['$stateParams', 'Personnel', function($stateParams, Personnel) {
                return Personnel.get({id : $stateParams.id});
            }]
        }
    })
    .state('personnel.new', {
        parent: 'personnel',
        url: '/new',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/personnel/personnel-add.html',
                controller: 'PersonnelDialogController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            nom: null,
                            prenom: null,
                            poste: null,
                            codeRubrique: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(result) {
                $state.go('personnel', null, { reload: true });
            }, function() {
                $state.go('personnel');
            })
        }]
    })
    .state('personnel.edit', {
        parent: 'personnel',
        url: '/{id}/edit',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/personnel/personnel-update.html',
                controller: 'PersonnelDialogController',
                size: 'lg',
                resolve: {
                    entity: ['Personnel', function(Personnel) {
                        return Personnel.get({id : $stateParams.id});
                     
                    }]
                }
            }).result.then(function(result) {
                $state.go('personnel', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    .state('personnel.delete', {
        parent: 'personnel',
        url: '/{id}/delete',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/personnel/personnel-delete-dialog.html',
                controller: 'PersonnelDeleteController',
                size: 'md',
                resolve: {
                    entity: ['Personnel', function(Personnel) {
                        return Personnel.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('personnel', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    });
}

})();
