(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeExpediteurController', OeExpediteurController);

	OeExpediteurController.$inject = ['$scope', '$state', 'OeExpediteur', 'ParseLinks'];

	function OeExpediteurController ($scope, $state, OeExpediteur, ParseLinks) {

        $scope.oeExpediteurs = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            OeExpediteur.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.oeExpediteurs = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.oeExpediteur = {
                raisonSociale: null,
                denomination: null,
                activitePrincipale: null,
                activiteSecondaire: null,
                rccm: null,
                dateRccm: null,
                numeroCnss: null,
                nif: null,
                dateAdhesion: null,
                numeroAdhesion: null,
                matriculeFiscal: null,
                sigle: null,
                id: null
            };
        };
    }
})();
