(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('PieceJointeDetailController', PieceJointeDetailController);

	PieceJointeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PieceJointe', 'User', 'TypeProcess', 'Referentiel'];

	function PieceJointeDetailController ($scope, $rootScope, $stateParams, entity, PieceJointe, User, TypeProcess, Referentiel) {
        $scope.pieceJointe = entity;
        $scope.load = function (id) {
            PieceJointe.get({id: id}, function(result) {
                $scope.pieceJointe = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:pieceJointeUpdate', function(event, result) {
            $scope.pieceJointe = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
