(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ReclamationController', ReclamationController);

	ReclamationController.$inject = ['$rootScope','$uibModalInstance','Inscription2','$stateParams','$translate','$scope','Principal', '$state', '$http','$compile','AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder', 'CustomProcessBonita'];

	function ReclamationController ($rootScope,$uibModalInstance,Inscription2 ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder, CustomProcessBonita) {
		$scope.idExp = $stateParams.id;
		$scope.saveValue={};
		
		$scope.customProcess =[];
		
		$scope.clear=     function clear () {
//			$state.go('home', {id: $scope.idAgenda}, { reload: true } );
			$uibModalInstance.dismiss('cancel');
		}
		
		$scope.save=   function save (reclamation) {
			Inscription2.addReclamation(reclamation,$scope.idExp).then(function (result) {
				$state.go('home', null, { reload: true } );
				$uibModalInstance.dismiss('cancel');
			});
		}
}
})();
