(function() {
	'use strict';
	angular.module('infochamApp').factory('ReportingService', ReportingService);

	ReportingService.$inject = [ '$http', '$rootScope', '$translate' ];
	function ReportingService($http, $rootScope, $translate) {
		return {
			'downloadReportDistributeur' : function(criteres) {
				return $http({
					method : "PUT",
					url : 'api/generateReportDistributeur/',
					data : criteres,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'downloadReportFabricant' : function(criteres) {
				return $http({
					method : "PUT",
					url : 'api/generateReportFabricant',
					data : criteres,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportImportation' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportImportation",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportEveSanit' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportEveSanit",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportDechet' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportDechet",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportCadreReg' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportCadreReg",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportDomaineUtilisation' : function(criteres) {
				return $http({
					method : "PUT",
					data:criteres,
					url : "api/generateReportDomaineUtilisation",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadDemandeAdhesionExcel' : function(idProcess,demandeAdhesion) {
				return $http({
					method : "PUT",
					url : "api/generateDemandeAdhesionExcel/"+idProcess,
					data : demandeAdhesion,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadCertificatOrigineDraft' : function(certificat) {
				return $http({
					method : "PUT",
					url : "api/generateCertificatOrigineDraft",
					responseType : 'arraybuffer',
				    data : certificat
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadXlsFile' : function(result, fileName) {		
				var blob = new Blob([result.data], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });		
                var a = document.createElement('a');		
                var url = URL.createObjectURL(blob);		
                a.href = url;		
                a.download = fileName;		
                a.target = '_blank';		
                document.body.appendChild(a);		
                a.click();		
			},
			'downloadReportVtr' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportVtr",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},

			'downloadReportImportateur' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportImportateur",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportPollutionPotentielle' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportPollutioPotentielle",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportScoring' : function() {
				return $http({
					method : "PUT",
					
					url : "api/generateReportScoring",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportVeilleNormative' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportVeilleNormative",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportProduitFini' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportProduitFini",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportPictogramme' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportPictogramme",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportListRef' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportListRef",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportCodif' : function() {
				return $http({
					method : "PUT",
					url : "api/generateReportCodif",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportMentionDanger' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportMentionDanger",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportNature' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateReportNature",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportPcp' : function(urlParam) {
				return $http({
					method : "PUT",
					data: null,
					url : 'api/generateReportPcp/'+urlParam,
					
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportRef' : function() {
				return $http({
					method : "PUT",
					url : 'api/generateReportRef/',
					data : null,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadReportUsage' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/downloadReportUsage",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadFiche' : function(criteres) {
				return $http({
					method : "PUT",
					data : criteres,
					url : "api/generateFichePCP",
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadPdfFile' : function(result, fileName) {
				var a = document.createElement("a");
				document.body.appendChild(a);

				if (angular.equals({
					status : result.status
				}, {
					status : 200
				})) {
					var file = new Blob([ result.data ], {
						type : 'application/pdf'
					});
					var fileURL = window.URL.createObjectURL(file);

					if ($rootScope.navigateurIE) {
						// seulement
						// pour
						// le
						// navigateur
						// IE
						window.navigator.msSaveOrOpenBlob(file, titre);
					} else {
						// avec
						// d'autres
						// navigateurs
						a.href = fileURL;
						a.download = $translate.instant(fileName);
						a.click();
					}
				} else {
					swal("Opération interrompue!", "Erreur de chargement du PDF", "error");

				}

			},
			'downloadNoteHonoraire' : function(idProcess, numCO) {
				return $http({
					method : "PUT",
					url : "api/generateNoteHonoraire/"+idProcess+ "/"+ numCO,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'downloadNotePaiement' : function(idProcess,numPaiement,mntPaiement) {
				return $http({
					method : "PUT",
					url : "api/generateNotePaiement/"+idProcess+ "/"+ numPaiement+"/"+ mntPaiement,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadAttestationByDirectriceGenerale' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationByDirectriceGenerale/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadAttestationByPresident' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationByPresident/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			
			
			
			'downloadRessortissantByDirectriceGenerale' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateRessortissantByDirectriceGenerale/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadRessortissantByPresident' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateRessortissantByPresident/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadRessortissantByPresidentSigner' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateRessortissantByPresidentSigner/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'generateRessortissantDirectriceGeneraleAlfresco' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateRessortissantDirectriceGeneraleAlfresco/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'generateRessortissantPresidentAlfresco' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateRessortissantPresidentAlfresco/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			
			
			
			
			
			'downloadAttestationAdhesionByPresident' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationAdhesionByPresident/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'downloadAttestationAdhesionByPresidentSigner' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationAdhesionByPresidentSigner/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'downloadAttestationAdhesionByDirectriceGenerale' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationAdhesionByDirectriceGenerale/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'downloadAttestationByPresidentSigner' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationByPresidentSigner/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'generateDDU' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateDDU/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'generateDDUByAdmin' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateDDUByAdmin/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'generateRessortissant' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateRessortissant/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'generateCO' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateCO/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'generateAttestationDirectriceGeneraleAlfresco' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationDirectriceGeneraleAlfresco/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'generateAttestationAdhesionDirectriceGeneraleAlfresco' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationAdhesionDirectriceGeneraleAlfresco/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'generateAttestationPresidentAlfresco' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationPresidentAlfresco/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'generateAttestationAdhesionPresidentAlfresco' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateAttestationAdhesionPresidentAlfresco/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'generateFicheProduit' : function(depotDoss) {
				return $http({
					method : "PUT",
					url : 'api/generateFicheProduit',
					data : depotDoss,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadCertificatOrigine' : function(idProcess, numCO) {
				return $http({
					method : "PUT",
					url : "api/generateCertificatOrigine/"+idProcess+"/"+numCO,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'downloadDemandeAdhesionFr' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateDemandeAdhesionFr/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			'downloadDemandeAdhesionAn' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateDemandeAdhesionAn/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			'downloadDemandeAdhesion' : function(idProcess,numAdhesion) {
				return $http({
					method : "PUT",
					url : "api/generateDemandeAdhesion/"+idProcess+"/"+numAdhesion,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'downloadDemandeAdhesionAr' : function(idProcess) {
				return $http({
					method : "PUT",
					url : "api/generateDemandeAdhesionAr/"+idProcess,
					responseType : 'arraybuffer'
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},

		}
	}
})();