(function() {
	'use strict';
	angular.module('infochamApp').controller('AgendaDetailEditController',
			AgendaDetailEditController);
	AgendaDetailEditController.$inject = [ 'blockUI', 'fileUpload', 'Agenda',
			'ReferentielWs', '$rootScope', 'Inscription2', '$stateParams',
			'$translate', '$scope', 'Principal', '$state',
			'$http', '$compile', 'calendarConfig', 'moment', 'AuditsService',
			'$filter', 'DTOptionsBuilder', 'DTColumnBuilder' ];
	function AgendaDetailEditController(blockUI, fileUpload, Agenda,
			ReferentielWs, $rootScope, Inscription2, $stateParams, $translate,
			 $scope, Principal, $state, $http, $compile,
			calendarConfig, moment, AuditsService, $filter, DTOptionsBuilder,
			DTColumnBuilder) {
		$scope.idAgenda = $stateParams.id;
		blockUI.stop();
		Inscription2
				.getAgendaById($scope.idAgenda)
				.then(
						function(result) {
							$scope.state = $state.current.name;
							$scope.inscription = result.data;
							$scope.idCase = result.data.idCase;
							$scope.idCreateur = result.data.createur.id;
							$scope.imageUser = result.data.createur.image;
							// $scope.inscription.expediteur.numCNSS=parseInt($scope.inscription.expediteur.numCNSS);
							$scope.inscription.expediteur.nif = parseInt($scope.inscription.expediteur.nif);
							$scope.inscriptionUpdate = {};
							$scope.inscription.expediteur.dateRCCM = new Date(
									$scope.inscription.expediteur.dateRCCM)

							$scope.lovModel1 = 'api/referentiels/sousSecteur/'
									+ $scope.inscription.expediteur.secteurActivite.codeRubrique;// this
																									// will
																									// be
							// // the LOV
							// // datasource
							$scope.lovTitle1 = 'Liste des sous Secteur';
							$scope.lovColumnList1 = [ 'Libellé' ];
							$scope.lovFieldList1 = [ 'libelle' ];
							// function to be called when you click "choose"
							// button
							$scope.lovCallBack1 = function(result) {
								$scope.inscription.expediteur.sousSecteur = result;
							}

							$scope.mobilecheck = function() {
								var check = false;
								(function(a) {
									if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
											.test(a)
											|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
													.test(a.substr(0, 4)))
										check = true;
								})(navigator.userAgent || navigator.vendor
										|| window.opera);
								return check;
							};
							if ($scope.inscription.expediteur.adresseUsine.gouvernorat != null) {
								$scope.lovModel6 = 'api/referentiels/localite/'
										+ $scope.inscription.expediteur.adresseUsine.gouvernorat.codeRubrique;// this
																												// will
																												// be
							}
							// // the LOV
							// // datasource
							$scope.lovTitle6 = 'Liste des villes';
							$scope.lovColumnList6 = [ 'Libellé' ];
							$scope.lovFieldList6 = [ 'libelle' ];
							// function to be called when you click "choose"
							// button
							$scope.lovCallBack6 = function(result) {
								$scope.inscription.expediteur.adresseUsine.localite = result;

							}
							$scope.lovModel4 = 'api/referentiels/localite/'
									+ $scope.inscription.expediteur.adresseSiege.gouvernorat.codeRubrique;// this
																											// will
																											// be
							// // the LOV
							// // datasource
							$scope.lovTitle4 = 'Liste des villes';
							$scope.lovColumnList4 = [ 'Libellé' ];
							$scope.lovFieldList4 = [ 'libelle' ];
							// function to be called when you click "choose"
							// button
							$scope.lovCallBack4 = function(result) {
								$scope.inscription.expediteur.adresseSiege.localite = result;

							}
							Agenda
									.getRessortissant($scope.idAgenda)
									.then(
											function(result) {
												$scope.ressortissant = result.data;
												$scope.ressortissant.dateAdhesion = new Date(
														$scope.ressortissant.dateAdhesion)
												setTimeout(
														function() {
															if ($scope.idCase != null) {
																// getList
																// Button
																// workflow
																$http
																		.get(
																				"api/listBouton/"
																						+ $scope.idCase)
																		.then(
																				function(
																						result) {
																					// récupérer
																					// key(id
																					// du
																					// bouton
																					// =
																					// nextStep)
																					// and
																					// value(libelle
																					// du
																					// bouton)
																					$scope.listButton = result.data;
																					// parcour
																					// :
																					// ng-repeat="(key,value)
																					// in
																					// listButton"
																					// pour
																					// distinguer
																					// entre
																					// les
																					// ids
																					// des
																					// boutons
																					// lors
																					// du
																					// clic
																					var html = ' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" ng-disabled="AgendaForm.$invalid || isSaving">{{value}}</button>&nbsp;&nbsp;</span>';
																					// ajouter
																					// le
																					// ou
																					// les
																					// boutons
																					// dans
																					// la
																					// balise
																					// boutonWK
																					// l'id
																					// #boutonWK
																					// est
																					// ajouté
																					// au
																					// niveau
																					// html
																					angular
																							.element(
																									"#boutonWK")
																							.append(
																									$compile(
																											html)
																											(
																													$scope));

																				})
															}
															$scope.dtOptions = DTOptionsBuilder
																	.newOptions()
																	.withOption(
																			'ajax',
																			{
																				url : 'api/getCommentaireInscription/'
																						+ $scope.idAgenda,
																				type : 'GET',

																			})
																	.withOption(
																			'createdRow',
																			createdRow)
																	.withPaginationType(
																			'full_numbers')
																	.withOption(
																			'order',
																			[
																					0,
																					'asc' ])
																	.withDOM(
																			"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
																	.withBootstrap()
																	.withLanguageSource(
																			$translate
																					.instant('global.datatable'))
																	.withLightColumnFilter(
																			{

																				'0' : {
																					type : 'text'
																				},
																				'1' : {
																					type : 'text'
																				},
																				'2' : {
																					type : 'text'
																				}

																			})

															$scope.dtColumns = [
																	DTColumnBuilder
																			.newColumn(
																					'note')
																			.withTitle(
																					'Description')
																			.withOption(
																					'width',
																					'30%'),
																	DTColumnBuilder
																			.newColumn(
																					'dateCreation')
																			.withTitle(
																					'Date')
																			.withOption(
																					'width',
																					'30%')
																			.renderWith(
																					function(
																							data,
																							type) {

																						return $filter(
																								'date')
																								(
																										data,
																										'dd/MM/yyyy');
																					}), ];

															$scope.dtOptions1 = DTOptionsBuilder
																	.newOptions()
																	.withOption(
																			'ajax',
																			{
																				url : 'api/findPieceJointe/'
																						+ $scope.idCreateur,
																				type : 'GET',

																			})
																	.withOption(
																			'createdRow',
																			createdRow1)
																	.withPaginationType(
																			'full_numbers')
																	.withOption(
																			'order',
																			[
																					0,
																					'desc' ])
																	.withDOM(
																			"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
																	.withBootstrap()
																	.withLanguageSource(
																			$translate
																					.instant('global.datatable'))
																	.withLightColumnFilter(
																			{

																				'0' : {
																					type : 'text'
																				},
																				'1' : {
																					type : 'text'
																				},
																				'2' : {
																					type : 'text'
																				}

																			})

															$scope.dtColumns1 = [
																	DTColumnBuilder
																			.newColumn(
																					'commentaire')
																			.withTitle(
																					'Nature')
																			.withOption(
																					'width',
																					'30%'),
																	DTColumnBuilder
																			.newColumn(
																					null)
																			.withTitle(
																					'Fichier')
																			.notSortable()
																			.withOption(
																					'width',
																					'40%')
																			.renderWith(
																					actionsHtml1),

															];
															function actionsHtml(
																	data, type,
																	full, meta) {

																return '<td><strong> <a ng-click="test('
																		+ data
																		+ ')">'
																		+ data.nom
																		+ '</a></strong></td>'
															}

															function actionsHtml1(
																	data, type,
																	full, meta) {

																return '   <td><strong> <a ng-click="downloadDecharge('
																		+ data.id
																		+ ')">'
																		+ data.nom
																		+ '</a></strong></td>'
															}

															$scope.search = 0;
															function createdRow1(
																	row, data,
																	dataIndex) {
																$compile(
																		angular
																				.element(
																						row)
																				.contents())
																		($scope);
															}

														}, 1000);
											})
						});

		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					url : 'api/getCommentaireInscription/' + $scope.idAgenda,
					type : 'GET',

				})
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					}

				})

		$scope.dtColumns = [
				DTColumnBuilder.newColumn('note').withTitle('Description')
						.withOption('width', '30%'),
				DTColumnBuilder.newColumn('dateCreation').withTitle('Date')
						.withOption('width', '30%').renderWith(
								function(data, type) {

									return $filter('date')(data, 'dd/MM/yyyy');
								}),

		];

		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		$scope.dtOptions1 = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					url : 'api/findPieceJointe/0',
					type : 'GET',

				})
				.withOption('createdRow1', createdRow1)
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					}

				})

		$scope.dtColumns1 = [
				DTColumnBuilder.newColumn('nom').withTitle('Nom').withOption(
						'width', '40%'),
				DTColumnBuilder.newColumn('commentaire').withTitle('Nature')
						.withOption('width', '30%'),
				DTColumnBuilder.newColumn('dateCreation').withTitle(
						'Date Création').withOption('width', '30%').renderWith(
						function(data, type) {

							return $filter('date')(data, 'dd/MM/yyyy');
						}),
		//

		];

		$scope.search = 0;
		function createdRow1(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		/* get file dec tresor from alfreco */
		$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");

			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2.downloadDocument(docId)
					.then(
							function(result) {
								if (angular.equals({
									status : result.status
								}, {
									status : 500
								})) {
									swal(message, "Un problème s'est produit!",
											"error")
								}
								var fName = result
										.headers('Content-Disposition');
								var file = new Blob([ result.data ], {
									type : 'application/pdf'
								});
								var fileURL = (window.URL || window.webkitURL)
										.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
		}
		$scope.traitement = function(keyBouton, value) {
			$scope.inscriptionUpdate.adresseSiege = $scope.inscription.expediteur.adresseSiege;
			$scope.inscriptionUpdate.adresseUsine = $scope.inscription.expediteur.adresseUsine;
			$scope.inscriptionUpdate.expediteur = $scope.inscription.expediteur;
			$scope.inscriptionUpdate.responsable = $scope.inscription.expediteur.responsable;
			if (keyBouton == "ENVINFO") {
				if ($scope.ressortissant.nvRessortissant == "N") {
					Agenda.updateRessortissant($scope.ressortissant).then(
							function(result) {

							});
				}
				Inscription2.updateInscription($scope.inscriptionUpdate).then(
						function(result) {

						});

				Inscription2.updateProcInscription($scope.inscription.id,
						keyBouton, value).then(
						function(result) {
							var uploadUrl1 = "api/uploadRegistre";
							var file = $scope.docRegistre;
							if (typeof file != 'undefined') {
								var result = fileUpload.uploadFileToUrl(file,
										uploadUrl1).then(function(result) {
								});
							}
							var uploadUrl2 = "api/uploadAutre";
							var file = $scope.docAutre;
							if (typeof file != 'undefined') {
								var result = fileUpload.uploadFileToUrl(file,
										uploadUrl2).then(function(result) {
								});
							}
							$state.go('home', null, {
								reload : true
							});

						});
			} else {
				swal(
						{
							title : "Confirmation",
							text : "Êtes-vous sûr de vouloir annuler la demande d'inscription  ?",
							type : "warning",
							showCancelButton : true,
							confirmButtonColor : "#449d44",
							confirmButtonText : "Valider",
							closeOnConfirm : true,
							cancelButtonText : "Annuler",
						}, function() {
							Inscription2.updateProcInscription($scope.idAgenda,
									keyBouton, value).then(function(result) {
								$state.go('home', null, {
									reload : true
								});

							});
						});
			}
		}

		$scope.lovModel2 = 'api/referentiels/secteur';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des secteurs d`activités';
		$scope.lovColumnList2 = [ 'Libellé' ];
		$scope.lovFieldList2 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.inscription.expediteur.secteurActivite = result;
			$scope.$emit('eventSousSecteur', result)
		}

		$scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
		// the LOV
		// datasource
		$scope.lovTitle3 = 'Liste des régions';
		$scope.lovColumnList3 = [ 'Libellé' ];
		$scope.lovFieldList3 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack3 = function(result) {
			$scope.inscription.expediteur.adresseSiege.gouvernorat = result;
			$scope.$emit('eventLocaliteAdrSiege', result)
		}

		$scope.lovModel5 = 'api/referentiels/gouvernorat';// this will be
		// the LOV
		// datasource
		$scope.lovTitle5 = 'Liste des régions';
		$scope.lovColumnList5 = [ 'Libellé' ];
		$scope.lovFieldList5 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack5 = function(result) {
			$scope.inscription.expediteur.adresseUsine.gouvernorat = result;
			$scope.$emit('eventLocaliteAdrUsine', result)
		}

		$scope.lovModel7 = 'api/referentiels/getTypeOperateur';// this will be
		// the LOV
		// datasource
		$scope.lovTitle7 = 'Liste des Types Opérateurs économiques';
		$scope.lovColumnList7 = [ 'Libellé' ];
		$scope.lovFieldList7 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack7 = function(result) {
			$scope.inscription.expediteur.typeOperateur = result;
		}

		$rootScope.$on('eventSousSecteur', function(event, result) {
			$scope.lovModel1 = 'api/referentiels/sousSecteur/'
					+ result.codeRubrique;// this will be
			//	  		// the LOV
			//	  		// datasource
			$scope.lovTitle1 = 'Liste des sous Secteur';
			$scope.lovColumnList1 = [ 'Libellé' ];
			$scope.lovFieldList1 = [ 'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBack1 = function(result) {
				$scope.inscription.expediteur.sousSecteur = result;
			}
		});

		$rootScope.$on('eventLocaliteAdrUsine', function(event, result) {
			$scope.lovModel6 = 'api/referentiels/localite/'
					+ result.codeRubrique;// this will be
			//	  		// the LOV
			//	  		// datasource
			$scope.lovTitle6 = 'Liste des villes';
			$scope.lovColumnList6 = [ 'Libellé' ];
			$scope.lovFieldList6 = [ 'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBack6 = function(result) {
				$scope.inscription.expediteur.adresseUsine.localite = result;
			}
		});

		$rootScope.$on('eventLocaliteAdrSiege', function(event, result) {
			$scope.lovModel4 = 'api/referentiels/localite/'
					+ result.codeRubrique;// this will be
			//	  		// the LOV
			//	  		// datasource
			$scope.lovTitle4 = 'Liste des villes';
			$scope.lovColumnList4 = [ 'Libellé' ];
			$scope.lovFieldList4 = [ 'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBack4 = function(result) {
				$scope.inscription.expediteur.adresseSiege.localite = result;
			}
		});
		$scope.lovModel8 = 'api/referentiels/allNationalites';// this will be
		// the LOV
		// datasource
		$scope.lovTitle8 = 'Liste des Nationalités';
		$scope.lovColumnList8 = [ 'Libellé' ];
		$scope.lovFieldList8 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack8 = function(result) {
			$scope.inscription.expediteur.responsable.nationalite = result;
		}

		$scope.lovModel9 = 'api/referentiels/allPays';// this will be
		// the LOV
		// datasource
		$scope.lovTitle9 = 'Liste des pays';
		$scope.lovColumnList9 = [ 'Libellé' ];
		$scope.lovFieldList9 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack9 = function(result) {
			$scope.inscription.expediteur.pays = result;
		}

		$scope.lovModel12 = 'api/referentiels/matricule';// this will be
		// the LOV
		// datasource
		$scope.lovTitle12 = 'Liste des matricules';
		$scope.lovColumnList12 = [ 'Libellé' ];
		$scope.lovFieldList12 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack12 = function(result) {
			$scope.inscription.expediteur.matriculeFiscale = result;
		}

		$scope.$on('eventEmitedNameRegistre', function(event, data) {
			$scope.docRegistre = data.message;
		});

		$scope.$on('eventEmitedNameAutres', function(event, data) {
			$scope.docAutre = data.message;
		});
	}

})();
