(function() {
	'use strict';

	angular.module('infochamApp').config(compileServiceConfig);

	compileServiceConfig.$inject = [ '$compileProvider', 'DEBUG_INFO_ENABLED' ];

	function compileServiceConfig($compileProvider, DEBUG_INFO_ENABLED) {
		// disable debug data on prod profile to improve performance
		$compileProvider.debugInfoEnabled(false);

		/*
		If you wish to debug an application with this information
		then you should open up a debug console in the browser
		then call this method directly in this console:

		angular.reloadWithDebugInfo();
		 */
	}
})();
