(function() {
    'use strict';
    angular.module('app.forms', ['ui.router'])
    angular
        .module('app.forms', ['ui.bootstrap'])
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider
            .state('app.form', {
                abstract: true,
                data: {
                    title: 'Forms'
                }
            })

            .state('forms', {
                parent : 'site',
                url: '/form/elements',
                data: {
                    title: 'Form Elements'
                },
                views: {
                    "content@": {
                        templateUrl: 'app/forms/views/form-elements.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('sessions');
                        return $translate.refresh();
                    }]
                }
            })

            .state('layouts', {
            	parent : 'site',
                url: '/form/layouts',
                data: {
                    title: 'Form Layouts'
                },
                views: {
                    "content@": {
                        controller: 'FormLayoutsCtrl',
                        templateUrl: 'app/forms/views/form-layouts/form-layouts-demo.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('sessions');
                        return $translate.refresh();
                    }]
                }
            })

            .state('formsvalidation', {
            	parent : 'site',
            	url: '/form/validation',
                data: {
                    title: 'Form Validation'
                },
                views: {
                    "content@": {
                        templateUrl: 'app/forms/views/form-validation.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('sessions');
                        return $translate.refresh();
                    }]
                }
            })

            .state('bootstrapForms', {
            	parent : 'site',
                url: '/form/bootstrap-forms',
                data: {
                    title: 'Bootstrap Forms'
                },
                views: {
                    "content@": {
                        templateUrl: 'app/forms/views/bootstrap-forms.html'
                    }
                }
            })

            .state('bootstrapValidation', {
                url: '/form/bootstrap-validation',
                data: {
                    title: 'Bootstrap Validation'
                },
                views: {
                    "content@": {
                        templateUrl: 'app/forms/views/bootstrap-validation.html'
                    }
                },
                resolve: {
                    srcipts: function(lazyScript){
                        return lazyScript.register([
                            'build/vendor.ui.js'
                        ])

                    }
                }
            })

            .state('app.form.plugins', {
                url: '/form/plugins',
                data: {
                    title: 'Form Plugins'
                },
                views: {
                    "content@app": {
                        templateUrl: 'app/forms/views/form-plugins.html',
                        controller: 'FormPluginsCtrl'
                    }
                },
                resolve: {
                    srcipts: function(lazyScript){
                        return lazyScript.register([
                            "build/vendor.ui.js"
                        ])

                    }
                }
            })
            .state('wizards', {
            	parent : 'site',
                url: '/form/wizards',
                data: {
                    title: 'Wizards'
                },
                views: {
                    "content@": {
                        templateUrl: 'app/forms/views/form-wizards.html',
                        //controller: 'FormWizardCtrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('app.form.editors', {
                url: '/form/editors',
                data: {
                    title: 'Editors'
                },
                views: {
                    "content@app": {
                        templateUrl: 'app/forms/views/form-editors.html'
                    }
                },
                resolve: {
                    scripts: function(lazyScript){
                        return lazyScript.register([
                            "build/vendor.ui.js"
                        ])
                    }
                }
            })
            .state('app.form.dropzone', {
                url: '/form/dropzone',
                data: {
                    title: 'Dropzone'
                },
                views: {
                    "content@app": {
                        templateUrl: 'app/forms/views/dropzone.html',
                        controller: function($scope){
                            $scope.dropzoneConfig = {
                                'options': { // passed into the Dropzone constructor
                                    'url': '/api/plug'
                                },
                                'eventHandlers': {
                                    'sending': function (file, xhr, formData) {
                                    },
                                    'success': function (file, response) {
                                    }
                                }
                            };
                        }
                    }
                },
                resolve: {
                    scripts: function(lazyScript){
                        return lazyScript.register('build/vendor.ui.js')
                    }
                }
            })
            .state('app.form.imageEditor', {
                url: '/form/image-editor',
                data: {
                    title: 'Image Editor'
                },
                views: {
                    "content@app": {
                        templateUrl: 'app/forms/views/image-editor.html',
                        controller: 'ImageEditorCtrl'
                    }
                },
                resolve: {
                    scripts: function(lazyScript){
                        return lazyScript.register([
                            'build/vendor.ui.js'
                        ])
                    }
                }
            })


}
})();













