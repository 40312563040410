(function() {
	'use strict';

	angular.module('infochamApp').controller('MenutargetController',
			MenutargetController).directive('headerDirective', headerDirective);

	MenutargetController.$inject = [ '$scope' ];
	headerDirective.$inject = [ '$rootScope' ];
	function headerDirective($rootScope) {
		return {
			restrict : "E",
			scope : {
				target : '=',
				titre : '@titre',
			},
			templateUrl : 'app/directives/menu/header-directive.html',
			controller : [ '$scope', '$interval', function($scope, $interval) {

				//affichage de la date et l'heure
				$scope.date = "Chargement ..."; // initialise the time variable
				var tick = function() {
					$scope.date = new Date();

				}
				tick();
				$interval(tick, 1000);
			} ]
		};

	}

	function MenutargetController($scope) {
	}

})();
