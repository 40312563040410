(function() {
	'use strict';
	angular.module('infochamApp').controller('CciDialogController',
			CciDialogController).filter('propsFilter', propsFilter);
	CciDialogController.$inject = [ 'ReferentielWs', '$scope', '$stateParams',
			'$uibModalInstance', 'entity', 'Cci' ];

	function propsFilter() {
		return function(items, props) {
			var out = [];

			if (angular.isArray(items)) {
				var keys = Object.keys(props);

				items
						.forEach(function(item) {
							var itemMatches = false;

							for (var i = 0; i < keys.length; i++) {
								var prop = keys[i];
								var text = props[prop].toLowerCase();
								if (item[prop].toString().toLowerCase()
										.indexOf(text) !== -1) {
									itemMatches = true;
									break;
								}
							}

							if (itemMatches) {
								out.push(item);
							}
						});
			} else {
				// Let the output be the input untouched
				out = items;
			}

			return out;
		};
	}

	function CciDialogController(ReferentielWs, $scope, $stateParams,
			$uibModalInstance, entity, Cci) {

		$scope.cci = entity;
		$scope.load = function(id) {
			Cci.get({
				id : $stateParams.id
			}, function(result) {
				$scope.cci = result;
			});
		};
		if ($stateParams.id != null) {
			Cci.get({
				id : $stateParams.id
			}, function(result) {
				$scope.cci = result;
				ReferentielWs.getGouvernoratByCci($scope.cci.code).then(
						function(result) {

							$scope.gouvernorats = result.data;

						});
				ReferentielWs.getGouvernoratOnlyByCci($scope.cci.code).then(
						function(result) {

							$scope.s = []
							$scope.listResult = []
							$scope.gouv = result.data;
							$scope.a = $scope.gouv.join(" ; ")
							for ( var i in $scope.gouv) {
								$scope.listResult.push($scope.gouv[i])
							}
							for ( var i in $scope.gouv) {
								$scope.s.push($scope.gouv[i].libelle);
							}
							$scope.a = $scope.s.join(" ; ")
						});
			});
		} else {
			ReferentielWs.getGouvernoratNotAffected().then(function(result) {

				$scope.gouvernorats = result.data;

			});
		}
		$scope.titreRole = " rôles";
		$scope.idUser = $stateParams.login;

		$scope.getObject = function(result) {
			$scope.s = []
			$scope.id = []
			$scope.listResult = result;
			for ( var i in result) {
				$scope.s.push(result[i].libelle);
				$scope.id.push(result[i].id);
			}
			$scope.a = $scope.s.join(" ; ")
			$scope.listIdLoc = $scope.s;
		}

		var onSaveSuccess = function(result) {
			$scope.$emit('infochamApp:cciUpdate', result);
			$uibModalInstance.close(result);
			$scope.isSaving = false;
		};

		var onSaveError = function(result) {
			$scope.isSaving = false;
		};

		$scope.save = function() {
			$scope.isSaving = true;
			if ($scope.cci.id != null) {
				Cci.update($scope.cci, onSaveSuccess, onSaveError);
			} else {
				Cci.save($scope.cci, onSaveSuccess, onSaveError);
			}
			ReferentielWs.updateGouvByCci($scope.listResult, $scope.cci.code)
					.then(function(result) {
					});
		};

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
