(function() {
	'use strict';
	angular.module('infochamApp').factory('UserManagementDetailController',
			UserManagementDetailController);

	UserManagementDetailController.$inject = [ '$scope', '$stateParams', 'User' ];

	function UserManagementDetailController($scope, $stateParams, User) {
		$scope.user = {};
		$scope.load = function(login) {
			User.get({
				login : login
			}, function(result) {
				$scope.user = result;
			});
		};
		$scope.load($stateParams.login);
	}
})();
