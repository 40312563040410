(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('nouvelledetailval2lovlibre', nouvelledetailval2lovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('nouvelleDetailval2LibreCtrl', nouvelleDetailval2LibreCtrl);

	nouvelledetailval2lovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	nouvelleDetailval2LibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','id','CustomProcessBonita' ,'DTColumnDefBuilder'];

	function nouvelledetailval2lovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				id : '@',

			},

			template : '  <a style="font-size:16px" ng-click="openPopUp(id)"><span class="glyphicon glyphicon-eye-open"></span> <spanclass="hidden-xs forme-button"> </span></a>',

			controller : [
					'$scope',
					'$element',
					function($scope, $element) {
						$scope.openPopUp = function(id) {
							$scope.localPopUp = {
								backdrop : 'static',
								size : 'lg',

								templateUrl : 'app/directives/menu/nouvelleDetailval2Template.html',
								controller : 'nouvelleDetailval2LibreCtrl',
								resolve : {

									id : function() {
										return id;
									},

									translatePartialLoader : [
											'$translate',
											'$translatePartialLoader',
											function(
													$translate,
													$translatePartialLoader) {
												return $translate
														.refresh();
											} ]
								}

							};
							var modalInstance = $uibModal
									.open($scope.localPopUp);
						}
					} ]
		}
	}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function nouvelleDetailval2LibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,id,CustomProcessBonita ,DTColumnDefBuilder) {
		
		$scope.isEmpty = true;
		$scope.allSelected = false;
		$scope.selected = {};
		$scope.arrayIdNotToAttribute = [];
		$scope.selectAll = false;
		$scope.arrayIdToAttribute = [];
		$scope.arrayIdTotal = [];
		$scope.model = {};
		$scope.check = [];
		$scope.dtInstance = {}
		$scope.data = null;
		
		Agenda.getAutorisationByIdProcess(id).then(function(result){
			$scope.customProcess=result.data ;
			$scope.demandeAutorisation = result.data.demandeAutorisationDTO

			$scope.motifsList = $scope.customProcess.motif ;
			$scope.mesureSecuritesList = $scope.customProcess.mesureSecurite ;

		})
		

		CustomProcessBonita.getListEffectifByNouvelleDemande(id)
		.then(
				function(result) {
					$scope.listeEffectif = result.data;
				})

		

		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption(
						'ajax',
						{
							url : 'api/getListEffectifByNouvelleDemande/'
									+ id,
							type : 'GET',

						})
				.withOption('createdRow', createdRow)
				.withOption(
						'headerCallback',
						function(header) {
							if (!$scope.headerCompiled) {
								// Use this headerCompiled field to
								// only compile header once
								$scope.headerCompiled = true;
								$compile(
										angular.element(header)
												.contents())
										($scope);
							}
						}).withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'dsc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-5'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					},
					'4' : {
						type : 'text'
					},
					'5' : {
						type : 'text'
					},
					'6' : {
						type : 'text'
					}

				})

		$scope.dtColumns = [
				DTColumnBuilder
						.newColumn(null)
						.withTitle(
								'<input type="checkbox" style="width: 16px !important;" ng-model="selectAll" ng-click="checkAll('
								+ id
								+ ');" >')
						.notSortable().withOption('width', '1%')
						.renderWith(actionsHtml),
				DTColumnBuilder.newColumn('nom').withTitle('Nom')
						.withOption('width', '20%'),
				DTColumnBuilder.newColumn('prenom').withTitle(
						'Prénom').withOption('width', '20%'),
				DTColumnBuilder.newColumn('numPiece').withTitle(
						'N° de Pièce').withOption('width', '20%'),
				DTColumnBuilder.newColumn('telephone').withTitle(
						'Téléphone').withOption('width', '20%'),
				DTColumnBuilder.newColumn('fonction').withTitle(
						'Fonction').withOption('width', '20%'),
				DTColumnBuilder.newColumn('statut.libelleFr').withTitle(
						'Statut').withOption('width', '20%'),
				DTColumnBuilder.newColumn('numAutorisation')
						.withTitle('Numéro Autorisation')
						.withOption('width', '20%'), ];


		
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the
			// DT so actionHtml works
			$compile(angular.element(row).contents())($scope);
		}

		function actionsHtml(data, type, full, meta) {
			$scope.isEmpty = false;
			return '<div class="row" title ="Révoquer" style="margin-top:0px">'
					+ ' <div  class="col-md-1"><input type="checkbox" ng-model="selected['
					+ data.id
					+ ']" ng-checked="iselected('
					+ data.id
					+ ')" ng-value="$index" ng-click="updateArrayIds('
					+ data.id
					+ ');"'
					+ ' "></div>'

		}

		$scope.checkAll = function(id ) {
			if ($scope.allSelected == false) {
				$scope.arrayIdNotToAttribute = [];
				$scope.selectAll = true;
				$scope.allSelected = true;
				$scope.arrayIdToAttribute = [];
				$scope.arrayFinal = []

			} else {
				$scope.arrayIdNotToAttribute = [];
				$scope.selectAll = false;
				$scope.allSelected = false;
				$scope.arrayIdToAttribute = [];
			}
		}
		$scope.iselected = function(id) {
			if ($scope.allSelected && $scope.selectAll) {
				return true;
			} else if ($scope.allSelected) {
				return !($scope.arrayIdNotToAttribute.indexOf(id) !== -1);
			}
			return $scope.arrayIdToAttribute.indexOf(id) !== -1;
		}
		$scope.updateArrayIds = function(id) {
			if ($scope.allSelected) {
				$scope.selectAll = false;
				if ($scope.arrayIdNotToAttribute.indexOf(id) > -1) {
					var index = $scope.arrayIdNotToAttribute
							.indexOf(id);
					$scope.arrayIdNotToAttribute.splice(index, 1);
					$scope.selected[id] = true;
					if ($scope.arrayIdNotToAttribute.length == 0) {
						$scope.selectAll = true;
					}
				} else {
					$scope.arrayIdNotToAttribute.push(id);
					$scope.selected[id] = false;
				}
				return;
			}
			if ($scope.arrayIdToAttribute.indexOf(id) > -1) {
				var index = $scope.arrayIdToAttribute.indexOf(id);
				$scope.arrayIdToAttribute.splice(index, 1);
			} else {
				$scope.arrayIdToAttribute.push(id);

			}
		}
		
		$scope.revoquerByCin = function() {
						$http
								.put(
										'api/revoquerByCin/'+ $scope.arrayIdToAttribute)
								.then(
										function(response) {

											$scope.dtInstance._renderer
													.rerender();
											swal({
												timer : 2000,
												title : "Succès",
												text : "Les autorisations ont été révoquées avec succès",
												showConfirmButton : false,
												type : "success"
											});
										})
										$scope.nextProcess("REF_VAL2");
						$uibModalInstance.close(true);
					

		};
		
		$scope.confirm = function() {
			
			//$scope.customProcess.motifDTO = result;
			$scope.nextProcess("VALID_VAL2");
			$uibModalInstance.close(true);
			
		};
		
		$scope.nextProcess = function(nextStep) {
			CustomProcessBonita.nextStep($scope.customProcess,nextStep).then(function(result) {
				if(angular.equals( { status: result.status }, { status: 200 } )){		

					$state.go($state.current.name, {id:id}, { reload: true } );

				} else {

				}
			});
		};
		$scope.traitementDirective = function(resultDirective) {
		if(resultDirective.codeProcess=="REF_VAL2"){
			 if($scope.allSelected == true){
            	 $scope.revoquerByCompagny();
             }
             else{
            	 $scope.revoquerByCin();
             }
		}
							$scope.customProcess.motifDTO = resultDirective;
							$scope.nextProcess(resultDirective.codeProcess);
							$uibModalInstance.close(true);
						}
		
		$scope.revoquerByCompagny = function() {
						$http
								.put(
										'api/revoquerByMatricule/'+ id)
								.then(
										function(response) {
											$scope.$emit('reload')

										
										});

		};
		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
	
	}
})();
