(function() {
	'use strict';
	angular
		.module('infochamApp')
		.controller('DepotDialogController',DepotDialogController);
	DepotDialogController.$inject=['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Depot', 'Produit', 'User'];
	function DepotDialogController($scope, $stateParams, $uibModalInstance, entity, Depot, Produit, User){
		$scope.depot = entity;
        $scope.produits = Produit.query();
        $scope.users = User.query();
        $scope.load = function(id) {
            Depot.get({id : id}, function(result) {
                $scope.depot = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:depotUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.depot.id != null) {
                Depot.update($scope.depot, onSaveSuccess, onSaveError);
            } else {
                Depot.save($scope.depot, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForDateCommDoss = {};

        $scope.datePickerForDateCommDoss.status = {
            opened: false
        };

        $scope.datePickerForDateCommDossOpen = function($event) {
            $scope.datePickerForDateCommDoss.status.opened = true;
        };
        $scope.datePickerForDateCre = {};

        $scope.datePickerForDateCre.status = {
            opened: false
        };

        $scope.datePickerForDateCreOpen = function($event) {
            $scope.datePickerForDateCre.status.opened = true;
        };
	}

	})();
