(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('InscriptionController',InscriptionController);
InscriptionController.$inject=['blockUI', '$compile','DTColumnDefBuilder', 'ReferentielWs', 'Principal', '$window','$stateParams', '$http', '$rootScope', 'DTColumnBuilder','$scope', 'DTOptionsBuilder', '$translate', '$state','Agenda','Certificat', 'Inscription2', 'ParseLinks', 'AlertService','FileUploader', '$timeout', 'fileUpload'];
function InscriptionController(blockUI, $compile,
		DTColumnDefBuilder, ReferentielWs, Principal, $window,
		$stateParams, $http, $rootScope, DTColumnBuilder,
		$scope, DTOptionsBuilder, $translate, $state, Agenda,
		Certificat, Inscription2, ParseLinks, AlertService,
		FileUploader, $timeout, fileUpload){

	$scope.mobilecheck = function() {
		var check = false;
		(function(a) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
					.test(a)
					|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
							.test(a.substr(0, 4)))
				check = true;
		})(navigator.userAgent || navigator.vendor
				|| window.opera);
		return check;
	};
	$(document).ready(function () {
		$(document).ready(function () {
			$('#terms').scroll(function (e) {
				if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
					$('#payment').removeAttr('disabled');
					$scope.conditionChek=true;

				}
				
				
			});
		});
	});
	var vm = this;
	$scope.inscription = {};
	$scope.emailDTO = {};
	$scope.aaa = {};
	$scope.RIB = 0;
	$scope.paiementEspece = {};
	$scope.docPaiement = "";

	$scope.codeProcess = "INS"

	vm.facture = {};
	// vm.modePaiement="";
	// vm.facture.modePaiement="";
	$scope.adresseSiege = {};
	$scope.adresseUsine = {};
	$scope.oeExpediteur = {};
	$scope.user = {};
	$scope.responsable = {};
	$scope.photo = null;
	$scope.date = new Date();
	$scope.mailExist = false;
	$scope.loginExist = false;
	$scope.user.email = null;
	$scope.fileList = [];
	vm.password = null;
	$scope.disabled = true;
	$scope.nvRessortissant = null;
	$scope.carteRessortissant = null;
	$scope.verifInscription = false;
	$scope.verifPaiementMobile = false;
	$scope.verifPaiementEspece = false;

	var i = 0;
	$scope.isAuthenticated = Principal.isAuthenticated;
//	$scope
//			.$on(
//					'eventEmitedNameRegistre',
//					function(event, data) {
//						$scope.msgresult = '\nParentController - receive EVENT "'
//								+ event.name
//								+ '" with message = "'
//								+ data.message + '"';
//						$scope.file = data.message;
//						var file = $scope.file;
//						var fileName = file != undefined ? file.name
//								: "";
//						var uploadUrl = "api/uploadPieceJointeInDirectory/Registre de commerce";
//						if (typeof file != 'undefined') {
//							var result = fileUpload
//									.uploadFileToUrl(file,
//											uploadUrl)
//									.then(
//											function(result) {
//												$scope.piece = result.data;
//												$scope.fileList[0] = $scope.piece;
//											});
//
//						}
//					});
//
//	$scope
//			.$on(
//					'eventEmitedNameAutres',
//					function(event, data) {
//						$scope.msgresult = '\nParentController - receive EVENT "'
//								+ event.name
//								+ '" with message = "'
//								+ data.message + '"';
//						$scope.file = data.message;
//						var file = $scope.file;
//						var fileName = file != undefined ? file.name
//								: "";
//						var uploadUrl = "api/uploadPieceJointeInDirectory/Autre";
//						if (typeof file != 'undefined') {
//							var result = fileUpload
//									.uploadFileToUrl(file,
//											uploadUrl)
//									.then(
//											function(result) {
//												$scope.piece = result.data;
//												$scope.fileList[1] = $scope.piece;
//											});
//
//						}
//					});

	$scope.add = function() {
		var f = document.getElementById('file').files[0], r = new FileReader();
		r.onloadend = function(e) {
			var data = e.target.result;
		}
		r.readAsBinaryString(f);
	}

	$scope.data = 'none';
	$scope.add = function() {
		var f = document.getElementById('file').files[0], r = new FileReader();
		r.onloadend = function(e) {
			var binary = "";
			var bytes = new Uint8Array(e.target.result);
			var length = bytes.byteLength;

			for (var i = 0; i < length; i++) {
				binary += String.fromCharCode(bytes[i]);
			}

			$scope.data = (binary).toString();

			alert($scope.data);
		}
		r.readAsArrayBuffer(f);
		var file = new Blob([ $scope.data ], {

			type : 'application/pdf'
		});

		// do stuff here to give the blob some data...
		function blobToFile(theBlob, fileName) {
			// A Blob() is almost a File() - it's just missing
			// the two properties below which we will add
			theBlob.lastModifiedDate = new Date();
			theBlob.name = fileName;
			return theBlob;
		}
		var myFile = blobToFile(file, "my-image");
		$window.open(myFile, '_blank');
		var uploadUrl = "api/uploadPieceJointe/aaa";
		if (typeof file != 'undefined') {
			var result = fileUpload
					.uploadFileToUrl(file, uploadUrl)
					.then(
							function(result) {
								swal({
									timer : 2000,
									title : "Succès",
									text : "Le fichier est sauvegardé avec Succès",
									showConfirmButton : false,
									type : "success"
								});

							});
		}
	}
	$scope.annuler = function() {
		$state.go('home', null, {
			reload : true
		});
		// window.location.href = "http://92.222.121.235";
	}

	$scope.checkLogin = function(login) {
		Inscription2.loginExist(login).then(function(result) {
			$scope.loginExist = result.data;
		});
	}
	$scope.checkMail = function(email) {
		$scope.emailDTO.email = email;
		Inscription2.emailExist($scope.emailDTO).then(
				function(result) {
					$scope.mailExist = result.data;
				});
	}

	$(".toggle-password").click(function() {

		$(this).toggleClass("fa-eye fa-eye-slash");
		var input = $($(this).attr("toggle"));
		if (input.attr("type") == "password") {
			input.attr("type", "text");
		} else {
			input.attr("type", "password");
		}
	});

	$scope.styleSwalConfirmation = {
		title : "Confirmation",
		text : "Voulez vous vraiment continuer ?",
		type : "warning",
		showCancelButton : true,
		cancelButtonText : "NON",
		confirmButtonColor : "#337ab7",
		confirmButtonText : "Oui",
		closeOnConfirm : true
	}

	vm.validationStep = function(item, actions) {
		$scope.errors = [];
		// blockUI.start();
		// $timeout(function() {
		// blockUI.stop();
		// }, 2000);
		 if(item.index==0){
			    		
		 if($scope.user.login !=null && $scope.user.login !=
		 undefined){
		 if( $scope.loginExist == true){
		 $scope.errors.push("Login existe déjà")
		 }
		 }
		 if($scope.user.email !=null && $scope.user.email !=
		 undefined){
		 if( $scope.mailExist == true){
		 $scope.errors.push("Email existe déjà")
		 }
		 }
			    		   
		 if($scope.user.login ==null || $scope.user.login ==
		 undefined || $scope.user.login == "" ){
		 $scope.errors.push("Le champ Login est requis. Il doit contenir que des lettres et des chiffres.")
		 }
			    		   
		 if($scope.user.lastName ==null ||
		 $scope.user.lastName == undefined ||
		 $scope.user.lastName == ""){
		 $scope.errors.push("Le champ Nom est requis.")
		 }
		 if($scope.user.firstName ==null ||
		 $scope.user.firstName == undefined ||
		 $scope.user.firstName == ""){
		 $scope.errors.push("Le champ Prénom est requis.")
		 }
		 if($scope.user.email ==null || $scope.user.email ==
		 undefined || $scope.user.email == ""){
		 $scope.errors.push("Le champ Email est requis.")
		 }
		 if(vm.password ==null || vm.password == undefined ||
		 vm.password == ""){
		 $scope.errors.push("Le champ Mot de passe est requis.")
		 }
			    		   
		 if(vm.confirmPassword ==null || vm.confirmPassword ==
		 undefined || vm.confirmPassword == ""){
		 $scope.errors.push("Le champ Confirmation du Mot de passe est requis.")
		 }
			    		   
		 if(vm.password !=null && vm.confirmPassword !=null &&
		 !angular.equals(vm.password , vm.confirmPassword)){
		 $scope.errors.push("Le mot de passe et sa confirmation ne sont pas égaux !")
		 }

			    		   
//		 if(vm.captcha ==null || vm.captcha == undefined || vm.captcha == "" ){
//		 $scope.errors.push("Le captcha est requis.")
//		 }
			    		   
		 }
		 if(item.index==1){
		 if($scope.verifInscription == true){
		 $scope.errors.push("Tu es déjà inscrit.")
		 }
		 if($scope.oeExpediteur.raisonSociale ==null ||
		 $scope.oeExpediteur.raisonSociale == undefined ||
		 $scope.oeExpediteur.raisonSociale == ""){
		 $scope.errors.push("Le champ Raison sociale est requis.")
		 }
//		 if($scope.oeExpediteur.denominationAr ==null ||
//				 $scope.oeExpediteur.denominationAr == undefined ||
//				 $scope.oeExpediteur.denominationAr == ""){
//				 $scope.errors.push("Le champ الاسم التجاري est requis.")
//				 }
		 if($scope.oeExpediteur.typeOperateur ==null ||
		 $scope.oeExpediteur.typeOperateur == undefined ||
		 $scope.oeExpediteur.typeOperateur == ""){
		 $scope.errors.push("Le champ Activité est requis.")
		 }
		 if($scope.oeExpediteur.denomination ==null ||
		 $scope.oeExpediteur.denomination == undefined ||
		 $scope.oeExpediteur.denomination == ""){
		 $scope.errors.push("Le champ Dénomination est requis.")
		 }
		 if($scope.oeExpediteur.secteurActivite ==null ||
		 $scope.oeExpediteur.secteurActivite == undefined ||
		 $scope.oeExpediteur.secteurActivite == ""){
		 $scope.errors.push("Le champ Secteur est requis.")
		 }
		 if($scope.responsable.nom ==null || $scope.responsable.nom == undefined ||	 $scope.responsable.nom == ""){
				 $scope.errors.push("Le champ Nom du responsable est requis.")
				 }
		 if($scope.responsable.qualification ==null || $scope.responsable.qualification == undefined ||	 $scope.responsable.qualification == ""){
			 $scope.errors.push("Le champ Fonction du responsable est requis.")
			 }
		if($scope.responsable.prenom ==null || $scope.responsable.prenom == undefined || $scope.responsable.prenom == ""){
				 $scope.errors.push("Le champ Prénom du responsable est requis.")
				} 	  
		if($scope.oeExpediteur.bureau ==null || $scope.oeExpediteur.bureau == undefined || $scope.oeExpediteur.bureau == ""){
			 $scope.errors.push("Le champ Bureau est requis.")
			} 	  
			    	  		
			    	  
			    	  		
		 if($scope.oeExpediteur.sousSecteur ==null ||
		 $scope.oeExpediteur.sousSecteur == undefined ||
		 $scope.oeExpediteur.sousSecteur == ""){
		 $scope.errors.push("Le champ Sous secteur est requis.")
		 }
		
			    	  		  
		 if($scope.adresseSiege.adresse ==null ||
		 $scope.adresseSiege.adresse == undefined ||
		 $scope.adresseSiege.adresse == ""){
		 $scope.errors.push("Le champ Adresse est requis.")
		 }
		 if($scope.adresseSiege.codePostal ==null ||
		 $scope.adresseSiege.codePostal == undefined ||
		 $scope.adresseSiege.codePostal == ""){
		 $scope.errors.push("Le champ Code Postal est requis.")
		 }
		 if($scope.adresseSiege.gouvernorat ==null ||
		 $scope.adresseSiege.gouvernorat == undefined ||
		 $scope.adresseSiege.gouvernorat == ""){
		 $scope.errors.push("Le champ Gouvernorat est  requis.")
		 }
		 
		 if($scope.adresseSiege.email ==null ||
				 $scope.adresseSiege.email == undefined ||
				 $scope.adresseSiege.email == ""){
			 $scope.errors.push("Le champ Email de L'enteprise est requis.")
		 }
		
		 if($scope.adresseSiege.tel ==null ||
		 $scope.adresseSiege.tel == undefined ||
		 $scope.adresseSiege.tel == ""){
		 $scope.errors.push("Le champ Téléphone est requis.")
		 }
		 if ($scope.pieceValide == undefined ||
				 $scope.pieceValide==false) {
				 $scope.errors.push("Il faut attacher toutes les piéces justificatives!")
				
				 }    	  	  
		 }

		Inscription2.loginExist($scope.user.login).then(
				function(result) {
					$scope.loginExist = result.data;
					if ($scope.loginExist == true) {
						$scope.errors.push("Login existe déjà")
					}
					if ($scope.errors.length > 0) {
						actions.error($scope.errors);
						$("html, body").animate({
							scrollTop : 400
						}, "slow");
					} else
						actions.success();
				});

	}

	vm.completeWizard = function(scope, actions) {
		if(document.getElementById('payment').checked) {
			$scope.conditionChek=true;
		} else {
			$scope.conditionChek=false;
		}
		
		if($scope.conditionChek==true){
		$scope.inscription.adresseSiege = $scope.adresseSiege;
		$scope.inscription.adresseUsine = $scope.adresseUsine;
		$scope.inscription.oeExpediteur = $scope.oeExpediteur;
		$scope.inscription.user = $scope.user;
		// $scope.inscription.file =$scope.photo;
		$scope.inscription.ressortissant = vm.ressortissant;
		// vm.facture.modePaiement=vm.modePaiement
		// $scope.inscription.facture = vm.facture;
		$scope.aaa.type = "Autres";
		$scope.inscription.uploadFileList = $scope.aaa;
		$scope.inscription.responsable = $scope.responsable;
		$scope.inscription.pieceJointeDTOs = $scope.pieces;

		swal(
				$scope.styleSwalConfirmation,
				function() {
					$scope.newUser = Inscription2
							.addInscription($scope.inscription,
									vm.password)
							.then(
									function(result) {
										if (result.status == 200) {
											$scope.idAgenda = result.data.id;
											$scope.typeC = "";
											if (vm.typeCci == undefined) {
												$scope.typeC = "-1";
											} else {
												$scope.typeC = vm.typeCci
											}

											if ($scope.adresseUsine.gouvernorat == undefined) {
												$scope.gouUs = "-1";
											} else {
												$scope.gouUs = $scope.adresseUsine.gouvernorat.valeur1
											}

											Certificat
													.putTypeCci(
															$scope.typeC,
															$scope.adresseSiege.gouvernorat.valeur1,
															$scope.gouUs,
															$scope.idAgenda)
													.then(
															function(
																	result) {

															});
											$scope.idCreateur = result.data.createur.id;

											$scope.piece = Inscription2
													.addPiece(
															$scope.idCreateur,
															$scope.fileList)
													.then(
															function(
																	result) {
																swal({
																	position : 'center',
																	type : 'success',
																	title : "Bienvenue sur notre plateforme. Vous recevrez un email de validation de votre inscription.",
																	showConfirmButton : true,
																	confirmButtonText: "ok",
																	timer : 30000
																})

																$state
																		.go(
																				'home',
																				null,
																				{
																					reload : true
																				});
															});
										} else {
											swal(
													"veuillez réessayer",
													"", "error")

										}
										
									
									});
					
				});
		
		}else{
			swal("Veuillez accepter les conditions générales d'utilisation","", "error")
		}
	}
	
	
	
	$scope.lovModel20 = 'api/referentiels/categories';// this will
	// be
// the LOV
// datasource
$scope.lovTitle20= 'Liste des formes Juridiques';
$scope.lovColumnList20 = [ '','Libellé' ];
$scope.lovFieldList20 = [ 'valeur1','codeListeMere' ];
// function to be called when you click "choose" button
$scope.lovCallBack20 = function(result) {
	if(result.codeRubrique =="PM"){
	swal(
			"veuillez Choisir le type de la personne morale",
			"", "error")
	}else{
$scope.oeExpediteur.categorieJuridique = result;
$scope.$emit('eventCategorieJuridique', result)
}
}
$rootScope
.$on(
		'eventSecteur',
		function(event, result) {
			$scope.lovModel2 = 'api/referentiels/secteur/'+ result.codeRubrique;// this
			// will
			// be;// this will
			// be
// the LOV
// datasource
$scope.lovTitle2 = 'Liste des secteurs';
$scope.lovColumnList2 = [ 'Libellé' ];
$scope.lovFieldList2 = [ 'libelle' ];
// function to be called when you click "choose" button
$scope.lovCallBack2 = function(result) {
$scope.oeExpediteur.secteurActivite = result;
$scope.$emit('eventSousSecteur', result)
}
	
		});
	

	$scope.lovModel2 = 'api/referentiels/secteur';// this will
													// be
	// the LOV
	// datasource
	$scope.lovTitle2 = 'Liste des secteurs';
	$scope.lovColumnList2 = [ 'Libellé' ];
	$scope.lovFieldList2 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack2 = function(result) {
		$scope.oeExpediteur.secteurActivite = result;
		$scope.$emit('eventSousSecteur', result)
	}

	$scope.lovModel3 = 'api/referentiels/gouvernorat';// this
														// will
														// be
	// the LOV
	// datasource
	$scope.lovTitle3 = 'Liste des gouvernorats';
	$scope.lovColumnList3 = [ 'Libellé' ];
	$scope.lovFieldList3 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack3 = function(result) {
		$scope.adresseSiege.gouvernorat = result;
		$scope.$emit('eventLocaliteAdrSiege', result)
		Agenda
				.getCCIByGouvernorat(
						$scope.adresseSiege.gouvernorat.valeur1)
				.then(
						function(result) {
							$scope.libelleCci = result.data.libelle;
							$scope.telephoneCci = result.data.telephone;
							$scope.adresseCci = result.data.adresse;
							$scope.faxCci = result.data.fax;
							$scope.courrielCci = result.data.courriel;
							$scope.siteWebCci = result.data.siteWeb;

							// $scope.records =
							// $scope.data.length;
						});
	}

	$scope.lovModel5 = 'api/referentiels/gouvernorat';// this
														// will
														// be
	// the LOV
	// datasource
	$scope.lovTitle5 = 'Liste des gouvernorats';
	$scope.lovColumnList5 = [ 'Libellé' ];
	$scope.lovFieldList5 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack5 = function(result) {
		$scope.adresseUsine.gouvernorat = result;
		$scope.$emit('eventLocaliteAdrUsine', result)
		Agenda
				.getCCIByGouvernorat(
						$scope.adresseUsine.gouvernorat.valeur1)
				.then(
						function(result) {
							$scope.libelleCciUsine = result.data.libelle;
							$scope.telephoneCciUsine = result.data.telephone;
							$scope.adresseCciUsine = result.data.adresse;
							$scope.faxCciUsine = result.data.fax;
							$scope.courrielCciUsine = result.data.courriel;
							$scope.siteWebCciUsine = result.data.siteWeb;

							// $scope.records =
							// $scope.data.length;
							console
									.log(
											"gouv : ",
											$scope.adresseSiege.gouvernorat)
						});
	}
	$scope.siegeToUsine = function(siegeUsine) {
		if (siegeUsine == "O") {

			$scope.adresseUsine = $scope.adresseSiege;
		} else
			$scope.adresseUsine = {};
	}

	$scope.getSousSecteur = function(secteur) {
	}

	$scope.lovModel1 = 'api/referentiels/sousSecteur/all';// this
															// will
															// be
	// the LOV
	// datasource
	$scope.lovTitle1 = 'Liste des sous Secteurs';
	$scope.lovColumnList1 = [ 'Libellé' ];
	$scope.lovFieldList1 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack1 = function(result) {
		$scope.oeExpediteur.sousSecteur = result;
	}

	$rootScope
			.$on(
					'eventSousSecteur',
					function(event, result) {
						$scope.lovModel1 = 'api/referentiels/sousSecteur/'
								+ result.codeRubrique;// this
														// will
														// be
						// the LOV
						// datasource
						$scope.lovTitle1 = 'Liste des sous Secteurs d`activités';
						$scope.lovColumnList1 = [ 'Libellé' ];
						$scope.lovFieldList1 = [ 'libelle' ];
						// function to be called when you click
						// "choose" button
						$scope.lovCallBack1 = function(result) {
							$scope.oeExpediteur.sousSecteur = result;
						}
					});

	$scope.lovModel6 = 'api/referentiels/localite/all';// this
														// will
														// be
	// the LOV
	// datasource
	$scope.lovTitle6 = 'Liste des villes';
	$scope.lovColumnList6 = [ 'Libellé' ];
	$scope.lovFieldList6 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack6 = function(result) {
		$scope.adresseUsine.localite = result;
	}

	$rootScope.$on('eventLocaliteAdrUsine', function(event,
			result) {
		$scope.lovModel6 = 'api/referentiels/localite/'
				+ result.codeRubrique;// this will be
		// the LOV
		// datasource
		$scope.lovTitle6 = 'Liste des villes';
		$scope.lovColumnList6 = [ 'Libellé' ];
		$scope.lovFieldList6 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack6 = function(result) {
			$scope.adresseUsine.localite = result;
		}
	});

	$scope.lovModel4 = 'api/referentiels/localite/all';// this
														// will
														// be
	// the LOV
	// datasource
	$scope.lovTitle4 = 'Liste des villes';
	$scope.lovColumnList4 = [ 'Libellé' ];
	$scope.lovFieldList4 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack4 = function(result) {
		$scope.adresseSiege.localite = result;
	}
	$rootScope.$on('eventLocaliteAdrSiege', function(event,
			result) {
		$scope.lovModel4 = 'api/referentiels/localite/'
				+ result.codeRubrique;// this will be
		// the LOV
		// datasource
		$scope.lovTitle4 = 'Liste des villes';
		$scope.lovColumnList4 = [ 'Libellé' ];
		$scope.lovFieldList4 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack4 = function(result) {
			$scope.adresseSiege.localite = result;
		}
	});

	$scope.lovModel7 = 'api/referentiels/getTypeOperateur';// this
															// will
															// be
	// the LOV
	// datasource
	$scope.lovTitle7 = 'Liste des activités';
	$scope.lovColumnList7 = [ 'Libellé' ];
	$scope.lovFieldList7 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack7 = function(result) {
		$scope.oeExpediteur.typeOperateur = result;
		$scope.$emit('eventSecteur', result)
	}
	$scope.lovModel8 = 'api/referentiels/allNationalites';// this
															// will
															// be
	// the LOV
	// datasource
	$scope.lovTitle8 = 'Liste des Nationalités';
	$scope.lovColumnList8 = [ 'Libellé' ];
	$scope.lovFieldList8 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack8 = function(result) {
		$scope.responsable.nationalite = result;
	}

	$scope.lovModel9 = 'api/referentiels/allPays';// this will
													// be
	// the LOV
	// datasource
	$scope.lovTitle9 = 'Liste des pays';
	$scope.lovColumnList9 = [ 'Libellé' ];
	$scope.lovFieldList9 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack9 = function(result) {
		$scope.oeExpediteur.pays = result;
	}

	$scope.lovModel10 = 'api/findAllBanque';// this will be

	// the LOV
	// datasource
	$scope.lovTitle10 = 'Liste des banques';
	$scope.lovColumnList10 = [ 'Dénomination' ];
	$scope.lovFieldList10 = [ 'denomination' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack10 = function(result) {
		vm.paiementCheque.banque = result;
	}
	$scope.lovModel11 = 'api/findAllBanque';// this will be
	// the LOV
	// datasource
	$scope.lovTitle11 = 'Liste des banques';
	$scope.lovColumnList11 = [ 'Dénomination' ];
	$scope.lovFieldList11 = [ 'denomination' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack11 = function(result) {
		vm.paiementVirement.banque = result;
	}

	$scope.lovModel12 = 'api/referentiels/matricule';// this
														// will
														// be
	// the LOV
	// datasource
	$scope.lovTitle12 = 'Liste des matricules';
	$scope.lovColumnList12 = [ 'Libellé' ];
	$scope.lovFieldList12 = [ 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack12 = function(result) {
		$scope.oeExpediteur.matriculeFiscale = result;
	}
	
	$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
	// the LOV
	// datasource
	$scope.lovTitleBureau = 'Liste des bureaux';
	$scope.lovColumnListBureau = [ 'Libellé' ];
	$scope.lovFieldListBureau = [  'libelle' ];
	
	// function to be called when you click "choose" button
	$scope.lovCallBackBureau = function(result) {
		$scope.oeExpediteur.bureau = result;
		
	}

	$scope.changeNature = function(nature) {
		$scope.nature = nature;
		if ($scope.nature.aSigner == 0) {
			$scope.isSigner = false;
		} else {
			$scope.isSigner = true;
		}
	}

	/** file uploader* */
	var uploader = $scope.uploader = new FileUploader({
		url : ''
	});

	$scope.showUploader = function() {
		if (uploader.queue.length == 0
				|| $scope.myFile == undefined) {
			return "hidden";
		} else {
			return "";
		}
	}

	$scope.showUploaderAlert = function() {
		if (uploader.queue.length > 1
				&& $scope.myFile != undefined) {
			return "";
		} else {
			return "hidden";
		}
	}

	$scope.fileAlert = function() {
		if (!($scope.showUploaderAlert() == "hidden")) {
			return true;
		} else {
			return false;
		}
	}

	// FILTERS

	// a sync filter
	uploader.filters
			.push({
				name : 'syncFilter',
				fn : function(item /* {File|FileLikeObject} */,
						options) {
					return this.queue.length < 10;
				}
			});

	// an async filter
	uploader.filters.push({
		name : 'asyncFilter',
		fn : function(item /* {File|FileLikeObject} */, options,
				deferred) {
			setTimeout(deferred.resolve, 1e3);
		}
	});

	// CALLBACKS
	// test de l'uploader
	uploader.onWhenAddingFileFailed = function(
			item /* {File|FileLikeObject} */, filter, options) {
		// console.info('onWhenAddingFileFailed', item, filter,
		// options);
	};
	uploader.onAfterAddingFile = function(fileItem) {
		// console.info('onAfterAddingFile', fileItem);
		for (var i = 0; i < $scope.uploader.queue.length; i++) {
			var itm = $scope.uploader.queue[i];
		}
	};

	uploader.onAfterAddingAll = function(addedFileItems) {
		// console.info('onAfterAddingAll', addedFileItems);
	};
	uploader.onBeforeUploadItem = function(item) {
		// console.info('onBeforeUploadItem', item);
	};
	uploader.onProgressItem = function(fileItem, progress) {
		// console.info('onProgressItem', fileItem, progress);
	};
	uploader.onProgressAll = function(progress) {
		// console.info('onProgressAll', progress);
	};
	uploader.onSuccessItem = function(fileItem, response,
			status, headers) {
		// console.info('onSuccessItem', fileItem, response,
		// status, headers);
	};
	uploader.onErrorItem = function(fileItem, response, status,
			headers) {
		// console.info('onErrorItem', fileItem, response,
		// status, headers);
	};
	uploader.onCancelItem = function(fileItem, response,
			status, headers) {
		// console.info('onCancelItem', fileItem, response,
		// status, headers);
	};
	uploader.onCompleteItem = function(fileItem, response,
			status, headers) {
		// console.info('onCompleteItem', fileItem, response,
		// status, headers);
	};
	uploader.onCompleteAll = function() {
		// console.info('onCompleteAll');
	};

	$scope.fileExist = false;

	$('.file').click(function(e) { // détecter l'evenemnt du
									// click de l'ajout du
									// fichier
		$scope.fileExist = true;
		$rootScope.$emit('reload');
	});

	// verifier le type du fichier excel xls ou xlsx
	function fileExtension(fileName) {
		if (fileName.indexOf("pdf") !== -1) {
			return "pdf";
		}

		if (fileName.indexOf("jpeg") !== -1) {
			return "jpeg";
		}
		if (fileName.indexOf("png") !== -1) {
			return "png";
		}
	}

	$scope.showUploder = true;
	$scope.uploadFile = function(item, codeNature) {
		if ($scope.dateAvert == null) {
			$scope.dateAvert = new Date('01/01/1500');
		}
		var file = $scope.myFile;
		var fileName = file != undefined ? file.name : "";
		var uploadUrl = "api/uploadPieceJointe/" + codeNature;
		item.upload();
		if (typeof file != 'undefined') {
			var result = fileUpload
					.uploadFileToUrl(file, uploadUrl)
					.then(
							function(result) {
								swal({
									timer : 2000,
									title : "Succès",
									text : "Le fichier est sauvegardé avec Succès",
									showConfirmButton : false,
									type : "success"
								});

							});
		}

	};

	$scope.downloadPiece = function(docId) {
		var downloadLink = document.createElement("a");

		document.body.appendChild(downloadLink);
		downloadLink.style = "display: none";
		Inscription2
				.downloadDocumentFromDirectory(docId)
				.then(
						function(result) {
							if (angular.equals({
								status : result.status
							}, {
								status : 500
							})) {
								swal(
										message,
										"Un problème s'est produit!",
										"error")
							}
							var fName = result
									.headers('Content-Disposition');
							var file = new Blob(
									[ result.data ],
									{
										type : 'application/pdf'
									});
							var fileURL = (window.URL || window.webkitURL)
									.createObjectURL(file);
							downloadLink.href = fileURL;
							downloadLink.download = fName;
							downloadLink.click();
						});
	}
	// $scope.$on('eventEmitedPaiement', function(event, data) {
	// $scope.docPaiement = data.message;
	// });

	$scope.dtOptionsConsolide = DTOptionsBuilder
			.newOptions()
			// or here
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
			.withDOM(
					"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource(
					$translate.instant('global.datatable'))

	$scope.dtColumnsConsolide = [

			DTColumnDefBuilder.newColumnDef(0).withTitle(
					"Nature").withOption('width', '15%'),
			DTColumnDefBuilder.newColumnDef(1).withTitle(
					"Fichier").withOption('width', '15%'),

	];

	function separateur(aa) {
		aa = number_format(aa[0], 0, ' ', ' ');
		return ("aa");
	}
	function createdRow(row, data, dataIndex) {
		// Recompiling so we can bind Angular directive to the
		// DT
		$compile(angular.element(row).contents())($scope);
	}

	$('.unmask').on('click', function() {

		if ($(this).prev('input').attr('type') == 'password')
			changeType($(this).prev('input'), 'text');

		else
			changeType($(this).prev('input'), 'password');

		return false;
	});

	function changeType(x, type) {
		if (x.prop('type') == type)
			return x; // ça serait facile.
		try {
			// Une sécurité d'IE empêche ceci
			return x.prop('type', type);
		} catch (e) {
			// On tente de recréer l'élément
			// En créant d'abord une div
			var html = $("<div>").append(x.clone()).html();
			var regex = /type=(\")?([^\"\s]+)(\")?/;
			// la regex trouve type=text ou type="text"
			// si on ne trouve rien, on ajoute le type à la fin, sinon on le remplace
			var tmp = $(html.match(regex) == null ? html
					.replace(">", ' type="' + type + '">')
					: html
							.replace(regex, 'type="' + type
									+ '"'));

			// on rajoute les vieilles données de l'élément
			tmp.data('type', x.data('type'));
			var events = x.data('events');
			var cb = function(events) {
				return function() {
					//Bind all prior events
					for (i in events) {
						var y = events[i];
						for (j in y)
							tmp.bind(i, y[j].handler);
					}
				}
			}(events);
			x.replaceWith(tmp);
			setTimeout(cb, 10); // On attend un peu avant d'appeler la fonction
			return tmp;
		}
	}

	var unsubscribe1 = $scope.$on('eventEmitedPiece', function(
			event, result) {
		$scope.pieces = result.message;
		$scope.pieceValide = result.pieceValide;

	});
	$scope.$on('$destroy', unsubscribe1);
	
	
	
}

})();
