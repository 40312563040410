(function() {
	'use strict';
	angular.module('infochamApp').controller('CciController', CciController);
	CciController.$inject = [ '$scope', '$state', 'Cci', 'ParseLinks',
			'DTOptionsBuilder', '$filter', '$resource', '$rootScope',
			'$compile', '$translate', 'DTColumnBuilder' ];

	function CciController($scope, $state, Cci, ParseLinks, DTOptionsBuilder,
			$filter, $resource, $rootScope, $compile, $translate,
			DTColumnBuilder) {

		function actionsHtml(data, type, full, meta) {
			return '<div class="action-center" style="margin-top:0px">'
					+ ' <div class="col-md-1"><a ui-sref="cci.edit({id:'
					+ data.id
					+ '})">'
					+ '   <i class="fa fa-pencil pencil-style"></i> '
					+ '</a></div>'
					+ '<div class="col-md-1"><a ui-sref="cci.delete({id:'
					+ data.id
					+ '})" )"="">'
					+ '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> '
					+ '</a></div>'
		}

		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					url : 'api/findAllCci',
					type : 'GET',

				})
				.withOption('createdRow', createdRow)

				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				// }
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					},
					'4' : {
						type : 'text'
					},
					'5' : {
						type : 'text'
					},
					'6' : {
						type : 'text'
					},
					'7' : {
						type : 'text'
					},
					'8' : {
						type : 'text'
					},
					'9' : {
						type : 'text'
					},
					'10' : {
						type : 'text'
					},
				})

		$scope.dtColumns = [
				DTColumnBuilder.newColumn(null).withTitle('Code').withOption(
						'width', '10%').renderWith(actionsGouv),
				DTColumnBuilder.newColumn('libelle').withTitle('Libellé')
						.withOption('width', '20%'),
						DTColumnBuilder.newColumn('libelleArabe').withTitle('Libellé Arabe')
						.withOption('width', '20%'),
				DTColumnBuilder.newColumn('telephone').withTitle('Téléphone')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('fax').withTitle('Fax').withOption(
						'width', '10%'),
				DTColumnBuilder.newColumn('siteWeb').withTitle('Site web')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('adresse').withTitle('Adresse')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('courriel').withTitle('Courriel')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('mntSpecimen').withTitle(
						'Montant specimen').withOption('width', '10%'),
				DTColumnBuilder.newColumn(null).withTitle('Siége ?')
						.withOption('className', 'text-center').withOption(
								'width', '10%').renderWith(isSiege),
				DTColumnBuilder.newColumn(null).withTitle('Actions')
						.notSortable().withClass('datatable-action-width')
						.renderWith(actionsHtml),

		];
		$scope.search = 0;

		function isSiege(data, type, full, meta) {
			// $scope.soumissions[data.id] = data;
			if (data.isSiege == 0 || data.isSiege == null) {
				return '<div   class="badge badge-warning badge-statut">Non</div>';
			} else if (data.isSiege == 1) {
				return '<div  class="badge badge-success badge-statut">Oui</div>';
			}
		}

		function actionsGouv(data, type, full, meta) {
			return '<a href="#/ccis/' + data.code
					+ '/gouvernorat"  class="directive-lien">' + data.code
					+ '</a>'
		}
		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		$scope.dtInstance = {};

		$scope.clear = function() {

		};
	}
})();
