(function() {
	'use strict';
	angular.module('infochamApp').controller('AdresseDialogController',
			AdresseDialogController);
	AdresseDialogController.$inject = [ '$scope', '$stateParams',
			'$uibModalInstance', 'entity', 'Adresse', 'Referentiel' ];
	function AdresseDialogController($scope, $stateParams, $uibModalInstance,
			entity, Adresse, Referentiel) {
		$scope.adresse = entity;
		$scope.referentiels = Referentiel.query();
		$scope.load = function(id) {
			Adresse.get({
				id : id
			}, function(result) {
				$scope.adresse = result;
			});
		};

		var onSaveSuccess = function(result) {
			$scope.$emit('infochamApp:adresseUpdate', result);
			$uibModalInstance.close(result);
			$scope.isSaving = false;
		};

		var onSaveError = function(result) {
			$scope.isSaving = false;
		};

		$scope.save = function() {
			$scope.isSaving = true;
			if ($scope.adresse.id != null) {
				Adresse.update($scope.adresse, onSaveSuccess, onSaveError);
			} else {
				Adresse.save($scope.adresse, onSaveSuccess, onSaveError);
			}
		};

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}

})();