(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('NumeroAttestationController',NumeroAttestationController);
NumeroAttestationController.$inject=['$scope', '$state', 'NumeroAttestation', 'ParseLinks', '$filter','$resource', 'Referentiel', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService'];
function NumeroAttestationController($scope, $state, NumeroAttestation, ParseLinks, $filter,$resource, Referentiel, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService){
	$scope.agenceTypes = {};
	

	function actionsHtml(data, type, full, meta) {
		// $scope.agenceTypes[data.id] = data;
		return '<div class="action-center" style="margin-top:0px">' + ' <div class="col-md-1"><a ui-sref="numeroAttestation.edit({id:' + data.id + '})">' +
		'   <i class="fa fa-pencil pencil-style"></i> ' + '</a></div>' + '<div class="col-md-1"><a ui-sref="numeroAttestation.delete({id:' + data.id + '})" )"="">' + '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> ' + '</a></div>'
	}
	
	$scope.dtOptions = DTOptionsBuilder
	.newOptions()
	.withOption('ajax', {
		url: 'api/findAllNumeroAttestation',
		type: 'GET',
		
	})
.withOption('createdRow', createdRow)

.withPaginationType('full_numbers')
.withOption('order', [0, 'asc'])
.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
//        }
.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

	'0' : {
		type : 'text'
	},
	'1' : {
		type : 'text'
	},
	
})

$scope.dtColumns = [
    DTColumnBuilder.newColumn('libelle').withTitle('Type de Certificat').withOption('width', '20%'),

    DTColumnBuilder.newColumn('numero').withTitle('Numéro').withOption('width', '20%'),
	DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withClass('datatable-action-width')
	.renderWith(actionsHtml),

	];
$scope.search=0;
function createdRow(row, data, dataIndex) {
$compile(angular.element(row).contents())($scope);
}

	$scope.dtInstance = {};

	$scope.clear = function() {

	};	
}

})();
