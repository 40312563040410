(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('NaturePieceJointeController', NaturePieceJointeController);

	NaturePieceJointeController.$inject = ['$timeout' , '$scope', '$state', 'CustomNaturePieceJointe', 'ParseLinks', 'DTOptionsBuilder', '$filter','$resource', '$rootScope', '$compile', '$translate', 'DTColumnBuilder','DTColumnDefBuilder'];

	function NaturePieceJointeController ($timeout , $scope, $state, CustomNaturePieceJointe, ParseLinks, DTOptionsBuilder, $filter,$resource, $rootScope, $compile, $translate, DTColumnBuilder,DTColumnDefBuilder) {

		
		$scope.dtOptions = DTOptionsBuilder
		.newOptions()
		.withOption('order', [ 0, 'asc' ])
		.withPaginationType('full_numbers')
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap()
		.withLanguageSource($translate.instant('global.datatable'))
		.withLightColumnFilter({
			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			}

		})

	$scope.dtColumns = [
		DTColumnDefBuilder.newColumnDef(0).withTitle('Nature pièce jointe').withOption('width', '40%'),
		DTColumnDefBuilder.newColumnDef(1).withTitle('Etape process').withOption('width', '20%'),
		DTColumnDefBuilder.newColumnDef(2).withTitle('Obligatoire ?').withOption('width', '10%'),
		DTColumnDefBuilder.newColumnDef(3).withTitle('Ordre').withOption('width', '10%'),
		DTColumnDefBuilder.newColumnDef(4).withTitle('Note').withOption('width', '10%'),
		DTColumnDefBuilder.newColumnDef(5).withTitle('Actions').withOption('width','10%').notSortable()
			];


		
		$scope.search=0;
		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		$scope.dtInstance = {};

		$scope.clear = function() {

		};

		//choisir Process
		$scope.choisir = function(){
			$state.go('naturePieceJointe.typeProcessForPiece');
		}
		
		//choisir Process
		$scope.addNewType = function(){
			$state.go('naturePieceJointe.new',{typeProcess:$scope.typeProcess});
		}

		$rootScope.$on('itemChoosen', function (event, data) {
			$scope.typeProcess = data
			$scope.DisplayTable();
		});

		$rootScope.$on('reload', function(event, result) {
			$scope.DisplayTable ();
		});

		$scope.DisplayTable = function(){
			CustomNaturePieceJointe.getPieceJointeByCodeProcess($scope.typeProcess.codeRubrique).then(function (result){
				$scope.data = result.data;
				$scope.records = $scope.data.length;
			});
		}
		
		$rootScope.$on('savedNatPJ', function(event,result) {
			$timeout( function(){
				if (result.update == true){
					$scope.DisplayTable();
				}
			}, 500 );

		});

}
})();
