

(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('referentiel', {
            parent: 'entity',
            url: '/referentiels',
            data: {
                authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],
                pageTitle: 'infochamApp.referentiel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/referentiel/referentiels.html',
                    controller: 'ReferentielController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('referentiel');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('referentiel.detail', {
            parent: 'entity',
            url: '/referentiel/{id}',
            data: {
                authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],
                pageTitle: 'infochamApp.referentiel.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/referentiel/referentiel-detail.html',
                    controller: 'ReferentielDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('referentiel');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Referentiel', function($stateParams, Referentiel) {
                    return Referentiel.get({id : $stateParams.id});
                }]
            }
        })
        .state('referentiel.new', {
            parent: 'referentiel',
            url: '/new',
            data: {
                authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],

            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/referentiel/referentiel-add.html',
                    controller: 'ReferentielDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                codeType: null,
                                codeRubrique: null,
                                libelle: null,
                                valeur1: null,
                                valeur2: null,
                                status: null,
                                codeListeMere: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('referentiel', null, { reload: true });
                }, function() {
                    $state.go('referentiel');
                })
            }]
        })
        .state('referentiel.edit', {
            parent: 'referentiel',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],

            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/referentiel/referentiel-update.html',
                    controller: 'ReferentielDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['Referentiel', function(Referentiel) {
                            return Referentiel.get({id : $stateParams.id});
                         
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('referentiel', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('referentiel.delete', {
            parent: 'referentiel',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],

            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/referentiel/referentiel-delete-dialog.html',
                    controller: 'ReferentielDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['Referentiel', function(Referentiel) {
                            return Referentiel.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('referentiel', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        });
}
})();

