(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeAdhesionDeleteController', TypeAdhesionDeleteController);

	TypeAdhesionDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'TypeAdhesion', '$rootScope'];

	function TypeAdhesionDeleteController ($scope, $uibModalInstance, entity, TypeAdhesion, $rootScope) {

        $scope.typeAdhesion = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            TypeAdhesion.delete({id: id},
                function () {
            		$rootScope.$broadcast('saveTypeAdhesion',{saved:true});
                    $uibModalInstance.close(true);
                });
        };

    }
})();
