(function() {
	'use strict';
	angular
	.module('infochamApp')
	.directive('detailAgendaDirective', detailAgendaDirective)

	detailAgendaDirective.$inject = ['$rootScope'];

	function detailAgendaDirective ($rootScope) {
		return {
			restrict: "E",
			scope: {
				idagenda:'=',
			},
			templateUrl: 'app/directives/menu/detailAgendaDirective.html',
				controller:['blockUI', '$timeout','fileReader' ,'fileUpload' ,
					'FileUploader' ,'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope', 
					'$state', 'ParseLinks',  '$rootScope', '$compile', '$translate', 'DTOptionsBuilder',
					'DTColumnBuilder','$http', 'Principal', function (blockUI, $timeout,fileReader ,fileUpload ,
							FileUploader ,DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, 
							$state, ParseLinks,  $rootScope, $compile, $translate, DTOptionsBuilder,
							DTColumnBuilder,$http, Principal){

				$http.get("api/getAgendaDTOById/"+$scope.idagenda).then(function(result) {
					$scope.agendaDTO = result.data;
				});
				}]
		};

	}
	

})();


