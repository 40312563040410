(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('affecterphotodetaillovlibre', affecterphotodetaillovlibre)
	.filter('StartOnPage',StartOnPage)
	.controller('AffecterPhotoDialogController',AffecterPhotoDialogController);

	affecterphotodetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	AffecterPhotoDialogController.$inject = ['DTColumnBuilder', '$translate', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','specimen', 'index', 'FileUploader', 'blockUI' ];

	function affecterphotodetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				specimen:'@',
				index: '@',
				btn: '&',

			},
	
			template: '<a  ng-show="index != undefined || index != null " class="glyphicon glyphicon glyphicon-edit" style="color: orange" ng-click="openPopUp(specimen,index,btn)"   />' + 
			'<a ng-show="index == undefined && index == null" class="glyphicon glyphicon glyphicon-plus-sign" style="color: orange" ng-click="openPopUp(specimen,index,btn)"     />',

			controller : [ '$scope', '$element', function($scope, $element) {
				
				$scope.openPopUp = function(specimen,index,btn) {
					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/affecterPhotoDialog.html',
							controller : 'AffecterPhotoDialogController',
							resolve: {
								specimen: function () {
									
									return specimen;
								},index: function () {
									return index;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
					modalInstance.result.then(function (selectedItem) {
						btn({ e: selectedItem });
					}, function () {
						//dismiss: nothing to do
					});
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			
			start = +start;
			return input.slice(start);
		} }
	
	function AffecterPhotoDialogController (DTColumnBuilder, $translate, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,specimen, index , FileUploader, blockUI) {

		
		$scope.specimenSignature={}

		if (specimen != undefined  && specimen != null && specimen != {} && specimen != "{}") {
			$scope.specimenSignature =  angular.fromJson(specimen);
			$scope.index = index;

		}


		if ($scope.specimenSignature.pieceJointeDTO != undefined &&  $scope.specimenSignature.pieceJointeDTO != null ) {
			$scope.showBtnUpdate = true;
		}

		else if ($scope.specimenSignature.image != null  && $scope.index != undefined ) {
			$scope.showBtnUpdate = true;

		} else {
			$scope.showBtnUpdate = false;

		}

		//when you click "choose" button
		$scope.chooseItem = function (result) {
			$uibModalInstance.close(result);
		}


		var uploader = $scope.uploader = new FileUploader({  url: '' });

		$scope.save=function(){
			if ($scope.specimenSignature.pieceJointeDTO != undefined &&  $scope.specimenSignature.pieceJointeDTO != null ) {
				$scope.specimenSignature.index = index;
			}

			else if ($scope.specimenSignature.image != null  && $scope.index != undefined ) {
				$scope.specimenSignature.index = index;
			} else {
			}


			$scope.chooseItem($scope.specimenSignature);


		}
		$scope.$on('eventEmitedPhoto', function(event, data) {
			blockUI.start();
			$scope.isSaving = true;
			$scope.photo = data.message;
			$scope.type = data.message.type;
			$scope.size = data.message.size;
			$scope.imageUser = data.image;
			$scope.specimenSignature.myFile = data;
			$scope.specimenSignature.image = data.image;
			$scope.specimenSignature.nameImage = data.message.name;
			$scope.isSaving = false;
			blockUI.stop();
		});


		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
	{}}
})();
