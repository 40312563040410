(function() {
	'use strict';

	angular.module('infochamApp').directive('widgetGridDirective',
			widgetGridDirective);

	widgetGridDirective.$inject = [ '$rootScope', '$compile', '$q', '$state',
			'$timeout', 'Historique' ];

	function widgetGridDirective($rootScope, $compile, $q, $state, $timeout,
			Historique) {
		// evénement panel collapse
		$(".panel-collapse").on(
				"hide.bs.collapse",
				function() {
					$(".panel-collapse-clickable").find('i').removeClass(
							"glyphicon-chevron-up").addClass(
							"glyphicon-chevron-down");
				});

		$(".panel-collapse").on(
				"show.bs.collapse",
				function() {
					$(".panel-collapse-clickable").find('i').removeClass(
							"glyphicon-chevron-down").addClass(
							"glyphicon-chevron-up");
				});

		return {
			restrict : 'E',
			// passer deux paramétres pour la directive
			scope : {
				// passer le nom de l'entité
				nomEntite : '@',
				// passer l'id de l'entité
				isolatedAttributeIdEntite : '@attributeIdEntite',
			},
			templateUrl : 'app/directives/menu/historique-directive.html',
			controller : [
					'$scope',
					'$attrs',
					'Historique',
					function() {
						$scope.id = "";
						$scope.historiques = {};
						// récuperer code type de l'entité ( entité en cours) by
						// nom de l'entité ($attrs.nomentite)
						Historique
								.getCodeEntiteByLibelle($attrs.nomentite)
								.then(
										function(result) {
											$scope.codetype = result; // get
																		// code
																		// type
																		// (exemple
																		// : 3
																		// pour
																		// categorie
																		// assure)
											// get historique By id entite
											// ($scope.isolatedAttributeEntite)et
											// code entité déja récupéré
											// ($scope.codetype)
											Historique
													.getHistoriqueByEntite(
															$scope.isolatedAttributeIdEntite,
															$scope.codetype)
													.then(
															function(result) {
																$scope.historiques = JSON
																		.parse(JSON
																				.stringify(result));
															});
										});
					} ]

		}
	}

})();
