(function() {
	'use strict';
	angular.module('infochamApp').controller('UserManagementScanController',
			UserManagementScanController);

	UserManagementScanController.$inject = [ '$filter','$compile','DTOptionsBuilder' ,
		'$translate','DTColumnBuilder' ,'$rootScope','$scope', 'Principal', 'User', 'ParseLinks',
		'Inscription2' ];

	function UserManagementScanController($filter,$compile,DTOptionsBuilder ,
			$translate,DTColumnBuilder ,$rootScope,$scope, Principal, User, ParseLinks,
			Inscription2) {
		$scope.users = [];
		$scope.dtInstance = {};
		$scope.lien = 'api/AllUsersNonOE';
		$scope.tab = [];
		$scope.dtOptions = DTOptionsBuilder
		.newOptions()
		.withOption('ajax', {
			url: $scope.lien,
			type: 'GET',

		})
		.withOption('createdRow', createdRow)

		.withPaginationType('full_numbers')
		.withOption('order', [0, 'asc'])
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			},
			'4' : {
				type : 'text'
			},
			'5' : {
				type : 'text'
			}

		})


		$scope.dtColumns = [
			DTColumnBuilder.newColumn('login').withTitle($translate.instant('user-management.login')).withOption('width', '20%'),
			DTColumnBuilder.newColumn('firstName').withTitle($translate.instant('user-management.lastName')).withOption('width', '15%'),
			DTColumnBuilder.newColumn('lastName').withTitle($translate.instant('user-management.firstName')).withOption('width', '15%'),
			DTColumnBuilder.newColumn('email').withTitle($translate.instant('user-management.email')).withOption('width', '15%'),
			DTColumnBuilder.newColumn(null).withTitle($translate.instant('user-management.profiles')).withOption('width', '10%').withOption('className', 'text-center').renderWith(actionsDroit),
			DTColumnBuilder.newColumn('createdDate').withTitle($translate.instant('user-management.createdDate')).withOption('width', '10%').renderWith(function(data, type) {
				return $filter('date')(data, 'dd/MM/yyyy');
			}),

			DTColumnBuilder.newColumn(null).withTitle("Scan").withOption('className', 'text-center').renderWith(function(data, type) {
				$scope.tab[data.id] = data.allowToScan;
				return '<label '
				+' class="switch switch-text switch-pill switch-success-outline-alt"> '
				+' <input id="hh" type="checkbox" class="switch-input" '
				+'  ng-checked="'+ data.allowToScan +'" ng-model="tab['+data.id+']"  ng-change="changeAllawToScan('+data.id+',tab['+data.id+'])"  >'
				+' <span class="switch-label" data-on="oui" data-off="non"></span> '
				+' <span class="switch-handle"></span> '
				+' </label> ' 
			}),
			]


		$scope.changeAllawToScan = function(idUser,allowToScan) {
			Inscription2.updateAllowUserToScan(idUser,allowToScan).then(function (result) {
				
				if(angular.equals( { status: result.status }, { status: 200 } )){
					if (result.data.stringReturn != null) {
						swal("Opération interrompue!",result.data.stringReturn, "error");
						$scope.tab[idUser] = false;
					}
				}
			});
		}

		$scope.search=0;
		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		$scope.userss=[];
		function actionsHtml(data, type, full, meta) {
			$scope.users[data.id] = data;
			return '<div class="action-center" style="margin-top:0px">' +
			' <div class="col-md-1"><a href="#/user-management/'+ data.id +'/edit">' +
			'   <i class="fa fa-pencil" style="font-size: 18px;color:#FF931D"></i> ' +
			'</a></div>' 
		}
		
		function actionsDroit(data, type, full, meta) {
			return '<a href="#/user-management-scan/'+ data.id +'/droit-scan"  class="badge badge-danger bg-dangerStyle" >'+data.authorities.length+'</a>'
		}


}
})();
