(function() {
	'use strict';
	angular
		.module('infochamApp')
		.controller('DepotController',DepotController);
	DepotController.$inject=['$scope', '$state', 'Depot', 'ParseLinks'];
	function DepotController($scope, $state, Depot, ParseLinks){
		$scope.depots = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            Depot.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.depots = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.depot = {
                fraisPersDirProd: null,
                fraisPersIndirProd: null,
                totalMo: null,
                fraisTransp: null,
                commAchat: null,
                fraisStock: null,
                sousTrait: null,
                entrRepar: null,
                matNonStock: null,
                matConsEnreg: null,
                serExt: null,
                chargFinanc: null,
                amortiss: null,
                totalProd: null,
                dateCommDoss: null,
                commentaire: null,
                dateCre: null,
                id: null
            };
        };
	}

	})();

