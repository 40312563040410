(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ConsultAllDARAController', ConsultAllDARAController);

	ConsultAllDARAController.$inject = ['ReferentielWs' ,'ReportingService' ,'Inscription2','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$http','$state', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function ConsultAllDARAController (ReferentielWs ,ReportingService ,Inscription2,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal,  $http,$state, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {


		
		
		// or here
		$scope.dtOptions = DTOptionsBuilder.newOptions().withOption('ajax', {
			url: 'api/getEtatRechercheInsAdhRA',
		    type: 'GET'
		})
			
		.withDataProp('data')
		.withDisplayLength(10)
		.withOption('processing', true)
		.withOption('serverSide', true)
		.withOption('autoWidth', false)
		.withOption('order',[[3, 'desc']])
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
		.withLightColumnFilter({
			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			},
			'4' : {
				 "type" : "select",values : [ {"value" : "Adhérent simple","label" : "Adhérent simple"},{"value" : "Pack Privilège","label" : "Pack Privilège"}]
			},
			'5' : {
				type : 'text'
			},
			'6' : {
				type : 'text'
			},
			'7' : {
				type : 'text'
			},
			'8' : {
				type : 'text'
			},
			'9' : {
				type : 'text'
			},

			

			
		})
		$scope.dtColumns = [  
			DTColumnBuilder.newColumn(null).withTitle($translate.instant('Référence adhésion')).withOption('width', '2%').renderWith(function(data, type) {
				if (angular.equals( { status: data.CODE_RUBRIQUE }, { status: "INS" } )) {
					return data.REFERENCE
				} else {
					return '<label class="directive-lien"> '+data.REFERENCE+'<span demandeadhesiondetaillovlibre typeprocess="'+data.CODE_RUBRIQUE+'"  idda="'+data.IDDEMANDE+'" ></span></label>'
				}
			}),
			DTColumnBuilder.newColumn('RAISON_SOCIALE').withTitle($translate.instant('Raison sociale')).withOption('width', '10%'),
			DTColumnBuilder.newColumn(null).withTitle('Identifiant Unique').withOption('width', '5%').renderWith(function(data, type) {
				return '<label class="directive-lien"> '+data.NUM_IDE+'<span oeexpediteurdetaillovlibre idoe="'+data.ID+'" ></span></label>'
		}),
		DTColumnBuilder.newColumn('DATE_CRE').withTitle($translate.instant('Date d\'adhésion')).withOption('width', '10%'),
		DTColumnBuilder.newColumn('TYPE_ADHESION').withTitle('Type Adhésion').withOption('width', '20%'),
		DTColumnBuilder.newColumn('GOUV').withTitle($translate.instant('Gouvernorat')).withOption('width', '10%'),
			DTColumnBuilder.newColumn('NOM_RESP').withTitle('Nom du responsable').withOption('width', '10%'),
			DTColumnBuilder.newColumn('PRENOM_RESP').withTitle('Prénom du responsable').withOption('width', '10%'),
			DTColumnBuilder.newColumn('SECTEUR').withTitle('Secteur').withOption('width', '20%'),
			DTColumnBuilder.newColumn('TEL').withTitle('Téléphone').withOption('width', '10%'),
			
		];	
	 
	 function oeExp (data, type, full, meta){
			return '<label class="directive-lien"> '+data+'<span oeexpediteurdetaillovlibre idoe="'+data+'" ></span></label>'
		}
	
		 

		function createdRow(row, data, dataIndex) {
		$compile(angular.element(row).contents())($scope);
		}
}
})();
