(function() {
	'use strict';
	angular.module('infochamApp').controller('PaiementEnLigne',PaiementEnLigne);
	PaiementEnLigne.$inject = [ '$filter','$resource', '$scope', '$state', 'PaiementEnLigneConfig','ParseLinks', '$rootScope', '$compile', '$translate',
		'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService' ];

	function PaiementEnLigne($filter,resource, $scope, $state, PaiementEnLigneConfig, ParseLinks,
			$rootScope, $compile, $translate, DTOptionsBuilder,
			DTColumnBuilder, ReportingService) {
		$scope.agenceTypes = {};
		$scope.dtOptions = DTOptionsBuilder
		.newOptions()
		.withOption('ajax', {
			url : 'api/findAllPaiementConfiguration',
			type : 'GET',
		})
		.withOption('createdRow', createdRow)

		.withPaginationType('full_numbers')
		.withOption('order', [ 0, 'asc' ])
		.withDOM(
		"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource(
				$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					},
					'4' : {
						type : 'text'
					},
					'5' : {
						type : 'text'
					},


				})

				$scope.dtColumns = [
					DTColumnBuilder.newColumn('denomination').withTitle(
					'Dénomination').withOption('width', '20%'),
					DTColumnBuilder.newColumn('userName').withTitle('Nom de compte')
					.withOption('width', '10%'),

					DTColumnBuilder.newColumn('dateCreation').withTitle(
					'Date de créaction')
					.withOption('width', '15%').renderWith(
							function(data, type) {

								return $filter('date')(data, 'dd/MM/yyyy');
							}),
					DTColumnBuilder.newColumn('currency').withTitle('type de device')
							.withOption('width', '10%'),
					DTColumnBuilder.newColumn('language').withTitle(
							'Langage').withOption('width', '10%'),
					DTColumnBuilder.newColumn('dateModification').withTitle(
							'Date de modification')
							.withOption('width', '15%').renderWith(
									function(data, type) {

										return $filter('date')(data, 'dd/MM/yyyy');
									}),

									DTColumnBuilder.newColumn(null).withTitle('Actions')
									.notSortable().withClass('datatable-action-width')
									.renderWith(actionsHtml), ];
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}

		function actionsHtml(data, type, full, meta) {
			// $scope.agenceTypes[data.id] = data;
			return '<div class="action-center" style="margin-top:0px">'
			+ ' <div class="col-md-1"><a ui-sref="paiementEnLigne.edit({id:'
			+ data.id
			+ '})">'
			+

			'   <i class="fa fa-pencil pencil-style"></i> '
			+ '</a></div>'
			+ '<div class="col-md-1"><a ui-sref="paiementEnLigne.delete({id:'
			+ data.id
			+ '})" )"="">'
			+ '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> '
			+ '</a></div>'
		}
		$scope.dtInstance = {};

		$scope.clear = function() {

		};
	}
})();
