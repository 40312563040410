(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('settingScan', {
            parent: 'entity',
            url: '/settingScans',
            data: {
                authorities: [],
                pageTitle: 'infochamApp.settingScan.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/settingScan/settingScans.html',
                    controller: 'SettingScanController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingScan');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('settingScan.detail', {
            parent: 'entity',
            url: '/settingScan/{id}',
            data: {
                authorities: [],
                pageTitle: 'infochamApp.settingScan.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/settingScan/settingScan-detail.html',
                    controller: 'SettingScanDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settingScan');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SettingScan', function($stateParams, SettingScan) {
                    return SettingScan.get({id : $stateParams.id});
                }]
            }
        })
        .state('settingScan.new', {
            parent: 'settingScan',
            url: '/new',
            data: {
                authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/settingScan/settingScan-dialog.html',
                    controller: 'SettingScanDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                scannerUrl: null,
                                scannerPort: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('settingScan', null, { reload: true });
                }, function() {
                    $state.go('settingScan');
                })
            }]
        })
        .state('settingScan.edit', {
            parent: 'settingScan',
            url: '/{id}/edit',
            data: {
                authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/settingScan/settingScan-dialog.html',
                    controller: 'SettingScanDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['SettingScan', function(SettingScan) {
                            return SettingScan.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('settingScan', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('settingScan.delete', {
            parent: 'settingScan',
            url: '/{id}/delete',
            data: {
                authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/settingScan/settingScan-delete-dialog.html',
                    controller: 'SettingScanDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['SettingScan', function(SettingScan) {
                            return SettingScan.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('settingScan', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        });
}
})();


