(function() {
	'use strict';

	angular.module('infochamApp')
        .directive('wcUnique', wcUnique);

	wcUnique.$inject = ['dataService'];

    
    function wcUnique (dataService) {
 	   
    	   return {
    		
    	  
    	       restrict: 'A',
    	       require: 'ngModel',
    	       link: function (scope, element, attrs, ngModel) {
    	    	  

    	           element.bind('change', function (e) {
    	               if (!ngModel || !element.val()) return;
    	               var keyProperty = scope.$eval(attrs.wcUnique);
    	               var currentValue = element.val();
    	               if(!("userByEmail"==keyProperty.property && keyProperty.value[0]==undefined)){
    	               dataService.checkUniqueValue(keyProperty.property ,keyProperty.key, keyProperty.value)
    	                   .then(function (unique) {
    	                       //Ensure value that being checked hasn't changed
    	                       //since the Ajax call was made
    	                       if (currentValue == element.val()) { 
    	                           ngModel.$setValidity('unique', unique);

    	                       }
    	                   }, function () {
    	                       //Probably want a more robust way to handle an error
    	                       //For this demo we'll set unique to true though
    	                       ngModel.$setValidity('unique', true);

    	                   });
    	               
    	           }
    	           });
    	       }
    	   }
}

})();
