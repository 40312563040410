(function() {
'use strict';
angular
	.module('infochamApp')
	.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('paramPaiement', {
        parent: 'entity',
        url: '/paramPaiement',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.paramPaiement.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/paramPaiement/paramPaiement.html',
                controller: 'ParamPaiementController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                $translatePartialLoader.addPart('document');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    .state('paramPaiement.detail', {
        parent: 'entity',
        url: '/paramPaiement/{id}',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.paramPaiement.detail.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/ParamPaiement/paramPaiement-detail.html',
                controller: 'ParamPaiementDetailController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                $translatePartialLoader.addPart('document');
                return $translate.refresh();
            }],
            entity: ['$stateParams', 'ParamPaiement', function($stateParams, ParamPaiement) {
                return ParamPaiement.get({id : $stateParams.id});
            }]
        }
    })
    .state('paramPaiement.new', {
        parent: 'paramPaiement',
        url: '/new',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/paramPaiement/paramPaiement-add.html',
                controller: 'ParamPaiementDialogController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            codeType: null,
                            codeRubrique: null,
                            libelle: null,
                            valeur1: null,
                            valeur2: null,
                            status: null,
                            codeListeMere: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(result) {
                $state.go('paramPaiement', null, { reload: true });
            }, function() {
                $state.go('paramPaiement');
            })
        }]
    })
    .state('paramPaiement.edit', {
        parent: 'paramPaiement',
        url: '/{id}/edit',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/paramPaiement/paramPaiement-update.html',
                controller: 'ParamPaiementDialogController',
                size: 'lg',
                resolve: {
                    entity: ['ParamPaiement', function(ParamPaiement) {
                        return ParamPaiement.get({id : $stateParams.id});
                     
                    }]
                }
            }).result.then(function(result) {
                $state.go('paramPaiement', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    .state('paramPaiement.delete', {
        parent: 'paramPaiement',
        url: '/{id}/delete',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/paramPaiement/paramPaiement-delete-dialog.html',
                controller: 'ParamPaiementDeleteController',
                size: 'md',
                resolve: {
                    entity: ['ParamPaiement', function(ParamPaiement) {
                        return ParamPaiement.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('paramPaiement', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    });	
}

})();
