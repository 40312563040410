(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('MatierePremiereDeleteController',MatierePremiereDeleteController);
MatierePremiereDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'MatierePremiere'];
function MatierePremiereDeleteController($scope, $uibModalInstance, entity, MatierePremiere){
	$scope.matierePremiere = entity;
    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.confirmDelete = function (id) {
        MatierePremiere.delete({id: id},
            function () {
                $uibModalInstance.close(true);
            });
    };
}

})();
