(function() {
	    'use strict';

	    angular
	        .module('infochamApp')
	        .directive('piecejustificative', piecejustificative)

	    piecejustificative.$inject = ['$rootScope'];
	    function piecejustificative($rootScope) {
	        return {
				restrict: "E",
				scope: {
					target: '=',
					codeprocess:'=',
					identity:'=',
				},
				templateUrl: 'app/directives/menu/piece-justificative.html',
				controller:['Inscription2','blockUI', '$timeout','fileReader' ,'fileUpload' ,'FileUploader' ,
					'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope','$state', 'ParseLinks',
					'$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder','$http', 'Principal', '$window', function (Inscription2,blockUI, $timeout,fileReader ,fileUpload ,FileUploader ,
						DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, $state, ParseLinks, 
						$rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder,$http, Principal, $window){
				
				$scope.dateCre=  $filter('date')(new Date(), 'yyyy-MM-dd');
				
				var unsubscribe1 = $rootScope.$on('eventEmitedPiece', function(event, result) {
					$scope.listDocs = result.message;

				});
//				$scope.$on('$destroy', unsubscribe1);
				
				if ($scope.listDocs == undefined || $scope.listDocs == null) {
					if($scope.identity != undefined && $scope.codeprocess != undefined){
					$http.get('api/pieceJointes/'+ $scope.identity+"/"+$scope.codeprocess).then(function(result) {
						$scope.listDocs = result.data;
					});
					}
				} 

				/****************************visualisation piece drom decode64*************************************/
				
			$scope.convertDataURIToBinary =  function (dataURI) {
				var BASE64_MARKER = ';base64,';
				var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
				var base64 = dataURI.substring(base64Index);
				var raw = window.atob(base64);
				var rawLength = raw.length;
				var array = new Uint8Array(new ArrayBuffer(rawLength));

				for(var i = 0; i < rawLength; i++) {
					array[i] = raw.charCodeAt(i);
				}
				return array;
			}
				
				
				$scope.downloadPiece = function(fileCode,fileName) {
					var downloadLink = document.createElement("a");
					document.body.appendChild(downloadLink);
					downloadLink.style = "display: none";
					var data = $scope.convertDataURIToBinary(fileCode);
					var file = new Blob( [ data ], { type : 'application/pdf' });
					var fileURL = (window.URL || window.webkitURL)
					.createObjectURL(file);
					downloadLink.href = fileURL;
					downloadLink.download = fileName;
					downloadLink.click();
				}

				
				
				/****************************visualisation piece from api*************************************/
				$scope.downloadDechargeAPI = function(docId){
					var downloadLink = document.createElement("a");

					document.body.appendChild(downloadLink);
					downloadLink.style = "display: none";
					Inscription2
					.downloadDocument(docId)
					.then(
							function(result) {
								if(angular.equals( { status: result.status }, { status: 500 } )){
									swal(message,"Un problème s'est produit!", "error")
								}
								var fName = result
								.headers('Content-Disposition');
								var file = new Blob(
										[ result.data ],
										{
											type : 'application/pdf'
										});
								var fileURL = (window.URL || window.webkitURL)
								.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
				};
			}]
			};
	    }
	})();

