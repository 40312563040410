(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'cci',
						{
							parent : 'entity',
							url : '/ccis',
							data : {
								pageTitle : 'infochamApp.cci.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/cci/ccis.html',
									controller : 'CciController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('cci');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'cci.detail',
						{
							parent : 'entity',
							url : '/cci/{id}',
							data : {
								pageTitle : 'infochamApp.cci.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/cci/cci-detail.html',
									controller : 'CciDetailController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('cci');
											return $translate.refresh();
										} ],
								entity : [ '$stateParams', 'Cci',
										function($stateParams, Cci) {
											return Cci.get({
												id : $stateParams.id
											});
										} ]
							}
						})
				.state(
						'cci.new',
						{
							parent : 'cci',
							url : '/new',
							data : {},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/cci/cci-dialog.html',
													controller : 'CciDialogController',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																code : null,
																libelle : null,
																telephone : null,
																fax : null,
																siteWeb : null,
																adresse : null,
																courriel : null,
																isSiege : null,
																id : null
															};
														}
													}
												}).result.then(
												function(result) {
													$state.go('cci', null, {
														reload : true
													});
												}, function() {
													$state.go('cci');
												})
									} ]
						})
				.state(
						'cci.edit',
						{
							parent : 'cci',
							url : '/{id}/edit',
							data : {},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/cci/cci-dialog.html',
													controller : 'CciDialogController',
													size : 'lg',
													resolve : {
														entity : [
																'Cci',
																function(Cci) {
																	return Cci
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('cci', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						})

				.state(
						'cci.gouvernorat',
						{
							parent : 'cci',
							url : '/{cci}/gouvernorat',
							data : {
								authorities : [],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/cci/gouvernoratByCci.html',
													controller : 'GouvernoratByCciController',
													size : 'lg',

												}).result.then(
												function(result) {
													$state.go(
															'user-management',
															null, {
																reload : true
															});
												}, function() {
													$state.go('^');
												})
									} ]
						})
				.state(
						'cci.delete',
						{
							parent : 'cci',
							url : '/{id}/delete',
							data : {},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/cci/cci-delete-dialog.html',
													controller : 'CciDeleteController',
													size : 'md',
													resolve : {
														entity : [
																'Cci',
																function(Cci) {
																	return Cci
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('cci', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						});
	}
})();
