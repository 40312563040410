(function() {
	'use strict';

	// fonction permet de faire l'ouverture et la fermuture des sous menu
	(function($) {
		$.fn.smartCollapseToggle = function() {
			return this.each(function() {
				var $body = $('body');
				var $this = $(this);
				// only if not 'menu-on-top'
				if ($body.hasClass('menu-on-top')) {
				} else {
					$body.hasClass('mobile-view-activated')
					// toggle open
					$this.toggleClass('open');
					// for minified menu collapse only second level
					if ($body.hasClass('minified')) {
						if ($this.closest('nav ul ul').length) {
							$this.find('>a .collapse-sign .fa').toggleClass(
									'fa-minus-square-o fa-plus-square-o');
							$this.find('ul:first').slideToggle(200);
						}
					} else {
						// toggle expand item
						$this.find('>a .collapse-sign .fa').toggleClass(
								'fa-minus-square-o fa-plus-square-o');
						$this.find('ul:first').slideToggle(200);
					}
				}
			});
		};
	})(jQuery);

	angular.module('infochamApp').controller('MenuController', MenuController)
			.directive('menuDirective', menuDirective).directive('smartMenu',
					smartMenu).directive('minifyMenu', minifyMenu);

	MenuController.$inject = [ '$scope', '$rootScope', '$state',
			'localStorageService' ];
	menuDirective.$inject = [ '$rootScope' ];
	smartMenu.$inject = [ '$state, $rootScope' ]
	minifyMenu.$inject = []
	function MenuController($scope, $rootScope, $state, localStorageService) {

		// menu referentiel
		var referentiel = "referentiel";
		// menu accueil
		var accueil = "accueil";
		// menu compte
		var compte = "compte";
		// menu administration
		var administration = "administration";

		// fonction qui affiche le menu lors d'un clique

		$scope.showMenuContent = function(itemId) {
			localStorage.setItem('itemId', itemId);

			// sous menu referentiel
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : referentiel
			})) {
				$state.go('accueil');
				$rootScope.compte = "hidden";
				$rootScope.administration = "hidden";
				$rootScope.accueil = "hidden";
				$rootScope.referentiel = "";

			} else {
				$rootScope.referentiel = "hidden";
			}

			// sous menu accueil
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : accueil
			})) {
				$state.go('accueil');
				$rootScope.compte = "hidden";
				$rootScope.administration = "hidden";
				$rootScope.referentiel = "hidden";
				$rootScope.accueil = "";

			} else {
				$rootScope.accueil = "hidden";
			}

			// sous menu compte
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : compte
			})) {
				$state.go('accueil');
				$rootScope.accueil = "hidden";
				$rootScope.administration = "hidden";
				$rootScope.referentiel = "hidden";
				$rootScope.compte = "";

			} else {
				$rootScope.compte = "hidden";
			}

			// sous menu administration
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : administration
			})) {
				$state.go('accueil');
				$rootScope.accueil = "hidden";
				$rootScope.compte = "hidden";
				$rootScope.administration = "";
				$rootScope.referentiel = "hidden";

			} else {
				$rootScope.administration = "hidden";
			}

		}
		// fonction qui donne le menu lors d'un refresh
		$scope.showMenu = function(itemId) {
			localStorage.setItem('itemId', itemId);

			// sous menu referentiel
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : referentiel
			})) {
				$rootScope.compte = "hidden";
				$rootScope.administration = "hidden";
				$rootScope.accueil = "hidden";
				$rootScope.referentiel = "";

			} else {
				$rootScope.referentiel = "hidden";
			}

			// sous menu accueil
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : accueil
			})) {
				$rootScope.compte = "hidden";
				$rootScope.administration = "hidden";
				$rootScope.referentiel = "hidden";
				$rootScope.accueil = "";

			} else {
				$rootScope.accueil = "hidden";
			}

			// sous menu compte
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : compte
			})) {
				$rootScope.accueil = "hidden";
				$rootScope.administration = "hidden";
				$rootScope.referentiel = "hidden";
				$rootScope.compte = "";

			} else {
				$rootScope.compte = "hidden";
			}

			// sous menu administration
			if (angular.equals({
				itemId : itemId
			}, {
				itemId : administration
			})) {
				$rootScope.accueil = "hidden";
				$rootScope.compte = "hidden";
				$rootScope.administration = "";
				$rootScope.referentiel = "hidden";
			} else {
				$rootScope.administration = "hidden";
			}

		}
		// appel de fonction lors du refresh
		$scope.showMenu(localStorage.getItem('itemId'));

	}

	function menuDirective($rootScope) {
		return {
			restrict : "E",
			scope : {
				widgetColor : '@',
				widgetTitle : '@',
				widgetIconClass : '@'
			},
			templateUrl : 'app/directives/menu/menu-directive.html',
			controller : function($scope, $rootScope) {

			}
		};

	}

	function smartMenu($state, $rootScope) {
		return {
			restrict : 'A',
			link : function(scope, element, attrs) {
				var $body = $('body');

				var $collapsible = element.find('li[data-menu-collapse]');

				var bindEvents = function() {
					$collapsible
							.each(function(idx, li) {
								var $li = $(li);
								$li
										.on(
												'click',
												'>a',
												function(e) {

													// collapse all open
													// siblings
													$li
															.siblings('.open')
															.smartCollapseToggle();

													// toggle element
													$li.smartCollapseToggle();

													// add active marker to
													// collapsed element if it
													// has active childs
													if (!$li.hasClass('open')
															&& $li
																	.find('li.active').length > 0) {
														$li.addClass('active')
													}

													e.preventDefault();
												})
										.find('>a')
										.append(
												'<b class="collapse-sign"><em class="fa fa-plus-square-o"></em></b>');

								// initialization toggle
								if ($li.find('li.active').length) {
									$li.smartCollapseToggle();
									$li.find('li.active').parents('li')
											.addClass('active');
								}
							});
				}
				bindEvents();

				// click on route link
				element.on('click', 'a[data-ui-sref]', function(e) {
					// collapse all siblings to element parents and remove
					// active markers
					$(this).parents('li').addClass('active').each(function() {
						$(this).siblings('li.open').smartCollapseToggle();
						$(this).siblings('li').removeClass('active')
					});

					if ($body.hasClass('mobile-view-activated')) {
						$rootScope.$broadcast('requestToggleMenu');
					}
				});

				scope.$on('$smartLayoutMenuOnTop', function(event, menuOnTop) {
					if (menuOnTop) {
						$collapsible.filter('.open').smartCollapseToggle();
					}
				});
			}
		}
	}

	function minifyMenu() {
		return {
			restrict : 'A',
			link : function(scope, element) {
				var $body = $('body');
				var minifyMenu = function() {
					if (!$body.hasClass("menu-on-top")) {
						$body.toggleClass("minified");
						$body.removeClass("hidden-menu");
						$('html').removeClass("hidden-menu-mobile-lock");
					}
				};

				element.on('click', minifyMenu);
			}
		}
	}

})();
