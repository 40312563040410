(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeDestinataireDialogController', OeDestinataireDialogController);

	OeDestinataireDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'OeDestinataire'];

	function OeDestinataireDialogController ($scope, $stateParams, $uibModalInstance, entity, OeDestinataire) {

        $scope.oeDestinataire = entity;
        $scope.load = function(id) {
            OeDestinataire.get({id : id}, function(result) {
                $scope.oeDestinataire = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:oeDestinataireUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.oeDestinataire.id != null) {
                OeDestinataire.update($scope.oeDestinataire, onSaveSuccess, onSaveError);
            } else {
                OeDestinataire.save($scope.oeDestinataire, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}
})();
