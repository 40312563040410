(function() {
'use strict';
angular
	.module('infochamApp')
	.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('inscription', {
        parent: 'entity',
        url: '/inscription',
        data: {
            pageTitle: 'infochamApp.inscription.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/inscription/inscriptions.html',
                controller: 'InscriptionController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('inscription');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })

    .state('AjoutOperateurEconomique', {
        parent: 'entity',
        url: '/inscriptionAdhesion',
        data: {
            pageTitle: 'demoApp.document.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/inscription/inscriptionAdh.html',
                controller: 'InscriptionAdhController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    
    
     .state('inscription-detail', {
        parent: 'home',
        url: 'inscription/{id}',
        data: {
        	  authorities: ['ROLE_ADHESION','ROLE_ADMIN'],		
            pageTitle: 'infochamApp.inscription.home.title'
        },
        params : {
        	idEntity : null
        	
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/inscription/inscription-detail.html',
                controller: 'InscriptionDetailController',
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('agenda');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    
    
      .state('inscription-detail-edit', {
        parent: 'home',
        url: 'inscriptionEdit',
        data: {
        	  authorities: ['ROLE_OPERATEUR_ECONOMIQUE','ROLE_ADMIN'],		
            pageTitle: 'infochamApp.inscription.home.title'
        },  params : {
        	id : null,
        	idEntity : null
        },
        
        views: {
            'content@': {
                templateUrl: 'app/entities/inscription/inscription-detail-edit.html',
                controller: 'InscriptionDetailEditController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('listeReference');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    
   
    
                  .state('note-inscription-infoComplementaire', {
        parent: 'inscription-detail',
        url: '/new/note/inscription/{idProcess}',
        data: {
            pageTitle: 'infochamApp.listeReference.home.title'
        },
        params : {
        	key : null,
        	value : null
        },
        
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/inscription/note/note-inscription-infoComplementaire.html',
                controller: 'NoteInscriptionInfoComplementaireController',
                size: 'md',
                resolve: {
                  entity: function () {
                      return {
                          codeListe: null,
                          designationListe: null,
                          status: null,
                      };
                  }
              }
            }).result.then(function(result) {
                $state.go('inscription-detail', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    
    
                         .state('note-inscription-operateurEconomique', {
        parent: 'inscription-detail-edit',
        url: '/new/note/inscriptionOE/{idProcess}',
        data: {
            pageTitle: 'infochamApp.listeReference.home.title'
        },
        params : {
        	key : null,
        	value : null
        },
        
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/inscription/note/note-inscription-operateurEconomique.html',
                controller: 'NoteInscriptionOperateurEconomiqueController',
                size: 'md',
                resolve: {
                  entity: function () {
                      return {
                          codeListe: null,
                          designationListe: null,
                          status: null,
                      };
                  }
              }
            }).result.then(function(result) {
                $state.go('inscription-detail-edit', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })	
}

})();
