(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('metrics', {
			parent : 'admin',
			url : '/metrics',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'metrics.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/admin/metrics/metrics.html',
					controller : 'MetricsController'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate',
						'$translatePartialLoader',
						function($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('metrics');
							return $translate.refresh();
						} ]
			}
		});
	}
})();
