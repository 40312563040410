(function() {
	'use strict';
	angular.module('infochamApp').config(stateConfig);
	stateConfig.$inject = [ '$stateProvider' ];
	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'agenda-detail',
						{
							parent : 'home',
							url : 'agenda/{id}',
							data : {
								authorities : [ 'PRESIDENT', 'ROLE_ADHESION',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/agenda/agenda-detail.html',
									controller : 'AgendaDetailController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'agenda-paiement',
						{
							parent : 'home',
							url : 'agendapaiement/{id}',
							data : {
								authorities : [ 'PRESIDENT', 'ROLE_ADHESION',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/agenda/agenda-paiement.html',
									controller : 'AgendaPaiementController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'agenda-delivrance',
						{
							parent : 'home',
							url : 'agendadelivrance/{id}',
							data : {
								authorities : [ 'PRESIDENT', 'ROLE_ADHESION',
										'ROLE_ADMIN',
										'ROLE_OPERATEUR_ECONOMIQUE' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/agenda/agenda-delivrance.html',
									controller : 'AgendaDelivranceController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'agenda-paiement-edit',
						{
							parent : 'home',
							url : 'agendapaiementEdit/{id}',
							data : {
								authorities : [ 'PRESIDENT',
										'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/agenda/agenda-paiement-edit.html',
									controller : 'AgendaPaiementEditController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'agenda-paiement-consult',
						{
							parent : 'home',
							url : 'agendapaiementConsult/{id}',
							data : {
								authorities : [ 'PRESIDENT',
										'ROLE_RESPONSABLE_PAIEMENT',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/agenda/agenda-paiement-consult.html',
									controller : 'AgendaPaiementConsultController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'agenda-detail-edit',
						{
							parent : 'home',
							url : 'agendaEdit',
							data : {
								authorities : [ 'PRESIDENT',
										'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								id : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/agenda/agenda-detail-edit.html',
									controller : 'AgendaDetailEditController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('listeReference');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
	}

})();