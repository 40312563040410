(function() {
    'use strict';

    angular
        .module('infochamApp')
        .directive('uploadfactureachatDirective', uploadfactureachatDirective)

    uploadfactureachatDirective.$inject = ['$rootScope'];
    function uploadfactureachatDirective($rootScope) {
        return {
			restrict: "E",
			scope: {
				target: '=',
			},
			templateUrl: 'app/directives/menu/upload-directive-facture-achat.html',
			controller:['fileUpload' ,'FileUploader' ,'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope', '$state', 'ParseLinks',  '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder','$http', 'Principal', function (fileUpload ,FileUploader ,DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, $state, ParseLinks,  $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder,$http, Principal){

				/**file uploader**/
				var uploader = $scope.uploader = new FileUploader({  url: '' });
				$scope.fileList =[];
				$scope.fileNature = {};

				$scope.showUploader= function(){
					if(uploader.queue.length ==0 || $scope.myFile == undefined){
						return "hidden";
					}else {
						return "";
					}
				}

				$scope.showUploaderAlert= function(){
					if(uploader.queue.length >1 && $scope.myFile != undefined){
						return "";
					}else {
						return "hidden";
					}
				}

				$scope.fileAlert= function(){
					if(!($scope.showUploaderAlert()=="hidden")){
						return true;
					}else{
						return false;
					}
				}


				// FILTERS

				// a sync filter
				uploader.filters.push({
					name: 'syncFilter',
					fn: function(item /*{File|FileLikeObject}*/, options) {
						return this.queue.length < 10;
					}
				});

				// an async filter
				uploader.filters.push({
					name: 'asyncFilter',
					fn: function(item /*{File|FileLikeObject}*/, options, deferred) {
						setTimeout(deferred.resolve, 1e3);
					}
				});

				// CALLBACKS
				//test de l'uploader
				uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
//					console.info('onWhenAddingFileFailed', item, filter, options);
				};
				uploader.onAfterAddingFile = function(fileItem) {
					var file = $scope.myFile;
					$scope.$emit('eventEmitedFactureAchat',{message:file});
//					console.info('onAfterAddingFile', fileItem);
					for(var i = 0; i<$scope.uploader.queue.length; i++) {
						var itm = $scope.uploader.queue[i];
					}
				};

				uploader.onAfterAddingAll = function(addedFileItems) {
//					console.info('onAfterAddingAll', addedFileItems);
				};
				uploader.onBeforeUploadItem = function(item) {
//					console.info('onBeforeUploadItem', item);
				};
				uploader.onProgressItem = function(fileItem, progress) {
//					console.info('onProgressItem', fileItem, progress);
				};
				uploader.onProgressAll = function(progress) {
//					console.info('onProgressAll', progress);
				};
				uploader.onSuccessItem = function(fileItem, response, status, headers) {
//					console.info('onSuccessItem', fileItem, response, status, headers);
				};
				uploader.onErrorItem = function(fileItem, response, status, headers) {
//					console.info('onErrorItem', fileItem, response, status, headers);
				};
				uploader.onCancelItem = function(fileItem, response, status, headers) {
//					console.info('onCancelItem', fileItem, response, status, headers);
				};
				uploader.onCompleteItem = function(fileItem, response, status, headers) {
//					console.info('onCompleteItem', fileItem, response, status, headers);
				};
				uploader.onCompleteAll = function() {
//					console.info('onCompleteAll');
				};

				$scope.fileExist=false;

				$('.file').click(function(e) { // détecter l'evenemnt du click de l'ajout du fichier
					$scope.fileExist=true;
					$rootScope.$emit('reload');
				});

				//verifier le type du fichier excel xls ou xlsx
				function fileExtension (fileName){
					if(fileName.indexOf("pdf") !== -1){
						return "pdf";
					}

					if(fileName.indexOf("jpeg") !== -1){
						return "jpeg";
					}
					if(fileName.indexOf("png") !== -1){
						return "png";
					}
				}

				$scope.fileList=[];
				$scope.showUploder=true;
				$scope.uploadFile = function(item,codeNature){
					var file = $scope.myFile;
					$scope.fileNature.file = $scope.myFile;
					$scope.fileNature.type = codeNature;
					
					
					var fileName= file != undefined ? file.name : "";
					$scope.$emit('eventEmitedName',{message:$scope.fileNature});
				};


			}]
		};
    }

    
})();


