(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DemandeAdhesionController', DemandeAdhesionController);

    DemandeAdhesionController.$inject = ['$scope', '$state', 'DemandeAdhesion', 'ParseLinks'];

    function DemandeAdhesionController ($scope, $state, DemandeAdhesion, ParseLinks) {

        $scope.demandeAdhesions = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            DemandeAdhesion.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.demandeAdhesions = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.demandeAdhesion = {
                dateAdhesion: null,
                chiffreAffaire: null,
                nbreEffectif: null,
                capitalSocial: null,
                isSpecimen: null,
                montantPayer: null,
                montantSpecimen: null,
                commentaire: null,
                numCarteAdhesion: null,
                dateCre: null,
                id: null
            };
        };
    }
})();
