(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('traitementDemandeDepotDossierTechniqueController', traitementDemandeDepotDossierTechniqueController);

	traitementDemandeDepotDossierTechniqueController.$inject = ['$http','Agenda','$stateParams','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita'];

	function traitementDemandeDepotDossierTechniqueController ($http,Agenda,$stateParams,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita) {
			$scope.codeStatut=$stateParams.codeStatut;
			$scope.idAgenda = $stateParams.id;
			$scope.idEntity= $stateParams.idEntity;
			$scope.demcompdepotfichetech="DEMCOMPDEPOTFICHETECH"
			$scope.deminfocompconfdoc="DEMINFOCOMPCONFDOC"
			if($scope.idAgenda == null){
				$state.go('home', null, { reload: true } );
			}
			$scope.codePiece= 'DEPDOSSTECH';
			$scope.fileList = "DEPDOSSTECH";
			var vm = this;
			$scope.ctrl = this;
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.depotDoss= {};
			$scope.totalMPE =0;
			$scope.totalMPL = 0;
			Agenda.getDepotDossTechniqueById($scope.idAgenda).then(function (result) {
				$scope.pieceJointeDTOs = result.data.listePieceNumerise;
$scope.agenda = result.data.agenda;

	 $scope.expediteur = $scope.agenda.oeExpediteur;
Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
			$scope.adhesion = result.data 
		});
$scope.depot = result.data.depot;
$scope.produit = result.data.produit;
$scope.matierePremiereEtrangereList=result.data.matierePremiereEtrangereList;
$scope.matierePremiereLocaleList=result.data.matierePremiereLocaleList;
$scope.listePieceNumerise= result.data.listePieceNumerise;
$scope.fraisAchats= $scope.depot.fraisTransp+$scope.depot.fretTransit+$scope.depot.commAchat+$scope.depot.fraisStock;
if($scope.produit.ngpNdp != null){
$scope.produit.ngpNdp = parseFloat($scope.produit.ngpNdp)
}
if($scope.depot.modeDepot != null){
$scope.modeDepot = $scope.depot.modeDepot.typeDepot.codeRubrique;
$scope.dateCommunication  = new Date($scope.depot.modeDepot.dateCommunication);
}
	 $http.get("api/listBouton/"+$scope.agenda.idCase).then(function(result) {
		  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
		  	$scope.listButton=result.data;
		 if($scope.codeStatut=="DEPOTFICHETECHNIQUE" || $scope.codeStatut=="ENVOIEDEPOTFICHETECH"){
		 $scope.listButton1 = {};
			$scope.listButton1["ANNULERDEPOTFICHETECH"]="Annuler";
			$scope.listButton1["DEMCOMPDEPOTFICHETECH"]="Demander des informations complémentaires";
			$scope.listButton1["VALIDERDEPOTFICHETECH"]="Valider";
		  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" ><span class="box-container-direct" note id="test""></span>{{value}}</button>&nbsp;&nbsp;</span>';
			angular.element("#boutonWKDEPOTFICHETECHNIQUE").append( $compile(html)($scope));
		 }
		 else if($scope.codeStatut=="DEMCOMPDEPOTFICHETECH"){
			  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKDEMCOMPDEPOTFICHETECH").append( $compile(html)($scope));
		 }
		 else if($scope.codeStatut=="DEMINFOCOMPCONFDOC"){
			  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-disabled="formCom.$invalid" ng-click="traitementDepotDoc(key,value,modeDepot,dateCommunication)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKDEMINFOCOMPCONFDOC").append( $compile(html)($scope));
		 }
		 else if($scope.codeStatut=="VALIDERDEPOTFICHETECH"){
			  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-disabled="formCom.$invalid" ng-click="traitementDepotDoc(key,value,modeDepot,dateCommunication)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKVALIDERDEPOTFICHETECH").append( $compile(html)($scope));
		 }
		 else if($scope.codeStatut=="DEPOTDOCORIGINAUX" || $scope.codeStatut=="ENVDOCCOMPLEMENT"){
			 $scope.listButton1 = {};
			    $scope.listButton1["ANNULVALIDERCONFORMITEDOC"]="Annuler";
				$scope.listButton1["DEMINFOCOMPCONFDOC"]="Demander des informations complémentaires";
				$scope.listButton1["VALIDERCONFORMITEDOC"]="Valider document";
			  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitementValidDocOriginaux(key,value,depot.commentaire)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKDEPOTDOCORIGINAUX").append( $compile(html)($scope));
		 }
		 
		 else if($scope.codeStatut=="VALIDERCONFORMITEDOC" || $scope.codeStatut=="DELIVDOSSTECH"){
			  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKVALIDERCONFORMITEDOC").append( $compile(html)($scope));
		 }
		 else if($scope.codeStatut=="DEPOTFICHETECHNIQUEHL"){
			 $scope.listButton1 = {};
			 $scope.listButton1["ANNULNUMERISATION"]="Annuler";
			 $scope.listButton1["VALIDNUMERISATION"]="Valider";
			  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKDEPOTFICHETECHNIQUEHL").append( $compile(html)($scope));
		 }
		 else if($scope.codeStatut=="VALIDNUMERISATION"){
			 $scope.listButton1 = {};
				$scope.listButton1["ANNULVALIDATION"]="Annuler";
				$scope.listButton1["ARCHIVERDEPOTDOSSHL"]="Valider";
			  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKVALIDNUMERISATION").append( $compile(html)($scope));
		 }
		 else if($scope.codeStatut=="ATTENDCORRECTION"){
			 	$scope.listButton1 = {};
				$scope.listButton1["VALIDCORRDOSSTECH"]="Valider";
			  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKVALIDERCONFORMITEDOC").append( $compile(html)($scope));
		 } if($scope.codeStatut=="VALIDCORRDOSSTECH"){
			 	$scope.listButton1 = {};
				$scope.listButton1["VALIDCORRDOSSTECH"]="Archiver";
			  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWKVALIDERCONFORMITEDOC").append( $compile(html)($scope));
		 }
		 else {
			  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			  	angular.element("#boutonWK").append( $compile(html)($scope));
		 }
		  })
     	 });
			Agenda.getDepotDossTechniqueById($scope.idAgenda).then(function (result) {
				$scope.oldMatierePremiereEtrangereList=result.data.matierePremiereEtrangereList;
				$scope.oldMatierePremiereLocaleList=result.data.matierePremiereLocaleList;
			});
			  $scope.traitementValidDocOriginaux = function(keyBouton,value,commentaire){
				  if(keyBouton == "ANNULVALIDERCONFORMITEDOC"){
					  swal({
				            title: "Confirmation",
				            text: "Êtes-vous sûr de vouloir annuler la demande dépot dossier technique  ?",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonColor: "#449d44",
				            confirmButtonText: "Valider",
				            closeOnConfirm: true,
				            cancelButtonText: "Annuler",    
				        },
				        function(){
							  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				        });
				  }
				  else if(keyBouton == "DEMINFOCOMPCONFDOC"){
					  $state.go('note-demandeDossTech-infoComplementaire', {idProcess:$scope.idAgenda,key:keyBouton,value:value}, { reload: true } );
				  }
			  else {
				  $scope.depot.produitDTO=$scope.produit;
				  $scope.depot.commentaire=commentaire;
					  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
						  Agenda.updateDepot($scope.depot).then(function (result) {
						  $state.go('home', null, { reload: true } );
						 });
					 
				  });
				  }
				  
				}
								  $scope.traitementDepotDoc = function(keyBouton, value,
							modeDepot, dateCommunication) {
						if (keyBouton == "ANNULDEPOTDOCORIGINAUX") {
							swal(
									{
										title : "Confirmation",
										text : "Êtes-vous sûr de vouloir annuler la demande dépot dossier technique  ?",
										type : "warning",
										showCancelButton : true,
										confirmButtonColor : "#449d44",
										confirmButtonText : "Valider",
										closeOnConfirm : true,
										cancelButtonText : "Annuler",
									}, function() {
										Inscription2.updateProcInscription(
												$scope.idAgenda, keyBouton,
												value).then(function(result) {
											$state.go('home', null, {
												reload : true
											});
										});
									});
						} else {
							$scope.depot.produitDTO = $scope.produit;
							if ($scope.depot.modeDepot == null) {
								$scope.depot.modeDepot = {};
							}
							$scope.depot.modeDepot.dateCommunication = dateCommunication;
							Inscription2.updateProcInscription($scope.idAgenda,
									keyBouton, value).then(
									function(result) {
										Agenda.setModeDepot(modeDepot,
												$scope.depot).then(
												function(result) {
													$state.go('home', null,
															{
																reload : true
															});
												});
									});
						}
					}
								  /*get file dec tresor from alfreco*/
									$scope.downloadDecharge = function(docId) {
									var downloadLink = document.createElement("a");
									document.body.appendChild(downloadLink);
									downloadLink.style = "display: none";
									Inscription2
									.downloadDocument(docId)
									.then(
									function(result) {
									   	if(angular.equals( { status: result.status }, { status: 500 } )){
									swal(message,"Un problème s'est produit!", "error")
									}
									var fName = result
									.headers('Content-Disposition');
									var file = new Blob(
									[ result.data ],
									{
									type : 'application/pdf'
									});
									var fileURL = (window.URL || window.webkitURL)
									.createObjectURL(file);
									downloadLink.href = fileURL;
									downloadLink.download = fName;
									downloadLink.click();
									});
									}
			  $scope.traitement = function(keyBouton,value){
				  if($scope.codeStatut=="DEPOTFICHETECHNIQUE" || $scope.codeStatut=="ENVOIEDEPOTFICHETECH"){
				  if(keyBouton == "ANNULERDEPOTFICHETECH"){
					  swal({
				            title: "Confirmation",
				            text: "Êtes-vous sûr de vouloir annuler la demande dépot dossier technique  ?",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonColor: "#449d44",
				            confirmButtonText: "Valider",
				            closeOnConfirm: true,
				            cancelButtonText: "Annuler",    
				        },
				        function(){
							  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				        });
				  }
			  else if(keyBouton == "DEMCOMPDEPOTFICHETECH"){
						  $state.go('note-demandeDossTech-infoComplementaire', {idProcess:$scope.idAgenda,key:keyBouton,value:value}, { reload: true } );
					  }
					  else{
						  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
					  $scope.depotDoss.depot = $scope.depot;
						$scope.depotDoss.produit=$scope.produit;
						$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
						$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
						Agenda.setPourcentageMatierePremiere($scope.depotDoss).then(function(result){
						 $state.go('home', null, { reload: true } );
						});
						  });
					  }
				}
				  if($scope.codeStatut=="DEMCOMPDEPOTFICHETECH"){
					  
					  $scope.depotDoss.depot = $scope.depot;
						$scope.depotDoss.produit=$scope.produit;
						$scope.depotDoss.depot.totalMP = $scope.depot.totalMP;
						$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
						$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
						  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
							  
							  Agenda.updateDemandeDepotDossTechnique($scope.depotDoss).then(function (result) {
							  $state.go('home', null, { reload: true } );
							 });
						 
					  });
				  }
				  if($scope.codeStatut=="VALIDERCONFORMITEDOC" || $scope.codeStatut=="DELIVDOSSTECH" || $scope.codeStatut=="ATTENDCORRECTION" || $scope.codeStatut=="VALIDCORRDOSSTECH" ){
				  if(keyBouton == "ANNULVALIDATIONFICHEPRD"){
					  swal({
				            title: "Confirmation",
				            text: "Êtes-vous sûr de vouloir annuler la validation fiche produit  ?",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonColor: "#449d44",
				            confirmButtonText: "Valider",
				            closeOnConfirm: true,
				            cancelButtonText: "Annuler",    
				        },
				        function(){
							  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				        });
				  }
				  else {
				  $scope.depotDoss.depot = $scope.depot;
					$scope.depotDoss.produit=$scope.produit;
					$scope.depotDoss.depot.totalMP = $scope.depot.totalMP;
					$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
					$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
					  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
						  
						  Agenda.updateDemandeDepotDossTechnique($scope.depotDoss).then(function (result) {
						  $state.go('home', null, { reload: true } );
						 });
					 
				  });
				  }
			  }
				  if($scope.codeStatut=="DEPOTFICHETECHNIQUEHL"){
				  if(keyBouton == "ANNULNUMERISATION"){
					  swal({
				            title: "Confirmation",
				            text: "Êtes-vous sûr de vouloir annuler la demande dépot dossier technique  ?",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonColor: "#449d44",
				            confirmButtonText: "Valider",
				            closeOnConfirm: true,
				            cancelButtonText: "Annuler",    
				        },
				        function(){
							  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				        });
				  }
				  else{
					  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
						  Agenda.savePieces($scope.pieces,$scope.idAgenda).then(function (result) {
						  $state.go('home', null, { reload: true } );
						 });
				  });
			  }
				}
				  if($scope.codeStatut=="VALIDNUMERISATION"){
				  if(keyBouton == "ANNULVALIDATION"){
					  swal({
				            title: "Confirmation",
				            text: "Êtes-vous sûr de vouloir annuler la demande dépot dossier technique  ?",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonColor: "#449d44",
				            confirmButtonText: "Valider",
				            closeOnConfirm: true,
				            cancelButtonText: "Annuler",    
				        },
				        function(){
							  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				        });
				  }
				  else{
					  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
						  $state.go('home', null, { reload: true } );
				  });
			  }
			  }
			  }
			vm.validationStep = function(item, actions) {
				$scope.errors =[];
				
				if($scope.errors.length> 0){
					actions.error($scope.errors);
					$("html, body").animate({scrollTop: 400},"slow");
				}
				else {
					actions.success();
				}
			}
			 $scope.gettotalMainDoeuvre = function(fraisPersDir,fraisPersIndir) {
				 var fraisPersDir1 = 0;
				 var fraisPersIndir1=0;
				 if(fraisPersDir != undefined){
					 fraisPersDir1=fraisPersDir;
				 }
				 if(fraisPersIndir != undefined){
					 fraisPersIndir1=fraisPersIndir;
				 }
				 $scope.depot.totalMo=parseFloat(fraisPersDir1)+parseFloat(fraisPersIndir1);
				 $scope.depot.totalProd = parseFloat($scope.depot.totalMP)+parseFloat($scope.depot.totalMo) +parseFloat($scope.depot.totalAutreFrais);
				 $scope.produit.tauxIntegration = (($scope.depot.totalProd -$scope.totalMPE)/$scope.depot.totalProd)*100

			 }
 $scope.gettotalAutresFrais = function(fraisTrans,fretTransit,commissionAchat,fraisStockage,sousTraitGen,entretienReparation,matiereNonStock,matiereConsommableEnerg,etudeRechDiversServExt,chargesFinancieres,dotAmortissementMateriel) {
				 
				 var fraisTrans1 = 0;
				 var fretTransit1 = 0;
				 var commissionAchat1 = 0;
				 var fraisStockage1 = 0;
				 var sousTraitGen1 = 0;
				 var entretienReparation1=0;
				 var matiereNonStock1=0;
				 var matiereConsommableEnerg1=0;
				 var etudeRechDiversServExt1=0;
				 var chargesFinancieres1=0;
				 var dotAmortissementMateriel1=0;
				 if(fraisTrans != undefined){
					 fraisTrans1=fraisTrans;
				 }
				 if(fretTransit != undefined){
					 fretTransit1=fretTransit;
				 }
				 if(commissionAchat != undefined){
					 commissionAchat1=commissionAchat;
				 }
				 if(fraisStockage != undefined){
					 fraisStockage1=fraisStockage;
				 }
				 if(sousTraitGen != undefined){
					 sousTraitGen1=sousTraitGen;
				 }
				 if(entretienReparation != undefined){
					 entretienReparation1=entretienReparation;
				 }
				 if(matiereNonStock != undefined){
					 matiereNonStock1=matiereNonStock;
				 }
				 if(matiereConsommableEnerg != undefined){
					 matiereConsommableEnerg1=matiereConsommableEnerg;
				 }
				 if(etudeRechDiversServExt != undefined){
					 etudeRechDiversServExt1=etudeRechDiversServExt;
				 }
				 if(chargesFinancieres != undefined){
					 chargesFinancieres1=chargesFinancieres;
				 }
				 if(dotAmortissementMateriel != undefined){
					 dotAmortissementMateriel1=dotAmortissementMateriel;
				 }
				 $scope.depot.totalAutreFrais=parseFloat(fraisTrans1)+parseFloat(fretTransit1)+parseFloat(commissionAchat1)+parseFloat(fraisStockage1)+parseFloat(sousTraitGen1)+parseFloat(entretienReparation1)+parseFloat(matiereNonStock1)+parseFloat(matiereConsommableEnerg1)+parseFloat(etudeRechDiversServExt1)+parseFloat(chargesFinancieres1)+parseFloat(dotAmortissementMateriel1);
				 $scope.depot.totalProd = parseFloat($scope.depot.totalMP)+parseFloat($scope.depot.totalMo) +parseFloat($scope.depot.totalAutreFrais);
				 $scope.produit.tauxIntegration = ((parseFloat($scope.depot.totalProd) -parseFloat($scope.totalMPE))/parseFloat($scope.depot.totalProd))*100
			 }
			vm.completeWizard = function(scope, actions) {
				$scope.initiation();
			}
			function formErrors(form){
				var errors = [];
				for(var key in form.$error){
					errors.push(key + "=" + form.$error);
				}
				if(errors.length > 0){
					for(var err in form.$error.required){
					}
				}
			}
			$scope.test = function(obj) {
				formErrors(obj);
			}
			$scope.editingData = {};
		    $scope.oldLibelle = {};
		    $scope.matierePremiereEtrangere={};
		    $scope.matierePremiereLocale={};
			  $scope.modifyPourcentageMPE = function(tableData){
			if(tableData!=undefined){
	    	for (var i = 0, length = $scope.matierePremiereEtrangereList.length; i < length; i++) {
	    	    $scope.editingData[$scope.matierePremiereEtrangereList[i].id] = false;
	    	  }
	    	$scope.editingData[$scope.matierePremiereEtrangereList[tableData].id] = true;
	    	 $scope.matierePremiereEtrangere.pourcentage=$scope.matierePremiereEtrangereList[tableData].pourcentage;
			}
	    };
	    $scope.updatePourcentageMPE = function(tableData){
	    	if(tableData.id!=undefined){
	    	  $scope.editingData[tableData.id] = false;
	    	     for (var i = 0, length = $scope.matierePremiereEtrangereList.length; i < length; i++) {
	              $scope.oldLibelle[$scope.matierePremiereEtrangereList[i].id] = $scope.matierePremiereEtrangereList[i].pourcentage;
	            }
	    	}
	    };
	    $scope.cancelPourcentageMPE= function(tableData){
	    	if(tableData!=undefined){
	    	$scope.matierePremiereEtrangereList[tableData].pourcentage=$scope.oldMatierePremiereEtrangereList[tableData].pourcentage;
	        $scope.editingData[$scope.matierePremiereEtrangereList[tableData].id] = false;
	    	}
	    }
		  $scope.modifyPourcentageMPL = function(tableData){
				if(tableData!=undefined){
		    	for (var i = 0, length = $scope.matierePremiereLocaleList.length; i < length; i++) {
		    	    $scope.editingData[$scope.matierePremiereLocaleList[i].id] = false;
		    	  }
		    	$scope.editingData[$scope.matierePremiereLocaleList[tableData].id] = true;
		    	 $scope.matierePremiereLocale.pourcentage=$scope.matierePremiereLocaleList[tableData].pourcentage;
				}
		    };
		    $scope.updatePourcentageMPL = function(tableData){
		    	if(tableData.id!=undefined){
		    	  $scope.editingData[tableData.id] = false;
		    	     for (var i = 0, length = $scope.matierePremiereLocaleList.length; i < length; i++) {
		              $scope.oldLibelle[$scope.matierePremiereLocaleList[i].id] = $scope.matierePremiereLocaleList[i].pourcentage;
		            }
		    	}
		    };
		    $scope.cancelPourcentageMPL= function(tableData){
		    	if(tableData!=undefined){
		    	$scope.matierePremiereLocaleList[tableData].pourcentage=$scope.oldMatierePremiereLocaleList[tableData].pourcentage;
		        $scope.editingData[$scope.matierePremiereLocaleList[tableData].id] = false;
		    	}
		    }
			$scope.dtOptionsMPE = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		.withOption('order',[[7, 'asc']])
	.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
			.withOption('fnFooterCallback',
					function(nRow, aasData, iStart, iEnd, aiDisplay) {
				var columnas = [0]; //the columns you wish to add
				var secondRow = $(nRow).next()[0];
				for (var j in columnas) {
					var columnaActual = columnas[j];
					var total = 0;
					var devise = " ";
					for (var i = 0; i < aasData.length; i++) {
						total = total + parseFloat($scope.matierePremiereEtrangereList[i].pht);
						$scope.totalMPE = total;
						$scope.depot.totalMP = $scope.totalMPE + $scope.totalMPL;
						$scope.depot.totalProd = parseFloat($scope.depot.totalMP)+parseFloat($scope.depot.totalMo) +parseFloat($scope.depot.totalAutreFrais);
						$scope.produit.tauxIntegration = ((parseFloat($scope.depot.totalProd) -parseFloat($scope.totalMPE))/parseFloat($scope.depot.totalProd))*100
					}
					$($(nRow).children().get(columnaActual)).html("Total TA1 ="+total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")+" TND");
					} // end
			})
			$scope.dtColumnsMPE = [
DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '30%'),
DTColumnDefBuilder.newColumnDef(1).withTitle("NGP").withOption('width', '15%'),
DTColumnDefBuilder.newColumnDef(2).withTitle("P.H.T").withOption('width', '15%').notSortable(),
DTColumnDefBuilder.newColumnDef(3).withTitle("Fournisseur").withOption('width', '10%'),
DTColumnDefBuilder.newColumnDef(4).withTitle("Facture").withOption('width', '15%'),
DTColumnDefBuilder.newColumnDef(5).withTitle("%").withOption('width', '5%'),
DTColumnDefBuilder.newColumnDef(6).withTitle("Actions").withOption('width', '10%'),
DTColumnDefBuilder.newColumnDef(7).withTitle("Automatique").withOption('width', '10%').notVisible()
				];
			$scope.dtOptionsMPL = DTOptionsBuilder
			.newOptions()
			// or here
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
			.withOption('order',[[7, 'asc']])
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
				.withOption('fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {
					var columnas = [0]; //the columns you wish to add
					var secondRow = $(nRow).next()[0];
					for (var j in columnas) {
						var columnaActual = columnas[j];
						var total = 0;
						var devise = " ";

						for (var i = 0; i < aasData.length; i++) {
							total = total + parseFloat($scope.matierePremiereLocaleList[i].pht);
							$scope.totalMPL = total;
							$scope.depot.totalMP= $scope.totalMPE + $scope.totalMPL;
							$scope.depot.totalProd = parseFloat($scope.depot.totalMP)+parseFloat($scope.depot.totalMo) +parseFloat($scope.depot.totalAutreFrais);
							$scope.produit.tauxIntegration = (($scope.depot.totalProd -parseFloat($scope.totalMPE))/$scope.depot.totalProd)*100

						}
						$($(nRow).children().get(columnaActual)).html("Total TA2 ="+total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")+" TND");
						} // end
				})
				$scope.dtColumnsMPL = [
	DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '30%'),
	DTColumnDefBuilder.newColumnDef(1).withTitle("NGP").withOption('width', '15%'),
	DTColumnDefBuilder.newColumnDef(2).withTitle("P.H.T").withOption('width', '15%').notSortable(),
	DTColumnDefBuilder.newColumnDef(3).withTitle("Fournisseur").withOption('width', '10%'),
	DTColumnDefBuilder.newColumnDef(4).withTitle("Facture").withOption('width', '15%'),
	DTColumnDefBuilder.newColumnDef(5).withTitle("%").withOption('width', '5%'),
	DTColumnDefBuilder.newColumnDef(6).withTitle("Actions").withOption('width', '10%'),
	DTColumnDefBuilder.newColumnDef(7).withTitle("Automatique").withOption('width', '10%').notVisible()
					];
			  $rootScope.$on('eventEmitedMatierePremiereEtrangere', function(event, data) {
				  $scope.matierePremiereEtrangere = data.message;
				  $scope.matierePremiereEtrangereList.push($scope.matierePremiereEtrangere);
			  });
			  $rootScope.$on('eventEmitedMatierePremiereLocale', function(event, data) {
				  $scope.matierePremiereLocale = data.message;
				  $scope.matierePremiereLocaleList.push($scope.matierePremiereLocale);
			  });
			
				$scope.downloadPiecee = function(docId) {
                    if(docId != undefined){
					var downloadLink = document.createElement("a");

					document.body.appendChild(downloadLink);
					downloadLink.style = "display: none";
					Inscription2
					.downloadDocument(docId)
					.then(
					function(result) {
					   	if(angular.equals( { status: result.status }, { status: 500 } )){
					swal(message,"Un problème s'est produit!", "error")
					}
					var fName = result
					.headers('Content-Disposition');
					var file = new Blob(
					[ result.data ],
					{
					type : 'application/pdf'
					});
					var fileURL = (window.URL || window.webkitURL)
					.createObjectURL(file);
					downloadLink.href = fileURL;
					downloadLink.download = fName;
					downloadLink.click();
					});
                    }
					}  
				  $scope.generateFicheProduit = function(depot,produit,totalMainDoeuvre,totalAutresFrais,coutTotalProd){
					  $scope.depotDoss.depot = depot;
					$scope.depotDoss.expediteur = $scope.expediteur;
						$scope.depotDoss.produit=produit;
//						$scope.depotDoss.depot.totalMo=totalMainDoeuvre;
//						$scope.depotDoss.depot.totalProd=coutTotalProd;
//						$scope.depotDoss.depot.totalAutreFrais=totalAutresFrais
//						$scope.depotDoss.depot.totalMP=$scope.totalMP;
						$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
						$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
					  ReportingService.generateFicheProduit($scope.depotDoss).then(function (result) {
						  var fileName="Fiche Technique"
						  ReportingService.downloadPdfFile(result, fileName)
				 });
					}
				$scope.nombreAvecVirgule = function(aa)
				{
					var a =parseFloat(aa)
					return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
				}
				
				
				  $scope.modifyPourcentageMPEValidation = function(tableData){
						if(tableData!=undefined){
				    	for (var i = 0, length = $scope.matierePremiereEtrangereList.length; i < length; i++) {
				    	    $scope.editingData[$scope.matierePremiereEtrangereList[i].id] = false;
				    	  }
				    	$scope.editingData[$scope.matierePremiereEtrangereList[tableData].id] = true;
				    	 $scope.matierePremiereEtrangere.pourcentage=$scope.matierePremiereEtrangereList[tableData].pourcentage;
						}
				    };
				    $scope.updatePourcentageMPEValidation = function(tableData){
				    	if(tableData.id!=undefined){
				    	  $scope.editingData[tableData.id] = false;
				    	     for (var i = 0, length = $scope.matierePremiereEtrangereList.length; i < length; i++) {
				              $scope.oldLibelle[$scope.matierePremiereEtrangereList[i].id] = $scope.matierePremiereEtrangereList[i].pourcentage;
				            }
				    	}
				    };
				    $scope.cancelPourcentageMPEValidation= function(tableData){
				    	if(tableData!=undefined){
				    	$scope.matierePremiereEtrangereList[tableData].pourcentage=$scope.oldMatierePremiereEtrangereList[tableData].pourcentage;
				        $scope.editingData[$scope.matierePremiereEtrangereList[tableData].id] = false;
				    	}
				    }
					  $scope.modifyPourcentageMPLValidation = function(tableData){
							if(tableData!=undefined){
					    	for (var i = 0, length = $scope.matierePremiereLocaleList.length; i < length; i++) {
					    	    $scope.editingData[$scope.matierePremiereLocaleList[i].id] = false;
					    	  }
					    	$scope.editingData[$scope.matierePremiereLocaleList[tableData].id] = true;
					    	 $scope.matierePremiereLocale.pourcentage=$scope.matierePremiereLocaleList[tableData].pourcentage;
							}
					    };
					    $scope.updatePourcentageMPLValidation = function(tableData){
					    	if(tableData.id!=undefined){
					    	
					    	     $rootScope.$broadcast('eventEmitedUpdatePourcentageMatierePremiereLocaleList',{tableData:tableData});
					    	}
					    };
					    $scope.cancelPourcentageMPLValidation= function(tableData){
					    	if(tableData!=undefined){
					    	$scope.matierePremiereLocaleList[tableData].pourcentage=$scope.oldMatierePremiereLocaleList[tableData].pourcentage;
					        $scope.editingData[$scope.matierePremiereLocaleList[tableData].id] = false;
					    	}
					    }
	
						$scope.$on('eventEmitedUpdatePourcentageMatierePremiereEtrangereList', function(event, data) {
							$scope.matierePremiereLocaleUpdate = data.message;
							$scope.index = data.index;
							$scope.matierePremiereEtrangereList[$scope.index] = $scope.matierePremiereLocaleUpdate;
//							if($scope.codeStatut == "VALIDERCONFORMITEDOC"){
							if($scope.matierePremiereLocaleUpdate.pourcentage == 100){
								 var exist = false;
								 $scope.indice ;
								 $scope.aaDelet={};
								  for (var i = 0, length = $scope.matierePremiereLocaleList.length; i < length; i++) {
							             $scope.matierePrEtrangere = $scope.matierePremiereLocaleList[i];
							             if($scope.matierePrEtrangere.designation==$scope.matierePremiereLocaleUpdate.designation && $scope.matierePrEtrangere.fournisseurs==$scope.matierePremiereLocaleUpdate.fournisseurs){
							            	 var sommePht = $scope.matierePremiereLocaleUpdate.pht + $scope.matierePrEtrangere.pht;
							            	 $scope.matierePremiereLocaleUpdate.pht =sommePht;
							            	 exist = true;
			                                 $scope.aaDelet =$scope.matierePrEtrangere;
			                                 $scope.indice =i;
			                                 break;
							             }
							            }
						             $scope.matierePremiereLocaleList.splice(i, 1);
							}
							  if($scope.matierePremiereLocaleUpdate.pourcentage != 100){
								  var exist = false;
								
								 
								  for (var i = 0, length = $scope.matierePremiereLocaleList.length; i < length; i++) {
						             $scope.matierePrEtrangere = $scope.matierePremiereLocaleList[i];
						             if($scope.matierePrEtrangere.designation==$scope.matierePremiereLocaleUpdate.designation && $scope.matierePrEtrangere.fournisseurs==$scope.matierePremiereLocaleUpdate.fournisseurs){
						            	 $scope.matierePrEtrangere.pourcentage = 100 -$scope.matierePremiereLocaleUpdate.pourcentage
						            	 var sommePht = $scope.matierePremiereLocaleUpdate.pht + $scope.matierePrEtrangere.pht;
						            	 $scope.matierePremiereLocaleUpdate.pht = ($scope.matierePremiereLocaleUpdate.pourcentage * sommePht)/100
						            	 $scope.matierePrEtrangere.pht = ($scope.matierePrEtrangere.pourcentage * sommePht)/100
						          
						             }
						          
						            }
								  if(!exist){
						            	   var pht = $scope.matierePremiereLocaleUpdate.pht;
								  $scope.matierePremiereetrangereNew = {};
								  $scope.matierePremiereetrangereNew.designation =$scope.matierePremiereLocaleUpdate.designation;
								  $scope.matierePremiereetrangereNew.fournisseurs =$scope.matierePremiereLocaleUpdate.fournisseurs;
								  $scope.matierePremiereetrangereNew.factureAchat =$scope.matierePremiereLocaleUpdate.factureAchat;
								  $scope.matierePremiereetrangereNew.ngp =$scope.matierePremiereLocaleUpdate.ngp;
								  $scope.matierePremiereetrangereNew.depotDTO =$scope.matierePremiereLocaleUpdate.depotDTO;
								  $scope.matierePremiereetrangereNew.isAutomatique =true;
								  $scope.matierePremiereetrangereNew.type = "L";
								  $scope.matierePremiereetrangereNew.pourcentage = 100 -$scope.matierePremiereLocaleUpdate.pourcentage;
								  $scope.matierePremiereLocaleUpdate.pht = (pht * $scope.matierePremiereLocaleUpdate.pourcentage)/100;
								  $scope.matierePremiereEtrangereList[$scope.index] = $scope.matierePremiereLocaleUpdate;
								  $scope.matierePremiereetrangereNew.pht = (pht * $scope.matierePremiereetrangereNew.pourcentage)/100;
						            	 $scope.matierePremiereLocaleList.push($scope.matierePremiereetrangereNew);
						             }
							  }
//							}
									  });
					    
				$scope.$on('eventEmitedUpdatePourcentageMatierePremiereLocaleList', function(event, data) {
					$scope.matierePremiereLocaleUpdate = data.message;
					$scope.index = data.index;
					$scope.matierePremiereLocaleList[$scope.index] = $scope.matierePremiereLocaleUpdate;
//					if($scope.codeStatut == "VALIDERCONFORMITEDOC"){
					if($scope.matierePremiereLocaleUpdate.pourcentage == 100){
						 var exist = false;
						 $scope.indice ;
						 $scope.aaDelet={};
						  for (var i = 0, length = $scope.matierePremiereEtrangereList.length; i < length; i++) {
					             $scope.matierePrEtrangere = $scope.matierePremiereEtrangereList[i];
					             if($scope.matierePrEtrangere.designation==$scope.matierePremiereLocaleUpdate.designation && $scope.matierePrEtrangere.fournisseurs==$scope.matierePremiereLocaleUpdate.fournisseurs){
					            	 var sommePht = $scope.matierePremiereLocaleUpdate.pht + $scope.matierePrEtrangere.pht;
					            	 $scope.matierePremiereLocaleUpdate.pht =sommePht;
					            	 exist = true;
	                                 $scope.aaDelet =$scope.matierePrEtrangere;
	                                 $scope.indice =i;
	                                 break;
					             }
					            }
				             $scope.matierePremiereEtrangereList.splice(i, 1);
					}
					  if($scope.matierePremiereLocaleUpdate.pourcentage != 100){
						  var exist = false;
						
						 
						  for (var i = 0, length = $scope.matierePremiereEtrangereList.length; i < length; i++) {
				             $scope.matierePrEtrangere = $scope.matierePremiereEtrangereList[i];
				             if($scope.matierePrEtrangere.designation==$scope.matierePremiereLocaleUpdate.designation && $scope.matierePrEtrangere.fournisseurs==$scope.matierePremiereLocaleUpdate.fournisseurs){
				            	 $scope.matierePrEtrangere.pourcentage = 100 -$scope.matierePremiereLocaleUpdate.pourcentage
				            	 var sommePht = $scope.matierePremiereLocaleUpdate.pht + $scope.matierePrEtrangere.pht;
				            	 $scope.matierePremiereLocaleUpdate.pht = ($scope.matierePremiereLocaleUpdate.pourcentage * sommePht)/100
				            	 $scope.matierePrEtrangere.pht = ($scope.matierePrEtrangere.pourcentage * sommePht)/100

				            	 exist = true;
				          
				             }
				            }
						  if(!exist){
				            	   var pht = $scope.matierePremiereLocaleUpdate.pht;
						  $scope.matierePremiereetrangereNew = {};
						  $scope.matierePremiereetrangereNew.designation =$scope.matierePremiereLocaleUpdate.designation;
						  $scope.matierePremiereetrangereNew.fournisseurs =$scope.matierePremiereLocaleUpdate.fournisseurs;
						  $scope.matierePremiereetrangereNew.factureAchat =$scope.matierePremiereLocaleUpdate.factureAchat;
						  $scope.matierePremiereetrangereNew.ngp =$scope.matierePremiereLocaleUpdate.ngp;
						  $scope.matierePremiereetrangereNew.isAutomatique =true;
						  $scope.matierePremiereetrangereNew.depotDTO =$scope.matierePremiereLocaleUpdate.depotDTO;
						  $scope.matierePremiereetrangereNew.type = "E";
						  $scope.matierePremiereetrangereNew.pourcentage = 100 -$scope.matierePremiereLocaleUpdate.pourcentage;
						  $scope.matierePremiereLocaleUpdate.pht = (pht * $scope.matierePremiereLocaleUpdate.pourcentage)/100;
						  $scope.matierePremiereLocaleList[$scope.index] = $scope.matierePremiereLocaleUpdate;
						  $scope.matierePremiereetrangereNew.pht = (pht * $scope.matierePremiereetrangereNew.pourcentage)/100;
				            	 $scope.matierePremiereEtrangereList.push($scope.matierePremiereetrangereNew);
				             }
					  }
//					}
							  });
							$scope.updateMatierePremLocale = function(index) {
								 if($scope.codeStatut=="DEPOTFICHETECHNIQUE" || $scope.codeStatut=="ENVOIEDEPOTFICHETECH" || $scope.codeStatut=="VALIDERDEPOTFICHETECH" ){
								  if (index != undefined) {
									  $scope.matierePremiereLocaleUpdate = $scope.matierePremiereLocaleList[index];
									  $state.go( 'matierePremiereLocaleUpdatePourcentage', {matierePremiere :$scope.matierePremiereLocaleUpdate, index : index });
								  }
								 }
								 if($scope.codeStatut=="DEMCOMPDEPOTFICHETECH"){
									 if (index != undefined) {
										  $scope.matierePremiereLocaleUpdate = $scope.matierePremiereLocaleList[index];
										  $state.go('matierePremiereLocaleInfoComplementaire', {matierePremiere :$scope.matierePremiereLocaleUpdate, index : index });
									  }
								 }
								}
							$scope.$on('eventEmitedInfoCOmplementaireMatierePremiereLocaleList', function(event, data) {
								$scope.matierePremiereLocaleUpdate = data.message;
								$scope.index = data.index;
								$scope.matierePremiereLocaleList[$scope.index] = $scope.matierePremiereLocaleUpdate;
										  });
							$scope.$on('eventEmitedInfoComplementaireMatierePremiereEtrangereList', function(event, data) {
								$scope.matierePremiereLocaleUpdate = data.message;
								$scope.index = data.index;
								$scope.matierePremiereEtrangereList[$scope.index] = $scope.matierePremiereLocaleUpdate;
										  });
							$scope.$on('eventEmitedUpdatePourcentageMatierePremiereEtrangereList', function(event, data) {
								$scope.matierePremiereLocaleUpdate = data.message;
								$scope.index = data.index;
								$scope.matierePremiereEtrangereList[$scope.index] = $scope.matierePremiereLocaleUpdate;
										  });
										$scope.updateMatierePremEtrangere = function(index) {
											 if($scope.codeStatut=="DEPOTFICHETECHNIQUE" || $scope.codeStatut=="ENVOIEDEPOTFICHETECH" || $scope.codeStatut=="VALIDERDEPOTFICHETECH"){
											  if (index != undefined) {
												  $scope.matierePremiereEtrangereUpdate = $scope.matierePremiereEtrangereList[index];
												  $state.go( 'matierePremiereEtrangereUpdatePourcentage', {matierePremiere :$scope.matierePremiereEtrangereUpdate, index : index });
											  }
											 }
											 if($scope.codeStatut=="DEMCOMPDEPOTFICHETECH"){
												 if (index != undefined) {
													  $scope.matierePremiereEtrangereUpdate = $scope.matierePremiereEtrangereList[index];
													  $state.go( 'matierePremiereEtrangereInfoComplementaire', {matierePremiere :$scope.matierePremiereEtrangereUpdate, index : index });
												  }
											 }
											}
			
						
									  function createdRow(row, data, dataIndex) {
										  $compile(angular.element(row).contents())($scope);
										  }
										$scope.lovModelSecteur = 'api/referentiels/unites';// this will be
										// the LOV
										// datasource
										$scope.lovTitleSecteur = 'Liste des unités';
										$scope.lovColumnListSecteur = [  'Libellé' ];
										$scope.lovFieldListSecteur = [  'libelle' ];
										// function to be called when you click "choose" button
										$scope.lovCallBackSecteur = function(result) {
											$scope.produit.uniteDTO = result;
											$scope.$emit('eventSousSecteur',result)
										}
										var unsubscribe1 = $scope.$on('eventEmitedPiece', function(event, result) {
											$scope.pieces = result.message;
										});
										$scope.$on('$destroy', unsubscribe1);
										$scope.downloadPieceAlfresco = function(docId) {
											var downloadLink = document.createElement("a");
											document.body.appendChild(downloadLink);
											downloadLink.style = "display: none";
											Inscription2.downloadDocument(docId).then(function(result) {
												if(angular.equals( { status: result.status }, { status: 500 } )){
													swal(message,"Un problème s'est produit!", "error")
												}
												var fName = result
												.headers('Content-Disposition');
												var file = new Blob([ result.data ],{type : 'application/pdf'});
												var fileURL = (window.URL || window.webkitURL)
												.createObjectURL(file);
												downloadLink.href = fileURL;
												downloadLink.download = fName;
												downloadLink.click();
											});
										}
										
										
		}
})();
