(function() {
	'use strict';

	angular.module('infochamApp').controller('AffectationRoleDialogController',
			AffectationRoleDialogController);

	AffectationRoleDialogController.$inject = [ 'authorities', '$http',
			'$filter', '$translate', '$state', '$scope', '$stateParams',
			'entity' ];

	function AffectationRoleDialogController(authorities, $http, $filter,
			$translate, $state, $scope, $stateParams, entity) {
		var actors = [];
		var roles = [];
		var actorMember = entity;
		var id = $stateParams.id;
		if (id == null) {
			// Bonita ACTORS
			$http.get('api/actorMember/actor').then(function(response) {
				$scope.actors = response.data;
			}, function(response) {
				swal({
					timer : 2000,
					title : "Impossible!",
					text : "Oops ... Quelque chose s'est mal passé!",
					showConfirmButton : false,
					type : "error"
				})
			});
			// Bonita Roles
			setTimeout(function() {
				$http.get('api/actorMember/roles').then(function(response) {
					$scope.roles = response.data;
				}, function(response) {
					swal({
						timer : 2000,
						title : "Impossible!",
						text : "Oops ... Quelque chose s'est mal passé!",
						showConfirmButton : false,
						type : "error"
					})
				});
			}, 1000);
		}

		$scope.save = function() {
			$scope.isSaving = true;
			actorMember.actorId = $scope.acteurBonita;
			actorMember.roleId = $scope.roleBonita;
			$http.post('api/actorMember', actorMember, 'application/json')
					.then(function(response) {
						swal({
							title : "Succès",
							text : "Traitement terminé avec succès",
							type : "success",
							confirmButtonColor : "#DD6B55",
							closeOnConfirm : true,
							html : false
						}, function() {
							$state.go('affectation-role', null, {
								reload : true
							});
						});
					}, function(response) {
						swal({
							timer : 2000,
							title : "Impossible!",
							text : "Oops ... Quelque chose s'est mal passé!",
							showConfirmButton : false,
							type : "error"
						})
					});
		}
		$scope.clear = function() {
			$state.go('affectation-role', null, {
				reload : false
			});
		};
	}
})();
