(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('Raison', Raison);

    Raison.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

    function Raison ($http,$resource, DateUtils,$rootScope) {
        return {
        	'getExpediteur': function (id) {

				return  $http.get('api/oeExpediteurs/raisonSociale/'+id) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			

            'updateOeExpediteur': function(oeExpediteur,idProcess){
				return $http({
					method : "PUT",
					url : "api/oeExpediteur/updateOeExpediteur/"+idProcess,
					dataType: "json",
					traditional: true,
					data : oeExpediteur,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			
        };
    }
})();