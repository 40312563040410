(function() {
    'use strict';

    angular
        .module('infochamApp')
        .directive('specimensignature', specimensignature)
    specimensignature.$inject = ['$rootScope'];
    function specimensignature($rootScope) {
        return {
    		restrict: "E",
    		scope: {
    			target: '=',
    			add:'=',
    			specimensignaturesparam:'=',
    			id:'='
    			
    			
    		},
    		templateUrl: 'app/directives/menu/specimen-signature.html',
			controller:['CustomProcessBonita','Inscription2','blockUI', '$timeout','fileReader' ,'fileUpload' ,'FileUploader' , 'SpecimenSignature', 'PieceJointe',
				'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope', '$state', 'ParseLinks', 
				'$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder','$http', 'Principal', '$window', '$sce', function (CustomProcessBonita,Inscription2,blockUI, $timeout,fileReader ,fileUpload ,FileUploader , SpecimenSignature, PieceJointe,
	    				DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, $state, ParseLinks, 
	    				$rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder,$http, Principal, $window, $sce){
				$scope.specimenSignature = {};
				$scope.status={}
				$scope.agendaDTO=[]
				$scope.codeStatut={}
				if($scope.specimensignaturesparam == undefined){
					CustomProcessBonita.remplireCustomProcess($scope.id).then(function (result) {
						$scope.specimensignaturesparam = result.data.specimenSignatureDTOs ;
						$scope.getImage = function(imageAlfKey) {
							if (imageAlfKey != null || imageAlfKey != "") {
								var getUrl = 'cmis/download/'+imageAlfKey;
								var urlObject = $http.get(getUrl, {	responseType : 'arraybuffer' })
								.success(function(data) {
									var file = new Blob([ data ], {	type : 'image/jpg'	});
									var url = URL.createObjectURL(file);
									$scope.content.push($sce.trustAsResourceUrl(url)) ;

								});
							} else {
								$scope.content = null;
								$scope.myFile = null;
							}
						}
						if ($scope.specimensignaturesparam != undefined && $scope.specimensignaturesparam != null ) {
							for (var i = 0; i < $scope.specimensignaturesparam.length; i++) {
								if ($scope.specimensignaturesparam[i].pieceJointeDTO != null && $scope.specimensignaturesparam[i].pieceJointeDTO.alfKey != null) {
									$scope.getImage($scope.specimensignaturesparam[i].pieceJointeDTO.alfKey);
								}

							}
						}
					});
				}
				if ($stateParams.customProcess != null){
					
					$scope.status=$stateParams.customProcess.agendaDTO.codeStatut;
				if (($scope.status == "ATTVALINFCOMPSP" )||($scope.status == "ATTVALSP" )){
					
					
					$scope.val3=false
				}
				else{
					$scope.val3=true
					  
				}
				
				}else {
					if($scope.add){
					$scope.val3=true
					} else {
						$scope.val3=false
					}
				}
				if ($scope.add) {
					$scope.row = 5
				} else {
					$scope.row = 4

				}
				
				$scope.btnCallBack = function(result) {
					$scope.specimenSignature = result;
					$scope.specimenSignature.imageFile = result.message
					if (result.index  != undefined && result.index  != null ) {
						$scope.specimensignaturesparam[result.index] = result;     

					} else {
						$scope.specimensignaturesparam.push(result)

					}
				
					
				}
			

				$scope.remove = function(item,index) { 
//					return;
					swal({
						title: "Confirmation",
						text: "Cette opération est irréversible. Etes-vous sûr de continuer ?",
						type: "warning",
						showCancelButton: true,
						cancelButtonText: "NON",
						cancelButtonColor: '#d33',
						confirmButtonColor: "#337ab7",
						confirmButtonText: "OUI",
						closeOnConfirm: true
					},function () {

							$scope.content.splice(index, 1);     
							$scope.specimensignaturesparam.splice(index, 1);     

							if (item.id != null && item.id != undefined) {

								$http.get("api/deleteSpecimenSignatureAndPiece/"+item.id).then(function(result) {

								});
							}
							
					});

				}


			

				$scope.addSpecimen = function() {
					$state.go("affecterPhotoDialog");

				}
				$scope.modifSpecimen = function(specimen,index) {
					
					$state.go("affecterPhotoDialog", {specimen:specimen, index:index});
				}

				

				/******************************************/

				$scope.content = [];

				$scope.getImage = function(imageAlfKey) {
					if (imageAlfKey != null || imageAlfKey != "") {
						var getUrl = 'cmis/download/'+imageAlfKey;
						var urlObject = $http.get(getUrl, {	responseType : 'arraybuffer' })
						.success(function(data) {
							var file = new Blob([ data ], {	type : 'image/jpg'	});
							var url = URL.createObjectURL(file);
							$scope.content.push($sce.trustAsResourceUrl(url)) ;

						});
					} else {
						$scope.content = null;
						$scope.myFile = null;
					}
				}
				if ($scope.specimensignaturesparam != undefined && $scope.specimensignaturesparam != null ) {
					for (var i = 0; i < $scope.specimensignaturesparam.length; i++) {
						if ($scope.specimensignaturesparam[i].pieceJointeDTO != null && $scope.specimensignaturesparam[i].pieceJointeDTO.alfKey != null) {
							$scope.getImage($scope.specimensignaturesparam[i].pieceJointeDTO.alfKey);
						}

					}
				}


				/*****************************************/



				$scope.open = function(i){
					if ( $scope.specimensignaturesparam[i].pieceJointeDTO != undefined &&  $scope.specimensignaturesparam[i].myFiles == undefined) {


						var downloadLink = document.createElement("a");

						document.body.appendChild(downloadLink);
						downloadLink.style = "display: none";
						Inscription2
						.downloadDocument($scope.specimensignaturesparam[i].pieceJointeDTO.id)
						.then(
								function(result) {
									if(angular.equals( { status: result.status }, { status: 500 } )){
										swal(message,"Un problème s'est produit!", "error")
									}
									var fName = result
									.headers('Content-Disposition');
									var file = new Blob([ result.data ],{type : 'application/pdf'});
									var fileURL = (window.URL || window.webkitURL).createObjectURL(file);
									downloadLink.href = fileURL;
									downloadLink.download = fName;
									downloadLink.click();
								});




					} else if ($scope.specimensignaturesparam[i].myFile != null && $scope.specimensignaturesparam[i].myFile != undefined  ) {
						var downloadLink = document.createElement("a");
						var file = new Blob([ $scope.specimensignaturesparam[i].myFile.message ], {	type : 'application/pdf'	});
//						var fileURL = URL.createObjectURL(file);
						var fileURL = (window.URL || window.webkitURL)
						.createObjectURL(file);
						downloadLink.href = fileURL;
						downloadLink.download = $scope.specimensignaturesparam[i].myFile.message.name;
						downloadLink.click();
//						window.open(fileURL);	
					}


				}

				$scope.openFromAPI = function(docId){}
			}]
    	};
    }
})();
