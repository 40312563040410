(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('PieceJointeController', PieceJointeController);

	PieceJointeController.$inject = ['$scope', '$state', 'PieceJointe', 'ParseLinks'];

	function PieceJointeController ($scope, $state, PieceJointe, ParseLinks) {

        $scope.pieceJointes = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            PieceJointe.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.pieceJointes = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.pieceJointe = {
                alfKey: null,
                nom: null,
                type: null,
                commentaire: null,
                dateCre: null,
                supprimer: null,
                idEntity: null,
                id: null
            };
        };
    }
})();
