(function() {
	'use strict';

	angular.module('infochamApp')
        .factory('dataService', dataService);

	dataService.$inject = ['$http'];

    
    function dataService ($http) {
           var dataFactory = {};

        dataFactory.checkUniqueValue = function (entity, id, value) {
            if (!id) id = 0;
          
            return $http.get('api/checkUnique/' + entity + '/' + id + '/' + escape(value)).then(
                function (results) {
                    return results.data;
                });
        };

        return dataFactory;

}

})();
