(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ResponsableDetailController',ResponsableDetailController);
ResponsableDetailController.$inject=['$scope', '$rootScope', '$stateParams','entity', 'Responsable'];
function ResponsableDetailController($scope, $rootScope, $stateParams, entity, Responsable){
	$scope.responsable = entity;
    $scope.load = function (id) {
        Responsable.get({id: id}, function(result) {
            $scope.responsable = result;
        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:responsableUpdate', function(event, result) {
        $scope.responsable = result;
    });
    $scope.$on('$destroy', unsubscribe);
}

})();