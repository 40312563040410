(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('PersonnelDetailController',PersonnelDetailController);
PersonnelDetailController.$inject=['$scope', '$rootScope', '$stateParams','entity', 'Personnel'];
function PersonnelDetailController($scope, $rootScope, $stateParams, entity, Personnel){
	$scope.personnel = entity;
    $scope.load = function (id) {
    	Personnel.get({id: id}, function(result) {
            $scope.personnel = result;
        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:PersonnelUpdate', function(event, result) {
        $scope.personnel = result;
    });
    $scope.$on('$destroy', unsubscribe);
}

})();
