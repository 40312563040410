(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DepotDossierJuridiqueDialogController', DepotDossierJuridiqueDialogController);

    DepotDossierJuridiqueDialogController.$inject = ['$sce','SpecimenSignature','$stateParams','Agenda' ,'Principal','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita','CustomTypeAdhesion','$http'];

    function DepotDossierJuridiqueDialogController($sce,SpecimenSignature, $stateParams,Agenda ,Principal,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita , CustomTypeAdhesion,$http) {

		
		
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.codeProcess ="DEPOTDOSJUR"
				
				$scope.codeProcessNote ="ATTINFCOMPDOSSJUR"
//			$scope.attcompdoc1="ATTINFCOMPSP";
			
			$scope.val=true
			$scope.val3={}
			$scope.customProcess = $stateParams.customProcess ;
			$scope.createur = $scope.customProcess.oeExpediteurDTO;
    		$scope.agendaDTO = $scope.customProcess.agendaDTO;
    		$scope.specimenSignatures = $stateParams.specimenSignatures ;
    		$scope.pieceJointeDTOs = $scope.customProcess.pieceJointeDTOs;
    		$scope.demandeDossJur = $scope.customProcess.demandeDossierJuridiqueDTO;
			$scope.customProcessDTO =$stateParams.customProcess ;
			$scope.expediteur =$stateParams.customProcess.demandeDossierJuridiqueDTO.createur.oeExpediteur;
			$scope.idEntity= $scope.customProcess.agendaDTO.idEntity;
			if (($scope.customProcess.agendaDTO.codeStatut == "ATTVALINFCOMPSP" )||($scope.customProcess.agendaDTO.codeStatut == "ATTVALSP" )){
				
				$scope.val=false
				$scope.val3 = {}
			}
			else{
				$scope.val=true
				$scope.val3 = "glyphicon glyphicon-trash icon-trash-style"
			}

			Principal.identity().then(function(account) {
				

	             Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
						
						$scope.adhesion = result.data
						
					});
					
				
			});
			
			
			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}

			 $http.get("api/listBouton/"+$scope.agendaDTO.idCase).then(function(result) {
				  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
				  	$scope.listButton=result.data;
				  	$scope.showNoteAttComp1 = true;
				  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
				  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
				  	// ajouter le ou les  boutons dans la balise boutonWK
				  	// l'id #boutonWK est ajouté au niveau html
				  	angular.element("#boutonWK").append( $compile(html)($scope));
				  })			
			
			
			
			
			
			$scope.nextProcess = function(nextStep) {
			CustomProcessBonita.nextStep($stateParams.customProcess,nextStep).then(function(result) {
				

			});
		}
			var unsubscribe1 = $scope.$on('eventEmitedPiece', function(event, result) {
				$scope.pieces = result.message;
				$scope.pieceValide = result.pieceValide;

			});
			$scope.traitement = function(keyBouton,value){
				$scope.customProcessDTO.oeExpediteurDTO = $scope.expediteur;
				 if(keyBouton == "ATTINFCOMPDOSSJUR"){
						$state.go('note-demandeDossierJuridique', {customProcess:$scope.customProcess,key: keyBouton, id: $scope.agendaDTO.id  , save:true}, { reload: true } );
			
					} else  if(keyBouton == "VALANNULDOSSJUR"){
						  swal({
					            title: "Confirmation",
					            text: "Êtes-vous sûr de vouloir annuler la demande de dépot dossier juridique  ?",
					            type: "warning",
					            showCancelButton: true,
					            confirmButtonColor: "#449d44",
					            confirmButtonText: "Valider",
					            closeOnConfirm: true,
					            cancelButtonText: "Annuler",    
					        },
					        function(){
								  Inscription2.updateProcInscription($scope.agendaDTO.id,keyBouton,value).then(function (result) {
					 			$state.go('home', null, { reload: true } );
					 });
					        });
					  } else  if(keyBouton == "ATTVALINFCOMPDOSSJUR"){
						  $scope.customProcessDTO.pieceJointeDTOs = $scope.pieces;
								  Agenda.updateDossierJuridique($scope.customProcessDTO,keyBouton).then(function (result) {
					 			$state.go('home', null, { reload: true } );
					 });
					  }
					  else {
					  Inscription2.updateProcInscription($scope.agendaDTO.id,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
					  }
			}

			
			$scope.addNote = function() {
				$state.go('note-demandeSpecimen', {customProcess:$scope.customProcess,key: $scope.agendaDTO.codeStatut, id: $scope.agendaDTO.id , save:true}, { reload: true } );

			}
			
			
		

			
		}
})();
