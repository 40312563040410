
(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('CustomNaturePieceJointe', CustomNaturePieceJointe);

    CustomNaturePieceJointe.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

    function CustomNaturePieceJointe ($http,$resource, DateUtils,$rootScope) {
        return {
        	
				'getPieceJointeByCodeProcess':function(code){
					return $http.get('api/getPieceJointeByCodeProcess/'+code);
				},
				'getPieceJointeByCodeProcessAndEtape':function(codeProcess,codeEtap){
					return $http.get('api/getPieceJointeByCodeProcessAndEtape/'+codeProcess+'/'+codeEtap);
				},
				'getPieceJointeByCodeProcessWithNoStep':function(code){
					return $http.get('api/getPieceJointeByCodeProcessWithNoStep/'+code);
				},
        };
    }
})();