(function() {
    'use strict';
    angular
        .module('infochamApp')
        .controller('CciDeleteController', CciDeleteController);
    CciDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Cci'];

    function CciDeleteController ($scope, $uibModalInstance, entity, Cci) {

        $scope.cci = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Cci.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();





