(function() {
'use strict';
angular
	.module('infochamApp')
	.factory('Personnel',Personnel);
Personnel.$inject=['$resource', 'DateUtils'];
function Personnel($resource, DateUtils){
	return $resource('api/personnels/:id', {}, {
        'query': { method: 'GET', isArray: true},
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                data = angular.fromJson(data);
                return data;
            }
        },
        'update': { method:'PUT' }
    });	
}

})();