(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ParamPaiementController',ParamPaiementController);
ParamPaiementController.$inject=['$filter','$resource', '$scope', '$state', 'Referentiel', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService'];
function ParamPaiementController($filter,$resource, $scope, $state, Referentiel, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService){
	$scope.agenceTypes = {};
	
	function actionsHtml(data, type, full, meta) {
		return '<div class="action-center" style="margin-top:0px">' + ' <div class="col-md-1"><a ui-sref="paramPaiement.edit({id:' + data.id + '})">' +
		'   <i class="fa fa-pencil pencil-style"></i> ' + '</a></div>' + '<div class="col-md-1"><a ui-sref="paramPaiement.delete({id:' + data.id + '})" )"="">' + '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> ' + '</a></div>'
	}
	$scope.dtOptions = DTOptionsBuilder
	.newOptions()
	.withOption('ajax', {
		url: 'api/findAllParamPaiement',
		type: 'GET',
	})
.withOption('createdRow', createdRow)

.withPaginationType('full_numbers')
.withOption('order', [0, 'asc'])
.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

	'0' : {
		type : 'text'
	},
	'1' : {
		type : 'text'
	},
	'2' : {
		type : 'text'
	},
})

$scope.dtColumns = [
    DTColumnBuilder.newColumn('typePaiement').withTitle('TypePaiement').withOption('width', '20%'),
    DTColumnBuilder.newColumn('libelle').withTitle('Libellé').withOption('width', '20%'),
	DTColumnBuilder.newColumn('valeur').withTitle('Valeur').withOption('width', '20%'),	
	DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withClass('datatable-action-width')
	.renderWith(actionsHtml),
	];
$scope.search=0;
function createdRow(row, data, dataIndex) {
$compile(angular.element(row).contents())($scope);
}
	$scope.dtInstance = {};

	$scope.clear = function() {

	};
}

})();

