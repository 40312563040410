(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('CertificatOrigineHorsLigneFacturationController', CertificatOrigineHorsLigneFacturationController);

	CertificatOrigineHorsLigneFacturationController.$inject = ['ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function CertificatOrigineHorsLigneFacturationController (ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.idAgenda = $stateParams.id;
			 Certificat.getDemandeByProcess($scope.idAgenda).then(function (result) {
				$scope.agenda = result.data.agenda;
				$scope.facture = result.data.facture;
				$scope.certificatOrigine = result.data.certificatOrigine;
				$scope.autresServices = result.data.autresServices ;
				$scope.detailFacture = result.data.detailFactureList;
				$scope.modeDepot = result.data.modeDepot;
					$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
				
				 $http.get("api/listBouton/"+$scope.facture.process.idCase).then(function(result) {
					  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
						$scope.listButton1 = {};
					  	$scope.listButton=result.data;
					  	$scope.listButton1["NUMFACTHLANNUL"]="Annuler";
					  	$scope.listButton1["NUMFACTHL"]="Facturer";
					  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
					  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)"  ng-disabled="Form2.$invalid || isSaving" class="btn btn-info-2" ><span class="box-container-direct" note id="test""></span>{{value}}</button>&nbsp;&nbsp;</span>';
					  	
					  	// ajouter le ou les  boutons dans la balise boutonWK
					  	// l'id #boutonWK est ajouté au niveau html
					  	angular.element("#boutonWK").append( $compile(html)($scope));
					  })
						   if($scope.autresServices.nbrecopieCO != undefined && $scope.autresServices.nbrecopieCO != "" && $scope.autresServices.nbrecopieCO != null){
								 $scope.nbrecopieCO= $scope.autresServices.nbrecopieCO;
							 }
							 else {
								 $scope.nbrecopieCO="0";
							 }
							 if($scope.autresServices.nbreDmpCo != undefined && $scope.autresServices.nbreDmpCo != "" && $scope.autresServices.nbreDmpCo != null){
								 $scope.nbreDmpCo= $scope.autresServices.nbreDmpCo;
							 }
							 else {
								 $scope.nbreDmpCo="0";
							 }
								
							 if($scope.autresServices.nbreVisaDoc != undefined && $scope.autresServices.nbreVisaDoc != "" && $scope.autresServices.nbreVisaDoc != null){
								 $scope.nbreVisaDoc= $scope.autresServices.nbreVisaDoc;
							 }
							 else {
								 $scope.nbreVisaDoc="0";
							 }
							 
							 if($scope.autresServices.nbreVisaFacture != undefined && $scope.autresServices.nbreVisaFacture != "" && $scope.autresServices.nbreVisaFacture != null){
								 $scope.nbreVisaFacture= $scope.autresServices.nbreVisaFacture;
							 }
							 else {
								 $scope.nbreVisaFacture="0";
							 }	
						  Certificat.getMontant($scope.idAgenda,$scope.nbrecopieCO , $scope.nbreDmpCo, $scope.nbreVisaDoc , $scope.nbreVisaFacture).then(function (result) {
								
						   $scope.montant=result.data;
					   });
			        	 });
				$scope.dtOptionsConsolide = DTOptionsBuilder
				.newOptions()

				function createdRow(row, data, dataIndex) {
					// Recompiling so we can bind Angular directive to the DT
					$compile(angular.element(row).contents())($scope);
				}
				
				/*get file dec tresor from alfreco*/
				$scope.downloadDecharge = function(docId) {
				var downloadLink = document.createElement("a");

				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2
				.downloadDocument(docId)
				.then(
				function(result) {
				   	if(angular.equals( { status: result.status }, { status: 500 } )){
				swal(message,"Un problème s'est produit!", "error")
				}
				var fName = result
				.headers('Content-Disposition');
				var file = new Blob(
				[ result.data ],
				{
				type : 'application/pdf'
				});
				var fileURL = (window.URL || window.webkitURL)
				.createObjectURL(file);
				downloadLink.href = fileURL;
				downloadLink.download = fName;
				downloadLink.click();
				});
				}
				  $scope.traitement = function(keyBouton,value,numCO){
					
					 if(keyBouton == "NUMFACTHLANNUL"){
						  swal({
					            title: "Confirmation",
					            text: "Êtes-vous sûr de vouloir annuler la demande de l'attestation d'origine  ?",
					            type: "warning",
					            showCancelButton: true,
					            confirmButtonColor: "#449d44",
					            confirmButtonText: "Valider",
					            closeOnConfirm: true,
					            cancelButtonText: "Annuler",    
					        },
					        function(){
								  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
					 			$state.go('home', null, { reload: true } );
					 });
					        });
					  }
					  else {
					
					
					
					  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
							  
		Certificat.attributionNumFacturation($scope.idAgenda,$scope.numCO).then(function (result) {
		});
				 			$state.go('home', null, { reload: true } );
				 });
				}
					}
				  $scope.noteHonoraire = function(){
					  ReportingService.downloadNoteHonoraire($scope.idAgenda, $scope.numCO).then(function (result) {
						  var fileName="Note honoraire"
						  ReportingService.downloadPdfFile(result, fileName)
				 });
					}
}
})();
