(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('SettingScanSearch', SettingScanSearch);

    SettingScanSearch.$inject = ['$resource'];

    function SettingScanSearch ($resource) {
        return $resource('api/_search/settingScans/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();