(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('CertificatOrigineDetailController', CertificatOrigineDetailController);

	CertificatOrigineDetailController.$inject = ['Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope','Principal', '$state', '$http','$compile', 'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function CertificatOrigineDetailController (Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile, AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.codeProcess ="CO"
			$scope.attinfcomp="ATTINFCOMP"
		$scope.idEntity = $stateParams.idEntity;
		$scope.dtInstance={};
		$scope.dtInstance1={};
		$scope.dtInstance2={};
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});
		
		$scope.idAgenda = $stateParams.id;
		
		 Certificat.getDemandeByProcess($scope.idAgenda).then(function (result) {
			$scope.agenda = result.data.agenda;
			$scope.facture = result.data.facture;
			$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
			$scope.autresServices = result.data.autresServices
			$scope.certificatOrigine = result.data.certificatOrigine
			$scope.detailFacture = result.data.detailFactureList;
			
			 $http.get("api/listBouton/"+$scope.facture.process.idCase).then(function(result) {
				  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
					$scope.listButton1 = {};
				  	$scope.listButton=result.data;
				  	$scope.listButton1["VALANNUL"]="Annuler";
				  	$scope.listButton1["ATTINFCOMP"]="Demander des informations complémentaires";
				  	$scope.listButton1["ATTDEPDOCPHY"]="Valider";
				  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
//				  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
				  	
				  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" ><span class="box-container-direct" note id="test""></span>{{value}}</button>&nbsp;&nbsp;</span>';
				  	
				  	// ajouter le ou les  boutons dans la balise boutonWK
				  	// l'id #boutonWK est ajouté au niveau html
				  	angular.element("#boutonWK").append($compile(html)($scope));
				  

				  })
				  
		        	 });
			$scope.dtOptionsConsolide = DTOptionsBuilder
			.newOptions()
			// or here
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
			.withOption('order',[ 0, 'desc' ] )
			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
			.withOption('fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {

					var columnas = [5]; //the columns you wish to add
					var secondRow = $(nRow).next()[0];
					for (var j in columnas) {
						var columnaActual = columnas[j];
						var totalFiltre = 0;
						var total = 0;
						for (var i = 0; i < aiDisplay.length; i++) {

							totalFiltre = totalFiltre + parseFloat($scope.detailFacture[i].nombreUnite * $scope.detailFacture[i].valeurUnite);
						}
						for (var i = 0; i < aasData.length; i++) {
							total = total + parseFloat($scope.detailFacture[i].nombreUnite * $scope.detailFacture[i].valeurUnite);
						}
						
						
						$($(nRow).children().get(columnaActual)).html(total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",") + " TND");
						
						
					} // end

				})
				$scope.dtColumnsConsolide = [
					DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(1).withTitle("Quantité").withOption('width', '10%'),
					DTColumnDefBuilder.newColumnDef(2).withTitle("Valeur (TND)").withOption('width', '10%'),
					DTColumnDefBuilder.newColumnDef(3).withTitle("Nombre / Nature de colis").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(4).withTitle("Poids net / brut").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(5).withTitle("Total ligne (TND)").withOption('width', '20%')
					];

			
			function createdRow(row, data, dataIndex) {
				// Recompiling so we can bind Angular directive to the DT
				$compile(angular.element(row).contents())($scope);
			}
		  
			 /*get file dec tresor from alfreco*/
			$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2
			.downloadDocument(docId)
			.then(
			function(result) {
			   	if(angular.equals( { status: result.status }, { status: 500 } )){
			swal(message,"Un problème s'est produit!", "error")
			}
			var fName = result
			.headers('Content-Disposition');
			var file = new Blob(
			[ result.data ],
			{
			type : 'application/pdf'
			});
			var fileURL = (window.URL || window.webkitURL)
			.createObjectURL(file);
			downloadLink.href = fileURL;
			downloadLink.download = fName;
			downloadLink.click();
			});
			}
			
			  function createdRow(row, data, dataIndex) {
				  $compile(angular.element(row).contents())($scope);
				  }
			  $scope.traitement = function(keyBouton,value){
			 if(keyBouton == "ATTINFCOMP"){
				  $state.go('note-demandeCO-infoComplementaire', {idProcess:$scope.idAgenda,key:keyBouton,value:value}, { reload: true } );
			  }
			 else  if(keyBouton == "VALANNUL"){
					  swal({
				            title: "Confirmation",
				            text: "Êtes-vous sûr de vouloir annuler la demande de l'attestation d'origine  ?",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonColor: "#449d44",
				            confirmButtonText: "Valider",
				            closeOnConfirm: true,
				            cancelButtonText: "Annuler",    
				        },
				        function(){
							  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				        });
				  }
				  else {
				  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
			 			$state.go('home', null, { reload: true } );
			 });
				  }
				}
				$scope.tab=0;
				$scope.selectTab = function (res){
				if(res==0){
				}else if(res==1){
				}
					$scope.tab=res;
				}
				$scope.isSelected = function(setTab){
					return setTab==$scope.tab;
				}
				$scope.myAccordionStatut = true;
				$scope.opened = function() {
					if ($scope.myAccordionStatut) {
						return "#collapse2";
					}
				}
				$(function() {
					var active = true;

					$('#collapse-init').click(function() {
						if (active) {
							active = false;
							$('.panel-collapse').collapse('show');
							$('.panel-title').attr('data-toggle', '');
							$(this).text('Enable accordion behavior');
						} else {
							active = true;
							$('.panel-collapse').collapse('hide');
							$('.panel-title').attr('data-toggle', 'collapse');
							$(this).text('Disable accordion behavior');
						}
					});

					$('#myAccordion2').on('show.bs.collapse', function() {
						if (active){$('#myAccordion2 .in').collapse('hide');}
					});
					$('#myAccordion2').on('hide.bs.collapse', function() {
					});
				});
				$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse', function() {
					$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-minus").addClass("fa fa-plus");

					// $scope.statut=false;
				});
				$('#myAccordion2 .panel-collapse').on('shown.bs.collapse', function() {
					$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-plus").addClass("fa fa-minus");
				});

				// The reverse of the above on hidden event:
				function renderer(api, rowIdx, columns) {
					var data = $.map( columns, function ( col, i ) {
						return col.hidden ?
								'<li data-dtr-index="'+col.columnIndex+'" data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+
								'<span class="dtr-title">'+col.title+'</span> '+'<span class="dtr-data">'+col.data+'</span>'+'</li>' :'';
					}).join('');
					return data ?
							$compile(angular.element($('<ul data-dtr-index="'+rowIdx+'"/>').append( data )))($scope) :
								false;
				}
			  


			  function createdRow(row, data, dataIndex) {
				  $compile(angular.element(row).contents())($scope);
				  }
				
				
					
		$scope.nombreAvecVirgule = function(aa)
		{
			var a =parseFloat(aa)
			return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
		}
			
		  
}
})();
