
(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('CciWs', CciWs);

    CciWs.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

    function CciWs ($http,$resource, DateUtils,$rootScope) {
        return {
        	'getAllCcis': function () {

				return  $http.get('api/findAllCci') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getCciByCode': function (codeCci) {

				return  $http.get('api/getCciByCode/'+codeCci) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
        };
    }
})();