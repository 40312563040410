(function() {
	'use strict';
	angular.module('infochamApp').controller('PaiementEnLigneDialogController',
			PaiementEnLigneDialogController);
	PaiementEnLigneDialogController.$inject = [ '$filter','PaiementEnLigneConfig', '$translate', '$scope',
		'$stateParams', '$uibModalInstance', 'entity', 'Referentiel',
		'TypeReferentiel' ];

	function PaiementEnLigneDialogController(filter,PaiementEnLigneConfig, $translate, $scope, $stateParams,
			$uibModalInstance, entity, Referentiel, typesCodificationPers) {

		$scope.paiementEnLigneConfig = entity;
		$scope.load = function(id) {
			PaiementEnLigneConfig.get({
				id : id
			}, function(result) {
				$scope.paiementEnLigneConfig = result;

			});
		};
		

		var onSaveSuccess = function(result) {
			$scope.$emit('infochamApp:updatePaiementConfiguration', result);
			$uibModalInstance.close(result);
			$scope.isSaving = false;

		};
		$scope.typeCod = {}

		var onSaveError = function(result) {
			$scope.isSaving = false;
			if (result.status == 500) {
				swal("Opération interrompue!", "Déja existant", "error");
			} else {

				swal("Opération interrompue!", "", "error");

			}
		};

		$scope.save = function() {
			$scope.isSaving = true;

			if ($scope.paiementEnLigneConfig.id != null) {
				PaiementEnLigneConfig.update($scope.paiementEnLigneConfig, onSaveSuccess, onSaveError);
			} else {
				PaiementEnLigneConfig.save($scope.paiementEnLigneConfig, onSaveSuccess, onSaveError);
			}
		};

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		
		$(".toggle-password").click(function() {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});



	}
})();
