

(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
    	$stateProvider
    	.state('initiationDemandeAdhesionOnline', {
    		parent: 'entity',
    		url: '/initiationDemandeAdhesionOnline',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			codeProcess : null,
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/ProcessDemandeAdhesion/Online/initiationDemandeAdhesionOnline.html',
    				controller: 'InitiationDemandeAdhesionOnlineController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})      

    	.state('traitDemandeAdhesion', {
    		parent: 'entity',
    		url: '/traitDemandeAdhesion',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/ProcessDemandeAdhesion/Online/traitDemandeAdhesion.html',
    				controller: 'TraitDemandeAdhesionController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
    	.state('successPaiementAdhesion', {
			parent: 'entity',
			url: '/successAdhesion',
			data: { pageTitle: 'infochamApp.document.home.title' },

			views: {
				'content@': {
					templateUrl: 'app/entities/ProcessDemandeAdhesion/Online/successAdhesion.html',
					controller: 'SuccesPaiementAdhesionController',


				}
			},

		})

		.state('failPaiementAdhesion', {
			parent: 'entity',
			url: '/failPaiementAdhesion',
			data: { pageTitle: 'infochamApp.document.home.title' },

			views: {
				'content@': {
					templateUrl: 'app/entities/ProcessDemandeAdhesion/Online/failAdhesion.html',
					controller: 'FailPaiementAdhesionController',


				}
			},

		})

}
})();

