

(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('typeAdhesion', {
            parent: 'entity',
            url: '/typeAdhesions',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'infochamApp.typeAdhesion.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/typeAdhesion/typeAdhesions.html',
                    controller: 'TypeAdhesionController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('typeAdhesion');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('typeAdhesion.detail', {
            parent: 'entity',
            url: '/typeAdhesion/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'infochamApp.typeAdhesion.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/typeAdhesion/typeAdhesion-detail.html',
                    controller: 'TypeAdhesionDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('typeAdhesion');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TypeAdhesion', function($stateParams, TypeAdhesion) {
                    return TypeAdhesion.get({id : $stateParams.id});
                }]
            }
        })
        .state('typeAdhesion.new', {
            parent: 'typeAdhesion',
            url: '/new',
            data: {
            },
            params : {
            	cci : null,
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/typeAdhesion/typeAdhesion-dialog.html',
                    controller: 'TypeAdhesionDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                description: null,
                                montant: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('typeAdhesion', null);
                }, function() {
                    $state.go('typeAdhesion');
                })
            }]
        })
        .state('typeAdhesion.edit', {
            parent: 'typeAdhesion',
            url: '/{id}/edit',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/typeAdhesion/typeAdhesion-dialog.html',
                    controller: 'TypeAdhesionDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['TypeAdhesion', function(TypeAdhesion) {
                            return TypeAdhesion.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('typeAdhesion', null);
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('typeAdhesion.delete', {
            parent: 'typeAdhesion',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/typeAdhesion/typeAdhesion-delete-dialog.html',
                    controller: 'TypeAdhesionDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['TypeAdhesion', function(TypeAdhesion) {
                            return TypeAdhesion.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('typeAdhesion', null);
                }, function() {
                    $state.go('^');
                })
            }]
        }).state('typeAdhesion.cci', {
        	parent: 'typeAdhesion',
        	url: '/cci',
        	data: {
        		authorities: [],
        	}  ,
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/typeAdhesion/listeCci.html',
        			controller: 'ListeCciController',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						code: null,
        						description: null,
        					};
        				}
        			}
        		}).result.then(function(result) {
        			$state.go('typeAdhesion', null, { reload: true });
        		}, function() {
        			$state.go('typeAdhesion');
        		})
        	}]
        });
}
})();

