(function() {
	'use strict';

	angular.module('infochamApp')
	.directive('footerDirective', footerDirective);
	footerDirective.$inject = [ '$rootScope' ];

	function footerDirective($rootScope) {
		return {
			restrict : "E",
			scope : {
				target : '=',
			},
			templateUrl : 'app/directives/menu/footer-directive.html',
			controller : [
					'$scope',
					'$state',
					'$compile',
					'$interval',
					'$translate',
					'CustomServices',
					'Principal',
					function($scope, $state, $compile, $interval, $translate,
							CustomServices, Principal) {
						$scope.libelleAgence = null;
						Principal
								.identity()
								.then(
										function(user) {
											var data = {
												user : user
											};

											$scope.userId = user != null ? user.id
													: null;
											if ($scope.userId != null) {
												CustomServices
														.getUserInfos(
																$scope.userId)
														.then(
																function(result) {
																	$scope.libelleAgence = result.data.libelleAgence;
																	return result;
																})
											}
										});

					} ]
		};

	}
})();
