(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeAdhesionController', TypeAdhesionController);

	TypeAdhesionController.$inject = ['$scope', '$state', 'CustomTypeAdhesion', 'ParseLinks', 'DTOptionsBuilder', 'DTColumnBuilder', '$rootScope', '$compile', '$translate', 'DTColumnDefBuilder','$timeout'];

	function TypeAdhesionController ($scope, $state, CustomTypeAdhesion, ParseLinks, DTOptionsBuilder, DTColumnBuilder, $rootScope, $compile, $translate, DTColumnDefBuilder,$timeout) {
		$scope.dtOptions = DTOptionsBuilder
		.newOptions()
		.withPaginationType('full_numbers')
		.withOption('order', [0, 'asc'])
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			},
		})

		$scope.dtColumns = [
			DTColumnDefBuilder.newColumnDef(0).withTitle('Code').withOption('width', '20%'),
			DTColumnDefBuilder.newColumnDef(1).withTitle('Description').withOption('width', '20%'),
			DTColumnDefBuilder.newColumnDef(2).withTitle('Montant personne Morale').withOption('width', '20%'),	
			DTColumnDefBuilder.newColumnDef(3).withTitle('Montant personne Physique').withOption('width', '20%'),	
			DTColumnDefBuilder.newColumnDef(4).withTitle('Actions').notSortable().withClass('datatable-action-width')
			];
		
		
		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}
		$scope.dtInstance = {};

		//choisir CCI
		$scope.choisir = function(){
			$state.go('typeAdhesion.cci');
		}

		$rootScope.$on('itemChoosen', function (event, data) {
			$scope.cci = data
			$scope.DisplayTable();
		});
		
		$scope.DisplayTable = function(){
			CustomTypeAdhesion.getTypeAdhesionByCCI($scope.cci.id).then(function (result){
				$scope.data = result.data;
				$scope.records = $scope.data.length;
			});
		}
		
		//choisir Process
		$scope.addNewType = function(){
			$state.go('typeAdhesion.new',{cci:$scope.cci});
		}
		
		$rootScope.$on('saveTypeAdhesion', function(event,result) {
			$timeout( function(){
				if (result.saved == true){
					$scope.DisplayTable();
				}
			}, 100 );

		});
		
}
})();
