(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('SuccesPaiementDossierExportController', SuccesPaiementDossierExportController);

	SuccesPaiementDossierExportController.$inject = ['ClickToPay','CustomProcessBonita','PaiementEnLigneWs','ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function SuccesPaiementDossierExportController (ClickToPay,CustomProcessBonita,PaiementEnLigneWs,ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.PaiementClickToPay = {};

		$.urlParam = function(name){
			var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
			if (results==null) {
				return null;
			}
			return decodeURI(results[1]) || 0;
		}
		if($.urlParam('orderId')!=null){
			PaiementEnLigneWs.getPaiementconfig().then(function(result) {
				CustomProcessBonita.remplireCustomProcess($.urlParam('id')).then(function (result) {
					$scope.customProcess = result.data;
					$scope.specimenSignatures = $scope.customProcess.specimenSignatureDTOs;
					$scope.customProcess.agendaDTO.idTask = $scope.customProcess.agendaDTO.idTask;
					$scope.paiementVirement = {};
					$scope.paiementCheque = {};
					$scope.numTmoney=0;
					$scope.numFlouss=0 ;
					$scope.RIB=0;
					$scope.verifPaiementMobile=false;
					$scope.verifPaiementEspece=false;
					$scope.paiementEspece={};
					$scope.customProcess = $scope.customProcess ;
					if($scope.customProcess !=null && $scope.customProcess.oeExpediteurDTO!=null){
						$scope.createur =$scope.customProcess.oeExpediteurDTO; 
					}
					$scope.agendaDTO = $scope.customProcess.agendaDTO;
					$scope.idAgenda=$scope.agendaDTO.id;
					$scope.demandeDossierExportDTO = $stateParams.demandeDossierExportDTO ;
					$scope.pieceJointeDTOs = $scope.customProcess.pieceJointeDTOs;
					$scope.customProcessDTO =$stateParams.customProcess ;
					$scope.expediteur =$scope.customProcess.demandeDossierExportDTO.createur.oeExpediteur;
					$scope.idEntity= $scope.customProcess.agendaDTO.idEntity;
					Principal.identity().then(function(account) {
						$scope.account = account;
						$scope.isAuthenticated = Principal.isAuthenticated;
						if ($scope.account == null) {
							$state.go('login');
						}
					});
					Principal.identity().then(function(account) {


						Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){

							$scope.adhesion = result.data

						});


					});


				});
				Certificat.getMontantByCode("DOSSIER_EXPORT").then(function (result) {
					$scope.montant1=result.data;
				});
				$.ajax({url: result.data.serviceClickToPayRedirect+"?userName="+result.data.userName +"&password="+result.data.password+"&orderId="+$.urlParam('orderId')+"&language=fr", 

					success: function(result){
						var json1 = JSON.parse(result);
						$scope.PaiementClickToPay.orderId=$.urlParam('orderId');
						var datePaiement = moment(json1.depositedDate).format("YYYY-MM-DD");
						$scope.PaiementClickToPay.datePaiement=datePaiement
						$scope.PaiementClickToPay.depositedAmount=json1.paymentAmountInfo.depositedAmount;
						$scope.PaiementClickToPay.approvedAmount=json1.paymentAmountInfo.approvedAmount;
						$scope.PaiementClickToPay.paymentState=json1.paymentAmountInfo.paymentState;
						$scope.PaiementClickToPay.orderNumber=json1.orderNumber;
						$scope.PaiementClickToPay.errorCode=json1.errorCode=json1.orderStatus;
						$scope.PaiementClickToPay.errorMessage=json1.actionCodeDescription;
						$scope.PaiementClickToPay.agendaDTO=$scope.agendaDTO;
						$scope.PaiementClickToPay.mail=json1.payerData.email;
						$scope.PaiementClickToPay.numCarteBancaire=json1.cardAuthInfo.pan;
						$scope.PaiementClickToPay.approvalCode=json1.cardAuthInfo.approvalCode;
						ClickToPay.addClickToPay($scope.PaiementClickToPay,$scope.idAgenda,$scope.montant1).then(function (result) {
						});
					}});

			});

		}
		$scope.verifMobile = function(numPaiement){

			Inscription2.verifPaiementMobile(numPaiement).then(function(result1){
				if(result1.data!=null && result1.data != ""){
					$scope.verifPaiementMobile=true;
				}
				else {
					$scope.verifPaiementMobile=false;
				}
			})
		};
		$scope.verifEspece = function(numPaiementEspece){
			Inscription2.verifPaiementEspece(numPaiementEspece).then(function(result1){
				if(result1.data!=null && result1.data != ""){
					$scope.verifPaiementEspece=true;
				}
				else {
					$scope.verifPaiementEspece=false;
				}
			})
		};
		$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
		// the LOV
		// datasource
		$scope.lovTitleBureau = 'Liste des bureaux';
		$scope.lovColumnListBureau = [ 'Libellé' ];
		$scope.lovFieldListBureau = [  'libelle' ];

		// function to be called when you click "choose" button
		$scope.lovCallBackBureau = function(result) {
			$scope.paiementEspece.bureau = result;

		}

		$scope.styleSwalConfirmation = {
				title: "Confirmation",
				text: "Voulez vous vraiment continuer ?",
				type: "warning",
				showCancelButton: true,
				cancelButtonText: "NON",
				confirmButtonColor: "#337ab7",
				confirmButtonText: "Oui",
				closeOnConfirm: true
		}
		$scope.nextProcess = function(nextStep) {
			CustomProcessBonita.nextStep($stateParams.customProcess,nextStep).then(function(result) {
			});
		}
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}
		var keyBouton = "CONFPAISPEC";
		if(keyBouton == "PAIANNUL"){
			swal({
				title: "Confirmation",
				text: "Êtes-vous sûr de vouloir annuler la demande de l'attestation d'origine  ?",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#449d44",
				confirmButtonText: "Valider",
				closeOnConfirm: true,
				cancelButtonText: "Annuler",  
			},
			function(){
				Inscription2.updateProcInscription($.urlParam('id'),keyBouton,value).then(function (result) {
					//$state.go('home', null, { reload: true } );
				});
			});
		}
		else{
			$scope.modePaiement="Mobile";
			$scope.authenticationError=false;
			if($scope.authenticationError == false){
				Inscription2.updateProcInscription($.urlParam('id'),"CONFPAIDOSSEXP","Payer").then(function (result) {
					//$state.go('home', null, { reload: true } );
				});
			}
		}
		$scope.traitement = function(keyBouton,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece){
			$scope.errors =null;
			$scope.authenticationError = false;
			if(modePaiement ==null || modePaiement  == undefined || modePaiement  == ""){
				$scope.errors ="Le mode de paiement est requis";
			}
			if ((modePaiement !=null && modePaiement != undefined && modePaiement != "")){
				if(modePaiement=="Virement"){
					if (paiementVirement !=null && paiementVirement != undefined && paiementVirement != ""){
						if(paiementVirement.dateVirement  ==null || paiementVirement.dateVirement   == undefined || paiementVirement.dateVirement    == ""){
							$scope.errors ="La date du virement est requis";
						}
						else if(paiementVirement.numVirement  ==null || paiementVirement.numVirement   == undefined || paiementVirement.numVirement    == ""){
							$scope.errors ="Le numéro du virement est requis";
						}
						else if(paiementVirement.agence  ==null || paiementVirement.agence   == undefined || paiementVirement.agence    == ""){
							$scope.errors ="L'agence du virement est requis";
						}

					}
				}

				else if(modePaiement=="Chèque"){
					if (paiementCheque  !=null && paiementCheque  != undefined && paiementCheque  != ""){
						if(paiementCheque.dateCheque  ==null || paiementCheque.dateCheque   == undefined || paiementCheque.dateCheque  == ""){
							$scope.errors ="La date du chèque est requis";
						}
						else if(paiementCheque.numCheque ==null || paiementCheque.numCheque   == undefined || paiementCheque.numCheque == ""){
							$scope.errors ="Le numéro du chèque est requis";
						}
						else if(paiementCheque.agence ==null || paiementCheque.agence == undefined || paiementCheque.agence == ""){
							$scope.errors ="L'agence du chèque est requis";
						}


					}
				}
			}
			if($scope.errors != null){
				$scope.authenticationError = true;
				$("html, body").animate({scrollTop: 300},"slow");
			}

			if(keyBouton == "PAIANNUL"){
				swal({
					title: "Confirmation",
					text: "Êtes-vous sûr de vouloir annuler la demande de l'attestation d'origine  ?",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#449d44",
					confirmButtonText: "Valider",
					closeOnConfirm: true,
					cancelButtonText: "Annuler",  
				},
				function(){
					Inscription2.updateProcInscription($.urlParam('id'),"CONFPAIDOSSEXP","Payer").then(function (result) {
						//$state.go('home', null, { reload: true } );
					});
				});
			}
			else{
				$scope.modePaiement="Mobile";
				if($scope.modePaiement =="Mobile"){
					Certificat.paiementMobile(paiementMobile,$scope.idAgenda,$scope.modePaiement,$scope.montant).then(function (result) {
					});
					$( document ).ready(function() {
						$("#CONFPAISPEC").click();
						//traitement(key,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece);
						document.getElementById("CONFPAISPEC").click();
						document.getElementById("boutonWK").click();
					});

				}
				if($scope.authenticationError == false){
					$scope.idAgenda=$.urlParam('id');
					Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
						//$state.go('home', null, { reload: true } );
					});
				}
			}
		}
		$scope.lovModel1 = 'api/findAllBanque';// this will be
		// the LOV
		// datasource
		$scope.lovTitle1 = 'Liste des banques';
		$scope.lovColumnList1 = [  'Dénomination' ];
		$scope.lovFieldList1 = [  'denomination' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack1 = function(result) {
			$scope.paiementVirement.banque = result;
		}

		$scope.lovModel2 = 'api/findAllBanque';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des banques';
		$scope.lovColumnList2 = [  'Dénomination' ];
		$scope.lovFieldList2 = [  'denomination' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.paiementCheque.banque = result;
		}

		$scope.$on('eventEmitedPaiement', function(event, data) {
			$scope.docPaiement = data.message;
		});

		$scope.noteHonoraire = function(){
			ReportingService.downloadNoteHonoraire($scope.idAgenda, $scope.certificatOrigine.numeroCo).then(function (result) {
				var fileName="Note honoraire"
					ReportingService.downloadPdfFile(result, fileName)
			});
		}







	}
})();
