(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('depotDossierJuridique', {
            parent: 'entity',
            url: '/depotDossierJuridique',
            data: {
//                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.demandeSpecimen.home.title'
            },
        	params : {
    			customProcess : null,
    			id : null,
    			codeStatut:null,
    			type:null
    		},
    		
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeDepotDossierJuridique/depotDossierJuridiques.html',
                	//templateUrl: 'app/404.html',
                    controller: 'DepotDossierJuridiqueController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('demandeSpecimen');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        
        
        	.state('traitDepotDossierJuridique', {
    		parent: 'entity',
    		url: '/traitDepotDossierJuridique',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeDepotDossierJuridique/depotDossierJuridique-dialog.html',
    				controller: 'DepotDossierJuridiqueDialogController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
  
        	.state('paiementDepotDossierJuridique', {
    		parent: 'entity',
    		url: '/paiementDepotDossierJuridique',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeDepotDossierJuridique/depotDossierJuridique-paiement.html',
    				controller: 'DepotDossierJuridiquePaiementController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
    	      
        	.state('paiementDepotDossierJuridique-detail', {
    		parent: 'entity',
    		url: '/paiementDepotDossierJuridiqueDetail',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeDepotDossierJuridique/depotDossierJuridique-paiement-detail.html',
    				controller: 'DepotDossierJuridiquePaiementDetailController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
    	  	      
        	.state('paiementDepotDossierJuridique-edit', {
    		parent: 'entity',
    		url: '/paiementDepotDossierJuridiqueEdit',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeDepotDossierJuridique/depotDossierJuridique-paiement-edit.html',
    				controller: 'DepotDossierJuridiquePaiementEditController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
    	      
    	
    	.state('consultAllDJ', {
            parent: 'home',
            url: 'consult-all-DJ',
            data: {
          	  authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT','ROLE_EMPLOYEE','ROLE_RESPONSABLE_PAIEMENT'],		
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeDepotDossierJuridique/recherche/consult-all-DJ.html',
                    controller: 'ConsultAllDJController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
      	.state('consultAllDJOE', {
            parent: 'home',
            url: 'consult-all-DJ-OE',
            data: {
//          	  authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT'],		
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeDepotDossierJuridique/recherche/consult-all-DJ-OE.html',
                    controller: 'ConsultAllDJOEController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })         .state('note-demandeDossierJuridique', {
            parent: 'traitDepotDossierJuridique',
            url: '/new/note/noteDemandeDossierJuridique/{idProcess}',
            data: {
                pageTitle: 'infochamApp.listeReference.home.title'
            },
            params : {
            	key : null,
            	value : null,
            	customProcess: null,
            	id:null
            },
            
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
                    controller: 'NoteDemandeDossTechInfoComplementaireController',
                    size: 'md',
                    resolve: {
                      entity: function () {
                          return {
                              codeListe: null,
                              designationListe: null,
                              status: null,
                          };
                      }
                  }
                }).result.then(function(result) {
                    $state.go('traitDepotDossierJuridique', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })         .state('note-demandeDossierJuridiquePaiement', {
            parent: 'paiementDepotDossierJuridique-detail',
            url: '/new/note/noteDemandeDossierJuridiquePaiement/{idProcess}',
            data: {
                pageTitle: 'infochamApp.listeReference.home.title'
            },
            params : {
            	key : null,
            	value : null,
            	customProcess: null,
            	id:null
            },
            
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
                    controller: 'NoteDemandeDossTechInfoComplementaireController',
                    size: 'md',
                    resolve: {
                      entity: function () {
                          return {
                              codeListe: null,
                              designationListe: null,
                              status: null,
                          };
                      }
                  }
                }).result.then(function(result) {
                    $state.go('traitDepotDossierJuridique', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        });  

        
  
}
})();

