(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('NaturePieceJointeDialogController', NaturePieceJointeDialogController);

	NaturePieceJointeDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'NaturePieceJointe', 'Referentiel', '$rootScope'];

	function NaturePieceJointeDialogController ($scope, $stateParams, $uibModalInstance, entity, NaturePieceJointe, Referentiel,$rootScope) {

    	$scope.naturePieceJointe = {};
    	$scope.naturePieceJointe.typeProcessDTO = {};
    	$scope.typeProcessDTO = $stateParams.typeProcess;
    	
    	
    	$scope.getEtapeProcess = function(codeProcess){
			$scope.lovModelEtapProcess = 'api/referentiels/getSousRefByCode/ETPROC/'+codeProcess;// this will be
			// the LOV
			// datasource
			$scope.lovTitleEtapProcess = 'Liste des étapes';
			$scope.lovColumnListEtapProcess = [  'Libellé' ];
			$scope.lovFieldListEtapProcess = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackEtapProcess = function(result) {
				$scope.naturePieceJointe.etapeProcessDTO = result;
			}
		}
    	
    	if ($scope.typeProcessDTO != null){
    		$scope.naturePieceJointe.typeProcessDTO = $scope.typeProcessDTO;
    		$scope.getEtapeProcess($scope.naturePieceJointe.typeProcessDTO.codeRubrique);
    	}
    	if (entity != null && entity.$promise != null){
    		entity.$promise.then( function(result) {
            	$scope.naturePieceJointe  = result;
            	$scope.getEtapeProcess($scope.naturePieceJointe.typeProcessDTO.codeRubrique);
            });
    	}
        
        
        
       
        
        $scope.referentiels = Referentiel.query();
        $scope.load = function(id) {
            NaturePieceJointe.get({id : id}, function(result) {
                $scope.naturePieceJointe = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:naturePieceJointeUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.naturePieceJointe.id != null) {
                NaturePieceJointe.update($scope.naturePieceJointe, onSaveSuccess, onSaveError);
                $rootScope.$broadcast('savedNatPJ',{update:true});
            } else {
                NaturePieceJointe.save($scope.naturePieceJointe, onSaveSuccess, onSaveError);
                $rootScope.$broadcast('savedNatPJ',{update:true});
            }
            
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        
        $scope.lovModelTypeProcess = 'api/referentiels/getByCode/TYPPROC';// this will be
		// the LOV
		// datasource
		$scope.lovTitleTypeProcess = 'Liste des process';
		$scope.lovColumnListTypeProcess = [  'Libellé' ];
		$scope.lovFieldListTypeProcess = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBackTypeProcess = function(result) {
			$scope.naturePieceJointe.typeProcessDTO = result;
			$scope.$emit('eventEtapProcess',result);
		}

		
		
		$rootScope.$on('eventEtapProcess', function(event,result) {
			$scope.getEtapeProcess(result.codeRubrique);
		});

    }
})();
