(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('NumeroAttestationDialogController',NumeroAttestationDialogController);
NumeroAttestationDialogController.$inject=['$scope', '$stateParams', '$uibModalInstance', 'entity', 'NumeroAttestation'];
function NumeroAttestationDialogController($scope, $stateParams, $uibModalInstance, entity, NumeroAttestation){
	$scope.numeroAttestation = entity;
    $scope.load = function(id) {
        NumeroAttestation.get({id : id}, function(result) {
            $scope.numeroAttestation = result;
        });
    };

    var onSaveSuccess = function (result) {
        $scope.$emit('infochamApp:numeroAttestationUpdate', result);
        $uibModalInstance.close(result);
        $scope.isSaving = false;
    };

    var onSaveError = function (result) {
        $scope.isSaving = false;
    };

    $scope.save = function () {
        $scope.isSaving = true;
        if ($scope.numeroAttestation.id != null) {
            NumeroAttestation.update($scope.numeroAttestation, onSaveSuccess, onSaveError);
        } else {
            NumeroAttestation.save($scope.numeroAttestation, onSaveSuccess, onSaveError);
        }
    };

    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };	
}

})();
