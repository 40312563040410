(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('errordetaillovlibre', errordetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('errorDetailLibreCtrl', errorDetailLibreCtrl);

	errordetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	errorDetailLibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','id','CustomProcessBonita' ,'DTColumnDefBuilder'];

	function errordetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				
			},
			
			template: '  <a style="font-size:16px" ng-click="openPopUp()">Besoin de l\'aide cliquez ici?&nbsp;&nbsp;<span class="glyphicon glyphicon-question-sign" style="font-size: x-large;"></span> <spanclass="hidden-xs forme-button">  </span></a>',


			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function() {

					
					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/helpDetailTemplate.html',
							controller : 'helpDetailLibreCtrl',
							resolve: {
								
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function errorDetailLibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,id,CustomProcessBonita ,DTColumnDefBuilder) {
		if(error.company != null && error.employees){
			$scope.employees =  error.company.concat(error.employees);
		} else if (error.company != null) {
			$scope.employees =  error.company;
		}
		else if (error.employees != null) {
			$scope.employees =  error.employees;
		}
		
		$scope.chooseItem = function (result) {
			$uibModalInstance.close(result);
		}

				// when you clic "X" button
				$scope.closeLov = function() {
					$uibModalInstance.dismiss('cancel');
				};
			}
})();
