(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$http','SpecimenSignature','$stateParams','Agenda' ,'Principal','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita'];

    function DashboardController($http,SpecimenSignature, $stateParams,Agenda ,Principal,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita) {

		
		
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.codePiece= 'SP';
			$scope.adhesion ={};
			$scope.expediteur = {};
			$scope.specimenSignatures = [];
			$scope.specimenSignatureDTOs = [];
			$scope.oeExpediteurDTO= {};
			$scope.demandeSpecimenDTO= {};
			$scope.statistique={};

			$scope.customProcessDTO = {};
			Principal.identity().then(function(account) {
				$scope.user = account;
					
			});
			
		
			
			$scope.lovModel2 = 'api/getAllExpediteur';// this will be
		 	// the LOV
		 	// datasource
		 	$scope.lovTitle2 = 'Liste des raisons sociales';
		 	$scope.lovColumnList2 = [' Raison Sociale '];
		 	$scope.lovFieldList2 = ['raisonSociale'];
		 	// function to be called when you click "choose" button
		 	$scope.lovCallBack2 = function(result) {
		 	$scope.expediteur = result;
		 	Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
				
				$scope.adhesion = result.data
			});
			
		 	}
		 	
		 	$scope.valider= function(){
		 		if ($scope.statistique.annee != null) {
		 			
		 			$http.get("api/findDossJuridiqueByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.dossierJuridique = result.data;
						$scope.idEntityDJ = $scope.dossierJuridique.idEntity;
						$scope.codeRubriqueDJ = 'DEPOTDOSJUR';
						$http.get('api/pieceJointes/'+ $scope.idEntityDJ+"/"+$scope.codeRubriqueDJ).then(function(result) {
							$scope.listDocs = result.data;
						});

					});
					
					$http.get("api/findAllDossTechniqueByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.dossierTechnique = result.data;
						$scope.idEntityDT = $scope.dossierTechnique.idEntity;
						$scope.codeRubriqueDT = 'DEPDOSSTECH';
						$http.get('api/pieceJointes/'+ $scope.idEntityDT+"/"+$scope.codeRubriqueDT).then(function(result) {
							$scope.listDocs3 = result.data;
							
						});

					});
					
					$http.get("api/findSpecByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.spec = result.data;
						$scope.idEntitySP = $scope.spec.idEntity;
						$scope.codeRubriqueSP = 'SP';
						$http.get('api/pieceJointes/'+ $scope.idEntitySP+"/"+$scope.codeRubriqueSP).then(function(result) {
							$scope.listDocs1 = result.data;
						});
					});
					
					$http.get("api/findDossExpByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.dossierExp = result.data;
						$scope.idEntityExp = $scope.dossierExp.idEntity;
						$scope.codeRubriqueExp = 'DEPOTDOSEXP';
						$http.get('api/pieceJointes/'+ $scope.idEntityExp+"/"+$scope.codeRubriqueExp).then(function(result) {
							$scope.listDocs2 = result.data;
						});
					});
		 			

				}else{
					$scope.statistique.annee=0;
					$http.get("api/findDossJuridiqueByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.dossierJuridique = result.data;
						$scope.idEntityDJ = $scope.dossierJuridique.idEntity;
						$scope.codeRubriqueDJ = 'DEPOTDOSJUR';
						$http.get('api/pieceJointes/'+ $scope.idEntityDJ+"/"+$scope.codeRubriqueDJ).then(function(result) {
							$scope.listDocs = result.data;
						});

					});
					
					$http.get("api/findAllDossTechniqueByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.dossierTechnique = result.data;
						$scope.idEntityDT = $scope.dossierTechnique.idEntity;
						$scope.codeRubriqueDT = 'DEPDOSSTECH';
						$http.get('api/pieceJointes/'+ $scope.idEntityDT+"/"+$scope.codeRubriqueDT).then(function(result) {
							$scope.listDocs3 = result.data;
							
						});

					});
					
					$http.get("api/findSpecByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.spec = result.data;
						$scope.idEntitySP = $scope.spec.idEntity;
						$scope.codeRubriqueSP = 'SP';
						$http.get('api/pieceJointes/'+ $scope.idEntitySP+"/"+$scope.codeRubriqueSP).then(function(result) {
							$scope.listDocs1 = result.data;
						});
					});
					
					$http.get("api/findDossExpByExpediteurByYearAndType/"+$scope.expediteur.id+"/"+$scope.statistique.annee).then(function(result) {
						$scope.dossierExp = result.data;
						$scope.idEntityExp = $scope.dossierExp.idEntity;
						$scope.codeRubriqueExp = 'DEPOTDOSEXP';
						$http.get('api/pieceJointes/'+ $scope.idEntityExp+"/"+$scope.codeRubriqueExp).then(function(result) {
							$scope.listDocs2 = result.data;
						});
					});
					
					
				}
		 		
		 		
		 		
		 	}
		 	
		var anneeDeb = $filter('date')(new Date(), 'yyyy');
		$scope.annees = [];
		for (var i = 0; i < 50; i++) {
			$scope.annees[i] = {
				id : i,
				ann : anneeDeb - i
				
			};

		}
		
		$scope.reset = function() {
			$state.go('dashboard', null, {
				reload : true
			});

		}
		

			
		

		}
})();
