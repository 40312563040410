(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ParamMobileDialogController',ParamMobileDialogController);
ParamMobileDialogController.$inject=['$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ParamMobile'];
function ParamMobileDialogController($translate, $scope, $stateParams, $uibModalInstance, entity,ParamMobile){
	$scope.ParamMobile = entity;
	$scope.load = function(id) {
		ParamMobile.get({
			id : id
		}, function(result) {
			$scope.paramMobile = result;
		});
	};
	var onSaveSuccess = function(result) {
		$scope.$emit('infochamApp:ParamMobileUpdate', result);
		$uibModalInstance.close(result);
		$scope.isSaving = false;
	};
	$scope.typeCod = {}
	var onSaveError = function(result) {
		$scope.isSaving = false;
		if (result.status == 500) {
			swal("Opération interrompue!", "Déja existant", "error");
		} else {
			swal("Opération interrompue!", "", "error");
		}
	};
	$scope.save = function() {
		$scope.isSaving = true;

		if ($scope.ParamMobile.id != null) {
			ParamMobile.update($scope.ParamMobile, onSaveSuccess, onSaveError);
		} else {
			ParamMobile.save($scope.ParamMobile, onSaveSuccess, onSaveError);
		}
	};
	$scope.clear = function() {
		$uibModalInstance.dismiss('cancel');
	};	
}

})();