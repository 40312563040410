(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('MatierePremiereController',MatierePremiereController);
MatierePremiereController.$inject=['$scope', '$state', 'MatierePremiere', 'ParseLinks'];
function MatierePremiereController($scope, $state, MatierePremiere, ParseLinks){
	 $scope.matierePremieres = [];
     $scope.predicate = 'id';
     $scope.reverse = true;
     $scope.page = 1;
     $scope.loadAll = function() {
         MatierePremiere.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
             $scope.links = ParseLinks.parse(headers('link'));
             $scope.totalItems = headers('X-Total-Count');
             $scope.matierePremieres = result;
         });
     };
     $scope.loadPage = function(page) {
         $scope.page = page;
         $scope.loadAll();
     };
     $scope.loadAll();


     $scope.refresh = function () {
         $scope.loadAll();
         $scope.clear();
     };

     $scope.clear = function () {
         $scope.matierePremiere = {
             designation: null,
             ngp: null,
             pht: null,
             fournisseurs: null,
             type: null,
             pourcentage: null,
             isAutomatique: null,
             dateCre: null,
             id: null
         };
     };	
}

})();