(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ModelePJDetailController',ModelePJDetailController);
ModelePJDetailController.$inject=['$scope', '$rootScope', '$stateParams','entity', 'ModelePj'];
function ModelePJDetailController($scope, $rootScope, $stateParams, entity, ModelePj){
	$scope.modelePj = entity;
    $scope.load = function (id) {
    	ModelePj.get({id: id}, function(result) {
            $scope.modelePj = result;
        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:ModelePjUpdate', function(event, result) {
        $scope.modelePj = result;
    });
    $scope.$on('$destroy', unsubscribe);
}

})();
