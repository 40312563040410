(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('DemandeAdhesion', DemandeAdhesion);

    DemandeAdhesion.$inject = ['$resource', 'DateUtils'];

    function DemandeAdhesion ($resource, DateUtils) {
        return $resource('api/demandeAdhesions/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateAdhesion = new Date(data.dateAdhesion);
                    data.dateCre = new Date(data.dateCre);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.dateAdhesion = DateUtils.convertLocaleDateToServer(data.dateAdhesion);
                    data.dateCre = DateUtils.convertLocaleDateToServer(data.dateCre);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.dateAdhesion = DateUtils.convertLocaleDateToServer(data.dateAdhesion);
                    data.dateCre = DateUtils.convertLocaleDateToServer(data.dateCre);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
