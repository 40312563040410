(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ReferentielController', ReferentielController);

	ReferentielController.$inject = ['Principal' ,'$resource', '$scope', '$state', 'Referentiel', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService'];

	function ReferentielController (Principal ,$resource, $scope, $state, Referentiel, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService) {
		
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;
			if ($scope.account == null) {
				$state.go('login', null, {
					reload : true
				});
			}
		});
				$scope.agenceTypes = {};
				$scope.dtOptions = DTOptionsBuilder.fromSource("api/referentiels/datatable")

				// or here
				.withDataProp('data').withDisplayLength(10).withOption('processing', true).withOption('serverSide', true).withOption('createdRow', createdRow).withPaginationType('full_numbers')
			.withOption('order', [0, 'asc'])

	     	.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({
					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					},
					'4' : {
						type : 'text'
					},
					'5' : {
						type : 'text'
					}

				})
				$scope.dtColumns = [ DTColumnBuilder.newColumn('codeType.codeType').withTitle($translate.instant('infochamApp.referentiel.codeType')).withOption('width', '15%'),
						DTColumnBuilder.newColumn('codeRubrique').withTitle($translate.instant('infochamApp.referentiel.codeRubrique')).withOption('width', '15%'), DTColumnBuilder.newColumn('libelle').withTitle($translate.instant('infochamApp.referentiel.libelle')),
						DTColumnBuilder.newColumn('valeur1').withTitle($translate.instant('infochamApp.referentiel.valeur1')).withOption('width', '10%'),
						DTColumnBuilder.newColumn('valeur2').withTitle($translate.instant('infochamApp.referentiel.valeur2')).withOption('width', '10%'),
						DTColumnBuilder.newColumn('status').withTitle($translate.instant('infochamApp.referentiel.status')).withOption('width', '10%'), DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('width', '7%').renderWith(actionsHtml)
				];

				function createdRow(row, data, dataIndex) {
					// Recompiling so we can bind Angular directive to the DT
					$compile(angular.element(row).contents())($scope);
				}

				function actionsHtml(data, type, full, meta) {
					// $scope.agenceTypes[data.id] = data;
					return '<div class="action-center" style="margin-top:0px">' + ' <div class="col-md-1"><a ui-sref="referentiel.edit({id:' + data.id + '})">' +

					'   <i class="fa fa-pencil pencil-style"></i> ' + '</a></div>' + '<div class="col-md-1"><a ui-sref="referentiel.delete({id:' + data.id + '})" )"="">' + '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> ' + '</a></div>'
				}
				$scope.dtInstance = {};

				$scope.clear = function() {

				};
			}
})();
