(function() {
	'use strict';
	angular.module('infochamApp').controller('AdresseDetailController',
			AdresseDetailController);
	AdresseDetailController.$inject = [ '$scope', '$rootScope', '$stateParams',
			'entity', 'Adresse', 'Referentiel' ];
	function AdresseDetailController($scope, $rootScope, $stateParams, entity,
			Adresse, Referentiel) {
		$scope.adresse = entity;
		$scope.load = function(id) {
			Adresse.get({
				id : id
			}, function(result) {
				$scope.adresse = result;
			});
		};
		var unsubscribe = $rootScope.$on('infochamApp:adresseUpdate', function(
				event, result) {
			$scope.adresse = result;
		});
		$scope.$on('$destroy', unsubscribe);
	}

})();
