(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
    	$stateProvider
    	.state('demandeDepotDossierTechnique', {
    		parent: 'entity',
    		url: '/depotDossierTechnique',
    		data: {
    			pageTitle: 'infochamApp.document.home.title'
    		},
    		params : {
    			status : null,
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/demandeDepotDossierTechniqueOnline.html',
    				//templateUrl: 'app/404.html',
    				controller: 'demandeDepotDossierTechniqueOnlineController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})   
    	.state('matierePremiereLocale', {
    		parent: 'demandeDepotDossierTechnique',
    		url: '/matierePremiereLocale',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},

    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-locale.html',
    				controller: 'MatierePremiereLocaleController',
    				size: 'lg',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})

    	.state('matierePremiereLocaleUpdate', {
    		parent: 'demandeDepotDossierTechnique',
    		url: '/matierePremiereLocaleUpdate',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-locale-update.html',
    				controller: 'MatierePremiereLocaleUpdateController',
    				size: 'lg',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereEtrangereUpdate', {
    		parent: 'demandeDepotDossierTechnique',
    		url: '/matierePremiereEtrangereUpdate',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-etrangere-update.html',
    				controller: 'MatierePremiereEtrangereUpdateController',
    				size: 'lg',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereLocaleInfoComplementaire', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/matierePremiereLocaleInfoComplementaire',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-locale-infoComplementaire.html',
    				controller: 'MatierePremiereLocaleInfoComplementaireController',
    				size: 'lg',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereEtrangereInfoComplementaire', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/matierePremiereEtrangereInfoComplementaire',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-etrangere-infoComplementaire.html',
    				controller: 'MatierePremiereEtrangereInfoComplementaireController',
    				size: 'lg',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereLocaleValidation', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/matierePremiereLocaleValidation',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-locale-validation.html',
    				controller: 'MatierePremiereLocaleValidationController',
    				size: 'md',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereEtrangereValidation', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/matierePremiereEtrangereValidation',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-etrangere-validation.html',
    				controller: 'MatierePremiereEtrangereValidationController',
    				size: 'md',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereLocaleUpdatePourcentage', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/matierePremiereLocaleUpdatePourcentage',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-locale-update-pourcentage.html',
    				controller: 'MatierePremiereLocaleUpdatePourcentageController',
    				size: 'md',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereEtrangereUpdatePourcentage', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/matierePremiereEtrangereUpdatePourcentage',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			index : null,
    			matierePremiere:null,

    		},
    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-etrangere-update-pourcentage.html',
    				controller: 'MatierePremiereEtrangereUpdatePourcentageController',
    				size: 'md',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('matierePremiereEtrangere', {
    		parent: 'demandeDepotDossierTechnique',
    		url: '/matierePremiereEtrangere',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},

    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/matierePremiere/matiere-premiere-etrangere.html',
    				controller: 'MatierePremiereEtrangereController',
    				size: 'lg',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('nature', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	.state('demandeDepotDossierTechniqueConsult', {
    		parent: 'entity',
    		url: '/depotDossierTechniqueConsult',
    		data: {
    			pageTitle: 'infochamApp.document.home.title'
    		},
    		params : {
    			id : null

    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/demandeDepotDossierTechniqueOnlineConsult.html',
    				controller: 'demandeDepotDossierTechniqueOnlineConsultController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})   
    	.state('demandeDepotDossierTechniqueEdit', {
    		parent: 'entity',
    		url: '/depotDossierTechniqueEdit',
    		data: {
    			pageTitle: 'infochamApp.document.home.title'
    		},
    		params : {
    			id : null

    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/demandeDepotDossierTechniqueOnlineEdit.html',
    				controller: 'demandeDepotDossierTechniqueOnlineEditController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	}) 
    	.state('depotDocOriginaux', {
    		parent: 'entity',
    		url: '/depotDocOriginaux',
    		data: {
    			pageTitle: 'infochamApp.document.home.title'
    		},
    		params : {
    			id : null

    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/depotDocOriginauxOnline.html',
    				controller: 'depotDocOriginauxOnlineController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	}) 
    	.state('depotDocOriginauxConsult', {
    		parent: 'entity',
    		url: '/depotDocOriginauxConsult',
    		data: {
    			pageTitle: 'infochamApp.document.home.title'
    		},
    		params : {
    			id : null

    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/depotDocOriginauxOnlineConsult.html',
    				controller: 'depotDocOriginauxOnlineConsultController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	.state('depotDocOriginauxEdit', {
    		parent: 'entity',
    		url: '/depotDocOriginauxEdit',
    		data: {
    			pageTitle: 'infochamApp.document.home.title'
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/depotDocOriginauxOnlineEdit.html',
    				controller: 'depotDocOriginauxOnlineEditController',
    				controllerAs: 'vm'
    			}
    		},
    		params : {
    			id : null

    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	}) 
    	.state('validationFicheProduit', {
    		parent: 'entity',
    		url: '/validationFicheProduit',
    		data: {
    			authorities: ['ROLE_DEPOT','ROLE_ADMIN'],	
    			pageTitle: 'infochamApp.document.home.title'
    		},
    		params : {
    			id : null

    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/validationFicheProduitOnline.html',
    				controller: 'ValidationFicheProduitOnlineController',
    				controllerAs: 'vm'
    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	}) 

    	.state('note-demandeDossTech-infoComplementaire', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/new/note/dossierTechnique/{idProcess}',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			key : null,
    			value : null
    		},

    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
    				controller: 'NoteDemandeDossTechInfoComplementaireController',
    				size: 'md',
    				backdrop  : 'static',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('certificat-detail-demandeCO', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})

    	.state('note-depotDocOrig-infoComplementaire', {
    		parent: 'depotDocOriginauxConsult',
    		url: '/new/note/depotDocument/{idProcess}',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			key : null,
    			value : null
    		},

    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-depotDocOrig-infoComplementaire.html',
    				controller: 'NoteDepotDocOrigInfoComplementaireController',
    				size: 'md',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('certificat-detail-demandeCO', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	
    		.state('note-depotDocOrig-add', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/new/note/depotDocument/add/{idProcess}',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			key : null,
    			value : null
    		},

    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-depotDocOrig-add.html',
    				controller: 'NoteDepotDocOrigAddController',
    				size: 'md',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('certificat-detail-demandeCO', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	
    	.state('note-demandeDossTech-add', {
    		parent: 'traitDemandeDepotDossTechnique',
    		url: '/new/note/demandeDossTech/add/{idProcess}',
    		data: {
    			pageTitle: 'infochamApp.listeReference.home.title'
    		},
    		params : {
    			key : null,
    			value : null
    		},

    		onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
    			$uibModal.open({
    				templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-add.html',
    				controller: 'NoteDemandeDossTechAddController',
    				size: 'md',
    				resolve: {
    					entity: function () {
    						return {
    							codeListe: null,
    							designationListe: null,
    							status: null,
    						};
    					}
    				}
    			}).result.then(function(result) {
    				$state.go('certificat-detail-demandeCO', null, { reload: true });
    			}, function() {
    				$state.go('^');
    			})
    		}]
    	})
    	
    	
    	  	
    	   .state('note-demandeAdhesionCO', {
                    parent: 'traitDemandeAdhesionCO',
                    url: '/new/note/noteDemandeAdhesionCO/{idProcess}',
                    data: {
                        pageTitle: 'infochamApp.listeReference.home.title'
                    },
                    params : {
                    	key : null,
                    	save : null,
                    	value : null,
                    	customProcess: null,
                    	id:null
                    },
                    
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeAdhesionCO-infoComplementaire.html',
                            controller: 'NoteDemandeAdhesionCOInfoComplementaireController',
                            size: 'md',
                            resolve: {
                              entity: function () {
                                  return {
                                      codeListe: null,
                                      designationListe: null,
                                      status: null,
                                  };
                              }
                          }
                        }).result.then(function(result) {
                            $state.go('traitDemandeAdhesionCO', null, { reload: true });
                        }, function() {
                            $state.go('^');
                        })
                    }]
                })
    	
    	
    	
    	   .state('note-demandeSpecimen', {
                    parent: 'traitDemandeSpecimen',
                    url: '/new/note/noteDemandeSpecimen/{idProcess}',
                    data: {
                        pageTitle: 'infochamApp.listeReference.home.title'
                    },
                    params : {
                    	key : null,
                    	save : null,
                    	value : null,
                    	customProcess: null,
                    	id:null
                    },
                    
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeSpecimen-infoComplementaire.html',
                            controller: 'NoteDemandeSpecimenInfoComplementaireController',
                            size: 'md',
                            resolve: {
                              entity: function () {
                                  return {
                                      codeListe: null,
                                      designationListe: null,
                                      status: null,
                                  };
                              }
                          }
                        }).result.then(function(result) {
                            $state.go('traitDemandeSpecimen', null, { reload: true });
                        }, function() {
                            $state.go('^');
                        })
                    }]
                })
    	
    	
    	     .state('note-demandeAdhesion', {
                    parent: 'traitDemandeAdhesion',
                    url: '/new/note/noteDemandeAdhesion/{idProcess}',
                    data: {
                        pageTitle: 'infochamApp.listeReference.home.title'
                    },
                    params : {
                    	key : null,
                    	value : null,
                    	customProcess: null,
                    	id:null
                    },
                    
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
                            controller: 'NoteDemandeDossTechInfoComplementaireController',
                            size: 'md',
                            resolve: {
                              entity: function () {
                                  return {
                                      codeListe: null,
                                      designationListe: null,
                                      status: null,
                                  };
                              }
                          }
                        }).result.then(function(result) {
                            $state.go('traitDemandeAdhesion', null, { reload: true });
                        }, function() {
                            $state.go('^');
                        })
                    }]
                })
                
                
        
                 .state('note-demandeAdhesion-init', {
                    parent: 'initiationDemandeAdhesionOnline',
                    url: '/new/note/noteDemandeAdhesion',
                    data: {
                        pageTitle: 'infochamApp.listeReference.home.title'
                    },
                    params : {
                    	key : null,
                    	value : null,
                    	customProcess: null,
                    	id:null,
                    	save:null
                    },
                    
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
                            controller: 'NoteDemandeDossTechInfoComplementaireController',
                            size: 'md',
                            resolve: {
                              entity: function () {
                                  return {
                                      codeListe: null,
                                      designationListe: null,
                                      status: null,
                                  };
                              }
                          }
                        }).result.then(function(result) {
                            $state.go('initiationDemandeAdhesionOnline', null, { reload: false });
                        }, function() {
                            $state.go('^');
                        })
                    }]
                })
            	.state('traitDemandeDepotDossTechnique', {
            		parent: 'entity',
            		url: '/traitementDemandeDepotDossierTechnique',
            		data: {
            			pageTitle: 'infochamApp.document.home.title'
            		},
            		params : {
            			id : null,
            			codeStatut:null,
            			idEntity : null
            			
            		},
            		views: {
            			'content@': {
            				templateUrl: 'app/entities/depotDossierTechnique/Online/traitementDemandeDepotDossierTechnique.html',
            				controller: 'traitementDemandeDepotDossierTechniqueController',
            				controllerAs: 'vm'
            			}
            		},
            		resolve: {
            			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            				$translatePartialLoader.addPart('global');
            				return $translate.refresh();
            			}]
            		}
            	})
            	        	.state('paiementDepotDossierTechnique', {
    		parent: 'entity',
    		url: '/paiementDepotDossierTechnique',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null,
    			id : null,
    			codeStatut:null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/depotDossierTechnique-paiement.html',
    				controller: 'DepotDossierTechniquePaiementController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
    	      
        	.state('paiementDepotDossierTechnique-detail', {
    		parent: 'entity',
    		url: '/paiementDepotDossierTechniqueDetail',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null,
    			id : null,
    			codeStatut:null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/depotDossierTechnique-paiement-detail.html',
    				controller: 'DepotDossierTechniquePaiementDetailController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	         .state('note-demandeDossierTechniquePaiement', {
            parent: 'paiementDepotDossierTechnique-detail',
            url: '/new/note/notepaiementDepotDossierTechnique/{idProcess}',
            data: {
                pageTitle: 'infochamApp.listeReference.home.title'
            },
            params : {
            	key : null,
            	value : null,
            	customProcess: null,
            	id:null
            },
            
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
                    controller: 'NoteDemandeDossTechInfoComplementaireController',
                    size: 'md',
                    resolve: {
                      entity: function () {
                          return {
                              codeListe: null,
                              designationListe: null,
                              status: null,
                          };
                      }
                  }
                }).result.then(function(result) {
                    $state.go('traitDepotDossierJuridique', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
    	  	      
        	.state('paiementDepotDossierTechnique-edit', {
    		parent: 'entity',
    		url: '/paiementDepotDossierTechniqueEdit',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null,
    			id : null,
    			codeStatut:null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/depotDossierTechnique/Online/depotDossierTechnique-paiement-edit.html',
    				controller: 'DepotDossierTechniquePaiementEditController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	.state('consultAllDT', {
                    parent: 'home',
                    url: 'consult-all-DT',
                    data: {
                  	  authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT','ROLE_EMPLOYEE','ROLE_RESPONSABLE_PAIEMENT'],		
                        pageTitle: 'infochamApp.agenda.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/depotDossierTechnique/recherche/consult-all-DT.html',
                            controller: 'ConsultAllDTController',
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('agenda');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                })
    	
       	.state('consultAllDTOE', {
            parent: 'home',
            url: 'consult-all-DT-OE',
            data: {
//          	  authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT'],		
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/depotDossierTechnique/recherche/consult-all-DT-OE.html',
                    controller: 'ConsultAllDTOEController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    	.state('successPaiementDossierTechnique', {
			parent: 'entity',
			url: '/successDossierTechnique',
			data: { pageTitle: 'infochamApp.document.home.title' },

			views: {
				'content@': {
					templateUrl: 'app/entities/depotDossierTechnique/Online/successDossierTechnique.html',
					controller: 'SuccesPaiementDossierTechniqueController',


				}
			},

		})

		.state('failPaiementDossierTechnique', {
			parent: 'entity',
			url: '/failPaiementDossierTechnique',
			data: { pageTitle: 'infochamApp.document.home.title' },

			views: {
				'content@': {
					templateUrl: 'app/entities/depotDossierTechnique/Online/failDossierTechnique.html',
					controller: 'FailPaiementDossierTechniqueController',


				}
			},

		})

}
})();

