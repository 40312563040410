(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('SpecimenSignatureController',SpecimenSignatureController);
SpecimenSignatureController.$inject=['$scope', '$state', 'SpecimenSignature', 'ParseLinks'];
function SpecimenSignatureController($scope, $state, SpecimenSignature, ParseLinks){
	$scope.specimenSignatures = [];
    $scope.predicate = 'id';
    $scope.reverse = true;
    $scope.page = 1;
    $scope.loadAll = function() {
        SpecimenSignature.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
            $scope.links = ParseLinks.parse(headers('link'));
            $scope.totalItems = headers('X-Total-Count');
            $scope.specimenSignatures = result;
        });
    };
    $scope.loadPage = function(page) {
        $scope.page = page;
        $scope.loadAll();
    };
    $scope.loadAll();


    $scope.refresh = function () {
        $scope.loadAll();
        $scope.clear();
    };

    $scope.clear = function () {
        $scope.specimenSignature = {
            nomPrenom: null,
            qualite: null,
            email: null,
            id: null
        };
    };
}

})();
