(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('PersonnelDialogController',PersonnelDialogController);
PersonnelDialogController.$inject=['$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Personnel','Agenda','fileUpload'];
function PersonnelDialogController($translate, $scope, $stateParams, $uibModalInstance, entity,Personnel,Agenda,fileUpload){
	$scope.personnel = entity;
	$scope.imageUser=null;
	$scope.load = function(id) {
		Personnel.get({
			id : id
		}, function(result) {
			$scope.personnel = result;
		});
	};
	 $scope.$on('eventEmitedPhoto', function(event, data) {
        	 $scope.photo = data.message;
        	$scope.imageUser = data.image;
        });
	var onSaveSuccess = function(result) {
		$scope.$emit('infochamApp:PersonnelUpdate', result);
		var uploadUrl = "api/uploadSignature/"+result.id;
		 var file =  $scope.photo ;
			if(typeof file !='undefined'){
				var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
						function(result) {
						});

		}
		$uibModalInstance.close(result);
		$scope.isSaving = false;
	};
	$scope.typeCod = {}
	var onSaveError = function(result) {
		$scope.isSaving = false;
		if (result.status == 500) {
			swal("Opération interrompue!", "Déja existant", "error");
		} else {
			swal("Opération interrompue!", "", "error");
		}
	};
	$scope.save = function() {
		$scope.isSaving = true;

		if ($scope.personnel.id != null) {
			Personnel.update($scope.personnel, onSaveSuccess, onSaveError);
		} else {
			Personnel.save($scope.personnel, onSaveSuccess, onSaveError)
			
		}
	};
	$scope.clear = function() {
		$uibModalInstance.dismiss('cancel');
	};
}

})();
