(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DemandeSpecimenController', DemandeSpecimenController);

    DemandeSpecimenController.$inject = ['$http','SpecimenSignature','$stateParams','Agenda' ,'Principal','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita'];

    function DemandeSpecimenController($http,SpecimenSignature, $stateParams,Agenda ,Principal,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita) {


		Principal.identity().then(function(account) {
			$scope.user = account;

			$http.get("api/findAllDossJuridiqueByExpediteurByYearAndType/"+$scope.user.oeExpediteur.id).then(function(result) {
				if (result.data.length == 0) {
					swal("Opération interrompue!", "Vous n'avez pas déposé le dossier juridique", "error");
					$state.go("home");
				}

			});
			
			Agenda.findAdhesionByExpediteur($scope.user.oeExpediteur.id).then(function(result){
				if(result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='SOUS' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='MAHD' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='KAIR' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique!='MONA'){
					swal("Opération interrompue!", "Ce service n'est pas disponible", "error");
					$state.go("home");
				}
				
			});
			
			
		});
		
		
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.codePiece= 'SP';
			$scope.adhesion ={};
			$scope.expediteur = {};
			$scope.specimenSignatures = [];
			$scope.specimenSignatureDTOs = [];
			$scope.oeExpediteurDTO= {};
			$scope.demandeSpecimenDTO= {};

			$scope.customProcessDTO = {};
			Principal.identity().then(function(account) {
				$scope.user = account;
				
				
					$scope.expediteur = $scope.user.oeExpediteur;
					
	Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
						
						$scope.adhesion = result.data
					});
					
				
			});
			
			Agenda.getModeleByTypeAndCode("SP","MODELE_SP").then(function(result){
				
				$scope.modele = result.data
			});
			$scope.downloadPiecee = function(docId) {
			    if(docId != undefined){
				var downloadLink = document.createElement("a");

				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2
				.downloadDocument(docId)
				.then(
				function(result) {
				   	if(angular.equals( { status: result.status }, { status: 500 } )){
				swal(message,"Un problème s'est produit!", "error")
				}
				var fName = result
				.headers('Content-Disposition');
				var file = new Blob(
				[ result.data ],
				{
				type : 'application/pdf'
				});
				var fileURL = (window.URL || window.webkitURL)
				.createObjectURL(file);
				downloadLink.href = fileURL;
				downloadLink.download = fName;
				downloadLink.click();
				});
			    }
				} 
			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}
			var unsubscribe1 = $scope.$on('eventEmitedPiece', function(event, result) {
				$scope.pieces = result.message;
				$scope.pieceValide = result.pieceValide;

			});
			
			$scope.demandeSP = function(customProcessDTO) {
				 $scope.errors =null;
				  $scope.authenticationError = false;
				  if ($scope.pieceValide == undefined ||
							 $scope.pieceValide==false) {
							 $scope.errors="Il faut attacher toutes les piéces justificatives!"
							
							 }   
				   if($scope.errors != null){
						  $scope.authenticationError = true;
						  $("html, body").animate({scrollTop: 300},"slow");
				       }
				   if($scope.authenticationError == false){
				$scope.customProcessDTO.specimenSignatureDTOs = $scope.specimenSignatures;
				$scope.customProcessDTO.oeExpediteurDTO = $scope.expediteur;
				$scope.customProcessDTO.pieceJointeDTOs = $scope.pieces;
					swal($scope.styleSwalConfirmation,function(){
						Agenda.addSpecimen(customProcessDTO).then(function (result) {
							if(result.status==200 ){
								swal({
									position: 'center',
									type: 'success',
									title: 'Votre demande a été créée avec succès',
									showConfirmButton: true,
									timer: 10000
								})
							}
							else {
								swal("veuillez réessayer","", "error")
							}
						});
						setTimeout(function() {
							$state.go('home', null, { reload: true } );
						}, 3000);
					});
				   }
				
			}

			
		

		}
})();
