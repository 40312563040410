(function() {

	angular.module('infochamApp').controller('RoleDialogController',
			RoleDialogController);

	RoleDialogController.$inject = [ '$state', '$scope', 'entity',
			'$uibModalInstance', '$http', '$stateParams', 'Inscription2' ];

	function RoleDialogController($state, $scope, entity, $uibModalInstance,
			$http, $stateParams, Inscription2) {

		$scope.id = $stateParams.name;
		$scope.treeObj = undefined;
		$scope.authority = entity.data;

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$scope.save = function() {
			$scope.isSaving = true;
			Inscription2.addAuthority($scope.authority).then(function(result) {
				$state.go('role-management', null, {
					reload : true
				});
			});
			$scope.clear();
		};
	}
})();
