(function() {
    'use strict';
    angular
        .module('infochamApp')
        .controller('BanqueDeleteController', BanqueDeleteController);
    BanqueDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Banque'];

    function BanqueDeleteController ($scope, $uibModalInstance, entity, Banque) {

        $scope.banque = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
        	Banque.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();





