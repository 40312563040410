(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('PersonnelController',PersonnelController);
PersonnelController.$inject=['$filter', '$resource', '$scope', '$state','ParseLinks', '$rootScope', '$compile', '$translate','DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService'];
function PersonnelController($filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService){
	$scope.agenceTypes = {};
	
	function actionsHtml(data, type, full, meta) {
		return '<div class="action-center" style="margin-top:0px">' + ' <div class="col-md-1"><a ui-sref="personnel.edit({id:' + data.id + '})">' +
		'   <i class="fa fa-pencil pencil-style"></i> ' + '</a></div>' + '<div class="col-md-1"><a ui-sref="personnel.delete({id:' + data.id + '})" )"="">' + '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> ' + '</a></div>'
	}
	function actionsHtml1(data, type, full, meta) {
		return '<img style="height: 90px;width: 145px;" ng-src="data:image/JPEG;base64,'+data.signature+'" alt="Pas de photo" />'
	}
	$scope.dtOptions = DTOptionsBuilder
	.newOptions()
	.withOption('ajax', {
		url: 'api/findAllPersonnel',
		type: 'GET',
	})
.withOption('createdRow', createdRow)

.withPaginationType('full_numbers')
.withOption('order', [0, 'asc'])
.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

	'0' : {
		type : 'text'
	},
	'1' : {
		type : 'text'
	},
	'2' : {
		type : 'text'
	},

})

$scope.dtColumns = [
                    
    DTColumnBuilder.newColumn('poste').withTitle('Poste').withOption('width', '20%'),	
    DTColumnBuilder.newColumn('nom').withTitle('Nom').withOption('width', '20%'),
    DTColumnBuilder.newColumn('prenom').withTitle('Prénom').withOption('width', '20%'),
    DTColumnBuilder.newColumn(null).withTitle('Signature').notSortable().withClass('datatable-action-width')
	.renderWith(actionsHtml1),
	DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withClass('datatable-action-width')
	.renderWith(actionsHtml),
	];
$scope.search=0;
function createdRow(row, data, dataIndex) {
$compile(angular.element(row).contents())($scope);
}
	$scope.dtInstance = {};

	$scope.clear = function() {

	};
}

})();

