(function() {
	'use strict';
	angular.module('infochamApp').controller('AgendaDelivranceController',
			AgendaDelivranceController);
	AgendaDelivranceController.$inject = [ 'blockUI', 'ReportingService',
			'Inscription2', 'Certificat', 'DTColumnDefBuilder', '$rootScope',
			'fileUpload', 'Agenda', 'Referentiel', '$stateParams',
			'$translate', '$scope', 'Principal', '$state',
			'$http', '$compile', 'calendarConfig', 'moment', 'AuditsService',
			'$filter', 'DTOptionsBuilder', 'DTColumnBuilder' ];

	function AgendaDelivranceController(blockUI, ReportingService,
			Inscription2, Certificat, DTColumnDefBuilder, $rootScope,
			fileUpload, Agenda, Referentiel, $stateParams, $translate,
			 $scope, Principal, $state, $http, $compile,
			calendarConfig, moment, AuditsService, $filter, DTOptionsBuilder,
			DTColumnBuilder) {
		$scope.idAgenda = $stateParams.id;
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});
		blockUI.stop();
		Inscription2.getAgendaById($scope.idAgenda).then(function(result) {
			$scope.state = $state.current.name;
			$scope.inscription = result.data;
			$scope.idCase = result.data.idCase;
			$scope.idCreateur = result.data.createur.id;
			$scope.imageUser = result.data.createur.image;
		});
		Certificat
				.getDemandeByProcess($scope.idAgenda)
				.then(
						function(result) {
							$scope.facture = result.data.facture;
							$scope.detailDDU = result.data.detailDDU;
							$http
									.get(
											"api/listBouton/"
													+ $scope.facture.process.idCase)
									.then(
											function(result) {
												// récupérer key(id du bouton =
												// nextStep) and value(libelle
												// du bouton)
												$scope.listButton = result.data;
												// parcour :
												// ng-repeat="(key,value) in
												// listButton" pour distinguer
												// entre les ids des boutons
												// lors du clic
												var html = ' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,paiementVirement,paiementCheque)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
												// ajouter le ou les boutons
												// dans la balise boutonWK
												// l'id #boutonWK est ajouté au
												// niveau html
												angular
														.element("#boutonWK")
														.append(
																$compile(html)(
																		$scope));
											})
						});
		$scope.traitement = function(keyBouton, value, paiementVirement,
				paiementCheque) {
			Inscription2.updateProcInscription($scope.idAgenda, keyBouton,
					value).then(function(result) {
				$state.go('home', null, {
					reload : true
				});
			});
		}
		$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			var fileName = "Carte"
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2.downloadRessortissant(docId)
					.then(
							function(result) {
								if (angular.equals({
									status : result.status
								}, {
									status : 500
								})) {
									swal(message, "Un problème s'est produit!",
											"error")
								}
								var fName = "Carte de Ressortissant";
								var file = new Blob([ result.data ], {
									type : 'application/pdf'
								});
								var fileURL = (window.URL || window.webkitURL)
										.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
		}
	}

})();
