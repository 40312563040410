(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('demandecertificatdetaillovlibre', demandecertificatdetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('demandeCertificatDetailLibreCtrl', demandeCertificatDetailLibreCtrl);

	demandecertificatdetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	demandeCertificatDetailLibreCtrl.$inject = ['Certificat','$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','CustomProcessBonita' ,'DTColumnDefBuilder','idda','identity','typeprocess' ,'DemandeAdhesion'];

	function demandecertificatdetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				idda:'@',
				identity:'@',
				typeprocess:'@'
			},
			template: '<button style="background-color: transparent;width:20px;height:20px;border: none;" ng-click="openPopUp(idda,identity,typeprocess)">' +

			'</button>',
			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(idda,identity,typeprocess) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/demandeCertificatDetailTemplate.html',
							controller : 'demandeCertificatDetailLibreCtrl',
							resolve: {
								idda: function () {
									return idda;
								},
								identity: function () {
									return identity;
								},
								typeprocess: function () {
									return typeprocess;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function demandeCertificatDetailLibreCtrl (Certificat,$compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,CustomProcessBonita ,DTColumnDefBuilder,idda,identity,typeprocess ,DemandeAdhesion) {
		$scope.codeRubrique = typeprocess;
		$scope.idda = idda;
		$scope.idEntity = identity;
		 Certificat.getDemandeByProcess($scope.idda).then(function (result) {
				$scope.agenda = result.data.agenda;
				$scope.facture = result.data.facture;
				$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
				$scope.autresServices = result.data.autresServices
				$scope.certificatOrigine = result.data.certificatOrigine
				$scope.detailFacture = result.data.detailFactureList;
				 if($scope.autresServices.nbrecopieCO != undefined && $scope.autresServices.nbrecopieCO != "" && $scope.autresServices.nbrecopieCO != null){
					 $scope.nbrecopieCO= $scope.autresServices.nbrecopieCO;
				 } else {
					 $scope.nbrecopieCO="0";
				 }
				 if($scope.autresServices.nbreDmpCo != undefined && $scope.autresServices.nbreDmpCo != "" && $scope.autresServices.nbreDmpCo != null){
					 $scope.nbreDmpCo= $scope.autresServices.nbreDmpCo;
				 } else {
					 $scope.nbreDmpCo="0";
				 }
				 if($scope.autresServices.nbreVisaDoc != undefined && $scope.autresServices.nbreVisaDoc != "" && $scope.autresServices.nbreVisaDoc != null){
					 $scope.nbreVisaDoc= $scope.autresServices.nbreVisaDoc;
				 } else {
					 $scope.nbreVisaDoc="0";
				 }
				 if($scope.autresServices.nbreVisaFacture != undefined && $scope.autresServices.nbreVisaFacture != "" && $scope.autresServices.nbreVisaFacture != null){
					 $scope.nbreVisaFacture= $scope.autresServices.nbreVisaFacture;
				 } else {
					 $scope.nbreVisaFacture="0";
				 }	
			  
			  
			  Certificat.getMontant($scope.idda,$scope.nbrecopieCO , $scope.nbreDmpCo, $scope.nbreVisaDoc , $scope.nbreVisaFacture).then(function (result) {
					
				   $scope.montant=result.data;
			   });	
			  
			

				$scope.nombreAvecVirgule = function(aa)
				{
					var a =parseFloat(aa)
					return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
				}
			  
				
			});
	
		
		$scope.getTypeAdhesion = function(id) {
			var single_object =  $filter('filter')($scope.typeAdhesions, function (d) {return d.id == id;})[0];
			if (single_object != undefined && single_object != null  ) {
				$scope.demandeDadhesion.typeAdhesionLibelle = single_object.description + "(" + single_object.montant + " TND)";

			}
			return "";

		}

		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
