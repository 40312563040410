/**
 * AngularJS fixed header scrollable table directive
 * 
 * @author Jason Watmore <jason@pointblankdevelopment.com.au>
 *         (http://jasonwatmore.com)
 * @version 1.2.0
 */
(function () {
	'use strict';
	angular
	.module('widgets', [])
	.directive('wizard', wizard)
	.directive('wizardGroup', wizardGroup);
	
	wizard.$inject = ['$rootScope'];
	wizardGroup.$inject = ['$parse'];
	
	
	
	function wizard ($rootScope) {
		return{
			restrict:"E",
			replace:!1,
			transclude:!0,
			templateUrl:"app/directives/widget/wizard.tmp.html",
			scope:{
				showactionbutton: "=",
				onValidationStep:"&",
				onCompletedWizard:"&",
				config:"=",
				next:"@",
				previous:"@",
				save:"@",
				end:"@"
					},controller:["$scope",function(a){
						var b=this;b.events=[],b.exeIndex=0,b.completeSteps=[],b.ACTIVE="active",b.SUCCESS="success",b.NO_ACTIVE="no-active",b.EVT_CHANGE="onValidationStep",
						b.EVT_COMPLETED="onCompletedStep",a.steps=[],a.selectedIndex=0,a.lastStep=!1,a.showErrors=!1,a.errors=[],a.selectedItem={},
						a.selectStep=function(b){
							a.managerStep(b),a.selectedIndex=b},a.clickStep=function(c){
								b.isCompleted(c)&&(a.selectedIndex<c?a.stepAction(c):(b.exeIndex=c,b.successStepChange()))},a.stepAction=function(a){
									b.exeIndex=a;var c=b.getEvent(b.EVT_CHANGE);void 0!=c?b.fireEvent(c,b.returValidationType):b.successStepChange()},a.stepAction1=function(a){
										b.exeIndex=a;var c=b.getEvent(b.EVT_CHANGE); $("html, body").animate({scrollTop: 400},"slow");void b.successStepChange()},a.showPrev=function(){
											return 0==a.selectedIndex?!1:!0},a.managerStep=function(c){
												angular.forEach(a.steps,function(d){
													d.hd.index===c?(d.show=!0,d.hd.active=b.ACTIVE,a.selectedItem=d.hd):d.hd.index<c?(d.show=!1,d.hd.active=b.SUCCESS):(d.show=!1,d.hd.active=b.NO_ACTIVE)})},a.completeWizard=function(){
													var a=b.getEvent(b.EVT_COMPLETED);void 0!=a?b.fireEvent(a,b.returnChangeSType):b.successStepPrevChange()},b.registerEvent=function(a){
														b.events.push(a)},b.setStep=function(b){0===a.steps.length&&(a.selectedItem=b.hd),a.steps.push(b)},b.getStepsSize=function(){
															return a.steps.length},b.fireEvent=function(b,c){
																b.data(a.selectedItem,c)},b.getEvent=function(a){var c;return angular.forEach(b.events,function(b){b.name===a&&(c=b)}),c},b.successStepChange=function(){
																	a.showErrors=!1;var c=b.exeIndex;b.completeSteps.push(a.selectedIndex),a.selectedIndex=c,a.selectStep(a.selectedIndex),a.lastStep=c<a.steps.length-1?!1:!0},b.isCompleted=function(a){
																		for(var c=0;c<b.completeSteps.length;c++)if(b.completeSteps[c]===a)return!0;return!1},b.errorStepChange=function(b){
																			void 0!=b&&(a.showErrors=!0,a.errors=b),a.selectedItem.complete=!1},b.returValidationType={success:b.successStepChange,error:b.errorStepChange}}],link:function(a,b,c,d){
																				void 0!=c.onValidationStep&&d.registerEvent({name:d.EVT_CHANGE,data:a.onValidationStep()}),void 0!=c.onCompletedWizard&&d.registerEvent({name:d.EVT_COMPLETED,data:a.onCompletedWizard()})}}}
	
	
	function wizardGroup ($parse) {return{restrict:"E",require:"^wizard",template:'<div ng-transclude ng-show="show"></div>',transclude:!0,scope:{title:"@"},link:function(a,b,c,d){a.show=void 0==c.active?!1:c.active,a.hd={name:a.title,index:d.getStepsSize(),active:a.show?d.ACTIVE:d.NO_ACTIVE},d.setStep(a)}}}
     })();