(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('user-management-scan', {	
            parent: 'admin',	
            url: '/user-management-scan',	
            data: {	
                authorities: ['ROLE_ADMIN'],	
                pageTitle: 'user-management.home.title'	
            },	
            views: {	
                'content@': {	
                    templateUrl: 'app/admin/user-management/user-management-scan.html',	
                    controller: 'UserManagementScanController'	
                }	
            },	
            resolve: {	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {	
                    $translatePartialLoader.addPart('user.management'),	
                    $translatePartialLoader.addPart('Admin');	
                    return $translate.refresh();	
                }]	
            }	
        })
				.state(
						'user-management',
						{
							parent : 'admin',
							url : '/user-management',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'user-management.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management.html',
									controller : 'UserManagementController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
													$translatePartialLoader
															.addPart('user.management'),
													$translatePartialLoader
															.addPart('Admin');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'user-management-detail',
						{
							parent : 'admin',
							url : '/user/:login',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'user-management.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management-detail.html',
									controller : 'UserManagementDetailController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
													$translatePartialLoader
															.addPart('user.management'),
													$translatePartialLoader
															.addPart('Account');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'user-management.new',
						{
							parent : 'user-management',
							url : '/new',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/user-management/user-management-dialog.html',
													controller : 'UserManagementDialogController',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																id : null,
																login : null,
																firstName : null,
																lastName : null,
																email : null,
																activated : true,
																langKey : null,
																createdBy : null,
																createdDate : null,
																lastModifiedBy : null,
																lastModifiedDate : null,
																resetDate : null,
																resetKey : null,
																authorities : null
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'user-management',
																			null,
																			{
																				reload : true
																			});
														},
														function() {
															$state
																	.go('user-management');
														})
									} ]
						})
				.state(
						'user-management.edit',
						{
							parent : 'user-management',
							url : '/{login}/edit',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/user-management/user-management-dialog.html',
													controller : 'UserManagementDialogController',
													size : 'lg',
												}).result.then(
												function(result) {
													$state.go(
															'user-management',
															null, {
																reload : true
															});
												}, function() {
													$state.go('^');
												})
									} ]
						})
				.state(
						'user-management.delete',
						{
							parent : 'user-management',
							url : '/{login}/delete',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/user-management/user-management-delete-dialog.html',
													controller : 'user-managementDeleteController',
													size : 'md',
												}).result.then(
												function(result) {
													$state.go(
															'user-management',
															null, {
																reload : true
															});
												}, function() {
													$state.go('^');
												})
									} ]
						})
				.state(
						'user-management.droit',
						{
							parent : 'user-management',
							url : '/{log}/droit',
							data : {
								authorities : [],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/user-management/user-management-droit.html',
													controller : 'usermanagementDroitController',
													size : 'lg',

												}).result.then(
												function(result) {
													$state.go(
															'user-management',
															null, {
																reload : true
															});
												}, function() {
													$state.go('^');
												})
									} ]
						})

				.state(
						'new_operateur',
						{
							parent : 'admin',
							url : '/new_operateur',
							data : {
								authorities : [ 'ROLE_ADMIN' ],
								pageTitle : 'user-management.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/user-management/user-management-add.html',
									controller : 'UserManagementAddController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
													$translatePartialLoader
															.addPart('user.management'),
													$translatePartialLoader
															.addPart('Admin');
											return $translate.refresh();
										} ]
							}
						})
							    .state('user-management.droit-scan', {	
            parent: 'user-management-scan',	
            url: '/{log}/droit-scan',	
            data: {	
                authorities: [],	
            },	
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {	
                $uibModal.open({	
                    templateUrl: 'app/admin/user-management/user-management-droit.html',	
                    controller: 'user-managementDroitController',	
                    size: 'lg',	
                }).result.then(function(result) {	
                    $state.go('user-management', null, { reload: true });	
                }, function() {	
                    $state.go('^');	
                })	
            }]	
        })
	

	}
})();
