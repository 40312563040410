(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('NoteDemandeDossTechInfoComplementaireController', NoteDemandeDossTechInfoComplementaireController);

	NoteDemandeDossTechInfoComplementaireController.$inject = ['$rootScope','$uibModalInstance','Inscription2','$stateParams','$translate','$scope','Principal', '$state', '$http','$compile','AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder', 'CustomProcessBonita'];

	function NoteDemandeDossTechInfoComplementaireController ($rootScope,$uibModalInstance,Inscription2 ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder, CustomProcessBonita) {
		$scope.idProcess = $stateParams.idProcess;
		$scope.idAgenda = $stateParams.id;
		$scope.key = $stateParams.key;
		$scope.value = $stateParams.value;
		$scope.customProcess = $stateParams.customProcess;
		$scope.saveValue = $stateParams.save;
		$scope.clear=     function clear () {
			$state.go('demandeDepotDossierTechniqueConsult', {id: $scope.idAgenda}, { reload: true } );
			$uibModalInstance.dismiss('cancel');
		}
		$scope.save=   function save (note) {
			note.etapeProcess=$stateParams.key;
			Inscription2.addNote( $scope.idAgenda,note).then(function (result) {
				setTimeout(function() {
					if ($scope.saveValue != null && $scope.saveValue == true) {
						$rootScope.$emit('eventEmitedNote',{message:true});

						$uibModalInstance.dismiss('cancel');

					} 
					else if ($scope.customProcess != undefined && $scope.customProcess != null) {
						CustomProcessBonita.nextStep($scope.customProcess,$scope.key).then(function(result) {
							$state.go('home', null, { reload: true } );

						});
					}

					else {
						Inscription2.updateProcInscription($scope.idAgenda, $scope.key, $scope.value).then(function (result) {
						$state.go('home', null, { reload: true } );
					});
				}
				},500);
				$uibModalInstance.dismiss('cancel');
			});
		}
}
})();
