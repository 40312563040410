(function() {
	'use strict';
	angular
	.module('infochamApp')
	.factory('CustomServices', CustomServices);

	CustomServices.$inject = ['$http', '$rootScope'];

	function CustomServices ($http, $rootScope) {
		return {

			'getServerDate':function(){
				return $http.get('api/getServerDate');
			},
			'getUserInfos':function(id){
				return $http.get('api/userById/'+id);
			},
			
		}

	}
})();

