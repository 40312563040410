(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('CertificatDepotPhysiqueController', CertificatDepotPhysiqueController);

	CertificatDepotPhysiqueController.$inject = ['Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','$stateParams','$translate','$scope', 'Principal','$state', '$http', '$compile', 'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function CertificatDepotPhysiqueController (Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.idAgenda = $stateParams.id;
		 Certificat.getDemandeByProcess($scope.idAgenda).then(function (result) {
			 
			$scope.agenda = result.data.agenda;
			$scope.facture = result.data.facture;
			$scope.autresServices = result.data.autresServices
			$scope.detailFacture = result.data.detailFactureList;
			$scope.certificatOrigine = result.data.certificatOrigine;
			$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
			$scope.donneesIdentification = result.data.donneesIdentification;
			$scope.autresServices = result.data.autresService
			$scope.modeDepot = result.data.modeDepot;
				  
			   $http.get("api/listBouton/"+$scope.facture.process.idCase).then(function(result) {
			  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
			  	$scope.listButton=result.data;
			  	$scope.listButton1 = {};
			  	$scope.listButton1["DEPDOCANNUL"]="Annuler";
			  	$scope.listButton1["VERIFCONFDOSS"]="Déposer";
			  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
			  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value,modeDepot)" ng-disabled="form1.$invalid || isSaving" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';

			  	// ajouter le ou les  boutons dans la balise boutonWK
			  	// l'id #boutonWK est ajouté au niveau html
			  	angular.element("#boutonWK").append( $compile(html)($scope));
			  })
		        	 });

			function createdRow(row, data, dataIndex) {
				// Recompiling so we can bind Angular directive to the DT
				$compile(angular.element(row).contents())($scope);
			}
			
			/*get file dec tresor from alfreco*/
			$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");

			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2
			.downloadDocument(docId)
			.then(
			function(result) {
			   	if(angular.equals( { status: result.status }, { status: 500 } )){
			swal(message,"Un problème s'est produit!", "error")
			}
			var fName = result
			.headers('Content-Disposition');
			var file = new Blob(
			[ result.data ],
			{
			type : 'application/pdf'
			});
			var fileURL = (window.URL || window.webkitURL)
			.createObjectURL(file);
			downloadLink.href = fileURL;
			downloadLink.download = fName;
			downloadLink.click();
			});
			}
			
			  $scope.traitement = function(keyBouton,value,modeDepot){
				  
				  if(keyBouton == "DEPDOCANNUL"){
					  
					  swal({
				            title: "Confirmation",
				            text: "Êtes-vous sûr de vouloir annuler la demande de l'attestation d'origine  ?",
				            type: "warning",
				            showCancelButton: true,
				            confirmButtonColor: "#449d44",
				            confirmButtonText: "Valider",
				            closeOnConfirm: true,
				            cancelButtonText: "Annuler",    
				        },
				        function(){
							  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				        });
				  }
				  else {
				  
				  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {

					Certificat.addModeDepot(modeDepot,$scope.typeDepot ,$scope.idAgenda).then(function (result) {
					});					 
			 			$state.go('home', null, { reload: true } );
			 });
				  }
				}
		  
}
})();
