(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DemandeCertificatOrigineHorsLigneController', DemandeCertificatOrigineHorsLigneController);

    DemandeCertificatOrigineHorsLigneController.$inject = ['Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel','$stateParams','$translate','ReferentielWs','$scope', 'Principal', '$state', '$http', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

    function DemandeCertificatOrigineHorsLigneController (Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,ReferentielWs,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
    	$scope.certificat={}
    	$scope.certificat.detailFactureList = [];
    	$scope.factureList = [];
    	$scope.docFacture = [];
    	$scope.certificat.autresServices={};
    	$scope.certificat.facture={};
    	$scope.certificat.oeDestinataire={};
    	$scope.certificat.oeExpediteur={};
    	$scope.certificat.certificatOrigine={};
    	$scope.certificat.facture.dateFacture = new Date() ;
    	
    	
    	Principal.identity().then(function(account) {
    		$scope.account = account;
    		if ($scope.account == null) {
    			$state.go('login');
    		}
    		Inscription2.getUserByLogin(account.login).then(function(result) {
    			$scope.user = result.data;
    		});
    		
    	});
    	  $scope.demandeCO = function(demande,total) {
    		  $scope.errors =null;
    		  $scope.authenticationError = false;
    		  if((demande.oeDestinataire==null || demande.oeDestinataire == undefined || demande.oeDestinataire == "") && (demande.facture.dateFacture==null || demande.facture.dateFacture == undefined || demande.facture.dateFacture == "" ) && (demande.facture.numFacture==null || demande.facture.numFacture == undefined || demande.facture.numFacture == "" ) && ($scope.factureList.length==0)){
    			  $scope.errors ="Veuillez remplir tous les champs obligatoires";
    		  }
    		  else if(demande.certificatOrigine.typeCo==null || demande.certificatOrigine.typeCo == undefined || demande.certificatOrigine.typeCo == "" ){
    			  $scope.errors ="Type Certificat d'origine est requis.";
    		  }
    		  else if(demande.expediteur==null || demande.expediteur == undefined || demande.expediteur == "" ){
    			  $scope.errors ="Raison Sociale est requis.";
    		  }
    		  else  if(demande.certificatOrigine.producteur == null || demande.certificatOrigine.producteur  == undefined || demande.certificatOrigine.producteur  == "" ){
    			  $scope.errors ="Producteur est requis.";
    		  }
    		  else  if(demande.certificatOrigine.adressProducteur == null || demande.certificatOrigine.adressProducteur  == undefined || demande.certificatOrigine.adressProducteur  == "" ){
    			  $scope.errors ="Adresse Producteur est requis.";
    		  }
    		  else  if(demande.oeDestinataire.importateur==null || demande.oeDestinataire.importateur == undefined || demande.oeDestinataire.importateur == "" ){
    			  $scope.errors ="Destinataire est requis.";
    		  }
    		  else  if(demande.oeDestinataire.adresse==null || demande.oeDestinataire.adresse == undefined || demande.oeDestinataire.adresse == "" ){
    			  $scope.errors ="Adresse Destinataire est requis.";
    		  }
    		  else if(demande.facture==null || demande.facture == undefined || demande.facture == "" ){
    			  $scope.errors ="Date facture est requis.";
    		  }
    		  else	  if(demande.facture.dateFacture==null || demande.facture.dateFacture == undefined || demande.facture.dateFacture == "" ){
    				  $scope.errors ="Date facture est requis.";
    		  }  
    		  else  if(demande.facture.numFacture==null || demande.facture.numFacture == undefined || demande.facture.numFacture == "" ){
    			  $scope.errors ="Numéro de la facture est requis.";
    		  }
    		  else  if($scope.factureList.length==0){
    			  $scope.errors ="Veuillez remplir Détail facture    ";
    		  }
    	


    		  if($scope.errors != null){
    			  $scope.authenticationError = true;
    			  $("html, body").animate({scrollTop: 300},"slow");
    	       }
    		  if($scope.authenticationError == false){
    			  


    		demande.detailFactureList = $scope.factureList;
            demande.oeExpediteur=$scope.certificat.expediteur;
    		Agenda.addCertificatHL(demande).then(function (result) {
    		 	if(result.status==200 ){
    		 swal({
    		  position: 'center',
    		  type: 'success',
    		  title: 'Votre demande a été ajoutée avec succès',
    		  showConfirmButton: false,
    		  timer: 2000
    		})
    		 	}
    		else {
    			swal("veuillez réessayer","", "error")
    		}
    	});
    			 setTimeout(function() {
    		$state.go('home', null, { reload: true } );
    			 }, 3000);
    				
    	  }
    	}
    	
    	  $scope.$on('eventEmitedFacture', function(event, data) {
    		  $scope.docFacture = data.message;
    	  });
    	  
    	  $rootScope.$on('eventEmitedDetailFacture', function(event, data) {
    		  $scope.detailFacture = data.message;
    		  $scope.factureList.push($scope.detailFacture);
    	  });
    	  
    			$scope.dtOptionsConsolide = DTOptionsBuilder
    		.newOptions()
    		// or here
    		.withOption('createdRow', createdRow)
    		.withPaginationType('full_numbers')
    	.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
    			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
    			.withOption('fnFooterCallback',
    					function(nRow, aasData, iStart, iEnd, aiDisplay) {
    				var columnas = [5]; //the columns you wish to add
    				var secondRow = $(nRow).next()[0];
    				for (var j in columnas) {
    					var columnaActual = columnas[j];
    					var total = 0;
    					for (var i = 0; i < aasData.length; i++) {
    						total = total + parseFloat($scope.factureList[i].nombreUnite * $scope.factureList[i].valeurUnite);
    					}
    					
    					$scope.totalSystem=total;
    					
    					$($(nRow).children().get(columnaActual)).html(total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",") + " TND");
    					
    					} // end
    				
    			})
    			$scope.dtColumnsConsolide = [

    				DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '15%'),
    				DTColumnDefBuilder.newColumnDef(1).withTitle("Qté").withOption('width', '15%'),
    				DTColumnDefBuilder.newColumnDef(2).withTitle("Valeur (TND)").withOption('width', '15%').notSortable(),
    				DTColumnDefBuilder.newColumnDef(3).withTitle("Nbre / Nature de colis").withOption('width', '15%'),
    				DTColumnDefBuilder.newColumnDef(4).withTitle("Poids net / brut").withOption('width', '15%'),
    				DTColumnDefBuilder.newColumnDef(5).withTitle("Total ligne (TND)").withOption('width', '15%'),
    				DTColumnDefBuilder.newColumnDef(6).withTitle("Actions").withOption('width', '10%')

    				];
    					
    					$scope.nombreAvecVirgule = function(aa)
    					{
    						var a =parseFloat(aa)
    						return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
    					}
    			

    			function separateur(aa) {
    				aa = number_format(aa[0], 0, ' ', ' ');
    				return ("aa");
    				}
    		function createdRow(row, data, dataIndex) {
    			// Recompiling so we can bind Angular directive to the DT
    			$compile(angular.element(row).contents())($scope);
    		}
    	  
    		 /*get file dec tresor from alfreco*/
    		$scope.downloadDecharge = function(docId) {
    		var downloadLink = document.createElement("a");

    		document.body.appendChild(downloadLink);
    		downloadLink.style = "display: none";
    		Inscription2
    		.downloadDocument(docId)
    		.then(
    		function(result) {
    		   	if(angular.equals( { status: result.status }, { status: 500 } )){
    		swal(message,"Un problème s'est produit!", "error")
    		}
    		var fName = result
    		.headers('Content-Disposition');
    		var file = new Blob(
    		[ result.data ],
    		{
    		type : 'application/pdf'
    		});
    		var fileURL = (window.URL || window.webkitURL)
    		.createObjectURL(file);
    		downloadLink.href = fileURL;
    		downloadLink.download = fName;
    		downloadLink.click();
    		});
    		}
    		
    		 $scope.traitement = function(keyBouton,value){
    			  Inscription2.updateProcInscription($scope.inscription.id,keyBouton,value).then(function (result) {
    		 			$state.go('home', null, { reload: true } );
    		 });
    			}
    		 
    		 $scope.removeItem = function(index){
    			    $scope.items.splice(index, 1);
    			  }
    		 
    		 $scope.remove = function(detail) {
    				if(detail != undefined){
    				$rootScope.$broadcast('eventEmitedDeletFacture',{message:detail});
    				}
    		};
    		$scope.$on('eventEmitedDeletFacture', function(event, data) {
    			  $scope.factureList.splice(data.message, 1);

    		  });
    		
    		$scope.$on('eventEmitedEditFacture', function(event, data) {
    		$scope.factureUpdate = data.message;
    		$scope.index = data.index;
    		$scope.factureList[$scope.index] = $scope.factureUpdate;
    		  });
    		$scope.update = function(index) {
    			  if (index != undefined) {
    				  $scope.factureUpdate = $scope.factureList[index];
    				  $state.go( 'factureHL-detail-edit', {idExpediteur : $scope.certificat.expediteur.id, facture :$scope.factureUpdate, index : index });
    			  }
    			}
    		
    		
    		$scope.lovModel7 = 'api/referentiels/getTypeCO';// this will be
    		// the LOV
    		// datasource
    		$scope.lovTitle7 = 'Liste des types de certificat d\'origine';
    		$scope.lovColumnList7 = [  'Libellé' ];
    		$scope.lovFieldList7 = [ 'libelle' ];
    		// function to be called when you click "choose" button
    		$scope.lovCallBack7 = function(result) {
    			$scope.certificat.certificatOrigine.typeCo = result;
    		}
    		
    		ReferentielWs.getAllRaisonSocialeLibelle().then(function(result){
    			$scope.raisonSociales=result.data;
    		})
    		
    		
    		$scope.lovModel2 = 'api/getAllExpediteur';// this will be
      		// the LOV
      		// datasource
      		$scope.lovTitle2 = 'Liste des raisons sociales';
      		$scope.lovColumnList2 = [' Raison Sociale '];
      		$scope.lovFieldList2 = ['raisonSociale'];
      		// function to be called when you click "choose" button
      		$scope.lovCallBack2 = function(result) {
      			$scope.certificat.expediteur = result;
      		}
    		
}
})();
