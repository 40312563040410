(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('SettingScanController', SettingScanController);

	SettingScanController.$inject = ['$scope', '$state', 'SettingScan','ParseLinks', '$controller', '$cookies', '$http'];

	function SettingScanController ($scope, $state, SettingScan, ParseLinks, $controller, $cookies, $http) {
    	$controller('ScannerApp', {$scope: $scope})
   $scope.scannerPort = $cookies.get('scannerAppConfigPort');
        $scope.save = function () {
        	$cookies.put('scannerAppConfigPort', $scope.scannerPort)
        
        };

        var scanTestLock = 0;
        $scope.executeTest = function (){
        	if (scanTestLock == 0){
        		angular.element("#scan-app-log").append("Lancement de l'application de test...<br/>")
            	$scope.startScannerApp("testmode", "", {
            		onopen: function(e) {
    					angular.element("#scan-app-log").append("Connexion Websocket...<br/>")
    				},
    				onmessage: function(e) {
    					if(e && e.data) {
    						if(e.data == 'status:OK') {
    							angular.element("#scan-app-log").append("OK<br/>");
    							angular.element("#scan-app-log").append("Envoie de message 'ping' par Websocket...")
    							$scope.scannerWs.send('ping');
    						} else if(e.data == 'pong') {
    							angular.element("#scan-app-log").append("OK<br/>");
    							angular.element("#scan-app-log").append("Message 'pong' reçu<br/>");
    							angular.element("#scan-app-log").append("Fermeture de la connextion Websocket et arrêt de l'application<br/>");
    							$scope.scannerWs.send('exit');
    						}
    					}
    					 return false;
    				},
    				onerror: function(error) {
    					angular.element("#scan-app-log").append("<span class='error'>Erreur Websocket : " + error + "</span><br/>")
    				},
    				onclose: function() {
    					angular.element("#scan-app-log").append("Fin de l'exécution du test<br/>")
    					scanTestLock = 0;
    				}
    			});
        		angular.element("#scan-app-log").append("OK<br/>")
        	}
        }
        
        $scope.testScan = function(){
        	$scope.idInstanceEntite=5;
        	$scope.typeentite='ADHES';
        	$scope.naturePieceId=7;
        	var param = "idEntity="+$scope.idInstanceEntite+"&typeEntity="+$scope.typeentite+"&naturePieceJointeId="+$scope.naturePieceId;
        	$scope.startScannerApp("adhesion", param);
        }
        
    	$scope.downloadDecharge = function(idPiece){
    		var getUrl = 'api/decharge/download/'+idPiece;
    		$http.get(getUrl, {	responseType : 'arraybuffer' })
    		.success(function(data) {
    			var file = new Blob([ data ], {	type : 'application/pdf'	});
    			var fileURL = URL.createObjectURL(file);
    			window.open(fileURL);	});
    	}
    	
    	
    	 $scope.testScanEnMasse = function(){
         	$scope.idEntity=5;
         	$scope.typeEntity="ADHES";
 			var param = "idEntity="+$scope.idEntity+"&typeEntity="+$scope.typeEntity;
         	$scope.startScannerApp("bulk-adhesion", param);
         };
    }
})();

