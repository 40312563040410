(function() {
'use strict';
angular
	.module('infochamApp')
	 .config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('numeroAttestation', {
        parent: 'entity',
        url: '/numeroSequentiels',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.numeroAttestation.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/numeroAttestation/numeroAttestations.html',
                controller: 'NumeroAttestationController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('numeroAttestation');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    .state('numeroAttestation.detail', {
        parent: 'entity',
        url: '/numeroSequentiel/{id}',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.numeroAttestation.detail.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/numeroAttestation/numeroAttestation-detail.html',
                controller: 'NumeroAttestationDetailController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('numeroAttestation');
                return $translate.refresh();
            }],
            entity: ['$stateParams', 'NumeroAttestation', function($stateParams, NumeroAttestation) {
                return NumeroAttestation.get({id : $stateParams.id});
            }]
        }
    })
    .state('numeroAttestation.new', {
        parent: 'numeroAttestation',
        url: '/new',
        data: {
            authorities: ['ROLE_ADMIN'],
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/numeroAttestation/numeroAttestation-dialog.html',
                controller: 'NumeroAttestationDialogController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            type: null,
                            numero: null,
                            libelle: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(result) {
                $state.go('numeroAttestation', null, { reload: true });
            }, function() {
                $state.go('numeroAttestation');
            })
        }]
    })
    .state('numeroAttestation.edit', {
        parent: 'numeroAttestation',
        url: '/{id}/edit',
        data: {
            authorities: ['ROLE_ADMIN'],
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/numeroAttestation/numeroAttestation-dialog.html',
                controller: 'NumeroAttestationDialogController',
                size: 'lg',
                resolve: {
                    entity: ['NumeroAttestation', function(NumeroAttestation) {
                        return NumeroAttestation.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('numeroAttestation', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    .state('numeroAttestation.delete', {
        parent: 'numeroAttestation',
        url: '/{id}/delete',
        data: {
            authorities: ['ROLE_ADMIN'],
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/numeroAttestation/numeroAttestation-delete-dialog.html',
                controller: 'NumeroAttestationDeleteController',
                size: 'md',
                resolve: {
                    entity: ['NumeroAttestation', function(NumeroAttestation) {
                        return NumeroAttestation.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('numeroAttestation', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    });	
}

})();