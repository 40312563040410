(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ConsultAllCOController', ConsultAllCOController);

	ConsultAllCOController.$inject = ['ReferentielWs' ,'ReportingService' ,'Inscription2','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$http','$state', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function ConsultAllCOController (ReferentielWs ,ReportingService ,Inscription2,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal,  $http,$state, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		
		$scope.co = {}
		$scope.typeProcess = "CO";
		$scope.dtInstance1 = {};
		
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});
		 	$scope.dtOptions = DTOptionsBuilder.fromSource('').withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>").
			withBootstrap().withLanguageSource( $translate.instant('global.datatable'));

			$scope.dtColumns = [  
			DTColumnBuilder.newColumn('agenda.oeExpediteur').withTitle($translate.instant('Code client')).withOption('width', '5%').renderWith(function(data, type) {
					return '<label class="directive-lien"> '+data.codeClient+'<span oeexpediteurdetaillovlibre idoe="'+data.id+'" ></span></label>'
			}),		
			DTColumnBuilder.newColumn('agenda.oeExpediteur.raisonSociale').withTitle("Raison sociale").withOption('width', '15%').notSortable(),
			DTColumnBuilder.newColumn('agenda.oeExpediteur.adresseSiege.gouvernorat.libelle').withTitle("Gouvernorat").withOption('width', '15%').notSortable(),
			DTColumnBuilder.newColumn('agenda.reference').withTitle("Référence CO").withOption('width', '10%').notSortable(),
			DTColumnBuilder.newColumn('agenda.libelleStatut').withTitle("Statut").withOption('width', '20%').notSortable(),
			];	
		 
		 
		 $scope.change = function(oeExpediteurSelected) {
				if (oeExpediteurSelected != undefined  || oeExpediteurSelected != null) {
					$scope.oeExpediteur = oeExpediteurSelected;

				}
			}

			$scope.searchAspect = function(searchStr) {
				if(searchStr.val == undefined || searchStr.val == null  || searchStr.val == "") {
					var searchStrEncoded = null;
				} else {
					var searchStrEncoded = searchStr.val// escape(searchStr);
				}
				return $http.get("api/getAllExpediteurDTOByRaison/"+ searchStrEncoded).then(function(data) {
					$scope.states = data.data;
					return data.data;
				});

			};
		 
		 $scope.valider= function(numeroCo,du,au, co, statut){
			 var dStart="";
			 var dFin="";
			 var dNumeroCo ="";
			 if(du == undefined || du ==  null){
				 dStart=-1;
			 } if(au == undefined || au == null){
				 dFin=-1;
			 } if(numeroCo == ""  || numeroCo == undefined || numeroCo ==  null){
				 dNumeroCo=-1;
			 } if(co.raisonSociale != undefined && co.raisonSociale != ""){
				 $scope.raisonSocCritere= co.raisonSociale.raisonSociale;
			 } else {
				 $scope.raisonSocCritere="-1";
			 } if(co.gouvernorat != undefined && co.gouvernorat != ""){
				 $scope.gouvernoratCritere=co.gouvernorat.libelle;
			 } else {
				 $scope.gouvernoratCritere="-1";
			 } if(co.typeCo != undefined && co.typeCo != ""){
				 $scope.typeCoCritere=co.typeCo.codeRubrique;
			 } else {
				 $scope.typeCoCritere="-1";
			 } if(co.produit != undefined && co.produit != ""){
				 $scope.produitCritere=co.produit.designation;
			 } else {
				 $scope.produitCritere="-1";
			 } if(co.localite != undefined && co.localite != ""){
				 $scope.localiteCritere=co.localite.codeRubrique;
			 } else {
				 $scope.localiteCritere="-1";
			 } if(statut != undefined && statut != ""){
				 $scope.statutCritere=statut.codeRubrique;
			 } else {
				 $scope.statutCritere="-1";
			 } if(co.codeClient != undefined && co.codeClient != ""){
				 $scope.codeClientCritere= co.codeClient;
			 } else {
				 $scope.codeClientCritere="-1";
			 }
			 
			 
			 $scope.inscriptionByDateList=[];
			 if(dStart != -1){
			 dStart = moment(du).format('YYYY-MM-DD');
			 }
			 if(dFin != -1){
				 dFin = moment(au).format('YYYY-MM-DD');
			 }
			 if(dNumeroCo != -1){
				 dNumeroCo = numeroCo;
			 }
			 
			 
				// or here
				$scope.dtOptions = DTOptionsBuilder.newOptions().withOption('ajax', {
					url: 'api/getAllCOByCritereDatatable/'+$scope.codeClientCritere+"/"+dNumeroCo+"/"+dStart+"/"+dFin+"/"+$scope.raisonSocCritere+"/"+$scope.gouvernoratCritere+"/"+$scope.typeCoCritere+"/"+$scope.produitCritere+"/"+$scope.statutCritere,
				    type: 'GET'
				})
					
				.withDataProp('data')
				.withDisplayLength(10)
				.withOption('processing', true)
				.withOption('serverSide', true)
				.withOption('autoWidth', false)
				.withOption('order',[[1, 'desc']])
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
				.withLightColumnFilter({
				'0' : {
					type : 'text'
				},
				'1' : {
					type : 'text'
				},
				'2' : {
					type : 'text'
				},
				'3' : {
					type : 'text'
				},
				'4' : {
					type : 'text'
				},
				'5' : {
					type : 'text'
				},
				})
				$scope.dtColumns = [  
				DTColumnBuilder.newColumn('agenda.oeExpediteur').withTitle($translate.instant('Identifiant Unique')).withOption('width', '5%').renderWith(function(data, type) {
						return '<label class="directive-lien"> '+data.numIde+'<span oeexpediteurdetaillovlibre idoe="'+data.id+'" ></span></label>'
				}),				
				DTColumnBuilder.newColumn('agenda.dateCreation').withTitle('Date').withOption('width', '20%').renderWith(function(data, type) {
					return $filter('date')(data, 'dd/MM/yyyy');
				}),
				DTColumnBuilder.newColumn('agenda.oeExpediteur.raisonSociale').withTitle("Raison sociale").withOption('width', '15%'),
				DTColumnBuilder.newColumn('agenda.oeExpediteur.adresseSiege.gouvernorat.libelle').withTitle("Gouvernorat").withOption('width', '15%'),
				DTColumnBuilder.newColumn('agenda').withTitle($translate.instant('Référence CO')).withOption('width', '2%').renderWith(function(data, type) {
					return '<label class="directive-lien"> '+data.reference+'<span demandecertificatdetaillovlibre typeprocess="'+data.typeProcess.codeRubrique+'"  idda="'+data.id+'" identity="'+data.idEntity+'"  ></span></label>'
				}),
				DTColumnBuilder.newColumn('agenda.libelleStatut').withTitle("Statut").withOption('width', '20%'),
				];	
			 
			 function oeExp (data, type, full, meta){
					return '<label class="directive-lien"> '+data+'<span oeexpediteurdetaillovlibre idoe="'+data+'" ></span></label>'
				}
	 		}
		 
			$scope.change = function(oeExpediteur) {
				if (oeExpediteur != undefined  || oeExpediteur != null) {
					$scope.oeExpediteur = oeExpediteur;

				}
			}
			if ($scope.oeExpediteur != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T3.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.oeExpediteur.id]
				})
			}
		$scope.search=0;
		function createdRow(row, data, dataIndex) {
		$compile(angular.element(row).contents())($scope);
		}
		
		
		$scope.lovModel2 = 'api/getAllExpediteur';// this will be
	 	// the LOV
	 	// datasource
	 	$scope.lovTitle2 = 'Liste des raisons sociales';
	 	$scope.lovColumnList2 = [' Raison Sociale '];
	 	$scope.lovFieldList2 = ['raisonSociale'];
	 	// function to be called when you click "choose" button
	 	$scope.lovCallBack2 = function(result) {
	 	$scope.co.raisonSociale = result;
	 	}
		
	 	$scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
		// the LOV
		// datasource
		$scope.lovTitle3 = 'Liste des régions';
		$scope.lovColumnList3 = [  'Libellé' ];
		$scope.lovFieldList3 = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack3 = function(result) {
		$scope.co.gouvernorat = result;
		$scope.$emit('eventLocaliteAdrSiege',result)
		}
		
		$scope.lovModel4 = 'api/referentiels/localite/all';// this will be
		// the LOV
		// datasource
		$scope.lovTitle4 = 'Liste des villes';
		$scope.lovColumnList4 = [  'Libellé' ];
		$scope.lovFieldList4 = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack4 = function(result) {
		$scope.co.localite = result;
		}
		
		$scope.lovModel7 = 'api/referentiels/getTypeCO';// this will be
		// the LOV
		// datasource
		$scope.lovTitle7 = 'Liste des types de certificat d\'origine';
		$scope.lovColumnList7 = [  'Libellé' ];
		$scope.lovFieldList7 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack7 = function(result) {
		$scope.co.typeCo = result;
		}
		
		$scope.lovModelStatut = 'api/referentiels/getSousRefByCode/ETPROC/'+$scope.typeProcess;// this will be
		// the LOV
		// datasource
		$scope.lovTitleStatut = 'Liste des villes';
		$scope.lovColumnListStatut = [  'Libellé' ];
		$scope.lovFieldListStatut = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBackStatut = function(result) {
		$scope.statut = result;
		}
				
		
		$scope.lovModel8 = 'api/getAllProduitDes';// this will be
		// the LOV
		// datasource
		$scope.lovTitle8 = 'Liste des produits';
		$scope.lovColumnList8 = [  'Désignation' ];
		$scope.lovFieldList8 = [ 'designation' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack8 = function(result) {
		$scope.co.produit = result;
		}
		
		
		ReferentielWs.getAllRaisonSocialeLibelle().then(function(result){
			$scope.raisonSociales=result.data;
		})
		  		
		$scope.myAccordionStatut = true;
		$scope.opened = function() {
			if ($scope.myAccordionStatut) {
				return "#collapse2";
			}
		}

		$scope.reset = function() {
			$state.go('consultAllCO', null, {
				reload : true
			});

		}
		$(function() {
			var active = true;

			$('#collapse-init').click(function() {
				if (active) {
					active = false;
					$('.panel-collapse').collapse('show');
					$('.panel-title').attr('data-toggle',
					'');
					$(this).text(
					'Enable accordion behavior');
				} else {
					active = true;
					$('.panel-collapse').collapse('hide');
					$('.panel-title').attr('data-toggle',
					'collapse');
					$(this).text(
					'Disable accordion behavior');
				}
			});

			$('#myAccordion').on('show.bs.collapse', function() {
				if (active) {
					$('#myAccordion2 .in').collapse('hide');
				}
			});
			$('#myAccordion').on('hide.bs.collapse', function() {
			});

			$('#myAccordion2').on('show.bs.collapse', function() {
				$scope.myAccordionStatut = false;
				if (active) {
					$('#myAccordion .in').collapse('hide');
				}
			});
			$('#myAccordion2').on('hide.bs.collapse', function() {
				$scope.myAccordionStatut = true;
			});

		});
		$('#myAccordion .panel-collapse').on('shown.bs.collapse',function() 
				{$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-plus").addClass(
			"fa fa-minus");
				});

		// The reverse of the above on hidden event:

		$('#myAccordion .panel-collapse').on('hidden.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-minus").addClass(
			"fa fa-plus");
			// $scope.statut=false;
		});
		$('#myAccordion2 .panel-collapse').on('shown.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-plus").addClass(
			"fa fa-minus");
		});

		// The reverse of the above on hidden event:

		$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-minus").addClass(
			"fa fa-plus");
			// $scope.statut=false;
		});		
		  		
}
})();
