(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('demandeinscreptiondetaillovlibre', demandeinscreptiondetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('demandeInscreptionDetailLibreCtrl', demandeInscreptionDetailLibreCtrl);

	demandeinscreptiondetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	demandeInscreptionDetailLibreCtrl.$inject = ['Certificat','$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','CustomProcessBonita' ,'DTColumnDefBuilder','idda','identity','typeprocess' ,'DemandeAdhesion'];

	function demandeinscreptiondetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				idda:'@',
				identity:'@',
				typeprocess:'@'
			},
			template: '<button style="background-color: transparent;width:20px;height:20px;border: none;" ng-click="openPopUp(idda,identity,typeprocess)">' +

			'</button>',
			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(idda,identity,typeprocess) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/demandeInscreptionDetailTemplate.html',
							controller : 'demandeInscreptionDetailLibreCtrl',
							resolve: {
								idda: function () {
									return idda;
								},
								identity: function () {
									return identity;
								},
								typeprocess: function () {
									return typeprocess;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function demandeInscreptionDetailLibreCtrl (Certificat,$compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,CustomProcessBonita ,DTColumnDefBuilder,idda,identity,typeprocess ,DemandeAdhesion) {
		$scope.codeRubrique = typeprocess;
		$scope.idda = idda;
		$scope.idEntity = identity;
		CustomProcessBonita.remplireCustomProcess($scope.idda).then(function (result) {
				$scope.agenda = result.data.agenda;
				$scope.nombreAvecVirgule = function(aa)
				{
					var a =parseFloat(aa)
					return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
				}
			  
				
			});
	
		
		$scope.getTypeAdhesion = function(id) {
			var single_object =  $filter('filter')($scope.typeAdhesions, function (d) {return d.id == id;})[0];
			if (single_object != undefined && single_object != null  ) {
				$scope.demandeDadhesion.typeAdhesionLibelle = single_object.description + "(" + single_object.montant + " TND)";

			}
			return "";

		}

		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
