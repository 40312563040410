(function() {
	'use strict';
	angular.module('infochamApp').controller('AgendaDetailController',
			AgendaDetailController);
	AgendaDetailController.$inject = [ 'blockUI', 'Agenda', 'Inscription2',
			'$stateParams', '$translate', '$scope',
			'Principal', '$state', '$http', '$compile', 'calendarConfig',
			'moment', 'AuditsService', '$filter', 'DTOptionsBuilder',
			'DTColumnBuilder' ];
	function AgendaDetailController(blockUI, Agenda, Inscription2,
			$stateParams, $translate, $scope, Principal,
			$state, $http, $compile, calendarConfig, moment, AuditsService,
			$filter, DTOptionsBuilder, DTColumnBuilder) {
		$scope.dtInstance = {};
		$scope.idAgenda = $stateParams.id;
		blockUI.stop();
		Inscription2
				.getAgendaById($scope.idAgenda)
				.then(
						function(result) {
							$scope.state = $state.current.name;
							$scope.inscription = result.data;
							$scope.idCase = result.data.idCase;
							$scope.idCreateur = result.data.createur.id;
							$scope.imageUser = result.data.createur.image;
							Agenda
									.getRessortissant($scope.idAgenda)
									.then(
											function(result) {
												$scope.ressortissant = result.data;

												setTimeout(
														function() {
															blockUI.stop();
															if ($scope.idCase != null) {
																// getList
																// Button
																// workflow
																$http
																		.get(
																				"api/listBouton/"
																						+ $scope.idCase)
																		.then(
																				function(
																						result) {
																					// récupérer
																					// key(id
																					// du
																					// bouton
																					// =
																					// nextStep)
																					// and
																					// value(libelle
																					// du
																					// bouton)
																					$scope.listButton = result.data;
																					// parcour
																					// :
																					// ng-repeat="(key,value)
																					// in
																					// listButton"
																					// pour
																					// distinguer
																					// entre
																					// les
																					// ids
																					// des
																					// boutons
																					// lors
																					// du
																					// clic
																					$scope.listButton1 = {};
																					// for
																					// (var
																					// i =
																					// 0,
																					// keys
																					// =
																					// Object.keys($scope.listButton),
																					// ii =
																					// keys.length;
																					// i <
																					// ii;
																					// i++)
																					// {
																					$scope.button = {};
																					// $scope.key=keys[i];
																					// $scope.value=$scope.listButton[keys[i]];
																					// $scope.button.key
																					// =
																					// $scope.key;
																					// $scope.button.value
																					// =
																					// $scope.value;
																					if ($scope.ressortissant.nvRessortissant == 'O'
																							&& $scope.ressortissant.carteRessortissant == 'O') {
																						// if($scope.key
																						// !=
																						// "DEMVALID"){
																						// var
																						// key
																						// =
																						// $scope.key
																						// var
																						// value
																						// =
																						// $scope.value;
																						// $scope.listButton1[key]
																						// =
																						// value;
																						// }
																						$scope.listButton1["ANNULFIN"] = "Annuler";
																						$scope.listButton1["COMPLEMENT"] = "Demander des informations complémentaires";
																						$scope.listButton1["VERIFPAYINSCRIPTION"] = "Vérifier Paiement";
																					} else if ($scope.ressortissant.nvRessortissant == 'N'
																							|| $scope.ressortissant.carteRessortissant == 'N') {
																						// if($scope.key
																						// !=
																						// "VERIFPAYINSCRIPTION"){
																						// var
																						// key
																						// =
																						// $scope.key
																						// var
																						// value
																						// =
																						// $scope.value;
																						// $scope.listButton1[key]
																						// =
																						// value;
																						// }
																						$scope.listButton1["ANNULFIN"] = "Annuler";
																						$scope.listButton1["COMPLEMENT"] = "Demander des informations complémentaires";
																						$scope.listButton1["DEMVALID"] = "Valider";
																					}
																					// }
																					var html = ' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
																					angular
																							.element(
																									"#boutonWK")
																							.append(
																									$compile(
																											html)
																											(
																													$scope));

																				})
															}
															$scope.dtOptions = DTOptionsBuilder
																	.newOptions()
																	.withOption(
																			'ajax',
																			{
																				url : 'api/getCommentaireInscription/'
																						+ $scope.idAgenda,
																				type : 'GET',

																			})
																	.withOption(
																			'createdRow',
																			createdRow)
																	.withPaginationType(
																			'full_numbers')
																	.withOption(
																			'order',
																			[
																					0,
																					'asc' ])
																	.withDOM(
																			"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
																	.withBootstrap()
																	.withLanguageSource(
																			$translate
																					.instant('global.datatable'))
																	.withLightColumnFilter(
																			{

																				'0' : {
																					type : 'text'
																				},
																				'1' : {
																					type : 'text'
																				},
																				'2' : {
																					type : 'text'
																				}

																			})

															$scope.dtColumns = [
																	DTColumnBuilder
																			.newColumn(
																					'note')
																			.withTitle(
																					'Description')
																			.withOption(
																					'width',
																					'30%'),
																	DTColumnBuilder
																			.newColumn(
																					'dateCreation')
																			.withTitle(
																					'Date')
																			.withOption(
																					'width',
																					'30%')
																			.renderWith(
																					function(
																							data,
																							type) {

																						return $filter(
																								'date')
																								(
																										data,
																										'dd/MM/yyyy');
																					}),

															];

															$scope.dtOptions1 = DTOptionsBuilder
																	.newOptions()
																	.withOption(
																			'ajax',
																			{
																				url : 'api/findPieceJointe/'
																						+ $scope.idCreateur,
																				type : 'GET',

																			})
																	.withOption(
																			'createdRow',
																			createdRow1)
																	.withPaginationType(
																			'full_numbers')
																	.withOption(
																			'order',
																			[
																					0,
																					'asc' ])
																	.withDOM(
																			"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
																	// }
																	.withBootstrap()
																	.withLanguageSource(
																			$translate
																					.instant('global.datatable'))
																	.withLightColumnFilter(
																			{

																				'0' : {
																					type : 'text'
																				},
																				'1' : {
																					type : 'text'
																				},
																				'2' : {
																					type : 'text'
																				}

																			})

															$scope.dtColumns1 = [
																	DTColumnBuilder
																			.newColumn(
																					null)
																			.withTitle(
																					'Fichier')
																			.notSortable()
																			.withOption(
																					'width',
																					'40%')
																			.renderWith(
																					actionsHtml1),
																	DTColumnBuilder
																			.newColumn(
																					'commentaire')
																			.withTitle(
																					'Nature')
																			.withOption(
																					'width',
																					'30%'),

															];
															function actionsHtml(
																	data, type,
																	full, meta) {

																return '<td><strong> <a ng-click="test('
																		+ data
																		+ ')">'
																		+ data.nom
																		+ '</a></strong></td>'
															}

															function actionsHtml1(
																	data, type,
																	full, meta) {

																return '   <td><strong> <a ng-click="downloadDecharge('
																		+ data.id
																		+ ')">'
																		+ data.nom
																		+ '</a></strong></td>'
															}

															$scope.search = 0;
															function createdRow1(
																	row, data,
																	dataIndex) {
																$compile(
																		angular
																				.element(
																						row)
																				.contents())
																		($scope);
															}

														}, 1000);
											})
						});

		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					url : 'api/getCommentaireInscription/' + $scope.idAgenda,
					type : 'GET',

				})
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					}

				})

		$scope.dtColumns = [
				DTColumnBuilder.newColumn('note').withTitle('Description')
						.withOption('width', '30%'),
				DTColumnBuilder.newColumn('dateCreation').withTitle('Date')
						.withOption('width', '30%').renderWith(
								function(data, type) {

									return $filter('date')(data, 'dd/MM/yyyy');
								}),

		];

		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		$scope.traitement = function(keyBouton, value) {

			if (keyBouton == "COMPLEMENT") {
				$state.go('commentaire-infoComplementaire', {
					id : $scope.idAgenda,
					idProcess : $scope.idCase,
					key : keyBouton,
					value : value
				}, {
					reload : true
				});
			}

			else if (keyBouton == "ANNULFIN") {
				swal(
						{
							title : "Confirmation",
							text : "Êtes-vous sûr de vouloir annuler la demande d'inscription  ?",
							type : "warning",
							showCancelButton : true,
							confirmButtonColor : "#449d44",
							confirmButtonText : "Valider",
							closeOnConfirm : true,
							cancelButtonText : "Annuler",
						}, function() {

							Inscription2.updateProcInscription($scope.idAgenda,
									keyBouton, value).then(function(result) {

								$state.go('home', null, {
									reload : true
								});

							});
						});
			} else {
				Inscription2.updateProcInscription($scope.idAgenda, keyBouton,
						value).then(function(result) {

					$state.go('home', null, {
						reload : true
					});

				});
			}
		}
		$scope.dtOptions1 = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					url : 'api/findPieceJointe/0',
					type : 'GET',

				})
				.withOption('createdRow1', createdRow1)
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				// }
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					}

				})

		$scope.dtColumns1 = [
				DTColumnBuilder.newColumn('nom').withTitle('Nom').withOption(
						'width', '40%'),
				DTColumnBuilder.newColumn('commentaire').withTitle('Nature')
						.withOption('width', '30%'),
				DTColumnBuilder.newColumn('dateCreation').withTitle(
						'Date Création').withOption('width', '30%').renderWith(
						function(data, type) {

							return $filter('date')(data, 'dd/MM/yyyy');
						}),

		];

		$scope.search = 0;
		function createdRow1(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		/* get file dec tresor from alfreco */
		$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");

			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2.downloadDocument(docId)
					.then(
							function(result) {
								if (angular.equals({
									status : result.status
								}, {
									status : 500
								})) {
									swal(message, "Un problème s'est produit!",
											"error")
								}
								var fName = result
										.headers('Content-Disposition');
								var file = new Blob([ result.data ], {
									type : 'application/pdf'
								});
								var fileURL = (window.URL || window.webkitURL)
										.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
		}

		$(function() {
			var active = true;

			$('#collapse-init').click(function() {
				if (active) {
					active = false;
					$('.panel-collapse').collapse('show');
					$('.panel-title').attr('data-toggle', '');
					$(this).text('Enable accordion behavior');
				} else {
					active = true;
					$('.panel-collapse').collapse('hide');
					$('.panel-title').attr('data-toggle', 'collapse');
					$(this).text('Disable accordion behavior');
				}
			});

			$('#myAccordion2').on('show.bs.collapse', function() {
				if (active) {
					$('#myAccordion2 .in').collapse('hide');
				}
			});
			$('#myAccordion2').on('hide.bs.collapse', function() {
			});

		});
		$('#myAccordion2 .panel-collapse').on(
				'hidden.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-minus").addClass("fa fa-plus");

					// $scope.statut=false;
				});
		$('#myAccordion2 .panel-collapse').on(
				'shown.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-plus").addClass("fa fa-minus");
				});
		function renderer(api, rowIdx, columns) {
			var data = $.map(
					columns,
					function(col, i) {
						return col.hidden ? '<li data-dtr-index="'
								+ col.columnIndex + '" data-dt-row="'
								+ col.rowIndex + '" data-dt-column="'
								+ col.columnIndex + '">'
								+ '<span class="dtr-title">' + col.title
								+ '</span> ' + '<span class="dtr-data">'
								+ col.data + '</span>' + '</li>' : '';
					}).join('');
			return data ? $compile(
					angular.element($('<ul data-dtr-index="' + rowIdx + '"/>')
							.append(data)))($scope) : false;
		}
	}

})();
