(function() {
'use strict';
angular
	.module('infochamApp')
	.factory('MatierePremiere',MatierePremiere);
MatierePremiere.$inject=['$resource', 'DateUtils'];
function MatierePremiere($resource, DateUtils){
	return $resource('api/matierePremieres/:id', {}, {
        'query': { method: 'GET', isArray: true},
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                data = angular.fromJson(data);
                data.dateCre = DateUtils.convertLocaleDateFromServer(data.dateCre);
                return data;
            }
        },
        'update': {
            method: 'PUT',
            transformRequest: function (data) {
                data.dateCre = DateUtils.convertLocaleDateToServer(data.dateCre);
                return angular.toJson(data);
            }
        },
        'save': {
            method: 'POST',
            transformRequest: function (data) {
                data.dateCre = DateUtils.convertLocaleDateToServer(data.dateCre);
                return angular.toJson(data);
            }
        }
    });	
}

})();
