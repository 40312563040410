(function() {
	'use strict';
	angular.module('infochamApp').controller('AgendaPaiementController',
			AgendaPaiementController);
	AgendaPaiementController.$inject = [ 'ReportingService', 'Inscription2',
			'Certificat', 'DTColumnDefBuilder', '$rootScope', 'fileUpload',
			'Agenda', 'Referentiel', '$stateParams', '$translate',
			 '$scope', 'Principal', '$state', '$http',
			'$compile', 'calendarConfig', 'moment', 'AuditsService', '$filter',
			'DTOptionsBuilder', 'DTColumnBuilder' ];
	function AgendaPaiementController(ReportingService, Inscription2,
			Certificat, DTColumnDefBuilder, $rootScope, fileUpload, Agenda,
			Referentiel, $stateParams, $translate, $scope,
			Principal, $state, $http, $compile, calendarConfig, moment,
			AuditsService, $filter, DTOptionsBuilder, DTColumnBuilder) {
		$scope.idAgenda = $stateParams.id;
		$scope.paiementVirement = {};
		$scope.paiementCheque = {};
		$scope.numTelephone = 0;
		$scope.RIB = 0;

		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});

		Certificat.getNumTel().then(function(result) {
			$scope.numTelephone = result.data.valeur;
		});

		Certificat.getRib().then(function(result) {
			$scope.RIB = result.data.valeur;
		});

		Certificat
				.getDemandeByProcess($scope.idAgenda)
				.then(
						function(result) {
							$scope.agenda = result.data.agenda;
							$scope.facture = result.data.facture;
							$scope.autresServices = result.data.autresServices
							$scope.detailFacture = result.data.detailFactureList;
							$scope.modeDepot = result.data.modeDepot;
							$http
									.get(
											"api/listBouton/"
													+ $scope.facture.process.idCase)
									.then(
											function(result) {
												// récupérer key(id du bouton =
												// nextStep) and value(libelle
												// du bouton)
												$scope.listButton = result.data;
												// parcour :
												// ng-repeat="(key,value) in
												// listButton" pour distinguer
												// entre les ids des boutons
												// lors du clic
												var html = ' <div ng-show="modePaiement==\'cheque\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,paiementVirement,paiementCheque,paiementMobile)" class="btn btn-info-2" ng-disabled="form3.$invalid || form4.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>'
														+ '<div ng-show="modePaiement==\'virement\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,paiementVirement,paiementCheque,paiementMobile)" class="btn btn-info-2" ng-disabled="form3.$invalid || form6.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>'
														+ '<div ng-show="modePaiement==\'mobile\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,paiementVirement,paiementCheque,paiementMobile)" class="btn btn-info-2" ng-disabled="form3.$invalid || form5.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>'
														+ '<div ng-show="modePaiement==\'espece\' || modePaiement==null"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,paiementVirement,paiementCheque,paiementMobile)" class="btn btn-info-2" ng-disabled="form3.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>';

												// ajouter le ou les boutons
												// dans la balise boutonWK
												// l'id #boutonWK est ajouté au
												// niveau html
												angular
														.element("#boutonWK")
														.append(
																$compile(html)(
																		$scope));
											})
							Certificat.getMontant(
									$scope.autresServices.duplicataCO,
									$scope.autresServices.copieCO).then(
									function(result) {
										$scope.montant = result.data;
									});
						});
		$scope.dtOptionsConsolide = DTOptionsBuilder
				.newOptions()
				// or here
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withOption('order', [ [ 3, 'desc' ], [ 0, 'desc' ] ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap()
				.withLanguageSource($translate.instant('global.datatable'))
				.withColumnFilter({
					sPlaceHolder : "head:after"
				})
				.withOption(
						'fnDrawCallback',
						function(iStart, iEnd, oSettings) {
							var api = this.api();
							var rows = api.rows({
								filter : 'applied'
							}).nodes();
							var last = null;
							var listGroup = [];
							var listIds = [];
							// somme Montant
							var listNombre = [];
							var listMontant = [];
							if (iStart._iDisplayStart > 0) {
								var k = 0;
								var j = -1;
								while (k < listIds.length & j == -1) {
									if (listIds[k] == iStart._iDisplayStart) {
										j = -1;

									} else if (listIds[k] > iStart._iDisplayStart) {
										j = k - 1;
									} else if (k + 1 == listIds.length) {
										j = k;
									}
									++k;
								}
								if (j != -1
										&& listIds[j] != iStart._iDisplayStart) {
									$(rows)
											.eq(iStart._iDisplayStart)
											.before(
													'<tr class="group"><td class="w3-center">'
															+ listGroup[j]
															+ '</td> <td class="footer-sum" >'
															+ $filter(
																	'currency')
																	(
																			listNombre[j],
																			'',
																			0)
															+ '  </td> <td class="footer-sum" >'
															+ $filter(
																	'currency')
																	(
																			listMontant[j],
																			'',
																			0)
															+ ' </td></tr>');

								}
							}
							for ( var j in listIds) {
								$(rows)
										.eq(listIds[j])
										.before(
												'<tr class="group"><td class="w3-center">'
														+ listGroup[j]
														+ '</td> <td class="footer-sum" >'
														+ $filter('currency')(
																listNombre[j],
																'', 0)
														+ '  </td> <td class="footer-sum" >'
														+ $filter('currency')(
																listMontant[j],
																'', 0)
														+ ' </td></tr>');
							}

						})
				.withOption(
						'fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {
							var columnas = [ 5 ]; // the columns you wish to
													// add
							var secondRow = $(nRow).next()[0];
							for ( var j in columnas) {
								var columnaActual = columnas[j];
								var totalFiltre = 0;
								var total = 0;
								for (var i = 0; i < aiDisplay.length; i++) {

									totalFiltre = totalFiltre
											+ parseFloat(aasData[aiDisplay[i]][columnaActual]
													.replace(/&nbsp;/g, ''));
								}
								for (var i = 0; i < aasData.length; i++) {
									total = total
											+ parseFloat(aasData[i][columnaActual]
													.replace(/&nbsp;/g, ''));
								}
								$($(nRow).children().get(columnaActual)).html(
										totalFiltre.toString().replace(
												/(\d)(?=(\d{3})+(?!\d))/g,
												'$1 '));
								$($(secondRow).children().get(columnaActual))
										.html(
												total
														.toString()
														.replace(
																/(\d)(?=(\d{3})+(?!\d))/g,
																'$1 '));
							} // end

						})
		$scope.dtColumnsConsolide = [
				DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation")
						.withOption('width', '30%'),
				DTColumnDefBuilder.newColumnDef(1).withTitle("Qté").withOption(
						'width', '15%'),
				DTColumnDefBuilder.newColumnDef(2).withTitle("Valeur")
						.withOption('width', '15%').notSortable(),
				DTColumnDefBuilder.newColumnDef(3).withTitle(
						"Nbre / Nature de colis").withOption('width', '15%'),
				DTColumnDefBuilder.newColumnDef(4)
						.withTitle("Poids net / brut").withOption('width',
								'15%'),
				DTColumnDefBuilder.newColumnDef(5).withTitle("Total ligne")
						.withOption('width', '80%') ];
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}
		/* get file dec tresor from alfreco */
		$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2.downloadDocument(docId)
					.then(
							function(result) {
								if (angular.equals({
									status : result.status
								}, {
									status : 500
								})) {
									swal(message, "Un problème s'est produit!",
											"error")
								}
								var fName = result
										.headers('Content-Disposition');
								var file = new Blob([ result.data ], {
									type : 'application/pdf'
								});
								var fileURL = (window.URL || window.webkitURL)
										.createObjectURL(file);
								downloadLink.href = fileURL;
								downloadLink.download = fName;
								downloadLink.click();
							});
		}
		$scope.traitement = function(keyBouton, value, paiementVirement,
				paiementCheque, paiementMobile) {
			if (keyBouton == "ANNULFACT") {
				swal(
						{
							title : "Confirmation",
							text : "Êtes-vous sûr de vouloir annuler la demande de l'attestation d'origine  ?",
							type : "warning",
							showCancelButton : true,
							confirmButtonColor : "#449d44",
							confirmButtonText : "Valider",
							closeOnConfirm : true,
							cancelButtonText : "Annuler",
						}, function() {
							Inscription2.updateProcInscription($scope.idAgenda,
									keyBouton, value).then(function(result) {
								$state.go('home', null, {
									reload : true
								});
							});
						});
			} else {
				if ($scope.modePaiement == "Virement") {
					Certificat.paiementVirement(paiementVirement,
							$scope.facture.id, $scope.modePaiement,
							$scope.montant, $scope.RIB).then(
							function(result) {
								var uploadUrl = "api/uploadFileVirement/"
										+ result.data.id;
								var file = $scope.docPaiement;
								if (typeof file != 'undefined') {
									var result = fileUpload.uploadFileToUrl(
											file, uploadUrl).then(
											function(result) {
												$scope.piece = result.data;
											});
								}
							});
				}
				if ($scope.modePaiement == "Chèque") {
					Certificat.paiementCheque(paiementCheque,
							$scope.facture.id, $scope.modePaiement,
							$scope.montant, $scope.RIB).then(
							function(result) {
								var uploadUrl = "api/uploadFileCheque/"
										+ result.data.id;
								var file = $scope.docPaiement;
								if (typeof file != 'undefined') {
									var result = fileUpload.uploadFileToUrl(
											file, uploadUrl).then(
											function(result) {
												$scope.piece = result.data;
											});
								}
							});
				}
				if ($scope.modePaiement == "Espèce") {
					Certificat.paiementEspece($scope.facture.id,
							$scope.modePaiement, $scope.montant).then(
							function(result) {
							});
				}
				if ($scope.modePaiement == "Mobile") {
					Certificat.paiementMobile(paiementMobile,
							$scope.facture.id, $scope.modePaiement,
							$scope.montant, $scope.numTelephone).then(
							function(result) {
							});
				}
				Inscription2.updateProcInscription($scope.idAgenda, keyBouton,
						value).then(function(result) {
					$state.go('home', null, {
						reload : true
					});
				});
			}
		}
		$scope.lovModel1 = 'api/referentiels/banques';// this will be
		// the LOV
		// datasource
		$scope.lovTitle1 = 'Liste des banques';
		$scope.lovColumnList1 = [ 'Libelle' ];
		$scope.lovFieldList1 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack1 = function(result) {
			$scope.paiementVirement.banque = result;
		}

		$scope.lovModel2 = 'api/referentiels/banques';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des banques';
		$scope.lovColumnList2 = [ 'Libelle' ];
		$scope.lovFieldList2 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.paiementCheque.banque = result;
		}

		$scope.$on('eventEmitedPaiement', function(event, data) {
			$scope.docPaiement = data.message;
		});

		$scope.noteHonoraire = function() {
			ReportingService.downloadNoteHonoraire($scope.idAgenda).then(
					function(result) {
						var fileName = "Note honoraire"
						ReportingService.downloadPdfFile(result, fileName)
					});
		}
	}

})();
