(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('DepotDossierTechniquePaiementEditController', DepotDossierTechniquePaiementEditController);

	DepotDossierTechniquePaiementEditController.$inject = ['ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function DepotDossierTechniquePaiementEditController (ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
			$scope.codeStatut=$stateParams.codeStatut;
			$scope.idAgenda = $stateParams.id;
			$scope.demcompdepotfichetech="DEMCOMPDEPOTFICHETECH"
			$scope.deminfocompconfdoc="DEMINFOCOMPCONFDOC"
			if($scope.idAgenda == null){
				$state.go('home', null, { reload: true } );
			}
			$scope.fileList = "DEPDOSSTECH";
			var vm = this;
			$scope.ctrl = this;
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.depotDoss= {};
			$scope.totalMPE =0;
			$scope.totalMPL = 0;
			Agenda.getDepotDossTechniqueById($scope.idAgenda).then(function (result) {
$scope.agenda = result.data.agenda;
	 $scope.expediteur = $scope.agenda.oeExpediteur;
Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
			$scope.adhesion = result.data 
		});
$scope.depot = result.data.depot;
$scope.produit = result.data.produit;
$scope.matierePremiereEtrangereList=result.data.matierePremiereEtrangereList;
$scope.matierePremiereLocaleList=result.data.matierePremiereLocaleList;
$scope.listePieceNumerise= result.data.listePieceNumerise;
if($scope.produit.ngpNdp != null){
$scope.produit.ngpNdp = parseFloat($scope.produit.ngpNdp)
}
if($scope.depot.modeDepot != null){
$scope.modeDepot = $scope.depot.modeDepot.typeDepot.codeRubrique;
$scope.dateCommunication  = new Date($scope.depot.modeDepot.dateCommunication);
}
$http.get("api/listBouton/"+$scope.agenda.idCase).then(function(result) {
  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
  	$scope.listButton=result.data;
  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
  	var html=' <div ng-show="facture.modePaiement==\'Chèque\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid || form4.$invalid || isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>'+
  	' <div ng-show="facture.modePaiement==\'Virement\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid || form6.$invalid || isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>'+
//  	' <div ng-show="facture.modePaiement==\'Mobile\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid || form5.$invalid || isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>'+
  	' <div ng-show="facture.modePaiement==\'Espèce\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form.$invalid || form7.$invalid ||  isSaving">{{value}}</button>&nbsp;&nbsp;</span></div>'+
  	' <div ng-show="facture.modePaiement==\'Crédit\'"><span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,facture.modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span></div>';

  	// ajouter le ou les  boutons dans la balise boutonWK
  	// l'id #boutonWK est ajouté au niveau html
  	angular.element("#boutonWK").append( $compile(html)($scope));
  })			
  Certificat.getFactureByProcess($scope.idAgenda).then(function (result) {
		 $scope.facture = result.data
		 
		 if($scope.facture.modePaiement=="Chèque"){
			 Certificat.getPaiementChequeByFacture($scope.facture.id).then(function (result) {
				 $scope.paiement = result.data
			   });
			 }
			 else if($scope.facture.modePaiement=="Mobile"){
				 Certificat.getPaiementMobileByFacture($scope.facture.id).then(function (result) {
					 $scope.paiement = result.data
				   });
				 }
			 else if($scope.facture.modePaiement=="Virement"){
				 Certificat.getPaiementVirementByFacture($scope.facture.id).then(function (result) {
					 $scope.paiement = result.data
				   });
			 }else if($scope.facture.modePaiement=="Espèce"){
					Certificat.getPaiementEspeceByFacture($scope.facture.id).then(function (result) {
						$scope.paiement = result.data
					});
				}

	   });
			});
		$scope.verifMobile = function(numPaiement) {

			Inscription2.verifPaiementMobile(numPaiement).then(
					function(result1) {
						if (result1.data != null && result1.data != "") {
							$scope.verifPaiementMobile = true;
						} else {
							$scope.verifPaiementMobile = false;
						}
					})

		};
	$scope.verifEspece = function(numPaiementEspece){
		  
		   Inscription2.verifPaiementEspece(numPaiementEspece).then(function(result1){
			   if(result1.data!=null && result1.data != ""){
				   $scope.verifPaiementEspece=true;
			   } else {
				   $scope.verifPaiementEspece=false;
			   }
		   })

	};
	$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
	// the LOV
	// datasource
	$scope.lovTitleBureau = 'Liste des bureaux';
	$scope.lovColumnListBureau = [ 'Libellé' ];
	$scope.lovFieldListBureau = [  'libelle' ];
	
	// function to be called when you click "choose" button
	$scope.lovCallBackBureau = function(result) {
		$scope.paiementEspece.bureau = result;
		
	}
			
				  
			  Certificat.getMontantByCode("DOSSIER_TECHNIQUE").then(function (result) {
			   $scope.montant=result.data;
		   });

			 Certificat.getFactureByProcess($scope.idAgenda).then(function (result) {
				 $scope.facture = result.data
					 if($scope.facture.modePaiement=="Chèque"){
						 
						 Certificat.getPaiementChequeByFacture($scope.facture.id).then(function (result) {
							$scope.paiementCheque = result.data
							$scope.paiementCheque.numCheque=parseInt($scope.paiementCheque.numCheque);
							$scope.paiementCheque.dateCheque = new Date($scope.paiementCheque.dateCheque)
						   });
						 }
						 else if($scope.facture.modePaiement=="Virement"){
							 Certificat.getPaiementVirementByFacture($scope.facture.id).then(function (result) {
								 $scope.paiementVirement = result.data
								 $scope.paiementVirement.dateVirement = new Date($scope.paiementVirement.dateVirement)
								 $scope.paiementVirement.numVirement=parseInt($scope.paiementVirement.numVirement);
							   });
						 }
						 else if($scope.facture.modePaiement=="Mobile"){
							 Certificat.getPaiementMobileByFacture($scope.facture.id).then(function (result) {
								 $scope.paiementMobile = result.data;
								 $scope.numPaiMobileOld= result.data.numPaiement;
								 $scope.paiementMobile.numPaiement=parseInt($scope.paiementMobile.numPaiement);
							   });
						 }else if($scope.facture.modePaiement=="Espèce"){
									Certificat.getPaiementEspeceByFacture($scope.facture.id).then(function (result) {
										$scope.paiementEspece = result.data
										$scope.paiementEspece.datePaiement = new Date($scope.paiementEspece.datePaiement);
									});
								}
			 });
			function createdRow(row, data, dataIndex) {
				// Recompiling so we can bind Angular directive to the DT
				$compile(angular.element(row).contents())($scope);
			}
			
			/*get file dec tresor from alfreco*/
			$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2
			.downloadDocument(docId)
			.then(
			function(result) {
			   	if(angular.equals( { status: result.status }, { status: 500 } )){
			swal(message,"Un problème s'est produit!", "error")
			}
			var fName = result
			.headers('Content-Disposition');
			var file = new Blob(
			[ result.data ],
			{
			type : 'application/pdf'
			});
			var fileURL = (window.URL || window.webkitURL)
			.createObjectURL(file);
			downloadLink.href = fileURL;
			downloadLink.download = fName;
			downloadLink.click();
			});
			}
			 $scope.traitement = function(keyBouton,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece){
				 
				 
				 $scope.errors =null;
					
				 
				 
				 
				  $scope.authenticationError = false;
			
			   if ((modePaiement !=null && modePaiement != undefined && modePaiement != "")){
				    if(modePaiement=="Virement"){
					     if (paiementVirement !=null && paiementVirement != undefined && paiementVirement != ""){
						   if(paiementVirement.dateVirement  ==null || paiementVirement.dateVirement   == undefined || paiementVirement.dateVirement    == ""){
							   $scope.errors ="La date du virement est requis";
					   }
						   else if(paiementVirement.numVirement  ==null || paiementVirement.numVirement   == undefined || paiementVirement.numVirement    == ""){
							   $scope.errors ="Le numéro du virement est requis";
					   }
						   else if(paiementVirement.agence  ==null || paiementVirement.agence   == undefined || paiementVirement.agence    == ""){
							   $scope.errors ="L'agence du virement est requis";
					   }
					   
				   }
			   }
			   

								   else if (modePaiement == "Chèque") {
					if (paiementCheque != null && paiementCheque != undefined
							&& paiementCheque != "") {
						if (paiementCheque.dateCheque == null
								|| paiementCheque.dateCheque == undefined
								|| paiementCheque.dateCheque == "") {
							$scope.errors = "La date du chèque est requis";
						} else if (paiementCheque.numCheque == null
								|| paiementCheque.numCheque == undefined
								|| paiementCheque.numCheque == "") {
							$scope.errors = "Le numéro du chèque est requis";
						} else if (paiementCheque.agence == null
								|| paiementCheque.agence == undefined
								|| paiementCheque.agence == "") {
							$scope.errors = "L'agence du chèque est requis";
						}
					}
				}
			}
			   if($scope.errors != null){
					  $scope.authenticationError = true;
					  $("html, body").animate({scrollTop: 300},"slow");
			       }
			   if($scope.authenticationError == false){
				   if($scope.facture.modePaiement =="Virement"){
						  Certificat.paiementVirement(paiementVirement,$scope.idAgenda,$scope.facture.modePaiement,$scope.montant).then(function (result) {
							  var uploadUrl = "api/uploadFileVirement/"+result.data.id;
								 var file = $scope.docPaiement ;
									if(typeof file !='undefined'){
										var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
												function(result) {
													$scope.piece = result.data;
												});
								}
						  });
					  }
					  if($scope.facture.modePaiement =="Chèque"){
						  Certificat.paiementCheque(paiementCheque,$scope.idAgenda,$scope.facture.modePaiement,$scope.montant).then(function (result) {
							  var uploadUrl = "api/uploadFileCheque/"+result.data.id;
								 var file = $scope.docPaiement ;
									if(typeof file !='undefined'){
										var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
												function(result) {
													$scope.piece = result.data;
												});
								}
						  });
					  }
					  if($scope.facture.modePaiement =="Espèce"){
						  Certificat.paiementEspece(paiementEspece,$scope.idAgenda,modePaiement,$scope.montant).then(function (result) {
						  });
					  }
					  if($scope.facture.modePaiement =="Crédit"){
						  Certificat.paiementCredit($scope.idAgenda,modePaiement,$scope.montant).then(function (result) {
						  });
					  }
					  
					  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
					}
			 }

			  $scope.lovModel1 = 'api/findAllBanque';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle1 = 'Liste des banques';
		  		$scope.lovColumnList1 = [ 'denomination' ];
		  		$scope.lovFieldList1 = [  'denomination' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack1 = function(result) {
		  			$scope.paiementVirement.banque = result;
		  		}
			  
			  $scope.lovModel2 = 'api/findAllBanque';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle2 = 'Liste des banques';
		  		$scope.lovColumnList2 = [  'denomination' ];
		  		$scope.lovFieldList2 = [  'denomination' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack2 = function(result) {
		  			$scope.paiementCheque.banque = result;
		  		}
		  		
		  		 $scope.$on('eventEmitedPaiement', function(event, data) {
		  			  $scope.docPaiement = data.message;
		  		  });
		  		
		  		
		  
		  	  $scope.noteHonoraire = function(){
				  ReportingService.downloadNoteHonoraire($scope.idAgenda, $scope.certificatOrigine.numeroCo).then(function (result) {
					  var fileName="Note honoraire"
					  ReportingService.downloadPdfFile(result, fileName)
			 });
				}
				function createdRow(row, data, dataIndex) {
					// Recompiling so we can bind Angular directive to the DT
					$compile(angular.element(row).contents())($scope);
				}
				
				$(function() {
					var active = true;

					$('#collapse-init').click(function() {
						if (active) {
							active = false;
							$('.panel-collapse').collapse('show');
							$('.panel-title').attr('data-toggle', '');
							$(this).text('Enable accordion behavior');
						} else {
							active = true;
							$('.panel-collapse').collapse('hide');
							$('.panel-title').attr('data-toggle', 'collapse');
							$(this).text('Disable accordion behavior');
						}
					});

					$('#myAccordion2').on('show.bs.collapse', function() {
						if (active){$('#myAccordion2 .in').collapse('hide');}
					});
					$('#myAccordion2').on('hide.bs.collapse', function() {
					});
				});
				$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse', function() {
					$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-minus").addClass("fa fa-plus");
					// $scope.statut=false;
				});
				$('#myAccordion2 .panel-collapse').on('shown.bs.collapse', function() {
					$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-plus").addClass("fa fa-minus");
				});
				function renderer(api, rowIdx, columns) {
					var data = $.map( columns, function ( col, i ) {
						return col.hidden ?
								'<li data-dtr-index="'+col.columnIndex+'" data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+
								'<span class="dtr-title">'+col.title+'</span> '+'<span class="dtr-data">'+col.data+'</span>'+'</li>' :'';
					}).join('');
					return data ?
							$compile(angular.element($('<ul data-dtr-index="'+rowIdx+'"/>').append( data )))($scope) :
								false;
				}
}
})();
