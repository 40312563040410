(function() {
	'use strict';
	angular
		.module('infochamApp')
		.controller('DepotDeleteController',DepotDeleteController);
	DepotDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'Depot'];
	function DepotDeleteController($scope, $uibModalInstance, entity, Depot){
		$scope.depot = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Depot.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
	}

	})();
