(function() {
	'use strict';

	angular.module('infochamApp')

	.controller('MenutargetController', MenutargetController).directive(
			'menutargetDirective', menutargetDirective);

	MenutargetController.$inject = [ '$scope' ];
	menutargetDirective.$inject = [ '$rootScope' ];

	function MenutargetController($scope) {

	}

	function menutargetDirective($rootScope) {
		return {
			restrict : "E",
			scope : {
				target : '=',
				titre : '@titre',
			},
			templateUrl : 'app/directives/menu/header-directive.html',
			controller : [
					'$scope',
					'$window',
					'$state',
					'$compile',
					'$interval',
					'$translate',
					'Principal',
					'$http',
					'User',
					function($scope, $window, $state, $compile, $interval,
							$translate, Principal, $http, User) {

						// affichage de la date et l'heure
						$scope.date = "Chargement ..."; // initialise the time
														// variable
						var tick = function() {
							$scope.date = new Date();
						}
						tick();
						$interval(tick, 1000);

						// Afficher en maj la première lettre
						function capitalizeFirstLetter(string) {
							return string.charAt(0).toUpperCase()
									+ string.slice(1);
						}
						;
						$scope.refresh = function() {
							$window.location.reload();
						}
						var jsonPath = "infochamApp.";
						var suffixJsonpath = ".home.title";

						var paramParent = 'Paramétrage';
						var parent = $state.current.parent;
						if (angular.equals({
							parent : parent
						}, {
							parent : 'entity'
						})) {

							url = "";

						} else {
							parent = capitalizeFirstLetter(parent);
							jsonPath += parent;
							jsonPath += suffixJsonpath;
							parent = $translate.instant(jsonPath);
						}
						var allUrl = $state.current.url;
						var url = allUrl.substring(allUrl.lastIndexOf('/') + 1);

						if (!angular.equals({
							url : url
						}, {
							url : 'new'
						}) && !angular.equals({
							url : url
						}, {
							url : 'edit'
						})) {
							jsonPath += url;
							jsonPath += suffixJsonpath;
							url = $translate.instant(jsonPath);

						} else {
							if (angular.equals({
								url : url
							}, {
								url : 'new'
							})) {
								url = parent;
							}

							if (angular.equals({
								url : url
							}, {
								url : 'edit'
							})) {
								url = parent;

							}
						}

						$scope.chemin = url;
					} ]
		};

	}

})();
