(function() {
	'use strict';
	angular.module('infochamApp').controller('BanqueController',
			BanqueController);
	BanqueController.$inject = [ '$resource', '$scope', '$state', 'Banque',
			'ParseLinks', '$rootScope', '$compile', '$translate',
			'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService' ];

	function BanqueController($resource, $scope, $state, Banque, ParseLinks,
			$rootScope, $compile, $translate, DTOptionsBuilder,
			DTColumnBuilder, ReportingService) {
		$scope.agenceTypes = {};
		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					url : 'api/findAllBanque',
					type : 'GET',
				})
				.withOption('createdRow', createdRow)

				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					},
					'4' : {
						type : 'text'
					},
					'5' : {
						type : 'text'
					},
					'6' : {
						type : 'text'
					},
					'7' : {
						type : 'text'
					},
					'8' : {
						type : 'text'
					},

				})

		$scope.dtColumns = [
				DTColumnBuilder.newColumn('denomination').withTitle(
						'Dénomination').withOption('width', '20%'),
				DTColumnBuilder.newColumn('siege').withTitle('Siège')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('rue').withTitle('Rue').withOption(
						'width', '10%'),
				DTColumnBuilder.newColumn('ville.libelle').withTitle('Ville')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('codePostal').withTitle(
						'Boite Postale').withOption('width', '10%'),
				DTColumnBuilder.newColumn('telephone').withTitle('Téléphone')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('fax').withTitle('Fax').withOption(
						'width', '10%'),
				DTColumnBuilder.newColumn('email').withTitle('Email')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('siteWeb').withTitle('Site web')
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn(null).withTitle('Actions')
						.notSortable().withClass('datatable-action-width')
						.renderWith(actionsHtml), ];
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}

		function actionsHtml(data, type, full, meta) {
			// $scope.agenceTypes[data.id] = data;
			return '<div class="action-center" style="margin-top:0px">'
					+ ' <div class="col-md-1"><a ui-sref="banque.edit({id:'
					+ data.id
					+ '})">'
					+

					'   <i class="fa fa-pencil pencil-style"></i> '
					+ '</a></div>'
					+ '<div class="col-md-1"><a ui-sref="banque.delete({id:'
					+ data.id
					+ '})" )"="">'
					+ '   <i class="glyphicon glyphicon-trash icon-trash-style"></i> '
					+ '</a></div>'
		}
		$scope.dtInstance = {};

		$scope.clear = function() {

		};
	}
})();
