(function () {
	'use strict';

	angular
	.module('infochamApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig ($stateProvider) {
		$stateProvider
		.state('depotDossierExport', {
			parent: 'entity',
			url: '/depotDossierExport',
			data: {
//				authorities: ['ROLE_USER'],
				pageTitle: 'infochamApp.demandeSpecimen.home.title'
			},
			params : {
				customProcess : null,
				id : null,
				codeStatut:null
			},

			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/depotDossierExports.html',
					//templateUrl: 'app/404.html',
					controller: 'DepotDossierExportController'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('demandeSpecimen');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})



		.state('traitDepotDossierExport', {
			parent: 'entity',
			url: '/traitDepotDossierExport',
			data: { pageTitle: 'infochamApp.document.home.title' },
			params : {
				customProcess : null,
				specimenSignatures : null
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/depotDossierExport-dialog.html',
					controller: 'DepotDossierExportDialogController',
					controllerAs: 'vm'

				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})


		.state('paiementDepotDossierExport', {
			parent: 'entity',
			url: '/paiementDepotDossierExport',
			data: { pageTitle: 'infochamApp.document.home.title' },
			params : {
				customProcess : null,
				specimenSignatures : null
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/depotDossierExport-paiement.html',
					controller: 'DepotDossierExportPaiementController',
					controllerAs: 'vm'

				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})


		.state('paiementDepotDossierExport-detail', {
			parent: 'entity',
			url: '/paiementDepotDossierExportDetail',
			data: { pageTitle: 'infochamApp.document.home.title' },
			params : {
				customProcess : null,
				specimenSignatures : null
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/depotDossierExport-paiement-detail.html',
					controller: 'DepotDossierExportPaiementDetailController',
					controllerAs: 'vm'

				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})
		.state('note-demandeDossierExportPaiement', {
			parent: 'paiementDepotDossierExport-detail',
			url: '/new/note/notepaiementDepotDossierExport/{idProcess}',
			data: {
				pageTitle: 'infochamApp.listeReference.home.title'
			},
			params : {
				key : null,
				value : null,
				customProcess: null,
				id:null
			},

			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
					controller: 'NoteDemandeDossTechInfoComplementaireController',
					size: 'md',
					resolve: {
						entity: function () {
							return {
								codeListe: null,
								designationListe: null,
								status: null,
							};
						}
					}
				}).result.then(function(result) {
					$state.go('traitDepotDossierJuridique', null, { reload: true });
				}, function() {
					$state.go('^');
				})
			}]
		})

		.state('paiementDepotDossierExport-edit', {
			parent: 'entity',
			url: '/paiementDepotDossierExportEdit',
			data: { pageTitle: 'infochamApp.document.home.title' },
			params : {
				customProcess : null,
				specimenSignatures : null
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/depotDossierExport-paiement-edit.html',
					controller: 'DepotDossierExportPaiementEditController',
					controllerAs: 'vm'

				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})



		.state('consultAllDE', {
			parent: 'home',
			url: 'consult-all-DE',
			data: {
				authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT','ROLE_EMPLOYEE','ROLE_RESPONSABLE_PAIEMENT'],		
				pageTitle: 'infochamApp.agenda.home.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/recherche/consult-all-DE.html',
					controller: 'ConsultAllDEController',
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('agenda');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})

		.state('consultAllDEOE', {
			parent: 'home',
			url: 'consult-all-DE-OE',
			data: {
//				authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT'],		
				pageTitle: 'infochamApp.agenda.home.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/recherche/consult-all-DE-OE.html',
					controller: 'ConsultAllDEOEController',
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('agenda');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})         .state('note-demandeDossierExport', {
			parent: 'traitDepotDossierExport',
			url: '/new/note/noteDemandeDossierExport/{idProcess}',
			data: {
				pageTitle: 'infochamApp.listeReference.home.title'
			},
			params : {
				key : null,
				value : null,
				customProcess: null,
				id:null
			},

			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
					controller: 'NoteDemandeDossTechInfoComplementaireController',
					size: 'md',
					resolve: {
						entity: function () {
							return {
								codeListe: null,
								designationListe: null,
								status: null,
							};
						}
					}
				}).result.then(function(result) {
					$state.go('traitDepotDossierExport', null, { reload: true });
				}, function() {
					$state.go('^');
				})
			}]
		})
		.state('successPaiementDossierExport', {
			parent: 'entity',
			url: '/successDossierExport',
			data: { pageTitle: 'infochamApp.document.home.title' },

			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/successDossierExport.html',
					controller: 'SuccesPaiementDossierExportController',


				}
			},

		})

		.state('failPaiementDossierExport', {
			parent: 'entity',
			url: '/failPaiementDossierExport',
			data: { pageTitle: 'infochamApp.document.home.title' },

			views: {
				'content@': {
					templateUrl: 'app/entities/demandeDepotDossierExport/failDossierExport.html',
					controller: 'FailPaiementDossierExportController',


				}
			},

		})






	}
})();

