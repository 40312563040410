(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('addnotebudgetedit', addnotebudgetedit)
	.filter('StartOnPage', StartOnPage)
	.controller('CommentaireController', CommentaireController);

	addnotebudgetedit.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	CommentaireController.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','id','CustomProcessBonita' ,'DTColumnDefBuilder'];

	function addnotebudgetedit ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				detail: '@',
			},
			
			
			template: '  <a style="font-size:16px" ng-click="openPopUp(detail)"><span class="glyphicon glyphicon-eye-open"></span> <spanclass="hidden-xs forme-button"> </span></a>',

			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(detail) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/app/entities/demandeReclamation/commentaire.html',
							controller : 'CommentaireController',
							resolve: {
							
								detail: function () {
									return detail;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function CommentaireController ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,id,CustomProcessBonita ,DTColumnDefBuilder) {
		
		
		$scope.detail = detail ;
		 $scope.clear=     function clear () {
	           $uibModalInstance.dismiss('cancel');
	       }
	}
})();
