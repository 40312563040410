(function() {
'use strict';
angular
	.module('infochamApp')
	.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('paramMobile', {
        parent: 'entity',
        url: '/paramMobile',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.paramMobile.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/paramMobile/paramMobile.html',
                controller: 'ParamMobileController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                $translatePartialLoader.addPart('document');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    .state('paramMobile.detail', {
        parent: 'entity',
        url: '/paramMobile/{id}',
        data: {
            authorities: ['ROLE_ADMIN'],
            pageTitle: 'infochamApp.paramMobile.detail.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/ParamMobile/paramMobile-detail.html',
                controller: 'ParamMobileDetailController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                $translatePartialLoader.addPart('document');
                return $translate.refresh();
            }],
            entity: ['$stateParams', 'ParamMobile', function($stateParams, ParamPaiement) {
                return ParamMobile.get({id : $stateParams.id});
            }]
        }
    })
    .state('paramMobile.add', {
        parent: 'paramMobile',
        url: '/new',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/paramMobile/paramMobile-add.html',
                controller: 'ParamMobileDialogController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            codeType: null,
                            codeRubrique: null,
                            libelle: null,
                            valeur1: null,
                            valeur2: null,
                            status: null,
                            codeListeMere: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(result) {
                $state.go('paramMobile', null, { reload: true });
            }, function() {
                $state.go('paramMobile');
            })
        }]
    })
    .state('paramMobile.update', {
        parent: 'paramMobile',
        url: '/{id}/edit',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/paramMobile/paramMobile-update.html',
                controller: 'ParamMobileDialogController',
                size: 'lg',
                resolve: {
                    entity: ['ParamMobile', function(ParamMobile) {
                        return ParamMobile.get({id : $stateParams.id});
                     
                    }]
                }
            }).result.then(function(result) {
                $state.go('paramMobile', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    .state('paramMobile.delete', {
        parent: 'paramMobile',
        url: '/{id}/delete',
        data: {
            authorities: ['ROLE_ADMIN'],

        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/paramMobile/paramMobile-delete-dialog.html',
                controller: 'ParamMobileDeleteController',
                size: 'md',
                resolve: {
                    entity: ['ParamMobile', function(ParamMobile) {
                        return ParamMobile.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('paramMobile', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    });
}

})();