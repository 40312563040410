(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'affectation-role',
						{
							parent : 'admin',
							url : '/affectation-role',
							data : {
								authorities : []
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/affectation-role/affectation-role.html',
									controller : 'AffectationRoleController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('affectation-role');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'affectation-role.new',
						{
							parent : 'affectation-role',
							url : '/AffectationRolenew',
							data : {
								authorities : [],
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/affectation-role/affectation-role-dialog.html',
									controller : 'AffectationRoleDialogController'
								}
							},
							resolve : {
								authorities : [ '$http', function($http) {

								} ],
								entity : function() {
									return {
										id : null,
										roleId : null,
										actorId : null,
										groupId : null,
										userId : null
									};
								}
							}
						})
				.state(
						'affectation-role-delete',
						{
							parent : 'affectation-role',
							url : '/{id}/delete',
							data : {
								authorities : [],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/affectation-role/affectation-role-delete-dialog.html',
													controller : 'AffectationRoleDeleteController',
													size : 'md',

												}).result.then(
												function(result) {
													$state.go(
															'affectation-role',
															null, {
																reload : true
															});
												}, function() {
													$state.go('^');
												})
									} ]
						})

	}
})();
