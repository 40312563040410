(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeDestinataireDeleteController', OeDestinataireDeleteController);

	OeDestinataireDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'OeDestinataire'];

	function OeDestinataireDeleteController ($scope, $uibModalInstance, entity, OeDestinataire) {

        $scope.oeDestinataire = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            OeDestinataire.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
