(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('DepotDossierJuridiquePaiementDetailController', DepotDossierJuridiquePaiementDetailController);

	DepotDossierJuridiquePaiementDetailController.$inject = ['ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function DepotDossierJuridiquePaiementDetailController (ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.paiementVirement = {};
		$scope.paiementCheque = {};
		$scope.numTmoney=0;
		$scope.numFlouss=0 ;
		$scope.RIB=0;
		$scope.verifPaiementMobile=false;
		$scope.verifPaiementEspece=false;
		$scope.paiementEspece={};
		$scope.customProcess = $stateParams.customProcess ;
		$scope.createur = $scope.customProcess.oeExpediteurDTO;
		$scope.agendaDTO = $scope.customProcess.agendaDTO;
		$scope.idAgenda=$scope.agendaDTO.id;
		$scope.specimenSignatures = $stateParams.specimenSignatures ;
		$scope.pieceJointeDTOs = $scope.customProcess.pieceJointeDTOs;
		$scope.customProcessDTO =$stateParams.customProcess ;
		$scope.expediteur =$stateParams.customProcess.demandeDossierJuridiqueDTO.createur.oeExpediteur;
		$scope.idEntity= $scope.customProcess.agendaDTO.idEntity;
		$scope.attinfcomppai="ATTINFCOMPPAIDOSSJUR"
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;
			if ($scope.account == null) {
				$state.go('login');
			}
		});

		 Certificat.getFactureByProcess($scope.idAgenda).then(function (result) {
			 $scope.facture = result.data
			 
			 if($scope.facture.modePaiement=="Chèque"){
				 Certificat.getPaiementChequeByFacture($scope.facture.id).then(function (result) {
					 $scope.paiement = result.data
				   });
				 }
				 else if($scope.facture.modePaiement=="Mobile"){
					 Certificat.getPaiementMobileByFacture($scope.facture.id).then(function (result) {
						 $scope.paiement = result.data
					   });
					 }
				 else if($scope.facture.modePaiement=="Virement"){
					 Certificat.getPaiementVirementByFacture($scope.facture.id).then(function (result) {
						 $scope.paiement = result.data
					   });
				 }else if($scope.facture.modePaiement=="Espèce"){
						Certificat.getPaiementEspeceByFacture($scope.facture.id).then(function (result) {
							$scope.paiement = result.data
						});
					}

		   });
		$scope.verifMobile = function(numPaiement){
			  
			   Inscription2.verifPaiementMobile(numPaiement).then(function(result1){
				   if(result1.data!=null && result1.data != ""){
					   $scope.verifPaiementMobile=true;
				   }
				   else {
					   $scope.verifPaiementMobile=false;
				   }
			   })
		};
		$scope.verifEspece = function(numPaiementEspece){
			   Inscription2.verifPaiementEspece(numPaiementEspece).then(function(result1){
				   if(result1.data!=null && result1.data != ""){
					   $scope.verifPaiementEspece=true;
				   }
				   else {
					   $scope.verifPaiementEspece=false;
				   }
			   })
		};
		$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
		// the LOV
		// datasource
		$scope.lovTitleBureau = 'Liste des bureaux';
		$scope.lovColumnListBureau = [ 'Libellé' ];
		$scope.lovFieldListBureau = [  'libelle' ];
		
		// function to be called when you click "choose" button
		$scope.lovCallBackBureau = function(result) {
			$scope.paiementEspece.bureau = result;
			
		}
		
		Principal.identity().then(function(account) {
			

            Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
					
					$scope.adhesion = result.data
					
				});
				
			
		});
		
		 Certificat.getMontantByCode("DOSSIER_JURIDIQUE").then(function (result) {
			   $scope.montant=result.data;
		   });
		$scope.styleSwalConfirmation = {
				title: "Confirmation",
				text: "Voulez vous vraiment continuer ?",
				type: "warning",
				showCancelButton: true,
				cancelButtonText: "NON",
				confirmButtonColor: "#337ab7",
				confirmButtonText: "Oui",
				closeOnConfirm: true
		}

		 $http.get("api/listBouton/"+$scope.agendaDTO.idCase).then(function(result) {
			  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
			  	$scope.listButton=result.data;
			  	$scope.listButton1 = {};
			  	$scope.listButton1["ATTINFCOMPPAIDOSSJUR"]="Demander des informations complémentaires";
			  	$scope.listButton1["DELIVDOSSJUR"]="Confirmer le paiement";			  	// ajouter le ou les  boutons dans la balise boutonWK
			 	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
			  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
			 
			  	// l'id #boutonWK est ajouté au niveau html
			  	angular.element("#boutonWK").append( $compile(html)($scope));
			  })			
		
		
		
				
		  $scope.traitement = function(keyBouton,value){
			  if(keyBouton == "ATTINFCOMPPAIDOSSJUR"){
				  $state.go('note-demandeDossierJuridiquePaiement', {id:$scope.idAgenda, idProcess:$scope.facture.process.idCase,key:keyBouton,value:value}, { reload: true } );
			  }
			  
			else{

				Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
		 			$state.go('home', null, { reload: true } );
		 });
			}
			}
				function createdRow(row, data, dataIndex) {
					// Recompiling so we can bind Angular directive to the DT
					$compile(angular.element(row).contents())($scope);
				}
				/*get file dec tresor from alfreco*/
				$scope.downloadDecharge = function(docId) {
				var downloadLink = document.createElement("a");
				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2
				.downloadDocument(docId)
				.then(
				function(result) {
				   	if(angular.equals( { status: result.status }, { status: 500 } )){
				swal(message,"Un problème s'est produit!", "error")
				}
				var fName = result
				.headers('Content-Disposition');
				var file = new Blob(
				[ result.data ],
				{
				type : 'application/pdf'
				});
				var fileURL = (window.URL || window.webkitURL)
				.createObjectURL(file);
				downloadLink.href = fileURL;
				downloadLink.download = fName;
				downloadLink.click();
				});
				}
				  $scope.lovModel1 = 'api/findAllBanque';// this will be
			  		// the LOV
			  		// datasource
			  		$scope.lovTitle1 = 'Liste des banques';
			  		$scope.lovColumnList1 = [  'Dénomination' ];
			  		$scope.lovFieldList1 = [  'denomination' ];
			  		// function to be called when you click "choose" button
			  		$scope.lovCallBack1 = function(result) {
			  			$scope.paiementVirement.banque = result;
			  		}
				  
				  $scope.lovModel2 = 'api/findAllBanque';// this will be
			  		// the LOV
			  		// datasource
			  		$scope.lovTitle2 = 'Liste des banques';
			  		$scope.lovColumnList2 = [  'Dénomination' ];
			  		$scope.lovFieldList2 = [  'denomination' ];
			  		// function to be called when you click "choose" button
			  		$scope.lovCallBack2 = function(result) {
			  			$scope.paiementCheque.banque = result;
			  		}
			  		
			  		 $scope.$on('eventEmitedPaiement', function(event, data) {
			  			  $scope.docPaiement = data.message;
			  		  });
			  		 
			  		  $scope.noteHonoraire = function(){
						  ReportingService.downloadNoteHonoraire($scope.idAgenda, $scope.certificatOrigine.numeroCo).then(function (result) {
							  var fileName="Note honoraire"
							  ReportingService.downloadPdfFile(result, fileName)
					 });
						}
			  		  
			  
}
})();
