(function() {
	'use strict';

	angular.module('infochamApp')
        .directive('dateAfter', dateAfter);

	dateAfter.$inject = [];

    
    function dateAfter () {
	      
	    return {
	      
	      require: 'ngModel',
	      
	      link: function (scope, element, attrs, ngModelCtrl) {
	        var date, otherDate;
	        scope.$watch(attrs.dateAfter, function (value) {
	          otherDate = value;
	          validate();
	        });
	        scope.$watch(attrs.ngModel, function (value) {
	          date = value;
	          validate();
	        });
	        function validate() {
	          ngModelCtrl.$setValidity('dateafter', !date || !otherDate || date >= otherDate);
	        }        
	      }
	      
	    };

	  }

})();
