(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DemandeSpecimenHLController', DemandeSpecimenHLController);

    DemandeSpecimenHLController.$inject = ['SpecimenSignature','$stateParams','Agenda' ,'Principal','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita'];

    function DemandeSpecimenHLController(SpecimenSignature, $stateParams,Agenda ,Principal,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita) {

		
		
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.codePiece= 'SP';
			$scope.adhesion ={};
			$scope.expediteur = {};
			$scope.specimenSignatures = [];
			$scope.specimenSignatureDTOs = [];
			$scope.oeExpediteurDTO= {};
			$scope.demandeSpecimenDTO= {};

			$scope.customProcessDTO = {};
			Principal.identity().then(function(account) {
				$scope.user = account;
					
			});
			
			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}
			var unsubscribe1 = $scope.$on('eventEmitedPiece', function(event, result) {
				$scope.pieces = result.message;
				$scope.pieceValide = result.pieceValide;

			});
			$scope.lovModel2 = 'api/getAllExpediteur';// this will be
		 	// the LOV
		 	// datasource
		 	$scope.lovTitle2 = 'Liste des raisons sociales';
		 	$scope.lovColumnList2 = [' Raison Sociale '];
		 	$scope.lovFieldList2 = ['raisonSociale'];
		 	// function to be called when you click "choose" button
		 	$scope.lovCallBack2 = function(result) {
		 	$scope.expediteur = result;
		 	Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
				
				$scope.adhesion = result.data
			});
		 	}
			$scope.demandeSP = function(customProcessDTO) {
				$scope.errors =null;
				  $scope.authenticationError = false;
				  if ($scope.pieceValide == undefined ||
							 $scope.pieceValide==false) {
							 $scope.errors="Il faut attacher toutes les piéces justificatives!"
							
							 }   
				   if($scope.errors != null){
						  $scope.authenticationError = true;
						  $("html, body").animate({scrollTop: 300},"slow");
				       }
				   if($scope.authenticationError == false){
				$scope.customProcessDTO.specimenSignatureDTOs = $scope.specimenSignatures;
				$scope.customProcessDTO.oeExpediteurDTO = $scope.expediteur;
				$scope.customProcessDTO.pieceJointeDTOs = $scope.pieces;
					swal($scope.styleSwalConfirmation,function(){
						Agenda.addSpecimenHL(customProcessDTO).then(function (result) {
							if(result.status==200 ){
								swal({
									position: 'center',
									type: 'success',
									title: 'Votre demande a été créée avec succès',
									showConfirmButton: false,
									timer: 2000
								})
							}
							else {
								swal("veuillez réessayer","", "error")
							}
						});
						setTimeout(function() {
							$state.go('home', null, { reload: true } );
						}, 3000);
					});
				   }
				
			}

			
		

		}
})();
