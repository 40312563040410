(function() {
'use strict';
angular
	.module('infochamApp')
	.config(stateConfig);
stateConfig.$inject=['$stateProvider'];
function stateConfig($stateProvider){
	$stateProvider
    .state('matierePremiere', {
        parent: 'entity',
        url: '/matierePremieres',
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'infochamApp.matierePremiere.home.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/matierePremiere/matierePremieres.html',
                controller: 'MatierePremiereController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('matierePremiere');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
    .state('matierePremiere.detail', {
        parent: 'entity',
        url: '/matierePremiere/{id}',
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'infochamApp.matierePremiere.detail.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/entities/matierePremiere/matierePremiere-detail.html',
                controller: 'MatierePremiereDetailController'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('matierePremiere');
                return $translate.refresh();
            }],
            entity: ['$stateParams', 'MatierePremiere', function($stateParams, MatierePremiere) {
                return MatierePremiere.get({id : $stateParams.id});
            }]
        }
    })
    .state('matierePremiere.new', {
        parent: 'matierePremiere',
        url: '/new',
        data: {
            authorities: ['ROLE_USER'],
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/matierePremiere/matierePremiere-dialog.html',
                controller: 'MatierePremiereDialogController',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            designation: null,
                            ngp: null,
                            pht: null,
                            fournisseurs: null,
                            type: null,
                            pourcentage: null,
                            isAutomatique: null,
                            dateCre: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(result) {
                $state.go('matierePremiere', null, { reload: true });
            }, function() {
                $state.go('matierePremiere');
            })
        }]
    })
    .state('matierePremiere.edit', {
        parent: 'matierePremiere',
        url: '/{id}/edit',
        data: {
            authorities: ['ROLE_USER'],
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/matierePremiere/matierePremiere-dialog.html',
                controller: 'MatierePremiereDialogController',
                size: 'lg',
                resolve: {
                    entity: ['MatierePremiere', function(MatierePremiere) {
                        return MatierePremiere.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('matierePremiere', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    })
    .state('matierePremiere.delete', {
        parent: 'matierePremiere',
        url: '/{id}/delete',
        data: {
            authorities: ['ROLE_USER'],
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/matierePremiere/matierePremiere-delete-dialog.html',
                controller: 'MatierePremiereDeleteController',
                size: 'md',
                resolve: {
                    entity: ['MatierePremiere', function(MatierePremiere) {
                        return MatierePremiere.get({id : $stateParams.id});
                    }]
                }
            }).result.then(function(result) {
                $state.go('matierePremiere', null, { reload: true });
            }, function() {
                $state.go('^');
            })
        }]
    });	
}

})();
