(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('dashboard', {
            parent: 'entity',
            url: '/dashboard',
            data: {
//                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.demandeSpecimen.home.title'
            },
        	params : {
    			customProcess : null,
    			id : null,
    			codeStatut:null
    		},
    		
            views: {
                'content@': {
                    templateUrl: 'app/admin/dashboard/dashboard.html',
                    controller: 'DashboardController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('demandeSpecimen');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
}
})();

