(function() {
	'use strict';
	angular.module('infochamApp').controller('AdresseController',
			AdresseController);
	AdresseController.$inject = [ '$scope', '$state', 'Adresse', 'ParseLinks' ];
	function AdresseController($scope, $state, Adresse, ParseLinks) {
		$scope.adresses = [];
		$scope.predicate = 'id';
		$scope.reverse = true;
		$scope.page = 1;
		$scope.loadAll = function() {
			Adresse.query({
				page : $scope.page - 1,
				size : 20,
				sort : [
						$scope.predicate + ','
								+ ($scope.reverse ? 'asc' : 'desc'), 'id' ]
			}, function(result, headers) {
				$scope.links = ParseLinks.parse(headers('link'));
				$scope.totalItems = headers('X-Total-Count');
				$scope.adresses = result;
			});
		};
		$scope.loadPage = function(page) {
			$scope.page = page;
			$scope.loadAll();
		};
		$scope.loadAll();

		$scope.refresh = function() {
			$scope.loadAll();
			$scope.clear();
		};

		$scope.clear = function() {
			$scope.adresse = {
				adresse : null,
				codePostal : null,
				gouvernorat : null,
				localite : null,
				tel : null,
				fax : null,
				email : null,
				siteWeb : null,
				id : null
			};
		};
	}

})();
