(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('NaturePieceJointeDetailController', NaturePieceJointeDetailController);

	NaturePieceJointeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'NaturePieceJointe', 'Referentiel'];

	function NaturePieceJointeDetailController ($scope, $rootScope, $stateParams, entity, NaturePieceJointe, Referentiel) {
        $scope.naturePieceJointe = entity;
        $scope.load = function (id) {
            NaturePieceJointe.get({id: id}, function(result) {
                $scope.naturePieceJointe = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:naturePieceJointeUpdate', function(event, result) {
            $scope.naturePieceJointe = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
