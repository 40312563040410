(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('FactureDetailEditController', FactureDetailEditController);

	FactureDetailEditController.$inject = ['Referentiel','ReferentielWs','$uibModalInstance','$rootScope','Inscription2','DTColumnDefBuilder','fileUpload','Agenda' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function FactureDetailEditController (Referentiel,ReferentielWs,$uibModalInstance,$rootScope,Inscription2,DTColumnDefBuilder,fileUpload,Agenda,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.detail= $stateParams.facture;
		$scope.idExpediteur= $stateParams.idExpediteur;
		 $scope.idpoidsBrutUnite=$scope.detail.poidsBrutUnite.id;
		 if($scope.detail.poidsNetUnite != null){
		 $scope.idpoidsNetUnite= $scope.detail.poidsNetUnite.id;
		 }
			Principal.identity().then(function(account) {
				$scope.account = account;
				if ($scope.account == null) {
					$state.go('login');
				}
				Inscription2.getUserByLogin(account.login).then(function(result) {
					$scope.user = result.data;
					$scope.id = result.data.id;
					
				});
			});
			
			ReferentielWs.getAllProduitByUser($scope.idExpediteur).then(function(result){
				$scope.produits=result.data;
			})
		 $scope.detail.nombreUnite=parseInt($scope.detail.nombreUnite);
		// $scope.detail.valeurUnite=parseInt($scope.detail.valeurUnite);
		 $scope.detail.nombreColis=parseInt($scope.detail.nombreColis);
		 $scope.detail.numeroColis=parseInt($scope.detail.numeroColis);
		// $scope.detail.poidsNet=parseInt($scope.detail.poidsNet);
		// $scope.detail.poidsBrut=parseInt($scope.detail.poidsBrut);
		$scope.updateDevise = function(id){
			 ReferentielWs.getReferentielById(id).then(function(result){
				 $scope.detail.devise = result.data;
			 })
		 };
		 $scope.updateBrutUnite = function(id){
			 ReferentielWs.getReferentielById(id).then(function(result){
				 $scope.detail.poidsBrutUnite = result.data;
			 })
		 };
		 $scope.updateNetUnite = function(id){
		 ReferentielWs.getReferentielById(id).then(function(result){
			 $scope.detail.poidsNetUnite = result.data;
		 })
		};
		$scope.index = $stateParams.index;
			$scope.clear = function() {
				$uibModalInstance.dismiss('cancel');
			};
			$scope.save = function(detail,total) {
					$rootScope.$broadcast('eventEmitedEditFacture',{message:detail,index:$scope.index});
					$uibModalInstance.dismiss('cancel');
			};
			ReferentielWs.getAllProduitLibelle().then(function(result){
				$scope.produits=result.data;
			})
			ReferentielWs.getAllDevise().then(function(result){
				$scope.devises=result.data;
			})
			ReferentielWs.getAllUnite().then(function(result){
				$scope.unites=result.data;
			})
			function createdRow(row, data, dataIndex) {
							  $compile(angular.element(row).contents())($scope);
							  }
			
				  $scope.lovModel7 =  'api/referentiels/getAllProduitByUser/'+$scope.idExpediteur;// this will be
				// the LOV
				// datasource
				$scope.lovTitle7 = 'Liste des produits';
				$scope.lovColumnList7 = [  'Désignation' ];
				$scope.lovFieldList7 = [ 'designation' ];
				// function to be called when you click "choose" button
				$scope.lovCallBack7 = function(result) {
					$scope.detail.designation = result.designation;
				}
			
			
}
})();
