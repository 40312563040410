(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('demandeSpecimenHL', {
            parent: 'entity',
            url: '/demandeSpecimensHL',
            data: {
//                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.demandeSpecimen.home.title'
            },
        	params : {
    			customProcess : null,
    			id : null,
    			codeStatut:null
    		},
    		
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeSpecimenHL/demandeSpecimensHL.html',
                    controller: 'DemandeSpecimenHLController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('demandeSpecimen');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        
        
        	.state('traitDemandeSpecimenHL', {
    		parent: 'entity',
    		url: '/traitDemandeSpecimenHL',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeSpecimenHL/demandeSpecimenHL-dialog.html',
    				controller: 'DemandeSpecimenHLDialogController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})


        
  
}
})();

