(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('anciennedetaillovlibre', anciennedetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('ancienneDetailLibreCtrl', ancienneDetailLibreCtrl);

	anciennedetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	ancienneDetailLibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','id','CustomProcessBonita' ,'DTColumnDefBuilder'];

	function anciennedetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				id:'@',

			},
			
			template: '  <a style="font-size:16px" ng-click="openPopUp(id)"><span class="glyphicon glyphicon-eye-open"></span> <spanclass="hidden-xs forme-button"> </span></a>',


			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(id) {

					
					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/ancienneDetailTemplate.html',
							controller : 'ancienneDetailLibreCtrl',
							resolve: {
							
								id: function () {
									return id;
								},
							
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function ancienneDetailLibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,id,CustomProcessBonita ,DTColumnDefBuilder) {

		
		 CustomProcessBonita.getListEffectifByAncienneDemande(id).then(function(result) {
				$scope.listeEffectif=result.data;
		  })
		  
		  Agenda.getAutorisationByIdProcess(id).then(function(result){
					$scope.customProcess=result.data ;
					$scope.demandeAutorisation = result.data.demandeAutorisationDTO
					$scope.motifsList = $scope.customProcess.motif ;
					$scope.mesureSecuritesList = $scope.customProcess.mesureSecurite ;
				})
		$scope.dtOptionsConsolideAD = DTOptionsBuilder
		.newOptions()
		// or here
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		.withOption('order',[ 0, 'desc' ] )
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
		.withOption('fnFooterCallback',
				function(nRow, aasData, iStart, iEnd, aiDisplay) {



		})
		$scope.dtColumnsConsolideAD = [
			DTColumnDefBuilder.newColumnDef(0).withTitle("Nom").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(1).withTitle("Prénom").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(2).withTitle("N° de Pièce").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(3).withTitle("Téléphone").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(4).withTitle("Fonction").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(5).withTitle("Statut").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(6).withTitle("Numéro d'autorisation").withOption('className', 'text-center').withOption('width', '10%'),
			];

		
		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
	
		 
$scope.chooseItem = function (result) {
	$uibModalInstance.close(result);
}

		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
		
		$scope.maskCin = function(cin)		
		{		
					
			return cin.substring(5);	
		}
	}
})();
