(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('NoteDepotDocOrigAddController', NoteDepotDocOrigAddController);

	NoteDepotDocOrigAddController.$inject = ['Agenda','$rootScope','$uibModalInstance','Inscription2 ','$stateParams','$translate','$scope', 'Principal', '$state','$http','$compile', 'AuditsService', '$filter','DTOptionsBuilder','DTColumnBuilder'];

	function NoteDepotDocOrigAddController (Agenda,$rootScope,$uibModalInstance,Inscription2 ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
	       $scope.idProcess = $stateParams.idProcess;
	       $scope.idAgenda = $stateParams.id;
	       $scope.key = $stateParams.key;
	       $scope.value = $stateParams.value;
	       Agenda.getDepotDossTechniqueById($scope.idAgenda).then(function (result) {
	    	   $scope.agenda = result.data.agenda;
	       });
	    $scope.clear=     function clear () {
	           $uibModalInstance.dismiss('cancel');
	       }
	    $scope.save=   function save (note) {
	    	note.etapeProcess="DEMINFOCOMPCONFDOC";
	       	 Inscription2.addNote( $scope.idAgenda,note).then(function (result) {
	       		$state.go('traitDemandeDepotDossTechnique', {id:$scope.idAgenda,codeStatut:$scope.agenda.codeStatut}, { reload: true } );
	       		setTimeout(function() {
	       	 $uibModalInstance.dismiss('cancel');
	     });
	     	},1000);
	    	}
}
})();
