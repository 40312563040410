(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ConsultAllDTController', ConsultAllDTController);

	ConsultAllDTController.$inject = ['ReferentielWs' ,'ReportingService' ,'Inscription2','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$http','$state', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function ConsultAllDTController (ReferentielWs ,ReportingService ,Inscription2,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal,  $http,$state, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {

	$scope.co = {}
	$scope.rechercheDTO = {};
	$scope.typeProcess = "DEPDOSSTECH";
	$scope.lovModel2 = 'api/getAllExpediteur';// this will be
 	// the LOV
 	// datasource
 	$scope.lovTitle2 = 'Liste des raisons sociales';
 	$scope.lovColumnList2 = [' Raison Sociale '];
 	$scope.lovFieldList2 = ['raisonSociale'];
 	// function to be called when you click "choose" button
 	$scope.lovCallBack2 = function(result) {
 	$scope.oeExpediteurSelected = result;

 	}
	var anneeDeb = $filter('date')(new Date(), 'yyyy');;
	$scope.annees = [];
	for (var i = 0; i < 50; i++) {
		$scope.annees[i] = {id:i,ann:  anneeDeb - i};
		
	}
	
	$scope.valider= function(){
		$scope.demandeDTO=[]

		$scope.listCriterionDto = []

		
		
		
		if ($scope.oeExpediteurSelected != null) {
			$scope.listCriterionDto.push({
				propertyName : 'T3.ID',
				propertyType : 'Number',
				operateur : '=',
				listCriterionValue : [$scope.oeExpediteurSelected.id]
			})
		}
		
		if ($scope.rechercheDTO.designation != null && $scope.rechercheDTO.designation != "") {
			$scope.listCriterionDto.push({
				propertyName : 'T2.DESIGNATION',
				propertyType : 'String',
				operateur : '=',
				listCriterionValue : [$scope.rechercheDTO.designation]
			})
		}
		if ($scope.rechercheDTO.ngpNdp != null && $scope.rechercheDTO.ngpNdp != "") {
			$scope.listCriterionDto.push({
				propertyName : 'T2.NGP_NDP',
				propertyType : 'String',
				operateur : '=',
				listCriterionValue : [$scope.rechercheDTO.ngpNdp]
			})
		}
		if ($scope.rechercheDTO.anneeCre != null) {
			$scope.listCriterionDto.push({
				propertyName : "extract(year from T1.DATE_CRE )  ",
				propertyType : 'Number',
				operateur : '=',
				listCriterionValue : [ $scope.rechercheDTO.anneeCre ]
			})

		}

		
		if ($scope.rechercheDTO.tauxMin != null) {
			$scope.listCriterionDto.push({
				propertyName : 'T2.TAUX_INTEGRATION',
				propertyType : 'Number',
				operateur : '>',
				listCriterionValue : [$scope.rechercheDTO.tauxMin]
			})
		}
		
		
		if ($scope.rechercheDTO.tauxMax != null) {
			$scope.listCriterionDto.push({
				propertyName : 'T2.TAUX_INTEGRATION',
				propertyType : 'Number',
				operateur : '<',
				listCriterionValue : [$scope.rechercheDTO.tauxMax]
			})
		}
		

	
		$scope.depotDTO = {
				typePorcess: $scope.typeProcess,
				etapPorcess: $scope.rechercheDTO.statutDTO,
				listCriterionDto : $scope.listCriterionDto
		}

		$scope.dtOptions = DTOptionsBuilder.newOptions()
		.withOption('ajax',function(data, fnCallback, settings) {
			$scope.depotDTO.input = data;
			$http.put('api/getEtatRechercheDossierTech',$scope.depotDTO,'application/json').then(
					function(result) {
						$scope.records=	result.data.recordsTotal;
						fnCallback(result.data);
					});

		})

		.withDataProp('data')
		.withDisplayLength(10)
		.withOption('processing', true)
		.withOption('serverSide', true)
		.withOption('createdRow', createdRow)
		.withOption('order',[[2, 'desc']])
		.withPaginationType('full_numbers')
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
		.withLightColumnFilter({
			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			},
			'4' : {
				type : 'text'
			},
			'5' : {
				type : 'text'
			},
			'6' : {
			type : 'text'
			},
			'7' : {
			type : 'text'
			}

		})
		if ($scope.dtInstance._renderer != undefined) {
			$scope.dtInstance._renderer.rerender();

		}

	}

	$scope.dtOptions = DTOptionsBuilder.fromSource('').withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>").
	withBootstrap().withLanguageSource( $translate.instant('global.datatable'));
	$scope.dtColumns = [
		DTColumnBuilder.newColumn('NUM_IDE').withTitle($translate.instant('Identifiant Unique')).withOption('width', '10%').renderWith(oeExp),
		DTColumnBuilder.newColumn('RAISON_SOCIALE').withTitle($translate.instant('Raison sociale')).withOption('width', '20%'),
		DTColumnBuilder.newColumn('DATE_CRE').withTitle($translate.instant('Date création ')).withOption('width', '10%'),
		
		
		DTColumnBuilder.newColumn(null).withTitle($translate.instant('Désignation ')).withOption('width', '10%').renderWith(function(data, type) {
			return '<label class="directive-lien"> '+data.DESIGNATION+'<span produitdetaillovlibre idproduit="'+data.IDPRODUIT+'" ></span></label>'
		}),
		DTColumnBuilder.newColumn('NGP_NDP').withTitle($translate.instant('Code NGP')).withOption('width', '10%'),	
		DTColumnBuilder.newColumn(null).withTitle($translate.instant('Fiche Technique')).withOption('width', '10%').renderWith(function(data, type) {
			return '<label class="directive-lien"> '+data.IDDEPOT+'<span depotdetaillovlibre iddepot="'+data.IDDEPOT+'" idagenda="'+data.ID+'"></span></label>'
			}),
		DTColumnBuilder.newColumn(null).withTitle($translate.instant('Taux d\'intégration')).withOption('width', '5%').renderWith(function(data, type) {
			return '<label> '+$scope.nombreAvecVirgule(data.TAUX_INTEGRATION)+'%</label>'}),
		DTColumnBuilder.newColumn('LIBELLE_REF').withTitle($translate.instant('Statut')).withOption('width', '20%'),


		];

	function oeExp (data, type, full, meta){
		return '<label class="directive-lien"> '+data+'<span oeexpediteurdetaillovlibre idoe="'+data+'" ></span></label>'
	}
	
	function produit (data, type, full, meta){
		
	}
	$scope.search=0;
	$scope.dtInstance = {};

	function createdRow(row, data, dataIndex) {
		$compile(angular.element(row).contents())($scope);
		
		 if ( data.TAUX_INTEGRATION >= 70 )
      {
          $('td', row).css('background-color', '#E2FAE2');
      }
	}
	$scope.nombreAvecVirgule = function(aa)
	{
		var a =parseFloat(aa)
		if(a!=100){
		return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
		}
		else return a;
	}

	/*******************************/

	$scope.change = function(oeExpediteurSelected) {
		if (oeExpediteurSelected != undefined  || oeExpediteurSelected != null) {
			$scope.oeExpediteur = oeExpediteurSelected;

		}
	}

	$scope.searchAspect = function(searchStr) {
		if(searchStr.val == undefined || searchStr.val == null  || searchStr.val == "") {
			var searchStrEncoded = null;
		} else {
			var searchStrEncoded = searchStr.val// escape(searchStr);
		}
		return $http.get("api/getAllExpediteurDTOByRaison/"+ searchStrEncoded).then(function(data) {
			$scope.states = data.data;
			return data.data;
		});

	};

	/*******************************/
	
	
	$scope.lovModelStatut = 'api/referentiels/getSousRefByCode/ETPROC/'+$scope.typeProcess;// this will be
	// the LOV
	// datasource
	$scope.lovTitleStatut = 'Liste des villes';
	$scope.lovColumnListStatut = [  'Libellé' ];
	$scope.lovFieldListStatut = [  'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBackStatut = function(result) {
		$scope.rechercheDTO.statutDTO = result;
	}
	
	
	
	/***********************************/
	
	
	

	
	$scope.reset = function() {
		$state.go('consultAllDT', null, {
			reload : true
		});

	}


	$scope.myAccordionStatut = true;
	$scope.opened = function() {
		if ($scope.myAccordionStatut) {
			return "#collapse2";
		}
	}

	$(function() {
		var active = true;

		$('#collapse-init').click(function() {
			if (active) {
				active = false;
				$('.panel-collapse').collapse('show');
				$('.panel-title').attr('data-toggle',
				'');
				$(this).text(
				'Enable accordion behavior');
			} else {
				active = true;
				$('.panel-collapse').collapse('hide');
				$('.panel-title').attr('data-toggle',
				'collapse');
				$(this).text(
				'Disable accordion behavior');
			}
		});

		$('#myAccordion').on('show.bs.collapse', function() {
			if (active) {
				$('#myAccordion2 .in').collapse('hide');
			}
		});
		$('#myAccordion').on('hide.bs.collapse', function() {
		});

		$('#myAccordion2').on('show.bs.collapse', function() {
			$scope.myAccordionStatut = false;
			if (active) {
				$('#myAccordion .in').collapse('hide');
			}
		});
		$('#myAccordion2').on('hide.bs.collapse', function() {
			$scope.myAccordionStatut = true;
		});

	});
	$('#myAccordion .panel-collapse').on('shown.bs.collapse',function() 
			{$(this).prev().find(".a-callapseStyle ")
		.removeClass("fa fa-plus").addClass(
		"fa fa-minus");
			});

	// The reverse of the above on hidden event:

	$('#myAccordion .panel-collapse').on('hidden.bs.collapse',function() {
		$(this).prev().find(".a-callapseStyle ")
		.removeClass("fa fa-minus").addClass(
		"fa fa-plus");
		// $scope.statut=false;
	});
	$('#myAccordion2 .panel-collapse').on('shown.bs.collapse',function() {
		$(this).prev().find(".a-callapseStyle ")
		.removeClass("fa fa-plus").addClass(
		"fa fa-minus");
	});

	// The reverse of the above on hidden event:

	$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse',function() {
		$(this).prev().find(".a-callapseStyle ")
		.removeClass("fa fa-minus").addClass(
		"fa fa-plus");
		// $scope.statut=false;
	});
	
}
})();
