(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('CertificatOrigineDetailEditController', CertificatOrigineDetailEditController);

	CertificatOrigineDetailEditController.$inject = ['ReferentielWs', 'Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile','AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function CertificatOrigineDetailEditController (ReferentielWs, Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.dtInstance={};
		$scope.codeProcess ="CO"
		$scope.idEntity = $stateParams.idEntity;  
		$scope.attinfcomp="ATTINFCOMP"
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});
		
			$scope.idAgenda = $stateParams.id;
			
		 Certificat.getDemandeByProcess($scope.idAgenda).then(function (result) {
			$scope.certificat={};
			$scope.agenda = result.data.agenda;
			$scope.pieceJointeDTOs = result.data.pieceJointeDTOs;
			$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
			$scope.agenda = result.data.agenda;
			$scope.certificat.certificatOrigine = result.data.certificatOrigine ;
	        $scope.certificat.oeDestinataire = $scope.agenda.oeDestinataire;
			$scope.certificat.facture = result.data.facture;
			$scope.certificat.autresServices = result.data.autresServices
			$scope.certificat.detailFactureList = result.data.detailFactureList;
			$scope.certificat.facture.total=parseFloat($scope.certificat.facture.total);
			$scope.certificat.autresServices.copieCO =parseInt($scope.certificat.autresServices.copieCO);
			$scope.dateFacture = new Date($scope.certificat.facture.dateFacture)
			
			 $http.get("api/listBouton/"+$scope.certificat.facture.process.idCase).then(function(result) {
				  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
				  	$scope.listButton=result.data;
				  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
				  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,certificat)" ng-disabled="DForm.$invalid || SForm.$invalid || isSaving || factureList.length==0" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
				  	// ajouter le ou les  boutons dans la balise boutonWK
				  	// l'id #boutonWK est ajouté au niveau html
				  	angular.element("#boutonWK").append( $compile(html)($scope));
				  })
		        	 });
			$scope.dtOptionsConsolide = DTOptionsBuilder
			.newOptions()
			// or here
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
				.withOption('fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {
					var columnas = [5]; //the columns you wish to add
					var secondRow = $(nRow).next()[0];
					for (var j in columnas) {
						var columnaActual = columnas[j];
						var totalFiltre = 0;
						var devise = " ";
						var total = 0;
						for (var i = 0; i < aiDisplay.length; i++) {
							totalFiltre = totalFiltre + parseFloat($scope.certificat.detailFactureList[i].nombreUnite * $scope.certificat.detailFactureList[i].valeurUnite);
						}
						for (var i = 0; i < aasData.length; i++) {
							total = total + parseFloat($scope.certificat.detailFactureList[i].nombreUnite * $scope.certificat.detailFactureList[i].valeurUnite);
						}
						$scope.totalSystem=total;
						$($(nRow).children().get(columnaActual)).html(total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",") + " TND");
						
					} // end
				})
				
				
				$scope.dtColumnsConsolide = [
					DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(1).withTitle("Quantité").withOption('width', '10%'),
					DTColumnDefBuilder.newColumnDef(2).withTitle("Valeur (TND)").withOption('width', '10%'),
					DTColumnDefBuilder.newColumnDef(3).withTitle("Nombre / Nature de colis").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(4).withTitle("Poids Net / Brut").withOption('width', '10%'),
					DTColumnDefBuilder.newColumnDef(5).withTitle("Total ligne (TND)").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(6).withTitle("Actions").withOption('width', '10%')
					];
			
			
			function createdRow(row, data, dataIndex) {
				// Recompiling so we can bind Angular directive to the DT
				$compile(angular.element(row).contents())($scope);
			}
			/*get file dec tresor from alfreco*/
			$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2
			.downloadDocument(docId)
			.then(
			function(result) {
			   	if(angular.equals( { status: result.status }, { status: 500 } )){
			swal(message,"Un problème s'est produit!", "error")
			}
			var fName = result
			.headers('Content-Disposition');
			var file = new Blob(
			[ result.data ],
			{
			type : 'application/pdf'
			});
			var fileURL = (window.URL || window.webkitURL)
			.createObjectURL(file);
			downloadLink.href = fileURL;
			downloadLink.download = fName;
			downloadLink.click();
			});
			}
			  function createdRow(row, data, dataIndex) {
				  $compile(angular.element(row).contents())($scope);
				  }
			  $scope.traitement = function(keyBouton,value,certificat){
					$scope.totalFinal = ($scope.totalSystem + $scope.totalAutreFrais).toFixed(3) ;			  
				  certificat.facture.dateFacture = $scope.dateFacture;
				  certificat.pieceJointeDTOs =  $scope.pieces; 
				  certificat.oeExpediteur =  $scope.agenda.oeExpediteur; 
					Agenda.updateCertificat(certificat, $scope.idAgenda).then(function (result) {
						var uploadUrl = "api/uploadFile/"+certificat.facture.id;
						 var file = $scope.docFacture ;
							if(typeof file !='undefined'){
								var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
										function(result) {
											$scope.piece = result.data;
										});
						}
							
					});
				  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
					  
					  $state.go('home', null, { reload: true } );
			 });

				}

			  function createdRow(row, data, dataIndex) {
				  $compile(angular.element(row).contents())($scope);
				  }
				  $scope.$on('eventEmitedFacture', function(event, data) {
					  $scope.docFacture = data.message;
				  });
					$(function() {
						var active = true;

						$('#collapse-init').click(function() {
							if (active) {
								active = false;
								$('.panel-collapse').collapse('show');
								$('.panel-title').attr('data-toggle', '');
								$(this).text('Enable accordion behavior');
							} else {
								active = true;
								$('.panel-collapse').collapse('hide');
								$('.panel-title').attr('data-toggle', 'collapse');
								$(this).text('Disable accordion behavior');
							}
						});

						$('#myAccordion2').on('show.bs.collapse', function() {
							if (active){$('#myAccordion2 .in').collapse('hide');}
						});
						$('#myAccordion2').on('hide.bs.collapse', function() {
						});
					});
					$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse', function() {
						$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-minus").addClass("fa fa-plus");

						// $scope.statut=false;
					});
					$('#myAccordion2 .panel-collapse').on('shown.bs.collapse', function() {
						$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-plus").addClass("fa fa-minus");
					});
					function renderer(api, rowIdx, columns) {
						var data = $.map( columns, function ( col, i ) {
							return col.hidden ?
									'<li data-dtr-index="'+col.columnIndex+'" data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+
									'<span class="dtr-title">'+col.title+'</span> '+'<span class="dtr-data">'+col.data+'</span>'+'</li>' :'';
						}).join('');
						return data ?
								$compile(angular.element($('<ul data-dtr-index="'+rowIdx+'"/>').append( data )))($scope) :
									false;
					}
					 $scope.updateCO = function(demande) {
								Agenda.updateCertificat(demande).then(function (result) {
								 swal({
								  position: 'center',
								  type: 'success',
								  title: 'Demande est ajoutée avec succèes',
								  showConfirmButton: false,
								  timer: 2000
								})
								var uploadUrl = "api/uploadFile/"+result.data.id;
								 var file = $scope.docFacture ;
									if(typeof file !='undefined'){
										var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
												function(result) {
													$scope.piece = result.data;
												});
								}
							});
							$state.go('home', null, { reload: true } );
						}
						$scope.$on('eventEmitedEditFacture', function(event, data) {
							$scope.factureUpdate = data.message;
							$scope.index = data.index;
							$scope.certificat.detailFactureList[$scope.index] = $scope.factureUpdate;
									  });
						$scope.update = function(index) {
							  if (index != undefined) {
								  $scope.factureUpdate = $scope.certificat.detailFactureList[index];
								  $state.go( 'facture-detail-edit-infoComplementaire', {facture :$scope.factureUpdate, index : index });
							  }
							}
						
					
						
						$scope.nombreAvecVirgule = function(aa)
						{
							var a =parseFloat(aa)
							return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
						}
						
						
						
						$scope.dtOptionsNote = DTOptionsBuilder
						.newOptions()
						.withOption('ajax', {
							url: 'api/findNoteByProcess/'+$scope.idAgenda+'/ATTINFCOMP',
							type: 'GET',
							
						})
					.withOption('createdRow', createdRow)
					.withPaginationType('full_numbers')
					.withOption('order', [0, 'asc'])
					.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
					.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
		$scope.dtColumnsNote = [
			DTColumnBuilder.newColumn('idUserCre.login').withTitle('Créateur').withOption('width', '35%'),
			DTColumnBuilder.newColumn('description').withTitle('Description').withOption('width', '35%'),
			DTColumnBuilder.newColumn('dateCre').withTitle('Date').withOption('width', '30%').renderWith(function(data, type) {
		return $filter('date')(data, 'dd/MM/yyyy HH:mm');
		}),
			];
					  function createdRow(row, data, dataIndex) {
						  $compile(angular.element(row).contents())($scope);
						  }
			  
					  
					  $scope.lovModel5 = 'api/referentiels/getTypeCO';// this will be
						// the LOV
						// datasource
						$scope.lovTitle5 = 'Liste des types de certificat d\'origine';
						$scope.lovColumnList5 = [  'Libellé' ];
						$scope.lovFieldList5 = [  'libelle' ];
						// function to be called when you click "choose" button
						$scope.lovCallBack5 = function(result) {
							$scope.certificat.certificatOrigine.typeCo = result;
						}
						
						
						
						$scope.lovModel9 = 'api/referentiels/allPays';// this will
						// be
						// the LOV
						// datasource
						$scope.lovTitle9 = 'Liste des pays';
						$scope.lovColumnList9 = [ 'Libellé' ];
						$scope.lovFieldList9 = [ 'libelle' ];
						// function to be called when you click "choose" button
						$scope.lovCallBack9 = function(result) {
						$scope.agenda.oeExpediteur.pays = result;
						}
						
						
						$scope.removeItem = function(index){
							$scope.items.splice(index, 1);
						}
						
						var unsubscribe1 = $scope.$on('eventEmitedPiece', function(event, result) {
							$scope.pieces = result.message;
							$scope.pieceValide = result.pieceValide;

						});
						$scope.$on('$destroy', unsubscribe1);
								  
					  
}
})();
