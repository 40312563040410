(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('oeexpediteurdetaillovlibre', oeexpediteurdetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('oeExpediteurDetailLibreCtrl', oeExpediteurDetailLibreCtrl);

	oeexpediteurdetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	oeExpediteurDetailLibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','CustomProcessBonita' ,'DTColumnDefBuilder','idoe' ,'OeExpediteur'];

	function oeexpediteurdetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				idoe:'@'
			},
			template: '<button style="background-color: transparent;width:20px;height:20px;border: none;" ng-click="openPopUp(idoe)">' +

			'</button>',
			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(idoe) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/oeExpediteurDetailTemplate.html',
							controller : 'oeExpediteurDetailLibreCtrl',
							resolve: {
								idoe: function () {
									return idoe;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
			                        $translatePartialLoader.addPart('oeExpediteur');
			                        $translatePartialLoader.addPart('adresse');
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function oeExpediteurDetailLibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,CustomProcessBonita ,DTColumnDefBuilder,idoe ,OeExpediteur) {

		//TODO
		OeExpediteur.get({id : idoe}, function(result) {
			$scope.oeExpediteur = result;
		});


		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
