(function() {
	'use strict';

	angular.module('infochamApp').controller('StatistiquesCOController',
			StatistiquesCOController);

	StatistiquesCOController.$inject = [ 'ReferentielWs', 'ReportingService',
			'Inscription2', 'DTColumnDefBuilder', '$rootScope', 'fileUpload',
			'Agenda', 'Referentiel', '$stateParams', '$translate',
			 '$scope', 'Principal', '$state', '$http',
			'$compile', 'AuditsService', '$filter', 'DTOptionsBuilder',
			'DTColumnBuilder' ];

	function StatistiquesCOController(ReferentielWs, ReportingService,
			Inscription2, DTColumnDefBuilder, $rootScope, fileUpload, Agenda,
			Referentiel, $stateParams, $translate, $scope,
			Principal, $state, $http, $compile, AuditsService, $filter,
			DTOptionsBuilder, DTColumnBuilder) {
		$scope.statistique = {}
		var vm = this;

		Principal.identity().then(function(account) {
			$scope.account = account;
			if ($scope.account == null) {
				$state.go('login');
			}
			Inscription2.getUserByLogin(account.login).then(function(result) {
				$scope.user = result.data;
			});

		});
		var anneeDeb = $filter('date')(new Date(), 'yyyy');
		;
		$scope.annees = [];
		for (var i = 0; i < 50; i++) {
			$scope.annees[i] = {
				id : i,
				ann : anneeDeb - i
			};

		}
		$scope.valider = function(statistique) {
			Agenda
					.getCOStatistiqueAnnee(statistique)
					.then(
							function(result) {
								$scope.statistiqueAnneeList = result.data;
								$scope.labels = [ 'Janvier', 'Février', 'Mars',
										'Avril', 'Mai', 'Juin', 'Juillet',
										'Aout', 'Septembre', 'Octobre',
										'Novembre', 'Décembre' ];
								$scope.series = [];
								$scope.data = [];
								$scope.totalStat = [];
								for (var i = 0; i < $scope.statistiqueAnneeList.length; i++) {
									$scope.series
											.push($scope.statistiqueAnneeList[i].annee);
									var stat = [];
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreJan))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreFev))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreMar))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreAvr))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreMai))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreJuin))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreJuil))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreAout))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreSept))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreOct))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreNov))
									stat
											.push(parseInt($scope.statistiqueAnneeList[i].nbreDec))

									if (statistique.montant != null) {
										$scope.totalStat
												.push($scope.statistiqueAnneeList[i].total)
									} else {
										$scope.totalStat
												.push($scope.statistiqueAnneeList[i].totalA)
									}

									$scope.data.push(stat);
								}
							});
		}

		$scope.dtOptionsConsolide = DTOptionsBuilder
				.newOptions()
				// or here
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')

				//    	.withOption('order',[ 0, 'desc' ] )
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")

				.withBootstrap()
				.withLanguageSource($translate.instant('global.datatable'))
				.withOption(
						'fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {

							var columnas = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
									12, 13 ]; //the columns you wish to add
							var secondRow = $(nRow).next()[0];
							$scope.totalSomme = [];
							for ( var j in columnas) {

								var columnaActual = columnas[j];
								var totalFiltre = 0;
								var devise = " ";
								var total = 0.0;
								var totalA = 0;
								for (var i = 0; i < aiDisplay.length; i++) {

									totalFiltre = totalFiltre
											+ parseFloat(aasData[aiDisplay[i]][columnaActual]
													.replace(/&nbsp;/g, ''));

								}
								for (var i = 0; i < aasData.length; i++) {

									if ($scope.statistique.montant != null) {
										total = total
												+ parseFloat(aasData[i][columnaActual]
														.replace(/&nbsp;/g, ''));
									} else {
										totalA = totalA
												+ parseFloat(aasData[i][columnaActual]
														.replace(/&nbsp;/g, ''));

									}
								}
								//    						$($(nRow).children().get(columnaActual)).html(total.toString());

								if ($scope.statistique.montant != null) {
									$($(nRow).children().get(columnaActual))
											.html(
													total
															.toFixed(3)
															.toString()
															.replace(
																	/\B(?=(\d{3})+(?!\d))/g,
																	" ")
															+ " TND");
									$scope.totalSomme.push(total)
								} else {
									$($(nRow).children().get(columnaActual))
											.html(
													totalA
															.toString()
															.replace(
																	/\B(?=(\d{3})+(?!\d))/g,
																	" "));
									$scope.totalSomme.push(totalA)
								}

							} // end

						})
		$scope.dtColumnsConsolide = [
				DTColumnDefBuilder.newColumnDef(0).withTitle("Bureau"),
				DTColumnDefBuilder.newColumnDef(1).withTitle("Nombre Adhésion")
						.withOption('className', 'text-right'),
				DTColumnDefBuilder.newColumnDef(2).withTitle("Nombre CO")
						.withOption('className', 'text-right'),
				DTColumnDefBuilder.newColumnDef(3).withTitle("Recette CO")
						.withOption('className', 'text-right'),
				DTColumnDefBuilder.newColumnDef(4).withTitle("Spécimen")
						.withOption('className', 'text-right'),
				DTColumnDefBuilder.newColumnDef(5).withTitle("Visa Document").withOption(
						'className', 'text-right'),
				DTColumnDefBuilder.newColumnDef(6).withTitle("Attestation")
						.withOption('className', 'text-right'),
//				DTColumnDefBuilder.newColumnDef(7).withTitle("ALV")
//						.withOption('className', 'text-right'),
//				DTColumnDefBuilder.newColumnDef(8).withTitle("AO")
//						.withOption('className', 'text-right'),
//				DTColumnDefBuilder.newColumnDef(9).withTitle("Septembre")
//						.withOption('className', 'text-right'),
//				DTColumnDefBuilder.newColumnDef(10).withTitle("Octobre")
//						.withOption('className', 'text-right'),
//				DTColumnDefBuilder.newColumnDef(11).withTitle("Novembre")
//						.withOption('className', 'text-right'),
//				DTColumnDefBuilder.newColumnDef(12).withTitle("Décembre")
//						.withOption('className', 'text-right'),
				DTColumnDefBuilder.newColumnDef(13).withTitle("Total")
						.withOption('className', 'text-right') ];

		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}
		$scope.reset = function() {
			$state.go('statistiques_co', null, {
				reload : true
			});

		}

		$scope.dtInstance = {};
		$rootScope.$on('reeload', function(event) {
			$scope.dtInstance._renderer.rerender();
			$scope.$emit('reeloadD');
		});
		$scope.myAccordionStatut = true;
		$scope.opened = function() {
			if ($scope.myAccordionStatut) {
				return "#collapse2";
			}
		}

		$(function() {
			var active = true;

			$('#collapse-init').click(function() {
				if (active) {
					active = false;
					$('.panel-collapse').collapse('show');
					$('.panel-title').attr('data-toggle', '');
					$(this).text('Enable accordion behavior');
				} else {
					active = true;
					$('.panel-collapse').collapse('hide');
					$('.panel-title').attr('data-toggle', 'collapse');
					$(this).text('Disable accordion behavior');
				}
			});

			$('#myAccordion').on('show.bs.collapse', function() {
				if (active) {
					$('#myAccordion2 .in').collapse('hide');
				}
			});
			$('#myAccordion').on('hide.bs.collapse', function() {
			});

			$('#myAccordion2').on('show.bs.collapse', function() {
				$scope.myAccordionStatut = false;
				if (active) {
					$('#myAccordion .in').collapse('hide');
				}
			});
			$('#myAccordion2').on('hide.bs.collapse', function() {
				$scope.myAccordionStatut = true;
			});

		});
		$('#myAccordion .panel-collapse').on(
				'shown.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-plus").addClass("fa fa-minus");
				});

		// The reverse of the above on hidden event:

		$('#myAccordion .panel-collapse').on(
				'hidden.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-minus").addClass("fa fa-plus");
					// $scope.statut=false;
				});
		$('#myAccordion2 .panel-collapse').on(
				'shown.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-plus").addClass("fa fa-minus");
				});

		// The reverse of the above on hidden event:

		$('#myAccordion2 .panel-collapse').on(
				'hidden.bs.collapse',
				function() {
					$(this).prev().find(".a-callapseStyle ").removeClass(
							"fa fa-minus").addClass("fa fa-plus");
					// $scope.statut=false;
				});
		$scope.lovModel1 = 'api/getAllExpediteur';// this will be
		// the LOV
		// datasource
		$scope.lovTitle1 = 'Liste des expediteurs';
		$scope.lovColumnList1 = [ 'Expediteur' ];
		$scope.lovFieldList1 = [ 'raisonSociale' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack1 = function(result) {
			$scope.statistique.raisonSociale = result;
		}

		$scope.lovModel2 = 'api/referentiels/secteur';// this will be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des secteurs';
		$scope.lovColumnList2 = [ 'Libellé' ];
		$scope.lovFieldList2 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.statistique.secteurActivite = result;
		}

		$scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
		// the LOV
		// datasource
		$scope.lovTitle3 = 'Liste des régions';
		$scope.lovColumnList3 = [ 'Libellé' ];
		$scope.lovFieldList3 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack3 = function(result) {
			$scope.statistique.gouvernorat = result;
		}

		$scope.lovModel7 = 'api/referentiels/getTypeCO';// this will be
		// the LOV
		// datasource
		$scope.lovTitle7 = 'Liste des types de certificat d\'origine';
		$scope.lovColumnList7 = [ 'Libellé' ];
		$scope.lovFieldList7 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack7 = function(result) {
			$scope.statistique.typeCo = result;
		}

		ReferentielWs.getAllDestinataire().then(function(result) {
			$scope.destinataires = result.data;
		})
	}
})();
