(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('DocumentPrixDetailController', DocumentPrixDetailController);

	DocumentPrixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'DocumentPrix'];

	function DocumentPrixDetailController ($scope, $rootScope, $stateParams, entity, DocumentPrix) {
        $scope.documentPrix = entity;
        $scope.load = function (id) {
        	DocumentPrix.get({id: id}, function(result) {
                $scope.documentPrix = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:referentielUpdate', function(event, result) {
            $scope.documentPrix = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
