"use strict";

angular.module("lov-directive").directive("reclamationdetaillovlibre", [ '$uibModal', function($uibModal) {
	return {
		restrict : 'A',
		scope : {
			idpersonnemorale:'@',
			iduser:'@',
			objet:'@',
			description:'@' ,
			idreclamation:'@'
		},
		
		template: '  <a style="font-size:16px" ng-click="openPopUp(idpersonnemorale,iduser,objet,description,idreclamation)"><span class="glyphicon glyphicon-eye-open"></span> <spanclass="hidden-xs forme-button"> </span></a>',


		controller : [ '$scope', '$element', function($scope, $element) {

			$scope.openPopUp = function(idpersonnemorale,iduser,objet,description,idreclamation) {

				
				$scope.localPopUp = {
						backdrop: 'static',
						size: 'lg',

						templateUrl : 'app/directives/menu/reclamationDetailTemplate.html',
						controller : 'reclamationDetailLibreCtrl',
						resolve: {
							idpersonnemorale: function () {
								return idpersonnemorale;
							},
							iduser: function () {
								return iduser;
							},
							objet: function () {
								return objet;
							},
							description: function () {
								return description;
							},
							
							idreclamation: function () {
								return idreclamation;
							},
							translatePartialLoader : ['$translate','$translatePartialLoader',
								function($translate, $translatePartialLoader) {
								return $translate.refresh();
							} ]
						}

				};
				var modalInstance = $uibModal.open($scope.localPopUp);
			}
		} ]
	}
} ]).filter('StartOnPage', function() {
	return function(input, start) {
		start = +start;
		return input.slice(start);
	} }).controller(
		'reclamationDetailLibreCtrl', function($http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,idpersonnemorale,iduser,objet,description,idreclamation ) {

			
			$http.get('api/personneMorale/'+idpersonnemorale).then(function(result) {
				$scope.personneMorale = result.data;
			});
			$http.get('api/usersById/'+iduser).then(function(result) {
				$scope.user = result.data;
			});
			
			$http.get('api/reclamationById/'+idreclamation).then(function(result) {
				$scope.noteReclamation = result.data.note;
				if(result.data.decision != null ) {
					$scope.decisionReclamation = result.data.decision.libelleFr;
				}
				else {
					$scope.decisionReclamation = result.data.decision
				}
			});
			
	$scope.objet=objet ;
	$scope.description=description ;
	
	$scope.reclamation = {};
	
	
	
	$http.get('api/referentiels/getByCode/DECISIONREC').then(function(result) {
		$scope.listDecision = result.data;
	});
	
	
	$scope.traitement = function(reclamation) {
		
		$scope.reclamation = reclamation
		$scope.reclamation.idUser = iduser ;
		$scope.reclamation.id=idreclamation ;
		$scope.reclamation.note = reclamation.note ;
		$scope.reclamation.decision = reclamation.decision.id
		Agenda.updateReclamation($scope.reclamation).then(function (result) {
			$state.go('consult-all-reclamation', null, { reload: true } );
			$uibModalInstance.dismiss(true);
		});
		
	}
	$scope.chooseItem = function (result) {
		$uibModalInstance.close(result);
	}

			// when you clic "X" button
			$scope.closeLov = function() {
				$uibModalInstance.dismiss('cancel');
			};
		} );