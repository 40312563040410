(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DemandeAdhesionDialogController', DemandeAdhesionDialogController);

    DemandeAdhesionDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'DemandeAdhesion', 'TypeAdhesion', 'Cci', 'Facture', 'ModeDepot', 'User'];

    function DemandeAdhesionDialogController ($scope, $stateParams, $uibModalInstance, entity, DemandeAdhesion, TypeAdhesion, Cci, Facture, ModeDepot, User) {

        $scope.demandeAdhesion = entity;
        $scope.typeadhesions = TypeAdhesion.query();
        $scope.ccis = Cci.query();
        $scope.factures = Facture.query();
        $scope.modedepots = ModeDepot.query();
        $scope.users = User.query();
        $scope.load = function(id) {
            DemandeAdhesion.get({id : id}, function(result) {
                $scope.demandeAdhesion = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:demandeAdhesionUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.demandeAdhesion.id != null) {
                DemandeAdhesion.update($scope.demandeAdhesion, onSaveSuccess, onSaveError);
            } else {
                DemandeAdhesion.save($scope.demandeAdhesion, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForDateAdhesion = {};

        $scope.datePickerForDateAdhesion.status = {
            opened: false
        };

        $scope.datePickerForDateAdhesionOpen = function($event) {
            $scope.datePickerForDateAdhesion.status.opened = true;
        };
        $scope.datePickerForDateCre = {};

        $scope.datePickerForDateCre.status = {
            opened: false
        };

        $scope.datePickerForDateCreOpen = function($event) {
            $scope.datePickerForDateCre.status.opened = true;
        };
}
})();
