(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('DepotDossierJuridiqueController', DepotDossierJuridiqueController);

    DepotDossierJuridiqueController.$inject = ['$http','SpecimenSignature','$stateParams','Agenda' ,'Principal','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita'];

    function DepotDossierJuridiqueController($http,SpecimenSignature, $stateParams,Agenda ,Principal,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita) {

$scope.type = $stateParams.type;
		Principal.identity().then(function(account) {
			$scope.user = account;

			$http.get("api/findAllAdhesionByExpediteurByYearAndType/"+$scope.user.oeExpediteur.id).then(function(result) {
				
				if (result.data.length == 0) {
					swal("Opération interrompue!", "Vous n'avez pas le pack privilège", "error");
					$state.go("home");
				}
				
				Agenda.findAdhesionByExpediteur($scope.user.oeExpediteur.id).then(function(result){
					if(result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='SOUS' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='MAHD' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='KAIR' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique!='MONA'){
						swal("Opération interrompue!", "Ce service n'est pas disponible", "error");
						$state.go("home");
					}
					
				});

			});
			
			if($scope.type=='RENOUVELLEMENT' ){
				$http.get("api/findAllDossJuridiqueByExpediteurByYearAndType/"+$scope.user.oeExpediteur.id).then(function(result) {
					if (result.data.length == 0) {
						swal("Opération interrompue!", "Vous n'avez pas déposé le dossier juridique", "error");
						$state.go("home");
					}
					

					Agenda.findAdhesionByExpediteur($scope.user.oeExpediteur.id).then(function(result){
						if(result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='SOUS' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='MAHD' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique !='KAIR' && result.data.oeExpediteur.adresseSiege.gouvernorat.codeRubrique!='MONA'){
							swal("Opération interrompue!", "Ce service n'est pas disponible", "error");
							$state.go("home");
						}
						
					});

				});
			}
		});
		
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			
			$scope.adhesion ={};
			$scope.expediteur = {};
			$scope.specimenSignatures = [];
			$scope.specimenSignatureDTOs = [];
			$scope.oeExpediteurDTO= {};
			$scope.demandeSpecimenDTO= {};
			$scope.codePiece= 'DEPOTDOSJUR';
//			$scope.pieceJointeDTOs = $scope.certificat.pieceJointeDTOs;
			$scope.customProcessDTO = {};
			Principal.identity().then(function(account) {
				$scope.user = account;
				
				
					$scope.expediteur = $scope.user.oeExpediteur;
					
	Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
						
						$scope.adhesion = result.data
					});
					
				
			});
			
			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}

			var unsubscribe1 = $scope.$on('eventEmitedPiece', function(event, result) {
				$scope.pieces = result.message;
				$scope.pieceValide = result.pieceValide;

			});
			$scope.depotJuridique = function(customProcessDTO) {
				$scope.errors =null;
				  $scope.authenticationError = false;
				  if ($scope.pieceValide == undefined ||
							 $scope.pieceValide==false) {
							 $scope.errors="Il faut attacher toutes les piéces justificatives!"
							
							 }   
				   if($scope.errors != null){
						  $scope.authenticationError = true;
						  $("html, body").animate({scrollTop: 300},"slow");
				       }
				   if($scope.authenticationError == false){
				$scope.customProcessDTO.oeExpediteurDTO = $scope.expediteur;
				$scope.customProcessDTO.pieceJointeDTOs = $scope.pieces;
					swal($scope.styleSwalConfirmation,function(){
						Agenda.addDossierJuridique(customProcessDTO,$scope.type).then(function (result) {
							if(result.status==200 ){
								swal({
									position: 'center',
									type: 'success',
									title: 'Votre demande a été créée avec succès',
									showConfirmButton: false,
									timer: 2000
								})
							}
							else {
								swal("veuillez réessayer","", "error")
							}
						});
						setTimeout(function() {
							$state.go('home', null, { reload: true } );
						}, 3000);
					});
				   }
				
			}

			
		

		}
})();
