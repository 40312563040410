(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('NoteDepotPhyInfoComplementaireController', NoteDepotPhyInfoComplementaireController);

	NoteDepotPhyInfoComplementaireController.$inject = ['$rootScope','$uibModalInstance','Inscription2','$stateParams','$translate','$scope', 'Principal','$state', '$http', '$compile', 'AuditsService', '$filter','DTOptionsBuilder','DTColumnBuilder'];

	function NoteDepotPhyInfoComplementaireController ($rootScope,$uibModalInstance,Inscription2 ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.idProcess = $stateParams.idProcess;
		$scope.idAgenda = $stateParams.id;
		$scope.key = $stateParams.key;
		$scope.value = $stateParams.value;
		$scope.clear = function clear() {
			$state.go('certificatOrigine-depotPhysique-detail', {
				id : $scope.idAgenda
			}, {
				reload : true
			});
			$uibModalInstance.dismiss('cancel');
		}
		$scope.save = function save(note) {
			note.etapeProcess = $stateParams.key;
			Inscription2.addNote($scope.idAgenda, note).then(
					function(result) {
						setTimeout(function() {
							Inscription2.updateProcInscription($scope.idAgenda,
									$scope.key, $scope.value).then(
									function(result) {
										$state.go('home', null, {
											reload : true
										});
									});
						}, 500);
						$uibModalInstance.dismiss('cancel');
					});
		}
	}
})();
