(function() {
    'use strict';
    angular.module('app.tables', [ 'ui.router', 'datatables', 'datatables.bootstrap', 'datatables.columnfilter', 'datatables.light-columnfilter']);
    angular
        .module('app.tables')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('app.tables', {
            abstract: true,
            data: {
                title: 'Tables'
            }
        })

        .state('tables', {
            url: '/tables/normal',
            parent : 'site',
            data: {
                title: 'Normal Tables'
            },
            views: {
                "content@": {
                    templateUrl: "app/tables/views/normal.html"

                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    //$translatePartialLoader.addPart('sessions');
                    return $translate.refresh();
                }]
            }            
        })
        .state('treeView', {
            url: '/tables/treeview',
            data: {
                title: 'Tree View'
            },
            views: {
                "content@": {
                    templateUrl: 'app/ui/views/tree-view.html',
                    controller: 'TreeviewCtrl'
                }
            }
        })

        .state('datatables', {
            url: '/tables/datatables',
            data: {
                title: 'Data Tables'
            },
            views: {
                "content@": {
                    controller: 'DatatablesCtrl as datatables',
                    templateUrl: "app/tables/views/datatables.html"
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    //$translatePartialLoader.addPart('sessions');
                    return $translate.refresh();
                }]
            }            
            
        })

        .state('jqgrid', {
            url: '/tables/jqgrid',
            data: {
                title: 'Jquery Grid'
            },
            views: {
                "content@": {
                    controller: 'JqGridCtrl',
                    templateUrl: "app/tables/views/jqgrid.html"
                }
            }
        })
    }
})();
