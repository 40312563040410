(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('demandeSpecimen', {
            parent: 'entity',
            url: '/demandeSpecimens',
            data: {
//                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.demandeSpecimen.home.title'
            },
        	params : {
    			customProcess : null,
    			id : null,
    			codeStatut:null
    		},
    		
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeSpecimen/demandeSpecimens.html',
                    //templateUrl: 'app/404.html',
                    controller: 'DemandeSpecimenController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('demandeSpecimen');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        
        
        	.state('traitDemandeSpecimen', {
    		parent: 'entity',
    		url: '/traitDemandeSpecimen',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeSpecimen/demandeSpecimen-dialog.html',
    				controller: 'DemandeSpecimenDialogController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})


    	.state('consultAllSP', {
            parent: 'home',
            url: 'consult-all-SP',
            data: {
          	  authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT','ROLE_EMPLOYEE','ROLE_RESPONSABLE_PAIEMENT'],		
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeSpecimen/recherche/consult-all-SP.html',
                    controller: 'ConsultAllSPController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
      	.state('consultAllSPOE', {
            parent: 'home',
            url: 'consult-all-SP-OE',
            data: {
//          	  authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE_CO','ROLE_DEPOT'],		
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeSpecimen/recherche/consult-all-SP-OE.html',
                    controller: 'ConsultAllSPOEController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }) 
        	.state('paiementDemandeSpecimen', {
    		parent: 'entity',
    		url: '/paiementSpecimen',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeSpecimen/demandeSpecimen-paiement.html',
    				controller: 'DemandeSpecimenPaiementController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
    	      
        	.state('paiementDemandeSpecimen-detail', {
    		parent: 'entity',
    		url: '/paiementSpecimenDetail',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeSpecimen/demandeSpecimen-paiement-detail.html',
    				controller: 'DemandeSpecimenPaiementDetailController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	
    	  	      
        	.state('paiementDemandeSpecimen-edit', {
    		parent: 'entity',
    		url: '/paiementSpecimenEdit',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		params : {
    			customProcess : null,
    			specimenSignatures : null
    		},
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeSpecimen/demandeSpecimen-paiement-edit.html',
    				controller: 'DemandeSpecimenPaiementEditController',
    				controllerAs: 'vm'

    			}
    		},
    		resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
    				$translatePartialLoader.addPart('global');
    				return $translate.refresh();
    			}]
    		}
    	})
    	                .state('note-demandeSP', {
            parent: 'paiementDemandeSpecimen-detail',
            url: '/new/note/noteDemandeDP/{idProcess}',
            data: {
                pageTitle: 'infochamApp.listeReference.home.title'
            },
            params : {
            	key : null,
            	value : null,
            	customProcess: null,
            	id:null
            },
            
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/depotDossierTechnique/Online/note/note-demandeDossTech-infoComplementaire.html',
                    controller: 'NoteDemandeDossTechInfoComplementaireController',
                    size: 'md',
                    resolve: {
                      entity: function () {
                          return {
                              codeListe: null,
                              designationListe: null,
                              status: null,
                          };
                      }
                  }
                }).result.then(function(result) {
                    $state.go('traitDepotDossierExport', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('successPaiement', {
    		parent: 'entity',
    		url: '/success',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeSpecimen/success.html',
    				controller: 'SuccesPaiementController',
    				

    			}
    		},
    		
    	})
    	
    	.state('failPaiement', {
    		parent: 'entity',
    		url: '/failPaiement',
    		data: { pageTitle: 'infochamApp.document.home.title' },
    		
    		views: {
    			'content@': {
    				templateUrl: 'app/entities/demandeSpecimen/fail.html',
    				controller: 'FailPaiementController',
    				

    			}
    		},
    		
    	})
        
       
    ;
        
        
  
}
})();

