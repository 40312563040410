(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ReclamationInfoController', ReclamationInfoController);

	ReclamationInfoController.$inject = ['$rootScope','$uibModalInstance','Inscription2','$stateParams','$translate','$scope','Principal', '$state', '$http','$compile','AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder', 'CustomProcessBonita'];

	function ReclamationInfoController ($rootScope,$uibModalInstance,Inscription2 ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder, CustomProcessBonita) {
		$scope.idAgenda = $stateParams.id;
		$scope.reclamation=$stateParams.reclamation;
        $scope.keyBouton=$stateParams.key;
        $scope.value=$stateParams.value;
		$scope.saveValue={};
		$scope.customProcess =[];
		
		$scope.clear=     function clear () {
//			$state.go('home', {id: $scope.idAgenda}, { reload: true } );
			$uibModalInstance.dismiss('cancel');
		}
		
		$scope.save=   function save (reclamation) {
			Inscription2.updateReclamation(reclamation).then(function (result) {
				Inscription2.updateProcInscription($scope.idAgenda,$scope.keyBouton,$scope.value).then(function (result) {
		 			$state.go('home', null, { reload: true } );
		 });
				$uibModalInstance.dismiss('cancel');
			});
		}
}
})();
