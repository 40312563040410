(function() {
	'use strict';
	angular.module('infochamApp').controller('BanqueDialogController',
			BanqueDialogController);
	BanqueDialogController.$inject = [ 'Banque', '$translate', '$scope',
			'$stateParams', '$uibModalInstance', 'entity', 'Referentiel',
			'TypeReferentiel' ];

	function BanqueDialogController(Banque, $translate, $scope, $stateParams,
			$uibModalInstance, entity, Referentiel, typesCodificationPers) {

		$scope.banque = entity;
		$scope.load = function(id) {
			Banque.get({
				id : id
			}, function(result) {
				$scope.banque = result;

			});
		};
		$scope.lovModel = 'api/referentiels/localite/all';// this will be the LOV
		// datasource

		$scope.lovTitle = "Liste des villes";
		$scope.lovColumnList = [ "Libellé" ];
		$scope.lovFieldList = [ "libelle" ];
		// function to be called when you click "choose" button
		$scope.lovCallBack = function(result) {
			// e parameter contains the whole object for the row selected
			$scope.banque.ville = result;

		}

		var onSaveSuccess = function(result) {
			$scope.$emit('infochamApp:banqueUpdate', result);
			$uibModalInstance.close(result);
			$scope.isSaving = false;

		};
		$scope.typeCod = {}

		var onSaveError = function(result) {
			$scope.isSaving = false;
			if (result.status == 500) {
				swal("Opération interrompue!", "Déja existant", "error");
			} else {

				swal("Opération interrompue!", "", "error");

			}
		};

		$scope.save = function() {
			$scope.isSaving = true;

			if ($scope.banque.id != null) {
				Banque.update($scope.banque, onSaveSuccess, onSaveError);
			} else {
				Banque.save($scope.banque, onSaveSuccess, onSaveError);
			}
		};

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

	}
})();
