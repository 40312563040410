(function() {
    'use strict';

    angular
        .module('infochamApp')
        .directive('uploadsignatureDirective', uploadsignatureDirective)
        .factory("fileReader",fileReader)

    uploadsignatureDirective.$inject = ['$rootScope'];
    fileReader.$inject = ['$q', '$log'];
    function uploadsignatureDirective($rootScope) {
        return {
			restrict: "E",
			scope: {
				target: '=',
			},
			templateUrl: 'app/directives/menu/upload-directive-signature.html',
			controller:['$timeout','fileUpload' ,'FileUploader' ,'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope', '$state', 'ParseLinks',  '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder','$http', 'Principal','fileReader', function ($timeout,fileUpload ,FileUploader ,DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, $state, ParseLinks,  $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder,$http, Principal,fileReader){

				/**file uploader**/
				var uploader = $scope.uploader = new FileUploader({  url: '' });
				$scope.fileList =[];
				$scope.fileNature = {};

				$scope.showUploader= function(){
					if(uploader.queue.length ==0 || $scope.myFile == undefined){
						return "hidden";
					}else {
						return "";
					}
				}

				$scope.showUploaderAlert= function(){
					if(uploader.queue.length >1 && $scope.myFile != undefined){
						return "";
					}else {
						return "hidden";
					}
				}

				$scope.fileAlert= function(){
					if(!($scope.showUploaderAlert()=="hidden")){
						return true;
					}else{
						return false;
					}
				}


				// FILTERS

				// a sync filter
				uploader.filters.push({
					name: 'syncFilter',
					fn: function(item /*{File|FileLikeObject}*/, options) {
						return this.queue.length < 10;
					}
				});

				// an async filter
				uploader.filters.push({
					name: 'asyncFilter',
					fn: function(item /*{File|FileLikeObject}*/, options, deferred) {
						setTimeout(deferred.resolve, 1e3);
					}
				});

				// CALLBACKS
				//test de l'uploader
				uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
				};
				uploader.onAfterAddingFile = function(fileItem) {
					var file = $scope.myFile;
//					console.info('onAfterAddingFile', fileItem);
					for(var i = 0; i<$scope.uploader.queue.length; i++) {
						var itm = $scope.uploader.queue[i];
					}
					 fileReader.readAsDataUrl(file, $scope)
		                .then(function(result) {
		                  $timeout(function() {
		                    $scope.ngModel = result;
		                    $scope.$emit('eventEmitedPhoto',{message:file,image:$scope.ngModel});
		                  });
		                });
						

				};

				uploader.onAfterAddingAll = function(addedFileItems) {
//					console.info('onAfterAddingAll', addedFileItems);
				};
				uploader.onBeforeUploadItem = function(item) {
//					console.info('onBeforeUploadItem', item);
				};
				uploader.onProgressItem = function(fileItem, progress) {
//					console.info('onProgressItem', fileItem, progress);
				};
				uploader.onProgressAll = function(progress) {
//					console.info('onProgressAll', progress);
				};
				uploader.onSuccessItem = function(fileItem, response, status, headers) {
//					console.info('onSuccessItem', fileItem, response, status, headers);
				};
				uploader.onErrorItem = function(fileItem, response, status, headers) {
//					console.info('onErrorItem', fileItem, response, status, headers);
				};
				uploader.onCancelItem = function(fileItem, response, status, headers) {
//					console.info('onCancelItem', fileItem, response, status, headers);
				};
				uploader.onCompleteItem = function(fileItem, response, status, headers) {
//					console.info('onCompleteItem', fileItem, response, status, headers);
				};
				uploader.onCompleteAll = function() {
//					console.info('onCompleteAll');
				};

				$scope.fileExist=false;

				$('.file').click(function(e) { // détecter l'evenemnt du click de l'ajout du fichier
					$scope.fileExist=true;
					$rootScope.$emit('reload');
				});

				//verifier le type du fichier excel xls ou xlsx
				function fileExtension (fileName){
					if(fileName.indexOf("pdf") !== -1){
						return "pdf";
					}

					if(fileName.indexOf("jpeg") !== -1){
						return "jpeg";
					}
					if(fileName.indexOf("png") !== -1){
						return "png";
					}
				}

				$scope.fileList=[];
				$scope.showUploder=true;
				$scope.uploadFile = function(item,codeNature){
					

					var file = $scope.myFile;
					$scope.fileNature.file = $scope.myFile;
					$scope.fileNature.type = codeNature;
					
					
					var fileName= file != undefined ? file.name : "";
					$scope.$emit('eventEmitedName',{message:$scope.fileNature});
				};


			}]
		};
    }

    function fileReader($q,$log) {
	      var onLoad = function(reader, deferred, scope) {
		        return function() {
		          scope.$apply(function() {
		            deferred.resolve(reader.result);
		          });
		        };
		      };

		      var onError = function(reader, deferred, scope) {
		        return function() {
		          scope.$apply(function() {
		            deferred.reject(reader.result);
		          });
		        };
		      };

		      var onProgress = function(reader, scope) {
		        return function(event) {
		          scope.$broadcast("fileProgress", {
		            total: event.total,
		            loaded: event.loaded
		          });
		        };
		      };

		      var getReader = function(deferred, scope) {
		        var reader = new FileReader();
		        reader.onload = onLoad(reader, deferred, scope);
		        reader.onerror = onError(reader, deferred, scope);
		        reader.onprogress = onProgress(reader, scope);
		        return reader;
		      };

		      var readAsDataURL = function(file, scope) {
		        var deferred = $q.defer();

		        var reader = getReader(deferred, scope);
		        reader.readAsDataURL(file);

		        return deferred.promise;
		      };

		      return {
		        readAsDataUrl: readAsDataURL
		      };
		    }
})();

