(function() {
    'use strict';
    angular
        .module('infochamApp')
        .controller('RaisonDialogController', RaisonDialogController);
    RaisonDialogController.$inject = ['Raison','$translate', '$scope','OeExpediteur', '$stateParams', 'Referentiel', 'TypeReferentiel', 'Adresse', 'Responsable'];

    function RaisonDialogController (Raison,$translate,OeExpediteur, $scope, $stateParams,   Referentiel, Adresse, Responsable) {
//        $scope.raison = entity;
    	$scope.raison=[]
            Raison.getExpediteur($stateParams.id).then(function (result){
                $scope.raison = result.data;
            });
       

//        var onSaveSuccess = function (result) {
//            $scope.$emit('infochamApp:raisonUpdate', result);
//            $uibModalInstance.close(result);
//            $scope.isSaving = false;
//        };
//
//        var onSaveError = function (result) {
//            $scope.isSaving = false;
//        };
//
//        $scope.save = function () {
//            $scope.isSaving = true;
//            if ($scope.raison.id != null) {
//            	Raison.update($scope.raison, onSaveSuccess, onSaveError);
//            } else {
//            	Raison.save($scope.raison, onSaveSuccess, onSaveError);
//            }
//        };
//
//        $scope.clear = function() {
//            $uibModalInstance.dismiss('cancel');
//        };
//        $scope.datePickerForDateRccm = {};
//
//        $scope.datePickerForDateRccm.status = {
//            opened: false
//        };
//
//        $scope.datePickerForDateRccmOpen = function($event) {
//            $scope.datePickerForDateRccm.status.opened = true;
//        };
//        $scope.datePickerForDateAdhesion = {};
//
//        $scope.datePickerForDateAdhesion.status = {
//            opened: false
//        };
//
//        $scope.datePickerForDateAdhesionOpen = function($event) {
//            $scope.datePickerForDateAdhesion.status.opened = true;
//        };
}
})();





