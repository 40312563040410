(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeReferentielDialogController', TypeReferentielDialogController);

	TypeReferentielDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'TypeReferentiel','$state'];

	function TypeReferentielDialogController ($scope, $stateParams, $uibModalInstance, entity, TypeReferentiel, $state) {

   	 $scope.typeReferentiel = entity;
        $scope.edit = ('typeReferentiel.edit' == $state.current.name);
        $scope.load = function(id) {
            TypeReferentiel.get({id : id}, function(result) {
                $scope.typeReferentiel = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:typeReferentielUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            if(result.status==500){
				swal("Opération interrompue!", "Déja existant", "error");
				}
				else{
					
					swal("Opération interrompue!", "", "error");

				}
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.typeReferentiel.id != null) {
                TypeReferentiel.update($scope.typeReferentiel, onSaveSuccess, onSaveError);
            } else {
                TypeReferentiel.save($scope.typeReferentiel, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}
})();
