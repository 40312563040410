(function() {
	'use strict';
	angular
		.module('infochamApp')
		.controller('AdresseDeleteController',AdresseDeleteController);
	AdresseDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'Adresse'];
	function AdresseDeleteController($scope, $uibModalInstance, entity, Adresse){
		$scope.adresse = entity;
		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$scope.confirmDelete = function (id) {
			Adresse.delete({id: id},
					function () {
                  		$uibModalInstance.close(true);
                  	});
			};
	}
	
})();

