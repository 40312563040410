(function() {
	'use strict';
	angular.module('infochamApp').config(stateConfig);
	stateConfig.$inject = [ '$stateProvider' ];
	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'adresse',
						{
							parent : 'entity',
							url : '/adresses',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'infochamApp.adresse.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/adresse/adresses.html',
									controller : 'AdresseController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('adresse');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'adresse.detail',
						{
							parent : 'entity',
							url : '/adresse/{id}',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'infochamApp.adresse.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/adresse/adresse-detail.html',
									controller : 'AdresseDetailController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('adresse');
											return $translate.refresh();
										} ],
								entity : [ '$stateParams', 'Adresse',
										function($stateParams, Adresse) {
											return Adresse.get({
												id : $stateParams.id
											});
										} ]
							}
						})
				.state(
						'adresse.new',
						{
							parent : 'adresse',
							url : '/new',
							data : {
								authorities : [ 'ROLE_USER' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/adresse/adresse-dialog.html',
													controller : 'AdresseDialogController',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																adresse : null,
																codePostal : null,
																gouvernorat : null,
																localite : null,
																tel : null,
																fax : null,
																email : null,
																siteWeb : null,
																id : null
															};
														}
													}
												}).result.then(
												function(result) {
													$state.go('adresse', null,
															{
																reload : true
															});
												}, function() {
													$state.go('adresse');
												})
									} ]
						})
				.state(
						'adresse.edit',
						{
							parent : 'adresse',
							url : '/{id}/edit',
							data : {
								authorities : [ 'ROLE_USER' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/adresse/adresse-dialog.html',
													controller : 'AdresseDialogController',
													size : 'lg',
													resolve : {
														entity : [
																'Adresse',
																function(
																		Adresse) {
																	return Adresse
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('adresse', null,
															{
																reload : true
															});
												}, function() {
													$state.go('^');
												})
									} ]
						})
				.state(
						'adresse.delete',
						{
							parent : 'adresse',
							url : '/{id}/delete',
							data : {
								authorities : [ 'ROLE_USER' ],
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/adresse/adresse-delete-dialog.html',
													controller : 'AdresseDeleteController',
													size : 'md',
													resolve : {
														entity : [
																'Adresse',
																function(
																		Adresse) {
																	return Adresse
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('adresse', null,
															{
																reload : true
															});
												}, function() {
													$state.go('^');
												})
									} ]
						});
	}

})();