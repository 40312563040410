(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ScannerApp', ScannerApp);

	ScannerApp.$inject = ['$location','$http' ,'$resource', '$scope', '$state', '$rootScope', '$compile', '$translate', '$cookies', 'CustomNaturePieceJointe'];

	function ScannerApp ($location,$http ,$resource, $scope, $state, $rootScope, $compile, $translate, $cookies, CustomNaturePieceJointe) {$scope.scannerAppConfigUrl = "127.0.0.1";
	$scope.scannerAppConfigPort = $cookies.get('scannerAppConfigPort');
	$scope.appConfigPort = "8085";
	$scope.url = $location.absUrl().split('/')[2];
	$scope.scannerWs = null;
	$scope.scanDocUrl="";  
	$scope.listNaturePieceJointe = [];
	$scope.listKeys = [];
	$scope.test =false;
	$scope.isMultiple = false;
	//Méthode StartScan
	$scope.startScannerApp = function(type, parameters, events) {
		if (type.includes("bulk-")){
			
			$scope.type = type.substring(type.indexOf("-")+1,type.length);
			if ($scope.type.includes("{") && $scope.type.includes("}")&& $scope.type.includes(",")){
				$scope.isMultiple = true;
				$scope.listKeys = [];
				$scope.processNames= "";
				var obj = JSON.parse($scope.type, function (key, value) {
					if (key != ""){
						$scope.listKeys.push(key);
						$scope.processNames = $scope.processNames+"-"+key;
					}
				});
				type = "bulk"+$scope.processNames;
				var codeProcess = parameters.substring(parameters.indexOf("typeEntity=")+11,type.parameters);
				parameters +='&combinedProcess='+$scope.type;
//				CustomNaturePieceJointe.getPieceJointeByCodeProcess($scope.data).then(function (result) {
				CustomNaturePieceJointe.getPieceJointeByCodeProcess(codeProcess).then(function (result) {
					$scope.listNaturePieceJointe = result.data;
    			});
			}else{
				$scope.isMultiple = false;
				$scope.listKeys = [];
				type = type.substring(type.indexOf("\"")+1,type.lastIndexOf("\""));
				
				$scope.listKeys.push(type);
				type = "bulk-"+type;
				$scope.listNaturePieceJointe = [];
//    			$http.get('api/findNaturePieceByListCodeProcess/'+$scope.listKeys).then(function (result) {
//    				$scope.listNaturePieceJointe = result.data;
//    			});
			}
		}
		
		$scope.session = $cookies.get('JSESSIONID');
		if($cookies.get('scandebug') == 'true') {
			parameters += '&debug=true';
		}
		parameters += '&appPort='+$scope.appConfigPort;
		//Appel au projet de scan + passage de paramétres nécessaires
		window.location.href = "infocham-scan://?type=" +type + "&appUrl=" + $scope.url + "&http-port=" + $scope.scannerAppConfigPort + "&session=" + $scope.session + "&" + parameters;
		$scope.openScanWebSocket(1, type, events);
	};
	
	//Méthode openScanWebSocket
	$scope.openScanWebSocket = function(attempt, type, events) {
		setTimeout(function() {
			
			if ($scope.scannerAppConfigPort == null){
				$scope.scannerAppConfigPort = 8862;
			}
			
			$scope.scannerWs = new WebSocket("ws://localhost:"+$scope.scannerAppConfigPort+"/scan");
			$scope.scannerWs.onopen = function() {
				if (events && typeof events.onopen != 'undefined') {
					events.onopen();
				}
			};
			$scope.scannerWs.onerror = function(error) {
				if (events && typeof events.onerror != 'undefined') {
					events.onerror(error);
				}
				if(attempt <= 3) {
					$scope.openScanWebSocket(attempt + 1, type, events);
					return ;
				}
			};
			$scope.array={};
			$scope.showScan=true;
			$scope.scannerWs.onmessage = function(e) {
				if (events && typeof events.onmessage != 'undefined') {
					events.onmessage(e);
				}
				if(e.data) {
					if (e.data.startsWith('success:')) {
						$scope.showScan=false;
						var node = JSON.parse(e.data.slice(8));
						$rootScope.node=node;
						$scope.array = node;
						$scope.isArray="";
						if(node instanceof Array) {
							$scope.isArray = true;
							for(n in node) {
								$scope.scanDocUrl = node[n].id;
								$scope.scanDocNaturePJ = node[n].typeNatureDTO.description;
								$scope.scanDocFileName = node[n].fileName;
							}
		    				$scope.$emit('ppMasse', {piece:node});  
						}
						else  {
							$scope.isArray = false;
							$scope.scanDocUrl = node.id;
							$scope.scanDocNaturePJ = node.typeNatureDTO.description;
							$scope.scanDocFileName = node.fileName;
		    				$scope.$emit('pp', {piece:node});  
						};
					} else if(e.data.startsWith('getdocumenttypes')) {
						var documenttypes = "";
						var i = 0;
						for(i in $scope.listNaturePieceJointe) {
							documenttypes += ";";
							documenttypes += $scope.listNaturePieceJointe[i].id + ":" + $scope.listNaturePieceJointe[i].description;
						}
						$scope.scannerWs.send('documenttypes:' + documenttypes);
					}
				}
			};
			$scope.scannerWs.onclose = function(e) {
				$scope.test =true;
				   $scope.$broadcast('myEvent', {message: $scope.test,elt:$scope.scanDocFileName});  
				if (events && typeof events.onclose != 'undefined') {
					events.onclose();
				}
			};
		}, 3000); // 3 seconds max to wait for the WS server to start
	};}
})();

