(function() {
	'use strict';
	angular.module('infochamApp').filter('propsFilter', propsFilter);

	propsFilter.$inject = [];

	function propsFilter() {
		return function(items, props) {
			admin
		};
	}

	angular.module('infochamApp').controller('UserManagementDialogController',
			UserManagementDialogController);

	UserManagementDialogController.$inject = [ '$http', '$filter',
			'CreateUser', '$translate', '$state', '$scope', '$stateParams',
			'User', 'JhiLanguageService', '$uibModalInstance' ];

	function UserManagementDialogController($http, $filter, CreateUser,
			$translate, $state, $scope, $stateParams, User, JhiLanguageService,
			$uibModalInstance) {
		$scope.titreRole = " rôles";
		$scope.idUser = $stateParams.login;
		CreateUser.getAllAuthority().then(function(result) {
			$scope.roles = result.data;
		});
		$scope.getObject = function(result) {
			$scope.s = []
			$scope.id = []
			$scope.listResult = result;
			for ( var i in result) {
				$scope.s.push(result[i]);
				$scope.id.push(result[i].id);
			}
			$scope.a = $scope.s.join(" ; ")
			$scope.listIdLoc = $scope.s;
		}
		CreateUser.getUserById($scope.idUser).then(function(result) {
			$scope.listResult = []
			$scope.user = result.data;
			$scope.a = $scope.user.authorities.join(" ; ")
			for ( var i in $scope.user.authorities) {
				$scope.listResult.push($scope.user.authorities[i])
			}
			$scope.siteInit = $scope.user.idAgence;
			var onSaveSuccess = function(result) {
				$state.go('user-management', null, {
					reload : true
				});
				$scope.$emit('reload');
				$scope.isSaving = false;
			};
			var onSaveError = function(result) {
				$scope.isSaving = false;
			};
		});
		var onSaveSuccess = function(result) {
			$scope.isSaving = false;
			$uibModalInstance.close(result);
		};

		var onSaveError = function(result) {
			$scope.isSaving = false;
		};
		$scope.save = function() {
			$scope.user.langKey = "fr";
			$scope.isSaving = true;
			$scope.user.authorities = $scope.listResult;
			if ($scope.user.id != null) {

				User.update($scope.user, onSaveSuccess, onSaveError);
			} else {
				User.save($scope.user, onSaveSuccess, onSaveError);
			}
		};
		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		function saveTrait() {
			$scope.user.authorities = $scope.listIdLoc;
			CreateUser.UpdateUser($scope.user, $scope.user.idAgence).then(
					function(result) {
						$uibModalInstance.close(true);
						$state.go('user-management', null, {
							reload : true
						});
					});
			$scope.active = true;
		}
	}
})();
