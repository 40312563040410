(function() {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('raison', {
            parent: 'entity',
            url: '/raisons',
            data: {
//                authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],
                pageTitle: 'infochamApp.referentiel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/raison/raison.html',
                    controller: 'RaisonController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('referentiel');
                    $translatePartialLoader.addPart('raison');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('raison.view', {
            parent: 'entity',
            url: '/raison/{id}',
            data: {
//                authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],
                pageTitle: 'infochamApp.referentiel.detail.title'
            },
            views: {
                'content@': {
                	 templateUrl: 'app/entities/raison/raison-detail.html',
                     controller: 'RaisonDetailController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('referentiel');
                    $translatePartialLoader.addPart('raison');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'OeExpediteur', function($stateParams, OeExpediteur) {
                    return OeExpediteur.get({id : $stateParams.id});
                }]
            }
        })
   
      .state('raison.edit', {
    	  parent: 'raison',
          url: '/{id}/edit',
          data: {
//              authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],

          },
            views: {
                'content@': {
                	templateUrl: 'app/entities/oeExpediteur/oeExpediteur-dialog.html',
                    controller: 'OeExpediteurDialogController',
                }
            },
            resolve: {
            	 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('referentiel');
                     $translatePartialLoader.addPart('raison');
                     return $translate.refresh();
                 }],
                 entity: ['$stateParams', 'OeExpediteur', function($stateParams, OeExpediteur) {
                     return OeExpediteur.get({id : $stateParams.id});
                
                 }]
            }
        })
//        .state('banque.delete', {
//            parent: 'banque',
//            url: '/{id}/delete',
//            data: {
//                authorities: ['ROLE_ADVANCEDUSER','ROLE_ADMIN'],
//
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/banque/banque-delete-dialog.html',
//                    controller: 'BanqueDeleteController',
//                    size: 'md',
//                    resolve: {
//                        entity: ['Banque', function(Banque) {
//                            return Banque.get({id : $stateParams.id});
//                        }]
//                    }
//                }).result.then(function(result) {
//                    $state.go('banque', null, { reload: true });
//                }, function() {
//                    $state.go('^');
//                })
//            }]
//        });
}
})();




