
(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('ReferentielWs', ReferentielWs);

    ReferentielWs.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

    function ReferentielWs ($http,$resource, DateUtils,$rootScope) {
        return {
        	'getAllGrouvernoratLibelle': function () {

				return  $http.get('api/getAllGourvernoratLibelle') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			'getAllProduitLibelle': function () {

				return  $http.get('api/referentiels/getAllProduitLibelle') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			
			'getAllRaisonSocialeLibelle': function () {

				return  $http.get('api/referentiels/getAllRaisonSocialeLibelle') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			
			'getAllDestinataire': function () {

				return  $http.get('api/referentiels/getAllDestinataire') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			
		 	'getAllUnite': function () {

				return  $http.get('api/referentiels/unites') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getAllGouv': function () {

				return  $http.get('api/referentiels/gouvernorat') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getAllDevise': function () {

				return  $http.get('api/referentiels/devises') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getAllCO': function () {

				return  $http.get('api/referentiels/certificats') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			'getSignataire': function () {

				return  $http.get('api/referentiels/getSignataire') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getAllNationalite': function () {

				return  $http.get('api/referentiels/allNationalites') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getAllPays': function () {

				return  $http.get('api/referentiels/allPays') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getPaysByCode': function (code) {

				return  $http.get('api/referentiels/getPaysByCode/'+code) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getReferentielByCode': function (code) {

				return  $http.get('api/referentiels/getByCode/'+code) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getTypeOperateur': function () {

				return  $http.get('api/referentiels/getTypeOperateur') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getReferentielById': function (id) {

				return  $http.get("api/referentiels/getReferentielById/"+id) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			 'addProduit': function (detailFactureList) {
				 	return $http({
						method : "PUT",
						url : "api/referentiels/addProduit",
						dataType: "json",
						traditional: true,
						data : detailFactureList,
						headers : {
							'Content-Type' : 'application/json'	}
					}).then(function(response) {
		            	$rootScope.status = response.status;
		            	return response;
		            	}, function(error) {
		            	$rootScope.status = error.status;
		            	return error;
		            	});
				},
				'findReferentielByTypeProcess':function(){
					return $http.get('api/findReferentielByTypeProcess');
				},
				
				
				'getAllProduitByUser': function (idUser) {

					return  $http.get("api/referentiels/getAllProduitByUser/"+idUser) .success(function (data){
				    })
				    .error(function (error, status){
				  }); 
				},
				'getGouvernoratByCci': function (codeCci) {

					return  $http.get("api/referentiels/getGouvernoratByCci/"+codeCci) .success(function (data){
				    })
				    .error(function (error, status){
				  }); 
				},
				
				'getGouvernoratOnlyByCci': function (codeCci) {

					return  $http.get("api/referentiels/getGouvernoratOnlyByCci/"+codeCci) .success(function (data){
				    })
				    .error(function (error, status){
				  }); 
				},
				
				'getGouvernoratNotAffected': function () {

					return  $http.get("api/referentiels/getGouvernoratNotAffected") .success(function (data){
				    })
				    .error(function (error, status){
				  }); 
				},
				
				 'updateGouvByCci': function (gouvList,codeCci) {
					 	return $http({
							method : "POST",
							url : "api/referentiels/updateGouvByCci/"+codeCci,
							dataType: "json",
							traditional: true,
							data : gouvList,
							headers : {
								'Content-Type' : 'application/json'	}
						}).then(function(response) {
			            	$rootScope.status = response.status;
			            	return response;
			            	}, function(error) {
			            	$rootScope.status = error.status;
			            	return error;
			            	});
					},
        };
    }
})();