(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$state','CreateUser','$scope', '$translate', '$timeout', 'Auth'];

    function RegisterController ($state,CreateUser,$scope, $translate, $timeout, Auth) {
        $scope.success = null;
        $scope.error = null;
        $scope.doNotMatch = null;
        $scope.errorUserExists = null;
        $scope.registerAccount = {};
		   CreateUser.getAllAuthority().then(function (result) {
	        	
			   $scope.roles = result.data;
	        	 
	        });
    $scope.getObject = function(result) {
		$scope.s=[]
		$scope.id=[]
		$scope.listResult=result;
		
		for(var i in result){
		$scope.s.push( result[i]);
		$scope.id.push(result[i].id);
		}
		$scope.a=$scope.s.join(" ; ")
		$scope.listIdLoc=$scope.s;
		
		}
  $(".toggle-password").click(function() {
    
    	    	   $(this).toggleClass("fa-eye fa-eye-slash");
    	    	   var input = $($(this).attr("toggle"));
    	    	   if (input.attr("type") == "password") {
    	    	     input.attr("type", "text");
    	    	   } else {
    	    	     input.attr("type", "password");
    	    	   }
    	    	 });
        $scope.register = function () {
        	$scope.registerAccount.authorities=$scope.listResult;
            if ($scope.registerAccount.password !== $scope.confirmPassword) {
                $scope.doNotMatch = 'ERROR';
                swal("Le mot de passe et sa confirmation ne sont pas égaux!","", "error")
            } else {
                $scope.registerAccount.langKey = $translate.use();
                $scope.doNotMatch = null;
                $scope.error = null;
                $scope.errorUserExists = null;
                $scope.errorEmailExists = null;

                Auth.createAccount($scope.registerAccount).then(function (response) {
                    $scope.success = 'OK';
                    swal({
	           			  position: 'center',
	           			  type: 'success',
	           			  title: 'Utilisateur est ajouté avec succès',
	           			  showConfirmButton: false,
	           			  timer: 2000
	           			})
	           			$state.go('user-management', null, { reload: true } );
	           			
                }).catch(function (response) {
                    $scope.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                    	swal("Ce compte utilisateur existe déjà","Veuillez en choisir un autre.", "error")
                        $scope.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                    	swal("Cet E-mail est déjà utilisé!","Veuillez en choisir un autre.", "error")
                        $scope.errorEmailExists = 'ERROR';
                    } else {
                    	swal("veuillez réessayer","", "error")
                        $scope.error = 'ERROR';
                    }
                });
            }
        };
    }
})();
