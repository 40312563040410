(function() {
	'use strict';
	angular.module('infochamApp').controller('UserManagementController',
			UserManagementController);

	UserManagementController.$inject = [ '$filter', '$compile',
			'DTOptionsBuilder', '$translate', 'DTColumnBuilder', '$rootScope',
			'$scope', 'Principal', 'User', 'ParseLinks' ];

	function UserManagementController($filter, $compile, DTOptionsBuilder,
			$translate, DTColumnBuilder, $rootScope, $scope, Principal, User,
			ParseLinks) {
		$scope.users = [];
		$scope.dtInstance = {};
		$scope.lien = 'api/Allusers';

		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption('ajax', {
					url : $scope.lien,
					type : 'GET',

				})
				.withOption('createdRow', createdRow)

				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					},
					'4' : {
						type : 'text'
					},
					'5' : {
						type : 'text'
					},
					'6' : {
						type : 'text'
					},
					'7' : {
						type : 'text'
					},

				})

		$scope.dtColumns = [
				DTColumnBuilder.newColumn('login').withTitle(
						$translate.instant('user-management.login'))
						.withOption('width', '8%'),
				DTColumnBuilder.newColumn('firstName').withTitle(
						$translate.instant('user-management.lastName'))
						.withOption('width', '15%'),
				DTColumnBuilder.newColumn('lastName').withTitle(
						$translate.instant('user-management.firstName'))
						.withOption('width', '19%'),
				DTColumnBuilder
						.newColumn(null)
						.withTitle("Raison Sociale")
						.withOption('width', '19%')
						.renderWith(
								function(data, type) {
									if (data.oeExpediteur == null) {
										return '<div></div>'
									} else {
										return '<div>'
												+ data.oeExpediteur.raisonSociale
												+ '</div>'
									}
								}),
				DTColumnBuilder.newColumn('email').withTitle(
						$translate.instant('user-management.email'))
						.withOption('width', '14%'),

				DTColumnBuilder.newColumn(null).withTitle(
						$translate.instant('user-management.profiles'))
						.withOption('width', '2%').withOption('className',
								'text-center').renderWith(actionsDroit),
				DTColumnBuilder.newColumn('createdDate').withTitle(
						$translate.instant('user-management.createdDate'))
						.withOption('width', '15%').renderWith(
								function(data, type) {

									return $filter('date')(data, 'dd/MM/yyyy');
								}),

				DTColumnBuilder.newColumn(null).withTitle("Accès").withOption(
						'className', 'text-center').renderWith(acces)
						.withOption('width', '5%'),
				DTColumnBuilder.newColumn(null).withTitle('Actions')
						.notSortable().withClass('datatable-action-width')
						.renderWith(actionsHtml) ]

		$rootScope
				.$on(
						'$translateChangeSuccess',
						function() {
							$scope.dtOptions.withLanguageSource($translate
									.instant('global.datatable'));
							$scope.dtColumns = [
									DTColumnBuilder
											.newColumn('login')
											.withTitle(
													$translate
															.instant('user-management.login'))
											.withOption('width', '8%'),
									DTColumnBuilder
											.newColumn('firstName')
											.withTitle(
													$translate
															.instant('user-management.lastName'))
											.withOption('width', '15%'),
									DTColumnBuilder
											.newColumn('lastName')
											.withTitle(
													$translate
															.instant('user-management.firstName'))
											.withOption('width', '19%'),
									DTColumnBuilder
											.newColumn(null)
											.withTitle("Raison sociale")
											.withOption('width', '19%')
											.renderWith(
													function(data, type) {
														if (data.identiteJuridique == null) {
															return '<div></div>'
														} else {
															return '<div>'
																	+ data.identiteJuridique.raisonSociale
																	+ '</div>'
														}
													}),
									DTColumnBuilder
											.newColumn('email')
											.withTitle(
													$translate
															.instant('user-management.email'))
											.withOption('width', '14%'),
									DTColumnBuilder
											.newColumn(null)
											.withTitle(
													$translate
															.instant('user-management.profiles'))
											.withOption('width', '2%')
											.withOption('className',
													'text-center').renderWith(
													actionsDroit),
									DTColumnBuilder
											.newColumn('createdDate')
											.withTitle(
													$translate
															.instant('user-management.createdDate'))
											.withOption('width', '15%')
											.renderWith(
													function(data, type) {

														return $filter('date')(
																data,
																'dd/MM/yyyy');
													}),

									DTColumnBuilder
											.newColumn(null)
											.withTitle(
													$translate
															.instant('user-management.acces'))
											.withOption('className',
													'text-center').renderWith(
													acces).withOption('width',
													'5%'),
									DTColumnBuilder.newColumn(null).withTitle(
											'Actions').notSortable().withClass(
											'datatable-action-width')
											.renderWith(actionsHtml) ]
						});

		$scope.search = 0;
		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		function edit(id) {

			$state.go("user-management.edit", {
				"log" : id
			});

		}
		function supprimer(id) {

			$state.go("user-management.delete", {
				"log" : id
			});

		}

		$scope.test = function() {

		}
		$scope.userss = [];
		function actionsHtml(data, type, full, meta) {
			$scope.users[data.id] = data;
			return '<div class="action-center" style="margin-top:0px">'
					+ ' <div class="col-md-1"><a href="#/user-management/'
					+ data.id
					+ '/edit">'
					+ '   <i class="fa fa-pencil" style="font-size: 18px;color:#FF931D"></i> '
					+ '</a></div>'
		}
		function actionsDroit(data, type, full, meta) {
			return '<a href="#/user-management/' + data.id
					+ '/droit"  class="badge badge-danger bg-dangerStyle" >'
					+ data.authorities.length + '</a>'
		}

		function acces(data, type, full, meta) {
			$scope.users[data.id] = data;
			if (data.activated) {
				return '<span class="badge badge-success">Activé</span>';
			} else {
				return '<span class="badge badge-danger">Bloqué</span>';
			}

		}
		function language(data, type, full, meta) {
			$scope.users[data.id] = data;
			if (data == 'fr') {
				return '<span>Français</span>';
			} else {
				return '<span>Anglais</span>';
			}
		}
	}
})();
