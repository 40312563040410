(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('health', {
			parent : 'admin',
			url : '/health',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'health.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/admin/health/health.html',
					controller : 'HealthController'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate',
						'$translatePartialLoader',
						function($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('health');
							$translatePartialLoader.addPart('Account');

							return $translate.refresh();
						} ]
			}
		});
	}
})();
