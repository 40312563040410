(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('demandeadhesiondetaillovlibre', demandeadhesiondetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('demandeAdhesionDetailLibreCtrl', demandeAdhesionDetailLibreCtrl);

	demandeadhesiondetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	demandeAdhesionDetailLibreCtrl.$inject = ['OeExpediteur','$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','CustomProcessBonita' ,'DTColumnDefBuilder','typeprocess','idda','DemandeAdhesion','CustomTypeAdhesion'];

	function demandeadhesiondetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				idda:'@',
				typeprocess:'@',
//				idprocess:'@'
			},
			template: '<button style="background-color: transparent;width:20px;height:20px;border: none;" ng-click="openPopUp(idda,typeprocess)">' +

			'</button>',
			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(idda,typeprocess) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/demandeAdhesionDetailTemplate.html',
							controller : 'demandeAdhesionDetailLibreCtrl',
							resolve: {
								idda: function () {
									return idda;
								},
								typeprocess: function () {
									return typeprocess;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function demandeAdhesionDetailLibreCtrl (OeExpediteur,$compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,CustomProcessBonita ,DTColumnDefBuilder,typeprocess,idda,DemandeAdhesion,CustomTypeAdhesion ) {
		$scope.codeRubrique = typeprocess;
		
		$scope.idda = idda;
		//TODO
		DemandeAdhesion.get({id : idda}, function(result) {
			$scope.demandeDadhesion = result;
			CustomTypeAdhesion.getTypeAdhesionByCCI($scope.demandeDadhesion.cciDTO.id).then(function(result) {
				$scope.typeAdhesions = result.data;
			});
			OeExpediteur.get({id : $scope.demandeDadhesion.oeExpediteurDTO.id}, function(result) {
				$scope.oeExpediteur = result;
				if(result.situationAdhesion==true){
					$scope.situationAdhesion="Nouvelle d’adhésion"
				}else{
					$scope.situationAdhesion="renouvellement d’adhésion"
				}

			});
		});

		
		
		
		$scope.getTypeAdhesion = function(id) {
			var single_object =  $filter('filter')($scope.typeAdhesions, function (d) {return d.id == id;})[0];
			if (single_object != undefined && single_object != null  ) {
				$scope.demandeDadhesion.typeAdhesionLibelle = single_object.description + "(" + single_object.montant + " TND)";

			}
			return "";

		}

		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
