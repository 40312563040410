
(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('OeExpediteur', OeExpediteur);

    OeExpediteur.$inject = ['$resource'];

    function OeExpediteur ($resource) {
        return $resource('api/oeExpediteurs/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'updateOeExpediteur': function(oeExpediteur,idProcess){
				return $http({
					method : "PUT",
					url : "api/oeExpediteur/updateOeExpediteur/"+idProcess,
					dataType: "json",
					traditional: true,
					data : oeExpediteur,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();