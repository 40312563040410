(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('CertificatDepotPhysiqueEditController', CertificatDepotPhysiqueEditController);

	CertificatDepotPhysiqueEditController.$inject = ['Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel','$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function CertificatDepotPhysiqueEditController (Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.dtInstance={};
		$scope.idAgenda = $stateParams.id;
		$scope.attdoccomp="ATTDOCCOMP"
		 Certificat.getDemandeByProcess($scope.idAgenda).then(function (result) {
			$scope.agenda = result.data.agenda;
			$scope.facture = result.data.facture;
			$scope.autresServices = result.data.autresService
			$scope.detailFacture = result.data.detailFactureList;
			$scope.modeDepot = result.data.modeDepot;
			$scope.certificatOrigine = result.data.certificatOrigine;
			$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
			$scope.dateCommunication = new Date($scope.modeDepot.dateCommunication)
			
			if($scope.modeDepot.typeDepot.libelle == "Envoi par courrier" ) {
				$scope.typeDepot = "Envoi"
			} else {
				$scope.typeDepot = "Dépot"
			}
			
			 $http.get("api/listBouton/"+$scope.facture.process.idCase).then(function(result) {
				  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
				  	$scope.listButton=result.data;
				  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
				  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value,modeDepot)" ng-disabled="form1.$invalid || isSaving" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
				  	// ajouter le ou les  boutons dans la balise boutonWK
				  	// l'id #boutonWK est ajouté au niveau html
				  	angular.element("#boutonWK").append( $compile(html)($scope));
				  

				  })
		 
	    	 });
		  
		  
			$scope.dtOptionsConsolide = DTOptionsBuilder
			.newOptions()

			// or here
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
			.withOption('order',[ [ 3, 'desc' ], [ 0, 'desc' ] ])
			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withColumnFilter({
					sPlaceHolder: "head:after"
				})

				.withOption('fnDrawCallback',
						function( iStart, iEnd, oSettings) {
					var api = this.api();

					var rows = api.rows({ filter: 'applied' }).nodes();
					var last = null;
					var listGroup=[];
					var listIds=[];
					//somme Montant
					var listNombre=[];
					var listMontant=[];

			        if(iStart._iDisplayStart>0){
			            var k=0;
			            var j=-1;
			            while( k< listIds.length & j==-1){
			                if(listIds[k]==iStart._iDisplayStart){
			                    j=-1;

			                }else if(listIds[k]>iStart._iDisplayStart){
			                    j=k-1;
			                }else if(k+1 == listIds.length){
			                    j=k;
			                }
			                ++k;
			            }
			            if(j!=-1 && listIds[j]!=iStart._iDisplayStart){
			                $(rows)
			                .eq(iStart._iDisplayStart)
			                .before(
									'<tr class="group"><td class="w3-center">' + listGroup[j] + '</td> <td class="footer-sum" >'+$filter('currency')(listNombre[j], '', 0)+'  </td> <td class="footer-sum" >'+$filter('currency')(listMontant[j], '', 0)+' </td></tr>');

			            }
			        }

					for(var j in listIds){

						$(rows)
						.eq(listIds[j])
						.before(
								'<tr class="group"><td class="w3-center">' + listGroup[j] + '</td> <td class="footer-sum" >'+$filter('currency')(listNombre[j], '', 0)+'  </td> <td class="footer-sum" >'+$filter('currency')(listMontant[j], '', 0)+' </td></tr>');
					}

				})
				.withOption('fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {

					var columnas = [5]; //the columns you wish to add
					var secondRow = $(nRow).next()[0];
					for (var j in columnas) {
						var columnaActual = columnas[j];
						var totalFiltre = 0;
						var total = 0;
						for (var i = 0; i < aiDisplay.length; i++) {
							totalFiltre = totalFiltre + parseFloat(aasData[aiDisplay[i]][columnaActual].replace( /&nbsp;/g, '' ));
						}
						for (var i = 0; i < aasData.length; i++) {
							total = total + parseFloat(aasData[i][columnaActual].replace( /&nbsp;/g, '' ));
						}
						$scope.totalSystem=total;
						$($(nRow).children().get(columnaActual)).html(totalFiltre.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '));
					} // end

				})
				$scope.dtColumnsConsolide = [
					DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '30%'),
					DTColumnDefBuilder.newColumnDef(1).withTitle("Qté").withOption('width', '15%'),
					DTColumnDefBuilder.newColumnDef(2).withTitle("Valeur").withOption('width', '15%').notSortable(),
					DTColumnDefBuilder.newColumnDef(3).withTitle("Nbre / Nature de colis").withOption('width', '15%'),
					DTColumnDefBuilder.newColumnDef(4).withTitle("Poids net / brut").withOption('width', '15%'),
					DTColumnDefBuilder.newColumnDef(5).withTitle("Total ligne").withOption('width', '80%')
					];

			function createdRow(row, data, dataIndex) {
				// Recompiling so we can bind Angular directive to the DT
				$compile(angular.element(row).contents())($scope);
			}
			
			/*get file dec tresor from alfreco*/
			$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");

			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2
			.downloadDocument(docId)
			.then(
			function(result) {
			   	if(angular.equals( { status: result.status }, { status: 500 } )){
			swal(message,"Un problème s'est produit!", "error")
			}
			var fName = result
			.headers('Content-Disposition');
			var file = new Blob(
			[ result.data ],
			{
			type : 'application/pdf'
			});
			var fileURL = (window.URL || window.webkitURL)
			.createObjectURL(file);
			downloadLink.href = fileURL;
			downloadLink.download = fName;
			downloadLink.click();
			});
			}
			
			  $scope.traitement = function(keyBouton,value,modeDepot){
				modeDepot.dateCommunication=  $scope.dateCommunication ;
				  Agenda.updateModeDepot(modeDepot).then(function (result) {

					});
				  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
						  
					Certificat.addModeDepot(modeDepot,$scope.typeDepot ,$scope.idAgenda).then(function (result) {
					});	
						 
		 			$state.go('home', null, { reload: true } );
			 			
				  });
				}
			  
				$(function() {
					var active = true;

					$('#collapse-init').click(function() {
						if (active) {
							active = false;
							$('.panel-collapse').collapse('show');
							$('.panel-title').attr('data-toggle', '');
							$(this).text('Enable accordion behavior');
						} else {
							active = true;
							$('.panel-collapse').collapse('hide');
							$('.panel-title').attr('data-toggle', 'collapse');
							$(this).text('Disable accordion behavior');
						}
					});

					$('#myAccordion2').on('show.bs.collapse', function() {
						if (active){$('#myAccordion2 .in').collapse('hide');}
					});
					$('#myAccordion2').on('hide.bs.collapse', function() {
					});
				});
				$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse', function() {
					$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-minus").addClass("fa fa-plus");

					// $scope.statut=false;
				});
				$('#myAccordion2 .panel-collapse').on('shown.bs.collapse', function() {
					$(this).prev().find(".a-callapseStyle ").removeClass("fa fa-plus").addClass("fa fa-minus");
				});
				function renderer(api, rowIdx, columns) {
					var data = $.map( columns, function ( col, i ) {
						return col.hidden ?
								'<li data-dtr-index="'+col.columnIndex+'" data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+
								'<span class="dtr-title">'+col.title+'</span> '+'<span class="dtr-data">'+col.data+'</span>'+'</li>' :'';
					}).join('');
					return data ?
							$compile(angular.element($('<ul data-dtr-index="'+rowIdx+'"/>').append( data )))($scope) :
								false;
				}
				
			function createdRow(row, data, dataIndex) {
				// Recompiling so we can bind Angular directive to the DT
				$compile(angular.element(row).contents())($scope);
			}
				
			$scope.dtOptionsNote = DTOptionsBuilder
			.newOptions()
			.withOption('ajax', {
				url: 'api/findNoteByProcess/'+$scope.idAgenda+'/ATTDOCCOMP',
				type: 'GET',
				
			})
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
			.withOption('order', [0, 'asc'])
			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
			.withOption('order', [0, 'asc'])
			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
			$scope.dtColumnsNote = [
			DTColumnBuilder.newColumn('idUserCre.login').withTitle('Créateur').withOption('width', '35%'),
			DTColumnBuilder.newColumn('description').withTitle('Description').withOption('width', '35%'),
			DTColumnBuilder.newColumn('dateCre').withTitle('Date').withOption('width', '30%').renderWith(function(data, type) {
				return $filter('date')(data, 'dd/MM/yyyy HH:mm');
			}),
			];
			
		  function createdRow(row, data, dataIndex) {
			  $compile(angular.element(row).contents())($scope);
		  }
				
				
}
})();
