(function() {
'use strict';
angular
	.module('infochamApp')
	.factory('ModelePj',ModelePj);
ModelePj.$inject=['$resource'];
function ModelePj($resource){
	return $resource('api/modeles/:id', {}, {
        'query': { method: 'GET', isArray: true},
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                data = angular.fromJson(data);
                return data;
            }
        },
        'update': { method:'PUT' }
    });	
}

})();