(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$sce','$http','$rootScope', '$scope', '$state', '$timeout', 'Auth','Principal', 'AuditsService'];

    function LoginController ($sce,$http,$rootScope, $scope, $state, $timeout, Auth,Principal, AuditsService) {
    	  $scope.user = {};
          $scope.errors = {};
      	$scope.isVisible = "";
      	$scope.notVisible = "";
      	$rootScope.visible=false;
      	$scope.captcha = "";
          $scope.rememberMe = false;
      	Principal.identity().then(function(account) {
    		$scope.account = account;
    		$scope.isAuthenticated = Principal.isAuthenticated;
    		if ($scope.account == null) {
    			if($state.current.name!='inscription'){ 
    			$state.go('login');
    			}
    		} else {
    			if($state.current.name=='login'){
    				$state.go('home');
    			}
    		}
    	});
          $timeout(function (){angular.element('[ng-model="username"]').focus();});
      	$rootScope.$watch('visible', function () {
      		if(!$rootScope.visible){
      			$scope.isVisible = "hidden";
      			$scope.notVisible=""
      		}
      		else{
      			$scope.isVisible = "";
      			$scope.notVisible = "hidden";
      		}
      	});
      	$timeout(function (){ Principal.identity().then(
      			function(user) {
      				var data = {
      						user : user
      				}
      				if(!angular.equals({user: user} ,{user: null})){
      					$rootScope.user =user.firstName+" "+user.lastName;
      					$rootScope.login =user.login;
      					$rootScope.id =user.id;
      					$rootScope.visible=true;
      				}
      			});
      	});
      	 $scope.renderHtml = function (htmlCode) {
             return $sce.trustAsHtml(htmlCode);
         };
         $scope.body = '<script src="//www.google.com/recaptcha/api.js?onload=vcRecaptchaApiLoaded&amp;render=explicit" async defer></script> <div vc-recaptcha key="\'6LfIBQ8kAAAAABXv45NG27-eGjTVSx71tLQPPG28\'" ng-model="captcha"></div>'; 
          $scope.login = function (event) {
        	  
//        	 if($scope.captcha ==null || $scope.captcha == undefined || $scope.captcha == "" ){
//      			 $scope.authenticationErrorCaptch = true;
//      		 }else if($scope.captcha !=null ){
//      			 $scope.authenticationErrorCaptch = false;
//      		 }
        	 
             event.preventDefault();
             
             if($scope.authenticationErrorCaptch!=true){
            	 Auth.login({
                     username: $scope.username,
                     password: $scope.password,
                     rememberMe: $scope.rememberMe
                 }).then(function () {
                	 if($scope.captcha ==null || $scope.captcha == undefined || $scope.captcha == "" ){
              			 $scope.authenticationErrorCaptch = true;
              		 }else{
              			$scope.authenticationError = true;
              		 }
                 	$rootScope.visible=true;
                 	
                 	if ($rootScope.previousStateName === 'register') {
         				$state.go('home',{},{reload:true});
         			} else {
         				$state.go('home',{},{reload:true});

         			}
                 }).catch(function () {
                	 if($scope.captcha ==null || $scope.captcha == undefined || $scope.captcha == "" ){
              			 $scope.authenticationErrorCaptch = true;
              			 $scope.authenticationError = true;
              		 }else{
              			$scope.authenticationError = true;
              		 }
                     
                 });
            	 
            	 $scope.logout = function () {
               		Auth.logout();
               		$rootScope.visible=false;
               		localStorage.clear();
               		$state.go('home',{},{reload:true});
                   }
            	 
             }
             
             
             
          };
          
    }
})();
