(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('PersonnelDeleteController',PersonnelDeleteController);
PersonnelDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'Personnel'];
function PersonnelDeleteController($scope, $uibModalInstance, entity, Personnel){
	$scope.personnel = entity;
    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.confirmDelete = function (id) {
    	Personnel.delete({id: id},
            function () {
                $uibModalInstance.close(true);
            });
    };
}

})();
