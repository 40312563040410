(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('SyntheseController', SyntheseController);

	SyntheseController.$inject = ['ReferentielWs' ,'ReportingService' ,'Inscription2','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$http','$state', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function SyntheseController (ReferentielWs ,ReportingService ,Inscription2,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal,  $http,$state, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		
		$scope.dj = {}
		$scope.typeProcess = "CO";
		$scope.dtInstance1 = {};
		
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});
		$scope.dtOptions = DTOptionsBuilder
		.newOptions()
		.withOption('ajax', {
			url : 'api/getSyntheseByExpediteur/-1',
			type : 'GET',
		})
		.withOption('createdRow', createdRow)

		.withPaginationType('full_numbers')
		.withOption('order', [ 0, 'asc' ])
		.withDOM(
				"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource(
				$translate.instant('global.datatable'))
		.withLightColumnFilter({

			'0' : {
				type : 'text'
			},
			'1' : {
				type : 'text'
			},
			'2' : {
				type : 'text'
			},
			'3' : {
				type : 'text'
			},
			'4' : {
				type : 'text'
			},
			'5' : {
				type : 'text'
			},
			'6' : {
				type : 'text'
			},
			'7' : {
				type : 'text'
			},
			'8' : {
				type : 'text'
			},

		})

$scope.dtColumns = [
		DTColumnBuilder.newColumn('nombreDossJur').withTitle(
				'Dossier juridique').withOption('width', '20%'),
		DTColumnBuilder.newColumn('nombreSp').withTitle('Spécimen de signature').withOption(
				'width', '10%'),
		DTColumnBuilder.newColumn('nombreDossExp').withTitle('Dossier export')
				.withOption('width', '10%'),
		DTColumnBuilder.newColumn('nombreDossTech').withTitle(
				'Dossier technique').withOption('width', '10%'),
		DTColumnBuilder.newColumn(null).withTitle('Actions')
				.notSortable().withClass('datatable-action-width')
				.renderWith(actionsHtml), ];
		 
		 
		 $scope.change = function(oeExpediteurSelected) {
				if (oeExpediteurSelected != undefined  || oeExpediteurSelected != null) {
					$scope.oeExpediteur = oeExpediteurSelected;

				}
			}

			$scope.searchAspect = function(searchStr) {
				if(searchStr.val == undefined || searchStr.val == null  || searchStr.val == "") {
					var searchStrEncoded = null;
				} else {
					var searchStrEncoded = searchStr.val// escape(searchStr);
				}
				return $http.get("api/getAllExpediteurDTOByRaison/"+ searchStrEncoded).then(function(data) {
					$scope.states = data.data;
					return data.data;
				});

			};
		 
			 $scope.valider= function(){
				
			 
				// or here
					$scope.dtOptions = DTOptionsBuilder
					.newOptions()
					.withOption('ajax', {
						url: 'api/getSyntheseByExpediteur/'+$scope.dj.raisonSociale.id,
						type : 'GET',
					})
					.withOption('createdRow', createdRow)

					.withPaginationType('full_numbers')
					.withOption('order', [ 0, 'asc' ])
					.withDOM(
							"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
					.withBootstrap().withLanguageSource(
							$translate.instant('global.datatable'))
					

			$scope.dtColumns = [
				DTColumnBuilder.newColumn(null).withTitle($translate.instant('Code client')).withOption('width', '20%').renderWith(function(data, type) {
					return '<label class="directive-lien"> '+data.identifiantUnique+'<span oeexpediteurdetaillovlibre idoe="'+data.idExpediteur+'" ></span></label>'
			}),	
		DTColumnBuilder.newColumn('nombreDossJur').withTitle(
				'Dossier juridique').withOption('width', '15%'),
		DTColumnBuilder.newColumn('nombreSp').withTitle('Spécimen de signature').withOption(
				'width', '15%'),
		DTColumnBuilder.newColumn('nombreDossExp').withTitle('Dossier export')
				.withOption('width', '15%'),
		DTColumnBuilder.newColumn('nombreDossTech').withTitle(
				'Dossier technique').withOption('width', '15%'),
		DTColumnBuilder.newColumn(null).withTitle('Actions')
				.notSortable().withClass('datatable-action-width')
				.renderWith(actionsHtml), ];
			 
			 function oeExp (data, type, full, meta){
					return '<label class="directive-lien"> '+data+'<span oeexpediteurdetaillovlibre idoe="'+data+'" ></span></label>'
				}
	 		}
		 
			$scope.change = function(oeExpediteur) {
				if (oeExpediteur != undefined  || oeExpediteur != null) {
					$scope.oeExpediteur = oeExpediteur;

				}
			}
			if ($scope.oeExpediteur != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T3.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.oeExpediteur.id]
				})
			}
		$scope.search=0;
		function createdRow(row, data, dataIndex) {
		$compile(angular.element(row).contents())($scope);
		}
		
		function actionsHtml(data, type, full, meta) {
			return '<div class="action-center" style="margin-top:0px">'+
			'<div class="col-md-1"><a title="Ouvrir" ui-sref="reclamation({id:' + data.idExpediteur + '})">' +
			'   <button type="button"  class="btn btn-info-2" style="font-size: 12px;" >Réclamation</button> ' +
			'</a></div>' 
		}
		$scope.lovModel2 = 'api/getAllExpediteur';// this will be
	 	// the LOV
	 	// datasource
	 	$scope.lovTitle2 = 'Liste des raisons sociales';
	 	$scope.lovColumnList2 = [' Raison Sociale '];
	 	$scope.lovFieldList2 = ['raisonSociale'];
	 	// function to be called when you click "choose" button
	 	$scope.lovCallBack2 = function(result) {
	 	$scope.dj.raisonSociale = result;
	 	}
		
	 	$scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
		// the LOV
		// datasource
		$scope.lovTitle3 = 'Liste des régions';
		$scope.lovColumnList3 = [  'Libellé' ];
		$scope.lovFieldList3 = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack3 = function(result) {
		$scope.dj.gouvernorat = result;
		$scope.$emit('eventLocaliteAdrSiege',result)
		}
		
	
		
		
		ReferentielWs.getAllRaisonSocialeLibelle().then(function(result){
			$scope.raisonSociales=result.data;
		})
		  		
		$scope.myAccordionStatut = true;
		$scope.opened = function() {
			if ($scope.myAccordionStatut) {
				return "#collapse2";
			}
		}

		$scope.reset = function() {
			$state.go('synthese', null, {
				reload : true
			});

		}
		$(function() {
			var active = true;

			$('#collapse-init').click(function() {
				if (active) {
					active = false;
					$('.panel-collapse').collapse('show');
					$('.panel-title').attr('data-toggle',
					'');
					$(this).text(
					'Enable accordion behavior');
				} else {
					active = true;
					$('.panel-collapse').collapse('hide');
					$('.panel-title').attr('data-toggle',
					'collapse');
					$(this).text(
					'Disable accordion behavior');
				}
			});

			$('#myAccordion').on('show.bs.collapse', function() {
				if (active) {
					$('#myAccordion2 .in').collapse('hide');
				}
			});
			$('#myAccordion').on('hide.bs.collapse', function() {
			});

			$('#myAccordion2').on('show.bs.collapse', function() {
				$scope.myAccordionStatut = false;
				if (active) {
					$('#myAccordion .in').collapse('hide');
				}
			});
			$('#myAccordion2').on('hide.bs.collapse', function() {
				$scope.myAccordionStatut = true;
			});

		});
		$('#myAccordion .panel-collapse').on('shown.bs.collapse',function() 
				{$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-plus").addClass(
			"fa fa-minus");
				});

		// The reverse of the above on hidden event:

		$('#myAccordion .panel-collapse').on('hidden.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-minus").addClass(
			"fa fa-plus");
			// $scope.statut=false;
		});
		$('#myAccordion2 .panel-collapse').on('shown.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-plus").addClass(
			"fa fa-minus");
		});

		// The reverse of the above on hidden event:

		$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-minus").addClass(
			"fa fa-plus");
			// $scope.statut=false;
		});		
		  		
}
})();
