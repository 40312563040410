(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ListeReferenceDialogController',ListeReferenceDialogController);
ListeReferenceDialogController.$inject=['$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ListeReference', '$state'];
function ListeReferenceDialogController($translate, $scope, $stateParams, $uibModalInstance, entity, ListeReference,$state){
	$scope.listeReference = entity;
    $scope.edit = ('listeReference.edit' == $state.current.name);

	$scope.load = function(id) {
		ListeReference.get({
			id : id
		}, function(result) {
			$scope.listeReference = result;
		});
	};
	var onSaveSuccess = function(result) {
		$scope.$emit('infochamApp:listeReferenceUpdate', result);
		$uibModalInstance.close(result);
		$scope.isSaving = false;
	};

	var onSaveError = function(result) {
		$scope.isSaving = false;
		if (result.status == 500) {
			swal("Opération interrompue!", "Déja existant", "error");
		} else {

			swal("Opération interrompue!", "", "error");

		}

	};

	$scope.save = function() {
		$scope.isSaving = true;
		if ($scope.listeReference.id != null) {
			ListeReference.update($scope.listeReference, onSaveSuccess, onSaveError);
		} else {
			ListeReference.save($scope.listeReference, onSaveSuccess, onSaveError);
		}
	};

	$scope.clear = function() {
		$uibModalInstance.dismiss('cancel');
	};

	$scope.getPcp = function(result) {
		$scope.listeReference.pcp = result;

		$scope.pcp = result;
		$scope.idpcp = result.id;
	}

	$scope.lovModel = 'api/getListCodeRubriqueListReference';// this will be
																// the LOV
																// datasource

	$scope.lovTitle = $translate.instant('infochamApp.listeReference.codeListe');
	$scope.lovColumnList = [ $translate.instant('infochamApp.nature.codeRubrique'), $translate.instant('infochamApp.nature.libelle') ];
	$scope.lovFieldList = [ "codeRubrique", "libelle" ];
	// function to be called when you click "choose" button
	$scope.lovCallBack = function(result) {
		// e parameter contains the whole object for the row selected
		$scope.listeReference.listeRef = result;
		$scope.listeReference.designationListe = result.libelle;

		$scope.natureRubrique = result.codeRubrique;
		// $scope.idGestionnaire = result.idGestionnaire;

	}

	$scope.lovModel2 = 'api/referentiels/status';// this will be
	// the LOV
	// datasource
	$scope.lovTitle2 = 'Liste des Status';
	$scope.lovColumnList2 = [ 'Code rubrique', 'Libelle' ];
	$scope.lovFieldList2 = [ 'codeRubrique', 'libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack2 = function(result) {
		$scope.listeReference.status = result;
	}	
}

})();
