(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('depotdetaillovlibre', depotdetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('depotDetailLibreCtrl', depotDetailLibreCtrl);

	depotdetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	depotDetailLibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','CustomProcessBonita' ,'DTColumnDefBuilder','iddepot','ReportingService','idagenda'];

	function depotdetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				iddepot:'@',
				idagenda:'@',
			},
			template: '<button style="background-color: transparent;width:20px;height:20px;border: none;" ng-click="openPopUp(iddepot,idagenda)">' +

			'</button>',
			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(iddepot,idagenda) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/depotDetailTemplate.html',
							controller : 'depotDetailLibreCtrl',
							resolve: {
								iddepot: function () {
									return iddepot;
								},
								
								idagenda: function () {
									return idagenda;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function depotDetailLibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,CustomProcessBonita ,DTColumnDefBuilder , iddepot,ReportingService,idagenda) {

		$scope.depotDoss= {};
		$http.get('api/depots/'+iddepot).then(function(result) {
			$scope.depot = result.data;
		});
		
		$scope.idAgenda = idagenda;
		Agenda.getDepotDossTechniqueById($scope.idAgenda).then(function (result) {
			$scope.pieceJointeDTOs = result.data.listePieceNumerise;
			$scope.agenda = result.data.agenda;
			$scope.expediteur = $scope.agenda.oeExpediteur;
			Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
				$scope.adhesion = result.data 
			});
			$scope.depot = result.data.depot;
			$scope.produit = result.data.produit;
			$scope.matierePremiereEtrangereList=result.data.matierePremiereEtrangereList;
			$scope.matierePremiereLocaleList=result.data.matierePremiereLocaleList;
			$scope.listePieceNumerise= result.data.listePieceNumerise;
			$scope.fraisAchats= $scope.depot.fraisTransp+$scope.depot.fretTransit+$scope.depot.commAchat+$scope.depot.fraisStock;
			var totalMainDoeuvre=result.data.depot.totalMo;
			var coutTotalProd=result.data.depot.totalProd;
			var totalAutresFrais=result.data.depot.totalAutreFrais;
			var totalMP = result.data.depot.totalMP ;
			$scope.generateFicheProduit = function(){
				  $scope.depotDoss.depot = $scope.depot;
				$scope.depotDoss.expediteur = $scope.expediteur;
					$scope.depotDoss.produit=$scope.produit;
					$scope.depotDoss.depot.totalMo=totalMainDoeuvre;
					$scope.depotDoss.depot.totalProd=coutTotalProd;
					$scope.depotDoss.depot.totalAutreFrais=totalAutresFrais
					$scope.depotDoss.depot.totalMP=totalMP;
					$scope.depotDoss.listMatierePremiereEtrangere=$scope.matierePremiereEtrangereList;
					$scope.depotDoss.listMatierePremiereLocale=$scope.matierePremiereLocaleList;
				  ReportingService.generateFicheProduit($scope.depotDoss).then(function (result) {
					  var fileName="Fiche Technique"
					  ReportingService.downloadPdfFile(result, fileName)
			 });
				}
			
		})
		
		
		
		$scope.nombreAvecVirgule = function(aa)
		{
			var a =parseFloat(aa)
			return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
		}

		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
