(function() {
    'use strict';
    angular.module('app.layout', ['ui.router'])
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$urlRouterProvider'];

    function stateConfig($stateProvider,$urlRouterProvider) {
        $stateProvider
        .state('infochamApp', {
            abstract: true,
            views: {
                root: {
                    templateUrl: 'app/layout/layout.tpl.html'
                }
            }
        });
    }
//    $urlRouterProvider.otherwise('/dashboard');
})();