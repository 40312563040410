(function() {
	'use strict';
	angular.module('infochamApp').controller('CciDetailController',
			CciDetailController);
	CciDetailController.$inject = [ '$scope', '$rootScope', '$stateParams',
			'entity', 'Cci' ];

	function CciDetailController($scope, $rootScope, $stateParams, entity, Cci) {
		$scope.cci = entity;
		$scope.load = function(id) {
			Cci.get({
				id : id
			}, function(result) {
				$scope.cci = result;
			});
		};
		var unsubscribe = $rootScope.$on('infochamApp:cciUpdate', function(
				event, result) {
			$scope.cci = result;
		});
		$scope.$on('$destroy', unsubscribe);

	}
})();
