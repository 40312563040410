(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ConsultAllCOOEController', ConsultAllCOOEController);

	ConsultAllCOOEController.$inject = ['ReferentielWs' ,'ReportingService' ,'Inscription2','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$http','$state', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function ConsultAllCOOEController (ReferentielWs ,ReportingService ,Inscription2,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal,  $http,$state, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		
		$scope.co = {}
		$scope.typeProcess = "CO";
		$scope.dtInstance1 = {};
		
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
		});
		
		$scope.dtOptions = DTOptionsBuilder.newOptions().withOption('ajax', {
			url: 'api/getAllCOByOEDatatable',
		    type: 'GET'
		})
					
				.withDataProp('data')
				.withDisplayLength(10)
				.withOption('processing', true)
				.withOption('serverSide', true)
				.withOption('autoWidth', false)
				.withOption('order',[[1, 'desc']])
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
				.withLightColumnFilter({
				'0' : {
					type : 'text'
				},
				'1' : {
					type : 'text'
				},
				'2' : {
					type : 'text'
				},
				'3' : {
					type : 'text'
				},
				'4' : {
					type : 'text'
				},
				'5' : {
					type : 'text'
				},
				})
				$scope.dtColumns = [  
				DTColumnBuilder.newColumn('agenda.oeExpediteur').withTitle($translate.instant('Identifiant Unique')).withOption('width', '5%').renderWith(function(data, type) {
						return '<label class="directive-lien"> '+data.numIde+'<span oeexpediteurdetaillovlibre idoe="'+data.id+'" ></span></label>'
				}),			
				DTColumnBuilder.newColumn('agenda.dateCreation').withTitle('Date').withOption('width', '20%').renderWith(function(data, type) {
					return $filter('date')(data, 'dd/MM/yyyy');
				}),
				DTColumnBuilder.newColumn('agenda.oeExpediteur.raisonSociale').withTitle("Raison sociale").withOption('width', '15%'),
				DTColumnBuilder.newColumn('agenda.oeExpediteur.adresseSiege.gouvernorat.libelle').withTitle("Gouvernorat").withOption('width', '15%'),
				DTColumnBuilder.newColumn('agenda').withTitle($translate.instant('Référence CO')).withOption('width', '2%').renderWith(function(data, type) {
					return '<label class="directive-lien"> '+data.reference+'<span demandecertificatdetaillovlibre typeprocess="'+data.typeProcess.codeRubrique+'"  idda="'+data.id+'" identity="'+data.idEntity+'"  ></span></label>'
				}),
				DTColumnBuilder.newColumn('agenda.libelleStatut').withTitle("Statut").withOption('width', '20%'),
				];	
			 
	
		$scope.search=0;
		function createdRow(row, data, dataIndex) {
		$compile(angular.element(row).contents())($scope);
		}
		
		
			  		
}
})();
