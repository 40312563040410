(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('FactureDetailEditInfoComplementaireController', FactureDetailEditInfoComplementaireController);

	FactureDetailEditInfoComplementaireController.$inject = ['Referentiel','ReferentielWs','$uibModalInstance','$rootScope','Inscription2','DTColumnDefBuilder','fileUpload','Agenda' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function FactureDetailEditInfoComplementaireController (Referentiel,ReferentielWs,$uibModalInstance,$rootScope,Inscription2,DTColumnDefBuilder,fileUpload,Agenda,$stateParams,$translate,$scope, Principal, $state, $http, $compile, AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.detail= $stateParams.facture;
		$scope.idExpediteur= $stateParams.facture.process.oeExpediteur.id
		Principal.identity().then(function(account) {
			$scope.account = account;
			if ($scope.account == null) {
				$state.go('login');
			}
			Inscription2.getUserByLogin(account.login).then(function(result) {
				$scope.user = result.data;
				$scope.id = result.data.id;
				
			});
		});
		ReferentielWs.getAllProduitByUser($scope.idExpediteur).then(function(result){
			$scope.produits=result.data;
		})
		 $scope.idpoidsBrutUnite=$scope.detail.poidsBrutUnite.id;
		 if($scope.detail.poidsNetUnite != null){
		 $scope.idpoidsNetUnite= $scope.detail.poidsNetUnite.id;
		 }
		 $scope.detail.nombreUnite=parseInt($scope.detail.nombreUnite);
		 $scope.detail.valeurUnite=parseFloat($scope.detail.valeurUnite);
		 $scope.detail.nombreColis=parseInt($scope.detail.nombreColis);
		 $scope.detail.numeroColis=parseInt($scope.detail.numeroColis);
		 $scope.detail.poidsNet=parseFloat($scope.detail.poidsNet);
		 $scope.detail.poidsBrut=parseFloat($scope.detail.poidsBrut);
		 $scope.updateBrutUnite = function(id){
			 ReferentielWs.getReferentielById(id).then(function(result){
				 $scope.detail.poidsBrutUnite = result.data;
			 })
		 };
		 $scope.updateNetUnite = function(id){
		 ReferentielWs.getReferentielById(id).then(function(result){
			 $scope.detail.poidsNetUnite = result.data;
		 })
		};
		$scope.index = $stateParams.index;
			$scope.clear = function() {
				$uibModalInstance.dismiss('cancel');
			};
			$scope.save = function(detail,total) {
					$rootScope.$broadcast('eventEmitedEditFacture',{message:detail,index:$scope.index});
					$uibModalInstance.dismiss('cancel');
			};
		
			ReferentielWs.getAllUnite().then(function(result){
				$scope.unites=result.data;
			})
			
			  $scope.lovModel7 = 'api/referentiels/getAllProduitByUser/'+$scope.id;// this will be
				// the LOV
				// datasource
				$scope.lovTitle7 = 'Liste des produits';
				$scope.lovColumnList7 = [  'Désignation' ];
				$scope.lovFieldList7 = [ 'designation' ];
				// function to be called when you click "choose" button
				$scope.lovCallBack7 = function(result) {
					$scope.detail.designation = result.designation;
				}
}
})();
