(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('ConsultAllDAController', ConsultAllDAController);

	ConsultAllDAController.$inject = ['CciWs','ReferentielWs' ,'ReportingService' ,'Inscription2','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$http','$state', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function ConsultAllDAController (CciWs,ReferentielWs ,ReportingService ,Inscription2,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal,  $http,$state, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {

		$scope.co = {}
		$scope.rechercheDTO = {};
		$scope.typeProcess = "ADHES;ADHESOFFLINE;RENVADHES";
		$scope.titreList = "Liste des demandes d'adhésion"

		$scope.valider= function(){
			$scope.demandeDTO=[]
			$scope.listCriterionDto = []
			if ($scope.oeExpediteurSelected != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T1.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.oeExpediteurSelected.id]
				})
			}
			if ($scope.rechercheDTO.localiteDTO != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T4.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.localiteDTO.id]
				})
			}
			if ($scope.rechercheDTO.matriculeFiscal != null && $scope.rechercheDTO.matriculeFiscal != "") {
				$scope.listCriterionDto.push({
					propertyName : 'T1.MATRICULE_FISCAL',
					propertyType : 'String',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.matriculeFiscal]
				})
			}
			if ($scope.rechercheDTO.registre != null && $scope.rechercheDTO.registre != "") {
				$scope.listCriterionDto.push({
					propertyName : 'T1.RCCM',
					propertyType : 'String',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.registre]
				})
			}
			if ($scope.rechercheDTO.secteurActiviteDTO != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T3.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.secteurActiviteDTO.id]
				})
			}
			
			if ($scope.rechercheDTO.typeAdhesion != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T9.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.typeAdhesion.id]
				})
			}
			
			if ($scope.rechercheDTO.categorieJuridique != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T13.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.categorieJuridique.id]
				})
			}


			if ($scope.rechercheDTO.gouvernorat != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T12.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.gouvernorat.id]
				})
			}
			
			if ($scope.rechercheDTO.typeOperateur != null) {
				$scope.listCriterionDto.push({
					propertyName : 'T14.ID',
					propertyType : 'Number',
					operateur : '=',
					listCriterionValue : [$scope.rechercheDTO.typeOperateur.id]
				})
			}

			$scope.demandeAdhesionDTO = {
					typePorcess: $scope.typeProcess,
					etapPorcess: $scope.rechercheDTO.statutDTO,
					listCriterionDto : $scope.listCriterionDto
			}

			$scope.dtOptions = DTOptionsBuilder.newOptions()
			.withOption('ajax',function(data, fnCallback, settings) {
				$scope.demandeAdhesionDTO.input = data;
                $scope.demandeAdhesionDTO.annee= $scope.annee;
                $scope.demandeAdhesionDTO.modePaiement=$scope.rechercheDTO.modePaiement;
               $http.put('api/getEtatRechercheInsAdh',$scope.demandeAdhesionDTO,'application/json').then(
						function(result) {
							$scope.records=	result.data.recordsTotal;
							fnCallback(result.data);
						});

			})

			.withDataProp('data')
			.withDisplayLength(10)
			.withOption('processing', true)
			.withOption('serverSide', true)
			.withOption('createdRow', createdRow)
			.withOption('order',[[2, 'desc']])
			.withPaginationType('full_numbers')
			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
			.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
			.withLightColumnFilter({
				'0' : {
					type : 'text'
				},
				'1' : {
					type : 'text'
				},
				'2' : {
					type : 'text'
				},
				'3' : {
					type : 'text'
				},
				'4' : {
					type : 'text'
				},
				'5' : {
					type : 'text'
				},

				'6' : {
		          	  "type" : "select",values : [ {"value" : "Adhérent simple","label" : "Adhérent simple"},{"value" : "Pack Privilège","label" : "Pack Privilège"}]
		            },
				'7' : {
					type : 'text'
				},
				'8' : {
					type : 'text'
				},

				
				
			})
			if ($scope.dtInstance._renderer != undefined) {
				$scope.dtInstance._renderer.rerender();

			}

		}

		$scope.dtOptions = DTOptionsBuilder.fromSource('').withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>").
		withBootstrap().withLanguageSource( $translate.instant('global.datatable'));

		$scope.dtColumns = [

			DTColumnBuilder.newColumn(null).withTitle('Identifiant Unique').withOption('width', '5%').renderWith(function(data, type) {
				return '<label class="directive-lien"> '+data.NUM_IDE+'<span oeexpediteurdetaillovlibre idoe="'+data.ID+'" ></span></label>'
		}),
		DTColumnBuilder.newColumn('TYPE_PROCESS').withTitle($translate.instant('Situation')).withOption('width', '10%'),
		DTColumnBuilder.newColumn('DATE_CRE').withTitle($translate.instant('Date')).withOption('width', '10%'),
		//DTColumnBuilder.newColumn('DATE_PAIEMENT').withTitle($translate.instant('Date paiement')).withOption('width', '10%'),

			DTColumnBuilder.newColumn('RAISON_SOCIALE').withTitle($translate.instant('Raison sociale')).withOption('width', '10%'),
			DTColumnBuilder.newColumn('SECTEUR').withTitle($translate.instant('Secteur d\'activité ')).withOption('width', '15%'),
			DTColumnBuilder.newColumn('GOUV').withTitle('Gouvernorat').withOption('width', '10%'),
			DTColumnBuilder.newColumn(null).withTitle('Type Adhésion').withOption('width', '20%').renderWith(function(data, type) {
				if (angular.equals( { status: data.CODE_RUBRIQUE }, { status: "INS" } )) {
					return null;
				}else{
					return data.TYPE_ADHESION;
				}
//			return '<label class="directive-lien"> '+data.IDDEPOT+'<span depotdetaillovlibre iddepot="'+data.IDDEPOT+'" ></span></label>'
			}),
			
			DTColumnBuilder.newColumn(null).withTitle($translate.instant('Référence adhésion')).withOption('width', '2%').renderWith(function(data, type) {
				if (angular.equals( { status: data.CODE_RUBRIQUE }, { status: "INS" } )) {
					//return data.REFERENCE 
					return '<label class="directive-lien"> '+data.REFERENCE+'<span demandeinscreptionDetailLovLibre  typeprocess="INS"  idda="'+data.id+'" identity="'+data.IDENTITY+'"  ></span></label>'
					//return '<label class="directive-lien"> '+data.REFERENCE+'<span demandeinscreptionDetailLovLibre typeprocess="'+data.CODE_RUBRIQUE+'"  ref="'+data.REFERENCE+'" ></span></label>'
				} else {
					return '<label class="directive-lien"> '+data.REFERENCE+'<span demandeadhesiondetaillovlibre typeprocess="'+data.CODE_RUBRIQUE+'"  idda="'+data.IDDEMANDE+'" ></span></label>'
				}
			}),
			DTColumnBuilder.newColumn('LIBELLESTATUT').withTitle($translate.instant('Statut')).withOption('width', '10%'),

			];
		
		

		function oeExp (data, type, full, meta){
			return '<label class="directive-lien"> '+data+'<span oeexpediteurdetaillovlibre idoe="'+data+'" ></span></label>'
		}
		function demandeAdhesionDir (data, type, full, meta){
			return '<label class="directive-lien"> '+data+'<span demandeadhesiondetaillovlibre idda="'+data+'" ></span></label>'
		}
		
		function demandeAdhesionDir (data, type, full, meta){
			return '<label class="directive-lien"> '+data+'<span demandeinscreptionDetailLovLibre ref="'+data+'" ></span></label>'
		}
		
		$scope.search=0;
		$scope.dtInstance = {};

		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}


		$scope.lovModelSecteur = 'api/referentiels/getByCode/SACT';// this will be
		// the LOV
		// datasource
		$scope.lovTitleSecteur = 'Liste des secteurs';
		$scope.lovColumnListSecteur = [  'Libellé' ];
		$scope.lovFieldListSecteur = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBackSecteur = function(result) {
			$scope.rechercheDTO.secteurActiviteDTO = result;
		}
		$scope.lovModelLocalite = 'api/referentiels/getByCode/LOCA';// this will be
		// the LOV
		// datasource
		$scope.lovTitleLocalite = 'Liste des secteurs';
		$scope.lovColumnListLocalite = [  'Libellé' ];
		$scope.lovFieldListLocalite = [  'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBackLocalite = function(result) {
			$scope.rechercheDTO.localiteDTO = result;
		}


		$scope.getEtapDir = function(typeProcess) {
			$scope.lovModelStatut = 'api/referentiels/getSousRefByCode/ETPROC/'+typeProcess;// this will be
			// the LOV
			// datasource
			$scope.lovTitleStatut = 'Liste des Etats';
			$scope.lovColumnListStatut = [  'Libellé' ];
			$scope.lovFieldListStatut = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackStatut = function(result) {
				$scope.rechercheDTO.statutDTO = result;
			}
		}

		$scope.getEtapDir($scope.typeProcess);
		
		$scope.getTypeAdhesion = function() {
			$scope.rechercheDTO.statutDTO = null;
			$scope.getEtapDir($scope.typeProcess);
			
			if ($scope.typeProcess == "ADHES;ADHESOFFLINE;RENVADHES") {
				$scope.titreList = "Liste des demandes d'adhésions"
			} else {
				$scope.titreList = "Liste des inscriptions"

			}
		}
		
		$scope.lovModel3 = 'api/referentiels/gouvernorat';// this
		// will
		// be
		// the LOV
		// datasource
		$scope.lovTitle3 = 'Liste des gouvernorats';
		$scope.lovColumnList3 = [ 'Libellé' ];
		$scope.lovFieldList3 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack3 = function(result) {
			$scope.rechercheDTO.gouvernorat = result;
		
		}

		$scope.lovModel7 = 'api/referentiels/getTypeOperateur';// this
																// will
																// be
		// the LOV
		// datasource
		$scope.lovTitle7 = 'Liste des activités';
		$scope.lovColumnList7 = [ 'Libellé' ];
		$scope.lovFieldList7 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack7 = function(result) {
			$scope.rechercheDTO.typeOperateur = result;
			$scope.$emit('eventSecteur', result)
		}
		
		$rootScope
		.$on(
				'eventSecteur',
				function(event, result) {
					$scope.lovModel2 = 'api/referentiels/secteur/'+ result.codeRubrique;// this
					// will
					// be;// this will
					// be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des secteurs';
		$scope.lovColumnList2 = [ 'Libellé' ];
		$scope.lovFieldList2 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.rechercheDTO.secteurActiviteDTO = result;
		}
			
				});
		

		$scope.lovModel2 = 'api/referentiels/secteur';// this will
														// be
		// the LOV
		// datasource
		$scope.lovTitle2 = 'Liste des secteurs';
		$scope.lovColumnList2 = [ 'Libellé' ];
		$scope.lovFieldList2 = [ 'libelle' ];
		// function to be called when you click "choose" button
		$scope.lovCallBack2 = function(result) {
			$scope.oeExpediteur.secteurActiviteDTO = result;
			$scope.$emit('eventSousSecteur', result)
		}
			
		$scope.lovModel20 = 'api/referentiels/categories';// this will
		// be
	// the LOV
	// datasource
	$scope.lovTitle20= 'Liste des formes Juridiques';
	$scope.lovColumnList20 = [ '','Libellé' ];
	$scope.lovFieldList20 = [ 'valeur1','libelle' ];
	// function to be called when you click "choose" button
	$scope.lovCallBack20 = function(result) {
	$scope.rechercheDTO.categorieJuridique = result;
	$scope.$emit('eventCategorieJuridique', result)
	}
		
		$scope.lovModelOeExp = 'api/getAllExpediteurDTO';// this will be
		// the LOV
		// datasource
		$scope.lovTitleOeExp = 'Liste des opérateurs économiques';
		$scope.lovColumnListOeExp = [' Liste des opérateurs économiques '];
		$scope.lovFieldListOeExp = ['raisonSociale'];
		// function to be called when you click "choose" button
		$scope.lovCallBackOeExp = function(result) {
			$scope.oeExpediteurSelected = result;
		}
		CciWs.getCciByCode("CCICENTRE").then(function(result) {
			$scope.cci = result.data;
			$scope.lovModelTA = 'api/getTypeAdhesionByCCI/'+$scope.cci.id;// this will be
		});
		// the LOV
		// datasource
		$scope.lovTitleTA = 'Liste des types d\'adhésions';
		$scope.lovColumnListTA = [ 'Type' ];
		$scope.lovFieldListTA = [ 'description' ];
		// function to be called when you click "choose" button
		$scope.lovCallBackTA = function(result) {
			$scope.rechercheDTO.typeAdhesion = result;
		}


		/*******************************/
	var anneeDeb = $filter('date')(new Date(), 'yyyy');
		;
		$scope.annees = [];
		for (var i = 0; i < 50; i++) {
			$scope.annees[i] = {
				id : i,
				ann : anneeDeb - i
			};

		}
		$scope.change = function(oeExpediteurSelected) {
			if (oeExpediteurSelected != undefined  || oeExpediteurSelected != null) {
				$scope.oeExpediteur = oeExpediteurSelected;

			}
		}

		$scope.searchAspect = function(searchStr) {
			if(searchStr.val == undefined || searchStr.val == null  || searchStr.val == "") {
				var searchStrEncoded = null;
			} else {
				var searchStrEncoded = searchStr.val// escape(searchStr);
			}
			return $http.get("api/getAllExpediteurDTOByRaison/"+ searchStrEncoded).then(function(data) {
				$scope.states = data.data;
				return data.data;
			});

		};

		/*******************************/
		
		
		$scope.reset = function() {
			$state.go('consultAllDA', null, {
				reload : true
			});

		}


		$scope.myAccordionStatut = true;
		$scope.opened = function() {
			if ($scope.myAccordionStatut) {
				return "#collapse2";
			}
		}

		$(function() {
			var active = true;

			$('#collapse-init').click(function() {
				if (active) {
					active = false;
					$('.panel-collapse').collapse('show');
					$('.panel-title').attr('data-toggle',
					'');
					$(this).text(
					'Enable accordion behavior');
				} else {
					active = true;
					$('.panel-collapse').collapse('hide');
					$('.panel-title').attr('data-toggle',
					'collapse');
					$(this).text(
					'Disable accordion behavior');
				}
			});

			$('#myAccordion').on('show.bs.collapse', function() {
				if (active) {
					$('#myAccordion2 .in').collapse('hide');
				}
			});
			$('#myAccordion').on('hide.bs.collapse', function() {
			});

			$('#myAccordion2').on('show.bs.collapse', function() {
				$scope.myAccordionStatut = false;
				if (active) {
					$('#myAccordion .in').collapse('hide');
				}
			});
			$('#myAccordion2').on('hide.bs.collapse', function() {
				$scope.myAccordionStatut = true;
			});

		});
		$('#myAccordion .panel-collapse').on('shown.bs.collapse',function() 
				{$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-plus").addClass(
			"fa fa-minus");
				});

		// The reverse of the above on hidden event:

		$('#myAccordion .panel-collapse').on('hidden.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-minus").addClass(
			"fa fa-plus");
			// $scope.statut=false;
		});
		$('#myAccordion2 .panel-collapse').on('shown.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-plus").addClass(
			"fa fa-minus");
		});

		// The reverse of the above on hidden event:

		$('#myAccordion2 .panel-collapse').on('hidden.bs.collapse',function() {
			$(this).prev().find(".a-callapseStyle ")
			.removeClass("fa fa-minus").addClass(
			"fa fa-plus");
			// $scope.statut=false;
		});
		
}
})();
