(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('nouvelledetaillovlibre', nouvelledetaillovlibre)
	.filter('StartOnPage', StartOnPage)
	.controller('nouvelleDetailLibreCtrl', nouvelleDetailLibreCtrl);

	nouvelledetaillovlibre.$inject = ['$uibModal'];
	StartOnPage.$inject = [];
	nouvelleDetailLibreCtrl.$inject = ['$compile','$http','DTColumnBuilder','$state', '$translate','Agenda', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance', '$filter','id','CustomProcessBonita' ,'DTColumnDefBuilder'];

	function nouvelledetaillovlibre ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				id:'@',

			},
			
			template: '  <a style="font-size:16px" ng-click="openPopUp(id)"><span class="glyphicon glyphicon-eye-open"></span> <spanclass="hidden-xs forme-button"> </span></a>',


			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(id) {
					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/nouvelleDetailTemplate.html',
							controller : 'nouvelleDetailLibreCtrl',
							resolve: {
							
								id: function () {
									return id;
								},
							
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}
}
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		} }
	
	function nouvelleDetailLibreCtrl ($compile,$http,DTColumnBuilder,$state, $translate,Agenda, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance, $filter,id,CustomProcessBonita ,DTColumnDefBuilder) {
		CustomProcessBonita.getListEffectifByNouvelleDemande(id).then(function(result) {
			$scope.listeEffectif=result.data;

		})

		Agenda.getAutorisationByIdProcess(id).then(function(result){
			$scope.customProcess=result.data ;
			$scope.demandeAutorisation = result.data.demandeAutorisationDTO

			$scope.motifsList = $scope.customProcess.motif ;
			$scope.mesureSecuritesList = $scope.customProcess.mesureSecurite ;

		})

		if ($stateParams.customProcess != null ) {
			$scope.customProcess = $stateParams.customProcess;

		}
		  
		$scope.agendaDTO = $scope.customProcess.agendaDTO

		CustomProcessBonita.listBouton($scope.agendaDTO.idCase).then(function(result) {
			$scope.listButton=result.data;

			var	html = null;				
			for (var i = 0, keys = Object.keys($scope.listButton), ii = keys.length; i < ii; i++) {
				$scope.key=keys[i];
				$scope.value=$scope.listButton[keys[i]];
//				var	html = '<button   id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button>' + "<span>&nbsp;&nbsp;</span>";

				if ($scope.key == "REAFFECT_VAL1") {
					var	html = '<button id="'+$scope.key+'" ' +
					'class="btn btn-info-2 back-direct-verybig"> ' +
					'<div class="box-direct">'+$scope.value+'</div> '+
					'<span class="box-container-direct" reaffectation '+
					'callback="traitementReaffectationDirective(result)" codeprocess="'+$scope.key+'" >&nbsp;</span> '+
					'</button> <span>&nbsp;&nbsp;</span>';
				} else {
					var	html = '<button id="'+$scope.key+'" ' +
					'class="btn btn-info-2 back-direct-verybig"> ' +
					'<div class="box-direct">'+$scope.value+'</div> '+
					'<span class="box-container-direct" notification '+
					'callback="traitementDirective(result)" codeprocess="'+$scope.key+'" >&nbsp;</span> '+
					'</button> <span>&nbsp;&nbsp;</span>';
				}


				angular.element("#btnWorkflow").append($compile(html)($scope));
			}
		});	

		$scope.traitementDirective = function(resultDirective) {


			$scope.customProcess.motifDTO = resultDirective;
			$scope.nextProcess(resultDirective.codeProcess);
			$uibModalInstance.close(true);
		}
		$scope.traitementReaffectationDirective = function(resultDirective) {
			$scope.customProcess.demandeAutorisationDTO.ministere = resultDirective.ministere;
			$scope.nextProcess(resultDirective.codeProcess);
			$uibModalInstance.close(true);

		}

		$scope.nextProcess = function(nextStep) {
			CustomProcessBonita.nextStep($scope.customProcess,nextStep).then(function(result) {
				if(angular.equals( { status: result.status }, { status: 200 } )){		

					$state.go($state.current.name, {id:id}, { reload: true } );

				} else {

				}
			});
		}


		$scope.styleSwalConfirmation = {
				title: "Confirmation",
				text: "Voulez vous vraiment continuer ?",
				type: "warning",
				showCancelButton: true,
				cancelButtonText: "NON",
				confirmButtonColor: "#337ab7",
				confirmButtonText: "Oui",
				closeOnConfirm: true
		}


		$scope.generationAutorisation = function(){


			ReportingService.downloadAutorisation($scope.customProcess).then(function (result) {
				var fileName="Autorisation de circulation"
					ReportingService.downloadPdfFile(result, fileName)
			});
		}

		$scope.traitement = function (nextStep) {
			swal($scope.styleSwalConfirmation,function(){
				$scope.nextProcess(nextStep); 
			})

			if(nextStep == "VALID_VAL2") {
				ReportingService.generateAutorisationAlfresco($scope.customProcess).then(function (result) {
				});
			}


		}

		$scope.dtOptionsConsolideAD = DTOptionsBuilder
		.newOptions()
		.withOption('createdRow', createdRow)
		.withPaginationType('full_numbers')
		.withOption('order',[ 0, 'desc' ] )
		.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
		.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
		.withOption('fnFooterCallback',
				function(nRow, aasData, iStart, iEnd, aiDisplay) {



		})
		$scope.dtColumnsConsolideAD = [
			DTColumnDefBuilder.newColumnDef(0).withTitle("Nom").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(1).withTitle("Prénom").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(2).withTitle("N° de Pièce").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(3).withTitle("Téléphone").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(4).withTitle("Fonction").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(5).withTitle("Statut").withOption('className', 'text-center').withOption('width', '10%'),
			DTColumnDefBuilder.newColumnDef(6).withTitle("Numéro d'autorisation").withOption('className', 'text-center').withOption('width', '10%'),
			];


		function createdRow(row, data, dataIndex) {
			// Recompiling so we can bind Angular directive to the DT
			$compile(angular.element(row).contents())($scope);
		}

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.chooseItem = function (result) {
			$uibModalInstance.close(result);
		}

		// when you clic "X" button
		$scope.closeLov = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$scope.maskCin = function(cin)		
		{		
					
			return cin.substring(5);	
		}
	}
})();
