(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('PieceJointeDeleteController', PieceJointeDeleteController);

	PieceJointeDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'PieceJointe'];

	function PieceJointeDeleteController ($scope, $uibModalInstance, entity, PieceJointe) {

        $scope.pieceJointe = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            PieceJointe.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
