(function() {
    'use strict';

    angular
        .module('infochamApp')
        .controller('ReclamationDialogController', ReclamationDialogController);

    ReclamationDialogController.$inject = ['$sce','SpecimenSignature','$stateParams','Agenda' ,'Principal','Inscription2','DTColumnDefBuilder','ReferentielWs','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita','CustomTypeAdhesion','$http'];

    function ReclamationDialogController($sce,SpecimenSignature, $stateParams,Agenda ,Principal,Inscription2,DTColumnDefBuilder,ReferentielWs,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita , CustomTypeAdhesion,$http) {

		
		
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.idAgenda =$stateParams.id ;
			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}
			Agenda.getReclamationById($scope.idAgenda).then(function (result) {
				$scope.agenda = result.data.agenda;
				$scope.reclamation= result.data.reclamation;
					 $scope.expediteur = $scope.agenda.oeExpediteur;
			 $http.get("api/listBouton/"+$scope.agenda.idCase).then(function(result) {
				  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
				  	$scope.listButton=result.data;
				  	$scope.showNoteAttComp1 = true;
				  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
				  	var html=' <span ng-repeat="(key,value) in listButton"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
				  	// ajouter le ou les  boutons dans la balise boutonWK
				  	// l'id #boutonWK est ajouté au niveau html
				  	angular.element("#boutonWK").append( $compile(html)($scope));
				  })	
			});
			
		
			$scope.downloadPiecee = function(docId) {
			    if(docId != undefined){
				var downloadLink = document.createElement("a");

				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2
				.downloadDocument(docId)
				.then(
				function(result) {
				   	if(angular.equals( { status: result.status }, { status: 500 } )){
				swal(message,"Un problème s'est produit!", "error")
				}
				var fName = result
				.headers('Content-Disposition');
				var file = new Blob(
				[ result.data ],
				{
				type : 'application/pdf'
				});
				var fileURL = (window.URL || window.webkitURL)
				.createObjectURL(file);
				downloadLink.href = fileURL;
				downloadLink.download = fName;
				downloadLink.click();
				});
			    }
				} 
			$scope.traitement = function (keyBouton,value) {
				swal($scope.styleSwalConfirmation,function(){
					if (keyBouton == "VALREC" ) {
						 Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
					 			$state.go('home', null, { reload: true } );
					 });
					}
					else if(keyBouton == "ATTINFCOMPREC" || keyBouton == "ATTVALINFCOMPREC"){
					
						  $state.go('reclamation-info', {id:$scope.idAgenda, key:keyBouton,value:value,reclamation:$scope.reclamation}, { reload: true } );

					}
					
				})
			}

			
			$scope.addNote = function() {
				$state.go('note-demandeSpecimen', {customProcess:$scope.customProcess,key: $scope.agendaDTO.codeStatut, id: $scope.agendaDTO.id , save:true}, { reload: true } );

			}
		}
})();
