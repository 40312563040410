(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('PieceJointeDialogController', PieceJointeDialogController);

	PieceJointeDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'PieceJointe', 'User', 'TypeProcess', 'Referentiel'];

	function PieceJointeDialogController ($scope, $stateParams, $uibModalInstance, entity, PieceJointe, User, TypeProcess, Referentiel) {

        $scope.pieceJointe = entity;
        $scope.users = User.query();
        $scope.typeprocesss = TypeProcess.query();
        $scope.referentiels = Referentiel.query();
        $scope.load = function(id) {
            PieceJointe.get({id : id}, function(result) {
                $scope.pieceJointe = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:pieceJointeUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.pieceJointe.id != null) {
                PieceJointe.update($scope.pieceJointe, onSaveSuccess, onSaveError);
            } else {
                PieceJointe.save($scope.pieceJointe, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForDateCre = {};

        $scope.datePickerForDateCre.status = {
            opened: false
        };

        $scope.datePickerForDateCreOpen = function($event) {
            $scope.datePickerForDateCre.status.opened = true;
        };
}
})();
