(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('oeDestinataire', {
            parent: 'entity',
            url: '/oeDestinataires',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.oeDestinataire.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/oeDestinataire/oeDestinataires.html',
                    controller: 'OeDestinataireController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('oeDestinataire');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('oeDestinataire.detail', {
            parent: 'entity',
            url: '/oeDestinataire/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.oeDestinataire.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/oeDestinataire/oeDestinataire-detail.html',
                    controller: 'OeDestinataireDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('oeDestinataire');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'OeDestinataire', function($stateParams, OeDestinataire) {
                    return OeDestinataire.get({id : $stateParams.id});
                }]
            }
        })
        .state('oeDestinataire.new', {
            parent: 'oeDestinataire',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/oeDestinataire/oeDestinataire-dialog.html',
                    controller: 'OeDestinataireDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                telephone: null,
                                ville: null,
                                importateur: null,
                                adresse: null,
                                raisonSociale: null,
                                fax: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('oeDestinataire', null, { reload: true });
                }, function() {
                    $state.go('oeDestinataire');
                })
            }]
        })
        .state('oeDestinataire.edit', {
            parent: 'oeDestinataire',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/oeDestinataire/oeDestinataire-dialog.html',
                    controller: 'OeDestinataireDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['OeDestinataire', function(OeDestinataire) {
                            return OeDestinataire.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('oeDestinataire', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('oeDestinataire.delete', {
            parent: 'oeDestinataire',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/oeDestinataire/oeDestinataire-delete-dialog.html',
                    controller: 'OeDestinataireDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['OeDestinataire', function(OeDestinataire) {
                            return OeDestinataire.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('oeDestinataire', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        });
}
})();

