(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('RaisonDetailController',RaisonDetailController);
RaisonDetailController.$inject=['Raison','blockUI','Agenda','ReportingService','Inscription2' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];
function RaisonDetailController(Raison,blockUI,Agenda,ReportingService,Inscription2 ,$stateParams,$translate,$scope, Principal, $state, $http, $compile, AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder){
            Raison.getExpediteur($stateParams.id).then(function (result){
                $scope.raison = result.data;
                $scope.precedent=function() {

					$state.go('raison', null, {
						reload : true
					});

				}
               
                
                $scope.consulterFr = function(){
  				  ReportingService.downloadDemandeAdhesionFr($stateParams.id).then(function (result) {
  					  var fileName="Demande d'Adhésion FR"
  					  ReportingService.downloadPdfFile(result, fileName)
  			 });
  				}
    		
  	$scope.consulterAn = function(){
  			  ReportingService.downloadDemandeAdhesionAn($stateParams.id).then(function (result) {
  				  var fileName="Demande d'Adhésion AN"
  				  ReportingService.downloadPdfFile(result, fileName)
  		 });
  			}
  		
  		$scope.consulterAr = function(){
  			  ReportingService.downloadDemandeAdhesionAr($stateParams.id).then(function (result) {
  				  var fileName="Demande d'Adhésion AR"
  				  ReportingService.downloadPdfFile(result, fileName)
  		 });
  			}
			}
	 );
}

})();

