(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ModelePjDeleteController',ModelePjDeleteController);
ModelePjDeleteController.$inject=['$scope', '$uibModalInstance', 'entity', 'ModelePj'];
function ModelePjDeleteController($scope, $uibModalInstance, entity, ModelePj){
	$scope.modelePj = entity;
    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.confirmDelete = function (id) {
    	ModelePj.delete({id: id},
            function () {
                $uibModalInstance.close(true);
            });
    };
}

})();
