(function() {
	'use strict';
	angular
	.module('infochamApp')
	.factory('CreateUser', CreateUser);

	CreateUser.$inject = ['$http','$rootScope'];
	function CreateUser ($http,$rootScope) {    

		return {	
			'saveUser': function(managedUserDTO,idagence){
				return $http({
					method : "POST",
					url : 'api/users/'+idagence,
					dataType: "json",
                    traditional: true,
					data :managedUserDTO ,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});

			},
			'UpdateUser': function(managedUserDTO,idagence){
				return $http({
					method : "PUT",
					url : 'api/users/updateUserAgence/'+idagence,
					dataType: "json",
                    traditional: true,
					data :managedUserDTO ,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});

			},
			'GroupUserParAgence': function () {
				return $http.get('api/userParAgence', { responseType: 'arraybuffer' }).then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(response) {
					$rootScope.status = response.status;
				});
			},
			'GroupUserParRole': function () {
				return $http.get('api/userParRole', { responseType: 'arraybuffer' }).then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(response) {
					$rootScope.status = response.status;
				});
			},
			
			'Gestionnaire': function (id) {
				return $http.get('api/gestionnaireReport/'+id, { responseType: 'arraybuffer' }).then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(response) {
					$rootScope.status = response.status;
				});
			},
			'getLoginById': function (id) {
				return $http.get('api/loginUserById/'+id).then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(response) {
					$rootScope.status = response.status;
				});
			},
			
			'getUserById': function (id) {
				return $http.get('api/usersById/'+id).then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(response) {
					$rootScope.status = response.status;
				});
			},
			
			'getAllAuthority': function () {
				return $http.get('api/users/getAllAuthoritiesName').then(function (response) {
					$rootScope.status = response.status;
					return response;
				}, function(response) {
					$rootScope.status = response.status;
				});
			},
			
		};
	}
})();



