(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('CertificatDelivranceController', CertificatDelivranceController);

	CertificatDelivranceController.$inject = ['$window','ReferentielWs' ,'ReportingService' ,'Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function CertificatDelivranceController ($window,ReferentielWs ,ReportingService ,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile, AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;

			if ($scope.account == null) {
				$state.go('login');
			}
			});
			$scope.idAgenda = $stateParams.id;
		 
			Certificat.getDemandeByProcess($scope.idAgenda).then(function (result) {
				$scope.agenda = result.data.agenda;
				$scope.facture = result.data.facture;
				$scope.autresServices = result.data.autresServices
				$scope.detailFacture = result.data.detailFactureList;
				$scope.modeDepot = result.data.modeDepot;
				$scope.certificatOrigine = result.data.certificatOrigine ;
				$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
			 $http.get("api/listBouton/"+$scope.facture.process.idCase).then(function(result) {
				  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
				  	$scope.listButton=result.data;
				  	$scope.listButton1 = {};
				  	if($scope.facture.modePaiement== "Crédit"){
					  	$scope.listButton1["PAICOCREDIT"]="Paiement crédit";
				  	} else {
					  	$scope.listButton1["ARCHIVED"]="Archiver";
				  	}
				  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
				  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value,paiementVirement,paiementCheque)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
				  	// ajouter le ou les  boutons dans la balise boutonWK
				  	// l'id #boutonWK est ajouté au niveau html
				  	angular.element("#boutonWK").append( $compile(html)($scope));
				  })
				  
			  		 if($scope.autresServices.nbrecopieCO != undefined && $scope.autresServices.nbrecopieCO != "" && $scope.autresServices.nbrecopieCO != null){
						 $scope.nbrecopieCO= $scope.autresServices.nbrecopieCO;
					 } else {
						 $scope.nbrecopieCO="0";
					 }
					 if($scope.autresServices.nbreDmpCo != undefined && $scope.autresServices.nbreDmpCo != "" && $scope.autresServices.nbreDmpCo != null){
						 $scope.nbreDmpCo= $scope.autresServices.nbreDmpCo;
					 } else {
						 $scope.nbreDmpCo="0";
					 }
					 if($scope.autresServices.nbreVisaDoc != undefined && $scope.autresServices.nbreVisaDoc != "" && $scope.autresServices.nbreVisaDoc != null){
						 $scope.nbreVisaDoc= $scope.autresServices.nbreVisaDoc;
					 } else {
						 $scope.nbreVisaDoc="0";
					 }
					 if($scope.autresServices.nbreVisaFacture != undefined && $scope.autresServices.nbreVisaFacture != "" && $scope.autresServices.nbreVisaFacture != null){
						 $scope.nbreVisaFacture= $scope.autresServices.nbreVisaFacture;
					 } else {
						 $scope.nbreVisaFacture="0";
					 }	
				  
				  
				  Certificat.getMontant($scope.idAgenda,$scope.nbrecopieCO , $scope.nbreDmpCo, $scope.nbreVisaDoc , $scope.nbreVisaFacture).then(function (result) {
						
					   $scope.montant=result.data;
				   });
			 
			 if($scope.facture.modePaiement=="Chèque"){
				 Certificat.getPaiementChequeByFacture($scope.facture.id).then(function (result) {
					 $scope.paiement = result.data
				   });
				 }
				 else if($scope.facture.modePaiement=="Mobile"){
					 Certificat.getPaiementMobileByFacture($scope.facture.id).then(function (result) {
						 $scope.paiement = result.data
					   });
					 }
				 else if($scope.facture.modePaiement=="Virement"){
					 Certificat.getPaiementVirementByFacture($scope.facture.id).then(function (result) {
						 $scope.paiement = result.data
					   });
				 }
			 
				  
		        	 });
			$scope.dtOptionsConsolide = DTOptionsBuilder
			.newOptions()
			// or here
			.withOption('createdRow', createdRow)
			.withPaginationType('full_numbers')
	.withOption('order',[ 0, 'desc' ] )
			.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable'))
				.withOption('fnFooterCallback',
						function(nRow, aasData, iStart, iEnd, aiDisplay) {

					var columnas = [5]; //the columns you wish to add
					var secondRow = $(nRow).next()[0];
					for (var j in columnas) {
						var columnaActual = columnas[j];
						var totalFiltre = 0;
						var total = 0;
						for (var i = 0; i < aiDisplay.length; i++) {

							totalFiltre = totalFiltre + parseFloat($scope.detailFacture[i].nombreUnite * $scope.detailFacture[i].valeurUnite);
						}
						for (var i = 0; i < aasData.length; i++) {
							total = total + parseFloat($scope.detailFacture[i].nombreUnite * $scope.detailFacture[i].valeurUnite);
						}
						
						
						$($(nRow).children().get(columnaActual)).html(total.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",") + " TND");
						
						
					} // end

				})
				$scope.dtColumnsConsolide = [
					DTColumnDefBuilder.newColumnDef(0).withTitle("Désignation").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(1).withTitle("Quantité").withOption('width', '10%'),
					DTColumnDefBuilder.newColumnDef(2).withTitle("Valeur (TND)").withOption('width', '10%'),
					DTColumnDefBuilder.newColumnDef(3).withTitle("Nombre / Nature de colis").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(4).withTitle("Poids net / brut").withOption('width', '20%'),
					DTColumnDefBuilder.newColumnDef(5).withTitle("Total ligne (TND)").withOption('width', '20%')
					];

			$scope.nombreAvecVirgule = function(aa)
			{
				var a =parseFloat(aa)
				return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
			}

			
			
			
			function createdRow(row, data, dataIndex) {
				// Recompiling so we can bind Angular directive to the DT
				$compile(angular.element(row).contents())($scope);
			}
			
			/*get file dec tresor from alfreco*/
			$scope.downloadDecharge = function(docId) {
			var downloadLink = document.createElement("a");
			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2
			.downloadDocument(docId)
			.then(
			function(result) {
			   	if(angular.equals( { status: result.status }, { status: 500 } )){
			swal(message,"Un problème s'est produit!", "error")
			}
			var fName = result
			.headers('Content-Disposition');
			var file = new Blob(
			[ result.data ],
			{
			type : 'application/pdf'
			});
			var fileURL = (window.URL || window.webkitURL)
			.createObjectURL(file);
			downloadLink.href = fileURL;
			downloadLink.download = fName;
			downloadLink.click();
			});
			}
			  $scope.traitement = function(keyBouton,value,paiementVirement,paiementCheque){
				  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
			 });
				}
		  		
		  		 $scope.visualiser = function(){
					  ReportingService.downloadCertificatOrigine($scope.idAgenda, $scope.certificatOrigine.numeroCo).then(function (result) {
						  var fileName="Certificat d'Origine"
						  ReportingService.downloadPdfFile(result, fileName)
				 });
					}
		  		
		  		  $scope.consulter = function(){
					  ReportingService.downloadCertificatOrigine($scope.idAgenda, $scope.certificatOrigine.numeroCo).then(function (result) {
		       var file = new Blob([ result.data ], {
		 	            	type : 'application/pdf'
		 	            	});
		 	            	var fileURL = window.URL.createObjectURL(file);
		 	            	$window.open(fileURL, '_blank');
					  });	
				  }
}
})();
