(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('FailPaiementAdhesionController', FailPaiementAdhesionController);

	FailPaiementAdhesionController.$inject = ['ClickToPay','CustomProcessBonita','PaiementEnLigneWs','ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function FailPaiementAdhesionController (ClickToPay,CustomProcessBonita,PaiementEnLigneWs,ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.PaiementClickToPay = {};

		$.urlParam = function(name){
			var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
			if (results==null) {
				return null;
			}
			return decodeURI(results[1]) || 0;
		}
		if($.urlParam('orderId')!=null){
			PaiementEnLigneWs.getPaiementconfig().then(function(result) {
				$scope.config = result.data;
				CustomProcessBonita.remplireCustomProcess($.urlParam('id')).then(function (result) {
					$scope.customProcess = result.data;
					$scope.oeExpediteur = $scope.customProcess.oeExpediteurDTO;
					$scope.specimenSignatures = $scope.customProcess.depotDoss;
					$scope.customProcess.agendaDTO.idTask = $scope.customProcess.agendaDTO.idTask;
					$scope.paiementVirement = {};
					$scope.paiementCheque = {};
					$scope.numTmoney=0;
					$scope.numFlouss=0 ;
					$scope.RIB=0;
					$scope.verifPaiementMobile=false;
					$scope.verifPaiementEspece=false;
					$scope.paiementEspece={};
					$scope.customProcess = $scope.customProcess ;
					if($scope.customProcess !=null && $scope.customProcess.oeExpediteurDTO!=null){
						$scope.createur =$scope.customProcess.oeExpediteurDTO; 
					}
					$scope.agendaDTO = $scope.customProcess.agendaDTO;
					$scope.idAgenda=$scope.agendaDTO.id;
					$scope.specimenSignatures = $stateParams.specimenSignatures ;
					$scope.pieceJointeDTOs = $scope.customProcess.pieceJointeDTOs;
					$scope.customProcessDTO =$stateParams.customProcess ;
					$scope.expediteur =$scope.agendaDTO.oeExpediteurDTO;
					$scope.idEntity= $scope.customProcess.agendaDTO.idEntity;
					Principal.identity().then(function(account) {
						$scope.account = account;
						$scope.isAuthenticated = Principal.isAuthenticated;
						if ($scope.account == null) {
							$state.go('login');
						}
					});
					Principal.identity().then(function(account) {
						Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
							$scope.adhesion = result.data
						});
					});
					$.ajax({url: $scope.config.serviceClickToPayRedirect+"?userName="+$scope.config.userName +"&password="+$scope.config.password+"&orderId="+$.urlParam('orderId')+"&language=fr", 

						success: function(result){
							var json1 = JSON.parse(result);
							$scope.PaiementClickToPay.orderId=$.urlParam('orderId');
							var datePaiement = moment(json1.depositedDate).format("YYYY-MM-DD");
							$scope.PaiementClickToPay.datePaiement=datePaiement
							$scope.PaiementClickToPay.depositedAmount=json1.paymentAmountInfo.depositedAmount;
							$scope.PaiementClickToPay.approvedAmount=json1.paymentAmountInfo.approvedAmount;
							$scope.PaiementClickToPay.paymentState=json1.paymentAmountInfo.paymentState;
							$scope.PaiementClickToPay.orderNumber=json1.orderNumber;
							$scope.PaiementClickToPay.errorCode=json1.errorCode=json1.orderStatus;
							$scope.PaiementClickToPay.errorMessage=json1.actionCodeDescription;
							$scope.PaiementClickToPay.agendaDTO=$scope.agendaDTO;
							$scope.PaiementClickToPay.mail=json1.payerData.email;
							$scope.PaiementClickToPay.numCarteBancaire=json1.cardAuthInfo.pan;
							if($scope.oeExpediteur.categorieJuridiqueDTO.valeur2=='PP'){
								$scope.montant = $scope.customProcess.demandeAdhesionDTO.typeAdhesionDTO.montantPp 
							}else{
								$scope.montant = $scope.customProcess.demandeAdhesionDTO.typeAdhesionDTO.montant 
							}
							ClickToPay.addClickToPay($scope.PaiementClickToPay,$.urlParam('id'),$scope.montant).then(function (result) {
							});
						}});
				});
			
			});
		}
	}
})();
