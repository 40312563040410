(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('Certificat', Certificat);

    Certificat.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

    function Certificat ($http,$resource, DateUtils,$rootScope) {

        return {
        	'getDemandeByProcess': function (idProcess) {

				return  $http.get('api/getCertificatByProcess/'+idProcess) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getInscriptionByProcess': function (idProcess) {

				return  $http.get('api/getInscriptionByProcess/'+idProcess) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'putTypeCciByUser': function (typeCci,gouvernoratS, gouvernoratU, idUser) {

				return $http({
					method : "PUT",
					url : "api/putTypeCciByUser/"+typeCci+"/"+gouvernoratS+"/"+gouvernoratU+"/"+idUser,
					dataType: "json",
					traditional: true,
//					data : modeDepot,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			'addModeDepot': function (modeDepot,typeDepot,idProcess) {

				return $http({
					method : "PUT",
					url : "api/putModeDepot/"+typeDepot+"/"+idProcess,
					dataType: "json",
					traditional: true,
					data : modeDepot,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			
			
			'putTypeCci': function (typeCci,gouvernoratS, gouvernoratU, idProcess) {

				return $http({
					method : "PUT",
					url : "api/putTypeCci/"+typeCci+"/"+gouvernoratS+"/"+gouvernoratU+"/"+idProcess,
					dataType: "json",
					traditional: true,
//					data : modeDepot,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			
			
			'addCom': function (comm,idProcess) {

				return $http({
					method : "PUT",
					url : "api/addCom/"+comm+"/" +idProcess,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			
			'attributionNumFacturation': function (idProcess,numCO) {

				return $http({
					method : "PUT",
					url : "api/attributionNumFacturation/"+idProcess+"/"+numCO,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			'paiementEspece': function (paiementEspece,idFacture,modePaiement,montant) {

				return $http({
					method : "PUT",
					url : "api/paiementEspece/"+idFacture+"/"+modePaiement+"/"+montant,
					dataType: "json",
					traditional: true,
					data : paiementEspece,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			'paiementMobile': function (paiementMobile,idFacture,modePaiement,montant) {

				return $http({
					method : "PUT",
					url : "api/paiementMobile/"+idFacture+"/"+modePaiement+"/"+montant,
					dataType: "json",
					traditional: true,
					data : paiementMobile,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			'paiementVirement': function (paiementVirement,idFacture,modePaiement,montant) {

				return $http({
					method : "PUT",
					url : "api/paiementVirement/"+idFacture+"/"+modePaiement+"/"+montant,
					dataType: "json",
					traditional: true,
					data : paiementVirement,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			
			'paiementCheque': function (paiementCheque,idFacture,modePaiement,montant) {

				return $http({
					method : "PUT",
					url : "api/paiementCheque/"+idFacture+"/"+modePaiement+"/"+montant,
					dataType: "json",
					data : paiementCheque,
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			'getFactureByProcess': function (idProcess) {

				return  $http.get('api/getFactureByProcess/'+idProcess) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getMontant': function (idProcess, copieCO, copieD, copieVisaDoc, copieVisaFact) {

				return  $http.get('api/getMontant/'+idProcess+"/"+copieCO + "/" + copieD + "/" + copieVisaDoc + "/" + copieVisaFact) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getMontantByCode': function (code) {

				return  $http.get('api/getMontantByCode/'+code) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getMontantAdhesion': function () {		
							
									return  $http.get('api/getMontantAdhesion') .success(function (data){		
								    })		
								    .error(function (error, status){		
								  }); 		
								},
			
			'getMontantAttestationAdhesion': function () {		
									
					return  $http.get('api/getMontantAttestationAdhesion') .success(function (data){		
				    })		
					    .error(function (error, status){		
						  }); 		
						},					
						
			'getMontantDuplicata': function () {		
									
				return  $http.get('api/getMontantDuplicata') .success(function (data){		
					    })		
				 .error(function (error, status){		
								  }); 		
								},								
								
								
			'getMontantDDU': function (nbreCopie) {

				return  $http.get('api/getMontantDDU/'+nbreCopie) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getMontantCO': function (nbreCopie,typeCO) {

				return  $http.get('api/getMontantCO/'+nbreCopie+'/'+typeCO) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getPaiementVirementByFacture': function (idFacture) {

				return  $http.get('api/getPaiementVirementByFacture/'+idFacture) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getPaiementChequeByFacture': function (idFacture) {

				return  $http.get('api/getPaiementChequeByFacture/'+idFacture) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getPaiementMobileByFacture': function (idFacture) {

				return  $http.get('api/getPaiementMobileByFacture/'+idFacture) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getPaiementEspeceByFacture': function (idFacture) {

				return  $http.get('api/getPaiementEspeceByFacture/'+idFacture) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'verifSazoff': function (idProcess) {

				return  $http.put('api/verifSazoff/'+idProcess) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'refusSazoff': function (idProcess) {

				return  $http.put('api/refusSazoff/'+idProcess) .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			'getNumeroAttestation': function () {

				return  $http.get('api/getNumeroAttestation') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			'getAllExpediteur': function () {

				return  $http.get('api/getAllExpediteur') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getNumFlouss': function () {

				return  $http.get('api/getNumFlouss') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getNumTmoney': function () {

				return  $http.get('api/getNumTmoney') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'getRib': function () {

				return  $http.get('api/getRIB') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			
			'checkNumeroAttestation': function (numDuplicata) {
				return $http({
					method : "PUT",
					url : "api/checkNumeroAttestation",
					dataType: "json",
					traditional: true,
					data : numDuplicata,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			
			'addMontantAttestation': function (idProcess) {

				return $http({
					method : "PUT",
					url : "api/addMontantAttestation/"+idProcess,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			'addSignataire': function (idProcess,idSignataire) {

				return $http({
					method : "PUT",
					url : "api/addSignataire/"+idProcess+"/"+idSignataire,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			
				  
        };
    }
})();
