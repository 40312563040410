(function() {
	'use strict';

	angular.module('infochamApp').controller('HealthModalController',
			HealthModalController);

	HealthModalController.$inject = [ '$scope', '$uibModalInstance',
			'currentHealth', 'baseName', 'subSystemName' ];

	function HealthModalController($scope, $uibModalInstance, currentHealth,
			baseName, subSystemName) {

		$scope.currentHealth = currentHealth;
		$scope.baseName = baseName, $scope.subSystemName = subSystemName;

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
