(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'demandeCertificatOrigine',
						{
							parent : 'home',
							url : 'demande-certificatOrigine',
							data : {
								authorities : [ 'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/demande-certificatOrigine.html',
									controller : 'DemandeCertificatOrigineController',
								}
							},

							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'facture-detail',
						{
							parent : 'demandeCertificatOrigine',
							url : '/facture',
							data : {
								authorities : [ 'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								idExpediteur : null,

							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/facture-detail.html',
													controller : 'FactureDetailController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'demandeCertificatOrigine',
																			null,
																			{
																				reload : false
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})
				.state(
						'facture-detail-edit',
						{
							parent : 'demandeCertificatOrigine',
							url : '/editfacture',
							data : {
								authorities : [ 'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								facture : null,
								idExpediteur : null,
								index : null
							},

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/facture-detail-edit.html',
													controller : 'FactureDetailEditController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'demandeCertificatOrigine',
																			{
																				reload : false
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})

				.state(
						'facture-detail-edit-infoComplementaire',
						{
							parent : 'certificatOrigine-detail-edit',
							url : '/editfacture',
							data : {
								authorities : [ 'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								facture : null,
								index : null
							},

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/facture-detail-edit.html',
													controller : 'FactureDetailEditInfoComplementaireController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'certificatOrigine-detail-edit',
																			null,
																			{
																				reload : true
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})

				.state(
						'certificatOrigine-detail',
						{
							parent : 'home',
							url : 'certificatOrigine/detail/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								idEntity : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-detail.html',
									controller : 'CertificatOrigineDetailController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-detail-edit',
						{
							parent : 'home',
							url : 'certificatOrigines/edit',
							data : {
								authorities : [ 'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								id : null,
								idEntity : null
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-detail-edit.html',
									controller : 'CertificatOrigineDetailEditController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-depotPhysique',
						{
							parent : 'demandeCertificatOrigine',
							url : '/depotPhysique/{id}',
							data : {
								//            	  authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],		
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-depotPhysique.html',
									controller : 'CertificatDepotPhysiqueController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'certificatOrigine-depotPhysique-detail',
						{
							parent : 'demandeCertificatOrigine',
							url : '/depotPhysique/consult/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								idEntity : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-depotPhysique-detail.html',
									controller : 'CertificatDepotPhysiqueConsultController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'certificatOrigine-depotPhysique-edit',
						{
							parent : 'demandeCertificatOrigine',
							url : '/depotPhysique/edit/{id}',
							data : {
								//            	  authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],		
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-depotPhysique-edit.html',
									controller : 'CertificatDepotPhysiqueEditController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-attributionFacturation',
						{
							parent : 'demandeCertificatOrigine',
							url : '/facturation/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-attributionFacturation.html',
									controller : 'CertificatAttributionFacturationController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-paiement',
						{
							parent : 'home',
							url : 'demandeCertificat/paiement',
							data : {
								authorities : [ 'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								id : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-paiement.html',
									controller : 'CertificatPaiementController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-paiement-detail',
						{
							parent : 'demandeCertificatOrigine',
							url : '/paiement/consult/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_PAIEMENT',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-paiement-detail.html',
									controller : 'CertificatPaiementConsultController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

							.state(
						'certificatOrigine-paiementCredit-detail',
						{
							parent : 'demandeCertificatOrigine',
							url : '/paiementCredit/consult/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_PAIEMENT',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-paiementCredit-detail.html',
									controller : 'CertificatPaiementCreditConsultController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-paiement-edit',
						{
							parent : 'home',
							url : 'demandeCertificat/paiement/edit',
							data : {
								authorities : [ 'ROLE_OPERATEUR_ECONOMIQUE',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								id : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-paiement-edit.html',
									controller : 'CertificatPaiementEditController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-delivrance',
						{
							parent : 'demandeCertificatOrigine',
							url : '/delivrance',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN',
										'ROLE_OPERATEUR_ECONOMIQUE' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								id : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-delivrance.html',
									controller : 'CertificatDelivranceController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'note-demandeCO-infoComplementaire',
						{
							parent : 'certificatOrigine-detail',
							url : '/new/note/demandeCO/{idProcess}',
							data : {
								//            	  authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],	

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								key : null,
								value : null
							},

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/note/note-demandeCO-infoComplementaire.html',
													controller : 'NoteDemandeCOInfoComplementaireController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'certificatOrigine-detail',
																			null,
																			{
																				reload : true
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})

				.state(
						'note-depotPhy-infoComplementaire',
						{
							parent : 'certificatOrigine-depotPhysique-detail',
							url : '/new/note/depotPhy/{idProcess}',
							data : {
								//            	  authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],	

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								key : null,
								value : null
							},

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/note/note-depotPhy-infoComplementaire.html',
													controller : 'NoteDepotPhyInfoComplementaireController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'certificatOrigine-depotPhysique-detail',
																			null,
																			{
																				reload : true
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})

				.state(
						'note-paiement-infoComplementaire',
						{
							parent : 'certificatOrigine-paiement-detail',
							url : '/new/note/paiement/{idProcess}',
							data : {
								//            	  authorities: ['ROLE_USER','ROLE_ADVANCEDUSER','ROLE_ADMIN'],	

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								key : null,
								value : null
							},

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/note/note-paiement-infoComplementaire.html',
													controller : 'NotePaiementInfoComplementaireController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result
												.then(
														function(result) {
															$state
																	.go(
																			'certificatOrigine-paiement-detail',
																			null,
																			{
																				reload : true
																			});
														}, function() {
															$state.go('^');
														})
									} ]
						})

	}
})();
