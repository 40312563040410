(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('selectgouv', selectgouv)
	.controller('SelectGouvCtrl', SelectGouvCtrl)
	.filter('StartOnPage', StartOnPage);

	selectgouv.$inject = ['$uibModal'];
	SelectGouvCtrl.$inject = ['DTColumnBuilder', '$translate', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance','list','listresult','titre'];
	StartOnPage.$inject = [];


	function selectgouv ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				callback : '&',
				list : '=',
				listresult:'=',
				titre : '='
			},
			template :'<button type="button" class="lovStyle btn-grey btn  "  ' + 'ng-click="openPopUp(list,listresult,callback,titre)">' + ' <span class="glyphicon glyphiconn glyphicon-search"></span>' + '</button>',
			controller : [ '$scope', '$element', function($scope, $element) {
				
				$scope.openPopUp = function(list,listresult,callback,titre) {

					$scope.localPopUp = {
						backdrop : 'static',
						size : 'lg',
						dialogFade : false,
						keyboard : false,
						templateUrl : 'app/directives/selectGouv.html',
						controller : 'SelectGouvCtrl',
						windowClass : '',
						resolve: {
							list: function () {
								return list;
							},listresult: function () {
								return listresult;
							},titre: function () {
								return titre;
							}
						}
					};

					var modalInstance = $uibModal.open($scope.localPopUp);

					modalInstance.result.then(function(selectedItem) {
						callback({
							result : selectedItem
						});
					}, function() {
						// dismiss: nothing to do
					});
				}
			} ]
		}
}
	
	
	function StartOnPage () {
		return function(input, start) {
			start = +start;
			return input.slice(start);
		}
}
	
	
	function SelectGouvCtrl (DTColumnBuilder, $translate, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance,list,listresult,titre) {
		$scope.list=list
//		$scope.options=list;
		$scope.resultList = {};
		$scope.titre = titre;
		$scope.alert = function(result) {
		    $window.$scope.alert(JSON.stringify(result));
		  };
		$scope.itemsPerPage = 10;
		$scope.currentPage = 1;
		    $scope.selectedList = listresult;
				$scope.data = null;
		

				// when you click "choose" button
				$scope.chooseItem = function(result) {
					$uibModalInstance.close(result);
				}

				// when you clic "X" button
				$scope.closeLov = function() {
					$uibModalInstance.dismiss('cancel');
				};
			}
})();












