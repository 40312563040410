(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('Inscription2', Inscription2);

    Inscription2.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

    function Inscription2 ($http,$resource, DateUtils,$rootScope) {
        return {
        	'getUsersBonita': function () {

				return  $http.get('api/allUsersBPM') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			'addInscription1': function () {
				return  $http.get('api/inscription/addInscription') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			},
			'addInscription': function(user,password){
				return $http({
					method : "PUT",
					url : "api/inscription/addInscription/"+password,
					dataType: "json",
					traditional: true,
					data : user,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'addInscriptionAdh': function(user,password){
				return $http({
					method : "PUT",
					url : "api/inscription/addInscriptionAdh/"+password,
					dataType: "json",
					traditional: true,
					data : user,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			'addInscriptionByAdmin': function(user,password){
				return $http({
					method : "PUT",
					url : "api/inscription/addInscriptionByAdmin/"+password,
					dataType: "json",
					traditional: true,
					data : user,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'addPiece': function(id,fileList){
				return $http({
					method : "POST",
					url : "api/addPiece/"+id,
					dataType: "json",
					traditional: true,
					data : fileList,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'updateInscription': function(user,idProcess){
				return $http({
					method : "PUT",
					url : "api/inscription/updateInscription/"+idProcess,
					dataType: "json",
					traditional: true,
					data : user,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'updateProcInscription': function(id,key,value){
				return $http({
					method : "PUT",
					url : "api/updateAgenda/"+id+"/"+key+"/"+value,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			
			
			
			'updateAgendaTransmission': function(id,key,customProcess){
				return $http({
					method : "PUT",
					url : "api/updateAgendaTransmission/"+id+"/"+key,
					dataType: "json",
					data : customProcess,
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			'addCommentaire': function(idProcess,commentaire){
				return $http({
					method : "PUT",
					url : "api/addCommentaire/"+idProcess,
					dataType: "json",
					traditional: true,
					data : commentaire,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			'loginExist': function(login){
				return $http({
					method : "GET",
					url : "api/loginExist/"+login,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},

			'emailExist': function(email){
				return $http({
					method : "PUT",
					url : "api/emailExist",
					dataType: "json",
					traditional: true,
					data : email,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			'verifierPassword': function(idUser,password){
				return $http({
					method : "GET",
					url : "api/verifierPassword/"+idUser+"/"+password,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'getAllAuthoritiesName': function(){
				return $http({
					method : "GET",
					url : "api/users/getAllAuthoritiesName",
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'getAgendaById': function(id){
				return $http({
					method : "GET",
					url : "api/getAgendaById/"+id,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			'getPieceJointe': function(id){
				return $http({
					method : "GET",
					url : "api/findPieceJointe/"+id,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			'getImageById': function(id){
				return $http({
					method : "GET",
					url : "api/getImage/"+id,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			  'downloadDocument' : function(docId) {
				  var downloadUrl = 'api/getPiece/' + docId;
				  return $http.get(downloadUrl, {
				  responseType : 'arraybuffer',
				  params : {

				  },
				  }).then(function(response, status, headers, config) {
				  return response;
				  });

				  },
				  
				  'downloadRessortissant' : function(docId) {
					  var downloadUrl = 'api/downloadRessortissant/' + docId;
					  return $http.get(downloadUrl, {
					  responseType : 'arraybuffer',
					  params : {

					  },
					  }).then(function(response, status, headers, config) {
					  return response;
					  });

					  },
				  
				  'downloadDocumentFromDirectory' : function(docId) {
					  var downloadUrl = 'api/getPieceFromDirectory/' + docId;
					  return $http.get(downloadUrl, {
					  responseType : 'arraybuffer',
					  params : {

					  },
					  }).then(function(response, status, headers, config) {
					  return response;
					  });

					  },
				  
				  'getFileByDirectory' : function(docId) {
					  var downloadUrl = 'api/getPieceFromDirectory/' + docId;
					  return $http.get(downloadUrl, {
					  responseType : 'arraybuffer',
					  params : {

					  },
					  }).then(function(response, status, headers, config) {
					  return response;
					  });

					  },
					  
						'getUserByLogin': function(login){
							return $http({
								method : "GET",
								url : "api/users/getUserByLogin/"+login,
								dataType: "json",
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						'findExpediteurByRccm': function(rccm){
							return $http({
								method : "GET",
								url : "api/findExpediteurByRccm/"+rccm,
								dataType: "json",
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						
						'verifInscription': function(idExpediteur){
							return $http({
								method : "GET",
								url : "api/verifInscription/"+idExpediteur,
								dataType: "json",
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},

						'getUserByIdOeExpediteur': function(idExpediteur){
							return $http({
								method : "GET",
								url : "api/getUserByIdOeExpediteur/"+idExpediteur,
								dataType: "json",
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						'verifPaiementMobile': function(numPaiement){
							return $http({
								method : "GET",
								url : "api/verifPaiementMobile/"+numPaiement,
								dataType: "json",
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						'verifPaiementEspece': function(numPaiement){
							return $http({
								method : "GET",
								url : "api/verifPaiementEspece/"+numPaiement,
								dataType: "json",
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						'addAuthority': function(role){
							return $http({
								method : "POST",
								url : "api/createRole",
								dataType: "json",
								data : role,
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						
						'uploadImageUser': function(id,image){
							return $http({
								method : "PUT",
								url : "api/uploadImageUser/"+id,
								dataType: "json",
								traditional: true,
								data : image,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						
						'addNote': function(idProcess,note){
							return $http({
								method : "PUT",
								url : "api/addNote/"+idProcess,
								dataType: "json",
								traditional: true,
								data : note,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						'addReclamation': function(reclamation,idExp){
							return $http({
								method : "PUT",
								url : "api/addReclamation/"+idExp,
								dataType: "json",
								traditional: true,
								data : reclamation,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						'updateReclamation': function(reclamation){
							return $http({
								method : "PUT",
								url : "api/updateReclamation",
								dataType: "json",
								traditional: true,
								data : reclamation,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
						'findNoteByProcess': function(idProcess,etape){
							return $http({
								method : "GET",
								url : "api/findNoteByProcess/"+idProcess+"/"+etape,
								dataType: "json",
								traditional: true,
								headers : {
									'Content-Type' : 'application/json'	}
							}).then(function(response) {
				            	$rootScope.status = response.status;
				            	return response;
				            	}, function(error) {
				            	$rootScope.status = error.status;
				            	return error;
				            	});
							
						},
        };
    }
})();
