

(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('pieceJointe', {
            parent: 'entity',
            url: '/pieceJointes',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.pieceJointe.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pieceJointe/pieceJointes.html',
                    controller: 'PieceJointeController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pieceJointe');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pieceJointe.detail', {
            parent: 'entity',
            url: '/pieceJointe/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.pieceJointe.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pieceJointe/pieceJointe-detail.html',
                    controller: 'PieceJointeDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pieceJointe');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PieceJointe', function($stateParams, PieceJointe) {
                    return PieceJointe.get({id : $stateParams.id});
                }]
            }
        })
        .state('pieceJointe.new', {
            parent: 'pieceJointe',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pieceJointe/pieceJointe-dialog.html',
                    controller: 'PieceJointeDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                alfKey: null,
                                nom: null,
                                type: null,
                                commentaire: null,
                                dateCre: null,
                                supprimer: null,
                                idEntity: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('pieceJointe', null, { reload: true });
                }, function() {
                    $state.go('pieceJointe');
                })
            }]
        })
        .state('pieceJointe.edit', {
            parent: 'pieceJointe',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pieceJointe/pieceJointe-dialog.html',
                    controller: 'PieceJointeDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['PieceJointe', function(PieceJointe) {
                            return PieceJointe.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('pieceJointe', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('pieceJointe.delete', {
            parent: 'pieceJointe',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pieceJointe/pieceJointe-delete-dialog.html',
                    controller: 'PieceJointeDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['PieceJointe', function(PieceJointe) {
                            return PieceJointe.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('pieceJointe', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        });
}
})();

