(function() {
	'use strict';
	angular
		.module('infochamApp')
	   .config(stateConfig);
	stateConfig.$inject=['$stateProvider'];
	function stateConfig($stateProvider){
		$stateProvider
        .state('depot', {
            parent: 'entity',
            url: '/depots',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.depot.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/depot/depots.html',
                    controller: 'DepotController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('depot');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('depot.detail', {
            parent: 'entity',
            url: '/depot/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.depot.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/depot/depot-detail.html',
                    controller: 'DepotDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('depot');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Depot', function($stateParams, Depot) {
                    return Depot.get({id : $stateParams.id});
                }]
            }
        })
        .state('depot.new', {
            parent: 'depot',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/depot/depot-dialog.html',
                    controller: 'DepotDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                fraisPersDirProd: null,
                                fraisPersIndirProd: null,
                                totalMo: null,
                                fraisTransp: null,
                                commAchat: null,
                                fraisStock: null,
                                sousTrait: null,
                                entrRepar: null,
                                matNonStock: null,
                                matConsEnreg: null,
                                serExt: null,
                                chargFinanc: null,
                                amortiss: null,
                                totalProd: null,
                                dateCommDoss: null,
                                commentaire: null,
                                dateCre: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('depot', null, { reload: true });
                }, function() {
                    $state.go('depot');
                })
            }]
        })
        .state('depot.edit', {
            parent: 'depot',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/depot/depot-dialog.html',
                    controller: 'DepotDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['Depot', function(Depot) {
                            return Depot.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('depot', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('depot.delete', {
            parent: 'depot',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/depot/depot-delete-dialog.html',
                    controller: 'DepotDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['Depot', function(Depot) {
                            return Depot.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('depot', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        });
	}

	})();
