(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeDestinataireController', OeDestinataireController);

	OeDestinataireController.$inject = ['$scope', '$state', 'OeDestinataire', 'ParseLinks']
	function OeDestinataireController ($scope, $state, OeDestinataire, ParseLinks) {

        $scope.oeDestinataires = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            OeDestinataire.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.oeDestinataires = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.oeDestinataire = {
                telephone: null,
                ville: null,
                importateur: null,
                adresse: null,
                raisonSociale: null,
                fax: null,
                id: null
            };
        };
    }
})();
