(function() {
	'use strict';
	angular
	.module('infochamApp')
	.factory('Agenda', Agenda);

	Agenda.$inject = ['$http','$resource', 'DateUtils','$rootScope'];
	
	function Agenda ($http,$resource, DateUtils,$rootScope) {

		return {

			'deleteAgenda': function (id) {
				return $http({
					method : "PUT",
					url : "api/deleteAgenda/"+id,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'addCertificat': function (demandeCO) {

				return $http({
					method : "PUT",
					url : "api/certificat/addCertificat",
					dataType: "json",
					traditional: true,
					data : demandeCO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'addSpecimen': function (demandeSpecimenDTO) {

				return $http({
					method : "PUT",
					url : "api/specimen/addSpecimen",
					dataType: "json",
					traditional: true,
					data : demandeSpecimenDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'addSpecimenHL': function (demandeSpecimenDTO) {

				return $http({
					method : "PUT",
					url : "api/specimen/addSpecimenHL",
					dataType: "json",
					traditional: true,
					data : demandeSpecimenDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'addDossierJuridique': function (dossierJuridiqueDTO,type) {

				return $http({
					method : "PUT",
					url : "api/addDossierJuridique/"+type,
					dataType: "json",
					traditional: true,
					data : dossierJuridiqueDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'addDossierExport': function (dossierExportDTO) {

				return $http({
					method : "PUT",
					url : "api/addDossierExport",
					dataType: "json",
					traditional: true,
					data : dossierExportDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'addDossierJuridiqueHL': function (dossierJuridiqueDTO) {

				return $http({
					method : "PUT",
					url : "api/addDossierJuridiqueHL",
					dataType: "json",
					traditional: true,
					data : dossierJuridiqueDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'addDossierExportHL': function (dossierExportDTO) {

				return $http({
					method : "PUT",
					url : "api/addDossierExportHL",
					dataType: "json",
					traditional: true,
					data : dossierExportDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'updateDossierJuridique': function (dossierJuridiqueDTO,nextButtonCode) {

				return $http({
					method : "PUT",
					url : "api/updateDossierJuridique/"+nextButtonCode,
					dataType: "json",
					traditional: true,
					data : dossierJuridiqueDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'updateDossierExport': function (dossierExportDTO,nextButtonCode) {

				return $http({
					method : "PUT",
					url : "api/updateDossierExport/"+nextButtonCode,
					dataType: "json",
					traditional: true,
					data : dossierExportDTO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'addCertificatHL': function (demandeCO) {
				return $http({
					method : "PUT",
					url : "api/certificat/addCertificatHL",
					dataType: "json",
					traditional: true,
					data : demandeCO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			'updateCertificat': function (demandeCO, idProcess) {
				return $http({
					method : "PUT",
					url : "api/certificat/updateCertificat/" + idProcess,
					dataType: "json",
					traditional: true,
					data : demandeCO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			'updateModeDepot': function (modeDepot) {
				return $http({
					method : "PUT",
					url : "api/certificat/updateModeDepot",
					dataType: "json",
					traditional: true,
					data : modeDepot,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			'getAllAgenda': function () {
				return  $http.get('api/agenda/allInscription') .success(function (data){
				})
				.error(function (error, status){
				}); 
			},
			
			
			'getAllAgenda': function () {
				return  $http.get('api/agenda/allInscription') .success(function (data){
				})
				.error(function (error, status){
				}); 
			},
			
			'getModeleByTypeAndCode': function (type,code) {
				return  $http.get('api/getModeleByTypeAndCode/'+type+'/'+code) .success(function (data){
				})
				.error(function (error, status){
				}); 
			},
			
			'addDemandeDepotDossTechnique': function(depotDoss){
				return $http({
					method : "PUT",
					url : "api/addDemandeDepotDossTechnique",
					dataType: "json",
					traditional: true,
					data : depotDoss,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'addDemandeDepotDossTechniqueOffline': function(depotDoss,idExpediteur){
				return $http({
					method : "PUT",
					url : "api/addDemandeDepotDossTechniqueOffline/"+idExpediteur,
					dataType: "json",
					traditional: true,
					data : depotDoss,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'getDepotDossTechniqueById': function (idAgenda) {

				return  $http.get('api/getDepotDossTechniqueById/'+idAgenda) .success(function (data){
				})
				.error(function (error, status){
				}); 
			},
			'getReclamationById': function (idAgenda) {

				return  $http.get('api/getReclamationById/'+idAgenda) .success(function (data){
				})
				.error(function (error, status){
				}); 
			},
			
			'setModeDepot': function(modeDepot,depot){
				return $http({
					method : "POST",
					url : "api/setModeDepot/"+modeDepot,
					dataType: "json",
					traditional: true,
					data : depot,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			'updateDepot': function(depot){
				return $http({
					method : "POST",
					url : "api/updateDepot/",
					dataType: "json",
					traditional: true,
					data : depot,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			
			'setPourcentageMatierePremiere': function(depotDoss){
				return $http({
					method : "PUT",
					url : "api/setPourcentageMatierePremiere",
					dataType: "json",
					traditional: true,
					data : depotDoss,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
			
			'updateDemandeDepotDossTechnique': function(depotDoss){
				return $http({
					method : "POST",
					url : "api/updateDemandeDepotDossTechnique",
					dataType: "json",
					traditional: true,
					data : depotDoss,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},
	
			
			'deleteNote': function (id) {
				return $http({
					method : "PUT",
					url : "api/deleteNote/"+id,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'savePieces': function (pieces,idAgenda) {

				return $http({
					method : "PUT",
					url : "api/savePieces/"+idAgenda,
					dataType: "json",
					traditional: true,
					data : pieces,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'getCOStatistiqueAnnee': function (statistique) {
				return $http({
					method : "PUT",
					url : "api/getCOStatistiqueAnnee",
					dataType: "json",
					traditional: true,
					data : statistique,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			'getAdhesionStatistiqueAnnee': function (statistique) {
				return $http({
					method : "PUT",
					url : "api/getAdhesionStatistiqueAnnee",
					dataType: "json",
					traditional: true,
					data : statistique,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			'getPaiementStatistiqueAnnee': function (statistique,dStart,dFin) {
				return $http({
					method : "PUT",
					url : "api/getPaiementStatistiqueAnnee/"+dStart+"/"+dFin,
					dataType: "json",
					traditional: true,
					data : statistique,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},
			
			
			'numerisationCOHL': function (demandeCO, idProcess) {

				return $http({
					method : "PUT",
					url : "api/numerisationCOHL/"+idProcess,
					dataType: "json",
					traditional: true,
					data : demandeCO,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
			},
			
			'findAdhesionByExpediteur': function(idExpediteur){
				return $http({
					method : "GET",
					url : "api/findAdhesionByExpediteur/"+idExpediteur,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
	            	$rootScope.status = response.status;
	            	return response;
	            	}, function(error) {
	            	$rootScope.status = error.status;
	            	return error;
	            	});
				
			},

			
			  'getCCIByGouvernorat':function(codeGouv){
				  return $http({
						method : "GET",
						url : "api/getCCIByGouvernorat/"+codeGouv,
						dataType: "json",
						traditional: true,
						headers : {
							'Content-Type' : 'application/json'	}
					}).then(function(response) {
		            	$rootScope.status = response.status;
		            	return response;
		            	}, function(error) {
		            	$rootScope.status = error.status;
		            	return error;
		            	});
				},
			
				  'getCCIDTOByGouvernorat':function(codeGouv){
					  return $http({
							method : "GET",
							url : "api/getCCIDTOByGouvernorat/"+codeGouv,
							dataType: "json",
							traditional: true,
							headers : {
								'Content-Type' : 'application/json'	}
						}).then(function(response) {
			            	$rootScope.status = response.status;
			            	return response;
			            	}, function(error) {
			            	$rootScope.status = error.status;
			            	return error;
			            	});
					},
				
				
		};
	}
})();
