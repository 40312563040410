(function() {
	'use strict';
	angular.module('infochamApp').controller('BanqueDetailController',
			BanqueDetailController);
	BanqueDetailController.$inject = [ '$scope', '$rootScope', '$stateParams',
			'entity', 'Banque' ];

	function BanqueDetailController($scope, $rootScope, $stateParams, entity,
			Banque) {
		$scope.referentiel = entity;
		$scope.load = function(id) {
			Banque.get({
				id : id
			}, function(result) {
				$scope.banque = result;
			});
		};
		var unsubscribe = $rootScope.$on('infochamApp:banqueUpdate', function(
				event, result) {
			$scope.banque = result;
		});
		$scope.$on('$destroy', unsubscribe);

	}
})();
