(function() {
	'use strict';
	angular.module('infochamApp')

	.directive('loginInfo', function(User) {

		return {
			restrict : 'A',
			templateUrl : 'app/directives/menu/login-info.html',
			controller : 'LoginController'
		}
	})

})();
