(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('InitiationDemandeAdhesionOnlineController', InitiationDemandeAdhesionOnlineController);

	InitiationDemandeAdhesionOnlineController.$inject = ['$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 
	                                     				'DTOptionsBuilder', 'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita','CustomTypeAdhesion','Principal' ,'$http', '$stateParams' , '$sce', '$window', 'Inscription2', '$timeout', 'Agenda','OeExpediteur'];

	function InitiationDemandeAdhesionOnlineController ($filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, 
			DTOptionsBuilder, DTColumnBuilder, ReportingService, CustomProcessBonita,CustomTypeAdhesion,Principal, $http, $stateParams , $sce, $window, Inscription2, $timeout, Agenda,OeExpediteur) {
			$scope.add = true;
			$scope.attcompdoc1="ATTCOMPDOC1"
			$scope.codePieceOnline = "ADHES";
			$scope.codePieceOffline = "ADHESOFFLINE";
			$scope.codePieceRnvOnline = "RENVADHES";
			$scope.codeProcess =$stateParams.codeProcess;
			$scope.c = "ccc";
			$scope.showactionbutton = true;
			$scope.specimensignaturesparam = [];
			var vm = this;
			$scope.ctrl = this;
			$scope.date=  $filter('date')(new Date(), 'yyyy-MM-dd');
			$scope.expediteur= {};
			$scope.demandeDadhesion = {};
			$scope.demandeDadhesion.typeAdhesionDTO = {};
			$scope.demandeDadhesion.dateCre = $scope.date;
			$scope.specimenSignatures = [];
			$scope.customProcess = {};
			$scope.agendaDTO = {};
			$scope.userRegister = {};
			$scope.oeExpediteur = {};
//			$scope.adhesCO = false;
			$scope.compareTypeProcess = function(code) {
				if (angular.equals( { status:$stateParams.codeProcess}, { status: code})  ) {
					return true;
				}
				return false;
			}
			if ($scope.compareTypeProcess("ADHES") || $scope.compareTypeProcess("RENVADHES") || $scope.compareTypeProcess(null)) {
				$scope.isOnline = true;
				$scope.codePiece = $scope.codeProcess;
				if ($stateParams.customProcess != undefined && $stateParams.customProcess != null) {
					$scope.pieceValide = false;
					$scope.showactionbutton = false;
					$scope.customProcess = $stateParams.customProcess;
					$scope.agendaDTO = $scope.customProcess.agendaDTO;
					$scope.demandeDadhesion = $scope.customProcess.demandeAdhesionDTO;
					$scope.oeExpediteur = $scope.customProcess.oeExpediteurDTO;
					$scope.specimenSignatures = $scope.customProcess.specimenSignatureDTOs;
					$scope.pieceJointeDTOs = $scope.customProcess.pieceJointeDTOs;
					$scope.oeExpediteur.dateAdhesion = new Date($scope.oeExpediteur.dateAdhesion)
					if($scope.oeExpediteur.participationEtrangere != null && $scope.oeExpediteur.participationEtrangere != undefined){
			        	   $scope.oeExpediteur.participationEtrangere=parseInt($scope.oeExpediteur.participationEtrangere);
			           }
					if(($scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="KAIR" ||$scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MAHD" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MONA" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="SOUS") && ($scope.oeExpediteur.typeOperateurDTO.codeRubrique=="COMM" || $scope.oeExpediteur.typeOperateurDTO.codeRubrique=="INDS")){
					$scope.adhesCO = true;
				}else {
					$scope.adhesCO=false;
				}
					CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
						$scope.typeAdhesions = result.data;
						$scope.getTypeAdhesion($scope.demandeDadhesion.typeAdhesionDTO.id);
					});
					$scope.demandeDadhesion.dateCre=  $filter('date')($scope.demandeDadhesion.dateCre, 'yyyy-MM-dd');
					
				} else {
					
					$scope.customProcess = {
							agendaDTO: {
								typeProcessDTO: {
									codeRubrique: $scope.codeProcess
								}
							}
					}
					

					
					Principal.identity().then(function(account) {
						$scope.user = account;
						if ($scope.compareTypeProcess("ADHES") ) {
							$http.get("api/findAllAdhesionByExpediteur/"+$scope.user.oeExpediteur.id).then(function(result) {
								if (result.data.length != 0) {
									var date = new Date(result.data[0].dateAdhesion);
									var d = new Date();
									var year = d.getFullYear();
									var anneeAdhesion = date.getFullYear();
									if (result.data.length != 0) {
										if(anneeAdhesion!=year){
											swal("Operation interrompue!", "Il faut faire un renouvellement", "error");
											$state.go("home");
										}else{
											swal("Operation interrompue!", "Vous etes deja un adherent pour l annee en cours", "error");
											$state.go("home");
										}
//										swal("Operation interrompue!", "Vous etes deja un adherent pour l annee en cours", "error");
//										$state.go("home");
									}
								}
							});
						}
						if ($scope.compareTypeProcess("RENVADHES") ) {
							$http.get("api/findAllAdhesionByExpediteur/"+$scope.user.oeExpediteur.id).then(function(result) {
								if (result.data.length != 0) {
									var date = new Date(result.data[0].dateAdhesion);
									var d = new Date();
									var year = d.getFullYear();
									var anneeAdhesion = date.getFullYear();
									if(anneeAdhesion == year){
										swal("Opération interrompue!", "Vous êtes déjà un adhérent pour l’année en cours", "error");
										$state.go("home");
									}
								}
								if (result.data.length == 0) {
									swal("Opération interrompue!", "Il faut déposé une nouvelle adhésion", "error");
									$state.go("home");
								}
							});
						}
						
							OeExpediteur.get({id: $scope.user.oeExpediteur.id}, function(result) {
								$scope.oeExpediteur = result;
								$scope.getTypeOE($scope.oeExpediteur.typeOperateurDTO.id);
								CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
									$scope.typeAdhesions = result.data;
								});
							});
//						}, 1000 );
						
						
					});				
				}
			} else if ($scope.compareTypeProcess("ADHESOFFLINE")) {
				$scope.codePiece = $scope.codePieceOffline;

				$scope.isOnline = false;
				$scope.typeChoix = "siege";
				
				$scope.customProcess = {
						agendaDTO: {
							typeProcessDTO: {
								codeRubrique: $scope.codePieceOffline
							}
						}
				}
			} 
			
			
			if ($scope.agendaDTO.id != undefined && $scope.agendaDTO.id != null) {
				$scope.idagenda = $scope.agendaDTO.id
			}
			
			$scope.isAdhesCO = function() {
				if(($scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="KAIR" ||$scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MAHD" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MONA" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="SOUS") && ($scope.oeExpediteur.typeOperateurDTO.codeRubrique=="COMM" || $scope.oeExpediteur.typeOperateurDTO.codeRubrique=="INDS")){
					$scope.adhesCO = true;
				}else {
					$scope.adhesCO=false;
				}

			}
			 $scope.searchAspect = function(searchStr) {
				    if(searchStr.val == undefined || searchStr.val == null  || searchStr.val == "") {
				        var searchStrEncoded = null;
				    } else {
				        var searchStrEncoded = searchStr.val// escape(searchStr);
				    }
				    return $http.get("api/getAllExpediteurDTOByRaison/"+ searchStrEncoded).then(function(data) {
						 $scope.states = data.data;
					     return data.data;
						});
				    
				};
	
			
	

			/********************************************/

			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}

			$scope.typeOperateurs = [{id:1 ,libelle: "AUTRE"}, {id:1 ,libelle: "EXPORTATEUR"} ,{id:1 ,libelle: "PRODUCTEUR"}];

	
			$scope.getTypeAdhesion = function(id) {
				var single_object =  $filter('filter')($scope.typeAdhesions, function (d) {return d.id == id;})[0];
				if (single_object != undefined && single_object != null  ) {
					$scope.demandeDadhesion.typeAdhesionLibelle = single_object.description + "(" + (single_object.montant).toFixed(3) + " TND)";

				}
				return "";

			}

			
			$scope.getTypeOE = function(id) {
				var single_object =  $filter('filter')($scope.typeOperateurs, function (d) {return d.id == id;})[0];
				if (single_object != undefined && single_object != null  ) {
					$scope.demandeDadhesion.typeOELibelle = single_object.libelle ;

				}
				return "";

			}

		

			$scope.lovModelTypeOe = 'api/referentiels/getByCode/TYOE';// this will be
			// the LOV
			// datasource
			$scope.lovTitleTypeOe = 'Liste des activités';
			$scope.lovColumnListTypeOe = [  'Libellé' ];
			$scope.lovFieldListTypeOe = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackTypeOe = function(result) {
				$scope.oeExpediteur.typeOperateurDTO = result;
				$scope.$emit('eventSecteur', result)
				$scope.isAdhesCO();
//				if(($scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="KAIR" ||$scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MAHD" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MONA" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="SOUS") && ($scope.oeExpediteur.typeOperateurDTO.codeRubrique=="COMM" || $scope.oeExpediteur.typeOperateurDTO.codeRubrique=="INDS")){
//					$scope.adhesCO = true;
//				}else {
//					$scope.adhesCO=false;
//				}
			}


			$scope.lovModelSecteur = 'api/referentiels/getByCode/SACT';// this will be
			// the LOV
			// datasource
			$scope.lovTitleSecteur = 'Liste des secteurs';
			$scope.lovColumnListSecteur = [  'Libellé' ];
			$scope.lovFieldListSecteur = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackSecteur = function(result) {
				$scope.oeExpediteur.secteurActiviteDTO = result;

			//	$scope.user.oeExpediteur.id
				$scope.$emit('eventSousSecteur',result)
			}
			
			$rootScope
			.$on(
					'eventSecteur',
					function(event, result) {
						$scope.lovModelSecteur = 'api/referentiels/secteur/'+ result.codeRubrique;// this
						// will
						// be;// this will
						// be
			// the LOV
			// datasource
						$scope.lovTitleSecteur = 'Liste des secteurs';
						$scope.lovColumnListSecteur = [  'Libellé' ];
						$scope.lovFieldListSecteur = [  'libelle' ];
						// function to be called when you click "choose" button
						$scope.lovCallBackSecteur = function(result) {
			$scope.oeExpediteur.secteurActiviteDTO = result;
			$scope.$emit('eventSousSecteur', result)
			}
					});		
				

			$scope.change = function(oeExpediteurSelected) {
				if (oeExpediteurSelected != undefined  || oeExpediteurSelected != null) {
					$scope.oeExpediteur = oeExpediteurSelected;
					
					if ($scope.oeExpediteur.dateAdhesion != null) {
						swal("Opération interrompue!", "Cette opérateur économique est déjà un adhérant", "error");
						$scope.oeExpediteur = {};
					} else {
						

						Inscription2.getUserByIdOeExpediteur($scope.oeExpediteur.id).then(function (result) {
							if (result.data != "" && result.data != null) {
								swal("", "Cette opérateur économique est déjà inscrit", "warning");
								$scope.userRegister = result.data;
								
								$scope.userExiste = true;
								
							} else {
								$scope.userExiste = false;
								$scope.userRegister = {};
							}
						});
						$scope.$emit('eventSousSecteur',$scope.oeExpediteur.sousSecteurDTO);
						CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
							$scope.typeAdhesions = result.data;
							
							
						});
						
					}
					
					
					
				} else {
					$scope.userRegister = {};
					$scope.oeExpediteur = {};
				}
			}
			
			
			$scope.lovModelOeExp = 'api/getAllExpediteurDTO';// this will be
			// the LOV
			// datasource
			$scope.lovTitleOeExp = 'Liste des raisons sociales';
			$scope.lovColumnListOeExp = [' Raison Sociale '];
			$scope.lovFieldListOeExp = ['raisonSociale'];
			// function to be called when you click "choose" button
			$scope.lovCallBackOeExp = function(result) {
				$scope.oeExpediteur = result;
				$scope.change($scope.oeExpediteur);
				
				if(result.situationAdhesion==false){
					$scope.situationAdhesion="Nouvelle d’adhésion"
				}else{
					$scope.situationAdhesion="renouvellement d’adhésion"
				}

				
			}
			
			
			
			
			$scope.lovModelCatJur = 'api/referentiels/getByCode/CATJUR';// this will be
			// the LOV
			// datasource
			$scope.lovTitleCatJur = 'Liste des Formes juridiques';
			$scope.lovColumnListCatJur = [ '','Libellé' ];
			$scope.lovFieldListCatJur = [ 'valeur1','codeListeMere' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackCatJur = function(result) {
				if(result.codeRubrique =="PM"){
					swal(
							"veuillez Choisir le type de la personne morale",
							"", "error")
					}else{
						$scope.oeExpediteur.categorieJuridiqueDTO = result;
				}
			}

			$http.get("api/referentiels/getByCode/TYOE").then(function(result) {
				$scope.typeOperateurs = result.data;
			});

			$scope.lovModel1 = 'api/referentiels/sousSecteur/all';// this will be
			// the LOV
			// datasource
			$scope.lovTitle1 = 'Liste des sous Secteur';
			$scope.lovColumnList1 = [  'Libellé' ];
			$scope.lovFieldList1 = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBack1 = function(result) {
				$scope.oeExpediteur.sousSecteurDTO = result;
				$scope.$emit('eventSousSecteur',result);

			}

			$rootScope.$on('eventSousSecteur', function(event,result) {
				$scope.lovModel1 = 'api/referentiels/getSousRefByCode/BACT/' +result.codeRubrique;// this will be
				// the LOV
				// datasource
				$scope.lovTitle1 = 'Liste des sous Secteur';
				$scope.lovColumnList1 = [  'Libellé' ];
				$scope.lovFieldList1 = [  'libelle' ];
				// function to be called when you click "choose" button
				$scope.lovCallBack1 = function(result) {
					$scope.oeExpediteur.sousSecteurDTO = result;
				}
			});
			

			$scope.lovModelOeExpediteur = 'api/getAllExpediteurDTO';// this will be
			// the LOV
			// datasource
			$scope.lovTitleOeExpediteur = 'Liste des raisons sociales';
			$scope.lovColumnListOeExpediteur = [' Raison Sociale '];
			$scope.lovFieldListOeExpediteur = ['raisonSociale'];
			// function to be called when you click "choose" button
			$scope.lovCallBackOeExpediteur = function(result) {
				$scope.oeExpediteur = result;

				Inscription2.verifInscription($scope.oeExpediteur.id).then(function (result) {
					if (result.data) {
						swal("Opération interrompue!", "Cette opérateur économique est déjà un adhérant", "error");
						$scope.oeExpediteur = null;
					}
				});
				$scope.$emit('eventSousSecteur',$scope.oeExpediteur.sousSecteurDTO);

				CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
					$scope.typeAdhesions = result.data;
					
				});
			}
			
			/****************CONTACT**************/
			
			$rootScope.$on('eventLocaliteAdrSiege', function(event,result) {
				$scope.lovModel4 = 'api/referentiels/localite/'+result.codeRubrique;// this will be
				// the LOV
				// datasource
				$scope.lovTitle4 = 'Liste des villes';
				$scope.lovColumnList4 = [  'Libellé' ];
				$scope.lovFieldList4 = [  'libelle' ];
				// function to be called when you click "choose" button
				$scope.lovCallBack4 = function(result) {
					$scope.oeExpediteur.adresseSiegeDTO.localite = result;
				}
			});
			$rootScope.$on('eventLocaliteAdrUsine', function(event,result) {
				$scope.lovModel6 = 'api/referentiels/localite/'+result.codeRubrique;// this will be
//				the LOV
//				datasource
				$scope.lovTitle6 = 'Liste des villes';
				$scope.lovColumnList6 = [  'Libellé' ];
				$scope.lovFieldList6 = [  'libelle' ];
//				function to be called when you click "choose" button
				$scope.lovCallBack6 = function(result) {
					$scope.oeExpediteur.adresseUsineDTO.localite = result;
				}
			});
			
			
			$scope.verifierMmCord = function(valeur1 /*adresse*/ , valeur2 /*CCI*/) {
	  			if (valeur2 != undefined  && valeur2 !=null) {

	  			if (  valeur1.gouvernorat.valeur1 != valeur2.code ) {
	  				$scope.mmCordonnee = false;
	  			} else {
	  				$scope.mmCordonnee = true;
  					CustomTypeAdhesion.getTypeAdhesionByCCI(valeur2.id).then(function(result) {
						$scope.typeAdhesions = result.data;
						
					});
	  			}
	  			}else {
	  				$scope.mmCordonnee = true;
  					$scope.siegeUsine = "N"
				}

			}
			
			
			$scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
	  		// the LOV
	  		// datasource
	  		$scope.lovTitle3 = 'Liste des Gouvernorats';
	  		$scope.lovColumnList3 = [  'Libellé' ];
	  		$scope.lovFieldList3 = [  'libelle' ];
	  		// function to be called when you click "choose" button
	  		$scope.lovCallBack3 = function(result) {
	  			if ($scope.oeExpediteur.adresseSiegeDTO == null) {
		  			$scope.oeExpediteur.adresseSiegeDTO = {};
				}
	  			$scope.oeExpediteur.adresseSiegeDTO.gouvernorat = result;
	  			Agenda.getCCIDTOByGouvernorat($scope.oeExpediteur.adresseSiegeDTO.gouvernorat.valeur1).then(function (result){
	  				$scope.oeExpediteur.cciDTO = result.data ; 
	  				$scope.cciDTOSiege = result.data;
		  			$scope.verifierMmCord($scope.oeExpediteur.adresseSiegeDTO,$scope.cciDTOUsine);

	  				CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
						$scope.typeAdhesions = result.data;
						
					});
				});
	  			
	  			
	  			$scope.$emit('eventLocaliteAdrSiege',result)
	  			$scope.isAdhesCO();
//	  			if(($scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="KAIR" ||$scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MAHD" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="MONA" || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat.codeRubrique=="SOUS") && ($scope.oeExpediteur.typeOperateurDTO.codeRubrique=="COMM" || $scope.oeExpediteur.typeOperateurDTO.codeRubrique=="INDS")){
//					$scope.adhesCO = true;
//				}else {
//					$scope.adhesCO=false;
//				}
	  		}
	  		
	  		$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
	  		// the LOV
	  		// datasource
	  		$scope.lovTitleBureau = 'Liste des bureaux';
	  		$scope.lovColumnListBureau = [ 'Libellé' ];
	  		$scope.lovFieldListBureau = [  'libelle' ];
	  		
	  		// function to be called when you click "choose" button
	  		$scope.lovCallBackBureau = function(result) {
	  			$scope.oeExpediteur.bureau = result;
	  			
	  		}
	  		
	  		$scope.lovModelRegime = 'api/referentiels/regimes';// this will be
	  		// the LOV
	  		// datasource
	  		$scope.lovTitleRegime = 'Liste des regimes';
	  		$scope.lovColumnListRegime = [ 'Libellé' ];
	  		$scope.lovFieldListRegime = [  'libelle' ];
	  		
	  		// function to be called when you click "choose" button
	  		$scope.lovCallBackRegime = function(result) {
	  			$scope.oeExpediteur.regimeDTO = result;
	  			
	  		}
	  		
	  		
	  		$scope.lovModelPaysEtranger = 'api/referentiels/allPays';// this will be
	  		// the LOV
	  		// datasource
	  		$scope.lovTitlePaysEtranger = 'Liste des pays';
	  		$scope.lovColumnListPaysEtranger = [ 'Libellé' ];
	  		$scope.lovFieldListPaysEtranger = [  'libelle' ];
	  		
	  		// function to be called when you click "choose" button
	  		$scope.lovCallBackPaysEtranger = function(result) {
	  			$scope.oeExpediteur.paysEtrangerDTO = result;
	  			
	  		}
	  	
	  		
		  	  $scope.lovModel5 = 'api/referentiels/gouvernorat';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle5 = 'Liste des Gouvernorats';
		  		$scope.lovColumnList5 = [ 'Libellé' ];
		  		$scope.lovFieldList5 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack5 = function(result) {
		  			if ($scope.oeExpediteur.adresseUsineDTO == null) {
			  			$scope.oeExpediteur.adresseUsineDTO = {};
					}
		  			$scope.oeExpediteur.adresseUsineDTO.gouvernorat = result;
		  			Agenda.getCCIDTOByGouvernorat($scope.oeExpediteur.adresseUsineDTO.gouvernorat.valeur1).then(function (result){
		  				$scope.cciDTOUsine = result.data;
			  			$scope.verifierMmCord($scope.oeExpediteur.adresseUsineDTO,$scope.cciDTOSiege);
	  						CustomTypeAdhesion.getTypeAdhesionByCCI($scope.cciDTOUsine.id).then(function(result) {
								$scope.typeAdhesions = result.data;
								
							});
					});
		  			
		  			
		  			$scope.$emit('eventLocaliteAdrUsine',result)

		  		}
		  		
		  		$scope.choixSiege= function(choix) {
		  			
		  			if (choix == "siege" ) {
		  				$scope.oeExpediteur.cciDTO = $scope.cciDTOSiege;
					} else {
		  				$scope.oeExpediteur.cciDTO = $scope.cciDTOUsine;

					}
		  			
		  			CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
						$scope.typeAdhesions = result.data;
						
					});

		  		}
		  		
		  		$scope.siegeToUsine= function(siegeUsine) {
		  			if(siegeUsine == "O"){

		  				$scope.oeExpediteur.adresseUsineDTO = $scope.oeExpediteur.adresseSiegeDTO;
		  				$scope.mmCordonnee = true;

		  			}
		  			else {
		  				$scope.oeExpediteur.adresseUsineDTO = {} ;
		  				$scope.cciDTOSiege = null;
		  				$scope.mmCordonnee = false;
		  			}
		  			
					Agenda.getCCIDTOByGouvernorat($scope.oeExpediteur.adresseSiegeDTO.gouvernorat.valeur1).then(function (result){
		  				$scope.oeExpediteur.cciDTO = result.data ; 
		  				CustomTypeAdhesion.getTypeAdhesionByCCI(result.data.id).then(function(result) {
							$scope.typeAdhesions = result.data;
							
						});
		  			});
		  			
		  		}
				/****************CONTACT**************/

			
		
		  		$scope.checkMail = function(email) {
					Inscription2.emailExist(email).then(function (result) {
						$scope.mailExist = result.data;
						if (result.data == true) {
							$scope.errors.push("Email existe déjà.")

						}
					});
				}

		  		$scope.checkLogin = function(login) {
					Inscription2.loginExist(login).then(function (result) {
						$scope.loginExist = result.data;
						if (result.data == true) {
							$scope.errors.push("Login existe déjà.")

						}
					});
				}
		  		
			vm.validationStep = function(item, actions) {
				$scope.errors =[];
				 if(item.index==0){
					 
					 if($scope.oeExpediteur.denomination == null || $scope.oeExpediteur.denomination == ""){
							$scope.errors.push("Dénomination est requis.")
					 } 
					 if($scope.oeExpediteur.raisonSociale == null || $scope.oeExpediteur.raisonSociale == ""){
							$scope.errors.push("Raison sociale est requis.")
					 } 
					 if($scope.demandeDadhesion.typeAdhesionDTO.id == null){
							$scope.errors.push("Type d'adhésion est requis.")
					 } 
//					 if($scope.oeExpediteur.matriculeFiscal == null || $scope.oeExpediteur.matriculeFiscal == ""){
//							$scope.errors.push("Matricule fiscal est requis.")
//					 } 
					 
					 if($scope.oeExpediteur.secteurActiviteDTO == null ){
							$scope.errors.push("Secteur est requis.")
					 } 
					 if($scope.oeExpediteur.sousSecteurDTO == null ){
							$scope.errors.push("Sous secteur est requis.")
					 } 
//					 if($scope.oeExpediteur.codeDouane == null || $scope.oeExpediteur.codeDouane == ""){
//							$scope.errors.push("Code douane est requis.")
//					 }
					 
					
					 
					 if($scope.oeExpediteur.categorieJuridiqueDTO == null || $scope.oeExpediteur.categorieJuridiqueDTO.libelle == ""){
							$scope.errors.push("Forme juridique est requis.")
					 }
//					 if($scope.oeExpediteur.codeClient == null  || $scope.oeExpediteur.codeClient ==  ""){
//							$scope.errors.push("Code client est requis.")
//					 }
//					 if($scope.oeExpediteur.denominationAr == null || $scope.oeExpediteur.denominationAr == ""){
//							$scope.errors.push(" الاسم التجاري est requis.")
//					 }
					 if($scope.oeExpediteur.numIde  ==  null || $scope.oeExpediteur.numIde == null  || $scope.oeExpediteur.numIde ==  ""){
							$scope.errors.push("Num Identifiant est requis.")
					 }
					
					 if($scope.oeExpediteur.responsableDTO  ==  null || $scope.oeExpediteur.responsableDTO.nom == null  || $scope.oeExpediteur.responsableDTO.nom ==  ""){
							$scope.errors.push("Nom du responsable est requis.")
					 }
					 if($scope.oeExpediteur.responsableDTO  ==  null || $scope.oeExpediteur.responsableDTO.prenom == null  || $scope.oeExpediteur.responsableDTO.prenom ==  ""){
							$scope.errors.push("Prenom du responsable est requis.")
					 }
					 
					 
					 if($scope.oeExpediteur.adresseSiegeDTO  == null || $scope.oeExpediteur.adresseSiegeDTO.adresse == null || $scope.oeExpediteur.adresseSiegeDTO.adresse == "" ){
							$scope.errors.push("Adresse siege est requis.")
					 }
					 if($scope.oeExpediteur.adresseSiegeDTO  == null || $scope.oeExpediteur.adresseSiegeDTO.codePostal == null || $scope.oeExpediteur.adresseSiegeDTO.codePostal == "" ){
							$scope.errors.push("Code postal siege est requis.")
					 }
					 if($scope.oeExpediteur.adresseSiegeDTO  == null || $scope.oeExpediteur.adresseSiegeDTO.gouvernorat == null  ){
							$scope.errors.push("Gouvernorat siege est requis.")
					 }
//					 if($scope.oeExpediteur.adresseSiegeDTO  == null || $scope.oeExpediteur.adresseSiegeDTO.localite == null  ){
//							$scope.errors.push("Localité siege est requis.")
//					 }
					 if($scope.oeExpediteur.adresseSiegeDTO  == null || $scope.oeExpediteur.adresseSiegeDTO.tel == null || $scope.oeExpediteur.adresseSiegeDTO.tel == "" ){
							$scope.errors.push("Téléphone siege est requis.")
					 }
//					 if($scope.adhesCO){
//					 if( $scope.oeExpediteur.codeDouane == null || $scope.oeExpediteur.codeDouane == "" ){
//							$scope.errors.push("Code en douane est requis.")
//					 }
//					 
//					 if( $scope.oeExpediteur.matriculeFiscal == null || $scope.oeExpediteur.matriculeFiscal == "" ){
//							$scope.errors.push("Matricule Fiscale est requis.")
//					 }
//					 
//					 }
					 
					 if ($scope.pieceValide == undefined || $scope.pieceValide==false) {
						 $scope.errors.push("Il faut attacher toutes les piéces justificatives!")

					 }
					 if (!$scope.isOnline) {
						 if ($scope.userRegister.login == null) {
							 $scope.errors.push("Login est requis.")

						 }
						 if ($scope.userRegister.lastName == null) {
							 $scope.errors.push("Nom est requis.")

						 }
						 if ($scope.userRegister.firstName == null) {
							 $scope.errors.push("Prénom est requis.")

						 }
						 if ($scope.userRegister.email == null) {
							 $scope.errors.push("Email est requis.")

						 }
						 
						 if (!$scope.userExiste) {
							 if ($scope.userRegister.password == null) {
								 $scope.errors.push("Mot de passe est requis.")

							 }
							 if ($scope.userRegister.confirmPassword == null) {
								 $scope.errors.push("Cofirmation mot de passe est requis.")
							 }
							 if ($scope.userRegister.password != $scope.userRegister.confirmPassword) {
								 $scope.errors.push("Concordance des mots de passes est requis.")

							 }
							 $scope.checkMail($scope.userRegister.mail);
							 $scope.checkLogin($scope.userRegister.login);
						}
						
						 
						 
					 }
				 } else if (item.index==1) {
					
					 if ($scope.pieceValide == undefined || $scope.pieceValide==false) {
						 $scope.errors.push("Il faut attacher toutes les piéces justificatives!")

					 }
				}



				if($scope.errors.length> 0){
					actions.error($scope.errors);
					$("html, body").animate({scrollTop: 400},"slow");
				}
				else {
					actions.success();

				}



			}
			vm.completeWizard = function(scope, actions) {
				$scope.initiation();
				
			}

			function formErrors(form){
				var errors = [];
				for(var key in form.$error){
					errors.push(key + "=" + form.$error);
				}
				if(errors.length > 0){
					for(var err in form.$error.required){
					}
				}
			}

			$scope.test = function(obj) {

				formErrors(obj);
			}


			if ($scope.agendaDTO.idCase != undefined) {
				CustomProcessBonita.getListBoutonWorkflowByIdCaseIdTask($scope.agendaDTO.idCase,$scope.agendaDTO.idTask).then(function(result) {

					var	html = null;
					$scope.listButton=result.data;
					$scope.showDemandeInfo = true;
					for (var i = 0, keys = Object.keys($scope.listButton), ii = keys.length; i < ii; i++) {
						$scope.key=keys[i];
						$scope.value=$scope.listButton[keys[i]];
						var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2" style="margin-right: 10px;" >'+$scope.value+'</button>&nbsp;&nbsp;';
						angular.element("#btnWorkflow").append($compile(html)($scope));
					}

				});

			}

			$scope.traitement = function (nextStep) {
				$scope.customProcess.nextStep = nextStep;
				$scope.initiation();

			}

			$scope.initiation = function() {
				$scope.customProcess.demandeAdhesionDTO = $scope.demandeDadhesion;
				$scope.customProcess.oeExpediteurDTO = $scope.oeExpediteur;
				$scope.customProcess.oeExpediteurDTO.userRegister = $scope.userRegister;
//				$scope.customProcess.specimenSignatureDTOs = $scope.specimenSignatures;
				$scope.customProcess.pieceJointeDTOs = $scope.pieces;
				swal($scope.styleSwalConfirmation,function(){

					CustomProcessBonita.createProcessAdhesionOnline($scope.customProcess).then(function(result) {
						if(angular.equals( { status: result.status }, { status: 200 } )){
							$scope.agendaDTO=result.data;
							swal({
								position: 'center',
								type: 'success',
								title: 'Votre demande d’adhésion est en cours de traitement, un email de suivi vous sera transmis prochainement ',
								showConfirmButton: true,
								timer: 50000
							})
							$state.go('home', null, { reload: true } );

						} else{
							swal("veuillez réessayer","", "error")

						}
					});
				})
			}

			$scope.next = function() {
				$state.go("cci");

			}

			
			var unsubscribe1 = $rootScope.$on('eventEmitedPiece', function(event, result) {
				$scope.pieces = result.message;
				$scope.pieceValide = result.pieceValide;
			});
			
			
			$scope.nombreAvecVirgule = function(number)
			{
				if (number != undefined && number != null && number != "") {
					var a =parseFloat(number)
					return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
				} else {
					return "";
				}
			}
		}
})();
