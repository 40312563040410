(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('FactureDetailController', FactureDetailController);

	FactureDetailController.$inject = ['Referentiel','ReferentielWs','$uibModalInstance','$rootScope','Inscription2','DTColumnDefBuilder','fileUpload','Agenda' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function FactureDetailController (Referentiel,ReferentielWs,$uibModalInstance,$rootScope,Inscription2,DTColumnDefBuilder,fileUpload,Agenda,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		 $scope.detail ={};
		 $scope.idExpediteur= $stateParams.idExpediteur ;
		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$scope.save = function(detail) {
				$rootScope.$broadcast('eventEmitedDetailFacture',{message:detail});
				$uibModalInstance.dismiss('cancel');
		};
		ReferentielWs.getAllProduitByUser($scope.idExpediteur).then(function(result){
			$scope.produits=result.data;
		})
		 function createdRow(row, data, dataIndex) {
						  $compile(angular.element(row).contents())($scope);
						  }
			  $scope.lovModel7 = 'api/referentiels/getAllProduitByUser/'+$scope.idExpediteur;// this will be
				// the LOV
				// datasource
				$scope.lovTitle7 = 'Liste des produits';
				$scope.lovColumnList7 = [  'Désignation' ];
				$scope.lovFieldList7 = [  'designation' ];
				// function to be called when you click "choose" button
				$scope.lovCallBack7 = function(result) {
					$scope.detail.designation = result.designation;
				}
		ReferentielWs.getAllUnite().then(function(result){
			$scope.unites=result.data;
		})
}
})();
