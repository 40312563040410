(function() {
	'use strict';

	angular.module('infochamApp').controller('AffectationRoleController',
			AffectationRoleController);

	AffectationRoleController.$inject = [ 'Principal', '$filter', '$state',
			'$scope', 'ParseLinks', 'DTOptionsBuilder', '$translate',
			'DTColumnBuilder', '$rootScope', '$resource', '$compile',
			'$stateParams' ];
	function AffectationRoleController(Principal, $filter, $state, $scope,
			ParseLinks, DTOptionsBuilder, $translate, DTColumnBuilder,
			$rootScope, $resource, $compile, $stateParams) {
		$scope.acteurs = [];
		$scope.dtOptions = DTOptionsBuilder
				.newOptions()
				.withOption(
						'ajax',
						{
							url : 'api/actorMember',
							type : 'GET',
							error : function(xhr, exception) {
								if (xhr.status === 0)
									alert('Error : ' + xhr.status
											+ 'You are not connected.');
								else if (xhr.status == "201")
									alert('Error : ' + xhr.status
											+ '\nServer error.');
								else if (xhr.status == "404")
									alert('Error : ' + xhr.status
											+ '\nPage note found');
								else if (xhr.status == "500")
									swal({
										timer : 3000,
										title : "Impossible!",
										text : "Oops ... Serveur Bonita est hors service ...!",
										showConfirmButton : false,
										type : "error"
									})
								else if (exception === 'parsererror')
									alert('Error : ' + xhr.status
											+ '\nImpossible to parse result.');
								else if (exception === 'timeout')
									alert('Error : ' + xhr.status
											+ '\nRequest timeout.');
								else
									alert('Error .\n' + xhr.responseText);
							}
						})
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])
				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					},
					'4' : {
						type : 'text'
					},
					'5' : {
						type : 'text'
					},
					'6' : {
						type : 'text'
					}

				})

		$scope.dtColumns = [
				DTColumnBuilder.newColumn('id').withTitle(
						$translate.instant('affectationRole.detail.id'))
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('actorId').withTitle(
						$translate.instant('affectationRole.detail.actorId'))
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('roleId').withTitle(
						$translate.instant('affectationRole.detail.roleId'))
						.withOption('width', '10%'),
				DTColumnBuilder.newColumn('groupId').withTitle(
						$translate.instant('affectationRole.detail.groupId'))
						.withOption('width', '18%'),
				DTColumnBuilder.newColumn('userId').withTitle(
						$translate.instant('affectationRole.detail.userId'))
						.withOption('width', '6%'),

				DTColumnBuilder.newColumn(null).withTitle('Actions')
						.notSortable().withClass('datatable-action-width')
						.renderWith(actionsHtml) ];

		$rootScope
				.$on(
						'$translateChangeSuccess',
						function() {
							$scope.dtOptions.withLanguageSource($translate
									.instant('global.datatable'));
							$scope.dtColumns = [
									DTColumnBuilder
											.newColumn('id')
											.withTitle(
													$translate
															.instant('affectationRole.detail.id'))
											.withOption('width', '10%'),
									DTColumnBuilder
											.newColumn('actorId')
											.withTitle(
													$translate
															.instant('affectationRole.detail.actorId'))
											.withOption('width', '10%'),
									DTColumnBuilder
											.newColumn('roleId')
											.withTitle(
													$translate
															.instant('affectationRole.detail.roleId'))
											.withOption('width', '10%'),
									DTColumnBuilder
											.newColumn('groupId')
											.withTitle(
													$translate
															.instant('affectationRole.detail.groupId'))
											.withOption('width', '18%'),
									DTColumnBuilder
											.newColumn('userId')
											.withTitle(
													$translate
															.instant('affectationRole.detail.userId'))
											.withOption('width', '6%'),

									DTColumnBuilder.newColumn(null).withTitle(
											'Actions').notSortable().withClass(
											'datatable-action-width')
											.renderWith(actionsHtml) ]
						});

		$scope.search = 0;
		function createdRow(row, data, dataIndex) {
			$compile(angular.element(row).contents())($scope);
		}

		function actionsHtml(data, type, full, meta) {
			$scope.acteurs[data.id] = data;

			return '<div class="action-center" style="margin-top:0px">'
					+ '<div has-permission="SUPPRIMER_ACTEUR" class="col-md-1"><a ui-sref="affectation-role-delete({id:'
					+ data.id
					+ '})">'
					+ '   <i class="glyphicon glyphicon-trash" style="font-size:16px;color:#F31414"></i> '
					+ '</a></div>'
		}

	}
})();
