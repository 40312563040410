(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'role-management',
						{
							parent : 'admin',
							url : '/role-management',
							data : {
								authorities : []
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/role-management/role-management.html',
									controller : 'RoleManagementController'
								}
							},
							resolve : {
								authorities : [ '$http', function($http) {

									return $http.get("api/authorities");
								} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('role.management');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'role-management.new',
						{
							parent : 'role-management',
							url : '/new',

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/role-management/role-management-dialog.html',
													controller : 'RoleDialogController',
													size : 'lg',
													backdrop : 'static',
													resolve : {
														entity : function() {
															return {
																name : null,
																privileges : null,
																isGestionnaire : false

															};
														},
														translatePartialLoader : [
																'$translate',
																'$translatePartialLoader',
																function(
																		$translate,
																		$translatePartialLoader) {
																	$translatePartialLoader
																			.addPart('role.management');
																	return $translate
																			.refresh();
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('^', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						})

				.state(
						'role-management.edit',
						{
							parent : 'role-management',
							url : '/edit/{name}',

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/role-management/role-management-dialog.html',
													controller : 'RoleDialogController',
													size : 'lg',
													backdrop : 'static',
													resolve : {
														authorities : [
																'$http',
																function($http) {

																	return $http
																			.get("api/roless");
																} ],

														entity : [
																'$http',
																function($http) {
																	return $http
																			.get("api/authority/"
																					+ $stateParams.name)

																} ],
														privileges : [
																'$http',
																function($http) {
																	return $http
																			.get("api/privileges/"
																					+ $stateParams.name);
																} ],
														translatePartialLoader : [
																'$translate',
																'$translatePartialLoader',
																function(
																		$translate,
																		$translatePartialLoader) {
																	$translatePartialLoader
																			.addPart('role.management');
																	return $translate
																			.refresh();
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('^', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						})
				.state(
						'role-management.detailUser',
						{
							parent : 'role-management',
							url : '/detailUserByRole/{name}',

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/admin/role-management/detailUserByRole.html',
													controller : 'DetailUserByRoleController',
													size : 'lg',
													backdrop : 'static',
													resolve: {
														entity : [
																'$http',
																function($http) {
																	return $http
																			.get("api/authority/"
																					+ $stateParams.name)

																} ],
														translatePartialLoader : [
																'$translate',
																'$translatePartialLoader',
																function(
																		$translate,
																		$translatePartialLoader) {
																	$translatePartialLoader
																			.addPart('role.management');
																	return $translate
																			.refresh();
																} ]
													}
												}).result.then(
												function(result) {
													$state.go('^', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						})

	}
})();
