(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeAdhesionDialogController', TypeAdhesionDialogController);

	TypeAdhesionDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'TypeAdhesion', 'Cci', '$rootScope'];

	function TypeAdhesionDialogController ($scope, $stateParams, $uibModalInstance, entity, TypeAdhesion, Cci, $rootScope) {

    	$scope.typeAdhesion = {};
    	$scope.cciDTO = $stateParams.cci;
        $scope.ccis = Cci.query();
        $scope.load = function(id) {
            TypeAdhesion.get({id : id}, function(result) {
                $scope.typeAdhesion = result;
            });
        };

        $scope.libelleCci = "";

        if ($scope.cciDTO != null){
    		$scope.typeAdhesion.cciDTO = $scope.cciDTO;
    	}

        if (entity.$promise != null && entity.$promise != undefined){
        	entity.$promise.then( function(result) {

            	$scope.typeAdhesion = result;
            	if ($scope.typeAdhesion.cciDTO != null){
            		$scope.libelleCci = $scope.typeAdhesion.cciDTO.libelle;
            	}
            });
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('infochamApp:typeAdhesionUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.typeAdhesion.id != null) {
                TypeAdhesion.update($scope.typeAdhesion, onSaveSuccess, onSaveError);
                $rootScope.$broadcast('saveTypeAdhesion',{saved:true});
            } else {
                TypeAdhesion.save($scope.typeAdhesion, onSaveSuccess, onSaveError);
                $rootScope.$broadcast('saveTypeAdhesion',{saved:true});
            }
            
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}
})();
