(function() {
	'use strict';
	angular.module('infochamApp').controller('GouvernoratByCciController',
			GouvernoratByCciController);
	GouvernoratByCciController.$inject = [ 'CciWs', 'ReferentielWs',
			'$translate', '$stateParams', '$http', '$scope',
			'$uibModalInstance', 'User', 'DTOptionsBuilder', 'DTColumnBuilder',
			'$timeout' ];

	function GouvernoratByCciController(CciWs, ReferentielWs, $translate,
			$stateParams, $http, $scope, $uibModalInstance, User,
			DTOptionsBuilder, DTColumnBuilder, $timeout) {
		$scope.id = $stateParams.log;
		$scope.codeCci = $stateParams.cci;
		ReferentielWs.getGouvernoratOnlyByCci($scope.codeCci).then(
				function(result) {

					$scope.gouvernorats = result.data;
				});

		CciWs.getCciByCode($scope.codeCci).then(function(result) {

			$scope.chambreCommerce = result.data;
		});
		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.dtOptions = DTOptionsBuilder
				.newOptions()

				.withPaginationType('full_numbers')
				.withOption('order', [ 0, 'asc' ])

				.withDOM(
						"t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource(
						$translate.instant('global.datatable'))
				.withLightColumnFilter({

					'0' : {
						type : 'text'
					}
				});
		$scope.dtColumns = [

				DTColumnBuilder.newColumn(0).withTitle('Gouvernorats')
						.withOption('width', '20%'), ];

	}
})();
