(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeExpediteurDeleteController', OeExpediteurDeleteController);

	OeExpediteurDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'OeExpediteur'];

	function OeExpediteurDeleteController ($scope, $uibModalInstance, entity, OeExpediteur) {

        $scope.oeExpediteur = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            OeExpediteur.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
