(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('OeExpediteurDetailController', OeExpediteurDetailController);

	OeExpediteurDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OeExpediteur', 'Referentiel', 'Adresse', 'Responsable'];

	function OeExpediteurDetailController ($scope, $rootScope, $stateParams, entity, OeExpediteur, Referentiel, Adresse, Responsable) {
        $scope.oeExpediteur = entity;
        $scope.load = function (id) {
            OeExpediteur.get({id: id}, function(result) {
                $scope.oeExpediteur = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:oeExpediteurUpdate', function(event, result) {
            $scope.oeExpediteur = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
