(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TypeReferentielDetailController', TypeReferentielDetailController);

	TypeReferentielDetailController.$inject = ['$scope', '$rootScope',' $stateParams', 'entity', 'TypeReferentiel'];

	function TypeReferentielDetailController ($scope, $rootScope, $stateParams, entity, TypeReferentiel) {
        $scope.typeReferentiel = entity;
        $scope.load = function (id) {
            TypeReferentiel.get({id: id}, function(result) {
                $scope.typeReferentiel = result;
            });
        };
        var unsubscribe = $rootScope.$on('infochamApp:typeReferentielUpdate', function(event, result) {
            $scope.typeReferentiel = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
