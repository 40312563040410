

(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('naturePieceJointe', {
            parent: 'entity',
            url: '/naturePieceJointes',
            data: {
                pageTitle: 'infochamApp.naturePieceJointe.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/naturePieceJointe/naturePieceJointes.html',
                    controller: 'NaturePieceJointeController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('naturePieceJointe');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('naturePieceJointe.detail', {
            parent: 'entity',
            url: '/naturePieceJointe/{id}',
            data: {
                pageTitle: 'infochamApp.naturePieceJointe.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/naturePieceJointe/naturePieceJointe-detail.html',
                    controller: 'NaturePieceJointeDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('naturePieceJointe');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'NaturePieceJointe', function($stateParams, NaturePieceJointe) {
                    return NaturePieceJointe.get({id : $stateParams.id});
                }]
            }
        })
        .state('naturePieceJointe.new', {
            parent: 'naturePieceJointe',
            url: '/new',
            data: {
            },
            params : {
            	typeProcess : null,
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/naturePieceJointe/naturePieceJointe-dialog.html',
                    controller: 'NaturePieceJointeDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('naturePieceJointe', null);
                }, function() {
                    $state.go('naturePieceJointe');
                })
            }]
        })
        .state('naturePieceJointe.edit', {
            parent: 'naturePieceJointe',
            url: '/{id}/edit',
            data: {
            },
            params : {
            	typeProcess : null,
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/naturePieceJointe/naturePieceJointe-dialog.html',
                    controller: 'NaturePieceJointeDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['NaturePieceJointe', function(NaturePieceJointe) {
                            return NaturePieceJointe.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('naturePieceJointe', null);
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('naturePieceJointe.delete', {
            parent: 'naturePieceJointe',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/naturePieceJointe/naturePieceJointe-delete-dialog.html',
                    controller: 'NaturePieceJointeDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['NaturePieceJointe', function(NaturePieceJointe) {
                            return NaturePieceJointe.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('naturePieceJointe', null);
                }, function() {
                    $state.go('^');
                })
            }]
        }).state('naturePieceJointe.typeProcessForPiece', {
        	parent: 'naturePieceJointe',
        	url: '/typeProcessForPiece',
        	data: {
        		authorities: [],
        	}  ,
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/naturePieceJointe/typeProcess.html',
        			controller: 'TypeProcessController',
        			size: 'lg',
        			resolve: {
        				entity: function () {
        					return {
        						code: null,
        						description: null,
        					};
        				}
        			}
        		}).result.then(function(result) {
        			$state.go('naturePieceJointe', null, { reload: true });
        		}, function() {
        			$state.go('naturePieceJointe');
        		})
        	}]
        });
}
})();

