(function() {
    'use strict';

    angular
        .module('infochamApp')
        .directive('uploaddirectivedeux', uploaddirectivedeux)

    uploaddirectivedeux.$inject = ['$rootScope'];
    function uploaddirectivedeux($rootScope) {
        return {
			restrict: "E",
			scope: {
				target: '=',
				code:'=code',
				codeprocess:'=',
				piecejointedtos:'=',
				codeetape:'=codeetape',
				showmessageblock:'=showmessageblock',
				indexpiece:'=indexpiece',
				
			},
			templateUrl: 'app/directives/menu/uploadDirectiveDeux.html',
			controller:['PieceJointe', 'CustomNaturePieceJointe','Inscription2', 'blockUI', '$timeout','fileReader','fileUpload' ,'FileUploader' ,'DTColumnDefBuilder' ,'$stateParams','$filter','$resource','$scope', '$state', 'ParseLinks',  '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder','$http', 'Principal', function (PieceJointe, CustomNaturePieceJointe,Inscription2, blockUI, $timeout,fileReader ,fileUpload ,FileUploader ,DTColumnDefBuilder ,$stateParams,$filter,$resource,$scope, $state, ParseLinks,  $rootScope, $compile, $translate, DTOptionsBuilder, DTColumnBuilder,$http, Principal){
				$scope.myFiles = [];
				if ($scope.codeetape != undefined || $scope.codeetape != null) {
					CustomNaturePieceJointe.getPieceJointeByCodeProcessAndEtape($scope.code,$scope.codeprocess,$scope.codeetape).then(function (result){
						$scope.piecestest = result.data;
					});
					
				} else {
					CustomNaturePieceJointe.getPieceJointeByCodeProcessWithNoStep($scope.code,$scope.codeprocess).then(function (result){
						$scope.piecestest = result.data;
						
						
						if ($scope.piecejointedtos != undefined && $scope.piecejointedtos !=  null) {
							
						for (var i = 0; i < $scope.piecestest.length; i++) {
							var single_object = $filter('filter')($scope.piecejointedtos, function (d) {return d.typeNatureDTO.id == $scope.piecestest[i].id;})
							if ( single_object.length != 0) {
								var file = {};
								file.name = single_object[0].nom;
								$scope.myFiles[i] = (file);
							} else {
							}
						}
						}
						
					});
				}
				
				if ($scope.piecejointedtos != undefined && $scope.piecejointedtos !=  null) {
				}
				/**file uploader**/
				var uploader = $scope.uploader = new FileUploader({  url: '' });
				
				
				
				
				$scope.fileList =[];
				$scope.fileNature = {};
				$scope.showUploader= function(){
					if(uploader.queue.length ==0 || $scope.myFile == undefined){
						return "hidden";
					}else {
						return "";
					}
				}
				$scope.showUploaderAlert= function(){
					if(uploader.queue.length >1 && $scope.myFile != undefined){
						return "";
					}else {
						return "hidden";
					}
				}
				$scope.fileAlert= function(){
					if(!($scope.showUploaderAlert()=="hidden")){
						return true;
					}else{
						return false;
					}
				}
				// FILTERS
				// a sync filter
				uploader.filters.push({
					name: 'syncFilter',
					fn: function(item /*{File|FileLikeObject}*/, options) {
						return this.queue.length < 10;
					}
				});
				// an async filter
				uploader.filters.push({
					name: 'asyncFilter',
					fn: function(item /*{File|FileLikeObject}*/, options, deferred) {
						setTimeout(deferred.resolve, 1e3);
					}
				});
				// CALLBACKS
				//test de l'uploader
				uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
//					console.info('onWhenAddingFileFailed', item, filter, options);
				};
				$scope.test = function(i,file,id) {
				}
				
				function getBase64(file,i) {
					   var reader = new FileReader();
					   reader.readAsDataURL(file);
					   reader.onload = function () {
					     
					     $scope.ngModel = {};						
							$scope.ngModel.fileEncoded64 = reader.result;
							$scope.myFilesResultEncoded[i] = $scope.ngModel;
					     
					   };
					   reader.onerror = function (error) {
					   };
					}
				uploader.onAfterAddingFile = function(fileItem) {		
					blockUI.start();
					
					var files = $scope.myFiles;
					for(var i = 0; i<$scope.uploader.queue.length; i++) {
						var itm = $scope.uploader.queue[i];
					}
					$scope.myFilesResult = [];
					$scope.myFilesResultEncoded = [];
					for (var j = 0; j < $scope.myFiles.length; j++) {
						if ($scope.myFiles[j] != undefined && $scope.myFiles[j] != null) {
							
							$scope.myFilesResult[j] = {};
							
							if ($scope.piecejointedtos != undefined && $scope.piecejointedtos !=  null) {
							}else {
								
							}
							
							$scope.myFilesResult[j].fileNameOrg = $scope.myFiles[j].name;
							$scope.myFilesResult[j].typeNatureDTO = $scope.piecestest[j];
							$scope.myFilesResult[j].indexPiece = $scope.indexpiece;
						} else {
							$scope.myFilesResult[j] = {};
							$scope.myFilesResult[j].typeNatureDTO = $scope.piecestest[j];
							$scope.myFilesResult[j].indexPiece = $scope.indexpiece;
						}
					}
					for (var j = 0; j < $scope.myFiles.length; j++) {
						if ($scope.myFiles[j] != undefined && $scope.myFiles[j] != null && $scope.myFiles[j].size != undefined) {
							getBase64($scope.myFiles[j],j);
						}
					}					
					$timeout( function(){
						for (var a = 0; a < $scope.myFilesResult.length; a++) {
							if ($scope.myFilesResultEncoded[a] != undefined && $scope.myFilesResultEncoded[a] != null &&
									$scope.myFilesResultEncoded[a].fileEncoded64 != undefined && $scope.myFilesResultEncoded[a].fileEncoded64 != null) {
								$scope.myFilesResult[a].fileEncoded64 = $scope.myFilesResultEncoded[a].fileEncoded64;
							}
						}
					}, 1000 );
					
					var fileName= fileItem.file != undefined ? fileItem.file.name : "";
					if( (fileName.indexOf("txt") == -1 && fileName.indexOf("TXT") == -1 )  &&
							 (fileName.indexOf("doc") == -1 && fileName.indexOf("DOC")) == -1  &&
							 (fileName.indexOf("docx") == -1 && fileName.indexOf("DOCX")) == -1  &&
							 (fileName.indexOf("pdf") == -1 && fileName.indexOf("PDF")) == -1  && 
							 (fileName.indexOf("jpg") == -1 && fileName.indexOf("JPG") == -1 )  &&
							 (fileName.indexOf("jpeg") == -1 && fileName.indexOf("JPEG")) == -1  &&
							 (fileName.indexOf("bmp") == -1 && fileName.indexOf("BMP")) == -1  &&
							 (fileName.indexOf("gif") == -1 && fileName.indexOf("GIF")) == -1  &&
							 (fileName.indexOf("png") == -1 && fileName.indexOf("PNG")) == -1){
						swal("Opération interrompue!", "Le fichier doit être de type jpg, jpeg, bmp, gif, png !", "error"); 
						
						for (var t = 0; t < $scope.myFiles.length; t++) {
							if ($scope.myFiles[t] != null &&  (fileItem.file.name == $scope.myFiles[t].name)) {
								$scope.myFiles[t] = null;
								$scope.myFilesResult.splice(t, 1);
							}
						}
						
						
					}
					$scope.$emit('eventEmitedPieceDeux',{message:$scope.myFilesResult,pieceValide:$scope.verifierPiece(),indexPiece:$scope.indexpiece});
					 blockUI.stop();
				};
				$scope.remove = function(index) {
					swal({
						title: "Confirmation",
						text: "Cette opération est irréversible. Etes-vous sûr de continuer ?",
						type: "warning",
						showCancelButton: true,
						cancelButtonText: "NON",
						cancelButtonColor: '#d33',
						confirmButtonColor: "#337ab7",
						confirmButtonText: "OUI",
						closeOnConfirm: true
					},function () {
						$scope.myFiles[index] = null;
						$scope.myFilesResult = [];
						if ($scope.piecejointedtos != undefined) {
							var single = $filter('filter')($scope.piecejointedtos, function (d) {return d.typeNatureDTO.id == $scope.piecestest[index].id;})[0];
							if (single != undefined  && single != null) {
								PieceJointe.delete({id: single.id});
							}
						}
						  $scope.myFilesResult.splice(index, 1);     
						$scope.$emit('eventEmitedPieceDeux',{message:$scope.myFilesResult,pieceValide:$scope.verifierPiece()});
			            
					});
				}
				
				$scope.verifierPiece = function() {
					var pieceValide = false;
					var nbPieceValide = 0;
					var nbPieceValide1 = 0;
					for (var r = 0; r < $scope.piecestest.length; r++) {
						if ($scope.piecestest[r].obligatoire  ) {
							nbPieceValide1 ++;
						}
					}
					for (var r = 0; r < $scope.piecestest.length; r++) {
						for (var q = 0; q < $scope.myFilesResult.length; q++) {
							
							if ($scope.piecestest[r].obligatoire  ) {
								if ($scope.myFilesResult[q].fileNameOrg != undefined && $scope.myFilesResult[q].typeNatureDTO.id == $scope.piecestest[r].id) {
									nbPieceValide ++;
								}	
							} 
						}
					
						
					}
					
					if (nbPieceValide  == nbPieceValide1 ) {
						pieceValide = true;
					}
					
					return pieceValide;
				}
			
				$scope.openPDF = function(i){
					var downloadLink = document.createElement("a");
					var file = new Blob([ $scope.myFiles[i] ], {	type : 'application/octet-stream'	});
//					var fileURL = URL.createObjectURL(file);
					var fileURL = (window.URL || window.webkitURL).createObjectURL(file);
					downloadLink.href = fileURL;
					downloadLink.download = $scope.myFiles[i].name;
					downloadLink.click();
				}
				$scope.openPDFFromAPI = function(idType,index){
					var single_object = $filter('filter')($scope.piecejointedtos, function (d) {return d.typeNatureDTO.id == idType;})
					if ( single_object != undefined &&  single_object.length != 0) {
						var downloadLink = document.createElement("a");
						document.body.appendChild(downloadLink);
						downloadLink.style = "display: none";
						Inscription2
						.downloadDocument(single_object[0].id)
						.then(
								function(result) {
									if(angular.equals( { status: result.status }, { status: 500 } )){
										swal(message,"Un problème s'est produit!", "error")
									}
									var fName = result
									.headers('Content-Disposition');
									var file = new Blob(
											[ result.data ],
											{
												type : 'application/pdf'
											});
									var fileURL = (window.URL || window.webkitURL)
									.createObjectURL(file);
									downloadLink.href = fileURL;
									downloadLink.download = fName;
									downloadLink.click();
								});
					}  else {
						
						var downloadLink = document.createElement("a");
						var file = new Blob([ $scope.myFiles[index] ], {	type : 'application/octet-stream'	});
//						var fileURL = URL.createObjectURL(file);
						var fileURL = (window.URL || window.webkitURL).createObjectURL(file);
						downloadLink.href = fileURL;
						downloadLink.download = $scope.myFiles[index].name;
						downloadLink.click();
					}
				
					
					
					
				
					
					
				}
				
				uploader.onAfterAddingAll = function(addedFileItems) {
//					console.info('onAfterAddingAll', addedFileItems);
				};
				uploader.onBeforeUploadItem = function(item) {
//					console.info('onBeforeUploadItem', item);
				};
				uploader.onProgressItem = function(fileItem, progress) {
//					console.info('onProgressItem', fileItem, progress);
				};
				uploader.onProgressAll = function(progress) {
//					console.info('onProgressAll', progress);
				};
				uploader.onSuccessItem = function(fileItem, response, status, headers) {
//					console.info('onSuccessItem', fileItem, response, status, headers);
				};
				uploader.onErrorItem = function(fileItem, response, status, headers) {
//					console.info('onErrorItem', fileItem, response, status, headers);
				};
				uploader.onCancelItem = function(fileItem, response, status, headers) {
//					console.info('onCancelItem', fileItem, response, status, headers);
				};
				uploader.onCompleteItem = function(fileItem, response, status, headers) {
//					console.info('onCompleteItem', fileItem, response, status, headers);
				};
				uploader.onCompleteAll = function() {
//					console.info('onCompleteAll');
				};
				$scope.fileExist=false;
				$('.file').click(function(e) { // détecter l'evenemnt du click de l'ajout du fichier
					$scope.fileExist=true;
					$rootScope.$emit('reload');
				});
				//verifier le type du fichier excel xls ou xlsx
				function fileExtension (fileName){
					if(fileName.indexOf("pdf") !== -1){
						return "pdf";
					}
					if(fileName.indexOf("jpeg") !== -1){
						return "jpeg";
					}
					if(fileName.indexOf("png") !== -1){
						return "png";
					}
				}
				$scope.fileList=[];
				$scope.showUploder=true;
				$scope.uploadFile = function(item,codeNature){
					var file = $scope.myFile;
					$scope.fileNature.file = $scope.myFile;
					$scope.fileNature.type = codeNature;
					var fileName= file != undefined ? file.name : "";
					$scope.$emit('eventEmitedName',{message:$scope.fileNature});
				};
			}]
		};
    }

    
})();

