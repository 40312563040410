(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('DocumentPrixDeleteController', DocumentPrixDeleteController);

	DocumentPrixDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'DocumentPrix'];

	function DocumentPrixDeleteController ($scope, $uibModalInstance, entity, DocumentPrix) {

        $scope.documentPrix = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
        	DocumentPrix.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
