(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('DepotDossierTechniquePaiementDetailController', DepotDossierTechniquePaiementDetailController);

	DepotDossierTechniquePaiementDetailController.$inject = ['ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function DepotDossierTechniquePaiementDetailController (ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
	$scope.codeStatut=$stateParams.codeStatut;
			$scope.idAgenda = $stateParams.id;
			$scope.demcompdepotfichetech="DEMCOMPDEPOTFICHETECH"
			$scope.deminfocompconfdoc="DEMINFOCOMPCONFDOC"
			if($scope.idAgenda == null){
				$state.go('home', null, { reload: true } );
			}
			$scope.fileList = "DEPDOSSTECH";
			var vm = this;
			$scope.ctrl = this;
			$scope.date=  $filter('date')(new Date(), 'dd/MM/yyyy');
			$scope.depotDoss= {};
			$scope.totalMPE =0;
			$scope.totalMPL = 0;
			Agenda.getDepotDossTechniqueById($scope.idAgenda).then(function (result) {
$scope.agenda = result.data.agenda;
	 $scope.expediteur = $scope.agenda.oeExpediteur;
Agenda.findAdhesionByExpediteur($scope.expediteur.id).then(function(result){
			$scope.adhesion = result.data 
		});
$scope.depot = result.data.depot;
$scope.produit = result.data.produit;
$scope.matierePremiereEtrangereList=result.data.matierePremiereEtrangereList;
$scope.matierePremiereLocaleList=result.data.matierePremiereLocaleList;
$scope.listePieceNumerise= result.data.listePieceNumerise;
if($scope.produit.ngpNdp != null){
$scope.produit.ngpNdp = parseFloat($scope.produit.ngpNdp)
}
if($scope.depot.modeDepot != null){
$scope.modeDepot = $scope.depot.modeDepot.typeDepot.codeRubrique;
$scope.dateCommunication  = new Date($scope.depot.modeDepot.dateCommunication);
}
$http.get("api/listBouton/"+$scope.agenda.idCase).then(function(result) {
  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
  	$scope.listButton=result.data;
  	$scope.listButton1 = {};
  	$scope.listButton1["ATTINFCOMPPAIDOSSTECH"]="Demander des informations complémentaires";
  	$scope.listButton1["DELIVDOSSTECH"]="Confirmer le paiement";			  	// ajouter le ou les  boutons dans la balise boutonWK
 	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
  	var html=' <span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span>';
 
  	// l'id #boutonWK est ajouté au niveau html
  	angular.element("#boutonWK").append( $compile(html)($scope));
  })			
  Certificat.getFactureByProcess($scope.idAgenda).then(function (result) {
		 $scope.facture = result.data
		 
		 if($scope.facture.modePaiement=="Chèque"){
			 Certificat.getPaiementChequeByFacture($scope.facture.id).then(function (result) {
				 $scope.paiement = result.data
			   });
			 }
			 else if($scope.facture.modePaiement=="Mobile"){
				 Certificat.getPaiementMobileByFacture($scope.facture.id).then(function (result) {
					 $scope.paiement = result.data
				   });
				 }
			 else if($scope.facture.modePaiement=="Virement"){
				 Certificat.getPaiementVirementByFacture($scope.facture.id).then(function (result) {
					 $scope.paiement = result.data
				   });
			 }else if($scope.facture.modePaiement=="Espèce"){
					Certificat.getPaiementEspeceByFacture($scope.facture.id).then(function (result) {
						$scope.paiement = result.data
					});
				}

	   });
			});

		
		$scope.verifMobile = function(numPaiement){
			  
			   Inscription2.verifPaiementMobile(numPaiement).then(function(result1){
				   if(result1.data!=null && result1.data != ""){
					   $scope.verifPaiementMobile=true;
				   }
				   else {
					   $scope.verifPaiementMobile=false;
				   }
			   })
		};
		$scope.verifEspece = function(numPaiementEspece){
			   Inscription2.verifPaiementEspece(numPaiementEspece).then(function(result1){
				   if(result1.data!=null && result1.data != ""){
					   $scope.verifPaiementEspece=true;
				   }
				   else {
					   $scope.verifPaiementEspece=false;
				   }
			   })
		};
		$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
		// the LOV
		// datasource
		$scope.lovTitleBureau = 'Liste des bureaux';
		$scope.lovColumnListBureau = [ 'Libellé' ];
		$scope.lovFieldListBureau = [  'libelle' ];
		
		// function to be called when you click "choose" button
		$scope.lovCallBackBureau = function(result) {
			$scope.paiementEspece.bureau = result;
			
		}
		
		Principal.identity().then(function(account) {
			

            
				
			
		});
		
		 Certificat.getMontantByCode("DOSSIER_TECHNIQUE").then(function (result) {
			   $scope.montant=result.data;
		   });
		$scope.styleSwalConfirmation = {
				title: "Confirmation",
				text: "Voulez vous vraiment continuer ?",
				type: "warning",
				showCancelButton: true,
				cancelButtonText: "NON",
				confirmButtonColor: "#337ab7",
				confirmButtonText: "Oui",
				closeOnConfirm: true
		}

			
		
		
		
				
		  $scope.traitement = function(keyBouton,value){
			  if(keyBouton == "ATTINFCOMPPAIDOSSTECH"){
				  $state.go('note-demandeDossierTechniquePaiement', {id:$scope.idAgenda, idProcess:$scope.facture.process.idCase,key:keyBouton,value:value}, { reload: true } );
			  }
			  
			else{

				Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
		 			$state.go('home', null, { reload: true } );
		 });
			}
			}
				function createdRow(row, data, dataIndex) {
					// Recompiling so we can bind Angular directive to the DT
					$compile(angular.element(row).contents())($scope);
				}
				/*get file dec tresor from alfreco*/
				$scope.downloadDecharge = function(docId) {
				var downloadLink = document.createElement("a");
				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2
				.downloadDocument(docId)
				.then(
				function(result) {
				   	if(angular.equals( { status: result.status }, { status: 500 } )){
				swal(message,"Un problème s'est produit!", "error")
				}
				var fName = result
				.headers('Content-Disposition');
				var file = new Blob(
				[ result.data ],
				{
				type : 'application/pdf'
				});
				var fileURL = (window.URL || window.webkitURL)
				.createObjectURL(file);
				downloadLink.href = fileURL;
				downloadLink.download = fName;
				downloadLink.click();
				});
				}
				  $scope.lovModel1 = 'api/findAllBanque';// this will be
			  		// the LOV
			  		// datasource
			  		$scope.lovTitle1 = 'Liste des banques';
			  		$scope.lovColumnList1 = [  'Dénomination' ];
			  		$scope.lovFieldList1 = [  'denomination' ];
			  		// function to be called when you click "choose" button
			  		$scope.lovCallBack1 = function(result) {
			  			$scope.paiementVirement.banque = result;
			  		}
				  
				  $scope.lovModel2 = 'api/findAllBanque';// this will be
			  		// the LOV
			  		// datasource
			  		$scope.lovTitle2 = 'Liste des banques';
			  		$scope.lovColumnList2 = [  'Dénomination' ];
			  		$scope.lovFieldList2 = [  'denomination' ];
			  		// function to be called when you click "choose" button
			  		$scope.lovCallBack2 = function(result) {
			  			$scope.paiementCheque.banque = result;
			  		}
			  		
			  		 $scope.$on('eventEmitedPaiement', function(event, data) {
			  			  $scope.docPaiement = data.message;
			  		  });
			  		 
			  		  $scope.noteHonoraire = function(){
						  ReportingService.downloadNoteHonoraire($scope.idAgenda, $scope.certificatOrigine.numeroCo).then(function (result) {
							  var fileName="Note honoraire"
							  ReportingService.downloadPdfFile(result, fileName)
					 });
						}
			  		  
			  
}
})();
