(function () {
    'use strict';

    angular
        .module('infochamApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('demandeAdhesion', {
            parent: 'entity',
            url: '/demandeAdhesions',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.demandeAdhesion.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeAdhesion/demandeAdhesions.html',
                    controller: 'DemandeAdhesionController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('demandeAdhesion');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('demandeAdhesion.detail', {
            parent: 'entity',
            url: '/demandeAdhesion/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'infochamApp.demandeAdhesion.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeAdhesion/demandeAdhesion-detail.html',
                    controller: 'DemandeAdhesionDetailController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('demandeAdhesion');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'DemandeAdhesion', function($stateParams, DemandeAdhesion) {
                    return DemandeAdhesion.get({id : $stateParams.id});
                }]
            }
        })
        .state('demandeAdhesion.new', {
            parent: 'demandeAdhesion',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/demandeAdhesion/demandeAdhesion-dialog.html',
                    controller: 'DemandeAdhesionDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                dateAdhesion: null,
                                chiffreAffaire: null,
                                nbreEffectif: null,
                                capitalSocial: null,
                                isSpecimen: null,
                                montantPayer: null,
                                montantSpecimen: null,
                                commentaire: null,
                                numCarteAdhesion: null,
                                dateCre: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {
                    $state.go('demandeAdhesion', null, { reload: true });
                }, function() {
                    $state.go('demandeAdhesion');
                })
            }]
        })
        .state('demandeAdhesion.edit', {
            parent: 'demandeAdhesion',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/demandeAdhesion/demandeAdhesion-dialog.html',
                    controller: 'DemandeAdhesionDialogController',
                    size: 'lg',
                    resolve: {
                        entity: ['DemandeAdhesion', function(DemandeAdhesion) {
                            return DemandeAdhesion.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('demandeAdhesion', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        })
        .state('demandeAdhesion.delete', {
            parent: 'demandeAdhesion',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/demandeAdhesion/demandeAdhesion-delete-dialog.html',
                    controller: 'DemandeAdhesionDeleteController',
                    size: 'md',
                    resolve: {
                        entity: ['DemandeAdhesion', function(DemandeAdhesion) {
                            return DemandeAdhesion.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('demandeAdhesion', null, { reload: true });
                }, function() {
                    $state.go('^');
                })
            }]
        }).state('consultAllDA', {
            parent: 'home',
            url: 'consult-all-DA',
            data: {
          	  authorities: ['ROLE_ADMIN','ROLE_ADHESION','ROLE_EMPLOYEE','ROLE_RESPONSABLE_PAIEMENT'],		
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeAdhesion/rechercheDA/consult-all-DA.html',
                    controller: 'ConsultAllDAController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('consultAllDAOE', {
            parent: 'home',
            url: 'consult-all-DA-OE',
            data: {
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeAdhesion/rechercheDA/consult-all-DA-OE.html',
                    controller: 'ConsultAllDAOEController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('consultAllDARA', {
            parent: 'home',
            url: 'consult-all-DA-RA',
            data: {
                pageTitle: 'infochamApp.agenda.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/demandeAdhesion/rechercheDA/consult-all-DA-RA.html',
                    controller: 'ConsultAllDARAController',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('agenda');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    
    
}
})();

