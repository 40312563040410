(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('MatierePremiereDetailController',MatierePremiereDetailController);
MatierePremiereDetailController.$inject=['$scope', '$rootScope', '$stateParams', 'entity','MatierePremiere', 'Depot', 'Produit', 'User', 'Facture', 'PieceJointe'];
function MatierePremiereDetailController($scope, $rootScope, $stateParams, entity, MatierePremiere, Depot, Produit, User, Facture, PieceJointe){
	 $scope.matierePremiere = entity;
     $scope.load = function (id) {
         MatierePremiere.get({id: id}, function(result) {
             $scope.matierePremiere = result;
         });
     };
     var unsubscribe = $rootScope.$on('infochamApp:matierePremiereUpdate', function(event, result) {
         $scope.matierePremiere = result;
     });
     $scope.$on('$destroy', unsubscribe);
}

})();