(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('DocumentPrixDialogController', DocumentPrixDialogController);

	DocumentPrixDialogController.$inject = ['$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DocumentPrix'];

	function DocumentPrixDialogController ($translate, $scope, $stateParams, $uibModalInstance, entity,DocumentPrix) {
		$scope.documentPrix = entity;
		$scope.load = function(id) {
			DocumentPrix.get({
				id : id
			}, function(result) {
				$scope.documentPrix = result;
			});
		};
		var onSaveSuccess = function(result) {
			$scope.$emit('infochamApp:referentielUpdate', result);
			$uibModalInstance.close(result);
			$scope.isSaving = false;
		};
		$scope.typeCod = {}
		var onSaveError = function(result) {
			$scope.isSaving = false;
			if (result.status == 500) {
				swal("Opération interrompue!", "Déja existant", "error");
			} else {
				swal("Opération interrompue!", "", "error");
			}
		};
		$scope.save = function() {
			$scope.isSaving = true;

			if ($scope.documentPrix.id != null) {
				DocumentPrix.update($scope.documentPrix, onSaveSuccess, onSaveError);
			} else {
				DocumentPrix.save($scope.documentPrix, onSaveSuccess, onSaveError);
			}
		};
		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		
	}
})();
