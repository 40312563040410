(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('SpecimenSignatureDialogController',SpecimenSignatureDialogController);
SpecimenSignatureDialogController.$inject=['$scope', '$stateParams', '$uibModalInstance', 'entity', 'SpecimenSignature', 'PieceJointe', 'DemandeAdhesion'];
function SpecimenSignatureDialogController($scope, $stateParams, $uibModalInstance, entity, SpecimenSignature, PieceJointe, DemandeAdhesion){
	$scope.specimenSignature = entity;
    $scope.piecejointes = PieceJointe.query();
    $scope.demandeadhesions = DemandeAdhesion.query();
    $scope.load = function(id) {
        SpecimenSignature.get({id : id}, function(result) {
            $scope.specimenSignature = result;
        });
    };

    var onSaveSuccess = function (result) {
        $scope.$emit('infochamApp:specimenSignatureUpdate', result);
        $uibModalInstance.close(result);
        $scope.isSaving = false;
    };

    var onSaveError = function (result) {
        $scope.isSaving = false;
    };

    $scope.save = function () {
        $scope.isSaving = true;
        if ($scope.specimenSignature.id != null) {
            SpecimenSignature.update($scope.specimenSignature, onSaveSuccess, onSaveError);
        } else {
            SpecimenSignature.save($scope.specimenSignature, onSaveSuccess, onSaveError);
        }
    };

    $scope.clear = function() {
        $uibModalInstance.dismiss('cancel');
    };
}

})();
