(function() {
	'use strict';
	angular
	.module('lov-directive')
	.directive('addnote', addnote)
	.filter('StartOnPage', StartOnPage)
	.controller('AffecterNoteDialogController', AffecterNoteDialogController);

	
	addnote.$inject = ['$uibModal'];
	AffecterNoteDialogController.$inject = ['codestatut','codeprocess','$state','Inscription2','DTColumnBuilder', '$translate', 'DTOptionsBuilder', '$resource', '$scope', '$log', '$rootScope', '$uibModalInstance','idagenda', '$filter' , 'FileUploader', 'blockUI'];

	function addnote ($uibModal) {
		return {
			restrict : 'A',
			scope : {
				specimen:'@',
				index: '@',
				idagenda: '@',
				codeprocess: '@',
				codestatut: '@',
			},
			template: '  <a class="btn btn-info" ng-click="openPopUp(idagenda,codeprocess,codestatut)"><span class="glyphicon glyphicon-plus"></span> <spanclass="hidden-xs forme-button"> Ajouter Note</span></a>',

			controller : [ '$scope', '$element', function($scope, $element) {

				$scope.openPopUp = function(idagenda,codeprocess,codestatut) {

					$scope.localPopUp = {
							backdrop: 'static',
							size: 'lg',

							templateUrl : 'app/directives/menu/addNote.html',
							controller : 'AffecterNoteDialogController',
							resolve: {
							
								idagenda: function () {
									return idagenda;
								},	codeprocess: function () {
									return codeprocess;
								},	codestatut: function () {
									return codestatut;
								},
								translatePartialLoader : ['$translate','$translatePartialLoader',
									function($translate, $translatePartialLoader) {
									return $translate.refresh();
								} ]
							}

					};
					var modalInstance = $uibModal.open($scope.localPopUp);
				}
			} ]
		}	
}
	
function StartOnPage () {
		return function(input, start) {
		start = +start;
		return input.slice(start);
	}
	}
	
	function AffecterNoteDialogController (codestatut,codeprocess,$state,Inscription2,DTColumnBuilder, $translate, DTOptionsBuilder, $resource, $scope, $log, $rootScope, $uibModalInstance,idagenda, $filter , FileUploader, blockUI) {  $scope.save=   function save (note) {
    	note.etapeProcess=codeprocess;
       	 Inscription2.addNote( idagenda,note).then(function (result) {
       		$state.go($state.current.name, {id:idagenda,codeStatut:codestatut}, { reload: true } );
       		setTimeout(function() {
       	 $uibModalInstance.dismiss('cancel');
     });
     	},1000);
    	}
$scope.clear=     function clear () {
       $uibModalInstance.dismiss('cancel');
   }}
})();

