(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('CertificatPaiementController', CertificatPaiementController);

	CertificatPaiementController.$inject = ['ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function CertificatPaiementController (ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.idAgenda = $stateParams.id;
		$scope.paiementVirement = {};
		$scope.paiementCheque = {};
		$scope.numTmoney=0;
		$scope.numFlouss=0 ;
		$scope.RIB=0;
		$scope.verifPaiementMobile=false;
		$scope.verifPaiementEspece=false;
		$scope.paiementEspece={};
		Principal.identity().then(function(account) {
			$scope.account = account;
			$scope.isAuthenticated = Principal.isAuthenticated;
			if ($scope.account == null) {
				$state.go('login');
			}
		});
		$scope.verifMobile = function(numPaiement){
			  
			   Inscription2.verifPaiementMobile(numPaiement).then(function(result1){
				   if(result1.data!=null && result1.data != ""){
					   $scope.verifPaiementMobile=true;
				   }
				   else {
					   $scope.verifPaiementMobile=false;
				   }
			   })
		};
		$scope.verifEspece = function(numPaiementEspece){
			   Inscription2.verifPaiementEspece(numPaiementEspece).then(function(result1){
				   if(result1.data!=null && result1.data != ""){
					   $scope.verifPaiementEspece=true;
				   }
				   else {
					   $scope.verifPaiementEspece=false;
				   }
			   })
		};
		$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
		// the LOV
		// datasource
		$scope.lovTitleBureau = 'Liste des bureaux';
		$scope.lovColumnListBureau = [ 'Libellé' ];
		$scope.lovFieldListBureau = [  'libelle' ];
		
		// function to be called when you click "choose" button
		$scope.lovCallBackBureau = function(result) {
			$scope.paiementEspece.bureau = result;
			
		}
			 Certificat.getDemandeByProcess($scope.idAgenda).then(function (result) {
				 $scope.agenda = result.data.agenda;
				$scope.facture = result.data.facture;
				$scope.autresServices = result.data.autresServices
				$scope.detailFacture = result.data.detailFactureList;
				$scope.certificatOrigine = result.data.certificatOrigine ;
				$scope.modeDepot = result.data.modeDepot;
				$scope.gouvCci = result.data.agenda.oeExpediteur.cci.libelle ; 
				 $http.get("api/listBouton/"+$scope.facture.process.idCase).then(function(result) {
					  	// récupérer key(id du bouton = nextStep) and value(libelle du bouton)
					  	$scope.listButton=result.data;
					  	$scope.listButton1 = {};
					  	$scope.listButton1["PAIANNUL"]="Annuler";
					  	$scope.listButton1["CONFPAI"]="Payer";
					  	//parcour : ng-repeat="(key,value) in listButton" pour distinguer entre les ids des boutons lors du clic
					  	var html=' <div ng-show="modePaiement==\'Chèque\'"><span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form3.$invalid || form4.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>'+
					  	         '<div ng-show="modePaiement==\'Virement\'"><span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form3.$invalid || form6.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>'+
//					            	'<div ng-show="modePaiement==\'Mobile\'"><span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form3.$invalid || form5.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>'+
		            	'<div ng-show="modePaiement==\'Espèce\' || modePaiement==null"><span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" ng-disabled="form3.$invalid ||form7.$invalid || isSaving" >{{value}}</button>&nbsp;&nbsp;</span></div>'+
		            	'<div ng-show="modePaiement==\'Crédit\'"><span ng-repeat="(key,value) in listButton1"><button  id={{key}} type="button" ng-click="traitement(key,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece)" class="btn btn-info-2" >{{value}}</button>&nbsp;&nbsp;</span></div>';
					  	// ajouter le ou les  boutons dans la balise boutonWK
					  	// l'id #boutonWK est ajouté au niveau html
					  	angular.element("#boutonWK").append( $compile(html)($scope));
					  })
					  
					  	if($scope.autresServices.nbrecopieCO != undefined && $scope.autresServices.nbrecopieCO != "" && $scope.autresServices.nbrecopieCO != null){
							 $scope.nbrecopieCO= $scope.autresServices.nbrecopieCO;
						} else {
							 $scope.nbrecopieCO="0";
						}
					  	if($scope.autresServices.nbreDmpCo != undefined && $scope.autresServices.nbreDmpCo != "" && $scope.autresServices.nbreDmpCo != null){
							 $scope.nbreDmpCo= $scope.autresServices.nbreDmpCo;
						} else {
							 $scope.nbreDmpCo="0";
						}
						if($scope.autresServices.nbreVisaDoc != undefined && $scope.autresServices.nbreVisaDoc != "" && $scope.autresServices.nbreVisaDoc != null){
							 $scope.nbreVisaDoc= $scope.autresServices.nbreVisaDoc;
						} else {
							 $scope.nbreVisaDoc="0";
						}
						if($scope.autresServices.nbreVisaFacture != undefined && $scope.autresServices.nbreVisaFacture != "" && $scope.autresServices.nbreVisaFacture != null){
							 $scope.nbreVisaFacture= $scope.autresServices.nbreVisaFacture;
						} else {
							 $scope.nbreVisaFacture="0";
						}	
					  Certificat.getMontant($scope.idAgenda,$scope.nbrecopieCO , $scope.nbreDmpCo, $scope.nbreVisaDoc , $scope.nbreVisaFacture).then(function (result) {
						   $scope.montant=result.data;
					   });
			        	 });
				function createdRow(row, data, dataIndex) {
					// Recompiling so we can bind Angular directive to the DT
					$compile(angular.element(row).contents())($scope);
				}
				/*get file dec tresor from alfreco*/
				$scope.downloadDecharge = function(docId) {
				var downloadLink = document.createElement("a");
				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2
				.downloadDocument(docId)
				.then(
				function(result) {
				   	if(angular.equals( { status: result.status }, { status: 500 } )){
				swal(message,"Un problème s'est produit!", "error")
				}
				var fName = result
				.headers('Content-Disposition');
				var file = new Blob(
				[ result.data ],
				{
				type : 'application/pdf'
				});
				var fileURL = (window.URL || window.webkitURL)
				.createObjectURL(file);
				downloadLink.href = fileURL;
				downloadLink.download = fName;
				downloadLink.click();
				});
				}
				  $scope.traitement = function(keyBouton,value,modePaiement,paiementVirement,paiementCheque,paiementMobile,paiementEspece){
					  $scope.errors =null;
					  $scope.authenticationError = false;
					   if(modePaiement ==null || modePaiement  == undefined || modePaiement  == ""){
					  $scope.errors ="Le mode de paiement est requis";
				  }
				   if ((modePaiement !=null && modePaiement != undefined && modePaiement != "")){
					    if(modePaiement=="Virement"){
						     if (paiementVirement !=null && paiementVirement != undefined && paiementVirement != ""){
							   if(paiementVirement.dateVirement  ==null || paiementVirement.dateVirement   == undefined || paiementVirement.dateVirement    == ""){
								   $scope.errors ="La date du virement est requis";
						   }
							   else if(paiementVirement.numVirement  ==null || paiementVirement.numVirement   == undefined || paiementVirement.numVirement    == ""){
								   $scope.errors ="Le numéro du virement est requis";
						   }
							   else if(paiementVirement.agence  ==null || paiementVirement.agence   == undefined || paiementVirement.agence    == ""){
								   $scope.errors ="L'agence du virement est requis";
						   }
						   
					   }
				   }
				   
					   else if(modePaiement=="Chèque"){
						     if (paiementCheque  !=null && paiementCheque  != undefined && paiementCheque  != ""){
							   if(paiementCheque.dateCheque  ==null || paiementCheque.dateCheque   == undefined || paiementCheque.dateCheque  == ""){
								   $scope.errors ="La date du chèque est requis";
						   }
							   else if(paiementCheque.numCheque ==null || paiementCheque.numCheque   == undefined || paiementCheque.numCheque == ""){
								   $scope.errors ="Le numéro du chèque est requis";
						   }
							   else if(paiementCheque.agence ==null || paiementCheque.agence == undefined || paiementCheque.agence == ""){
								   $scope.errors ="L'agence du chèque est requis";
						   }
						   
					   
						   }
						   }
				   }
				   if($scope.errors != null){
						  $scope.authenticationError = true;
						  $("html, body").animate({scrollTop: 300},"slow");
				       }
				   
					  if(keyBouton == "PAIANNUL"){
						  swal({
					            title: "Confirmation",
					            text: "Êtes-vous sûr de vouloir annuler la demande de l'attestation d'origine  ?",
					            type: "warning",
					            showCancelButton: true,
					            confirmButtonColor: "#449d44",
					            confirmButtonText: "Valider",
					            closeOnConfirm: true,
					            cancelButtonText: "Annuler",  
					        },
					        function(){
								  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
					 			$state.go('home', null, { reload: true } );
					 });
					        });
					  }
				  else{
					  if($scope.modePaiement =="Virement"){
						  Certificat.paiementVirement(paiementVirement,$scope.idAgenda,$scope.modePaiement,$scope.montant).then(function (result) {
							  var uploadUrl = "api/uploadFileVirement/"+result.data.id;
								 var file = $scope.docPaiement;
									if(typeof file !='undefined'){
										var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
												function(result) {
													$scope.piece = result.data;
												});
								}
						  });
					  }
					  if($scope.modePaiement =="Chèque"){
						  Certificat.paiementCheque(paiementCheque,$scope.idAgenda,$scope.modePaiement,$scope.montant).then(function (result) {
							  var uploadUrl = "api/uploadFileCheque/"+result.data.id;
								 var file = $scope.docPaiement;
									if(typeof file !='undefined'){
										var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
												function(result) {
													$scope.piece = result.data;
												});
								}
									
						  });
					  }
					  if($scope.modePaiement =="Espèce"){
						
						  Certificat.paiementEspece($scope.paiementEspece,$scope.idAgenda,$scope.modePaiement, $scope.montant).then(function (result) {
						  
						  });
					  }
					  if($scope.modePaiement =="Mobile"){
						  Certificat.paiementMobile(paiementMobile,$scope.idAgenda,$scope.modePaiement,$scope.montant).then(function (result) {
						  });
					  }
					  if($scope.modePaiement =="Crédit"){
						  Certificat.paiementCredit($scope.idAgenda,$scope.modePaiement,$scope.montant).then(function (result) {
						  });
					  }
					  if($scope.authenticationError == false){
					  Inscription2.updateProcInscription($scope.idAgenda,keyBouton,value).then(function (result) {
				 			$state.go('home', null, { reload: true } );
				 });
				  }
				  }
					}
				  $scope.lovModel1 = 'api/findAllBanque';// this will be
			  		// the LOV
			  		// datasource
			  		$scope.lovTitle1 = 'Liste des banques';
			  		$scope.lovColumnList1 = [  'Dénomination' ];
			  		$scope.lovFieldList1 = [  'denomination' ];
			  		// function to be called when you click "choose" button
			  		$scope.lovCallBack1 = function(result) {
			  			$scope.paiementVirement.banque = result;
			  		}
				  
				  $scope.lovModel2 = 'api/findAllBanque';// this will be
			  		// the LOV
			  		// datasource
			  		$scope.lovTitle2 = 'Liste des banques';
			  		$scope.lovColumnList2 = [  'Dénomination' ];
			  		$scope.lovFieldList2 = [  'denomination' ];
			  		// function to be called when you click "choose" button
			  		$scope.lovCallBack2 = function(result) {
			  			$scope.paiementCheque.banque = result;
			  		}
			  		
			  		 $scope.$on('eventEmitedPaiement', function(event, data) {
			  			  $scope.docPaiement = data.message;
			  		  });
			  		 
			  		  $scope.noteHonoraire = function(){
						  ReportingService.downloadNoteHonoraire($scope.idAgenda, $scope.certificatOrigine.numeroCo).then(function (result) {
							  var fileName="Note honoraire"
							  ReportingService.downloadPdfFile(result, fileName)
					 });
						}
			  		  
			  
}
})();
