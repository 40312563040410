(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('MatierePremiereEtrangereUpdatePourcentageController', MatierePremiereEtrangereUpdatePourcentageController);

	MatierePremiereEtrangereUpdatePourcentageController.$inject = ['fileUpload' ,'Referentiel','ReferentielWs','$uibModalInstance','$rootScope','Inscription2' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile',  'AuditsService', '$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function MatierePremiereEtrangereUpdatePourcentageController (fileUpload ,Referentiel,ReferentielWs,$uibModalInstance,$rootScope,Inscription2 ,$stateParams,$translate,$scope, Principal, $state, $http, $compile, AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {

		$scope.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		$scope.matierePremiereEtrangere = $stateParams.matierePremiere;
		$scope.index=$stateParams.index
		$scope.save = function(matierePremiereEtrangere) {
			if($scope.factureAchat != null){
				matierePremiereEtrangere.factureAchat = $scope.factureAchat;
			}
			if($scope.factureAchatAutre != null){
				matierePremiereEtrangere.factureAchatAutre = $scope.factureAchatAutre;
			}
				$rootScope.$broadcast('eventEmitedUpdatePourcentageMatierePremiereEtrangereList',{message:matierePremiereEtrangere,index:$scope.index});
				$uibModalInstance.dismiss('cancel');
		};
		ReferentielWs.getAllProduitLibelle().then(function(result){
			$scope.produits=result.data;
		})
		ReferentielWs.getAllDevise().then(function(result){
			$scope.devises=result.data;
		})
		ReferentielWs.getAllUnite().then(function(result){
			$scope.unites=result.data;
		})
		$scope.tab=0;
		$scope.selectTab = function (res){
			if(res==0){
			}else if(res==1){
			}
			$scope.tab=res;
		}
		$scope.isSelected = function(setTab){
			return setTab==$scope.tab;
		}
		
	    $scope.$on('eventEmitedFactureAchat', function(event, data) {
	        $scope.msgresult =  '\nParentController - receive EVENT "' + event.name + '" with message = "' + data.message + '"';
	        $scope.file = data.message;
	  		var file = $scope.file;
			var fileName= file != undefined ? file.name : "";
				var uploadUrl = "api/uploadPieceJointeInDirectory/Facture Achat";
				if(typeof file !='undefined'){
					var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
							function(result) {
								$scope.factureAchat = result.data;
							});

			}
	    });
	    
	    $scope.$on('eventEmitedNameAutres', function(event, data) {
	        $scope.msgresult =  '\nParentController - receive EVENT "' + event.name + '" with message = "' + data.message + '"';
	        $scope.file = data.message;
	  		var file = $scope.file;
			var fileName= file != undefined ? file.name : "";
				var uploadUrl = "api/uploadPieceJointeInDirectory/Autre";
				if(typeof file !='undefined'){
					var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
							function(result) {
								$scope.factureAchatAutre = result.data;
							});
			}
	    });
		 $scope.removeFactureAchat = function() {
			 $scope.factureAchat = null;
		};
		 $scope.removeFactureAchatAutre = function() {
			 $scope.factureAchatAutre = null;
		};
		$scope.downloadPiece = function(docId) {
			var downloadLink = document.createElement("a");

			document.body.appendChild(downloadLink);
			downloadLink.style = "display: none";
			Inscription2
			.downloadDocumentFromDirectory(docId)
			.then(
			function(result) {
			   	if(angular.equals( { status: result.status }, { status: 500 } )){
			swal(message,"Un problème s'est produit!", "error")
			}
			var fName = result
			.headers('Content-Disposition');
			var file = new Blob(
			[ result.data ],
			{
			type : 'application/pdf'
			});
			var fileURL = (window.URL || window.webkitURL)
			.createObjectURL(file);
			downloadLink.href = fileURL;
			downloadLink.download = fName;
			downloadLink.click();
			});
			}
}
})();
