(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'statistiques_co',
						{
							parent : 'admin',
							url : '/statistiques_co',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'statistiques_co.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/statistiques/statistiques-co.html',
									controller : 'StatistiquesCOController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('Admin');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'statistiques_adhesion',
						{
							parent : 'admin',
							url : '/statistiques_adhesion',
							data : {
								authorities : [ 'ROLE_ADHESION', 'ROLE_ADMIN' ],
								pageTitle : 'statistiques_adhesion.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/statistiques/statistiques-adhesion.html',
									controller : 'StatistiquesAdhesionController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('Admin');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'statistiques_paiement',
						{
							parent : 'admin',
							url : '/statistiques_paiement',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_PAIEMENT',
										'ROLE_ADMIN' ],
								pageTitle : 'statistiques_paiement.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/admin/statistiques/statistiques-paiement.html',
									controller : 'StatistiquesPaiementController'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('Admin');
											return $translate.refresh();
										} ]
							}
						})
	}
})();
