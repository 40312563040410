(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('TraitDemandeAdhesionController', TraitDemandeAdhesionController);

	TraitDemandeAdhesionController.$inject = ['PaiementEnLigneWs','$stateParams','$filter','$resource', '$scope', '$state', 'ParseLinks', '$rootScope', '$compile', '$translate', 'DTOptionsBuilder', 
	                          				'DTColumnBuilder', 'ReportingService', 'CustomProcessBonita', 'Certificat', 'fileUpload' ,'$timeout', '$http','$sce', 'Inscription2', 'CustomTypeAdhesion'];

	function TraitDemandeAdhesionController (PaiementEnLigneWs,$stateParams,$filter,$resource, $scope, $state, ParseLinks, $rootScope, $compile, $translate, DTOptionsBuilder, 
			DTColumnBuilder, ReportingService, CustomProcessBonita, Certificat, fileUpload ,$timeout, $http,$sce, Inscription2, CustomTypeAdhesion) {
			$scope.codePieceOnline = "ADHES";
			$scope.codePieceOffline = "ADHESOFFLINE";
			$scope.codePieceRnvOnline = "RENVADHES";
			$scope.factureDTO = [];

			$scope.changePaiementValue = function(changePaiement) {
				$scope.modePaiement = changePaiement;
			}
			
			var vm = this;
			$scope.ctrl = this;

			$scope.compareTypeProcess = function(code) {
				if (angular.equals( { status:$scope.agendaDTO.typeProcessDTO.codeRubrique}, { status: code})  ) {
					return true;
				}
				return false;
			}

			$scope.customProcess = $stateParams.customProcess ;
			if ($scope.customProcess.demandeAdhesionDTO.modeDepotDTO != undefined && $scope.customProcess.demandeAdhesionDTO.modeDepotDTO != null) {
				$scope.customProcess.demandeAdhesionDTO.modeDepotDTO.dateCommunication = new Date($scope.customProcess.demandeAdhesionDTO.modeDepotDTO.dateCommunication);

			}
			$scope.agendaDTO = $scope.customProcess.agendaDTO;
			$scope.demandeDadhesion = $scope.customProcess.demandeAdhesionDTO;
			$scope.oeExpediteur = $scope.customProcess.oeExpediteurDTO;
			$scope.specimenSignatures = $scope.customProcess.specimenSignatureDTOs;
			$scope.pieceJointeDTOs = $scope.customProcess.pieceJointeDTOs;
			$scope.paiementVirement = {};
			$scope.paiementCheque = {};
			$scope.paiementEspece = {};
			$scope.content = [];
			$scope.attcompdoc1="ATTCOMPDOC1";
			$scope.attcompdoc2="ATTCOMPDOC2";
			$scope.attcompdoc3="ATTCOMPDOC3";
			$scope.idAgenda = $scope.agendaDTO.id;
			
			$http.get("api/referentiels/getByCode/DEPOT").then(function(result) {
				$scope.modeDepots = result.data;
			});
			
			$scope.downloadPieceFROMAPI = function(docId) {
				var downloadLink = document.createElement("a");
				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2.downloadDocument(docId).then(function(result) {
					if(angular.equals( { status: result.status }, { status: 500 } )){
						swal(message,"Un problème s'est produit!", "error")
					}
					var fName = result
					.headers('Content-Disposition');
					var file = new Blob([ result.data ],{type : 'application/pdf'});
					var fileURL = (window.URL || window.webkitURL)
					.createObjectURL(file);
					downloadLink.href = fileURL;
					downloadLink.download = fName;
					downloadLink.click();
				});
			}
			vm.validationStep = function(item, actions) {
				$scope.errors =[];
				if($scope.errors.length> 0){
					actions.error($scope.errors);
					$("html, body").animate({scrollTop: 400},"slow");
				}
				else {
					actions.success();
				}
			}
			
			/***/
			
			
			$scope.dtInstanceNotes = {};
			/*****************************************/
			
			$scope.afficherNote = function(codeEtap) {
				function createdRow(row, data, dataIndex) {
					$compile(angular.element(row).contents())($scope);
				}
			
				$scope.dtOptionsNotes = DTOptionsBuilder.newOptions().withOption('ajax',function(data, fnCallback, settings) {

					$http.get('api/findNoteByProcess/'+$scope.agendaDTO.id+'/' + codeEtap).then(function(result) {
						$scope.records=	result.data.length;	
						fnCallback(result.data);
					});

				})
				.withOption('createdRow', createdRow)
				.withPaginationType('full_numbers')
				.withOption('order', [0, 'asc'])
				.withDOM("t<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
				.withBootstrap().withLanguageSource($translate.instant('global.datatable')).withLightColumnFilter({

					'0' : {
						type : 'text'
					},
					'1' : {
						type : 'text'
					},
					'2' : {
						type : 'text'
					},
					'3' : {
						type : 'text'
					}
				})
				if ($scope.dtInstanceNotes._renderer != undefined) {
					$scope.dtInstanceNotes._renderer.rerender();

				}
				$scope.dtColumnsNotes = [
					DTColumnBuilder.newColumn('idUserCre.login').withTitle('Créateur').withOption('width', '35%'),
					DTColumnBuilder.newColumn('description').withTitle('Description').withOption('width', '35%'),
					DTColumnBuilder.newColumn('dateCre').withTitle('Date').withOption('width', '30%').renderWith(function(data, type) {
				return $filter('date')(data, 'dd/MM/yyyy HH:mm');
				}),
					];
			}
			/********************************************/
			$scope.$on('eventEmitedPaiement', function(event, data) {
				$scope.docPaiement = data.message;
			});
			if($scope.oeExpediteur.categorieJuridiqueDTO.valeur2=='PP'){
				$scope.montant = parseFloat($scope.customProcess.demandeAdhesionDTO.typeAdhesionDTO.montantPp).toFixed(3)
			}else{
				$scope.montant = parseFloat($scope.customProcess.demandeAdhesionDTO.typeAdhesionDTO.montant).toFixed(3)
			}
			if ($scope.customProcess.demandeAdhesionDTO.isSpecimen) {
				$scope.montant +=$scope.customProcess.oeExpediteurDTO.cciDTO.mntSpecimen;

			}

			$scope.lovModelBanque = 'api/findAllBanque';// this will be
			// the LOV
			// datasource
			$scope.lovTitleBanque = 'Liste des banques';
			$scope.lovColumnListBanque = [ 'Dénomination' ];
			$scope.lovFieldListBanque = [  'denomination' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackBanque = function(result) {
				$scope.paiementVirement.banque = result;
				$scope.paiementCheque.banque = result;
			}

			$scope.lovModelBureau = 'api/referentiels/bureaux';// this will be
			// the LOV
			// datasource
			$scope.lovTitleBureau = 'Liste des bureaux';
			$scope.lovColumnListBureau = [ 'Libellé' ];
			$scope.lovFieldListBureau = [  'libelle' ];
			
			// function to be called when you click "choose" button
			$scope.lovCallBackBureau = function(result) {
				$scope.paiementEspece.bureau = result;
				
			}

			
				
			
$scope.verifDeliv = function(bool) {
	$scope.verifDelivVar =  bool;
}
			
			
			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}
			$scope.showSpecimen = true;
			$scope.verifDelivVar = true;
			CustomProcessBonita.getListBoutonWorkflowByIdCaseIdTask($scope.agendaDTO.idCase,$scope.agendaDTO.idTask).then(function(result) {
				var	html = null;
				$scope.listButton=result.data;
				$scope.showDemandeInfo = true;
				$scope.showWorkflowButtonOut = true;
				$scope.infoContact = true;
				$scope.infoOe = true;
				$scope.compInformation = true;
				$scope.piecejustif = true;
				for (var i = 0, keys = Object.keys($scope.listButton), ii = keys.length; i < ii; i++) {
					$scope.key=keys[i];
					$scope.value=$scope.listButton[keys[i]];
						if ($scope.key == "ANNULER2" && ($scope.compareTypeProcess($scope.codePieceOnline) || $scope.compareTypeProcess($scope.codePieceRnvOnline)) ) { //|| $scope.key == "ATTCONFPAYER2"
							$scope.showDemandeInfo = false;

							$scope.confirmationPayement = true;
							//var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2"   >'+$scope.value+'</button>&nbsp;&nbsp;';

						}else if ($scope.key == "ATTCONFPAYER2") { //|| $scope.key == "ATTCONFPAYER2"
							$scope.showDemandeInfo = false;
                              $scope.modePaiement = $scope.demandeDadhesion.factureDTO.modePaiement;
							if($scope.demandeDadhesion.factureDTO.modePaiement=="Chèque"){
								Certificat.getPaiementChequeByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
									$scope.paiementCheque = result.data
									$scope.paiementCheque.dateCheque = new Date($scope.paiementCheque.dateCheque);
									$scope.paiementCheque.numCheque=parseInt($scope.paiementCheque.numCheque);

								});
							}
							else if($scope.demandeDadhesion.factureDTO.modePaiement=="Virement"){
								Certificat.getPaiementVirementByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
									$scope.paiementVirement = result.data
									$scope.paiementVirement.dateVirement = new Date($scope.paiementVirement.dateVirement);

								});
							}
							else if($scope.demandeDadhesion.factureDTO.modePaiement=="Espèce"){
								Certificat.getPaiementEspeceByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
									$scope.paiementEspece = result.data
									$scope.paiementEspece.datePaiement = new Date($scope.paiementEspece.datePaiement);
								});
							}
							$scope.confirmationPayement = true;
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2"   >'+$scope.value+'</button>&nbsp;&nbsp;';

						}else if ($scope.key == "ATTCONFPAYER1" && ($scope.compareTypeProcess($scope.codePieceOnline) || $scope.compareTypeProcess($scope.codePieceRnvOnline)) ) {
							$scope.modePaiement=null;
							var	html = '<button  ng-disabled="(modePaiement==null) ||  (modePaiement ==\'Espèce\' && form7.$invalid)||(modePaiement ==\'Mobile\' && form7.$invalid)|| (modePaiement ==\'Virement\' && form6.$invalid)||(modePaiement ==\'Chèque\' && form4.$invalid)" id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2"   >Valider</button>&nbsp;&nbsp;';

						}else if ($scope.key == "ATTCONFPAYER1" && $scope.compareTypeProcess($scope.codePieceOffline)) {
							$scope.infoPayementOffline = true;
							$scope.showSpecimen = false;
							$scope.showWorkflowButtonOut = false;
							$scope.infoOe = false;
							$scope.confirmationPayementOffline = true;
							$scope.showDemandeInfo = false;
							var	html = '<button  ng-disabled="(form3.$invalid) && ((modePaiement ==\'Espèce\' && form8.$invalid)|| (modePaiement ==\'Virement\' && form6.$invalid)|| (modePaiement ==\'Mobile\' && form6.$invalid) ||(modePaiement ==\'Chèque\' && form4.$invalid))" id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2"   >'+$scope.value+'</button>&nbsp;&nbsp;';

						} else if ($scope.key == "ANNULER3") {
							$scope.donnerAdhesion1 = true;

							$scope.modeDepot = true;
							$scope.message = true;
							$scope.showSpecimen = false;
							$scope.infoContact = false;
							$scope.infoOe = false;
							$scope.compInformation = false;
							$scope.piecejustif = false;
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2"   >'+$scope.value+'</button>&nbsp;&nbsp;';

						} else if ($scope.key == "ATTVALIDATIONDOC1") {

							var	html = '<button  ng-disabled=" formDepot.$invalid" id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2"   >'+$scope.value+'</button>&nbsp;&nbsp;';

						} 

						else if ($scope.key == "ATTCOMPDOC1") {
							
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button> &nbsp;&nbsp;';
							$scope.donnerAdhesion = true;
							$scope.showSpecimen = false;
							$scope.showNoteAttComp1 = true;
						} 
						else if ($scope.key == "ATTVALIDATION2" ) {
							$scope.showNoteAttComp1 = true;
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button> &nbsp;&nbsp;';
						} 
						/**
						 * En attente de confirmation de paiement
						 * **/
						else if ($scope.key == "DELIVRANCE1") {
							$scope.showNoteAttComp3 = true;
							$scope.donnerAdhesion = true;
							$scope.showSpecimen = false;
							$scope.piecejustif = false;

							$scope.infoPayement = true;
							$scope.showDemandeInfo = false;
							$scope.showWorkflowButtonOut = false;
							
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button> &nbsp;&nbsp;';
							
							
							if($scope.demandeDadhesion.factureDTO!=null){
								if($scope.demandeDadhesion.factureDTO.modePaiement=="Chèque"){
									Certificat.getPaiementChequeByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
										$scope.paiement = result.data
										$scope.paiementCheque.dateCheque = new Date($scope.paiementCheque.dateCheque);
										$scope.paiementCheque.numCheque=parseInt($scope.paiementCheque.numCheque);
									});
								}
								else if($scope.demandeDadhesion.factureDTO.modePaiement=="Virement"){
									Certificat.getPaiementVirementByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
										$scope.paiement = result.data
										$scope.paiementVirement.dateVirement = new Date($scope.paiementVirement.dateVirement);
									});
								}
								else if($scope.demandeDadhesion.factureDTO.modePaiement=="Espèce"){
									Certificat.getPaiementEspeceByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
										$scope.paiement = result.data
										$scope.paiementEspece.datePaiement = new Date($scope.paiementEspece.datePaiement);
									});
								}
								
							}else{

								$scope.demandeDadhesion.factureDTO={};
								$scope.demandeDadhesion.factureDTO.modePaiement= "Paiement En ligne" ;
							}
							
							
						} 
						else if ($scope.key == "ATTCONFPAYER3") {
							$scope.showNoteAttComp3 = true;
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button> &nbsp;&nbsp;';

						}
						
						else if ($scope.key == "ATTCONFPAYER2") {
							$scope.showNoteAttComp3 = true;
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button> &nbsp;&nbsp;';

						}
						else if ($scope.key == "ATTDELIVFACTURE2" && $scope.compareTypeProcess("ADHESOFFLINE")) {
							$scope.infoPayement = true;
							$scope.showDemandeInfo = false;
							$scope.showWorkflowButtonOut = false;
							$scope.donnerAdhesion = true;
							$scope.showSpecimen = false;
							if($scope.demandeDadhesion.factureDTO.modePaiement=="Chèque"){
								Certificat.getPaiementChequeByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
									$scope.paiement = result.data
								});
							}
							else if($scope.demandeDadhesion.factureDTO.modePaiement=="Virement"){
								Certificat.getPaiementVirementByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
									$scope.paiement = result.data
								});
							}
							else if($scope.demandeDadhesion.factureDTO.modePaiement=="Espèce"){
								Certificat.getPaiementEspeceByFacture($scope.demandeDadhesion.factureDTO.id).then(function (result) {
									$scope.paiement = result.data
								});
							}
							
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button>&nbsp;&nbsp;';

						}
						
						/***
						 *  En attente de validation de la confirmation des documents originaux	
						 * ***/
						else if ($scope.key == "DELIVRANCE2") {
							$scope.showNoteAttComp2 = true;
							$scope.showSpecimen = false;
							$scope.confirmaterDocument = true;
							$scope.donnerAdhesion = true;
							$scope.infoOe = false;
							$scope.infoContact = false;
							$scope.compInformation = false;
							var	html = '<button ng-disabled="pieceRecu" id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button>&nbsp;&nbsp;';

						} 
						else if ($scope.key == "ATTCOMPDOC2") {
							$scope.confirmaterDocument = true;
							$scope.infoModeDepot = true;
							var	html = '<button ng-disabled="!pieceRecu" id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button>&nbsp;&nbsp;';

						} 
						
						/***
						 *  En attente de dépôt des documents originaux complémentaires	
						 * ***/
						else if ($scope.key == "ATTVALIDATIONDOC2") {
							$scope.showNoteAttComp2 = true;
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button>' + "<span>&nbsp;&nbsp;</span>";
						}
						else if ($scope.key == "ARCHIVER") {
							$scope.showSpecimen = false;
							$scope.infoDelivrance = true;
							$scope.showDemandeInfo = false;
							$scope.showWorkflowButtonOut = false;
							$scope.donnerAdhesion = true;

							var	html = '<button ng-disabled=verifDelivVar id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button>&nbsp;&nbsp;';

						} else {
							var	html = '<button id="'+$scope.key+'" type="button"  ng-click="traitement(\''+$scope.key+'\')" class="btn btn-info-2">'+$scope.value+'</button>' + "<span>&nbsp;&nbsp;</span>";
						}
					angular.element("#btnWorkflow").append($compile(html)($scope));
					angular.element("#btnWorkflowaa").append($compile(html)($scope));
					angular.element("#btnWorkflowa").append($compile(html)($scope));
					angular.element("#btnWorkflowOut").append($compile(html)($scope));
				}

			});
			$scope.verificationRecuPiece = function() {
				$scope.pieceRecu = false;
				for (var i = 0; i < $scope.pieceJointeDTOs.length; i++) {
					if ($scope.pieceJointeDTOs[i].recu == false) {
						$scope.pieceRecu = true;
					}
				}
			}
			$scope.verificationRecuPiece();

			$scope.downloadPiece = function(docId) {
				var downloadLink = document.createElement("a");
				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				Inscription2.downloadDocument(docId).then(function(result) {
					if(angular.equals( { status: result.status }, { status: 500 } )){
						swal(message,"Un problème s'est produit!", "error")
					}
					var fName = result.headers('Content-Disposition');
					var file = new Blob([ result.data ],{type : 'application/pdf'});
					var fileURL = (window.URL || window.webkitURL)
					.createObjectURL(file);
					downloadLink.href = fileURL;
					downloadLink.download = fName;
					downloadLink.click();
				});
			}

			$scope.nextProcess = function(nextStep) {
				CustomProcessBonita.nextStep($scope.customProcess,nextStep).then(function(result) {
					if(angular.equals( { status: result.status }, { status: 200 } )){	
							if($scope.modePaiement =="Virement"){
								$scope.paiementVirement.dateVirement=  $filter('date')($scope.paiementVirement.dateVirement, 'yyyy-MM-dd');
								Certificat.paiementVirement($scope.paiementVirement,$scope.agendaDTO.id,$scope.modePaiement,$scope.montant).then(function (result) {
									var uploadUrl = "api/uploadFileVirement/"+result.data.id;
									var file = $scope.docPaiement;
									if(typeof file !='undefined'){
										var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
												function(result) {
													$scope.piece = result.data;
												});
									}
								});
							} else if($scope.modePaiement =="Chèque"){
								Certificat.paiementCheque($scope.paiementCheque,$scope.agendaDTO.id,$scope.modePaiement,$scope.montant).then(function (result) {
									var uploadUrl = "api/uploadFileCheque/"+result.data.id;
									var file = $scope.docPaiement;
									if(typeof file !='undefined'){
										var result=fileUpload.uploadFileToUrl(file, uploadUrl).then(
												function(result) {
													$scope.piece = result.data;
												});
									}
								});
							} else if($scope.modePaiement =="Espèce"){
								Certificat.paiementEspece($scope.paiementEspece,$scope.agendaDTO.id,$scope.modePaiement, $scope.montant).then(function (result) {

								});
							} 
							if(nextStep=="ATTCONFPAYER1"){
								swal({
									position: 'center',
									type: 'success',
									title: 'Merci pour le règlement des frais d’adhésion à la C.C.I.C.'+
										'Après vérification du règlement, une notification vous sera adressée à votre boite e-mail pour retirer votre carte d’adhésion et éventuellement votre facture.',
									showConfirmButton: true,
									confirmButtonText: "ok",
									closeOnConfirm: true,
									timer : 60000 
									
								})
							}
							
						$state.go('home');
					} else {

					}
				});
			}

			$scope.traitement = function (nextStep) {
				swal($scope.styleSwalConfirmation,function(){
					$scope.customProcess.demandeAdhesionDTO = $scope.demandeDadhesion;

					if (nextStep == "ATTCOMPDOC1" || nextStep == "ATTCOMPDOC2" || nextStep == "ATTCOMPDOC3") {
						
						$scope.customProcess.specimenSignatureDTOs = null;
						$state.go('note-demandeAdhesion', {customProcess:$scope.customProcess,key: nextStep, id: $scope.agendaDTO.id }, { reload: false } );
					}else {
						$scope.nextProcess(nextStep);
					}
				})
			}

			
			
			
			
			$scope.nombreAvecVirgule = function(number)
			{
				if (number != undefined && number != null && number != "") {
					var a =parseFloat(number)
					return a.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",")
				} else {
					return "";
				}
			}
			
			/*****************************************/
			
			$scope.dtInstanceNotes = {};


			/********************************************/

			$scope.styleSwalConfirmation = {
					title: "Confirmation",
					text: "Voulez vous vraiment continuer ?",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "NON",
					confirmButtonColor: "#337ab7",
					confirmButtonText: "Oui",
					closeOnConfirm: true
			}


			$scope.convertDataURIToBinary =  function (dataURI) {
				var BASE64_MARKER = ';base64,';
				var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
				var base64 = dataURI.substring(base64Index);
				var raw = window.atob(base64);
				var rawLength = raw.length;
				var array = new Uint8Array(new ArrayBuffer(rawLength));

				for(i = 0; i < rawLength; i++) {
					array[i] = raw.charCodeAt(i);
				}
				return array;
			}

		     $scope.consulter = function(){
 				  ReportingService.downloadDemandeAdhesionExcel($scope.agendaDTO.id, $scope.demandeDadhesion).then(function (result) {
 					  var fileName="Adhésion.xlsx"
 					  ReportingService.downloadXlsFile(result, fileName)
 			 });
 				}
			
			$scope.downloadPiece = function(fileCode,fileName) {
				var downloadLink = document.createElement("a");

				document.body.appendChild(downloadLink);
				downloadLink.style = "display: none";
				var data = $scope.convertDataURIToBinary(fileCode);
				var file = new Blob( [ data ], { type : 'application/pdf' });
				var fileURL = (window.URL || window.webkitURL)
				.createObjectURL(file);
				$window.open(fileURL, '_blank');
			
			}
			
			$scope.typeOperateurs = [{id:1 ,libelle: "AUTRE"}, {id:1 ,libelle: "EXPORTATEUR"} ,{id:1 ,libelle: "PRODUCTEUR"}];

			
			CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
				$scope.typeAdhesions = result.data;
				$scope.getTypeAdhesion($scope.demandeDadhesion.typeAdhesionDTO.id);

			});
			$scope.demandeDadhesion.dateCre=  $filter('date')($scope.demandeDadhesion.dateCre, 'yyyy-MM-dd');

			$scope.getTypeAdhesion = function(id) {
				
				var single_object =  $filter('filter')($scope.typeAdhesions, function (d) {return d.id == id;})[0];
				if (single_object != undefined && single_object != null  ) {
					$scope.demandeDadhesion.typeAdhesionLibelle = single_object.description + "(" + single_object.montant + " TND)";
				}
				return "";

			}


			$scope.getTypeOE = function(id) {
				var single_object =  $filter('filter')($scope.typeOperateurs, function (d) {return d.id == id;})[0];
				if (single_object != undefined && single_object != null  ) {
					$scope.demandeDadhesion.typeOELibelle = single_object.libelle ;
				}
				return "";

			}

		


			$scope.lovModelSecteur = 'api/referentiels/getByCode/SACT';// this will be
			// the LOV
			// datasource
			$scope.lovTitleSecteur = 'Liste des secteurs';
			$scope.lovColumnListSecteur = [  'Libellé' ];
			$scope.lovFieldListSecteur = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackSecteur = function(result) {
				$scope.oeExpediteur.secteurActiviteDTO = result;

				$scope.user.oeExpediteur.id
				$scope.$emit('eventSousSecteur',result)
			}


			$scope.lovModelCatJur = 'api/referentiels/getByCode/CATJUR';// this will be
			// the LOV
			// datasource
			$scope.lovTitleCatJur = 'CatÃ©gorie juridique';
			$scope.lovColumnListCatJur = [  'Libellé' ];
			$scope.lovFieldListCatJur = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackCatJur = function(result) {
				$scope.oeExpediteur.categorieJuridiqueDTO = result;
			}

			$http.get("api/referentiels/getByCode/TYOE").then(function(result) {
				$scope.typeOperateurs = result.data;
			});

			$scope.lovModel1 = 'api/referentiels/sousSecteur/all';// this will be
			// the LOV
			// datasource
			$scope.lovTitle1 = 'Liste des sous Secteur';
			$scope.lovColumnList1 = [  'Libellé' ];
			$scope.lovFieldList1 = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBack1 = function(result) {
				$scope.oeExpediteur.sousSecteurDTO = result;
				$scope.$emit('eventSousSecteur',result);

			}

			$rootScope.$on('eventSousSecteur', function(event,result) {
				$scope.lovModel1 = 'api/referentiels/getSousRefByCode/BACT/' +result.codeRubrique;// this will be
				// the LOV
				// datasource
				$scope.lovTitle1 = 'Liste des sous Secteur';
				$scope.lovColumnList1 = [  'Libellé' ];
				$scope.lovFieldList1 = [  'libelle' ];
				// function to be called when you click "choose" button
				$scope.lovCallBack1 = function(result) {
					$scope.oeExpediteur.sousSecteurDTO = result;
				}
			});
			
			
			$scope.lovModelTypeOe = 'api/referentiels/getByCode/TYOE';// this will be
			// the LOV
			// datasource
			$scope.lovTitleTypeOe = 'Type d\'opérateur économique';
			$scope.lovColumnListTypeOe = [  'Libellé' ];
			$scope.lovFieldListTypeOe = [  'libelle' ];
			// function to be called when you click "choose" button
			$scope.lovCallBackTypeOe = function(result) {
				$scope.oeExpediteur.typeOperateurDTO = result;
			}

			$scope.lovModelOeExpediteur = 'api/getAllExpediteurDTO';// this will be
			// the LOV
			// datasource
			$scope.lovTitleOeExpediteur = 'Liste des raisons sociales';
			$scope.lovColumnListOeExpediteur = [' Raison Sociale '];
			$scope.lovFieldListOeExpediteur = ['raisonSociale'];
			// function to be called when you click "choose" button
			$scope.lovCallBackOeExpediteur = function(result) {
				$scope.oeExpediteur = result;				
				
				Inscription2.verifInscription($scope.oeExpediteur.id).then(function (result) {
					if (result.data) {
						swal("Opération interrompue!", "Cette opérateur économique est déjà un adhérant", "error");
						$scope.oeExpediteur = null;
					}
				});
				$scope.$emit('eventSousSecteur',$scope.oeExpediteur.sousSecteurDTO);

				CustomTypeAdhesion.getTypeAdhesionByCCI($scope.oeExpediteur.cciDTO.id).then(function(result) {
					$scope.typeAdhesions = result.data;
				});
			}
			
			/****************CONTACT**************/
			
			$rootScope.$on('eventLocaliteAdrSiege', function(event,result) {
				$scope.lovModel4 = 'api/referentiels/localite/'+result.codeRubrique;// this will be
				// the LOV
				// datasource
				$scope.lovTitle4 = 'Liste des villes';
				$scope.lovColumnList4 = [  'Libellé' ];
				$scope.lovFieldList4 = [  'libelle' ];
				// function to be called when you click "choose" button
				$scope.lovCallBack4 = function(result) {
					$scope.oeExpediteur.adresseSiegeDTO.localite = result;
				}
			});
			$rootScope.$on('eventLocaliteAdrUsine', function(event,result) {
				$scope.lovModel6 = 'api/referentiels/localite/'+result.codeRubrique;// this will be
//				the LOV
//				datasource
				$scope.lovTitle6 = 'Liste des villes';
				$scope.lovColumnList6 = [  'Libellé' ];
				$scope.lovFieldList6 = [  'libelle' ];
//				function to be called when you click "choose" button
				$scope.lovCallBack6 = function(result) {
					$scope.oeExpediteur.adresseUsineDTO.localite = result;
				}
			});
			
			
			$scope.lovModel3 = 'api/referentiels/gouvernorat';// this will be
	  		// the LOV
	  		// datasource
	  		$scope.lovTitle3 = 'Liste des régions';
	  		$scope.lovColumnList3 = [  'Libellé' ];
	  		$scope.lovFieldList3 = [  'libelle' ];
	  		// function to be called when you click "choose" button
	  		$scope.lovCallBack3 = function(result) {
	  			if ($scope.oeExpediteur.adresseSiegeDTO == null) {
		  			$scope.oeExpediteur.adresseSiegeDTO = {};
				}
	  			$scope.oeExpediteur.adresseSiegeDTO.gouvernorat = result;
	  			$scope.$emit('eventLocaliteAdrSiege',result)
	  			Agenda.getCCIDTOByGouvernorat($scope.oeExpediteur.adresseSiegeDTO.gouvernorat.valeur1).then(function (result){
	  				$scope.oeExpediteur.cciDTO = result.data ; 
				});
	  		}
	  		
		  	  $scope.lovModel5 = 'api/referentiels/gouvernorat';// this will be
		  		// the LOV
		  		// datasource
		  		$scope.lovTitle5 = 'Liste des régions';
		  		$scope.lovColumnList5 = [ 'Libellé' ];
		  		$scope.lovFieldList5 = [  'libelle' ];
		  		// function to be called when you click "choose" button
		  		$scope.lovCallBack5 = function(result) {
		  			if ($scope.oeExpediteur.adresseUsineDTO == null) {
			  			$scope.oeExpediteur.adresseUsineDTO = {};
					}
		  			
		  			$scope.oeExpediteur.adresseUsineDTO.gouvernorat = result;
		  			$scope.$emit('eventLocaliteAdrUsine',result)
		  			Agenda.getCCIDTOByGouvernorat($scope.oeExpediteur.adresseUsineDTO.gouvernorat.valeur1).then(function (result){
		  				$scope.oeExpediteur.cciDTO = result.data ; 

		  			});
		  		}
		  		$scope.siegeToUsine= function(siegeUsine) {
		  			if(siegeUsine == "O"){

		  				$scope.oeExpediteur.adresseUsineDTO = $scope.oeExpediteur.adresseSiegeDTO;
		  			}
		  			else {
		  				$scope.oeExpediteur.adresseUsineDTO = {} ;
		  			}
		  		}
				/****************CONTACT**************/
				$scope.addNote = function() {
					$state.go('note-demandeAdhesion-trait', {customProcess:$scope.customProcess,key: $scope.agendaDTO.codeStatut, id: $scope.agendaDTO.id , save:true}, { reload: true } );
				}
				
				PaiementEnLigneWs.getPaiementconfig().then(function(result) {
					var amount=parseFloat($scope.montant)*1000;
					
					if(amount==null || amount == undefined || amount == ""){
						$scope.errors ="Une Erreur dans l'envoie de montant ";
					}
					if(result.data.urlApplication==null || result.data.urlApplication == undefined || result.data.urlApplication == ""){
						$scope.errors ="Une configuration est requise  ";
					}
					if(result.data.userName==null || result.data.userName == undefined || result.data.userName == ""){
						$scope.errors ="Une configuration est requise ";
					}
					if(result.data.curreny==null || result.data.curreny == undefined || result.data.curreny == ""){
						$scope.errors ="Une configuration est requise ";
					}
					if(result.data.language==null || result.data.language == undefined || result.data.language == ""){
						$scope.errors ="Une configuration est requise ";
					}

					var min=1; 
					var max=9999999999;  
					var orderNumber = Math.floor(Math.random() * (max - min)) + min; 
					if(orderNumber==null ||orderNumber == undefined || orderNumber == ""){
						$scope.errors ="Une Erreur dans la configuration de number order est requis ";
					}

					$(".clickToPay").click(function(){
						if($scope.errors != null){
							$scope.paiementEnLigne = true;
						}
						if($scope.paiementEnLigne!=true){
							$.ajax({url: result.data.urlApplication+"?amount="+amount+"&currency="+result.data.curreny+"&language="+result.data.language+"&orderNumber="+orderNumber+"&password="+result.data.password+"&returnUrl="+result.data.urlRedirectionApplicationClickToPay+"/%23/successAdhesion?id="+$scope.idAgenda+"&failUrl="+result.data.urlRedirectionApplicationClickToPay+"/%23/failPaiementAdhesion?id="+$scope.idAgenda+"&userName="+result.data.userName , 
								success: function(result){
									var json = JSON.parse(result);
									$(".paiementEnLigne").click();
									window.location.href = json.formUrl;
									if(json.formUrl==undefined){
										if(json.errorCode==1){
											swal("Paiement En Ligne","Un problème s'est produit au niveau de géneration order number!", "error")
										}
										if(json.errorCode==2){
											swal("Paiement En Ligne","La commande est refusée en raison d'une erreur dans les informations d'identification de paiement!", "error")
										}

										if(json.errorCode==5){
											swal("Paiement En Ligne","Accès refusé!", "error")
										}

									}


								}});
						}else{
							$("html, body").animate({scrollTop: 100},"slow");

						}

					});

				});
		}
})();
