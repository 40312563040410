(function() {
'use strict';
angular
	.module('infochamApp')
	.controller('ListeReferenceDetailController',ListeReferenceDetailController);
ListeReferenceDetailController.$inject=['$scope', '$rootScope','$stateParams', 'entity', 'ListeReference'];
function ListeReferenceDetailController($scope, $rootScope, $stateParams, entity, ListeReference){
	$scope.listeReference = entity;
    $scope.load = function (id) {
        ListeReference.get({id: id}, function(result) {
            $scope.listeReference = result;
        });
    };
    var unsubscribe = $rootScope.$on('infochamApp:listeReferenceUpdate', function(event, result) {
        $scope.listeReference = result;
    });
    $scope.$on('$destroy', unsubscribe);

}

})();

