
(function() {
    'use strict';
    angular
        .module('infochamApp')
        .factory('PaiementEnLigneWs', PaiementEnLigneWs);

    PaiementEnLigneWs.$inject = ['$http','$resource', 'DateUtils','$rootScope'];

    function PaiementEnLigneWs ($http,$resource, DateUtils,$rootScope) {
        return {
        	
			'getPaiementconfig': function () {

				return  $http.get('api/getPaiementconfig') .success(function (data){
			    })
			    .error(function (error, status){
			  }); 
			}
			
			
        };
    }
    
})();