(function() {
    'use strict';

    angular
        .module('infochamApp')
        .factory('MonitoringService', MonitoringService);

    MonitoringService.$inject = ['$rootScope','$http'];

    function MonitoringService ($rootScope,$http) {
        return {
            getMetrics: function () {
                return $http.get('metrics/metrics').then(function (response) {
                    return response.data;
                });
            },

            checkHealth: function () {
                return $http.get('health').then(function (response) {
                    return response.data;
                });
            },

            threadDump: function () {
                return $http.get('dump').then(function (response) {
                    return response.data;
                });
            }
        };
    }
})();
