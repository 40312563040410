(function() {
	'use strict';

	angular.module('infochamApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'demandeCertificatOrigineHL',
						{
							parent : 'home',
							url : 'demande-certificatOrigineHL',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},

							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-HL/demande-certificatOrigine-horsLigne.html',
									controller : 'DemandeCertificatOrigineHorsLigneController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'factureHL-detail',
						{
							parent : 'demandeCertificatOrigineHL',
							url : '/factureHL',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								idExpediteur : null,

							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-HL/facture-detail-hl.html',
													controller : 'FactureDetailHLController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result.then(
												function(result) {
													$state.go('nature', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						})
				.state(
						'factureHL-detail-edit',
						{
							parent : 'demandeCertificatOrigineHL',
							url : '/editfacturehl',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],

								pageTitle : 'infochamApp.listeReference.home.title'
							},
							params : {
								facture : null,
								index : null,
								idExpediteur : null,
							},

							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/certificatOrigine/demandeCO-HL/facture-detail-hl-edit.html',
													controller : 'FactureDetailHLEditController',
													size : 'md',
													resolve : {
														entity : function() {
															return {
																codeListe : null,
																designationListe : null,
																status : null,
															};
														}
													}
												}).result.then(
												function(result) {
													$state.go('nature', null, {
														reload : true
													});
												}, function() {
													$state.go('^');
												})
									} ]
						})

				.state(
						'certificatOrigine-horsLigne-numerisation',
						{
							parent : 'home',
							url : 'certificatOrigineHL/numerisation/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-HL/certificatOrigine-horsLigne-numerisation.html',
									controller : 'CertificatOrigineHorsLigneNumerisationController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-horsLigne-facturation',
						{
							parent : 'demandeCertificatOrigineHL',
							url : '/facturationHL/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-HL/certificatOrigine-horsLigne-facturation.html',
									controller : 'CertificatOrigineHorsLigneFacturationController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-horsLigne-paiement',
						{
							parent : 'home',
							url : 'certificatOrigineHL/paiement',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								id : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-paiement.html',
									controller : 'CertificatOrigineHorsLignePaiementController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-horsLigne-paiement-detail',
						{
							parent : 'home',
							url : 'paiementHL/consult/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_PAIEMENT',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-HL/certificatOrigine-horsLigne-paiement-detail.html',
									controller : 'CertificatOrigineHorsLignePaiementConsultController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'certificatOrigine-horsLigne-delivrance',
						{
							parent : 'home',
							url : 'delivranceHL',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_CO',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							params : {
								id : null

							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-HL/certificatOrigine-delivrance.html',
									controller : 'CertificatHLDelivranceController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
						
							.state(
						'certificatOrigineHL-paiementCredit-detail',
						{
							parent : 'demandeCertificatOrigineHL',
							url : '/paiementCredit/consult/{id}',
							data : {
								authorities : [ 'ROLE_RESPONSABLE_PAIEMENT',
										'ROLE_ADMIN' ],
								pageTitle : 'infochamApp.agenda.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/certificatOrigine/demandeCO-Online/certificatOrigine-paiementCredit-detail.html',
									controller : 'CertificatPaiementCreditConsultController',
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('agenda');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})

	}
})();
