(function() {
	'use strict';
	angular.module('infochamApp').factory('CustomProcessBonita', CustomProcessBonita);
	CustomProcessBonita.$inject = ['$http', '$rootScope'];
	function CustomProcessBonita ($http, $rootScope) {
		return {
			'getListBoutonWorkflowByIdCaseIdTask':function(idCase,idTask){
				return $http.get('api/getListBoutonWorkflowByIdCaseIdTask/'+idCase + '/'+idTask)	;
			},
			'nextStepProcess': function(id,key,value){
				return $http({
					method : "PUT",
					url : "api/updateAgenda/"+id+"/"+key+"/"+value,
					dataType: "json",
					traditional: true,
					headers : {
						'Content-Type' : 'application/json'	}
				}).then(function(response) {
					$rootScope.status = response.status;
					return response;
				}, function(error) {
					$rootScope.status = error.status;
					return error;
				});
			},	
			'nextStep':function(customProcess,nextButtonCode){
				return $http({
					method : "PUT",
					url : "api/nextStep/"+nextButtonCode,
					data: customProcess,
					headers : {
						'Content-Type' : 'application/json'	}
				});
			},
			'createProcessAdhesionOnline':function(agenda){
				return $http({
					method : "PUT",
					url : "api/createProcessAdhesionOnline/",
					data: agenda,
					headers : {
						'Content-Type' : 'application/json'	}
				});
			},
			'createProcessAdhesionCOOnline':function(agenda){
				return $http({
					method : "PUT",
					url : "api/createProcessAdhesionCOOnline/",
					data: agenda,
					headers : {
						'Content-Type' : 'application/json'	}
				});
			},
			'remplireCustomProcess':function(idAgenda){
				return $http.get('api/remplireCustomProcess/'+idAgenda)	;
			},

		}
	}
})();

